import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const ButtonConteiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin: 1rem;
  padding: 0.5rem 1rem;
`;
export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 98%;
  margin-top: 1.5rem;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
`;

export const SettingsContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;

  &:last-child {
    padding-bottom: 4rem;
  }

  &:first-child {
    margin-top: 0;
  }

  span {
    margin: 1rem;
    padding: 0.5rem 1rem;
  }

  input {
    height: 3.5rem;
    padding: 0.3rem 0.5rem;
  }
`;

export const SettingsContentTable = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  padding: 1.6rem;

  h1 {
    margin-bottom: -1rem;
    margin-top: 1.5rem;
    font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
    color: #696969;
  }
`;

export const ImageBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const TableImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const InputButton = styled.input`
  border: 0;
  background: ${({ theme }) => theme.mainColor}; // ${styleSheet.mainColor};
  width: 3rem;
`;

export const HeaderTabFiles = styled.div`
  display: flex;
  width: 100%;
  algin-items: center;
  justify-content: space-between;
`;

export const ButtonTable = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;

export const InputColorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8rem;
`;

export const InputColorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputColorLabel = styled.label`
  font-family:  ${({ theme }) => theme.mainFont};
  font-size: 1.4rem;
`;


export const SemanticTab = styled(Tab)`

  .ui.vertical.menu.fluid {
    height: 100%;
  }

  .menu > .item {
    color: ${({ theme }) => theme.mainColor} !important;

    &:hover {
      opacity: 0.7 !important;
      color: ${({ theme }) => theme.mainColor} !important;
    }
  }

  .menu > .active {
    color: ${({ theme }) => theme.mainColor} !important;
    border-color: ${({ theme }) => theme.mainColor} !important;
  }
`;


export const AppSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;