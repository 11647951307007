import React, { FC, useContext, useEffect, useRef, useState } from "react";
import FullCalendar, { EventContentArg } from "@fullcalendar/react";
import { Calendar as CalendarIcon } from "phosphor-react";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Dimmer, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";

import { OsService } from "../../services/methods/OsService";

import { UIContext } from "../../contexts/UIContext";
import { OrderServiceContext } from "../../contexts/OrderServiceContext";

import FormOrderServicesItensModal from "../../components/FormOrderServicesItensModal";
import FormAttendanceModal from "../../components/FormAttendanceModal";
import FilterModal, { IFilter } from "../../components/FilterModal";
import DefaultHeader from "../../components/DefaultHeader";
import PrintModal from "../../components/PrintModal";
import { IErrorProps } from "../../components/Error";
import Datatable from "../../components/Datatable";
import SideMenu from "../../components/SideMenu";
import Browser from "../../components/Browser";
import Footer from "../../components/Footer";

import { IPaginatedServiceResponse } from "../../interfaces/Datatable/IPaginatedServiceResponse";
import { IOpcoes } from "../../interfaces/Menu/IMenu";

import {
  DataTableReducerInitialState,
  loadTableData,
  updateDataTableIndice,
  updateMode,
  updateTableCurrentPage,
  updateTableFilter,
  updateTableLoading,
  updateTableOrderBy,
  updateTableSearchText,
} from "../../reducers/DataTable";
import { EnvironmentReducerInitialState } from "../../reducers/Environment";
import { PolicieReducerInitialState } from "../../reducers/Policie";
import { MenuReducerInitialState } from "../../reducers/Menu";
import { AuthReducerInitialState } from "../../reducers/Auth";
import { clearAll } from "../../reducers/RestField";

import { Utils } from "../../common/Utils";
import { styleSheet } from "../../assets/styles/global";

import {
  BrowserContent,
  Calendar,
  CalendarContainer,
  Content,
  Data,
  PageDefault,
  SubTitle,
  Title,
} from "./styles";
import ProductDetailModal from "../../components/ProductDetailModal";
import { debounce } from "../../common/debounce";

enum StatusColor {
  Atendida = "#ffff00",
  Encerrado = "#ff0000",
  Aberto = "#00ff00",
}

const OrderServiceSchedules: FC = () => {
  const osService: OsService = new OsService();

  const {
    tableLoading,
    tableCurrentPage,
    tableFieldTypeConfig,
    tableFieldFilters,
    tableSearchText,
    tableFilter,
    indice,
    mode,
    tableOrderBy,
  } = useSelector(
    (state: { dataTable: DataTableReducerInitialState }) => state.dataTable
  );
  const { currentBranch, itensPerPage, selectedClient, currentCompany } =
    useSelector(
      (state: { environment: EnvironmentReducerInitialState }) =>
        state.environment
    );
  const { token, pwm: configuracoes, customerLevel } = useSelector(
    (state: { auth: AuthReducerInitialState }) => state.auth
  );
  const { companyPolicies } = useSelector(
    (state: { policie: PolicieReducerInitialState }) => state.policie
  );
  const { selectedMenu } = useSelector(
    (state: { menu: MenuReducerInitialState }) => state.menu
  );

  const dispatch = useDispatch();

  const calendar = useRef<FullCalendar>(null);

  const { setUIComponentState } = useContext(UIContext);
  const { orderServiceStyle } = useContext(OrderServiceContext);

  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [month, setMonth] = useState(Number(moment().format("MM")));
  const [startOfMonth, setStartOfMonth] = useState(
    moment()
      .set("month", Number(moment().format("MM")) - 1)
      .clone()
      .startOf("month")
      .format("YYYYMMDD")
  );
  const [endOfMonth, setEndOfMonth] = useState(
    moment()
      .set("month", Number(moment().format("MM")) - 1)
      .clone()
      .endOf("month")
      .format("YYYYMMDD")
  );
  const [fullCalendarData, setFullCalendarData] = useState<object[]>([]);

  const [formFieldState, setFormFieldState] = useState(false);
  const [attendanceMode, setAttendanceMode] = useState("");
  const [formAttendanceFieldState, setFormAttendanceFieldState] =
    useState(false);
  const [attendanceIndice, setAttendanceIndice] = useState<string | number>("");
  const [filterModal, setFilterModal] = useState(false);
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([]);
  const [orderServiceStatus, setOrderServiceStatus] = useState("");
  const [printModal, setPrintModal] = useState(false);
  const [printOptions, setPrintOption] = useState<IOpcoes[]>([]);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const utils = new Utils();

  async function getOrderServiceList(
    isDate: boolean,
    page: number,
    order: string,
    filter?: string | undefined,
    statusFilter?: { [key: string]: string } | undefined
  ) {
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));

      const pageSize = isDate ? 40 : itensPerPage;
      const customerKey = selectedClient.indice
        ? String(selectedClient.indice)
        : "";
      const response = await osService.index(
        currentBranch.codigo,
        page,
        pageSize,
        token,
        order,
        customerKey,
        currentCompany,
        filter,
        statusFilter
      );
      const {
        titulo,
        ordem,
        dados,
        legenda,
        totalPages,
        fieldTypeConfig,
        filtros,
        oculto,
        nivel
      } = response.data as IPaginatedServiceResponse;

      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados);
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(
          loadTableData({
            tableData: dados,
            tableOrder: ordem.filter(
              (campo) => !hiddenFields.find((oculto) => oculto === campo)
            ),
            tableTitles: titulo,
            tableLegend: legenda,
            tableFieldFilters: filtros,
            tableTotalPages: totalPages,
            tableFieldTypeConfig: fieldTypeConfig,
          })
        );

        setFullCalendarData(
          dados.map((dado) => {
            return {
              ...dado,
              start: utils.formatDateDefault(dado["ab6_emissa"].toString()),
              color: "transparent",
            };
          })
        );
      } else {
        setUIComponentState("error", {
          visible: true,
          title: "Ops... Houve um erro inesperado!",
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState("error", {
              visible: false,
              title: "Ops...",
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => {
                console.log();
              },
            });
          },
        } as IErrorProps);
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getOrderServiceWithTextSearch = debounce(
    async (searchValue: string) => {
      if (!!searchValue) {
        const searchValueText = configuracoes.usesearchsensitive
          ? searchValue
          : searchValue.toUpperCase();
        const searchString = utils.formatSearchStringToPaginationService(
          tableFieldTypeConfig.character,
          tableFieldFilters.character,
          "contains",
          searchValueText
        );

        dispatch(
          updateTableFilter({ value: { ...tableFilter, filter: searchString } })
        );
        dispatch(updateTableSearchText({ value: searchValue }));

        await getOrderServiceList(
          false,
          1,
          tableOrderBy,
          searchString,
          tableFilter.statusFilter
        );
      } else {
        dispatch(
          updateTableFilter({ value: { ...tableFilter, filter: undefined } })
        );
        dispatch(updateTableSearchText({ value: "" }));
        await getOrderServiceList(
          false,
          1,
          tableOrderBy,
          undefined,
          tableFilter.statusFilter
        );
      }
    },
    Number(configuracoes.debounceinput) || 2000
  );

  async function orderOrderServices(order: string) {
    dispatch(updateTableOrderBy({ value: order }));
    await getOrderServiceList(
      false,
      1,
      order,
      tableFilter.filter,
      tableFilter.statusFilter
    );
  }

  async function onViewProjectClick() {
    setFormFieldState(true);
  }

  async function filterOrderServices(
    filter?: string | undefined,
    statusFilter?: { [key: string]: string } | undefined
  ) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }));
    await getOrderServiceList(false, 1, tableOrderBy, filter, statusFilter);
  }

  async function handlePrint(indice: string | number, typePrint?: string) {
    const id = toast.loading("Por favor aguarde...", { closeButton: true });
    const useLandscapeDanfe = companyPolicies.find(
      (police) =>
        police.property === "useLandscapeDanfe" &&
        police.policy_value === "true"
    );

    try {
      const orientation = useLandscapeDanfe ? "landscape" : "portrait";

      const { data, status } = await osService.print(
        currentCompany,
        currentBranch.codigo,
        String(indice),
        "AB6",
        token,
        orientation,
        typePrint
      );

      if (status === 200 || status === 201) {
        if (data.pdf) {
          toast.update(id, {
            render: data.msg,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
          const blob = utils.base64toBlob(
            `data:application/pdf;base64,${ data.pdf }`
          );
          const url = URL.createObjectURL(blob);

          const hiddenElement = document.createElement("a");
          hiddenElement.href = url;
          hiddenElement.target = "_blank";
          hiddenElement.click();
        } else {
          toast.update(id, {
            render: data.msg,
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
          return Swal.fire({
            title: "Sucesso!",
            text: data.msg,
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      } else {
        toast.update(id, {
          render: data.msg,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        return Swal.fire({
          title: "Ops!",
          text: data.msg || data.errorMessage,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log(error);
      toast.update(id, {
        render: "O servidor retornou um erro interno!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      return Swal.fire({
        title: "Ops!",
        text: "O servidor retornou um erro interno!",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <Data
        color={ StatusColor[eventContent.event._def.extendedProps["ab6_status"]] }
      >
        <Title>{ eventContent.event._def.extendedProps["ab6_nomcli"] }</Title>
        <SubTitle>
          { eventContent.event._def.extendedProps["ab6_loja"] } -{ " " }
          { eventContent.event._def.extendedProps["ab6_numos"] }
        </SubTitle>
      </Data>
    );
  }

  function updateServiceOrders() {
    if (orderServiceStyle === "calendar") {
      getOrderServiceList(
        true,
        tableCurrentPage,
        tableOrderBy,
        `AB6_EMISSA ge '${ startOfMonth }' and AB6_EMISSA le '${ endOfMonth }'`
      );
    } else {
      getOrderServiceList(false, tableCurrentPage, tableOrderBy);
    }
  }

  useEffect(() => {
    updateServiceOrders();
    dispatch(updateTableSearchText({ value: "" }));
  }, [orderServiceStyle]);

  useEffect(() => {
    if (orderServiceStyle === "calendar") {
      updateServiceOrders();
      setCurrentDate(
        moment()
          .set("month", month - 1)
          .format("YYYY-MM-DD")
      );
    }
  }, [month]);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser
          title={ selectedMenu.descri || selectedMenu.nome }
          icon={ <CalendarIcon size={ 24 } color={ styleSheet.mainColor } /> }
        >
          <BrowserContent>
            { orderServiceStyle === "list" ? (
              <Datatable
                onItensPerPageChange={ () =>
                  getOrderServiceList(
                    false,
                    1,
                    tableOrderBy,
                    tableFilter.filter,
                    tableFilter.statusFilter
                  )
                }
                onPageChange={ (page) =>
                  getOrderServiceList(
                    false,
                    page,
                    tableOrderBy,
                    tableFilter.filter,
                    tableFilter.statusFilter
                  )
                }
                onOrderBy={ (value) => orderOrderServices(value) }
                onStatusFilter={ (
                  status: { [key: string]: string } | undefined
                ) => filterOrderServices(tableFilter.filter, status) }
                onDatatableActionClick={ (action, indice) => {
                  dispatch(updateDataTableIndice({ value: indice }));
                  if (action !== "upload")
                    dispatch(updateMode({ value: action }));
                  if (action === "view") onViewProjectClick();
                  if (action === "filter") setFilterModal(true);
                  if (action === "print") {
                    if (
                      selectedMenu.submenu.find(
                        (submenu) => submenu.nome.toLowerCase() === "print"
                      ).opcoes.length > 0
                    ) {
                      setPrintModal(true);
                      setPrintOption(
                        selectedMenu.submenu.find(
                          (submenu) => submenu.nome.toLowerCase() === "print"
                        ).opcoes
                      );
                    } else {
                      handlePrint(indice, "");
                    }
                  }
                } }
                onSearch={ (value, noDebounce) => getOrderServiceWithTextSearch(noDebounce ? 'enter' : '', value) }
              />
            ) : (
              <CalendarContainer>
                <Calendar>
                  { tableLoading ? (
                    <div style={ { height: window.innerHeight - 220 } }>
                      <Dimmer inverted active>
                        <Loader size="big">
                          Carregando ordens de serviços...
                        </Loader>
                      </Dimmer>
                    </div>
                  ) : (
                    <FullCalendar
                      ref={ calendar }
                      height={ window.innerHeight - 220 }
                      plugins={ [
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ] }
                      // views={{
                      //   dayGridMonth: {
                      //     type: "month",
                      //     duration: { week: 5 },
                      //     buttonText: "4 Weeks",
                      //     fixedWeekCount: false,
                      //   }
                      // }}
                      initialView="dayGridMonth"
                      headerToolbar={ {
                        left: "prev,next today",
                        center: "customTitle",
                        right: "",
                      } }
                      customButtons={ {
                        prev: {
                          text: "Prev",
                          click: async function () {
                            if (month === 1) {
                              setStartOfMonth(
                                moment()
                                  .set("month", 11)
                                  .clone()
                                  .startOf("month")
                                  .format("YYYYMMDD")
                              );
                              setEndOfMonth(
                                moment()
                                  .set("month", 11)
                                  .clone()
                                  .endOf("month")
                                  .format("YYYYMMDD")
                              );
                              setMonth(12);
                            } else {
                              setStartOfMonth(
                                moment()
                                  .set("month", month - 2)
                                  .clone()
                                  .startOf("month")
                                  .format("YYYYMMDD")
                              );
                              setEndOfMonth(
                                moment()
                                  .set("month", month - 2)
                                  .clone()
                                  .endOf("month")
                                  .format("YYYYMMDD")
                              );
                              setMonth(month - 1);
                            }
                          },
                        },
                        next: {
                          text: "Next",
                          click: async function () {
                            if (month === 12) {
                              setStartOfMonth(
                                moment()
                                  .set("month", 0)
                                  .clone()
                                  .startOf("month")
                                  .format("YYYYMMDD")
                              );
                              setEndOfMonth(
                                moment()
                                  .set("month", 0)
                                  .clone()
                                  .endOf("month")
                                  .format("YYYYMMDD")
                              );
                              setMonth(1);
                            } else {
                              setStartOfMonth(
                                moment()
                                  .set("month", month)
                                  .clone()
                                  .startOf("month")
                                  .format("YYYYMMDD")
                              );
                              setEndOfMonth(
                                moment()
                                  .set("month", month)
                                  .clone()
                                  .endOf("month")
                                  .format("YYYYMMDD")
                              );
                              setMonth(month + 1);
                            }
                          },
                        },
                        today: {
                          text: "Hoje",
                          click: async function () {
                            const month = Number(moment().format("MM"));
                            setStartOfMonth(
                              moment()
                                .set("month", month - 1)
                                .clone()
                                .startOf("month")
                                .format("YYYYMMDD")
                            );
                            setEndOfMonth(
                              moment()
                                .set("month", month - 1)
                                .clone()
                                .endOf("month")
                                .format("YYYYMMDD")
                            );
                            setMonth(Number(month));
                          },
                        },
                        customTitle: {
                          text: `Período de ${ new Intl.DateTimeFormat("pt-BR", {
                            month: "long",
                          }).format(new Date(moment(currentDate).format())) }`,
                          click: (ev) => {
                            ev.preventDefault();
                          },
                        },
                      } }
                      stickyHeaderDates={ false }
                      events={ fullCalendarData }
                      eventContent={ renderEventContent }
                      initialDate={ currentDate }
                      eventClick={ async (arg) => {
                        dispatch(
                          updateDataTableIndice({
                            value: arg.event._def.extendedProps.indice,
                          })
                        );
                        dispatch(updateMode({ value: "add" }));
                        setFormFieldState(true);
                      } }
                      selectable={ true }
                      selectMirror={ true }
                      eventDurationEditable={ false }
                      locale="br"
                      expandRows={ true }
                      dragScroll={ false }
                      firstDay={ 1 }
                    />
                  ) }
                </Calendar>
              </CalendarContainer>
            ) }
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

      { formFieldState && (
        <FormOrderServicesItensModal
          indice={ indice }
          visible={ formFieldState }
          mode={ "view" }
          updateOrderServiceStatus={ (value) => {
            setOrderServiceStatus(value);
          } }
          onAdd={ () => {
            setFormFieldState(false);
            dispatch(clearAll());
            setFormAttendanceFieldState(true);
            setAttendanceMode("add");
          } }
          onView={ (indice) => {
            setAttendanceMode("view");
            setAttendanceIndice(indice);
            setFormFieldState(false);
            dispatch(clearAll());
            setFormAttendanceFieldState(true);
          } }
          onClose={ () => {
            if (mode === "edit" || mode === "add") {
              if (orderServiceStyle !== "calendar") {
                getOrderServiceWithTextSearch(tableSearchText);
              } else {
                updateServiceOrders();
              }

            }
            dispatch(updateTableSearchText({ value: "" }));
            setFormFieldState(false);
            dispatch(clearAll());
          } }
        />
      ) }

      <FilterModal
        visible={ filterModal }
        filtersValue={ filtersValue }
        setFiltersValues={ setFiltersValue }
        handleFilter={ filterOrderServices }
        onClose={ () => {
          setFilterModal(false);
        } }
      />

      { formAttendanceFieldState && (
        <FormAttendanceModal
          indice={ indice }
          attendanceIndice={ attendanceIndice }
          visible={ formAttendanceFieldState }
          mode={ attendanceMode }
          orderServiceStatus={ orderServiceStatus }
          onClose={ (reload) => {
            if (orderServiceStyle !== "calendar") {
              getOrderServiceWithTextSearch(tableSearchText);
            }
            dispatch(updateTableSearchText({ value: "" }));
            setFormFieldState(true);
            setFormAttendanceFieldState(false);
            dispatch(clearAll());
          } }
        />
      ) }

      <ProductDetailModal />

      <PrintModal
        visible={ printModal }
        onClose={ () => setPrintModal(false) }
        handleConfirmationAction={ (type) => {
          handlePrint(indice, type);
          setPrintModal(false);
        } }
        options={ printOptions }
        isPrinting={ isSendEmail }
      />
    </PageDefault>
  );
};

export default OrderServiceSchedules;
