import React, { FC, useMemo } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../../common/Utils';
import { LogData } from "../../../../reducers/Logs";

interface IPagesBarChartProps {

  data: LogData[];
}

const PagesBarChart: FC<IPagesBarChartProps> = ({ data }) => {
  const pagesArray = useMemo(() => [...new Set(data.map(({ values }) => values.page))] as string[], [data]);

  const pagesArrayData = useMemo(() => {
    const arrayData = []

    for (const pagesName of pagesArray) {
      const pagesAccessCount = data.filter(({ values }) => values.page === pagesName).length;
      arrayData.push(pagesAccessCount);
    }

    return arrayData
  }, [data]);

  const utils = new Utils();
  const colors = pagesArray.map(() => utils.getRandomColor());

  return (
    <Chart
      type="bar"
      width="100%"
      height={180}
      series={[{
        name: 'Contagem de acesso',
        data: pagesArrayData,
      }] as ApexAxisChartSeries}
      options={{
        legend: {
          position: 'top',
          offsetX: 30
        },
        title: {
          text: 'Contagem de acesso',
          style: {
            fontSize: '12px'
          }
        },
        colors: colors,
        xaxis: {
          labels: {
            rotate: -18,
            style: {
              fontSize: '10px'
            }
          },
          categories: pagesArray,
        },
      }}
    />
  );
}

export default PagesBarChart;