import { Button, Modal, Transition, Dimmer, Loader, Tab, Table, Popup } from 'semantic-ui-react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { ClientService } from '../../services/methods/ClientService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../../components/Error';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { RestFieldReducerInitialState } from '../../reducers/RestField';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { CartReducerInitialState } from '../../reducers/Cart';
import { MenuReducerInitialState } from '../../reducers/Menu';
import RestField from '../RestField';
import { DataTableReducerInitialState } from '../../reducers/DataTable';
import { Utils } from '../../common/Utils';
import { RestFieldValidationReducerInitialState } from '../../reducers/RestFieldValidation';
import DropZone from "../DropZone";
import { IFile } from "../../interfaces/Attach/Attach";
import { Id, toast } from "react-toastify";
import { PageTypeReg, UploadDescription, UploadTable } from "../../common/Enums";
import { AttachService } from "../../services/methods/AttachService";
import Skeleton from "react-loading-skeleton";
import { ButtonTable, Content } from "./styles";
import { Eye } from "phosphor-react";
import SubMenuOptions from "../SubMenuOptions";
import { encode } from "base-64";

interface IFormModalProps {
  visible: boolean;
  fieldIsLoading: boolean;
  onClose(reload: boolean): void;
}

enum URLService {
  Empresas = 'wscliente',
  Usuarios = 'wsusuario',
  Projetos = 'wsprojeto',
  Vendedor = 'wsvendedor',
}

enum Title {
  Empresas = 'Cliente',
  Usuarios = 'Usuário',
  Projetos = 'Projeto',
  Financeiro = 'Financeiro',
  Vendedor = 'Vendedor',
}

const FormModal: FC<IFormModalProps> = ({ visible, fieldIsLoading, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { mode } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
    
  const [tabSelected, setTabSelected] = useState<'Lista' | 'Upload'>(mode === 'add' ? 'Upload' : 'Lista');
  const [files, setFiles] = useState<string[]>([])
  const [fileList, setFileList] = useState<IFile[]>([])
  const [deletedFile, setDeletedFile] = useState(999999999);

  const { currentBranch, selectedRow, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { isEditing, isCopying } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { selectedMenu, selectedSubMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { cgcIsValid } = useSelector((state: { validate: RestFieldValidationReducerInitialState }) => state.validate);

  const clientService: ClientService = new ClientService();
  const attachService: AttachService = new AttachService();

  const utils = new Utils();

  const { setUIComponentState } = useContext(UIContext);

  async function handleCreateOrUpdate() {
    let id: string | number
    try {

      let msg = '';

      const hasInvalidField = fields
        .filter(field => {
          if (field.obrigado === "S" && (field.tipo !== '5' && field.tipo !== '6')) {
            const result = (isEditing || isCopying)
              ? fieldValues[field.nome] === undefined || fieldValues[field.nome] === '' && field.conteudo === ''
              : fieldValues[field.nome] === undefined || fieldValues[field.nome] === ''

            if (field.nome === 'A1_CGC' && cgcIsValid.type !== '' && !cgcIsValid.isValid) return field.titulo

            if (result) return field.titulo;

          }
        })
        .map(field => msg += `<li><strong>${ field.titulo }</strong></li>`)

      if (hasInvalidField.length > 0) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
              <br>Campo(s) obrigatório(s) não preenchido(s) ou inválido(s)</br>
              <ul>
                ${ msg }
              </ul>
            `,
        })
      }

      const useObligationSuframaFieldBySelectedStatus = companyPolicies.find(policy => policy.property === 'useObligationSuframaFieldBySelectedStatus' && policy.policy_value === 'true');
      const useObligationCeiObraFieldBySelectedPeople = companyPolicies.find(policy => policy.property === 'useObligationCeiObraFieldBySelectedPeople' && policy.policy_value === 'true');

      if (useObligationSuframaFieldBySelectedStatus && selectedMenu.nome === 'Empresas') {

        const states = companyPolicies.find(policy => policy.policy === useObligationSuframaFieldBySelectedStatus.dependencie).policy_value
        const firstCondition = states.indexOf(String(fieldValues.A1_EST)) > -1 && !fieldValues['A1_SUFRAMA'];
        const secondCondition = states.indexOf(String(fieldValues.A1_EST)) > -1 && fieldValues['A1_SUFRAMA'];

        const msg = () => (
          Swal.fire({
            title: 'Atenção!',
            text: `O campo suframa é obrigatório para o estado ${ fieldValues.A1_EST }!`,
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        )

        if (firstCondition) return msg();
        if (secondCondition) {
          if (fieldValues['A1_SUFRAMA'].toString().trim().length <= 0) return msg();
        }
      }

      if (useObligationCeiObraFieldBySelectedPeople && selectedMenu.nome === 'Empresas') {
        const firstCondition = cgcIsValid.type === "cpf" && !fieldValues['A1_CEIOBRA']
        const secondCondition = cgcIsValid.type === "cpf" && fieldValues['A1_CEIOBRA']

        const msg = () => (
          Swal.fire({
            title: 'Atenção!',
            text: `O campo "CEI DA OBRA" é obrigatório para pessoa física.`,
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        )

        if (firstCondition) return msg();

        if (secondCondition) {
          if (fieldValues['A1_CEIOBRA'].toString().trim().length <= 0) return msg();
        }
      }

      const fieldValuesToSend = utils.formatFieldsValuesByPicture(fields, utils.despiseFieldsValue(fields, fieldValues))

      setIsLoading(true)
      id = toast.loading(`${mode === 'add' ? 'Criando' : 'Alterando'} ${selectedMenu.descri}`)
      if (mode === 'add') {

        const { data, status, request } = await clientService.create(URLService[selectedMenu.nome], '', currentBranch.codigo, token, fieldValuesToSend)

        if (status === 200 || status === 201) {
          if (fileList.length > 0) {
            handleUploadFile(id, data.indice)
          } else {
            onClose(true)
            Swal.fire({
              title: 'Sucesso!',
              text: data.msg,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          }
        } else {
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
          return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        }
      } else {
        const notChangedFields = {};

        fields.map(field => field.visual !== 'D' ?? Object.assign(notChangedFields, { [field.nome]: field.conteudo }));

        const restFieldData = {
          ...notChangedFields,
          ...fieldValuesToSend
        }

        const { data, status, request } = await clientService.update(URLService[selectedMenu.nome], '', currentBranch.codigo, selectedRow.indice, token, restFieldData)

        if (status === 200 || status === 201) {
          if (fileList.length > 0) {
            handleUploadFile(id, selectedRow.indice)
          } else {
            onClose(true)
            Swal.fire({
              title: 'Sucesso!',
              text: data.msg,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          }
        } else {          
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
          return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
      return toast.update(id, { isLoading: false, autoClose: 10 })
    } finally {
      if (fileList.length <= 0) {
        setIsLoading(false)
      }
    }
  }

  function handleCloseModal() {
    if (configuracoes.useconfirmationorder && Object.keys(fieldValues).length > 0 && mode !== 'view') {
      return Swal.fire({
        title: 'Confirmar ação?',
        text: 'Os dados preenchidos serão perdidos!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          onClose(false);
        }
      });
    } else {
      onClose(false);
    }
  }

  async function loadFiles() {
    try {
      setIsLoading(true)
      const { data, status } = await attachService.index(currentBranch.codigo, token, String(selectedRow.indice), String(selectedRow.indice), PageTypeReg[selectedMenu.nome], currentCompany)
      if (status === 200) {
        setFiles(data.files)
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleUploadFile(toastId: string | number, indice: string) {
    toast.update(toastId, { render: 'Upload dos arquivos....', isLoading: true });

    try {
      setIsLoading(true)

      const files = fileList.map(file => {
        return {
          nome: file.name,
          data: file.data
        }
      })

      const objectParams = {
        descricao: UploadDescription[selectedMenu.nome],
        files: files,
        registro: indice,
        indice: indice,
        size: '',
        tabela: UploadTable[selectedMenu.nome],
        type: ''
      }
      const { data, status } = await attachService.upload(currentBranch.codigo, currentCompany, objectParams)

      if ((status === 201 || status === 200) && data.status !== 'erro') {
        setFileList([])
        return toast.update(toastId, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      } else {
        return toast.update(toastId, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
      return toast.update(toastId, { isLoading: false, autoClose: 10 })
    } finally {
      setIsLoading(false)
      onClose(true)
    }

  }

  async function openFile(url: string) {
    const newTab = window.open();
    newTab.document.write('<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    newTab?.document.close();
  }

  async function handleOpenFile(fileName: string) {
    try {
      const indiceEncode = encode(String(selectedRow.indice))
      const fileNameEncode = encode(fileName)
      const { data, status } = await attachService.open(UploadTable[selectedMenu.nome], UploadDescription[selectedMenu.nome], indiceEncode, fileNameEncode)

      if (status === 200 && data.status === "ok") {
        let url = data.data;
        if (data.status === "ok") {
          if (data.data.indexOf("application/PNG") > -1 || data.data.indexOf("application/JPG") > -1 || data.data.indexOf("application/JPEG") > -1) {
            url = data.data.replace("application", "image")
            openFile(url);
          }

          else if (data.data.indexOf("application/PDF") > -1) {
            const blob = utils.base64toBlob(data.data)
            url = URL.createObjectURL(blob);
            openFile(url);
          }

          else {
            const blob = utils.base64toBlob(data.data)
            url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${ fileName }`);
            link.click();
          }

        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg,
        })
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function handleDeleteFile(fileName: string) {
    let id: Id
    try {
      return Swal.fire({
        title: 'Confirmar ação?',
        text: 'Tem certeza que deseja excluir esse arquivo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setDeleteLoading(true)
          const filesArr = files.filter(file => file !== fileName)
          const objectParams = {
            descricao: UploadDescription[selectedMenu.nome],
            files: [{ nome: fileName, data: '' }],
            registro: String(selectedRow.indice),
            indice: String(selectedRow.indice),
            size: '',
            tabela: UploadTable[selectedMenu.nome],
            type: ''
          }
          id = toast.loading("Por favor aguarde...")
          const { data, status } = await attachService.delete(currentBranch.codigo, currentCompany, objectParams)

          if (data.status === 'ok') {
            setFiles(filesArr)
            setDeleteLoading(false)
            return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

          } else {
            setDeleteLoading(false)
            return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
          }
        }
      });
    } catch (error) {
      console.log('error', error)
      toast.update(id, { render: 'Algo deu errado!', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      setDeleteLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const panes = [
    (mode === 'view' || mode === 'edit') && {
      menuItem: 'Lista',
      render: () =>
        <Tab.Pane style={ { boxShadow: '0 0 3px 2px #dedede' } }>
          {
            isLoading ?
              <Skeleton baseColor='#DDD' height={ 50 } width="100%" count={ 11 } />
              :
              <Table celled compact>
                <Table.Header>
                  <Table.Row >
                    <Table.HeaderCell textAlign='center'> Tipo </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Nome </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Ações </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    files.map((file, index) => (
                      <Table.Row key={ file } textAlign='center'>
                        <Table.Cell width='2'>{ file.split('.').pop() }</Table.Cell>
                        <Table.Cell>{ file }</Table.Cell>
                        <Table.Cell style={ { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' } } width='2'>
                          { (deleteLoading && deletedFile === index)

                            ? <Loader className='loadRow' inverted active inline />
                            :
                            <>
                              <SubMenuOptions
                                subMenuSelected={ selectedSubMenu }
                                type="icon"
                                onOptionClick={ (action) => {
                                  if (action === 'delete' && !deleteLoading) {
                                    handleDeleteFile(file);
                                    setDeletedFile(index)
                                  }
                                } }
                              />
                              <Button.Group size='small'>
                                <Popup
                                  trigger={
                                    <ButtonTable
                                      color="#6FCCF1"
                                      onClick={ () => {
                                        handleOpenFile(file)
                                      } }
                                    >
                                      <Eye size={ 20 } color="#6FCCF1" />
                                    </ButtonTable>
                                  }
                                  content={ <span style={ { fontSize: '1.3rem' } }>Visualizar</span> }
                                  inverted
                                />
                              </Button.Group>
                            </>
                          }
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
          }

        </Tab.Pane>
    },
    (mode === 'add' || mode === 'edit') &&{
      menuItem: 'Upload',
      render: () =>
        <Tab.Pane style={ { boxShadow: '0 0 3px 2px #dedede' } }>
          <Content>
            <DropZone
              fileList={ fileList }
              setFileList={ setFileList }
            />
          </Content>
        </Tab.Pane>
    },
  ]
  
  useEffect(() => {
    setFileList([])
    loadFiles()
  }, [selectedRow.indice])

  return (
    <Transition animation='fly up' visible={ visible } unmountOnHide>
      <Modal
        closeIcon
        size='fullscreen'
        onClose={ handleCloseModal }
        closeOnDimmerClick={false}
        open={ visible }
      >
        <Dimmer active={ isLoading || fieldIsLoading } inverted>
          <Loader size="huge" inverted>Carregando</Loader>
        </Dimmer>
        <Modal.Header>
          { mode === 'add' && <span>Criação de </span> }
          { mode === 'edit' && <span>Edição de </span> }
          { mode === 'view' && <span>Visualização de </span> }
          { Title[selectedMenu.nome] }
        </Modal.Header>

        <Modal.Content scrolling>
          <RestField mode={ mode } />

          {
            selectedMenu.submenu.find(submenu => submenu.nome === 'Upload') ? (
              <Tab
                menu={ { secondary: true, pointing: true } }
                panes={ panes }
                activeIndex={ tabSelected === 'Lista' ? 0 : 1 }
                onTabChange={ (event, data) => {
                  console.log(data, data.panes[data.activeIndex].menuItem)
                  setTabSelected(data.panes[data.activeIndex].menuItem)
                } }
              /> 
            ) : null
          }

          
        </Modal.Content>

        <Modal.Actions>
          {
            mode === 'view' &&
            <Button onClick={ () => onClose(false) } size='big'>Cancelar</Button>
          }
          {
            mode !== 'view' &&
            <Button.Group size='big'>
              <Button onClick={ handleCloseModal }>Cancelar</Button>
              <Button.Or text="ou" />
              <Button
                onClick={ () => {
                  if (configuracoes.useconfirmationorder) {
                    return Swal.fire({
                      title: 'Confirmar ação?',
                      text: `Deseja ${ mode === 'add' ? 'incluir' : 'alterar' } este ${ Title[selectedMenu.nome] }`,
                      icon: 'info',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim, confirmar!',
                      cancelButtonText: 'Cancelar'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleCreateOrUpdate();

                      }
                    });
                  } else {
                    handleCreateOrUpdate();
                  }
                } }
                positive
                disabled={ isLoading }
              >
                Salvar
              </Button>
            </Button.Group>
          }
        </Modal.Actions>
      </Modal>
    </Transition>
  );
}

export default FormModal;