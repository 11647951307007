import styled from 'styled-components';

interface DropContainerProps {
  haveFiles: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;


export const DropContainer = styled.div<DropContainerProps>`
  display: flex;
  align-items: ${({ haveFiles }) => haveFiles ? 'flex-start' : 'center'};
  justify-content: center;
  margin: 0;
  width: 100%;
  min-height: 160px;
  border: 0.4rem dashed #4aa1f3;
  padding: 2rem 0;
  overflow-y: auto;

`;

export const DropMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
`;



export const FileRemove = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
  gap: 2rem;
  width: 100%;
`;

