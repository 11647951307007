import React, { createContext, FC, useState } from 'react';

interface IOrderServiceContext {
  orderServiceStyle: 'calendar' | 'list';
  updateOrderServiceStyle(value: 'calendar' | 'list'): void;
}

export const OrderServiceContext = createContext<IOrderServiceContext>({} as IOrderServiceContext);

const OrderServiceContextProvider: FC = ({ children }) => {
  const [orderServiceStyle, setOrderServiceStyle] = useState<'calendar' | 'list'>('list');

  function updateOrderServiceStyle(value: 'calendar' | 'list') {
    setOrderServiceStyle(value);
  }

  return (
    <OrderServiceContext.Provider
      value={{
        orderServiceStyle,
        updateOrderServiceStyle
      }}
    >
      { children }
    </OrderServiceContext.Provider>
  );
}

export default OrderServiceContextProvider;