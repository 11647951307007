import { createSlice } from '@reduxjs/toolkit'
import { FieldTypeConfig, FiltersType, IFilterParams, LegendType, TableData, TableHelps, TableTitle } from '../../@types/Datatable';
import { ITotalizadores } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IDeleteTableFilterStatus, IUpdateIndice, IUpdateMode, IUpdateTableCurrentPage, IUpdateTableData, IUpdateTableFieldFilters, IUpdateTableFieldTypeConfig, IUpdateTableFilter, IUpdateTableHelps, IUpdateTableLegend, IUpdateTableLoading, IUpdateTableOrder, IUpdateTableOrderBy, IUpdateTableSearching, IUpdateTableSearchText, IUpdateTableTitles, IUpdateTableTotalizers, IUpdateTableTotalPages, IUpdateViewCheckoutModal, LoadTableData } from './actions';

export interface DataTableReducerInitialState {
  indice: string | number;
  mode: string;
  tableOrderBy: string
  viewCheckoutModal: boolean;
  tableLoading: boolean;
  tableSearching: boolean;
  tableTitles: TableTitle;
  tableHelps: TableHelps;
  tableOrder: string[];
  tableData: TableData[];
  tableLegend: LegendType[];
  tableFieldTypeConfig: FieldTypeConfig;
  tableFieldFilters: FiltersType;
  tableTotalPages: number;
  tableCurrentPage: number;
  tableTotalizers: ITotalizadores[];
  tableSearchText: string;
  tableFilter: IFilterParams;
}

export const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState: {
    tableLoading: false,
    tableSearching: false,
    viewCheckoutModal: false,
    indice: ('' as string | number),
    mode: 'add',
    tableOrderBy: '',
    tableTitles: ({} as TableTitle),
    tableHelps: ({} as TableHelps),
    tableOrder: ( [] as string[]),
    tableData: ([] as TableData[]),
    tableLegend: ([] as LegendType[]),
    tableFieldTypeConfig: ({} as FieldTypeConfig),
    tableFieldFilters: ({
      character: [],
      date: [],
      numeric: []
    } as FiltersType),
    tableTotalPages: 0,
    tableCurrentPage: 1,
    tableTotalizers: ([] as ITotalizadores[]),
    tableSearchText: '',
    tableFilter: ({ filter: undefined, statusFilter: undefined } as IFilterParams)
  },
  
  reducers: {
    resetDataTable: (state) => {
      state.tableSearchText = '';
      state.tableCurrentPage = 1;
      state.tableFilter = ({ filter: undefined, statusFilter: undefined } as IFilterParams);
      state.tableOrderBy = '';
      state.tableData = [];
      state.tableHelps = {}
    },

    updateViewCheckoutModal: (state, action: IUpdateViewCheckoutModal) => {
      state.viewCheckoutModal = action.payload.value
    },

    updateDataTableIndice: (state, action: IUpdateIndice) => {   
      state.indice = action.payload.value;
    },

    updateTableOrderBy: (state, action: IUpdateTableOrderBy) => {
      state.tableOrderBy = action.payload.value;
    },

    updateMode: (state, action: IUpdateMode) => {
      state.mode = action.payload.value;
    },

    updateTableLoading: (state, action: IUpdateTableLoading) => {
      state.tableLoading = action.payload.value;
    },

    updateTableSearching: (state, action: IUpdateTableSearching) => {
      state.tableSearching = action.payload.value;
    },

    updateTableTitles: (state, action: IUpdateTableTitles) => {
      state.tableTitles = action.payload.value;
    },

    updateTableHelps: (state, action: IUpdateTableHelps) => {
      state.tableHelps = action.payload.value;
    },

    updateTableOrder: (state, action: IUpdateTableOrder) => {
      state.tableOrder = action.payload.value;
    },

    updateTableData: (state, action: IUpdateTableData) => {
      state.tableData = action.payload.value;
    },

    updateTableLegend: (state, action: IUpdateTableLegend) => {
      state.tableLegend = action.payload.value;
    },

    updateTableFieldTypeConfig: (state, action: IUpdateTableFieldTypeConfig) => {
      state.tableFieldTypeConfig = action.payload.value;
    },

    updateTableFieldFilters: (state, action: IUpdateTableFieldFilters) => {
      state.tableFieldFilters = action.payload.value;
    },

    updateTableTotalPages: (state, action: IUpdateTableTotalPages) => {
      state.tableTotalPages = action.payload.value;
    },

    updateTableCurrentPage: (state, action: IUpdateTableCurrentPage) => {
      state.tableCurrentPage = action.payload.value;
    },

    updateTableTotalizers: (state, action: IUpdateTableTotalizers) => {
      state.tableTotalizers = action.payload.value;
    },

    updateTableSearchText: (state, action: IUpdateTableSearchText) => {
      state.tableSearchText = action.payload.value;
    },

    updateTableFilter: (state, action: IUpdateTableFilter) => {
      state.tableFilter = action.payload.value;
    },

    deleteTableStatusFilter: (state, action: IDeleteTableFilterStatus) => {
      const statusFilter = state.tableFilter.statusFilter
      delete statusFilter[action.payload.campo]
    },

    loadTableData: (state, action: LoadTableData) => {
      state.tableOrder = action.payload.tableOrder;
      state.tableTitles = action.payload.tableTitles;
      state.tableHelps = action.payload.tableHelps ? action.payload.tableHelps : {};
      state.tableData = action.payload.tableData;
      state.tableLegend = action.payload.tableLegend;
      state.tableFieldFilters = action.payload.tableFieldFilters;
      state.tableTotalPages = action.payload.tableTotalPages;
      state.tableFieldTypeConfig = action.payload.tableFieldTypeConfig;
      state.tableTotalizers = action.payload.tableTotalizadores ? action.payload.tableTotalizadores : [] as ITotalizadores[]
    }
  }
})

export const {
  updateTableCurrentPage, updateTableData, updateTableFieldFilters, updateTableOrder, updateTableSearching,
  updateTableFieldTypeConfig, updateTableLegend, updateTableLoading, updateTableSearchText, updateViewCheckoutModal,
  updateTableFilter, updateTableTitles, updateTableTotalPages, updateTableTotalizers, loadTableData,
  deleteTableStatusFilter, updateDataTableIndice, updateMode, updateTableOrderBy, resetDataTable, updateTableHelps
} = dataTableSlice.actions;
export default dataTableSlice.reducer; 