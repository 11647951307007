import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

export const BrowserContainer = styled.div`
  padding-left: 1rem;
  padding-top: 2rem;
  height: 100%;
`;

export const HeaderContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.mainFont};//${styleSheet.mainFont};
  color: #696969;
`;

export const HeaderTitle = styled.span`
  text-transform: capitalize; 
  margin-left: 1rem;
  font-size: 2.2rem;
`;

export const SelectedContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
  align-items: center;
  margin-right: 0;
`;

export const SelectedContent = styled.div`
  display: flex;
  margin-right: 2rem;
  align-items: center;
`;

export const Selected = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  line-height: 1.5rem;
`;


export const SelectedTitle = styled.div`
  color: ${({ theme }) => theme.mainColor};// ${styleSheet.contrastColor};
  font-size: 1.5rem;
`;

export const SelectedText = styled.div`
  font-size: 1.2rem;
  color: #696969;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;



export const BrowserMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ButtonGoBack = styled.button`
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  background-color: ${({ theme }) => theme.contrastColor}; //${styleSheet.contrastColor};
  border: 0;
  margin-left: 1rem;
  cursor: pointer;
`;