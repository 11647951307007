import React, { FC, useEffect, useState } from 'react';
import { Input, Modal, Table } from 'semantic-ui-react';

interface IModalF3Props {
  title: string;
  visible: boolean;
  columns: F3Column[];
  data: F3Data[][];
  onSelect(selectedRow: F3Data[]): void;
  onClose(): void;
}

const ModalF3: FC<IModalF3Props> = ({ title, visible, columns, data, onSelect, onClose }) => {

  const [ search, setSearch ] = useState('');

  const dataFiltered = data.map(dado => {
    const contain = dado.map(item => {
      if(String(item.conteudo).toUpperCase().includes(search.toUpperCase())){
        return true;
      } else {
        return false;
      }
    })

    if(contain.includes(true)){
      return dado;
    }
  }).filter(dado => dado !== undefined)

  return (
    <Modal dimmer='blurring' open={ visible } onClose={ onClose } closeIcon>
      <Modal.Header>Busca de { title }</Modal.Header>

      <Modal.Content scrolling>
        <Input 
          fluid 
          icon='search'
          value={ search }
          placeholder={ `Procurar por ${ title.toLowerCase() }` } 
          onChange={(event) => setSearch(event.target.value)}
        />

        <Table color='purple' selectable singleLine>
          <Table.Header>
            <Table.Row>
              {
                columns.map(({ nome, titulo }) => {
                  return (
                    <Table.HeaderCell 
                      key={ nome }
                    >
                      <span style={{ fontSize: '1.3rem' }}>
                        { titulo }
                      </span>
                    </Table.HeaderCell>
                  );
                })
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
               dataFiltered.map((row, index) => {
                return (
                  
                  <Table.Row 
                    style={{ cursor: 'pointer' }} 
                    key={ index }
                    onClick={ 
                      () => { 
                        onSelect(row);
                        onClose(); 
                      } 
                    }
                  >
                    {
                      row.map(({ nome, conteudo }) => {
                        return <Table.Cell key={ nome }>{ conteudo }</Table.Cell>
                      })
                    }
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}

export default ModalF3;