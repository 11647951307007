import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.darkerGrey};
  display: inline-block;
  text-align: left;
`;

export const LabelText = styled.span`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey};
`;

export const Mandatory = styled.span`
  color: ${({ theme }) => theme.dangerColor}; //${styleSheet.dangerColor};
  font-size: 2rem;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const InputRadio = styled(Form.Radio)`
  .ui.radio.checkbox input:checked~.box:after, .ui.radio.checkbox input:checked~label:after {
    background-color: ${({ theme }) => theme.mainColor};
  }
`;