import { Header, Modal, Button, Table, Dimmer, Loader, Icon, Popup } from 'semantic-ui-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';
import { Trash } from 'phosphor-react';


import { ProjectService } from '../../services/methods/ProjectService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import DatatableField from '../Datatable/DatatableField';
import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { TableData, TableTitle } from '../../@types/Datatable';


import { fillFieldAndFieldValues, RestFieldReducerInitialState } from '../../reducers/RestField';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { styleSheet } from '../../assets/styles/global';

import { ButtonTable, HeaderTable, TableColumnText, TableDataText, Title } from './styles';
import { toast } from 'react-toastify';

enum Alias {
  Empresas = 'SA1',
  Usuarios = 'PW0',
  Projetos = 'SZ2'
}

export interface IViewExpenseModalProps {
  indice: string | number;
  code: string | number;
  visible: boolean;
  mode: string;
  onOpenExpenseModal(): void;
  onClose(reload: boolean): void;
}

const ViewExpenseModal: React.FC<IViewExpenseModalProps> = ({ indice, code, mode, visible, onClose, onOpenExpenseModal }) => {
  const fieldService: FieldService = new FieldService();
  const projectService: ProjectService = new ProjectService();
  
	const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

	const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<TableTitle>({} as TableTitle);
  const [order, setOrder] = useState<string[]>([]);
  const [data, setData] = useState<TableData[]>([]);

  async function getProjectFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: 'SZ2',
        alias2: '',
        url: '',
        tab: '1',
        usuario: '',
        indice: String(indice),
        ordem: 1,
        token: token,
      }
  
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        dispatch(fillFieldAndFieldValues({ fields: data.campos }))
        await getProjectDetails()
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getProjectDetails() {
    try {
      const response = await projectService.details(code)
      const { dados, ordem, titulo } = response.data;
      if (response.status === 200) {
        setTitle(titulo)
        setOrder(ordem)
        setData(dados)
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleCreateOrUpdate() {
    try {
      setIsLoading(true)
      if (mode === 'add') {
        const objectParams = {
          alias: Alias[selectedMenu.nome],
          alias2: '',
          email: token,
          dados: fieldValues,
          indice: indice,
          ordem: "",
          url: "demo.ethosx.com.br"//window.location.href.split('/')[2]
        }
  
        const { data, status, request } = await fieldService.create(currentBranch.codigo, currentCompany, objectParams)
  
        if (data.status === 'ok') {
          onClose(true)
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
          console.log(data.msg)
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.msg,
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      } else {
        const notChangedFields = {};
  
        fields.map(field => Object.assign(notChangedFields, { [field.nome]: field.conteudo }));
  
        const restFieldData = {
          ...notChangedFields,
          ...fieldValues
        }
        const objectParams = {
          alias: Alias[selectedMenu.nome],
          alias2: '',
          email: token,
          dados: restFieldData,
          indice: indice ? indice : "",
          ordem: "&$&1",
          url: "demo.ethosx.com.br"//window.location.href.split('/')[2]
        }
        const { data, status, request } = await fieldService.update(currentBranch.codigo, currentCompany, objectParams)
  
        if (data.status === 'ok') {
          onClose(true)
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
          console.log(data.msg)
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.msg,
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleDeleteExpense(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")

    try {
      const response = await projectService.deleteExpense(indice, currentCompany.toString(), currentBranch.codigo,  token)
      if(response.status === 200 || response.data.status === 'ok') {
        const tableDataArr = [...data];
        const newTableData = tableDataArr.filter(item => item.indice !== indice);
        setData(newTableData)
        toast.update(id, {render: response.data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
        return Swal.fire({
          title: 'Sucesso!',
          text: response.data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else {
        toast.update(id, {render: response.data.errorMessage || response.data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
        return Swal.fire({
          title: 'Ops!',
          text: response.data.errorMessage || response.data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      console.log('error', error)
      return toast.update(id, {render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getProjectFields()
  }, [indice])

  return (
    <Modal
      onClose={() => !isLoading && onClose(false)}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Modal.Header>
        Visualização de Projetos
      </Modal.Header>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Content scrolling>
        <RestField  mode={mode} />

        <HeaderTable>
          <Title>Detalhes despesas</Title>
          <Button icon labelPosition='right' positive size="medium" onClick={() => {
            onOpenExpenseModal()
          }}>
            Incluir Despesa
            <Icon name='plus' />
          </Button>
        </HeaderTable>

        <Table size="large" celled>
          <Table.Header>
            <Table.Row>
              {
                order.map((column) => {
                  return (
                    <Table.HeaderCell
                      key={column}
                    >
                      <TableColumnText>
                        {title[column]}
                      </TableColumnText>
                    </Table.HeaderCell>
                  );
                })
              }
              <Table.HeaderCell className='dataHeaderTableFixedActions' key='actions'><TableColumnText>Ações</TableColumnText></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              data.map((row, index) => {
                return (
                  <Table.Row key={index} style={{ cursor: 'pointer' }}>
                    {
                      order.map((columnName) => {
                        return (
                          <Table.Cell>
                            {
                              <TableDataText>
                                <DatatableField
                                  fieldName={columnName}
                                  fieldValue={row[columnName.toLowerCase()]}
                                />
                              </TableDataText>
                            }
                          </Table.Cell>
                        );
                      })
                    }
                    <Table.Cell className='dataTableFixed' width={1} key="actions">
                      <Button.Group size='small'>
                        <Popup
                          trigger={
                            <ButtonTable 
                              onClick={() => {
                                handleDeleteExpense(row.indice)
                              }}
                            >
                            <Trash size={18} color="#E47261" />
                            </ButtonTable>
                          }
                          content={<span style={{ fontSize: '1.3rem' }}>Excluir</span>}
                          inverted
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Modal.Content>



      <Modal.Actions>
        {
          mode === 'view' &&
          <Button onClick={() => onClose(false)} size='big'>Cancelar</Button>
        }
        {
          mode !== 'view' &&
          <Button.Group size='big'>
            <Button onClick={() => onClose(false)}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button
              onClick={handleCreateOrUpdate}
              positive
              disabled={isLoading}
            >
              Salvar
            </Button>
          </Button.Group>
        }

      </Modal.Actions>
    </Modal>
  );
}

export default ViewExpenseModal;