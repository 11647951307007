import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Person } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import FilterModal, { IFilter } from '../../components/FilterModal';
import ApprovalModal from '../../components/ApprovalModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { UIContext } from '../../contexts/UIContext';

import { ApprovalsService } from '../../services/methods/ApprovalsService';


import { DataTableReducerInitialState, loadTableData, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';


import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';

import { Utils } from '../../common/Utils';
import { styleSheet } from '../../assets/styles/global';
import { BrowserContent, Content, PageDefault } from './styles';
import { ClientService } from '../../services/methods/ClientService';
import { debounce } from "../../common/debounce";
import { Id, toast } from 'react-toastify';

const Approvals: FC = () => {

  const approvalsService: ApprovalsService = new ApprovalsService();

  const { tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableSearchText, tableFilter, tableOrderBy, indice, mode } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  
  const dispatch = useDispatch();

  const utils = new Utils();

  const { setUIComponentState } = useContext(UIContext);


  const [filterModal, setFilterModal] = useState(false);
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [viewItensModal, setViewItensModal] = useState(false);
  const [selectedIndice, setSelectedIndice] = useState<string | number>('')

  async function getApprovalList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }))
      dispatch(updateTableLoading({ value: true }))

      const response = await approvalsService.index(currentBranch.codigo, page, itensPerPage, token, order, currentCompany, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados); 
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getApprovalsWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));

      dispatch(updateTableSearchText({ value: searchValue }));
      await getApprovalList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));

      await getApprovalList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function handleApprovedOrRefused(indice: string | number, type: 'A' | 'R') {
    let id: Id
    try {
      return Swal.fire({
        title: 'Confirmar ação?',
        text: `Realmente deseja ${type === 'A' ? 'Aprovar' : 'Reprovar'} esse orçamento?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          id = toast.loading("Por favor aguarde...")
          const { data, status } = await approvalsService.approvedOrRefused(currentBranch.codigo, token, currentCompany, indice, type)
          if (status === 200 || status === 201) { 
            getApprovalList(tableCurrentPage, tableOrderBy, undefined, tableFilter.statusFilter);
            return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          } else {
            return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
          }
        }
      }).catch((error) => {
        console.log('error', error)
        toast.update(id, { render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado!',
        })
      });
    } catch (error) {
      console.log('error', error)
      toast.update(id, { render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
    
  }


  async function onViewApprovalClick(indice: string | number) {
    setViewItensModal(true);
    // await getClientFields(indice);
  }

  async function filterApproval(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }))
    await getApprovalList(1, tableOrderBy, filter, statusFilter)
  }

  async function orderApproval(order: string) {
    dispatch(updateTableOrderBy({ value: order }))
    await getApprovalList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  useEffect(() => {
    getApprovalList(tableCurrentPage, tableOrderBy, undefined, tableFilter.statusFilter);
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<Person size={24} color={styleSheet.mainColor} />}>
        <BrowserContent>
          <Datatable
            serviceName="wsrestorccli"
            onItensPerPageChange={() => getApprovalList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
            onPageChange={(page) => getApprovalList(page, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
            onOrderBy={(value) => orderApproval(value)}
            onStatusFilter={(status: { [key: string]: string } | undefined) => filterApproval(tableFilter.filter, status)}
            onDatatableActionClick={(action, indice) => {
              setSelectedIndice(indice)
              if (action === 'view') onViewApprovalClick(indice);
              if (action === 'filter') setFilterModal(true)
              if (action === 'authorize') handleApprovedOrRefused(indice, 'A')
              if (action === 'refuse') handleApprovedOrRefused(indice, 'R')

            }}
            onSearch={ (value, noDebounce) => getApprovalsWithTextSearch(noDebounce ? 'enter' : '', value) }
          />
        </BrowserContent>
        
        </Browser>
      </Content>

      <Footer />

      {
        viewItensModal &&
          <ApprovalModal
            indice={selectedIndice}
            onClose={() => setViewItensModal(false)}
            visible={viewItensModal}
            mode="view"
          />
      }

      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterApproval}
        onClose={() => {
          setFilterModal(false)
        }}
      />




    </PageDefault>
  );
}

export default Approvals;