import React, { FC, useCallback, useEffect, useState } from 'react';
import { House } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select, Tab } from 'semantic-ui-react';

import 'react-loading-skeleton/dist/skeleton.css'

import { LogsService } from '../../services/methods/LogsService';

import DefaultHeader from '../../components/DefaultHeader';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { ILoginLogsChart, IOrderLogsChar, IPageLogsChart } from '../../interfaces/Logs/ILogin';

import { PolicieReducerInitialState } from '../../reducers/Policie';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';

import {  BrowserContent, Content, FormGroup, PageDefault } from './styles';
import { LogsReducerInitialState, updateAccessLogData, updateChartOrderByClientData, updateLogIsLoading, updateOrderLogData, updatePageLogData } from "../../reducers/Logs";
import AccessLogs from "./Dashboards/AccessLogs";
import PageLogs from "./Dashboards/PageLogs";
import OrderLogs from "./Dashboards/OrdersLogs";
import { SettingsService } from "../../services/methods/SettingsService";
import moment from "moment";
import ActiveClients from "./Dashboards/ActiveClients";
import ClientValues from "./Dashboards/ClientsValues";

const Logs: FC = () => {
  const logsService: LogsService = new LogsService();
  const settingsService: SettingsService = new SettingsService();

  const {  companyName, authToken, companyEnvironment  } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const {  logIsLoading } = useSelector((state: { logs: LogsReducerInitialState }) => state.logs);

  const dispatch = useDispatch()

  const [clients, setClients] = useState<Array<{ key: string, value: string, text: string }>>([])
  const [selectedClient, setSelectedClient] = useState('Ethosx')

  const [startDate, setStartDate] = useState<string>(moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'))

  const createFilters = useCallback((useClients?: boolean) => {
    return (
      <Form>
        <FormGroup>
          {
            companyName === 'Ethosx' && useClients ? (
              <Form.Field>
                Cliente
                <Select 
                  floating
                  style={{  height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
                  placeholder='Selecione empresa' 
                  options={clients} 
                  defaultValue={selectedClient}
                  onChange={(ev, data) => {
                    setSelectedClient(String(data.value))
                  }}
                />
              </Form.Field> 
            ) : null
          }
          <Form.Field>
            De
            <Input
              style={{ height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
              className='inputFilter'
              type="date"
              max={endDate}
              onChange={e => setStartDate(moment(e.target.value).format('YYYY-MM-DD'))}
              defaultValue={startDate}
            />
          </Form.Field>
          <Form.Field>
            Até
            <Input
              style={{  height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
              className='inputFilter'
              type="date"
              min={startDate}
              max={moment().format('YYYY-MM-DD')}
              onChange={e => setEndDate(moment(e.target.value).format('YYYY-MM-DD'))}
              defaultValue={endDate}
            />
          </Form.Field>

          <Button 
            style={{ height: '4rem', fontSize: '1.3rem', marginBottom: '.8em', lineHeight: '1.9rem', alignSelf: 'flex-end' }} 
            size="big" 
            onClick={() => loadingData(companyName === 'Ethosx' ? selectedClient : companyName, startDate, endDate)} 
            positive
            loading={logIsLoading}
            disabled={logIsLoading}
          >
            Filtrar
          </Button>
        </FormGroup>
      </Form>
    )
  }, [clients, selectedClient, startDate, endDate, logIsLoading])

  async function getClients() {
    try {
      const { data } = await settingsService.getCompanies(authToken) 

      const clientsArray = data.data.filter(client => client.environment === companyEnvironment).map(client => {
        return {
          key: client.name,
          text: client.name,
          value: client.name
        }
      })
   
      setClients([...clientsArray, { key: 'Todos', text: 'Todos', value: '' }])
   
    } catch (error) {
      console.log(error)
    } 
  }

  const panes = [
   {
      menuItem: `Logs de Acesso`,
      render: () =>
        <Tab.Pane>
          {createFilters(true)}
          <AccessLogs 
              keys={['client', 'user', 'status', 'environment', 'device', 'message', 'createdAt']} 
              types={['C', 'C', 'C', 'C', 'C', 'C', 'C']}  
            />
        </Tab.Pane>
    },
    {
      menuItem: `Logs de Páginas`,
      render: () =>
        <Tab.Pane>
          {createFilters(true)}
          <PageLogs 
            keys={['client', 'user', 'page', 'environment', 'device', 'requesterIp', 'createdAt']} 
            types={['C', 'C', 'C', 'C', 'C', 'C', 'C']}
          />          
        </Tab.Pane>
    },
    {
      menuItem: `Logs de Pedidos`,
      render: () =>
        <Tab.Pane>
          {createFilters(true)}
          <OrderLogs 
            keys={['client', 'user', 'type', 'orderNumber', 'totalValue', 'itemsQuantity', 'createdAt']} 
            types={['C', 'C', 'C', 'C', 'C', 'C', 'C']}
          />
        </Tab.Pane>
    },
    companyName === 'Ethosx' && {
      menuItem: `Clientes mais ativos`,
      render: () =>
        <Tab.Pane>
          {createFilters(true)}
          <ActiveClients />
        </Tab.Pane>
    },
    companyName === 'Ethosx' && {
      menuItem: `Valores/Clientes`,
      render: () =>
        <Tab.Pane>
          {createFilters(false)}
          <ClientValues />
        </Tab.Pane>
    }
  ]
  
  async function loadAccessLogs(companyName: string, startDate?: string, endDate?: string) {
    try {
      const { data } = await logsService.getAccessLogs(companyName, startDate, endDate) 
   
      dispatch(updateAccessLogData({ value: data }))
   
    } catch (error) {
      console.log(error)
    } 
  }

  async function loadPageLogs(companyName: string, startDate?: string, endDate?: string) {
    try {
      const {  data } = await logsService.getAllPageLogs(companyName, startDate, endDate) 
      
      dispatch(updatePageLogData({ value: data }))
    } catch (error) {
      console.log(error)
    } 
  }

  async function loadOrderLogs(companyName: string, startDate?: string, endDate?: string) {
    try {
      const {  data } = await logsService.getOrderLogs(companyName, startDate, endDate) 
      dispatch(updateOrderLogData({ value: data }))
    } catch (error) {
      console.log(error)
    } 
  }

  async function loadOrdersGroupedByClient(startDate?: string, endDate?: string) {
    try {
      const { data } = await logsService.getOrdersValuesGroupedByClient(startDate, endDate) 

      dispatch(updateChartOrderByClientData({ value: data }))

    } catch (error) {
      console.log(error)
    }
  }

  async function loadOrdersGroupedByUsers() {
    try {
      await logsService.getOrdersValuesGroupedByUsers(companyName) 
      
    } catch (error) {
      console.log(error)
    } 
  }

  const handleTabChange = async () => {
    dispatch(updateLogIsLoading({ value: true }))
    const timer = setTimeout(() => {
      dispatch(updateLogIsLoading({ value: false }))
    }, 1000);
    return () => clearTimeout(timer);
  }

  async function loadingData(companyName: string, startDate: string, endDate: string) {
    dispatch(updateLogIsLoading({ value: true }))
    dispatch(updateAccessLogData({ value: [] }))
    await Promise.all([
      loadAccessLogs(companyName, startDate, endDate),
      loadPageLogs(companyName, startDate, endDate),
      loadOrderLogs(companyName, startDate, endDate),
      loadOrdersGroupedByClient(startDate, endDate),
      loadOrdersGroupedByUsers(),
    ])

    setTimeout(() => {
      dispatch(updateLogIsLoading({ value: false }))
    }, 2000);
  }


  useEffect(() => {
    if (companyName === 'Ethosx') {
      getClients()
    } 

    loadingData(companyName, startDate, endDate)
  }, [])


  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<House size={24} color={styleSheet.mainColor} />} >
          <BrowserContent>
            <Tab style={{ width: '100%' }} onTabChange={handleTabChange} panes={panes} />
          </BrowserContent>
        </Browser>
      </Content>
      <Footer />
    </PageDefault>
  );
}

export default Logs;