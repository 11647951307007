import { AxiosResponse } from "axios";
import { ICreateSchedule } from "../../interfaces/Scheludes/ISchedules";
import protheusProvider from "../providers/protheus.provider";

export class SchedulesService {
  public async getAnalyst(token: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`WSANALISTAPL`, {
      params: {
        token
      }
    });
    return response;
  }

  public async getProjects(token: string, client: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`WSPROJPL`, {
      params: {
        token,
        client
      }
    });
    return response;
  }

  public async getClients(token: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`WSCLIENTPL`, {
      params: {
        token
      }
    });
    return response;
  }

  public async getSchedules(month: number, year: number, analista: string, token: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`WSAGENDAPL`, {
      params: {
        token: token,
        month: month,
        year: year,
        period: true,
        analista: analista,
      }
    });
    return response;
  }

  public async createSchedules(objectParam: ICreateSchedule): Promise<AxiosResponse> {
    const response = await protheusProvider.post(`WSAGENDAPL`,  objectParam );
    return response;
  }

  public async updateSchedules(objectParam: ICreateSchedule): Promise<AxiosResponse> {
    const response = await protheusProvider.put(`WSAGENDAPL`,  objectParam );
    return response;
  }

  public async deleteSchedules(keys: number[], token: string): Promise<AxiosResponse> {
    const response = await protheusProvider.delete(`WSAGENDAPL`,  {
      data: {
        data: keys.map(key => { return { id: key } }),
        token
      }
    } );
    return response;
  }
}