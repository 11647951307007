import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { CartReducerInitialState, updateProductDetail } from "../../reducers/Cart";
import defaultProductImg from '../../assets/images/default-product.jpeg';
import { Image } from "./styles";
import { PolicieReducerInitialState } from "../../reducers/Policie";

const ProductDetailModal: FC = () => {
  const { productDetail } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const useTitleSettingsInProductDetail = companyPolicies.find(police => police.property === 'useTitleSettingsInProductDetail' && police.policy_value === 'true');

  const dispatch = useDispatch();

  return (
    <Modal
      onClose={() => dispatch(updateProductDetail({ value: { visible: false, image: '' } }))}
      open={productDetail.visible}
      closeIcon
      style={{ maxHeight: '85%' }}
      size="large"
    >
      <Modal.Header>
        {
          useTitleSettingsInProductDetail 
            && companyPolicies.find(policy => policy.policy === useTitleSettingsInProductDetail.dependencie).policy_value.split(';')[0] 
            ? companyPolicies.find(policy => policy.policy === useTitleSettingsInProductDetail.dependencie).policy_value.split(';')[0] 
            : 'Ficha técnica'
        }
      </Modal.Header>
      <Modal.Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}  image scrolling>
        <img 
          style={{ minHeight: '200px', maxWidth: '100%', maxHeight: '100%', width: productDetail.description ? '50%' : 'auto' }}
          src={productDetail.image} 
          // centered={!productDetail.description}
          // wrapped={!!productDetail.description}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = defaultProductImg;
          }}
        />
        {
          productDetail.description && (
            <Modal.Description style={{ width: '50%', fontSize: '2rem' }}>
              {
                productDetail.description.map(productDescription => (
                  <p style={{ wordWrap: 'break-word' }} key={Object.keys(productDescription)[0]}><strong>{Object.keys(productDescription)[0]}</strong>: {productDescription[Object.keys(productDescription)[0]]}</p>
                ))
              }
            </Modal.Description>
          )
        }
      </Modal.Content>
      {/* <Modal.Actions>
        <Button color='black' onClick={() => dispatch(updateProductDetail({ value: { visible: false, image: '' } }))}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => dispatch(updateProductDetail({ value: { visible: false, image: '' } }))}
          positive
        />
      </Modal.Actions> */}
    </Modal>
  )
}

export default ProductDetailModal;