import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Header, Modal, Button, Table, Dimmer, Loader, Label, Form, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import { styleSheet } from '../../assets/styles/global';
import { IFile } from '../../interfaces/Attach/Attach';
import { OrderService } from '../../services/methods/OrderService';
import { Utils } from '../../common/Utils';
import { MenuReducerInitialState, updateSelectedMenu } from "../../reducers/Menu";
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { clearCart } from "../../reducers/Cart";
import { AuthReducerInitialState } from '../../reducers/Auth';

import { CartContext } from "../../contexts/CartContext";
import { clearFieldValues } from "../../reducers/RestField";
import { PolicieReducerInitialState } from "../../reducers/Policie";
import { bucketURL } from "../../common/Constants";

export interface IImportCsvModalModal {
  visible: boolean;
  onClose(): void;
}

type InconsistList = {
  descr: string;
  info: string;
  item: string;
  produto: string;
}

const HeaderNames = {
  'codigo': 'B1_COD',
  'código': 'B1_COD',
  'quantidade': 'quantity',
  'desconto': 'discount',
  'acrescimo': 'increase',
  'acréscimo': 'increase'
}

const ImportCsvModal: FC<IImportCsvModalModal> =({ visible, onClose }) =>{
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { menuList, selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyEnvironment, companyFiles, companyName } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);


  const [_, setCookie] = useCookies(['selected_menu']);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { updateIsImported, updateImportedData } = useContext(CartContext)

  const [checkboxValue, setCheckboxValue] = useState('');

  const options = useMemo(() => selectedMenu.submenu.find(submenu => submenu.nome === 'importToCart')?.opcoes, [selectedMenu])
  const menuToNavigate = useMemo(() => {
    if (selectedMenu.nome !== 'Pedidos' && selectedMenu.nome !== 'orcamentos' && options?.length > 0) {
      const menuName = checkboxValue === 'P' ? 'Pedidos' : 'orcamentos'
      return menuList.find(menu => menu.nome === menuName)
    } else {
      return selectedMenu
    }
    
  }, [selectedMenu, checkboxValue, options])
  
  const [jsonData, setJsonData] = useState<Array<{ [key: string]: string }>>([]);
  const [ newFile, setNewFile ] = useState<IFile>({} as IFile);
  const [ newFileBase64, setNewFileBase64 ] = useState('');
  const [ isFileSelected, setIsFileSelected ] = useState(false);
  const [ csvModalState, setCsvModalState ]= useState(false);
  const [ openModal, setOpenModal ]= useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ fastOrderList, setFastOrderList ] = useState<InconsistList[]>([]);
  const inputFile = useRef<HTMLInputElement>(null);
  const orderService: OrderService = new OrderService();
  const utils = new Utils();


  const handleFileSelected = async (e) => {
    dispatch(clearCart());
    dispatch(clearFieldValues());

    if (selectedMenu.nome === 'WsPedRap') {
      const files = Array.prototype.slice.call(e.target.files);
      const attach = e.target.files[0];
      const data = await utils.getBase64(files[0]);
      setNewFile(attach);
      setIsFileSelected(true);
      setNewFileBase64(data.toString());
    } else {
      const file = e.target.files?.[0];
      setNewFile( e.target.files[0]);

    if (file) {
      const reader = new FileReader();

      let isFileValid = true

      reader.onload = (e) => {
        if (e.target) {
          const arrayBuffer = e.target.result as ArrayBuffer;
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { type: 'array', raw: true });
    
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
 
          const csvData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: '' }) as Array<Array<any>>;

          const headers = csvData[0]
          if ((headers[0] !== 'codigo' && headers[0] !== 'código') || headers[1].replace('\r', '') !== 'quantidade') {
            inputFile.current.value = ""
            isFileValid = false
            return  Swal.fire({
              icon: 'error',
              title: 'Arquivo errado',
              text: 'Arquivo importado com formato errado faça o download do template e preencha os valores',
            })
          }

          const jsonArray = csvData.slice(1).map((row) => {
            const obj: { [key: string]: string } = {};
           
            headers.forEach((header, index) => {
              if (header.trim() === '' || !row[index]) return
              obj[HeaderNames[header.trim()]] = typeof row[index] === 'string' ?  row[index].replace('\r', '') :  row[index];
            });

            return obj;
          }).filter((item) => Object.keys(item).length > 0);

          const result = jsonArray
            .reduce((acc: Record<string, { quantity: number, discount: number, increase: number }>, item) => {
              const cod = item["B1_COD"];
              const quantity = parseInt(item["quantity"]);
              const discount = parseFloat(item["discount"]) || 0;
              const increase = parseFloat(item["increase"]) || 0;
            
              acc[cod] = {
                quantity: (acc[cod]?.quantity || 0) + quantity,
                discount: (acc[cod]?.discount || 0) + discount,
                increase: (acc[cod]?.increase || 0) + increase
              };
            
              return acc;
            }, {});
          
          const resultadoFinal = Object.keys(result).map(cod => ({
            "B1_COD": cod,
            "quantity": result[cod].quantity.toString(),
            "discount": result[cod].discount.toString(),
            "increase": result[cod].increase.toString()
          }));

          updateImportedData(resultadoFinal)
          setIsFileSelected(true);
        }
      };
      
      reader.readAsArrayBuffer(file);
    }
    }
  }
  
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    
    setIsLoading(true);
    try {
      const response = await orderService.postFastOrder(currentBranch.codigo, currentCompany, token, newFileBase64, newFile.name, '0');
      const data = response.data;
      
      if(data.status === 'erro'){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg,
        })
      } else {
        setNewFile({...newFile, name: data.fileName});
        setFastOrderList(data.inconsistList);
        setOpenModal(true);
        setCsvModalState(true);
      }
    } catch (error) {
        console.log("Errei?");
    } finally {
      setIsLoading(false);
    }
  }

  const handleOnCloseModal = () => {
    onClose();
    setCsvModalState(false); 
    setIsFileSelected(false);
    if (selectedMenu.nome === 'WsPedRap' || selectedMenu.nome === 'importToCart') {
      dispatch(updateSelectedMenu({ value: menuList[0] }))
      navigate(`/${ menuList[0].nome.toLowerCase() }`);
    }
  }

  const handleConfirmationFastOrder = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try{
      const response = await orderService.confirmationFastOrder(currentBranch.codigo, currentCompany, token, newFile.name , '1');
      const data = response.data;
      
      if( data.status === 'ok') {
        handleOnCloseModal();
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: data.msg,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg,
       })
      }
    } catch (error) {
     console.log('error', error)
     Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
     })

    } finally {
      setIsLoading(false);
    }
  }

  const handleCancelationFastOrder = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try{

      const response = await orderService.cleanFastOrder(currentBranch.codigo, currentCompany, token, newFile.name , '2');
      const data = response.data;

      if( data.status === 'canc') {
        handleOnCloseModal();
        Swal.fire({
          icon: 'success',
          title: 'Arquivo deletado',
          text: data.msg,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg,
       })
      }

    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  }

  const handleExportTemplate = () => {
    const a = document.createElement('a')
    a.href = `${bucketURL}/${companyFiles.includes('template.csv') ? companyEnvironment : 'prod'}/${ companyFiles.includes('template.csv') ? companyName : 'Ethosx'}/assets/template.csv`
    a.download = 'template.csv'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  useEffect(() => {
    if (options && options.length > 1) {
      setCheckboxValue(options[0].tipo)
    }
  }, [options])
  
  return(
    <>
      <Modal
        closeIcon
        open={visible}
        onClose={handleOnCloseModal}
        size='tiny'
        style={{fontFamily: styleSheet.mainFont}}
        closeOnDimmerClick={false} 
      >
        <Dimmer active={isLoading} inverted>
          <Loader size="huge" inverted>Carregando</Loader>
        </Dimmer>
        <Header content='Pedido Rápido' style={{fontSize: '1.5rem'}}/>
        <Modal.Content>
          <p style={{fontSize: '1.3rem'}}>
            Favor inserir o arquivo CSV abaixo:
          </p>
          <input
            ref={inputFile}
            type='file'
            accept='.csv, .xlsx'
            style={{ display: 'none', color: styleSheet.mainColor, background: styleSheet.mainColor }}
            onChange={e => handleFileSelected(e)}

          />
          <Button
            onClick={() => inputFile.current.click()}
          >
            Pesquisar...  
          </Button>     
          <span style={{marginLeft: '1rem', fontSize: '1.2rem'}}>
            {isFileSelected ? newFile.name : 'Nenhum arquivo foi escolhido...'}
          </span>

          <Form style={{ marginTop: '15px' }}>
            {
              options && options.length > 0 && 
                options.map(option => (
                  <Form.Field>
                    <Checkbox
                      style={{ fontSize: '14px' }}
                      radio
                      label={option.descricao}
                      value={option.tipo}
                      checked={checkboxValue === option.tipo}
                      onChange={(e, data) => { setCheckboxValue(data.value.toString()) } }
                    />
                  </Form.Field>
                ))
            }
          </Form>
        </Modal.Content>
        <Modal.Actions>
        <Button 
            centered
            color="yellow"
            size="big"
            onClick={handleExportTemplate} 
          >
            Download Template
          </Button>
          <Button 
            disabled={!isFileSelected}
            centered
            color="green"
            size="big"
            onClick={(e) => {
              if (selectedMenu.nome === 'WsPedRap') {
                handleOnSubmit(e);
                setCsvModalState(false);
              } else {
                updateIsImported(true)
                setCookie('selected_menu', JSON.stringify(menuToNavigate))
                dispatch(updateSelectedMenu({ value: menuToNavigate }))
                navigate('/cart')
                onClose();
                setCsvModalState(false); 
                setIsFileSelected(false);
              }
            } } 
          >
            Confirmar
          </Button>
        </Modal.Actions>
      </Modal>
      
       
      <Modal
        closeIcon
        open={csvModalState}
        onClose={handleOnCloseModal}
        style={{fontFamily: styleSheet.mainFont}}
        closeOnDimmerClick={false}
      >
        <Dimmer active={isLoading} inverted>
          <Loader size="huge" inverted>Carregando</Loader>
        </Dimmer>
        <Modal.Header
          fluid
          style={{textAlign: 'center'}}
        >
          Resumo da Leitura do Arquivo!
        </Modal.Header>
        <Modal.Content scrolling>
          <Table selectable>
            <Table.Header
              style={{textAlign: 'center'}}
            >
              <Table.Row>
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>Produto</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Info</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              { fastOrderList.map((item) => (
                  <Table.Row
                  style={{textAlign: 'center'}}
                  >
                    <Table.Cell>{item.item}</Table.Cell>
                    <Table.Cell>{item.produto}</Table.Cell>
                    <Table.Cell>{item.descr}</Table.Cell>
                      <Table.Cell>
                        <Label 
                          color={item.info === 'Em Estoque'? 'green' : 'red'}
                          horizontal 
                          style={{fontWeight: 'bold'}} 
                        >
                          {item.info}
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          </Modal.Content>
          <Modal.Actions  >
            <div 
              style={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center', gap: '1.5rem', 
                fontFamily: styleSheet.mainFont, 
                fontSize: '1.2rem'
              }}
            >
              <div>
                <span >
                  Você deseja continuar o pedido ou deseja cancelá-lo? 
                </span>
              </div>
              <div style={{display: 'flex', gap: '.4rem'}}>
                <Button 
                  primary 
                  positive
                  size="big"
                  onClick={handleConfirmationFastOrder}
                >
                  Prosseguir
                  </Button>
                <Button 
                  secondary 
                  negative
                  size="big"
                  onClick={handleCancelationFastOrder}
                >
                  Cancelar Pedido
                </Button>
              </div>
            </div>
          </Modal.Actions>
      </Modal>
    </>
  );
}

export default ImportCsvModal;

