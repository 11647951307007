import { Input } from "semantic-ui-react";
import styled from "styled-components";

interface IButtonTable {
  color: string;
}

export const DefaultLabel = styled.label`
  font-size: 1.3rem !important;
`;

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
`;


export const ButtonTable = styled.button<IButtonTable>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;

export const InputNumber = styled(Input)`

  background: transparent !important;
  -webkit-appearance: none !important;

  &:hover {
  -webkit-appearance: none!important;
    border-bottom-width:  2px;
    border-bottom-color: #ddd;
  }

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button { 
    -webkit-appearance: none !important; 
    margin: 0; 
  }
`;