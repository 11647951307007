import { useDispatch, useSelector } from "react-redux";
import React, { createContext, FC } from "react";
import Swal from "sweetalert2";

import { ClientService } from "../services/methods/ClientService";

import { IPaginatedServiceResponse } from "../interfaces/Datatable/IPaginatedServiceResponse";
import { IEnvironmentContext } from "../interfaces/Environment/IEnvironmentContext";

import { updateSelectedClient } from "../reducers/Environment";
import { AuthReducerInitialState } from "../reducers/Auth";
import { useCookies } from "react-cookie";

export const EnvironmentContext = createContext<IEnvironmentContext>({} as IEnvironmentContext);

const EnvironmentContextProvider: FC = ({ children }) => {
  const clientService: ClientService = new ClientService();

	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const [cookies, setCookie] = useCookies(['selected_client']);
  const dispatch = useDispatch()

  async function selectFirstClient(branch: string, companyId: string, authToken?: string) {
    try {
      const response = await clientService.index(branch, 1, 1, authToken ? authToken : token, '', companyId, undefined, undefined);
      const { dados } = response.data as IPaginatedServiceResponse;
      setCookie('selected_client', JSON.stringify(dados[0]))
      dispatch(updateSelectedClient({ value: dados[0] }))
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  return (
    <EnvironmentContext.Provider value={{
      selectFirstClient
    }}>
      {children}
    </EnvironmentContext.Provider>
  );

}

export default EnvironmentContextProvider;