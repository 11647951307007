import { AxiosError, AxiosResponse } from "axios";
import { IObjectParams } from "../../interfaces/Product/IProduct";
import protheusProvider from "../providers/protheus.provider";

export class ProductService {
  public async index(authToken: string, pageSize: number, page: number, clientId: string, branchId: string, companyId: string, order: string, isGrid: boolean, filters?: string | undefined, statusFilter?: { [key: string]: string } | undefined, priceTable?: string | number) {
    const params = {
      branchId,
      companyId,
      customerKey: clientId,
      page,
      order,
      pageSize,
      token: authToken
    }

    if (filters) Object.assign(params, isGrid ? { search: filters } : { filter: filters });
    if (statusFilter) Object.assign(params, { whereapiadp: statusFilter });
    if (priceTable) Object.assign(params, { priceTable: priceTable })

    const response = await protheusProvider.get(`wsproduto${isGrid ? '/grade/colors/' : ''}`, { params })

    return response;
  }

  public async getProductsWithPage(page: number, authToken: string, pageSize: number, clientId: string, branchId: string, companyId: string, filters?: string | undefined, statusFilter?: string | undefined) {
    const params = {
      branchId,
      companyId,
      customerKey: clientId,
      page,
      pageSize,
      token: authToken
    }

    if (filters) Object.assign(params, { filter: filters });
    if (statusFilter) Object.assign(params, { whereapiadp: statusFilter });

    const response = await protheusProvider.get(`wsproduto`, { params })

    return response;
  }

  public async getHeaderData(url: string, companyId: string, branchId: string, authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`${url}/${indice}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getItens(url: string, companyId: string, branchId: string, authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`${url}/${indice}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getGroups(subsidiaryId: string, companyId: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`wsrestgrupro/${subsidiaryId}`, { params: { companyId } });
    return response;
  }

  public async getTaxes(process: 'PED' | 'ORC', companyId: string, branchId: string, authToken: string, customerKey: string, objectParams: IObjectParams): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsplanilha`, {
        token: authToken,
        process,
        customerKey,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async productDetail(authToken: string, branchId: string, companyId: string, searchKey: string) {
    const params = {
      branchId,
      companyId,
      token: authToken,
      searchKey
    }

    const response = await protheusProvider.get(`wsprodinfo`, { params })

    return response;
  }

  public async create(url: string, companyId: string, branchId: string, authToken: string, customerKey: string, objectParams: IObjectParams) {
    try {
      const response = await protheusProvider.post(`${url}`, {
        token: authToken,
        customerKey,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async update(url: string, companyId: string, branchId: string, authToken: string, customerKey: string, objectParams: IObjectParams, index: string | number) {
    try {
      const response = await protheusProvider.put(`${url}/${index}`, {
        token: authToken,
        customerKey,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getGridLine(companyId: string, branchId: string, authToken: string, codGrade: string, linha: string, priceTable: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsxitemgrd`, {
        params: {
          companyId,
          branchId,
          token: authToken,
          codGrade,
          linha,
          priceTable
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getLots(companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsloteprd', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async consultation(consultationService: string, consult: string, companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`${consultationService}/${consult}`, {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || "",
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}