import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ShoppingBag } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { decode } from 'base-64';
import Swal from 'sweetalert2';

import { OrderService } from '../../services/methods/OrderService';
import { EmailService } from '../../services/methods/EmailService';

import { UIContext } from '../../contexts/UIContext';

import ViewOrderItensModal from '../../components/ViewOrderItensModal';
import ProductDetailModal from '../../components/ProductDetailModal';
import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import PrintModal from '../../components/PrintModal';
import { IErrorProps } from '../../components/Error';
import EmailModal from '../../components/EmailModal';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IOpcoes } from '../../interfaces/Menu/IMenu';

import { DataTableReducerInitialState, loadTableData, resetDataTable, updateTableCurrentPage, updateTableData, updateTableFilter, updateTableLoading, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { createOrEditOrderOrBudget, updateConsultationType, updateSelectedProductToConsultation } from '../../reducers/Cart';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { BrowserContent, Content, PageDefault } from './styles';
import SelectedClientModal from '../../components/SelectedClientModal';
import { updateCurrentBranch } from '../../reducers/Environment';
import { useCookies } from 'react-cookie';
import { debounce } from "../../common/debounce";
import SendModal from "../../components/SendModal";
import ConsultationModal from "../../components/ConsultationModal";

const Orders: FC = () => {

  const navigate = useNavigate();
  const orderService: OrderService = new OrderService();
  const emailService: EmailService = new EmailService();

  const { tableData, tableCurrentPage, tableOrderBy, tableFieldTypeConfig, tableFieldFilters, tableFilter, tableLegend } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, clientLegends, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, branches, activeUserEmail, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch()

  const useInitialFilterOrder = useMemo(() => companyPolicies.find(policy => policy.property === 'useInitialFilterOrder' && policy.policy_value === 'true'), [companyPolicies])
  const initialFilterOrder = useMemo(() => {
    if (useInitialFilterOrder) {
      return JSON.parse(companyPolicies.find(policy => policy.policy === useInitialFilterOrder.dependencie).policy_value)
    }

    return undefined
  }, [companyPolicies])

  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);

  const { setUIComponentState } = useContext(UIContext);

  const [viewItensModal, setViewItensModal] = useState(false)
  const [selectedIndice, setSelectedIndice] = useState<string | number>('')

  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [filterModal, setFilterModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [danfeModal, setDanfeModal] = useState(false);
  const [selectedClientModal, setSelectedClientModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [printOptions, setPrintOption] = useState<IOpcoes[]>([]);
  const [isDanfeXml, setIsDanfeXml] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [orderBy, setOrderBy] = useState('')

  const [consultationModalVisible, setConsultationModalVisible] = useState(false)

  const utils = new Utils();

  async function getOrderList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));
      const response = await orderService.index(currentCompany, currentBranch.codigo, page, itensPerPage, token, String(selectedClient.indice), order, filter, statusFilter)
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getOrdersWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getOrderList(1, orderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));
      await getOrderList(1, orderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [orderBy, tableFieldTypeConfig, tableFilter]);

  async function handlePrint(indice: string | number, typePrint?: string) {
    const id = toast.loading("Por favor aguarde...")
    setEmailModal(false);
    setIsSendEmail(true);
    
    try {
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      
      const nf = isDanfeXml ? {
        ...selectedRow
      } : {
        C5_NUM: selectedRow.C5_NUM || selectedRow.c5_num,
      }
      const url = isDanfeXml ? 'WSRESTDANFEXML' : 'WSENVPED';
      const config = notHaveDanfeConfiguration && isDanfeXml;

      const {data} = await emailService.print(url, currentBranch.codigo, currentCompany, String(indice), "SC5", nf, token, config, typePrint);

      if(data.status === 'ok') {
        if(data.html) {  
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

          Swal.fire({
            html: `${decode(data.html)} <br/><br/><button class="button-pdf-download" onClick="window.print();">Download em PDF</button> `,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
          });

          document.querySelector<HTMLElement>('.swal2-container > div').style.width = '80%';
        }else if(data.x64) {
          const blob = utils.base64toBlob(`data:application/pdf;base64,${data.x64}`);
          const url = URL.createObjectURL(blob);

          const hiddenElement = document.createElement('a');
          hiddenElement.href = url
          hiddenElement.target = '_blank';
          hiddenElement.click();
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        } else{
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          return Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }

      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function handleSendThroughEmail(email: string, indice: string | number, type?: string) {
    const id = toast.loading("Por favor aguarde...")
    setEmailModal(false);
    setIsSendEmail(true);
    
    try {
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      
      const nf = {
        C5_NUM: selectedRow.C5_NUM || selectedRow.c5_num,
      }

      const config = notHaveDanfeConfiguration && isDanfeXml;

      const {data} = await emailService.sendEmail(currentBranch.codigo, currentCompany, String(indice), email, activeUserEmail, "SC5", nf, token, config, type);

      if(data.status === 'ok') {
        if(data.html) {  
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

          Swal.fire({
            html: `${decode(data.html)} <br/><br/><button class="button-pdf-download" onClick="window.print();">Download em PDF</button> `,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
          });

          document.querySelector<HTMLElement>('.swal2-container > div').style.width = '80%';
        }else{
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          return Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }

      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function handleDanfeXml(email: string, indice: string | number, sendType: string, type?: string) {
    const id = toast.loading("Por favor aguarde...")
    setDanfeModal(false);    
    try {
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      const useLandscapeDanfe = companyPolicies.find(police => police.property === 'useLandscapeDanfe' && police.policy_value === 'true');
      
      const config = notHaveDanfeConfiguration && isDanfeXml;
      const orientation = useLandscapeDanfe ? 2 : 1;

      const {data} = await emailService.danfe(sendType, currentBranch.codigo, currentCompany, String(indice), email, activeUserEmail, "SC5", selectedRow, token, config, isDanfeXml ? orientation : null, type);

      if(data.status === 'ok') {
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

        if (sendType === 'download' && data.arquivos.pdf.length > 0) {
          const blobPDF = utils.base64toBlob(data.arquivos.pdf[0]);
          const urlPDF = URL.createObjectURL(blobPDF);

          const hiddenElementPDF = document.createElement('a');
          hiddenElementPDF.href = urlPDF
          hiddenElementPDF.target = '_blank';
          hiddenElementPDF.click();

          if (data.arquivos.xml[0]) {
            const hiddenElementXML = document.createElement('a');
            hiddenElementXML.href = data.arquivos.xml[0]
            hiddenElementXML.download = `${String(indice).trim()}.xml`;
            hiddenElementXML.click();
          }
        }

      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function handleDeleteOrder(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")

    try {
      const { data } = await orderService.delete(token, indice)
      
      if(data.status === 'ok') {
        const tableDataArr = [...tableData];
        const newTableData = tableDataArr.filter(item => item.indice !== indice);
        dispatch(updateTableData({ value: newTableData }));
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
  
      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });

      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }

  }

  async function filterOrders(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }))
    await getOrderList(1, orderBy, filter, statusFilter)
  }

  async function orderOrder(order: string) {
    setOrderBy(order)
    await getOrderList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  async function onEditOrderClick(indice: string | number) {
    dispatch(createOrEditOrderOrBudget({ indice: indice, isEditing: true, isCopying: false, type: 'order' }))
    navigate('/cart')
  }

  async function onCopyOrderClick(indice: string | number) {
    dispatch(createOrEditOrderOrBudget({ indice: indice, isEditing: false, isCopying: true, type: 'order' }))
    navigate('/cart')
  }

  async function onViewOrderClick() {
    setViewItensModal(true);
  }

  async function onEmailOrderClick(indice: string | number, danfeXml: boolean) {
    if(!danfeXml && selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'enviapedid').opcoes.length > 0) {
      setPrintOption(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'enviapedid').opcoes);
    }else {
      setPrintOption([]);
    }
    setIsDanfeXml(danfeXml);
    if (danfeXml) {
      setDanfeModal(true);
    } else {
      setEmailModal(true);
    }
    setSelectedIndice(indice)
  }

  function handleActionDataTable(action: string, indice: string | number) {
    setSelectedIndice(indice);
    const useValidationByClientStatusInOrderCreate = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInOrderCreate' && policy.policy_value === 'true');
    const useValidationByClientStatusInOrderUpdate = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInOrderUpdate' && policy.policy_value === 'true');
    const useValidationByClientStatusInOrderDelete = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInOrderDelete' && policy.policy_value === 'true');
    const useValidationByOrderStatusInOrderUpdate = companyPolicies.find(policy => policy.property === 'useValidationByOrderStatusInOrderUpdate' && policy.policy_value === 'true');
    const useValidationByOrderStatusInPrint = companyPolicies.find(policy => policy.property === 'useValidationByOrderStatusInPrint' && policy.policy_value === 'true');
    const useValidationByOrderStatusInSendEmail = companyPolicies.find(policy => policy.property === 'useValidationByOrderStatusInSendEmail' && policy.policy_value === 'true');
    const useValidationByOrderStatusInDanfeXml = companyPolicies.find(policy => policy.property === 'useValidationByOrderStatusInDanfeXml' && policy.policy_value === 'true');
    const useValidationByOrderStatusInOrderDelete = companyPolicies.find(policy => policy.property === 'useValidationByOrderStatusInOrderDelete' && policy.policy_value === 'true');
    const useValidationClientRisk = companyPolicies.find(policy => policy.property === 'useValidationClientRisk');
    const showBranchExchangeMessage = companyPolicies.find(policy => policy.property === 'showBranchExchangeMessage' && policy.policy_value === 'true');

    if(useValidationByClientStatusInOrderCreate && action === 'add') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInOrderCreate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você inclua um pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationClientRisk && action === 'add') {
      const risks = companyPolicies.find(policy => policy.policy === useValidationClientRisk.dependencie).policy_value.split(';')
      if(risks.includes(String(selectedClient.a1_risco).split('Risco ')[1])) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: useValidationClientRisk.policy_value,
        })
      }
    }

    if(useValidationByClientStatusInOrderUpdate && action === 'edit') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInOrderUpdate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você altere um pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByClientStatusInOrderDelete && action === 'delete') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInOrderDelete.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você exclua um pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByOrderStatusInOrderUpdate && action === 'edit') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByOrderStatusInOrderUpdate.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do pedido selecionado não permite que você altere um pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }
    
    if(useValidationByOrderStatusInOrderDelete && action === 'delete') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByOrderStatusInOrderDelete.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do pedido selecionado não permite que você exclua esse pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByOrderStatusInPrint && action === 'print') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByOrderStatusInPrint.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse pedido não permite que você faça a impressão do pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByOrderStatusInSendEmail && action === 'email') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByOrderStatusInSendEmail.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse pedido não permite que você faça o envio do pedido por email!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByOrderStatusInDanfeXml && action === 'xml') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByOrderStatusInDanfeXml.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse pedido não permite que você faça o envio do danfeXml do pedido!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if (action === 'edit' || action === 'copy') {
      const selectedRow = tableData.find(item => item.indice === indice)
      if (selectedRow.c5_filial && currentBranch.codigo !== selectedRow.c5_filial ) {
        if(branches.find(branch => branch.codigo === selectedRow.c5_filial)) {
          if (showBranchExchangeMessage) {
            Swal.fire({
              title: 'Atenção!',
              text: `Filial alterada para ${branches.find(branch => branch.codigo === selectedRow.c5_filial).nome}!`,
              icon: 'info',
              confirmButtonText: 'Ok'
            })
          }
          setCookie('current_branch', JSON.stringify(branches.find(branch => branch.codigo === selectedRow.c5_filial)))
          dispatch(updateCurrentBranch({ value: branches.find(branch => branch.codigo === selectedRow.c5_filial) }))
        } else {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Você não tem acesso a Filial desse pedido!',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
      }
    }


    switch (action) {
      case 'add':
        dispatch(createOrEditOrderOrBudget({ indice: '', isEditing: false, isCopying: false, type: 'order' }));
        navigate('/cart');
        break;
      case 'edit':
        onEditOrderClick(indice);
        break;
      case 'copy':
        setSelectedIndice(indice);
        setSelectedClientModal(true)
        break;
      case 'view':
       onViewOrderClick();
       break;
      case 'filter':
        setFilterModal(true);
        break;
      case 'email':
        onEmailOrderClick(indice, false);
        break;
      case 'delete':
        if(configuracoes.useconfirmationorder) {
          return Swal.fire({
            title: 'Confirmar ação?',
            text: 'Você deseja excluir esse pedido!',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, confirmar!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              handleDeleteOrder(indice);
            }
          });
        } else {
          handleDeleteOrder(indice);
        }
        break;
      case 'xml':
        onEmailOrderClick(indice, true);
        break;
      case 'print': 
        setIsDanfeXml(false);
        if(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'print').opcoes.length > 0) {
          setPrintModal(true);
          setPrintOption(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'print').opcoes)
        }else {
          handlePrint(indice, '')
        }
        break;
      case 'log':
        const selectedRow = tableData.find(item => item.indice === indice)
        dispatch(updateConsultationType({ value: selectedMenu.nome }))
        dispatch(updateSelectedProductToConsultation({ value: selectedRow }))
        setConsultationModalVisible(true);
        break;
      default:
        console.log(action)
        break;
    }
  }

  useEffect(() => {
    dispatch(resetDataTable())
    
    if (useInitialFilterOrder) {
      dispatch(updateTableFilter({ value: { filter: undefined, statusFilter: initialFilterOrder } }))
    }

    getOrderList(tableCurrentPage, orderBy, tableFilter.filter, initialFilterOrder)
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<ShoppingBag size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Datatable
              serviceName="wspedido"
              onItensPerPageChange={() => getOrderList(1, orderBy, tableFilter.filter, tableFilter.statusFilter)}
              onPageChange={(page) => getOrderList(page, orderBy, tableFilter.filter, tableFilter.statusFilter)}
              onSearch={ (value, noDebounce) => getOrdersWithTextSearch(noDebounce ? 'enter' : '', value) }
              onOrderBy={(value) => orderOrder(value)}
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterOrders(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => handleActionDataTable(action, indice)}
            />
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

      {
        viewItensModal &&
        <ViewOrderItensModal
          indice={selectedIndice}
          onClose={(reload) => {
            if (reload) {
              getOrderList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterOrder);
            }
            setViewItensModal(false)
          }}
          visible={viewItensModal}
          mode="view"
        />
      }

      <ProductDetailModal />


      {/* <ApprovalModal
        indice={selectedIndice}
        onClose={() => setViewItensModal(false)}
        visible={viewItensModal}
        mode="view"
      /> */}

      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterOrders}
        onClose={() => {
          setFilterModal(false)
        }}
      />

      <EmailModal
        visible={emailModal}
        onClose={() => setEmailModal(false)}
        handleSendEmail={(email, type) => handleSendThroughEmail(email, selectedIndice, type)}
        options={printOptions}
        isSendEmail={isSendEmail}
      />

      <SendModal
        visible={danfeModal}
        onClose={() => setDanfeModal(false)}
        handleSend={ (email, sendType, type) => handleDanfeXml(email, selectedIndice, sendType, type) }
        isSend={isSendEmail}
        options={ printOptions }
      />

      <SelectedClientModal 
        visible={selectedClientModal}
        onClose={() => setSelectedClientModal(false)}
        onConfirm={() => onCopyOrderClick(selectedIndice)}
      />

      <PrintModal
        visible={printModal}
        onClose={() => setPrintModal(false)}
        handleConfirmationAction={(type) =>  { handlePrint(selectedIndice, type); setPrintModal(false); } }
        options={printOptions}
        isPrinting={isSendEmail}
      />

      <ConsultationModal 
        visualizationOnly={true}
        consultationServiceName="wsconsgener"
        visible={consultationModalVisible}
        onClose={() => setConsultationModalVisible(false)}
      />
    </PageDefault>
  );
}

export default Orders;