import _ from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Modal, Step } from 'semantic-ui-react';
import { ButtonContainer, Circle, CodeContainer, FormContainer, InfoButtonContainer, ResendCodeLink, ResendCodeText, ValidationContainer, ValidationLabel } from './styles';
import { Envelope, Phone } from 'phosphor-react';
import Swal from 'sweetalert2';
import { encode } from 'base-64';
import { toast } from 'react-toastify';
import { AuthService } from '../../services/methods/AuthService';
import ReactCodeInput from 'react-verification-code-input';
import { useNavigate } from 'react-router';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { useDispatch, useSelector } from 'react-redux';
import { IHelperTokenResponse } from "../../interfaces/Auth/IAuthServiceResponse";
import { updateBearerAccessToken, updateBearerRefreshToken } from "../../reducers/Auth";
import { UIContext } from "../../contexts/UIContext";
import { IErrorProps } from "../Error";
import protheusProvider from "../../services/providers/protheus.provider";


interface IForgetPasswordModal {
  forgetPasswordModalOpen: boolean;
  setForgetPasswordModalOpen(value: boolean): void;
}

enum BodyModal {
  options,
  input,
  code,
}

const ForgetPasswordModal:  FC<IForgetPasswordModal> = ({forgetPasswordModalOpen, setForgetPasswordModalOpen}) => {
	const { companyPolicies, companyName, companyRest } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const dispatch = useDispatch();
  
  const useStrongPasswordValidator = companyPolicies.find(police => police.property === 'useStrongPasswordValidator' && police.policy_value === 'true');
  const useProtheusSecurity = companyPolicies.find(police => police.property === 'useProtheusSecurity' && police.policy_value === 'true');
  
  const authService: AuthService = new AuthService();

  const [ email, setEmail ] = useState('');
  const [ bodyModal, setBodyModal ] = useState<BodyModal>(BodyModal.options);
  const [ counter, setCounter] = useState(0);
  const [ activeButton, setActiveButton ] = useState(false);
  const [sendCodeBy, setSendCodeBy] = useState<'email' | 'sms'>('email');
  const [ validateCodeLoad, setValidateCodeLoad ] = useState(false);
  const [token, setToken] = useState('');
  const [info, setInfo] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperAndLowerCase, setUpperAndLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);

  const navigate = useNavigate();
  const { setUIComponentState } = useContext(UIContext);


  async function getProtheusBearerToken() {
    try {
      const response = await authService.helperToken(companyName.toLowerCase(), companyRest)
      const { accessToken, renewToken } = response.data as IHelperTokenResponse

      if (response.status === 200) {
        dispatch(updateBearerAccessToken({ value: accessToken }))
        dispatch(updateBearerRefreshToken({ value: renewToken }))
        protheusProvider.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      }else {
        if (!!response.data.msg) {
          setUIComponentState('error', {
            visible: true,
            title: 'Não foi possível efetuar a autenticação!',
            message: response.data.msg,
            statusHttp: response.status,
            urlHttp: response.request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: response.data.msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        } else {
          setUIComponentState('error', {
            visible: true,
            message: 'Erro desconhecido.',
            title: 'Não foi possível efetuar a autenticação!',
            urlHttp: response.request.responseURL,
            statusHttp: response.status,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: response.data.msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleAuthenticateEmail( sendBy: 'email' | 'sms') {
    if(email === '') {
      return  Swal.fire({
        title: 'Atenção',
        text: 'O campo email é obrigatório.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
    const id = toast.loading("Por favor aguarde...")


    if(useProtheusSecurity) {
      await getProtheusBearerToken()
    }
    
    try {
      setSendCodeBy(sendBy)
      // const emailBase64 = encode(email)

      const { data, status } = await authService.authEmail(email, sendBy)
      setCounter(0)
      if(status === 200) {
        setInfo(data.info)
        setCounter(data.countdown);
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setBodyModal(BodyModal.code)
      }else{
        toast.update(id, {render: data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    } finally {
      setValidateCodeLoad(false);

    }
  }

  async function handleValidateCode(code: string) {
    const id = toast.loading("Por favor aguarde...")
    try {
      // const emailBase64 = encode(email)
      setValidateCodeLoad(true);

      const { data, status } = await authService.validateCode(email, code)
      if(status === 200 || status === 201) {
        setToken(data.token)
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        setBodyModal(BodyModal.input)
      }else{
        toast.update(id, {render: data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    } finally {
      setValidateCodeLoad(false);
    }
  }

  async function handleChangePassword() {
    if(newPassword === '') {
      return  Swal.fire({
        title: 'Atenção',
        text: 'Preencha a senha.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
  
    if(newPassword !== confirmPassword) {
      return  Swal.fire({
        title: 'Atenção',
        text: 'As senhas precisam ser iguais.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
    }
    const id = toast.loading("Por favor aguarde...")
    try {
      const { data, status } = await authService.newPassword(encode(confirmPassword), token)
      if(status !== 400 && status !== 500 && status !== 401) {
        setToken(data.token)
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        navigate('/login')
        setBodyModal(BodyModal.options)
      }else{
        toast.update(id, {render: data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
    }
  }
  
  useEffect(() => {
    let interval
    if(counter > 0){
      interval = setTimeout(() => {
       setCounter(counter - 1);
     }, 1000);
    } else {
     setActiveButton(false);
    }
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setValidLength(newPassword.length >= 8 ? true : false);
    setUpperAndLowerCase(newPassword.match(/[a-z]+/) && newPassword.match(/[A-Z]+/) ? true : false);
    setHasNumber(/\d/.test(newPassword));
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(newPassword));
    setMatch(newPassword !== '' && newPassword === confirmPassword)
  }, [newPassword, confirmPassword]);
  

  function getBodyModal(): JSX.Element {

    if(bodyModal === BodyModal.options) {
      return (
        <Step 
          active={ forgetPasswordModalOpen }
          style={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '2rem',
            border: 'none',
            width: '100%',
            zIndex: '3',
            background: '#fff'
          }}
        >
          <Step.Content
            style={{ width: '100%' }}
          >
            <Form>
              <FormContainer>
                <Form.Field style={{  width: '85%' }}>
                  <Step.Title
                    style={{fontSize: '1.5rem', marginBottom: '0.5rem'}}
                  > 
                    Informe seu e-mail
                  </Step.Title>

                  <Input 
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Form.Field>
                <span>Escolha a forma como deseja receber o código</span>
                <ButtonContainer>
                  <Button
                    color='blue'
                    circular
                    style={{ height: '11rem', width: '11rem' }}
                    onClick={() => handleAuthenticateEmail('email') }
                    type='button'
                  > 
                    <InfoButtonContainer>  
                      <Envelope size={22} color='white'/>
                      <span>E-mail</span>
                    </InfoButtonContainer> 
                  </Button>
                  <Button
                    circular
                    style={{height: '11rem', width: '11rem'}}
                    color='purple'
                    onClick={() => handleAuthenticateEmail('sms') }  
                    type='button'
                  > 
                    <InfoButtonContainer>
                      <Phone size={22} color='white'/>
                      <span>SMS</span>
                    </InfoButtonContainer> 
                  </Button>
                </ButtonContainer>
              </FormContainer>
            </Form>
          </Step.Content>
        </Step>
      )
    } else if(bodyModal === BodyModal.input) {
      return (
        <Step
          style={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '2rem',
            border: 'none',
            width: '100%',
            zIndex: '3',
            background: '#fff'
          }}
        >
          <Button
            style ={{
              background: 'transparent',
              position: 'absolute',
              top: '1rem',
              left: 0,
              zIndex: 9999,
            }}
            onClick={() => setBodyModal(BodyModal.options)}
          >
            <Icon 
              name='angle left' 
              color='blue' 
              size='big'
              style={{ alignSelf: 'flex-start' }} 
            />
          </Button>
          <Step.Content
            style={{ width: '100%' }}
          >
            <Form>
              <FormContainer>
                <Form.Field>
                  <span>Senha</span>
                  <Input 
                    type='password'
                    style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                  />

                  <span>Confirmar senha</span>
                  <Input 
                    type='password'
                    style={{ marginTop: '0.5rem' }}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  {
                    useStrongPasswordValidator && 
                    <ValidationContainer>
                      <ValidationLabel>
                        <Circle active={upperAndLowerCase} />
                        <div className="info" id="info">Letra Minuscula e Maiúscula</div>
                      </ValidationLabel>

                      <ValidationLabel>
                        <Circle active={validLength} />
                        <div className="info" id="info2">Deve conter ao menos 8 caracteres</div>
                      </ValidationLabel>

                      <ValidationLabel>
                        <Circle active={hasNumber} />
                        <div className="info" id="info3">Deve conter ao menos 1 valor numérico.</div>
                      </ValidationLabel>
                      
                      <ValidationLabel>
                        <Circle active={specialChar} />
                        <div className="info" id="info4">Deve conter ao menos 1 carácter especial.</div>
                      </ValidationLabel>

                      <ValidationLabel>
                        <Circle active={match} />
                        <div className="info" id="info5">Senhas conferem</div>
                      </ValidationLabel>
                    </ValidationContainer>
                  }
                </Form.Field>
                <Button
                  disabled={ useStrongPasswordValidator && !(upperAndLowerCase && validLength && hasNumber && specialChar && match) }
                  positive
                  onClick={() => handleChangePassword()}
                  style={{fontSize: '1.3rem'}}
                >
                  Trocar a senha
                </Button>

              </FormContainer>
            </Form>
          </Step.Content>
        </Step>
      )
    } else if(bodyModal === BodyModal.code) {
        return (
        <Step
          style={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            borderRadius: '1rem',
            border: 'none',
            width: '50%'
          }}
        >
         <Button
            style ={{
              background: 'transparent',
              position: 'absolute',
              top: '1rem',
              left: 0,
              zIndex: 9999,
            }}
            onClick={() => setBodyModal(BodyModal.options)}
          >
            <Icon 
              name='angle left' 
              color='blue' 
              size='big'
              style={{ alignSelf: 'flex-start' }} 
            />
          </Button>
          <Step.Content>
              <Form>
                <FormContainer>
                  <span>Insira aqui o código enviado</span>
                  {info !== '' && ( <span>{info}</span> )}
                  <CodeContainer>
                    <ReactCodeInput
                      disabled={validateCodeLoad}
                      fields={6}
                      onComplete={(val) => handleValidateCode(val)}
                    />
                    {/* { codeColumnsInput } */}
                  </CodeContainer>
                </FormContainer>
              </Form>
              {counter > 0 ? (
                <ResendCodeText>Reenviar o código, disponível em <ResendCodeLink>{counter}</ResendCodeLink></ResendCodeText>
              ) : (
                <ResendCodeText>Não chegou o código, <ResendCodeLink onClick={() => handleAuthenticateEmail(sendCodeBy)}>clique aqui</ResendCodeLink> para enviar novamente</ResendCodeText>
              )}
          </Step.Content>
        </Step>
      )
    } else {
      return <></>
    }
  }
  
  return (
    <Modal
      size='mini'
      open={forgetPasswordModalOpen}
      closeOnDimmerClick={false}
      onClose={() => {
        setForgetPasswordModalOpen(false)
        setBodyModal(BodyModal.options)
      }}
      onOpen={() => setForgetPasswordModalOpen(true)}
      style={{borderRadius: '10px'}}
      closeIcon
    >
      <Modal.Header style={{borderRadius: '2rem 2rem 0rem 0rem'}}>Recuperação de senha</Modal.Header>
      <Step.Group 
        stackable='tablet'
        style={{
          width: '100%',
          margin: '0'
        }}
      >
       { getBodyModal() }
      </Step.Group>
    </Modal>
  );
}

export default ForgetPasswordModal;
