import React, { FC, useContext } from 'react';
import { Button, Container, Header } from 'semantic-ui-react';
import { ISubmenuItem } from '../../interfaces/Menu/IMenu';
import { SquaresFour, List  as ListIcon, Buildings, User, X } from 'phosphor-react';

import { BrowserContainer, BrowserMainContent, HeaderContainer, HeaderTitle, Selected, SelectedTitle, SelectedText, SelectedContainer, SelectedContent } from './styles';
import { styleSheet } from '../../assets/styles/global';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentReducerInitialState, updateSelectedClient } from '../../reducers/Environment';
import { Utils } from '../../common/Utils';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { OrderServiceContext } from '../../contexts/OrderServiceContext';
import { MenuReducerInitialState, updateSelectedMenu } from '../../reducers/Menu';
import { resetDataTable } from "../../reducers/DataTable";
import { updateIsConsultation } from "../../reducers/Cart";
import { useCookies } from "react-cookie";
import { MenuContext } from "../../contexts/MenuContext";

interface IBrowserProps {
  title: string;
  icon: JSX.Element;
  submenu?: ISubmenuItem[];
  children: JSX.Element;
}

const Browser: FC<IBrowserProps> = ({ title, icon, children }) => {
  const dispatch = useDispatch()

  const navigate = useNavigate();
  
  const utils = new Utils();

  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);
  const { getMenuRoute } = useContext(MenuContext)

  
  const { selectedClient, currentBranch } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { selectedMenu, menuList } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { pwm } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const { updateOrderServiceStyle, orderServiceStyle } = useContext(OrderServiceContext);

  function handleUnselectedClient() {
    
    dispatch(updateSelectedClient({ value: {} }))
    const currentMenu = menuList.find(menu => menu.nome === 'Empresas')
    if (selectedMenu.nome !== currentMenu.nome) {
      dispatch(resetDataTable())
      setCookie('selected_menu', JSON.stringify(currentMenu))
      dispatch(updateSelectedMenu({ value: currentMenu }))
      dispatch(updateIsConsultation({ value: false }))
      navigate(`/${ getMenuRoute(currentMenu.nome) }`);
    }
  }

  return (
    // <Container style={{ width: 'calc(100% - 8rem)' }} fluid>
    <Container style={{ paddingLeft: '6rem' }} fluid>
      <BrowserContainer>
        <Header dividing style={{ marginLeft: '4.8rem', width: 'calc(100% - 6.5rem)' }}>
          <HeaderContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              <HeaderTitle>{title}</HeaderTitle>
            </div>

            <SelectedContainer>
                  {
                  selectedMenu && selectedMenu.nome === 'OrdemServico' && (
                    <Button.Group style={{ marginRight: '2rem' }}>
                      <Button
                        onClick={() => updateOrderServiceStyle('calendar')}
                        style={{ backgroundColor: orderServiceStyle === 'calendar' ? styleSheet.contrastColor : '' }}
                      >
                        <SquaresFour color={orderServiceStyle === 'calendar' ? '#fff' : '#000'} size="15" />
                      </Button>
                      <Button.Or text="ou" />
                      <Button
                        onClick={() => updateOrderServiceStyle('list')}
                        style={{ backgroundColor: orderServiceStyle === 'list' ? styleSheet.contrastColor : '' }}
                      >
                        <ListIcon color={orderServiceStyle === 'list' ? '#fff' : '#000'} size="15" />
                      </Button>
                    </Button.Group>
                  )
                }
              <SelectedContent>
                <User size={24} color={styleSheet.mainColor} />
                <Selected>
                  <SelectedTitle>Cliente Selecionado</SelectedTitle>
                  <SelectedText>
                    {utils.formatSelectClientTitle(selectedClient, pwm)} 
                    {utils.formatSelectClientTitle(selectedClient, pwm) !== "N/A" ? <X style={{ marginBottom: '1px', cursor: 'pointer' }} size={14} color="red" onClick={handleUnselectedClient} /> : <div />}
                    </SelectedText>
                </Selected>
              </SelectedContent>
              <SelectedContent>
                <Buildings size={24} color={styleSheet.mainColor} />
                <Selected>
                  <SelectedTitle>Filial Selecionado</SelectedTitle>
                  <SelectedText>{currentBranch.nome || 'N/A'}</SelectedText>
                </Selected>
              </SelectedContent>
            </SelectedContainer>
          </HeaderContainer>
        </Header>

        <Container fluid>
          <BrowserMainContent>
            {children}
          </BrowserMainContent>
        </Container>
      </BrowserContainer>
    </Container>
  );
}

export default Browser;