import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont};// ${styleSheet.mainFont};
  font-size: 2rem;
  display: inline-block;
  text-align: left;
`;

export const Mandatory = styled.span`
  color: ${({ theme }) => theme.dangerColor}; //${styleSheet.dangerColor};
  font-size: 2rem;
`;


export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
`;

export const TableColumnText = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;

export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;

export const HeaderTable = styled.div`
  width: 100%;
  display: flex;
  algin-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const ButtonTable = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;


