import { Dropdown, Form, Input, Popup, TextArea } from 'semantic-ui-react';
import { Question } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import React, { FC } from 'react';
import ReactQuill from 'react-quill';
import Swal from 'sweetalert2';

import { ClientService } from '../../../services/methods/ClientService';

import F3 from '../../F3';

import { FieldValues } from '../../../@types/Restfield';

import { Utils } from '../../../common/Utils';

import { updateGridFieldValues, updateAllGridFieldValues, RestFieldReducerInitialState, updatePropertyGridField, updateToOldPropertyGridField } from '../../../reducers/RestField';
import { EnvironmentReducerInitialState } from '../../../reducers/Environment';
import { PolicieReducerInitialState } from '../../../reducers/Policie';
import { AuthReducerInitialState } from '../../../reducers/Auth';
import { MenuReducerInitialState } from '../../../reducers/Menu';

import 'react-quill/dist/quill.snow.css';
import { DefaultLabel } from './styles';


interface IGridRestFieldProps {
  mode: string;
}

const GridRestField: FC<IGridRestFieldProps> = ({ mode }) => {

	const { gridFields, gridFieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

	const dispatch = useDispatch();

  /** HOOK PARA CRITÉRIOS DE DEBUG */
  const clientService: ClientService = new ClientService();

  const utils = new Utils();

  async function intersectionsF3Value(f3Data: F3Data[], fieldValuesAtt: FieldValues) {
    const useDiscountByPaymentConditionInF3 = companyPolicies.find(policy => policy.property === 'useDiscountByPaymentConditionInF3' && policy.policy_value === 'true');
    const useClientDiscountByPriceTableInF3 = companyPolicies.find(policy => policy.property === 'useClientDiscountByPriceTableInF3' && policy.policy_value === 'true');

    
    for (let index = 0; index < f3Data.length; index++) {

    }
  }

  async function intersectionsNewF3Value(f3Data: INewF3Data) {
    const useDiscountByPaymentConditionInF3 = companyPolicies.find(policy => policy.property === 'useDiscountByPaymentConditionInF3' && policy.policy_value === 'true');
    const useClientDiscountByPriceTableInF3 = companyPolicies.find(policy => policy.property === 'useClientDiscountByPriceTableInF3' && policy.policy_value === 'true');

    const f3Keys = Object.keys(f3Data)

    for (let index = 0; index < f3Keys.length; index++) {
      
    }
  }

  async function intersectionsCombo(fieldName: string, fieldValue: string) {
    const useObligationDeliveryFieldsByFreightType = companyPolicies.find(police => police.property === 'useObligationDeliveryFieldsByFreightType' && police.policy_value !== '');

    if(useObligationDeliveryFieldsByFreightType && fieldName.includes('_XTPFRET')) {
      const marketType = selectedClient['a1_dvtip'].toString().replace('&$&', '');
      const fields = companyPolicies.find(policie => policie.policy === useObligationDeliveryFieldsByFreightType.dependencie).policy_value.split(';')

      if(marketType === 'T') {
        if(fieldValue === useObligationDeliveryFieldsByFreightType.policy_value) {
          dispatch(updateToOldPropertyGridField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields, property: 'obrigado' }))
        }else{
          dispatch(updatePropertyGridField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields, value: 'N', property: 'obrigado' }))
        }
      }
    }


  }

  function onTrigger(f3SelectedValue: INewF3Data, fieldKey: string, trigger: string[]) {
    const fieldIndex = gridFields.findIndex(field => field.nome === fieldKey) + 1
    trigger.shift();

    if(trigger.length > 0) {
      trigger.map((f3Key, index) => {
        const fieldTrigger = gridFields[fieldIndex + index]
        dispatch(updateGridFieldValues({ name: fieldTrigger.nome, value: f3SelectedValue[f3Key]}))
      })
    }
  }

  return (
    <Form style={{ display: 'flex', flexWrap: 'wrap', gap: '1.5rem', flexGrow: '1' }}>
      {
        gridFields.map(({ titulo, nome, obrigado, placeholder, visual, tipo, type, options, help, f3, f3Post, decimal = "2", conteudo, len, picture, fieldOccupationSize, nivel }) => { 
          let isValid = true
          isValid = utils.validationFormField(tipo, mode);
          isValid = configuracoes.usecustomerlevel && nivel ? customerLevel >= Number(nivel) : isValid

          if(isValid) {
            return (
              <Form.Field key={nome}  required={obrigado === 'S'} style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? `${fieldOccupationSize}%` : '32%' }}> 
                <DefaultLabel>
                  {titulo}
                  {
                    help &&
                    <Popup
                      style={{ fontWeight: '600' }}
                      trigger={<Question size={16} style={{ marginLeft: '0.5rem' }} />}
                      content={help}
                      size='large'
                      inverted
                    />
                  }
                </DefaultLabel>
                {
                  type === 'character' && !f3 &&
                  <Input
                    style={{ height: '4rem' }}
                    placeholder={placeholder}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    onFocus={e => e.target.select()}
                    maxLength={len}
                    onChange={
                      (ev, { value }) => { 
                        dispatch(updateGridFieldValues({ name: nome, value: utils.pictureValidate(picture, value)  }));
                      } 
                    }
                    value={gridFieldValues[nome] || conteudo}
                  />
                }
                {
                  type === 'number' &&
                  <Input
                    style={{ height: '4rem' }}
                    placeholder={placeholder}
                    type="number"
                    onFocus={e => e.target.select()}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    maxLength={len}
                    onChange={
                      (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value: parseFloat(value) })) }
                    }
                    defaultValue={gridFieldValues[nome] || conteudo}
                  />
                }
                {
                  type === 'percent' &&
                  <Input
                    style={{ height: '4rem' }}
                    placeholder={placeholder}
                    icon="percent"
                    onFocus={e => e.target.select()}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    maxLength={len}
                    onChange={
                      (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value: parseFloat(value) })) }
                    }
                    defaultValue={gridFieldValues[nome] || conteudo}
                  />
                }
                {
                  type === 'date' &&
                  <Input
                    style={{ height: '4rem' }}
                    placeholder={placeholder}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    type="date"
                    onChange={
                      (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value })) }
                    }
                    defaultValue={ gridFieldValues[nome] ? utils.formatDateDefault(gridFieldValues[nome].toString()) : utils.formatDateDefault(conteudo.toString())}
                  />
                }

                {
                  type === 'time' &&
                  <Input
                    style={{ height: '4rem' }}
                    placeholder={placeholder}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    type={ mode === 'view' ? 'text' : 'time' }
                    onChange={
                      (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value })) }
                    }
                    defaultValue={ gridFieldValues[nome] ? gridFieldValues[nome] : conteudo}
                  />
                }

                {
                  type === 'memo' && nome !== 'PW0_MSG' &&
                  <TextArea
                    // style={{ height: '4rem' }}
                    placeholder={placeholder}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    type="date"
                    onChange={
                      (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value })) }
                    }
                    defaultValue={gridFieldValues[nome] || conteudo}
                  />
                }

                {
                  type === 'memo' && nome === 'PW0_MSG' && 
                  <ReactQuill 
                    style={{ height: '18rem' }} 
                    theme="snow" 
                    modules={{
                      toolbar: [
                        [{ 'header': [1, 2,3,4,5, false] }],
                        ['bold', 'italic', 'underline','strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image'],
                        ['clean']
                      ],
                    }}
                    value={String(gridFieldValues[nome]) || String(conteudo)} 
                    onChange={(value) => dispatch(updateGridFieldValues({ name: nome, value })) }
                  />
                }

                {
                  type === 'currency' &&
                    <Input
                      style={{ height: '4rem' }}
                      placeholder={placeholder}
                      type="text"
                      onFocus={e => e.target.select()}
                      readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                      maxLength={len}
                      onChange={
                        (ev, { value }) => { dispatch(updateGridFieldValues({ name: nome, value: utils.unmaskBRLCurrency(value, decimal) })) }
                      }
                      value={utils.inputMaskBRLCurrency(Number(gridFieldValues[nome] || conteudo), decimal)}
                    />
                }
                {
                  type === 'combo' &&
                  <Dropdown
                    search
                    selection
                    placeholder={placeholder}
                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                    style={{ height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
                    options={options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } })}
                    onChange={
                      (ev, { value }) => {  dispatch(updateGridFieldValues({ name: nome, value: value.toString() })) }
                    }                      
                    value={gridFieldValues[nome] || conteudo}                 
                    text= {gridFieldValues[nome] && gridFieldValues[nome].toString() || conteudo.toString()}                     
                    onBlur={
                      (ev, { value }) => { 
                        intersectionsCombo(nome, value.toString())
                      }
                    }
                  />
                }             
                {
                  type === 'character' && !!f3 &&
                  <F3
                    clientId={null}
                    token={token}
                    consulta={f3Post.consulta}
                    conteudo={gridFieldValues[nome] || conteudo || f3Post.conteudo}
                    email={f3Post.email}
                    requisito={f3Post.requisito}
                    requisitocont={f3Post.requisitocont}
                    readonly={mode === 'view'}
                    placeholder={placeholder}
                    label={titulo}
                    name={nome}
                    onChange={(f3SelectReturn, trigger) => {
                      dispatch(updateGridFieldValues({ name: nome, value: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo }))
                      intersectionsF3Value(f3SelectReturn, { ...gridFieldValues, [nome]: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo })
                    }}
                    onChangeNewF3={(f3SelectReturn, trigger) => {
                      dispatch(updateGridFieldValues({ name: nome, value: f3SelectReturn[trigger[0]]}))
                      intersectionsNewF3Value(f3SelectReturn)
                      onTrigger(f3SelectReturn, nome, trigger)
                    }}
                  />
                }
              </Form.Field>
            )
          }
        }
        )
      }
    </Form>
  );
}

export default GridRestField;