import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 2rem;
  display: inline-block;
  text-align: left;
`;

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow-x: auto;
  display: flex;
  margin-top: 1.5rem; 
  box-shadow: 0 0 3px 2px #dedede;
`;

export const TableColumnText = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;

export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;

export const QuantityContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  text-align: center;
`;

export const QuantityInput = styled.input`
  width: 6rem;
  text-align: center; 
  outline: 0;
  border: 0;
  background: transparent;
  -webkit-appearance: none;

  &:hover {
    border-bottom-width:  2px;
    border-bottom-color: #ddd;
  }

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #d9dadc;
  border-radius: 8px;
  margin-right: 1rem;
`;

export const LegendStatus = styled.legend`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 0.5rem;
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0.4rem;
`;




