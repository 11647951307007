import { Button, Container, Icon, Input, Label,  Menu, Modal, Pagination, Popup, Select, Table } from 'semantic-ui-react';
import { Barcode, Code, Upload, CalendarPlus, Password, Eye, Trash, Printer, Pencil, Envelope, Copy } from 'phosphor-react';

import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState, useContext, useRef, useCallback } from 'react';

import { SelectedContainer } from '../Browser/styles';
import DatatableSubMenu from '../DatatableSubMenu';

import { ISubmenuItem } from '../../interfaces/Menu/IMenu';

import { EnvironmentReducerInitialState, updateItensPerPage, updateOrderBy, updateSelectedClient, updateSelectedRow } from '../../reducers/Environment';
import { MenuReducerInitialState, updateSelectedSubMenu } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';
import { ButtonTable, Checkbox, CheckMark, Fieldset, LegendsStatusContainer, LegendStatus, StatusGroup, TableColumnText, TableContainer, TableDataText } from './styles';
import { DataTableReducerInitialState, updateTableCurrentPage, updateTableSearching } from '../../reducers/DataTable';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { useCookies } from 'react-cookie';
import DatatableLoad from '../Datatable/DatatableLoad';
import DatatableField from '../Datatable/DatatableField';
import { FieldTypeConfig, FiltersType, IFilterParams, LegendType, TableData, TableTitle } from '../../@types/Datatable';
import { ClientService } from '../../services/methods/ClientService';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { Utils } from '../../common/Utils';
import { UIContext } from '../../contexts/UIContext';
import { IErrorProps } from '../Error';
import Swal from 'sweetalert2';
import { debounce } from "../../common/debounce";

interface ISelectedClientModalProps {
  visible: boolean;
  onClose(): void;
  onConfirm(): void;
}


const SelectedClientModal: FC<ISelectedClientModalProps> = ({ visible, onClose, onConfirm }) => {
  const { selectedClient, orderBy, itensPerPage, currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const [currentClientSelected, setCurrentClientSelected] = useState(selectedClient)

  const dispatch = useDispatch()
  const enterKeyPressed = useRef(false)

  const [cookies, setCookie] = useCookies(['selected_client']);
  const { setUIComponentState } = useContext(UIContext);

  const clientService: ClientService = new ClientService();
  const utils = new Utils();

  const showLegendFilterInFooter = companyPolicies.find(policy => policy.property === 'showLegendFilterInFooter' && policy.policy_value === 'true');
  const useCompressedLegendFilterInFooter = companyPolicies.find(policy => policy.property === 'useCompressedLegendFilterInFooter' && policy.policy_value === 'true');
  const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51

  const [subMenu, setSubMenu] = useState<ISubmenuItem[]>(selectedMenu?.submenu);

  const [tableLoading, setTableLoading] = useState(false);
  const [tableTitles, setTableTitles] = useState<TableTitle>({} as TableTitle);
  const [tableOrder, setTableOrder] = useState<string[]>([])
  const [tableData, setTableData] = useState<TableData[]>([])
  const [tableLegend, setTableLegend] = useState<LegendType[]>([])
  const [tableTotalPages, setTableTotalPages] = useState(0)
  const [tableSearchText, setTableSearchText] = useState('')
  const [tableFieldFilters, setTableFieldFilters] = useState<FiltersType>({
    character: [],
    date: [],
    numeric: []
  } as FiltersType)
  const [tableCurrentPage, setTableCurrentPage] = useState(1)
  const [tableFilter, setTableFilter] = useState<IFilterParams>({ filter: undefined, statusFilter: undefined } as IFilterParams)
  const [tableFieldTypeConfig, setTableFieldTypeConfig] = useState<FieldTypeConfig>({} as FieldTypeConfig)
  const [tableOrderBy, setTableOrderBy] = useState('')
  const [changeItensPerPage, setChangeItensPerPage] = useState(0);

  async function getClientList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      setTableCurrentPage(page)
      setTableLoading(true)

      const response = await clientService.index(currentBranch.codigo, page, itensPerPage, token, order, currentCompany, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados); 

        setTableData(dados)
        setTableOrder(ordem.filter(campo => !oculto.find(oculto => oculto === campo)))
        setTableTitles(titulo)
        setTableLegend(legenda)
        setTableFieldFilters(filtros)
        setTableTotalPages(totalPages)
        setTableFieldTypeConfig(fieldTypeConfig)

      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setTableLoading(false)
    }
  }

  const getClientsWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      setTableFilter({ ...tableFilter, filter: searchString })
      setTableSearchText(searchValue)
      
      await getClientList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      setTableFilter({ ...tableFilter, filter: undefined })
      setTableSearchText('')

      await getClientList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function filterClient(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    setTableFilter({ filter, statusFilter })
    await getClientList(1, tableOrderBy, filter, statusFilter)
  }

  async function orderClient(order: string) {
    setTableOrderBy(order)
    await getClientList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  function handleOrderBy(column: string) {
    if (tableFieldFilters.character.includes(column)) {
      if (Object.keys(orderBy)[0] === column) {
        switch (orderBy[column]) {
          case "ascending":
            orderClient(`${column} DESC`)
            dispatch(updateOrderBy({ value: { [column]: "descending" } }))
            break;
          case "descending":
            orderClient(``)
            dispatch(updateOrderBy({ value: { [column]: null } }))

            break;
          case null:
            orderClient(`${column} ASC`)
            dispatch(updateOrderBy({ value: { [column]: "ascending" } }))

            break;
        }
      } else {
        orderClient(`${column} ASC`)
        dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
      }
    }

  }

  function handleCleanInputSearch() {
    document.querySelector<HTMLInputElement>('#inputSearchClientModal').value = ''
    getClientsWithTextSearch('enter', '');
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearchClientModal').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if(changeItensPerPage > 0) {
      getClientList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter);
    }
  }, [changeItensPerPage]) 

  useEffect(() => {
    if(visible) {
      getClientList(tableCurrentPage, tableOrderBy);
    }
  }, [visible]);
  
  return (
    <Modal
      style={{ fontFamily: styleSheet.mainFont }}
      onClose={() => !tableLoading && onClose()}
      closeOnDimmerClick={false}
      open={visible}
      size='fullscreen'
      closeIcon
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        Selecione um cliente
      </Modal.Header>
      <Modal.Content scrolling style={{ width: '100%', height: `${window.innerHeight - 450}px` }}>
        <Container style={{ width: '100%', height: `${window.innerHeight - 450}px` }}>
          <Menu style={{ boxShadow: '0 0 3px 2px #dedede' }} borderless>
            <Menu.Item>
              <Input
                id="inputSearchClientModal"
                className='icon'
                type="search" 
                loading={tableLoading}
                disabled={tableLoading}
                icon={tableSearchText.length !== 0 ? <Icon name='close' link onClick={handleCleanInputSearch} /> : <Icon name='search' link />}
                defaultValue={tableSearchText}
                onChange={(event) => getClientsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)}
                onBlur={(event) =>  getClientsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)}
                placeholder='Pesquisar...'
                style={{ width: '40rem' }} />
            </Menu.Item> 
          </Menu>

          <TableContainer>
            {
              tableLoading ? <DatatableLoad itensPerPage={itensPerPage - 2}/> :
              <Table celled compact sortable selectable color='teal'>
                <Table.Header>
                  <Table.Row >
                    {
                      tableOrder.map((column) => {
                        return (
                          <Table.HeaderCell
                            key={column}
                            className='dataHeaderTableFixed'
                            onClick={() => handleOrderBy(column)}
                            sorted={orderBy[column] || null}
                          >
                            <TableColumnText>
                              {tableTitles[column]}
                            </TableColumnText>
                          </Table.HeaderCell>
                        );
                      })
                    }
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    tableData.map((row) => {
                      return (
                        <Table.Row key={row.indice} onClick={() => {     
                          if(selectedClient.indice && row.indice === selectedClient.indice) {
                            setCurrentClientSelected({})
                          }else{
                            setCurrentClientSelected(row)
                          }
                        }} active={currentClientSelected.indice === row.indice}>
                          {
                            tableOrder.map((columnName) => {
                              return (
                                <Table.Cell width={!!tableLegend.find(({ campo }) => campo === columnName) ? 1 : 2} key={columnName}>
                                  {
                                    !!tableLegend.find(({ campo }) => campo === columnName) &&
                                    <Label 
                                      size="large"
                                      style={{
                                        background: tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]) ? tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]).cor : '#333',
                                        color: '#fff'
                                      }}
                                    >
                                      {/* {row[columnName.toLowerCase()].toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)} */}
                                      {row[columnName.toLowerCase()]}
                                    </Label>
                                  }
                                  {
                                    !tableLegend.find(({ campo }) => campo === columnName) &&
                                    <TableDataText>
                                      <DatatableField
                                        fieldName={columnName}
                                        fieldValue={row[columnName.toLowerCase()]}
                                      />
                                    </TableDataText>
                                  }
                                </Table.Cell>
                              );
                            })
                          }
                        </Table.Row>
                      );
                    })
                  }
                </Table.Body>
              </Table>
            }

          </TableContainer>
          <Menu borderless style={{ boxShadow: '0 0 3px 2px #dedede'}}>
            {
              showLegendFilterInFooter && (
                <Menu.Item>
                  {
                    tableLegend.map(({ dados, campo }) => {
                      return (
                        <Fieldset key={campo}>
                          <LegendStatus>
                            {tableTitles[campo]}
                          </LegendStatus>
                          <LegendsStatusContainer>
                            {
                              dados.map(({ nome, cor }) => {
                                return (
                                  <StatusGroup
                                    key={nome}
                                    cor={cor}
                                    onClick={() => {
                                      if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
                                        const status = {...tableFilter.statusFilter};
                                        delete status[campo]
                                        filterClient(tableFilter.filter, status)
                                      } else {
                                        filterClient(tableFilter.filter, { ...tableFilter.statusFilter, [campo]: nome })
                                      }
                                    }}
                                    isSelected={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome}
                                  >
                                    <Checkbox>
                                      <CheckMark cor={cor} checked={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome} />
                                    </Checkbox>

                                    {useCompressedLegendFilterInFooter ? nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2) : nome}
                                  </StatusGroup>
                                );
                              })
                            }
                          </LegendsStatusContainer>
                        </Fieldset>                    
                      )
                    })
                  }
                </Menu.Item>
              )
            }

            {
              !tableLoading && 
              <Menu.Item position="right">
                {
                  customizations['useQtdPaginationSelect'] ?  (
                    <Menu.Item position="right">
                      <Fieldset>
                        <LegendStatus>
                          QTD PAGINA
                          </LegendStatus>
                          <LegendsStatusContainer>
                            <Select 
                              placeholder="Quantidade por pagina"
                              defaultValue={itensPerPage}
                              options={ configuracoes.quantityperpage ? utils.generateItensPerPage(configuracoes.quantityperpage) : utils.generateItensPerPageDefault(window.innerHeight, rowSize) }
                              onChange={async (event, data) => {
                                dispatch(updateItensPerPage({ value: Number(data.value) } ));
                                dispatch(updateTableCurrentPage({ value: 1 }));
                                setChangeItensPerPage(changeItensPerPage + 1);
                              }}
                            />
                          </LegendsStatusContainer>
                        </Fieldset>
                    
                    </Menu.Item> 
                  ) : null
                }
                <Menu floated='right'>
                  <Pagination
                    // defaultActivePage={currentPage}
                    activePage={tableCurrentPage}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={tableTotalPages}
                    onPageChange={(ev, { activePage }) => getClientList(Number(activePage), tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
                  />
                </Menu>
              </Menu.Item>
            }
          </Menu>
        </Container>
      </Modal.Content>

      <Modal.Actions>
        <Button.Group  style={{ marginLeft: '1rem' }}>
          <Button size="big" onClick={onClose}>canelar</Button>
          <Button.Or text="ou" />  
          <Button 
            size="big" 
            color='green' 
            onClick={() => { 
              dispatch(updateSelectedClient({ value: currentClientSelected }))
              onConfirm()
            }}
          >
            Confirmar
          </Button> 
        </Button.Group>   
      </Modal.Actions>
    </Modal>
  );
}

export default SelectedClientModal;