import { Modal, Button, Table, Dimmer, Loader } from 'semantic-ui-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';

import { FinancialService } from '../../services/methods/FinancialService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { FieldTypeConfig, FiltersType, IFilterParams, LegendType, TableData, TableTitle } from '../../@types/Datatable';
import DatatableField from '../Datatable/DatatableField';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { fillFieldAndFieldValues } from '../../reducers/RestField';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { styleSheet } from '../../assets/styles/global';

import { TableColumnText, TableDataText, Title } from './styles';
import { ClosureService } from "../../services/methods/ClosureService";
import { IPaginatedServiceResponse } from "../../interfaces/Datatable/IPaginatedServiceResponse";
import { Utils } from "../../common/Utils";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import DatatableOrderService from "../Datatable/DatatableOrderService";
import { updateTableSearching } from "../../reducers/DataTable";

export interface IViewClosureModalProps {
  indice: string | number;
  visible: boolean;
  mode: string;
  filter?: string;
  onClose(): void;
}

const ViewClosureModal: React.FC<IViewClosureModalProps> = ({ indice, mode, visible, onClose, filter }) => {
  const fieldService: FieldService = new FieldService();
  const closureService: ClosureService = new ClosureService();

  const utils = new Utils();

  const { currentBranch, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

	const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51
  const itensPerPage = useMemo(() => Math.floor((window.innerHeight - 400) / rowSize), [rowSize, window])
  
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [tableTitles, setTableTitles] = useState<TableTitle>({});
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableLegend, setTableLegend] = useState<LegendType[]>([]);
  const [tableFieldTypeConfig, setTableFieldTypeConfig] = useState<FieldTypeConfig>({} as FieldTypeConfig);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldFilters, setFieldFilters] = useState<FiltersType>({
    character: [],
    date: [],
    numeric: []
  });
  const [filters, setFilters] = useState<IFilterParams>({ filter: undefined, statusFilter: undefined })
  const [orderBy, setOrderBy] = useState('');

  async function getClosureList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      setIsTableLoading(true);
      setCurrentPage(page)
      const response = await closureService.details(indice, currentCompany, currentBranch.codigo, page, itensPerPage, token, selectedClient.indice, order, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        setColumnOrder(ordem.filter(campo => !oculto.find(oculto => oculto === campo)));
        setTableTitles(titulo);
        utils.formatTableData(fieldTypeConfig, dados)
        setTableData(dados);
        setTableLegend(legenda);
        setFieldFilters(filtros)
        setTotalPages(totalPages);
        setTableFieldTypeConfig(fieldTypeConfig);
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsTableLoading(false)
      setIsLoading(false)

    }
  }

  const getClosureWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, fieldFilters.character, "contains", searchValueText);

      setFilters(oldState => ({
        ...oldState,
        filter: searchString,
      }))

      setSearchText(searchValue);
      await getClosureList(1, orderBy, searchString, filters.statusFilter);
    }
    else {
      setFilters(oldState => ({
        ...oldState,
        filter: undefined,
      }))
      setSearchText('');
      await getClosureList(1, orderBy, undefined, filters.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [orderBy, tableFieldTypeConfig, filters]);

  async function orderClosure(order: string) {
    setOrderBy(order)
    await getClosureList(1, order, filters.filter, filters.statusFilter)
  }

  async function onViewClosure(indice: string | number) {
    const selectedRow = tableData.find(item => item.indice === indice)
    
    if (selectedRow['zx_num'] === "") {
      return Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Essa agenda ainda não foi lançada',
      })
    }
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `/ospdf?scheduleKey=${selectedRow['zx_num']}&analista=${selectedRow['zx_coduser']}&token=${token}`
    hiddenElement.target = '_blank';
    hiddenElement.click();
  }

  useEffect(() => {
    setIsLoading(true)
    console.log(filter)
    getClosureList(1, orderBy, filter)
    setFilters(oldState => ({
      ...oldState,
      filter: filter,
    }))

  }, [indice, filter, visible])

  return (
    <Modal
      onClose={() => !isLoading && onClose()}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Header>
        <span>Visualização do Fechamentos</span>
      </Modal.Header>

      <Modal.Content scrolling>
      <DatatableOrderService
        isClientTable={false}
        data={tableData}
        loading={isTableLoading}
        order={columnOrder}
        title={tableTitles}
        legend={tableLegend}
        totalPages={totalPages}
        fieldTypeConfig={tableFieldTypeConfig}
        fieldFilters={fieldFilters}
        currentPage={currentPage}
        searchText={searchText}
        onPageChange={(page) => getClosureList(page, orderBy, filters.filter, filters.statusFilter)}
        onOrderBy={(value) => orderClosure(value)}
        statusFilter={filters.statusFilter}
        onStatusFilter={(status: { [key: string]: string } | undefined) => console.log(filters.filter, status)}
        onDatatableActionClick={(action, indice) => {
          if (action === 'view') onViewClosure(indice);
        }}
        onSearch={(value) => getClosureWithTextSearch(value)}
      />
      </Modal.Content>



      <Modal.Actions>
        {
          mode === 'view' &&
          <Button onClick={onClose} size='big'>Cancelar</Button>
        }
        {
          mode !== 'view' &&
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
          </Button.Group>
        }

      </Modal.Actions>
    </Modal>
  );
}

export default ViewClosureModal;