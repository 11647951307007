import styled, { css } from 'styled-components';
import { styleSheet } from '../../assets/styles/global';


export const LabelInput = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.mainFont};
`;

export const GroupsContainer = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  font-family: ${({ theme }) => theme.mainFont};
`;

interface GroupProps {
  isSelected: boolean;
}

export const Group = styled.a<GroupProps>`
  width: 23%;  
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: wrap;
  line-break: anywhere;
  border: 1px solid ${({ theme, isSelected }) => isSelected ? theme.successColor : theme.darkerGrey};
  border-radius: 4px;

  ${({ theme, isSelected }) => isSelected && css`
    /* background-color: ${theme.lighterGrey}; */
    box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                0 2px 4px rgba(0,0,0,0.07), 
                0 4px 8px rgba(0,0,0,0.07), 
                0 8px 16px rgba(0,0,0,0.07),
                0 16px 32px rgba(0,0,0,0.07), 
                0 32px 64px rgba(0,0,0,0.07);
  `};

  cursor: pointer;
`;