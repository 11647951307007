import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { User, Buildings } from 'phosphor-react';

import packageInfo from '../../../package.json';
import { Utils } from '../../common/Utils';

import { Container, FooterSpan, SelectedClientFooter, FooterSpanSelectedClient } from './style';
import { styleSheet } from '../../assets/styles/global';
import { useSelector } from 'react-redux';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';

const Footer: FC = () => {
  const utils = new Utils();
  const { currentBranch, selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
 
  const [ clockState, setClockState ] = useState('');
  const [ environment, setEnvironment ] = useState('');
  const [ buildName, setBuildName ] = useState('');

  function getBuildName(): string {
    return `${ packageInfo.client.toLowerCase() }${ packageInfo.buildCode }${ utils.getEnvironment().substring(0, 3).toLowerCase() }`
  }

  async function updateDate() {
    const date = moment().format('DD/MM/YYYY HH:mm:ss');
    setClockState(date.toString());
  }

  useEffect(() => {
    const interval = setInterval(() => {
      updateDate()
    }, 1000);

    setEnvironment(utils.getEnvironment());
    setBuildName(getBuildName());
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <SelectedClientFooter>
        <User size={20} color={ styleSheet.shape } style={{ marginLeft: '1rem', padding: '0.3rem 0' }} />
        <FooterSpanSelectedClient>{ selectedClient.a1_nome || selectedClient.a1_nreduz || 'N/A' }</FooterSpanSelectedClient>
        <Buildings size={20} color={ styleSheet.shape } style={{ marginLeft: '1rem', padding: '0.3rem 0' }} />
        <FooterSpanSelectedClient>{ currentBranch.nome || currentBranch.codigo }</FooterSpanSelectedClient>
      </SelectedClientFooter>
      <FooterSpan>
        { clockState } - { packageInfo.client } ( { environment } ) - { packageInfo.version } - {buildName}
      </FooterSpan>
    </Container>
  );
}

export default Footer;