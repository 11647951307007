import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';
import { useSelector } from "react-redux";
import { AuthReducerInitialState } from "../../../reducers/Auth";

interface IPizzaBarraChartProps {
  data: Array<string | number | object | Array<string | number | object>>;
  keys?: string[];
  isFullscreen?: boolean;
  filter?: (value: string) => void;
}

const PizzaBarraChart: FC<IPizzaBarraChartProps> = ({ data, keys, isFullscreen = false, filter }) => {
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const keysPie = keys.slice(0, keys.findIndex(key => key === '-'))
  const keysBar = keys.slice(keys.findIndex(key => key === '-') + 1, keys.length)

  const typeOfChartValues = Array.isArray(data[1]) ? data[1].find((item) => item['group']) ? data.find((item) => item)['group'] : '' : '';

  const utils = new Utils();

  return (
    <div style={ { width: '100%', flex: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' } }>
      <div style={ { width: '100%' } }>
        <Chart
          type="pie"
          width="100%"
          height={isFullscreen ? '250' : '100'}
          series={Array.isArray(data[0]) ? data[0].map(x => x[1]).slice(1) : []}
          options={{
            chart: {
              width: '100%',
              height: isFullscreen ? 250 : 100,
              defaultLocale: 'br',
              locales: utils.localeConfig,
              events: {
                dataPointSelection: function(event, chartContext, config) {
                  filter(config.w.config.labels[config.dataPointIndex])
                }
              }  
            },
            labels: keysPie,
            
          }}
        />
      </div>
      <div style={ { width: '100%' } }>
      <Chart
        type="bar"
        width="100%"
        height={ isFullscreen ? '500' : '150' }
        series={ data[1] as ApexAxisChartSeries }
        options={
          {
            chart: {
              width: '100%',
              height: isFullscreen ? '500' : '150',
              type: 'bar',
              defaultLocale: 'br',
              locales: utils.localeConfig,
              events: {
                dataPointSelection: function (event, chartContext, config) {
                  filter(config.w.config.xaxis.categories[config.dataPointIndex])
                }
              }
            },
            plotOptions: {
              bar: {
                // vertical: true,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
              formatter: (value, opts) => {
                // const type = opts.w.config.series[opts.dataPointIndex] !== undefined ? opts.w.config.series[opts.dataPointIndex]['group'] : ''
                return utils.formatDashboardLabel(value)
              },
              offsetX: 0,
              offsetY: isFullscreen ? -14 : -14,
              style: {
                fontSize: isFullscreen ? '14' : '10',
                colors: ['#000'],
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false,
            },
            xaxis: {
              categories: keysBar.slice(1),
            },
            yaxis: {
              labels: {
                show: true,
                formatter: (value) => {
                  return utils.formatDashboardYaxisPopup(value, typeOfChartValues)
                },
              }
            }
          }
        }
      />
      </div>
    </div>
  );
}

export default PizzaBarraChart;