import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Checkbox, Table, Input, Label, Icon, Button, Loader, Modal, InputOnChangeData, List, Grid, Segment } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import { styleSheet } from '../../assets/styles/global';
import { PolicieContext } from '../../contexts/PolicieContext';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { IPolicies, SettingsService } from '../../services/methods/SettingsService';
import { PolicyDependency } from '../PolicyDependency';
import { InputOptionContainer, LabelComponent, ToggleContent, LoaderContent, ToggleContainer, PolicyProperty } from './styles';

export interface IProps {
  data: IPolicies;
}


export const Policy = ({ data: { policy, policy_value, property, description, company, type, id, dependencie}}: IProps) => {
	const { companyPolicies, authToken } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const isADependency = companyPolicies.find(policie => policie.dependencie === policy)

  if(isADependency) {
    return <></>
  }

  const settingsService = new SettingsService();
  const { getCompanyPolicies } = useContext(PolicieContext);
  const [ isChecked, setIsChecked ] = useState(policy_value);
  const [ inputElement, setInputElement ] = useState('');
  const [ defaultValueApi, setDefaultValueApi ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleteLoading, setIsDeleteLoading ] = useState(false);
  const [ isLoadingDone, setIsLoadingDone ] = useState(false);
  const [ modalDeletePolicies, setModalDeletePolicies ] = useState(false);
  const [policyDependency, setPolicyDependency] = useState<IPolicies>();

  const handleOnBlur =  async (event: React.FocusEvent<HTMLInputElement>) => {
    try {
      const inputValue = event.currentTarget.value;
      if(inputValue === policy_value) return;
      setIsLoading(true);
      await settingsService.updateCompanyPolicies(company, authToken, id, inputValue);
      getCompanyPolicies();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false);
      setIsLoadingDone(true);
    }
  }

  const handleSwitch =  async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const newValue = isChecked === 'true' ? 'false' : 'true';
      setIsChecked(newValue);
      setIsLoading(true);
      await settingsService.updateCompanyPolicies(company, authToken, id, newValue);
      getCompanyPolicies();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false);
      setIsLoadingDone(true);
    }
  }

  const inputPolicieValue =  async () => {
    try {
      const defaultValueApiCopy = defaultValueApi;
      defaultValueApiCopy.push(inputElement);
      const newValue = defaultValueApiCopy.join(';')
      setIsLoading(true)
      await settingsService.updateCompanyPolicies(company, authToken, id, newValue);
      setInputElement('');
      getCompanyPolicies();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false);
      setIsLoadingDone(true);   
    }
  }

  const defaultValuePolicies = () => {
    const showDefaultValue = policy_value.split(';');
    setDefaultValueApi(showDefaultValue);
  }

  const handleRemoveLabel = async (index: number) => {
    try {   
      const defaultValueApiCopy = defaultValueApi;
      defaultValueApiCopy.splice(index, 1);
      setDefaultValueApi([...defaultValueApiCopy]);
      const newValue = defaultValueApiCopy.filter(policy => policy !== '').join(';');
      setIsLoading(true);
      await settingsService.updateCompanyPolicies(company, authToken, id, newValue);
      getCompanyPolicies();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {    
      setIsLoading(false);
      setIsLoadingDone(true);
    }
  }

  const handleRemovePolicy = async() => {
    try {
      setIsDeleteLoading(true);
      const { status } = await settingsService.deleteCompanyPolicies(id, authToken);
      if(policyDependency) {
        await settingsService.deleteCompanyPolicies(policyDependency.id, authToken);
      }
      if(status === 200) return getCompanyPolicies();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsDeleteLoading(false);
    }
  }

 useEffect(() => {
   if(type === 'options') {
    defaultValuePolicies();
   }
 }, [type]);

 useEffect(() => {
  if(isLoadingDone) {
    setTimeout(() => {
      setIsLoadingDone(false);
    }, 2000)
  }
 },[isLoadingDone])

  useEffect(() => {
    const currentPolicyDependency = companyPolicies.find(policy => policy.policy === dependencie);
    const fatherDependency = companyPolicies.find(policie => policie.dependencie === policy);
    if(currentPolicyDependency) {
      setPolicyDependency(currentPolicyDependency)
    }else if (fatherDependency) {
      setPolicyDependency(fatherDependency)
    }
  }, [])

  return (
    <>
      <List.Item>
        {/* <Table.Row key={ id }> */}
          {/* <Table.Cell width='2'> */}
          <Segment style={{ fontSize: '14px', boxShadow: '0 0 3px 2px #dedede' }}>
          <List.Content>
            <Grid celled>
              <Grid.Row>
                <Grid.Column style={{ alignSelf: 'center' }} width={3}>
                  <PolicyProperty>{ property }</PolicyProperty>
                </Grid.Column>

                <Grid.Column style={{ alignSelf: 'center' }} width={8}>
                  { description }
                </Grid.Column>

                <Grid.Column style={{ alignSelf: 'center' }} width={4}>
                  {type === 'boolean' && (
                // <Table.Cell textAlign='center' width='1'>
                  <ToggleContainer>
                    <ToggleContent>
                      <span>Não</span>
                      <Checkbox
                        toggle
                        style={{ margin: '0 1rem' }}
                        checked={ isChecked === 'true' ? true : false }
                        onChange={ handleSwitch }
                        disabled={ isLoading }
                      />
                      <span>Sim</span>
                    </ToggleContent>
                    <LoaderContent>
                      {
                        isLoading && 
                          <Loader active inline />
                      }
                      {
                        isLoadingDone && 
                          <Icon name='check' color='green' />
                      }
                    </LoaderContent>
                  </ToggleContainer>
                // </Table.Cell>
                  )}

                  {type === 'text' && (
                    // <Table.Cell textAlign='center' width='1' >
                    <>
                      <Input 
                        style={{ width: '90%', textAlign: 'center', marginRight: '.65rem' }}
                        onBlur={ handleOnBlur }
                        defaultValue={ policy_value }
                      />
                      {
                        isLoading && 
                          <Loader active inline color='blue' />
                      }
                      {
                      isLoadingDone && 
                        <Icon name='check' color='green' />
                      }
                    </>
                    // </Table.Cell>
                  )}
                  {type === 'options' && (
                    // <Table.Cell textAlign='center' width='1'>
                      <InputOptionContainer>
                        <Input
                          action={{
                            color: 'blue',
                            labelPosition: 'right',
                            icon: 'plus',
                            content: 'Add',
                            onClick: () => { console.log() },
                          }}
                          placeholder='insira o valor...'
                          onChange={(_, data) => setInputElement(data.value)}
                          value={inputElement} />
                        <LabelComponent>
                          {defaultValueApi.map((option, index) => option !== '' &&
                          <Label
                            style={{ backgroundColor: styleSheet.contrastColor, color: styleSheet.shape }}
                          >
                            {option}
                            <Icon
                              name='delete'
                              onClick={() => handleRemoveLabel(index)} />
                          </Label>
                          )}
                          {isLoading &&
                            <Loader active inline />}
                          {isLoadingDone &&
                            <Icon name='check' color='green' />}
                        </LabelComponent>
                      </InputOptionContainer>
                    // </Table.Cell>
                  )}
                </Grid.Column>

                <Grid.Column style={{ alignSelf: 'center' }} textAlign="center" width={1}>
                  <Button 
                    style={{ background: 'none', margin: '0' }}
                  >
                    {
                        isDeleteLoading ?  
                          <Loader active inline />
                          :
                          <Icon
                          name='trash alternate outline'
                          color='red'
                          size='large'
                          onClick={ () => setModalDeletePolicies(true) }
                          loading={ isDeleteLoading }
                        />
                      }
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Content>
          <List.List>
            {
              policyDependency && <PolicyDependency data={policyDependency}/>
            }
          </List.List>
          </Segment>

      </List.Item>


      <Modal
        size='mini'
        open={ modalDeletePolicies }
        onClose={ () => setModalDeletePolicies(false) }
      >
        <Modal.Header>Deletar Regra de Negócio</Modal.Header>
        <Modal.Content style={{ fontSize: '1.5rem' }}>
          <p>Tem certeza que deseja deletar esta regra?</p>
          {
            policyDependency && <p>Esta regra tem dependência <strong>"{policyDependency.property}"</strong> que será excluída também </p>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ () => setModalDeletePolicies(false) }>
            Não
          </Button>
          <Button positive onClick={ handleRemovePolicy }>
            Sim
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

