import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class OrderService {
  public async index(companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wspedido', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getData(companyId: string, branchId: string, authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wspedido/${indice}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getItens(companyId: string, branchId: string, authToken: string, selectedOrder: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wspeditem/${selectedOrder}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async postFastOrder(branchId: string, companyId: string, authToken: string, data: string, name: string, typeaction: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsfastorder`, {
        data,
        typeaction,
        name,
      }, { params: { companyId, branchId, token: authToken } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async confirmationFastOrder(branchId: string, companyId: string, authToken: string, name: string, typeaction: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsfastorder`, {
        name,
        typeaction,
        
      }, { params: { companyId, branchId, token: authToken } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async cleanFastOrder(branchId: string, companyId: string, authToken: string, name: string, typeaction: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsfastorder`, {
        name,
        typeaction,
        companyId
      }, { params: { companyId, branchId, token: authToken } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async delete(authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.delete(`wspedido/${indice}`, {
        data: {
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}