import { AxiosError, AxiosResponse } from "axios";
import loggerProvider from "../providers/logger.provider";

enum Environment {
  hml = 'Homologação',
  prod = 'Produção'
}

export class LogsService {
  public async registerAccessLog(client: string, user: string, status: boolean, environment: string, message:string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.post('/accesslog', {
        client,
        user,
        status,
        environment: Environment[environment],
        message,
        device: 'web'
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async createPageLog(client: string, user: string, page: string, environment: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.post('/pagelog', {
        client,
        user,
        page,
        environment: Environment[environment],
        device: 'web'
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async createOrderLog(client: string, user: string, type: string, orderNumber: string, orderJson: string, totalValue: number, itemsQuantity: number): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.post('/orderlog', {
        client, 
        user, 
        type, 
        orderNumber, 
        orderJson, 
        totalValue, 
        itemsQuantity,
        device: 'web'
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getAccessLogs(clientName: string, startDate?: string, endDate?: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.get(`/accesslog/${clientName}`, { params: { startDate: `${startDate}T00:00:00.000Z`, endDate: `${endDate}T23:59:59.999Z` } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getAllPageLogs(clientName: string, startDate?: string, endDate?: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.get(`/pagelog/${clientName}`, { params: { startDate: `${startDate}T00:00:00.000Z`, endDate: `${endDate}T23:59:59.999Z` } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getOrderLogs(clientName: string, startDate?: string, endDate?: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.get(`/orderlog/${clientName}`, { params: { startDate: `${startDate}T00:00:00.000Z`, endDate: `${endDate}T23:59:59.999Z` } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getOrdersValuesGroupedByClient(startDate?: string, endDate?: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.get(`/orderlog/sum/all`, { params: { startDate: `${startDate}T00:00:00.000Z`, endDate: `${endDate}T23:59:59.999Z` }});
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getOrdersValuesGroupedByUsers(clientName: string): Promise<AxiosResponse> {
    try {
      const response = await loggerProvider.get(`/orderlog/sum/${clientName}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}