import { AxiosError, AxiosResponse } from "axios";
import { IFieldValues } from "../../components/FormAttendanceModal";
import { IObjectParams } from "../../interfaces/Product/IProduct";
import protheusProvider from "../providers/protheus.provider";

export class OsService {
  public async index(branchId: string, page: number, pageSize: number, authToken: string, order: string, customerKey: string, companyId: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('WSORDEMSERVICO', {
        params: {
          branchId,
          companyId,
          page,
          pageSize,
          token: authToken,
          order,
          customerKey,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getAttendance(numberOs: string | number, branchId: string, page: number, pageSize: number, authToken: string, order: string, companyId: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsatendos', {
        params: {
          branchId,
          companyId,
          page,
          pageSize,
          numberOs,
          token: authToken,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getItens(companyId: string, branchId: string, authToken: string, selectedOrder: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsositem/${selectedOrder}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getOrderContent(indice: string | number, branchId: string,  authToken: string, companyId: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsordemservico/${indice}`, {
        params: {
          branchId,
          companyId,
          page: 1,
          pageSize: 1,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getAttendanceItens(authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const params = {
        token: authToken
      }
  
      const response = await protheusProvider.get(`wsatendositem/${indice}`, { params });
  
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response!;
    }
  }

  public async getAttendanceContent(indice: string | number, branchId: string,  authToken: string, companyId: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsatendos/${indice}`, {
        params: {
          branchId,
          companyId,
          page: 1,
          pageSize: 1,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getAttendanceData(authToken: string, indice: string | number,): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsatendos/${indice}`, {
        params: {
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getChecklistHeader(authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const params = {
        token: authToken
      }
  
      const response = await protheusProvider.get(`wsoschecklist/${indice}`, { params });
  
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async create(indice: string | number, companyId: string, branchId: string, authToken: string, objectParams: IObjectParams) {
    try {
      const response = await protheusProvider.post(`wsatendos`, {
        numberOs: `${indice}01`, 
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async createChecklist(indice: string | number, companyId: string, branchId: string, authToken: string, objectParams: IFieldValues[]): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsoschecklist`,{
        numberOs: `${indice}`,
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response!;
    }
  }

  public async update(companyId: string, branchId: string, indice: string | number, authToken: string, objectParams: IObjectParams) {
    try {
      const response = await protheusProvider.put(`wsatendos/${indice}`, {
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async delete(authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.delete(`wsatendos/${indice}`, {
        data: {
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async print(companyId: string, branchId: string, id: string, alias: string, token: string, orientation: string, typeprint?: string): Promise<AxiosResponse> {
    const objectParams ={
      alias,
      id,
      token,
    }

    if(orientation) objectParams['orientation'] = orientation;
    if(typeprint) objectParams['typeprint'] = typeprint.trim();

    const response = await protheusProvider.post(`WSPRINTDOC`, objectParams, { params: { companyId, branchId } });
    return response;
  }

  public async getProducts(authToken: string, pageSize: number, page: number, clientId: string, branchId: string, companyId: string, order: string, filters?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    const params = {
      branchId,
      companyId,
      customerKey: clientId,
      page,
      order,
      pageSize,
      token: authToken
    }

    if (filters) Object.assign(params, { filter: filters });
    if (statusFilter) Object.assign(params, { whereapiadp: statusFilter });

    const response = await protheusProvider.get(`wsosmateriais`, { params })

    return response;
  }

  public async getOs(os: number, analista: string, token: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`WSRESTOS/${os}`, {
        params: {
          analista,
          token,
          pyli: true
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response!;
    }
  }
}