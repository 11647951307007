import React, { FC, useEffect, useState } from "react";
import { CaretDown, CaretUp, WarningCircle, CheckCircle } from "phosphor-react";
import { Browser as BrowserIcon } from 'phosphor-react';
import {
  Menu,
  Table,
  Segment,
  List,
  Dropdown,
  Pagination,
  Input,
  Checkbox,
  Select,
  Button,
  DropdownItemProps,
} from "semantic-ui-react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import styled from "styled-components";
import { debounce } from "lodash";
import generateExcel from "zipcelx";
import { useSelector } from "react-redux";

import { Utils } from "../../../../common/Utils";
import {
  ILoginLogsChart,
  IOrderLogsChar,
  IPageLogsChart,
} from "../../../../interfaces/Logs/ILogin";
import { PolicieReducerInitialState } from "../../../../reducers/Policie";
import { styleSheet } from "../../../../assets/styles/global";

import { AccessCount, AccessCountContainer, AccessCountNumber, AccessCountText, Header, TableColumnText, TableContainer, TableDataText } from "./styles";
import PagesBarChart from "../PagesBar";
import Skeleton from "react-loading-skeleton";
import { LogsReducerInitialState } from "../../../../reducers/Logs";

interface IPageLogsProps {
  keys: string[];
  types: string[];
}

enum Titles {
  client = "Cliente",
  user = "Usuário",
  status = "Status",
  environment = "Ambiente",
  message = "Mensagem",
  page = "Página/Menu",
  requesterIp = "IP do Requisitante",
  createdAt = "Data/Hora",
  device = "Dispositivo",
  type = "Tipo",
  orderNumber = "Número",
  totalValue = "Valor Total",
  itemsQuantity = "Quantidade de itens",
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  height: 100%;
  padding: 1rem;

  /* .menu {
    position: absolute;
    top: 0px;
    right: 0px;
  } */

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  .inputSearch {
    width: 100%;
    height: 2.5rem;
    padding-left: 0.5rem;
    border-radius: 4px;
    border: 1px solid #aaa;
  }

  .pagination {
    padding: 0.5rem;
  }

  .buttonChangePage {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
    margin: 0 3px;
    cursor: pointer;
  }

  .pageQuantity {
    width: 10rem;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
  }
`;

const PageLogs: FC<IPageLogsProps> = ({ keys, types }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const utils = new Utils();
  const { logIsLoading, pageLogData } = useSelector((state: { logs: LogsReducerInitialState }) => state.logs);
  const { companyPolicies } = useSelector(
    (state: { policie: PolicieReducerInitialState }) => state.policie
  );

  const useThreeDecimalPlaces = companyPolicies.find(
    (policy) =>
      policy.property === "useThreeDecimalPlaces" &&
      policy.policy_value === "true"
  );

  const getBudgetsWithTextSearch = debounce(async () => {
    setReloadData(!reloadData);
  }, 2000);

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <Input
        style={{ width: "100%" }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          getBudgetsWithTextSearch();
        }}
        placeholder={`Pesquisar  ${count} registros...`}
      />
    );
  }

  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Input
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
            getBudgetsWithTextSearch();
          }}
          placeholder={`Min (${min})`}
          style={{
            width: "45%",
          }}
        />
        {/* to */}
        <Input
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
            getBudgetsWithTextSearch();
          }}
          placeholder={`Max (${max})`}
          style={{
            width: "45%",
          }}
        />
      </div>
    );
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = React.useMemo(
    () =>
      keys.map((item, index) => {
        return {
          Header: Titles[item],
          accessor: item,
          Filter:
            types[index] === "C"
              ? DefaultColumnFilter
              : NumberRangeColumnFilter,
          filter: types[index] === "C" ? "" : "between",
          Footer: () => {
            if (types[index] === "N") {
              const total = React.useMemo(() => {
                return page.reduce(
                  (sum, row) => parseFloat(row.values[item]) + sum,
                  0
                );
              }, [page.length]);

              return (
                <TableColumnText style={{ fontWeight: "bold" }}>
                  Total:{" "}
                  {utils.convertBrl(total, useThreeDecimalPlaces ? 3 : 2)}
                </TableColumnText>
              );
            } else {
              return "";
            }
          },
        };
      }),
    [reloadData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    allColumns,
    state: { pageIndex, pageSize },
  } = useTable<any>(
    {
      columns,
      data: pageLogData,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function getPageSize() {
    const pageSizes: DropdownItemProps[] = [];

    if (pageLogData.length > 50) {
      pageSizes.push({ text: "50", value: 50 });
    }
    if (pageLogData.length > 100) {
      pageSizes.push({ text: "100", value: 100 });
    }

    if (pageLogData.length > 250) {
      pageSizes.push({ text: "250", value: 250 });
    }

    if (pageLogData.length > 500) {
      pageSizes.push({ text: "500", value: 500 });
    }

    pageSizes.push({ text: pageLogData.length, value: pageLogData.length });

    return pageSizes;
  }

  function getHeader(column) {
    if (column.totalHeaderCount && column.totalHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      // const span = [...Array(column.totalHeaderCount - 1)].map(x => ({
      //   value: "",
      //   type: "string"
      // }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        // ...span
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: "download",
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    if (page.length > 0) {
      page.forEach((row) => {
        const dataRow = [];

        Object.values(row.cells).forEach(({ value }) =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          } as never)
        );

        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ]);
    }

    return generateExcel(config);
  }

  useEffect(() => {
    setReloadData(!reloadData);
  }, [pageSize, pageIndex]);

  return (
    <>
      <Header>
      <AccessCount>
        <BrowserIcon size={35} color={styleSheet.mainColor} />
        <AccessCountContainer>
          <AccessCountNumber>{page.length}</AccessCountNumber>
          <AccessCountText>páginas acessadas</AccessCountText>
        </AccessCountContainer>
      </AccessCount>
        
        <div style={{ flex: 1, height: '100%' }}>
          <PagesBarChart data={page} />
        </div>
          
      </Header>
      {
        logIsLoading ? <Skeleton baseColor='#DDD' inline height={`${window.innerHeight - 450}px`}  width="100%" count={1} /> 
        :
          <Styles style={{ height: `${window.innerHeight - 450}px` }}>
            <TableContainer style={{ height: `${window.innerHeight - 550}px` }}>
            <Table
              style={{ height: `${window.innerHeight - 350}px` }}
                {...getTableProps()}
                celled
                sortable
                selectable
                color="teal"
              >
                <Table.Header>
                  {headerGroups.map((headerGroup) => (
                    <Table.Row
                      style={{ position: "sticky", top: 0 }}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <Table.HeaderCell style={{ background: "#fff" }}>
                        <Menu.Item
                          style={{ textAlign: "center", fontSize: "1.5rem" }}
                        >
                          <Dropdown direction="right" item icon="bars" simple>
                            <Dropdown.Menu direction="right">
                              <Segment
                                style={{
                                  width: "15rem",
                                  boxShadow: "none",
                                  border: 0,
                                  padding: "10px 0",
                                  zIndex: 999999,
                                }}
                                floated="right"
                              >
                                <List relaxed selection>
                                  <List.Item as="label">
                                    <List.Content style={{ fontSize: "1.5rem" }}>
                                      <Checkbox
                                        onClick={() => setShowFilter(!showFilter)}
                                        type="checkbox"
                                        checked={showFilter}
                                      />{" "}
                                      Habilitar Filtros
                                    </List.Content>
                                  </List.Item>
                                  {allColumns.map((column) => (
                                    <List.Item
                                      as="label"
                                      htmlFor={column.id}
                                      key={column.id}
                                    >
                                      <List.Content style={{ fontSize: "1.5rem" }}>
                                        <Checkbox
                                          id={column.id}
                                          type="checkbox"
                                          {...column.getToggleHiddenProps()}
                                        />{" "}
                                        {column.id}
                                      </List.Content>
                                    </List.Item>
                                  ))}
                                </List>
                              </Segment>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Menu.Item>
                      </Table.HeaderCell>
                      {headerGroup.headers.map((column) => (
                        <Table.HeaderCell style={{ background: "#fff" }}>
                          <div
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            <TableColumnText>
                              {column.render("Header")}
                            </TableColumnText>

                            <span style={{ textAlign: "left" }}>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <CaretDown size={15} />
                                ) : (
                                  <CaretUp size={15} />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>

                          {showFilter && (
                            <div>
                              {column.canFilter ? column.render("Filter") : null}
                            </div>
                          )}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Header>

                <Table.Body {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <Table.Row {...row.getRowProps()}>
                        <Table.Cell></Table.Cell>
                        {row.cells.map((cell) => {
                          if (typeof cell.value === "boolean") {
                            return (
                              <Table.Cell style={{ textAlign: "center" }}>
                                <TableDataText>
                                  {cell.value ? (
                                    <CheckCircle
                                      size={25}
                                      color={styleSheet.successColor}
                                    />
                                  ) : (
                                    <WarningCircle
                                      size={25}
                                      color={styleSheet.dangerColor}
                                    />
                                  )}
                                </TableDataText>
                              </Table.Cell>
                            );
                          } else {
                            return (
                              <Table.Cell {...cell.getCellProps()}>
                                <TableDataText>{cell.render("Cell")}</TableDataText>
                              </Table.Cell>
                            );
                          }
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  {footerGroups.map((group) => (
                    <Table.Row
                      style={{ position: "sticky", bottom: 0, background: "#fff" }}
                      {...group.getFooterGroupProps()}
                    >
                      <Table.Cell></Table.Cell>
                      {group.headers.map((column) => (
                        <Table.Cell {...column.getFooterProps()}>
                          <TableDataText>{column.render("Footer")}</TableDataText>
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Footer>
              </Table>
            </TableContainer>

            <Menu borderless style={{ backgroundColor: "#F9FAFB" }}>
              <Menu.Item>
                <Button onClick={getExcel} color="green">
                  Excel
                </Button>
                {/* <Button size="big" style={{ position: 'absolute', top: '1rem', right: '3rem' }} onClick={getExcel} color='green'>Excel</Button> */}
              </Menu.Item>
              <Menu.Item position="right">
                <Menu.Item>
                  <Select
                    value={pageSize}
                    options={getPageSize()}
                    onChange={(e, data) => {
                      setPageSize(Number(data.value));
                    }}
                  />
                </Menu.Item>
                <Menu floated="right">
                  <Pagination
                    activePage={pageIndex + 1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={pageOptions.length}
                    onPageChange={(ev, { activePage }) => {
                      gotoPage(Number(activePage) - 1);
                    }}
                  />
                </Menu>
              </Menu.Item>
            </Menu>
          </Styles>
      }
    </>
  );
};

export default PageLogs;
