import { AxiosResponse } from "axios";
import { ICompanyStyleResponse } from "../../interfaces/Styles/IStyles";
import policiesProvider from "../providers/policies.provider";

interface ICompanyResponse {
  data: {
    id: number,
    name: string,
    environment: string,
    dns: string,
    created_at: string,
    updated_at: string
    deleted_at: string | null
  };
  error?:string;
}

interface ICompaniesResponse {
  data: Array<{
    created_at: string
    deleted_at: string | null
    dns: string
    environment: string
    id: number
    name: string
    updated_at: string
  }>
}

export interface IPolicies {
  id: number;
  policy_value: string;
  company: number;
  policy: number;
  created_at: string;
  updated_at: string;
  property: string;
  description: string;
  dependencie: number | null;
  type: 'text' | 'boolean' | 'options';
}

export interface ICustomization {
  id: number;
  customization_value: boolean;
  company: number;
  customization: number;
  created_at: string;
  updated_at: string;
  property: string;
  device: 'web' | 'mobile' | 'all';
  description: string;
  dependencie: number | null;
  type: 'text' | 'boolean' | 'options';
}

export interface IPoliciesList {
  id: number;
  property: string;
  description: string;
  default_value: string;
  type: 'text' | 'boolean' | 'options';
  dependencie: number | null;
}
export interface IPoliciesDropdown {
  key: string;
  value: string;
  text: string;
}

interface IPoliciesDropdownResponse {
  data: IPoliciesList[];
  error?: string;
}

interface ICompanyPolicies {
  policies: IPolicies[];
  error?: string;
}

interface ICompanyCustomizations {
  policies: ICustomization[];
  error?: string;
}

interface IUpdateCompanyPolicieResponse {
  status: 0 | 1;
  error?: string;
}


export class SettingsService {
  public async auth(username: string, password: string): Promise<AxiosResponse<{token: string, error?: string }>> {
    return await policiesProvider.post('auth' ,{ username, password });
  }

  public async getCompanies(token: string): Promise<AxiosResponse<ICompaniesResponse>> {
    return await policiesProvider.get(`company`, { headers: { 'x-access-token': token } } );
  }
  
  public async getCompany(dns: string, token: string): Promise<AxiosResponse<ICompanyResponse>> {
    return await policiesProvider.get(`company/dns`, { headers: { 'x-access-token': token, 'dns': dns } } );
  }

  public async getCompanyPolicies(companyId: number, token: string): Promise<AxiosResponse<ICompanyPolicies>> {
    return await policiesProvider.get(`policies/value/${companyId}` , { headers: { 'x-access-token': token } });
  }

  public async getCompanyCustomizations(companyId: number, token: string): Promise<AxiosResponse<ICompanyCustomizations>> {
    return await policiesProvider.get(`customizations/value/${companyId}` , { headers: { 'x-access-token': token } });
  }

  public async getPolicies(token: string): Promise<AxiosResponse<IPoliciesDropdownResponse>> {
    return await policiesProvider.get(`policies` , { headers: { 'x-access-token': token } });
  }

  public async getCustomizations(token: string): Promise<AxiosResponse<IPoliciesDropdownResponse>> {
    return await policiesProvider.get(`customizations` , { headers: { 'x-access-token': token } });
  }

  public async updateCompanyPolicies(companyId: number, token: string, policyId: number, value: string | boolean): Promise<AxiosResponse<IUpdateCompanyPolicieResponse>> {
    return await policiesProvider.put(`policies/value` , { companyId, policyId, value } ,{ headers: { 'x-access-token': token } });
  }

  public async updateCompanyCustomizations(companyId: number, token: string, customizationId: number, value: string | boolean): Promise<AxiosResponse<IUpdateCompanyPolicieResponse>> {
    return await policiesProvider.put(`customizations/value` , { companyId, customizationId, value } ,{ headers: { 'x-access-token': token } });
  }

  public async includeCompanyPolicies(companyId: number, token: string, policyId: number, value: string | boolean): Promise<AxiosResponse<IUpdateCompanyPolicieResponse>> {
    return await policiesProvider.post(`policies/value` , { companyId, policyId, value } ,{ headers: { 'x-access-token': token } });
  }

  public async deleteCompanyPolicies(id: number, token: string): Promise<AxiosResponse> {
    return await policiesProvider.delete(`policies/value/${id}` ,{ headers: { 'x-access-token': token } });
  }

  //separar componentes
  public async getCompanyStyles(companyId: number, token: string): Promise<AxiosResponse<ICompanyStyleResponse>> {
    return await policiesProvider.get(`styles/value/${companyId}` , { headers: { 'x-access-token': token } });
  }

  public async updateCompanyStyles(companyId: number, token: string, styleId: number, value: string): Promise<AxiosResponse<IUpdateCompanyPolicieResponse>> {
    return await policiesProvider.put(`styles/value` , { companyId, styleId, value: value } ,{ headers: { 'x-access-token': token } });
  }

  public async updateCompanyStylesAssets(companyId: number, token: string, base64: string, value: string): Promise<AxiosResponse<IUpdateCompanyPolicieResponse>> {
    return await policiesProvider.post(`styles/upload/value` , { companyId, value: value, base64 } ,{ headers: { 'x-access-token': token } });
  }

  public async getCompanyFiles(companyId: number, token: string) : Promise<AxiosResponse<{ data: string[], error?: string; }>> {
    return await policiesProvider.get(`assets/${companyId}`, { headers: { 'x-access-token': token } });
  }

  public async uploadCompanyFiles(companyId: number, fileName: string, base64: string, token: string) : Promise<AxiosResponse<{ error?: string; }>> {
    return await policiesProvider.post(`assets/upload`, { companyId, fileName, base64 }, { headers: { 'x-access-token': token } });
  }
 
  public async deleteCompanyFiles(companyId: number, fileName: string, token: string) : Promise<AxiosResponse<{ error?: string; }>> {
    return await policiesProvider.delete(`assets`, { headers: { 'x-access-token': token }, data: { companyId, fileName } });
  }
}
