import { Modal, Button, Table, Dimmer, Loader } from 'semantic-ui-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';

import { FinancialService } from '../../services/methods/FinancialService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { TableData, TableTitle } from '../../@types/Datatable';
import DatatableField from '../Datatable/DatatableField';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { fillFieldAndFieldValues } from '../../reducers/RestField';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { styleSheet } from '../../assets/styles/global';

import { TableColumnText, TableDataText, Title } from './styles';

export interface IViewFinancialModalProps {
  indice: string | number;
  visible: boolean;
  mode: string;
  onClose(): void;
}

const ViewFinancialModal: React.FC<IViewFinancialModalProps> = ({ indice, mode, visible, onClose }) => {
  const fieldService: FieldService = new FieldService();
  const financialService: FinancialService = new FinancialService();

  const { currentBranch, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const useTitleDetail = companyPolicies.find(policy => policy.property === 'useTitleDetail' && policy.policy_value === 'true');
  
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState<TableTitle>({} as TableTitle);
  const [order, setOrder] = useState<string[]>([]);
  const [data, setData] = useState<TableData[]>([]);

  async function getProjectFields() {
    const body: IRestFieldRequestData = {
      alias: 'SE1',
      alias2: '',
      url: '',
      tab: '1',
      usuario: '',
      indice: String(indice),
      ordem: 1,
      token: token,
    }

    const encodedBody = encode(JSON.stringify(body));
    const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

    if (status === 200) {
      dispatch(fillFieldAndFieldValues({ fields: data.campos }))
      if(useTitleDetail) {
        await getFinancialDetails()
      }else{
        setIsLoading(false)
      }
    } else {
      console.log('error')
      setUIComponentState('error', {
        visible: true,
        title: 'Ops...',
        message: 'Houve um erro ao buscar a lista de clientes.',
        statusHttp: status,
        urlHttp: request,
        onClose: () => {
          setUIComponentState('error', {
            visible: false,
            title: 'Ops...',
            message: data.msg,
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => { console.log() }
          })
        }
      } as IErrorProps);
      setIsLoading(false)
    }
  }

  async function getFinancialDetails() {
    const objectParam = {
      companyId: currentCompany,
      branchId: currentBranch.codigo,
      cliente: selectedClient.indice,
      companyID: '',
      indice: indice,
      ordem: '&$&1'
    }

    const response = await financialService.details(objectParam)
    const { dados, ordem, titulo } = response.data;
    if (response.status === 200) {
      if (response.data.status === "erro") {
        setTitle({})
        setOrder([])
        setData([])
      } else {
        setTitle(titulo)
        setOrder(ordem)
        setData(dados)
      }
    }
    else {

      setUIComponentState('error', {
        visible: true,
        title: 'Ops... Houve um erro inesperado!',
        message: response.data.errorMessage,
        statusHttp: response.status,
        urlHttp: response.request.responseURL,
        onClose: () => {
          setUIComponentState('error', {
            visible: false,
            title: 'Ops...',
            message: response.data.msg,
            statusHttp: response.status,
            urlHttp: response.request.responseURL,
            onClose: () => { console.log() }
          })
        }
      } as IErrorProps);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getProjectFields()
  }, [indice])

  return (
    <Modal
      onClose={() => !isLoading && onClose()}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Header>
        <span>Visualização do Financeiro</span>
      </Modal.Header>

      <Modal.Content scrolling>
        {
          useTitleDetail && <Title>Cabeçalho</Title>
        }
        <RestField  mode={mode} />
        {
          useTitleDetail &&
          <>
            <Title>Detalhes do título</Title>

            <Table size="large" celled>
              <Table.Header>
                <Table.Row>
                  {
                    order.map((column) => {
                      return (
                        <Table.HeaderCell
                          key={column}
                        >
                          <TableColumnText>
                            {title[column]}
                          </TableColumnText>
                        </Table.HeaderCell>
                      );
                    })
                  }
                </Table.Row>
              </Table.Header>
                <Table.Body>
                  {
                    data.map((row, index) => {
                      return (
                        <Table.Row key={index} style={{ cursor: 'pointer' }}>
                          {
                            order.map((columnName) => {
                              return (
                                <Table.Cell>
                                  {
                                    <TableDataText>
                                      <DatatableField
                                        fieldName={columnName}
                                        fieldValue={row[columnName]}
                                      />
                                    </TableDataText>
                                  }
                                </Table.Cell>
                              );
                            })
                          }
                        </Table.Row>
                      );
                    })
                  }
                </Table.Body>
            </Table>
          </>
        }
      </Modal.Content>



      <Modal.Actions>
        {
          mode === 'view' &&
          <Button onClick={onClose} size='big'>Cancelar</Button>
        }
        {
          mode !== 'view' &&
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
          </Button.Group>
        }

      </Modal.Actions>
    </Modal>
  );
}

export default ViewFinancialModal;