import { AxiosError, AxiosResponse } from "axios";
import { TableData } from "../../interfaces/Datatable/IPaginatedServiceResponse";
import protheusProvider from "../providers/protheus.provider";

interface IDetailsParams {
  branchId: string;
  cliente: string | number;
  companyId: string;
  indice: string | number;
  ordem: string;
}

export class FinancialService {
  public async index(companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wstitrec', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async sendTicket(url: string, branchId: string, companyId: string, objectParams: object, hasConfig: boolean, typeprint?: string): Promise<AxiosResponse> {   
    if(typeprint) objectParams['typeprint'] = typeprint.trim();
   
    try {
      const response = await protheusProvider.post(`${url}/${branchId}${hasConfig ? '/1' : ''}`, objectParams
      , { params: { companyId } });

      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async details(objectParams: IDetailsParams) {
    try {
      const response = await protheusProvider.get(`WSRETDETTIT`, { params: objectParams });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}