export enum UploadDescription {
  Empresas = 'A1_NREDUZ',
  Projetos = 'Z2_COD',
  OrdemServico = 'ATENDOS'
}

export enum UploadTable {
  Empresas = 'SA1',
  Vendedor = 'SA3',
  Projetos = 'SZ2',
  OrdemServico = 'AB9'
}

export enum PageTypeReg {
  Projetos = 'PROJETO',
  Empresas = 'CLIENTE',
  OrdemServico = 'ATENDOS',
  Vendedor = 'VENDEDOR'
}