import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';
import { useSelector } from "react-redux";
import { AuthReducerInitialState } from "../../../reducers/Auth";



interface IBarraDuplaChartProps {
  data: Array<string | number | object | Array<string | number | object>>;
  keys?: string[];
  isFullscreen?: boolean;
  filter?: (value: string) => void;
}

const BarraDuplaChart: FC<IBarraDuplaChartProps> = ({ data, keys, isFullscreen = false, filter }) => {
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);


  const utils = new Utils();
  let chartValuesTypeOne = '';
  let chartValuesTypeTwo = '';
  if (Array.isArray(data[0]) && Array.isArray(data[1])) {
    chartValuesTypeOne = data[0].find((item) => item['group']) ? data[0].find((item) => item)['group'] : '';
    chartValuesTypeTwo = data[1].find((item) => item['group']) ? data[1].find((item) => item)['group'] : '';
  } 

  return (
    <div style={ { width: '100%', flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' } }>
      <div style={ { width: '49%' } }>
        <Chart
          type="bar"
          width="100%"
          height={ isFullscreen ? '500' : '250' }
          series={ data[0] as ApexAxisChartSeries }
          options={
            {
              chart: {
                width: '100%',
                height: isFullscreen ? '500' : '250',
                type: 'bar',
                defaultLocale: 'br',
                locales: utils.localeConfig,
                events: {
                  dataPointSelection: function (event, chartContext, config) {
                    filter(config.w.config.xaxis.categories[config.dataPointIndex])
                  }
                }
              },
              plotOptions: {
                bar: {
                  // vertical: true,
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              dataLabels: {
                enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
                formatter: (value, opts) => {
                  return utils.formatDashboardLabel(value)
                },
                offsetX: 0,
                offsetY: isFullscreen ? -14 : -14,
                style: {
                  fontSize: isFullscreen ? '14' : '10',
                  colors: ['#000'],
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false,

              },
              xaxis: {
                categories: keys[0].slice(1),
              },
              yaxis: {
                labels: {
                  show: true,
                  formatter: (value) => {
                    return utils.formatDashboardYaxisPopup(value, chartValuesTypeOne)
                  },
                }
              }
            }
          }
        />
      </div>
      <div style={ { width: '49%' } }>
        <Chart
          type="bar"
          width="100%"
          height={ isFullscreen ? '500' : '250' }
          series={ data[1] as ApexAxisChartSeries }
          options={
            {
              chart: {
                width: '100%',
                height: isFullscreen ? '500' : '250',
                type: 'bar',
                defaultLocale: 'br',
                locales: utils.localeConfig,
                events: {
                  dataPointSelection: function (event, chartContext, config) {
                    filter(config.w.config.xaxis.categories[config.dataPointIndex])
                  }
                }
              },
              plotOptions: {
                bar: {
                  // vertical: true,
                  dataLabels: {
                    position: 'top',
                  },
                }
              },
              dataLabels: {
                enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
                formatter: (value, opts) => {
                  return utils.formatDashboardLabel(value)
                },
                offsetX: 0,
                offsetY: isFullscreen ? -14 : -14,
                style: {
                  fontSize: isFullscreen ? '14' : '10',
                  colors: ['#000'],
                }
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
              },
              tooltip: {
                shared: true,
                intersect: false,

              },
              xaxis: {
                categories: keys[1].slice(1),
              },
              yaxis: {
                labels: {
                  show: true,
                  formatter: (value) => {
                    return utils.formatDashboardYaxisPopup(value, chartValuesTypeTwo)
                  },
                }
              }
            }
          }
        />
      </div>
    </div>
  );
}

export default BarraDuplaChart;