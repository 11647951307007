

import React, { FC, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { EnvironmentReducerInitialState } from '../../../reducers/Environment';

interface IDatatableLoad {
  itensPerPage: number;
}

const DatatableLoad: FC<IDatatableLoad> = ({itensPerPage}) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Skeleton baseColor='#DDD' height={50}  width="100%"  count={itensPerPage + 1} />
    </div>
  );
}

export default DatatableLoad;