import { AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class EmailService {
  public async sendEmail(branchId: string, companyId: string, id: string, email: string, activeUserEmail: string, alias: string, nf: object, token: string,  hasConfig: boolean, typeprint?: string): Promise<AxiosResponse> {
    const objectParams ={
      alias,
      id,
      nf,
      token,
      email,
      activeuseremail: activeUserEmail
    }

    if(typeprint) objectParams['typeprint'] = typeprint.trim()
    const response = await protheusProvider.post(`WSENVPED/${branchId}${hasConfig ? '/1' : ''}`, objectParams, { params: { companyId } });
    return response;
  }

  public async danfe(sendType: string, branchId: string, companyId: string, id: string, email: string, activeUserEmail: string, alias: string, nf: object, token: string, hasConfig: boolean, orientation?: number, typeprint?: string): Promise<AxiosResponse> {
    const objectParams ={
      alias,
      id,
      nf,
      token,
      activeuseremail: activeUserEmail
    }

    if (sendType === 'mail') {
      objectParams['email'] = email
    }

    if(orientation) objectParams['orientation'] = orientation
    if(typeprint) objectParams['typeprint'] = typeprint.trim()
    const response = await protheusProvider.post(`WSRESTDANFEXML/${branchId}${hasConfig ? '/1' : ''}`, objectParams, { params: { companyId } });
    return response;
  }

  public async print(url: string, branchId: string, companyId: string, id: string, alias: string, nf: object, token: string,  hasConfig: boolean, typeprint?: string): Promise<AxiosResponse> {
    const objectParams ={
      alias,
      id,
      nf,
      token
    }

    if(typeprint) objectParams['typeprint'] = typeprint.trim()
    const response = await protheusProvider.post(`${url}/${branchId}${hasConfig ? '/1' : ''}`, objectParams, { params: { companyId } });
    return response;
  }
}