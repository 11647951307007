import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';



interface IBarraeChartProps {
  data: [string | number | object];
  keys?: string[];
  isFullscreen?: boolean;
}

const BarraeChart: FC<IBarraeChartProps> = ({ data, keys, isFullscreen = false }) => {
  const utils = new Utils();

  return (
    <Chart
      type="bar"
      width="100%"
      height={isFullscreen ? '500' : '250'}
      series={data as ApexAxisChartSeries}
      options={
        {
          chart: {
            width: '100%',
            height: isFullscreen ? '500' : '250',
            type: 'bar',
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: false
            },
          },
          xaxis: {
            type: 'numeric',
            categories: keys.map(item => Number(item)),
          },
          legend: {
            position: 'top',
            offsetY: 20
          },
          fill: {
            opacity: 1
          }
        }
      }
    />
  );
}

export default BarraeChart;