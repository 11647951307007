export const bucketURL = 'https://pyli.s3.amazonaws.com'

export const Alias = {
  empresas: 'SA1',
  pendencias: '',
  pedidos: 'SC5',
  orcamentos: 'SCJ',
  financeiro: 'SE1',
  usuarios: 'PW0',
  vendedor: 'SA3'
}

export const Alias2 = {
  pedidos: 'SC6',
  orcamentos: 'SCK',
}

export const FilterAlertMessage = {
  oneFilter: 'Preencha o campo para filtrar',
  manyFilters: 'Preencha todos os campos para filtrar'
}