import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, Transition, Input, Form, Checkbox } from 'semantic-ui-react';
import { IOpcoes } from '../../interfaces/Menu/IMenu';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';

import { Container, LabelInput } from './styles';
import { MenuReducerInitialState } from "../../reducers/Menu";

interface IEmailModalProps {
  visible: boolean;
  isSendEmail: boolean;
  options?: IOpcoes[];
  onClose(): void;
  handleSendEmail(email: string, type?: string): void;
}

const EmailModal: FC<IEmailModalProps> = ({ visible, isSendEmail,  onClose, handleSendEmail, options }) => {
  
  const { selectedClient, selectedRow } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const [email, setEmail] = useState('');
  const [checkboxValue, setCheckboxValue] = useState('');

  useEffect(() => {
    if (selectedMenu.nome === 'Fechamento') {
      setEmail( selectedRow.zl_email ? selectedRow.zl_email : selectedClient.a1_email ? selectedClient.a1_email : '')
    } else {
      if(selectedClient.a1_email) {
        setEmail(selectedClient.a1_email.toString())
      }
    }
    if(options && options.length > 0) {
      setCheckboxValue(options[0].tipo)
    }
  }, [visible])

  return (
    <Transition animation='fly up' visible={visible} unmountOnHide>
      <Modal
        closeIcon
        size="mini"
        onClose={onClose}
        open={visible}
      >
        <Container>
          <Modal.Header>
            <LabelInput>
              Digite o e-mail para onde deve ser enviado.
            </LabelInput>
          </Modal.Header>

          <Modal.Content>
            <Input
              style={{ width: '100%', marginTop: '1rem' }}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form style={{ marginTop: '15px' }}>
              {
                options && options.length > 0 && 
                  options.map(option => (
                    <Form.Field>
                      <Checkbox
                        style={{ fontSize: '14px' }}
                        radio
                        label={option.descricao}
                        value={option.tipo}
                        checked={checkboxValue === option.tipo}
                        onChange={(e, data) => setCheckboxValue(data.value.toString())}
                      />
                    </Form.Field>
                  ))
              }
            </Form>
          </Modal.Content>
        </Container>

        <Modal.Actions>
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button 
              onClick={() => {
                if(email === '') {
                  return toast.error('Preencha o email!')
                }
                setEmail('');
                handleSendEmail(email, checkboxValue);
              }} 
              positive
              disabled={isSendEmail}
              loading={isSendEmail}
            >
              Enviar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </Transition >
  );
}

export default EmailModal;