import { Utils } from './../../common/Utils';
import styled, { css } from "styled-components";
import { styleSheet } from "../../assets/styles/global";

const utils = new Utils();

export const MenuContainer = styled.div`
  height: 100vh;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to top right, ${({ theme }) => theme.mainColor}, hsl(${({ theme }) => utils.hexToHSL(theme.mainColor)}, 50%, 50%));

  overflow-y: auto;
  direction: rtl;


  transition: all 0.8s ease-out;

  ::-webkit-scrollbar {
    width: 8px;     
  }

  ::-webkit-scrollbar-track {
    background: linear-gradient(to top right, ${({ theme }) => theme.mainColor}, hsl(${({ theme }) => utils.hexToHSL(theme.mainColor)}, 50%, 50%));;    
    /* border-radius: 20px;        */

  }

  ::-webkit-scrollbar-thumb {
    background-color: #ddd;    
    border-radius: 20px;       
    border: 3px solid linear-gradient(to top right, ${({ theme }) => theme.mainColor}, hsl(${({ theme }) => utils.hexToHSL(theme.mainColor)}, 50%, 50%));;  

    &:hover {
      background-color: #ddd; 
    }
  }
`;

export const Logo = styled.img`
  height: 5rem;
  max-width: 100%;
  padding: 5px;
  object-fit: contain;
  z-index: 9999;
  margin-bottom: 10px;
  margin-top: 15px;
`;

export const Menus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 1rem 1rem 1rem 0;
  margin-left: 1rem;
  cursor: pointer;

  color: hsl(${({ theme }) => utils.hexToHSL(theme.mainColor)}, 100%, 80%);

  font-size: 1.2rem;
  
  ${({ isActive }) => isActive ? css`
    background: #F7F8FB;

    border-radius: 20px 0px 0px 20px;

    &::before {
      content: "";
      position: absolute;

      background-color: transparent;
      top: -50px;
      right: 5px;
      height: 50px;
      width: 25px;
      border-bottom-right-radius: 25px;
      box-shadow: 0 25px 0 0 #F7F8FB;
      transition: all 0.6s ease;
    }

    &::after {
      content: "";
      position: absolute;
      
      background-color: transparent;
      bottom: -50px;
      right: 5px;
      height: 50px;
      width: 25px;
      border-top-right-radius: 25px;
      box-shadow: 0 -25px 0 0 #F7F8FB;
      transition: all 0.6s ease;
    }
  ` : css`
    &:hover {
      font-size: 1.4rem;
      
      & svg {
        width: 24px;
        height: 24px;
        transition: all 0.4s ease-in;
      }
    }
  `};

  @media (max-width: 1360px) {
    padding: .5rem 1rem .5rem 0 !important;
  }
  transition: all 0.6s ease;
`;

export const MenuName = styled.span`
  text-transform: capitalize; 
  text-align: center;
  z-index: 9999;
`;

export const MenuFooter = styled.div`
  width: 100%;
  
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonTable = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  background: transparent;
  cursor: pointer;
`;

export const LogoModal = styled.img`
  width: 20rem !important;
  height: 8rem;
  align-self: center;
  margin-bottom: 3rem;
`;

export const ModalParagraph = styled.p`
  font-size: 1.8rem;
`;