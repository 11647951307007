import React, { FC } from 'react';

import ReactDOM from 'react-dom';

import ReactFC from 'react-fusioncharts';

import FusionCharts from 'fusioncharts';
import Maps from "fusioncharts/fusioncharts.maps";
import Brazil from 'fusionmaps/maps/fusioncharts.brazil';

import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Maps, Brazil, FusionTheme);

interface IGeoChartProps {
  data: [string | number | object | [string | number]];
  keys: string[];
  isFullscreen?: boolean;
}

const GeoChart: FC<IGeoChartProps> = ({ data, keys, isFullscreen = false }) => {
  return (
    <ReactFC
      type="brazil"
      width='100%'
      height={isFullscreen ? 500 : 250}
      dataFormat="json"
      dataSource={{
        chart: {
          caption: 'Teste',//data[0],//data[1][1],
          numbersuffix: "",
          includevalueinlabels: "1",
          showLabels: "1",
          formatNumberScale: "0",
          entityFillHoverColor: "#FFF9C4",
          theme: "fusion"
        },
        // Aesthetics; ranges synced with the slider
        colorrange: {
          "minvalue": "0",
          "code": "#FFE0B2",
          "gradient": "0.5",
          "color": [{
            "minvalue": keys[0],
            "maxvalue": keys[1],
            "color": "#4444ff"
          }]
        },
        // Source data as JSON --> id represents countries of world.
        data: data
      }}
    />
  );
}

export default GeoChart;