import { AxiosError, AxiosResponse } from "axios";
import { IEmailParams } from "../../interfaces/Support/Support";
import emailProvider from '../providers/email.provider';

export class SupportEmailService {
  public async send(emailParams: IEmailParams, token: string): Promise<AxiosResponse> {
    try{
    const response = await emailProvider.post('/mail', emailParams, { headers:{'x-access-token': token } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
    
  }


  public async authToken(auth: string): Promise<AxiosResponse> {
    try {
      const response = await emailProvider.post('/auth', {}, { headers:{ 'authorization': auth }});
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response
    }
  }

}