import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class UserService {
  public async index(companyId: string, branchId: string, page: number, pageSize: number, authToken: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsusuario', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async resetPassword(email: string, url: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`WSRESTESQUECI/${email}/${url}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}