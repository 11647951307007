import { AxiosError, AxiosResponse } from "axios";
import { IObjectParams } from "../../interfaces/Product/IProduct";
import protheusProvider from "../providers/protheus.provider";

export class ProjectService {
  public async index(branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, companyId: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsprojeto', {
        params: {
          branchId,
          companyId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async details(code: string | number) {
    try {
      const response = await protheusProvider.get(`WSRETCLIDESP/${code}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async create(authToken: string, subsidiaryId: string, companyId: string, restFieldData: object): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsppddata/${subsidiaryId}`, {
        alias: 'SA1',
        alias2: '',
        email: authToken,
        dados: restFieldData,
      }, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async update(authToken: string, subsidiaryId: string, companyId: string, restFieldData: object): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put(`wsppddata/${subsidiaryId}`, {
        alias: 'SA1',
        alias2: '',
        email: authToken,
        dados: restFieldData,
      }, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async createExpense(companyId: string, branchId: string, authToken: string, projeto: string | number, objectParams: IObjectParams): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`WSRETCLIDESP`, {
        projeto,
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async deleteExpense(indice: string | number,  companyId: string, branchId: string | number, authToken: string) {
    try {
      const response = await protheusProvider.delete(`WSRETCLIDESP/${indice}`,  {
        data: {
          token: authToken,
        },
        params: { 
          companyId,
          branchId
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}

