import React, { createElement } from "react";

import TimelineChart from "../TimelineChart";
import BarraeChart from "../BarraeChart";
import BarravChart from "../BarravChart";
import BarrahChart from "..//BarrahChart";
import PizzaChart from "../PizzaChart";
import TableChart from "../TableChart";
import TableAndOtherChart from "../TableAndOtherChart";
import DotsChart from "../DotsChart";
import GeoChart from "../GeoChart";
import BarraDuplaChart from "../BarraDuplaChart";
import PizzaBarraChart from "../PizzaBarraChart";

const fields = new Map();
fields.set("barrav", BarravChart);
fields.set("barrah", BarrahChart);
fields.set("barrae", BarraeChart);
fields.set("lista", TableChart);
fields.set("pizza", PizzaChart);
fields.set("pontos", DotsChart);
fields.set("timeline", TimelineChart);
fields.set("pizzalista", TableAndOtherChart);
fields.set("pizzabarra", PizzaBarraChart);
fields.set("barralista", TableAndOtherChart);
fields.set("barradupla", BarraDuplaChart);
fields.set("geo", GeoChart);

const Chart = ({ type, ...props }) => createElement(fields.get(type), props);

export default Chart;
