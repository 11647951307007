import { Header, Modal, Icon, Button, Accordion, Dimmer, Loader, Table } from 'semantic-ui-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { FieldService } from '../../services/methods/FieldService';
import { OsService } from '../../services/methods/OsService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { FieldTypeConfig, FiltersType, IFilterParams, LegendType, TableData, TableTitle } from '../../@types/Datatable';
import { IPaginatedItensServiceResponse, IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import DatatableOrderService from '../Datatable/DatatableOrderService';
import { IRestField } from '../../interfaces/Fields/IRestField';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { fillFieldAndFieldValues, RestFieldReducerInitialState } from '../../reducers/RestField';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { TableDataText, Title } from './styles';
import { toast } from 'react-toastify';
import AttachModal from '../AttachModal';
import moment from 'moment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import DatatableLoad from '../Datatable/DatatableLoad';
import DatatableField from '../Datatable/DatatableField';
import { debounce } from "../../common/debounce";
import { updateTableSearching } from "../../reducers/DataTable";

export interface IFormOrderServicesItensModalProps {
  indice: string | number;
  visible: boolean;
  mode: string;
  updateOrderServiceStatus(value: string): void;
  onAdd(): void;
  onView(indice: string | number): void;
  onClose(): void;
}

const FormOrderServicesItensModal: React.FC<IFormOrderServicesItensModalProps> = ({ indice, mode, visible, onAdd, updateOrderServiceStatus, onView, onClose }) => {
  const fieldService: FieldService = new FieldService();
  const osService: OsService = new OsService();
  
  const { currentBranch, currentCompany, itensPerPage, selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
	const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const dispatch = useDispatch();

  const useThreeDecimalPlaces = companyPolicies.find(policy => policy.property === 'useThreeDecimalPlaces' && policy.policy_value === 'true');
  const useTitleSettingsInOrderServiceScreen = companyPolicies.find(police => police.property === 'useTitleSettingsInOrderServiceScreen' && police.policy_value === 'true');
  const accordionsCloseByDefault = companyPolicies.find(police => police.property === 'accordionsCloseByDefault' && police.policy_value === 'true')

  const utils = new Utils();
  
  const { setUIComponentState } = useContext(UIContext);

  const [itensActive, setItensActive] = useState(false);
  const [headerActive, setHeaderActive] = useState(false);
  const [attendanceActive, setAttendanceActive] = useState(accordionsCloseByDefault ? false : true);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [tableTitles, setTableTitles] = useState<TableTitle>({});
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableLegend, setTableLegend] = useState<LegendType[]>([]);
  const [tableFieldTypeConfig, setTableFieldTypeConfig] = useState<FieldTypeConfig>({} as FieldTypeConfig);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldFilters, setFieldFilters] = useState<FiltersType>({
    character: [],
    date: [],
    numeric: []
  });
  const [filters, setFilters] = useState<IFilterParams>({ filter: undefined, statusFilter: undefined })
  const [orderBy, setOrderBy] = useState('');
  const [attachModalOpen, setAttachModalOpen] = useState(false);
  const [attendanceIndice, setAttendanceIndice] = useState<string | number>('');

  const [tableLoading, setTableLoading] = useState(false);

  const [itensColumnOrder, setItensColumnOrder] = useState<string[]>([]);
  const [itensTableTitles, setItensTableTitles] = useState<TableTitle>({});
  const [itensTableData, setItensTableData] = useState<TableData[]>([]);

  const [tableFields, setTableFields] = useState<IRestField[][]>([]);
  const [totalFields, setTotalFields] = useState<IRestField[][]>([]);
  const [tableFieldsTitle, setTableFieldsTitle] = useState<string[]>([])

  async function getOrderFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: "AB6",
        alias2: "AB7",
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token,
      }
  
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        await Promise.all([getOrderServiceData(data.campos),getOrderServiceItensList( data.campos1.item01)])
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function getOrderServiceData(fields: IRestField[]) {
    try {
      const response = await osService.getOrderContent(indice, currentBranch.codigo, token, currentCompany);
      const { dados } = response.data;
      if (response.status === 200) {
        const fieldsWithValues = fields.map(field => {
          const value = dados[0][field.nome.toLowerCase()]
          if(field.nome === 'AB6_XCLAOS') {
            updateOrderServiceStatus(value)
          }
          field.conteudo = value
          field.visual = "V"
          return field
        })
        
        dispatch(fillFieldAndFieldValues({ fields: fieldsWithValues }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getOrderServiceItensList(fieldsTable: IRestField[]) {
    try {
      setTableLoading(true);
  
      const response = await osService.getItens(currentCompany, currentBranch.codigo, token, indice);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, totalizadores } = response.data as IPaginatedItensServiceResponse;
  
      if (response.status === 200) {
        const tableFieldsWithValue: IRestField[][] = []
        dados.map((item: { [x: string]: any; }) => {
          const fields: IRestField[] = fieldsTable.map(field => {
            return {
              ...field,
              conteudo: item[field.nome.toLowerCase()]
            }
          })
          tableFieldsWithValue.push(fields)
        })
        const totalFieldsWithValue: IRestField[][] = []
  
        const totalFields: IRestField[] = fieldsTable.map(field => {
          return {
            ...field,
            conteudo: totalizadores.totRegistros[field.nome]
          }
        })
        totalFieldsWithValue.push(totalFields)
  
        const tableFieldsWithTitle = fieldsTable.map(item => item.titulo)
        setTableFieldsTitle(tableFieldsWithTitle)
        setTableFields(tableFieldsWithValue)
        setTotalFields(totalFieldsWithValue)
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setTableLoading(false);
    }
  }

  async function handleCreateOrUpdate() {
    try {

      let valido = true;
      let msg = '';

      fields.forEach((field) => {
        if(field.obrigado === 'S' && (field.tipo !== '4' && field.tipo !== '5' && field.tipo !== '6' )) {
          if (mode === 'edit') {
            if ((fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') && field.conteudo === '') {
              valido = false;
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          } else {
            if (fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') {
              valido = false;
              msg += `<li><strong>${field.titulo}</strog></li>`
            }
          }
        }
      })

      if (!valido) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
            <br>Campo(s) obrigatório(s) não preenchido(s)</br>
            <ul>
              ${msg}
            </ul>
          `,
        })
      }
      
      setIsLoading(true)
      if (mode === 'add') {

        const { data, status, request } = await osService.create(indice, currentCompany, currentBranch.codigo, token, fieldValues)
  
        if (status === 200 || status === 201) {
          onClose()
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
  
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      } else {
        const notChangedFields = {};
  
        fields.map(field => Object.assign(notChangedFields, { [field.nome]: field.conteudo }));
  
        const restFieldData = {
          ...notChangedFields,
          ...fieldValues
        }
  
        const { data, status, request } = await osService.update(currentCompany, currentBranch.codigo, indice,  token, restFieldData)
  
        if (status === 200 || status === 201) {
          onClose()
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
  
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally{
      setIsLoading(false)
    }
  }

  async function getAttendanceList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      setIsTableLoading(true);
      setCurrentPage(page)
      const response = await osService.getAttendance(indice, currentBranch.codigo, page, 5, token, order, currentCompany, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        setColumnOrder(ordem.filter(campo => !oculto.find(oculto => oculto === campo)));
        setTableTitles(titulo);
        utils.formatTableData(fieldTypeConfig, dados)
        setTableData(dados);
        setTableLegend(legenda);
        setFieldFilters(filtros)
        setTotalPages(totalPages);
        setTableFieldTypeConfig(fieldTypeConfig);
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsTableLoading(false)
    }
  }

  const getOrderServiceWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, fieldFilters.character, "contains", searchValueText);

      setFilters(oldState => ({
        ...oldState,
        filter: searchString,
      }))

      setSearchText(searchValue);
      await getAttendanceList(1, orderBy, searchString, filters.statusFilter);
    }
    else {
      setFilters(oldState => ({
        ...oldState,
        filter: undefined,
      }))
      setSearchText('');
      await getAttendanceList(1, orderBy, undefined, filters.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [orderBy, tableFieldTypeConfig, filters]);

  async function orderOrderServices(order: string) {
    setOrderBy(order)
    await getAttendanceList(1, order, filters.filter, filters.statusFilter)
  }

  async function handleDeleteAttendance(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")
    try {
      const { data } = await osService.delete(token, indice)
      
      if(data.status === 'ok') {
        const tableDataArr = [...tableData];
        const newTableData = tableDataArr.filter(item => item.indice !== indice);
        setTableData(newTableData)
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }else{
        toast.update(id, {render: data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });

      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function onAttachUserClick() {
    setAttachModalOpen(true)
  }

  useEffect(() => {
    setIsLoading(true);
    setIsTableLoading(true);
    getOrderFields()
    getAttendanceList(1, orderBy)
  }, [indice])

  return (
    <>
    <Modal
      onClose={() => !isLoading && onClose()}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Content scrolling>
        <Accordion fluid styled>
          <Accordion.Title
            active={headerActive}
            index={0}
            onClick={() => setHeaderActive(!headerActive)}
          >
            <Icon name='dropdown' />
            <Title>
              {
                useTitleSettingsInOrderServiceScreen 
                  && companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[0] 
                  ? companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[0] 
                  : 'Dados da abertura da O.S.'
              }
            </Title>
          </Accordion.Title>
          <Accordion.Content active={headerActive}>
            <RestField  mode={mode} /> 
          </Accordion.Content>

          <Accordion.Title
            active={attendanceActive}
            index={0}
            onClick={() => setAttendanceActive(!attendanceActive)}
          >
            <Icon name='dropdown' />
            <Title>
            {
              useTitleSettingsInOrderServiceScreen 
                && companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[1] 
                ? companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[1] 
                : 'Dados do Equipamento'
            }
            </Title>
          </Accordion.Title>

          <Accordion.Content active={attendanceActive}>
            {
              tableLoading ? <DatatableLoad itensPerPage={1} />
              : (
                <Table size="large" celled>
                  <Table.Header>
                    <Table.Row>
                      {
                        tableFieldsTitle.map(title => {
                          if (tableFields[0] && tableFields[0].find(field => field.titulo === title) && tableFields[0].find(field => field.titulo === title).browse !== 'N') {
                            return (
                              <Table.HeaderCell>{title}</Table.HeaderCell>
                            )
                          }
                        })
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      tableFields.map(fields => (
                        <Table.Row>
                          {
                            tableFieldsTitle.map(title => {
                              if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
                                return (
                                  <Table.Cell>
                                    {
                                      fields.find(field => field.titulo === title).type === 'currency' &&
                                        `R$ ${utils.convertBrl(fields.find(field => field.titulo === title).conteudo, useThreeDecimalPlaces ? 3 : 2)}`
                                    }
    
                                    {
                                      fields.find(field => field.titulo === title).type === 'date' &&
                                      fields.find(field => field.titulo === title).conteudo !== "" && 
                                        moment(fields.find(field => field.titulo === title).conteudo).format('DD/MM/YYYY') 
                                    }
    
                                    {
                                      fields.find(field => field.titulo === title).type !== 'date' && 
                                      fields.find(field => field.titulo === title).type !== 'currency' &&
                                      fields.find(field => field.titulo === title).conteudo 
                                    }
                                  </Table.Cell>
                                )
                              }
    
    
                            })
                          }
                        </Table.Row>
                      ))
                    }
                  </Table.Body>
                </Table>
              )
            }
          </Accordion.Content>

          <Accordion.Title
            active={itensActive}
            index={0}
            onClick={() => setItensActive(!itensActive)}
          >
            <Icon name='dropdown' />
            <Title>
            {
              useTitleSettingsInOrderServiceScreen 
                && companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[2] 
                ? companyPolicies.find(policy => policy.policy === useTitleSettingsInOrderServiceScreen.dependencie).policy_value.split(';')[2] 
                : 'Atendimentos'
            }
            </Title>

          </Accordion.Title>
          <Accordion.Content active={itensActive}>
            <DatatableOrderService
              isClientTable={false}
              data={tableData}
              loading={isTableLoading}
              order={columnOrder}
              title={tableTitles}
              legend={tableLegend}
              totalPages={totalPages}
              fieldTypeConfig={tableFieldTypeConfig}
              fieldFilters={fieldFilters}
              currentPage={currentPage}
              searchText={searchText}
              onPageChange={(page) => getAttendanceList(page, orderBy, filters.filter, filters.statusFilter)}
              onOrderBy={(value) => orderOrderServices(value)}
              statusFilter={filters.statusFilter}
              onStatusFilter={(status: { [key: string]: string } | undefined) => console.log(filters.filter, status)}
              onDatatableActionClick={(action, indice) => {
                setAttendanceIndice(indice)
                if (action === 'apontar') onAdd();
                if (action === 'delete') {
                  if(configuracoes.useconfirmationorder) {
                    return Swal.fire({
                      title: 'Confirmar ação?',
                      text: 'Você deseja excluir esse atendimento !',
                      icon: 'info',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim, confirmar!',
                      cancelButtonText: 'Cancelar'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDeleteAttendance(indice);
                      }
                    });
                  }else {
                    handleDeleteAttendance(indice);
                  }               
                }
                if (action === 'upload') onAttachUserClick();
                if (action === 'view') onView(indice);
              }}
              onSearch={ (value, noDebounce) => getOrderServiceWithTextSearch(noDebounce ? 'enter' : '', value) }
            />
          </Accordion.Content>
        </Accordion>
      </Modal.Content>

      <Modal.Actions>
          {
            mode === 'view' &&
            <Button onClick={onClose} size='big'>Cancelar</Button>
          }
          {
            mode !== 'view' &&
            <Button.Group size='big'>
              <Button onClick={onClose}>Cancelar</Button>
              <Button.Or text="ou" />
              <Button
                onClick={handleCreateOrUpdate}
                positive
                disabled={isLoading}
              >
                Salvar
              </Button>
            </Button.Group>
          }
      </Modal.Actions>
    </Modal>
    {
      attachModalOpen &&
      <AttachModal
        indice={attendanceIndice}
        open={attachModalOpen}
        onClose={() => {
          setAttachModalOpen(false)
          setAttendanceIndice('')
        }}
      />
    }
    </>
  );
}

export default FormOrderServicesItensModal;