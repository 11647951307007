import { Button, Dropdown, Form, Input, Popup, Table } from "semantic-ui-react";
import { Trash } from 'phosphor-react';
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import F3 from "../../F3";

import { AuthReducerInitialState } from "../../../reducers/Auth";

import { ButtonTable } from "./styles";
import { IRestField } from "../../../interfaces/Fields/IRestField";
import { IFieldValues } from "../../FormAttendanceModal";
import { Utils } from "../../../common/Utils";

interface IUpdateFieldsValuesProps {
  index: number;
  name: string;
  value: string | number;
}

interface OrderServiceItensRestFieldProps {
  mode: string;
  fields: IRestField[];
  itensFieldsValue: IFieldValues[];
  updateFieldValues({ index, name, value }: IUpdateFieldsValuesProps): void;
  deleteItemFieldsValue(index: number): void;
}

const OrderServiceItensRestField: FC<OrderServiceItensRestFieldProps> = ({ mode, fields, itensFieldsValue, updateFieldValues, deleteItemFieldsValue}) => {

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth - (window.innerWidth * 6 / 100))

  const utils = new Utils();

	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  function onTrigger(f3SelectedValue: INewF3Data, fieldKey: string, trigger: string[], idx: number) {
    const fieldIndex = fields.findIndex(field => field.nome === fieldKey) + 1
    trigger.shift();

    if(trigger.length > 0) {
      trigger.map((f3Key, index) => {
        const fieldTrigger = fields[fieldIndex + index]
        updateFieldValues({index: idx, name: fieldTrigger.nome, value: f3SelectedValue[f3Key] })
      })
    }
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth - (window.innerWidth * 6 / 100))
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };  
  }, [])

  return (
    <Table size="large">
      <Table.Header>
        <Table.Row>
          {
            fields.map(field => {
              let isValid = true
              isValid = utils.validationFormField(field.tipo, mode);
              isValid = configuracoes.usecustomerlevel && field.nivel ? customerLevel >= Number(field.nivel) : isValid

              if(isValid) {
                return (
                  <Table.HeaderCell ><p>{field.titulo} <span style={{ color: '#db2828' }}>{field.obrigado === 'S' ? '*' : ''}</span></p></Table.HeaderCell>
                )
              }
            })
          }
          <Table.HeaderCell className='dataHeaderTableFixedActions'>Ações</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          itensFieldsValue.map((item, index) => (
            <Table.Row className="tableProductRow">
              {
                fields.map(({ titulo, nome, obrigado, placeholder, tipo, type, options, f3, picture, f3Post, decimal = "2", conteudo, visual, len, fieldOccupationSize, nivel }) => {
                  let isValid = true
                  isValid = utils.validationFormField(tipo, mode);
                  isValid = configuracoes.usecustomerlevel && nivel ? customerLevel >= Number(nivel) : isValid

                  if(isValid) {
                    return (
                      <Table.Cell>
                        <Form.Field key={nome} required={obrigado === 'S'} style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? `${screenWidth * fieldOccupationSize / 100}px` : `32%` }}>
                          {
                            type === 'character' && !f3 &&
                            <Input
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem'  }}
                              placeholder={placeholder}
                              onFocus={e => e.target.select()}
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              disabled={utils.isFieldDisabled(mode, visual, tipo)}
                              onChange={
                                (ev, { value }) => { updateFieldValues({index: Number(item.index), name: nome, value: utils.pictureValidate(picture, value) }) }
                              }
                              value={item[nome] || conteudo}
                            />
                          }
                          {
                            type === 'number' &&
                            <Input
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem'  }}
                              placeholder={placeholder}
                              type="number"
                              min="0"
                              onFocus={e => e.target.select()}
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              disabled={utils.isFieldDisabled(mode, visual, tipo)}
                              onChange={
                                (ev, { value }) => {
                                  updateFieldValues({index: Number(item.index), name: nome, value})
                                }
                              }             
                              value={item[nome] || conteudo}
                              maxLength={len}
                            />
                          }
                          {
                            type === 'percent' &&
                            <Input
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem'  }}
                              placeholder={placeholder}
                              type="number"
                              icon="percent"
                              onFocus={e => e.target.select()}
                              min="0"
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              disabled={utils.isFieldDisabled(mode, visual, tipo)}
                              onChange={
                                (ev, { value }) => {
                                  updateFieldValues({index: Number(item.index), name: nome, value})
                                }
                              }             
                              value={item[nome] || conteudo}
                              maxLength={len}
                            />
                          }
                          {
                            type === 'date' &&
                            <Input
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem'  }}
                              placeholder={placeholder}
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              disabled={utils.isFieldDisabled(mode, visual, tipo)}
                              type="date"
                              onChange={
                                (ev, { value }) => { updateFieldValues({index: Number(item.index), name: nome, value}) }
                              }
                              defaultValue={item[nome] ? utils.formatDateDefault(item[nome].toString()) : utils.formatDateDefault(conteudo.toString())}
                              value={utils.formatDateDefault(String(item[nome] ? item[nome] : conteudo))}
                            />
                          }

                          {
                            type === 'time' &&
                            <Input
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem'  }}
                              placeholder={placeholder}
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              type={ mode === 'view' ? 'text' : 'time' }
                              onChange={
                                (ev, { value }) => { updateFieldValues({index: Number(item.index), name: nome, value}) }
                              }
                              value={item[nome] || conteudo}
                            />
                          }

  
                          {
                            type === 'currency' &&
                              <Input
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem'  }}
                                placeholder={placeholder}
                                type="text"
                                min="0"
                                onFocus={e => e.target.select()}
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                onChange={
                                  (ev, { value }) => {
                                    updateFieldValues({index: Number(item.index), name: nome, value: String(utils.unmaskBRLCurrency(value, decimal))})
                                  }
                                }             
                                value={utils.inputMaskBRLCurrency(Number(item[nome] || conteudo), decimal)}
                                maxLength={len}
                              />
                          }
                          {
                            type === 'combo' &&
                            <Dropdown
                              search
                              selection
                              placeholder={placeholder}
                              readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                              disabled={utils.isFieldDisabled(mode, visual, tipo)}
                              style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem'  }}
                              options={options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } })}
                              onChange={
                                (ev, { value }) => { updateFieldValues({index: Number(item.index), name: nome, value: String(value) }) }
                              }
                              value={item[nome] || conteudo}
                            />
                          }
                          {
                            type === 'character' && !!f3 &&
                            <F3
                              clientId={null}
                              token={token}
                              consulta={f3Post.consulta}
                              conteudo={item[nome] || f3Post.conteudo}
                              email={f3Post.email}
                              requisito={f3Post.requisito}
                              requisitocont={f3Post.requisitocont}
                              readonly={mode === 'view'}
                              placeholder={placeholder}
                              label={titulo}
                              name={nome}
                              onChange={(f3SelectReturn, trigger) => updateFieldValues({index: Number(item.index), name: nome, value: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo  }) }
                              onChangeNewF3={async (f3SelectReturn, trigger) => {
                                updateFieldValues({index: Number(item.index), name: nome, value: f3SelectReturn[trigger[0]] })
                                onTrigger(f3SelectReturn, nome, trigger, index)
                              }}
                            />
                          }
                        </Form.Field>
  
                      </Table.Cell>
                    )
                  }
                })
              }
              <Table.Cell className='dataTableFixed' width={1}>
                <Button.Group size='small'>
                  <Popup
                    trigger={
                      <ButtonTable 
                        color="#E47261"
                        onClick={() => {
                          deleteItemFieldsValue(Number(item.index) )
                        }}
                      >
                        <Trash size={20} color="#E47261" />
                      </ButtonTable>
                    }
                    content={<span style={{ fontSize: '1.3rem' }}>Deletar</span>}
                    inverted
                  />
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))
        }
      </Table.Body>

    </Table >
  )
}

export default OrderServiceItensRestField 
