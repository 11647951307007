import styled from "styled-components";

export const AccessCount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  algin-items: flex-start;
  justify-content: space-between;
  gap: 3rem;
`;

export const AccessCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const AccessCountNumber = styled.span`
  font-size: 2.8rem;
  color: ${({ theme }) => theme.defaultFontColor};
  font-weight: 600;
`;

export const AccessCountText = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.defaultFontColor};
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;
`;