import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';
import { useSelector } from "react-redux";
import { AuthReducerInitialState } from "../../../reducers/Auth";


interface IBarravChartProps {
  data: [string | number | object];
  keys?: string[];
  isFullscreen?: boolean;
  filter?: (value: string) => void;
}

const BarravChart: FC<IBarravChartProps> = ({ data, keys, isFullscreen = false, filter }) => {
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const utils = new Utils();
  const typeOfChartValues = data.find((item) => item['group']) ? data.find((item) => item)['group'] : '';

  return (
    <Chart
      type="bar"
      width="100%"
      height={ isFullscreen ? '500' : '250' }
      series={ data as ApexAxisChartSeries }
      options={
        {
          chart: {
            width: '100%',
            height: isFullscreen ? '500' : '250',
            type: 'bar',
            defaultLocale: 'br',
            locales: utils.localeConfig,
            events: {
              dataPointSelection: function (event, chartContext, config) {
                filter(config.w.config.xaxis.categories[config.dataPointIndex])
              }
            }
          },
          plotOptions: {
            bar: {
              // vertical: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
            formatter: (value, opts) => {
              // const type = opts.w.config.series[opts.dataPointIndex] !== undefined ? opts.w.config.series[opts.dataPointIndex]['group'] : ''
              return utils.formatDashboardLabel(value)
            },
            offsetX: 0,
            offsetY: isFullscreen ? -14 : -14,
            style: {
              fontSize: isFullscreen ? '14' : '10',
              colors: ['#000'],
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: keys.slice(1),
          },
          yaxis: {
            labels: {
              show: true,
              formatter: (value) => {
                return utils.formatDashboardYaxisPopup(value, typeOfChartValues)
              },
            }
          }
        }
      }
    />
  );
}

export default BarravChart;