import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { F3RequestData } from '../../@types/generic';
import { MagnifyingGlass } from 'phosphor-react';
import ModalF3 from './ModalF3';
import { FieldService } from '../../services/methods/FieldService';
import Swal from 'sweetalert2';
import { styleSheet } from '../../assets/styles/global';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { RestFieldReducerInitialState, updateFieldValues } from '../../reducers/RestField';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import NewModalF3 from './NewModalF3';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { CartReducerInitialState } from '../../reducers/Cart';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { CartContext } from "../../contexts/CartContext";

interface IF3Props extends F3RequestData {
  label: string;
  name: string;
  placeholder: string;
  readonly: boolean;
  icon?: JSX.Element;
  onChange(f3SelectedValue: F3Data[], trigger: string[]): void;
  onChangeNewF3(f3SelectedValue: INewF3Data, trigger: string[]): void;
}

const F3: FC<IF3Props> = ({ label, name, placeholder, readonly, icon, consulta, token, email, requisito, requisitocont, clientId, conteudo = '', onChange, onChangeNewF3 }) => {
  const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const {  searchItemSelected, searchResetOptionText } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch();
  const useNewF3 = companyPolicies.find(policy => policy.property === 'useNewF3' && policy.policy_value === 'true');
  const usePaymentConditionByTotalPrice = useMemo(() => companyPolicies.find(policy => policy.property === 'usePaymentConditionByTotalPrice' && policy.policy_value === 'true'), [companyPolicies]);


  const [modalF3State, setModalF3State] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [f3SelectedData, setF3SelectedData] = useState<F3Data[]>(null);
  const [newF3SelectedData, setNewF3SelectedData] = useState<INewF3Data>(null);

  const [columns, setColumns] = useState([]);
  const [f3Data, setF3Data] = useState([]);

  const [newColumns, setNewColumns] = useState<string[]>([]);
  const [newTitles, setNewTitles] = useState<INewF3Data>({} as INewF3Data)
  const [newF3Data, setNewF3Data] = useState<INewF3Data[]>([]);
  const [trigger, setTrigger] = useState<string[]>([]);
  const [field, setField] = useState<string[]>([]);

  const fieldService: FieldService = new FieldService();

  const {
    handleGetTotalPrice,
  } = useContext(CartContext);

  const inputRef = useRef<HTMLInputElement>() 
  
  const createRequisitoCont = (requisito: string): string | null => {

    if (requisito === "") return "";

    const referenceKey = "M->";

    const existsReferenceKey = requisito.includes(referenceKey);

    if (!existsReferenceKey) return "";

    const referenceField = Object.values(fields).find(({ nome }) => requisito.includes(nome, requisito.indexOf(referenceKey)));

    if (referenceField) {
      const { nome, titulo } = referenceField;

      if(!fieldValues[nome]) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: `O campo ${titulo} não foi informado, informe-os e tente novamente.`,
        })
        return null;
      }
      return requisito.replace(`M->${nome}`, `\"${fieldValues[nome].toString()}\"`)
    
    }

    return "";
  }

  async function getF3TableData() {
    try {
      setLoadingData(true);
      const newRequisitocont = createRequisitoCont(requisito)

      if(newRequisitocont === null) { 
        setLoadingData(false);
        return;
      }

      const total = usePaymentConditionByTotalPrice ? handleGetTotalPrice() : undefined
      const { data } = await fieldService.getF3(currentBranch.codigo, currentCompany, clientId, consulta, conteudo, email, requisito, newRequisitocont, token, false, total);
      const [titleList, dataList, trigger] = data;
  
      setLoadingData(false);
      setColumns(titleList);
      setF3Data(dataList);
      setTrigger(trigger)
      setModalF3State(true);
    } catch (error) {
      setLoadingData(false);
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getNewF3TableData() {
    try {
      setLoadingData(true);
      const newRequisitocont = createRequisitoCont(requisito);

      if(newRequisitocont === null) { 
        setLoadingData(false);
        return;
      }

      const total = usePaymentConditionByTotalPrice ? handleGetTotalPrice() : undefined
      const { data } = await fieldService.getF3(currentBranch.codigo, currentCompany, clientId, consulta, conteudo, email, requisito, newRequisitocont, token,  true, total);
      setLoadingData(false);
      setNewF3Data(data.data)
      setNewTitles(data.title)
      setNewColumns(Object.keys(data.title))
      setTrigger(data.setTrigger)
      setField(data.setField)
      setModalF3State(true);
    } catch (error) {
      setLoadingData(false);
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  function interception() {
    const useCleanDeliveryAddressOnChangeF3 = companyPolicies.find(policy => policy.property === 'useCleanDeliveryAddressOnChangeF3' && policy.policy_value !== '');

    if(useCleanDeliveryAddressOnChangeF3) {
      const fieldsName = useCleanDeliveryAddressOnChangeF3.policy_value.split(';');
      const fieldsToClean = companyPolicies.find(policie => policie.policy === useCleanDeliveryAddressOnChangeF3.dependencie).policy_value.split(';')
      const fieldName = selectedMenu.nome === 'Pedidos' ? name.replace('C5_', '') : name.replace('CJ_', '')
      if(fieldsName.includes(fieldName)) {
        fieldsToClean.map(fieldName => {
          dispatch(updateFieldValues({ name: `${selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ'}_${fieldName}` , value: '' }));
        })
      }
    }
  }

  useEffect(() => {
    if(configuracoes.usedirectproductsear) {
      setNewF3SelectedData(null);
      setF3SelectedData(null);
    }
  }, [searchResetOptionText])

  useEffect(() => {
    inputRef.current.value = String(useNewF3 ? newF3SelectedData ? newF3SelectedData[field[0]] : conteudo : f3SelectedData ? f3SelectedData[1].conteudo : conteudo)
  }, [newF3SelectedData, f3SelectedData])

  return (
    <>
      <Input
        action
        placeholder={placeholder}
        readOnly={true}
        style={{ width: '100%', height: '4rem' }}
      >
        <input
          ref={inputRef}
          id={name}
          style={{ flex: 1, background: '#f5f5f5', color: '#3c3c3c', width: '100%' }}
        />
        <Button 
          style={{ backgroundColor: styleSheet.mainColor}} 
          loading={loadingData} 
          disabled={readonly || loadingData} 
          onClick={() => {  
            if(useNewF3) {
              getNewF3TableData()
            } else {
              getF3TableData()
            }
            interception()
          }}
        >
          {icon || <MagnifyingGlass size={22} color='#fff' />}
        </Button>
      </Input>

      {
        useNewF3 ? (
          <NewModalF3
            title={label}
            titles={newTitles}
            visible={modalF3State}
            columns={newColumns}
            data={newF3Data}
            onClose={() => setModalF3State(false)}
            onSelect={async (f3SelectReturn) => {
              setNewF3SelectedData(f3SelectReturn);
              onChangeNewF3(f3SelectReturn, trigger)
            }}
          />
        ): (
          <ModalF3
            title={label}
            visible={modalF3State}
            columns={columns}
            data={f3Data}
            onClose={() => setModalF3State(false)}
            onSelect={async (f3SelectReturn) => {
              setF3SelectedData(f3SelectReturn);
              onChange(f3SelectReturn, trigger)
            }}
          />
        )
      }



    </>
  );
}

export default F3;