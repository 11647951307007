import { Header, Modal, Button, Table, Dimmer, Loader, Icon } from 'semantic-ui-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { ProjectService } from '../../services/methods/ProjectService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';

import { fillFieldAndFieldValues, RestFieldReducerInitialState } from '../../reducers/RestField';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { styleSheet } from '../../assets/styles/global';


export interface IViewProjectModalProps {
  indice: string | number;
  code: string | number;
  visible: boolean;
  mode: string;
  onClose(): void;
}

const ViewProjectModal: React.FC<IViewProjectModalProps> = ({ indice, code, mode, visible, onClose }) => {
  const fieldService: FieldService = new FieldService();
  const projectService: ProjectService = new ProjectService();
  
	const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

	const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const [itensActive, setItensActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)


  async function getExpenseFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: 'SZ8',
        alias2: '',
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token
      }
  
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        dispatch(fillFieldAndFieldValues({ fields: data.campos }))
        // await getProjectDetails()
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCreateOrUpdate() {
    try {
      setIsLoading(true)
      if (mode === 'add') {
        const { data, status, request } = await projectService.createExpense(currentCompany.toString(), currentBranch.codigo, token, indice, fieldValues)
  
        if (data.status === 'ok') {
          onClose()
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.msg,
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      } else {
        const notChangedFields = {};
  
        fields.map(field => Object.assign(notChangedFields, { [field.nome]: field.conteudo }));
  
        const restFieldData = {
          ...notChangedFields,
          ...fieldValues
        }
        
        const objectParams = {
          alias: 'SZ8',
          alias2: '',
          email: token,
          dados: restFieldData,
          indice: indice ? indice : "",
          ordem: "&$&1",
          url: "demo.ethosx.com.br"//window.location.href.split('/')[2]
        }
        const { data, status, request } = await fieldService.update(currentBranch.codigo, currentCompany, objectParams)
  
        if (data.status === 'ok') {
          onClose()
        } else {
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.msg,
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getExpenseFields()
  }, [indice])

  return (
    <Modal
      onClose={() => !isLoading && onClose()}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Modal.Header>
        Inclusão de Despesas
      </Modal.Header>
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Content scrolling>
        <RestField  mode={mode} />
      </Modal.Content>

      <Modal.Actions>
        {
          mode === 'view' &&
          <Button onClick={onClose} size='big'>Cancelar</Button>
        }
        {
          mode !== 'view' &&
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button
              onClick={handleCreateOrUpdate}
              positive
              disabled={isLoading}
            >
              Salvar
            </Button>
          </Button.Group>
        }

      </Modal.Actions>
    </Modal>
  );
}

export default ViewProjectModal;