import { createSlice } from '@reduxjs/toolkit'
import { IMenuItem, ISubmenuItem } from '../../interfaces/Menu/IMenu';
import { ILoadMenus, IUpdateMenuList, IUpdateMenuListArr, IUpdateMenuLoading, IUpdateSelectedMenu, IUpdateSelectedSubMenu } from './actions';

export interface MenuReducerInitialState {
  selectedMenu: IMenuItem;
  selectedSubMenu:ISubmenuItem;
  menuList: IMenuItem[];
  menuListArr: IMenuItem[];
  menuLoading: false;
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    selectedMenu: ({} as IMenuItem),
    selectedSubMenu: ({} as ISubmenuItem),
    menuList: ([] as IMenuItem[]),
    menuListArr: ([] as IMenuItem[]),
    menuLoading: false
  },
  reducers: {
    updateSelectedMenu: (state, action: IUpdateSelectedMenu) => {
      state.selectedMenu = action.payload.value;
      state.selectedSubMenu = {} as ISubmenuItem;
    },

    updateSelectedSubMenu: (state, action: IUpdateSelectedSubMenu) => {
      state.selectedSubMenu = action.payload.value;
    },

    updateMenuList: (state, action: IUpdateMenuList) => {
      state.menuList = action.payload.value;
    },

    updateMenuListArr: (state, action: IUpdateMenuListArr) => {
      state.menuListArr = action.payload.value;
    },

    updateMenuLoading: (state, action: IUpdateMenuLoading) => {
      state.menuLoading = action.payload.value;
    },

    loadMenus: (state, action: ILoadMenus) => {
      state.selectedMenu = action.payload.selectedMenu;
      state.menuList = action.payload.menuList;
      state.menuListArr = action.payload.menuListArr;
    } 

  }
})

export const { updateMenuList, updateMenuListArr, updateMenuLoading, updateSelectedMenu, updateSelectedSubMenu, loadMenus } = menuSlice.actions;
export default menuSlice.reducer; 