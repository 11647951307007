import {
  Navigate,
  Route,
  BrowserRouter,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { updateToken, signIn } from "../reducers/Auth";

import protheusProvider from "../services/providers/protheus.provider";

import { PolicieContext } from "../contexts/PolicieContext";
import { MenuContext } from "../contexts/MenuContext";

import OrderServiceSchedules from "../pages/OrderServiceSchedules";
import SalesPerson from "../pages/SalesPerson";
import Approvals from "../pages/Approvals";
import Financial from "../pages/Financial";
import Dashboard from "../pages/Dashboard";
import Schedules from "../pages/Schedules";
import Settings from "../pages/Settings";
import Projects from "../pages/Projects";
import Clients from "../pages/Clients";
import Budgets from "../pages/Budgets";
import Orders from "../pages/Orders";
import Login from "../pages/Login";
import Home from "../pages/Home";
import User from "../pages/User";
import Cart from "../pages/Cart";

import {
  updateCurrentBranch,
  updateCurrentCompany,
  updateSelectedClient,
} from "../reducers/Environment";
import { AuthReducerInitialState } from "../reducers/Auth";
import { updateCompanyRest } from "../reducers/Policie";
import { updateSelectedMenu } from "../reducers/Menu";

import Logs from "../pages/Logs";

import { ThemeProvider } from "styled-components";
import Closure from "../pages/Closure";
import { OSPDFView } from "../pages/PDFView";

function AuthValidator({ children }: { children: JSX.Element }) {
  const [navigateToLogin, setNavigateToLogin] = useState(false);
  const { token } = useSelector(
    (state: { auth: AuthReducerInitialState }) => state.auth
  );

  const { getMenuList } = useContext(MenuContext);

  const location = useLocation();
  const dispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies([
    "user_info",
    "current_company",
    "current_branch",
    "url_rest",
    "selected_client",
    "selected_menu",
  ]);
  useEffect(() => {
    protheusProvider.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {           
        if (error.response.data.errorMessage.includes("Invalid Token")) {
          removeCookie("user_info");
          removeCookie("current_branch");
          removeCookie("current_company");
          removeCookie("selected_client");
          removeCookie("selected_menu");
          setNavigateToLogin(true);
        }

        if (error.response.status === 400) {     
          error.response.data['msg'] = error.response.data.errorMessage.replaceAll('\r\n', '\n');
          return error.response;
        }   

        return Promise.reject(error);
      }
    );
  }, []);

  if (navigateToLogin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!token) {
    if (!cookies.user_info) {
      return <Navigate to="/" state={{ from: location }} replace />;
    } else {
      protheusProvider.defaults.baseURL = cookies.url_rest;
      getMenuList(cookies.user_info.token, cookies.selected_menu);
      dispatch(signIn(cookies.user_info));
      dispatch(updateToken({ value: cookies.user_info.token }));
      dispatch(updateCurrentCompany({ value: cookies.current_company }));
      dispatch(updateCurrentBranch({ value: cookies.current_branch }));
      dispatch(updateCompanyRest({ value: cookies.url_rest }));
      dispatch(updateSelectedMenu({ value: cookies.selected_menu }));
      dispatch(updateSelectedClient({ value: cookies.selected_client }));
      if (location.pathname === "/cart") {
        return (
          <Navigate
            to={`/${
              cookies.selected_menu.nome === "Pedidos" ? "orders" : "budgets"
            }`}
            state={{ from: location }}
            replace
          />
        );
      }
    }
  }

  return children;
}

const PyliRoutes: FC = () => {
  const { theme } = useContext(PolicieContext);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} caseSensitive />
          <Route
            path="*"
            element={
              <AuthValidator>
                <Home />
              </AuthValidator>
            }
          />
          <Route
            path="/home"
            element={
              <AuthValidator>
                <Home />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/clients"
            element={
              <AuthValidator>
                <Clients />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/approvals"
            element={
              <AuthValidator>
                <Approvals />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/orders"
            element={
              <AuthValidator>
                <Orders />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/closure"
            element={
              <AuthValidator>
                <Closure />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/budgets"
            element={
              <AuthValidator>
                <Budgets />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/cart"
            element={
              <AuthValidator>
                <Cart />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/financial"
            element={
              <AuthValidator>
                <Financial />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/users"
            element={
              <AuthValidator>
                <User />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/salesperson"
            element={
              <AuthValidator>
                <SalesPerson />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/dashboard"
            element={
              <AuthValidator>
                <Dashboard />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/settings"
            element={
              <AuthValidator>
                <Settings />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/projects"
            element={
              <AuthValidator>
                <Projects />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/schedules"
            element={
              <AuthValidator>
                <Schedules />
              </AuthValidator>
            }
            caseSensitive
          />
          {/* <Route path="/catalog" element={<AuthValidator><Catalog /></AuthValidator>} caseSensitive /> */}
          <Route
            path="/logs"
            element={
              <AuthValidator>
                <Logs />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/orderService"
            element={
              <AuthValidator>
                <OrderServiceSchedules />
              </AuthValidator>
            }
            caseSensitive
          />
          <Route
            path="/ospdf"
            element={
              <AuthValidator>
                <OSPDFView />
              </AuthValidator>
            }
          />
        </Routes>

      </BrowserRouter>
    </ThemeProvider>
  );
};

export default PyliRoutes;
