import { Header, Modal, Button, Dimmer, Loader, Accordion, Icon, Label, Menu, Table, Image, Select, Pagination, Container, Input, Form, Dropdown } from 'semantic-ui-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { FieldService } from '../../services/methods/FieldService';
import { OsService } from '../../services/methods/OsService';

import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';

import { fillFieldAndFieldValues, RestFieldReducerInitialState } from '../../reducers/RestField';
import { EnvironmentReducerInitialState, updateItensPerPage, updateOrderBy } from '../../reducers/Environment';
import { AuthReducerInitialState } from '../../reducers/Auth';


import { styleSheet } from '../../assets/styles/global';

import { Fieldset, LegendStatus, QuantityContainer, QuantityInput, TableColumnText, TableContainer, TableDataText, Title } from './styles';
import { IRestField } from '../../interfaces/Fields/IRestField';
import OrderServiceItensRestField from '../RestField/OrderServiceItensRestField';
import { Utils } from '../../common/Utils';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import Skeleton from 'react-loading-skeleton';
import DatatableField from '../Datatable/DatatableField';
import { DataTableReducerInitialState, loadTableData, resetDataTable, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableSearchText } from '../../reducers/DataTable';

import defaultProductImg from '../../assets/images/default-product.jpeg';
import { IProduct } from '../../interfaces/Product/IProduct';
import { toast } from 'react-toastify';
import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { CartReducerInitialState, updateDictionaryABA, updateProductValueList } from '../../reducers/Cart';
import { CartContext } from '../../contexts/CartContext';
import F3 from '../F3';
import { IDictionary } from '../../interfaces/Fields/IDictionary';
import { debounce } from "../../common/debounce";
import { LegendsStatusContainer } from "../FilterModal/styles";
import { TableLevel } from '../../@types/Datatable';

export interface IFieldValues {
  [field: string]: string | number;
}

export interface IFormAttendanceModalProps {
  indice: string | number;
  visible: boolean;
  mode: string;
  orderServiceStatus: string;
  onClose(reload: boolean): void;
  attendanceIndice?: string | number;
}

const FormAttendanceModal: React.FC<IFormAttendanceModalProps> = ({ indice, mode, orderServiceStatus, visible, onClose, attendanceIndice }) => {
  const fieldService: FieldService = new FieldService();
  const osService: OsService = new OsService();

  const utils = new Utils();

  const { currentBranch, currentCompany, itensPerPage, orderBy, selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { tableLoading, tableTitles, tableOrder, tableData, tableLegend, tableTotalPages, tableSearchText, tableFieldFilters, tableFieldTypeConfig, tableCurrentPage, tableFilter } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const {  dictionaryABA } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);

  const useTitleSettingsInAttendanceScreen = companyPolicies.find(police => police.property === 'useTitleSettingsInAttendanceScreen' && police.policy_value === 'true');
  const useConfirmationServiceOrderCreateWithEmptyItems = companyPolicies.find(police => police.property === 'useConfirmationServiceOrderCreateWithEmptyItems' && police.policy_value === 'true')
  const useDecimalProductQuantity = companyPolicies.find(police => police.property === 'useDecimalProductQuantity' && police.policy_value === 'true');
  const accordionsCloseByDefault = companyPolicies.find(police => police.property === 'accordionsCloseByDefault' && police.policy_value === 'true')
  const useGrid = companyPolicies.findIndex(policy => policy.property === 'useGrid' && policy.policy_value === 'true') !== -1;
  const useThreeDecimalPlaces = companyPolicies.find(policy => policy.property === 'useThreeDecimalPlaces' && policy.policy_value === 'true');
  const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51
  
  const dispatch = useDispatch();

  const enterKeyPressed = useRef(false)
  
  const { setUIComponentState } = useContext(UIContext);
  const {
    checkBalance,
    handleOpenProductDetail,
  } = useContext(CartContext);

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth - (window.innerWidth * 6 / 100))

  const [accordionHeaderActive, setAccordionHeaderActive] = useState(accordionsCloseByDefault ? false : true);
  const [itensActive, setItensActive] = useState(false);
  const [checklistActive, setChecklistActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemFields, setItemFields] = useState<IRestField[]>([]);
  const [itensFieldsValue, setItensFieldsValue] = useState<IFieldValues[]>([]);
  const [checklistItensFields, setChecklistItensFields] = useState<IRestField[]>([]);
  const [checklistFieldsValue, setChecklistFieldsValue] = useState<IFieldValues[]>([]);
  
  const [productsFiltered, setProductsFiltered] = useState<IProduct[]>([] as IProduct[]);
  const [orderByText, setOrderByText] = useState('');
  const [changeItensPerPage, setChangeItensPerPage] = useState(0);
  
  const [productListFields, setProductListFields] = useState<IFieldValues[]>([]);
  const [tableFields, setTableFields] = useState<IRestField[][]>([]);
  const [totalFields, setTotalFields] = useState<IRestField[][]>([]);
  const [tableFieldsTitle, setTableFieldsTitle] = useState<string[]>([]);

  const [productsSelected, setProductsSelected] = useState<IProduct[]>([]);
  const [showProductsSelected, setShowProductsSelected] = useState(false);

  async function getAttendanceFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: "AB9",
        alias2: "ABA",
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token,
      }
  
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        setItemFields(data.campos1.item01);
        if(mode !== 'add') {
          await Promise.all([getAttendanceData(data.campos), getAttendanceItens(data.campos1.item01)]);
        }else {
          dispatch(fillFieldAndFieldValues({ fields: data.campos }));
          await getProducts('')
        }   
        setIsLoading(false);
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const getAttendanceData = async (fields: IRestField[]) => {
    try {
      const response = await osService.getAttendanceData(token, indice)
      const { dados } = response.data;
      if(response.status === 200) {
        const fieldsWithValues = fields.map(field => {
          const value = dados[0][field.nome.toLowerCase()]
          field.conteudo = value === null ? "" : value;
          field.visual = "V"
          return field
        })
        dispatch(fillFieldAndFieldValues({ fields: fieldsWithValues }))
      }

    } catch (error) {
      console.log('Error', error)
    }
  }

  const getAttendanceItens = async (fieldsTable: IRestField[]) => {
    try {
      const response = await osService.getAttendanceItens(token, String(attendanceIndice))
      const { dados, totalizadores } = response.data;

      if(response.status === 200) {
        const tableFieldsWithValue: IRestField[][] = []
        dados.map((item: { [x: string]: string | number; }) => {
          const fields: IRestField[] = fieldsTable.map(field => {
            return {
              ...field,
              conteudo: item[field.nome.toLowerCase()]
            }
          })
          tableFieldsWithValue.push(fields)
        })
        const totalFieldsWithValue: IRestField[][] = []
  
        const totalFields: IRestField[] = fieldsTable.map(field => {
          return {
            ...field,
            conteudo: totalizadores.totRegistros[field.nome]
          }
        })
        totalFieldsWithValue.push(totalFields)
  
        const tableFieldsWithTitle = fieldsTable.map(item => item.titulo)
        setTableFieldsTitle(tableFieldsWithTitle)
        setTableFields(tableFieldsWithValue)
        setTotalFields(totalFieldsWithValue)
      }

    } catch (error) {
      console.log('Error', error)
    }
  }

  const getChecklistFields = async () => {
    try {
      const body: IRestFieldRequestData = {
        alias: "SZI",
        alias2: "",
        url: "",
        tab: "1",
        usuario: "",
        indice: '',
        ordem: "1",
        token: token,
      }
  
      // showLoading(`carregando campos do checklist`)
      
      const encodedBody = encode(JSON.stringify(body));
      const { data, status } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        setChecklistItensFields(data.campos)

        if(mode !== 'add') {
          await getChecklistData(data.campos);
        }     
      }
      else { }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      // hideLoading()
    }
  }

  const getChecklistData = async (fieldsTable: IRestField[]) => {
    try {
      const response = await osService.getChecklistHeader(token, indice)
      const { dados } = response.data;

      if(response.status === 200) {
        const tableFieldsWithValue: IFieldValues[] = []
        dados.map((item: { [x: string]: string | number; }, index) => {
          const itemFieldValue: IFieldValues = {}
          itemFieldValue['index'] = index;
          fieldsTable.map(field => {
            itemFieldValue[field.nome] = item[field.nome.toLowerCase()]
          })
          tableFieldsWithValue.push(itemFieldValue)
        })

        setChecklistFieldsValue(tableFieldsWithValue)
      }

    } catch (error) {
      console.log('Error', error)
    }
  }

  async function handleCreateOrUpdate() {
    try {

      let valido = true;
      let msg = '';

      fields.forEach((field) => {
        if(field.obrigado === 'S' && (field.tipo !== '5' && field.tipo !== '6' )) {
          if (mode === 'edit') {
            if ((fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') && field.conteudo === '') {
              valido = false;
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          } else {
            if (fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') {
              valido = false;
              msg += `<li><strong>${field.titulo}</strog></li>`
            }
          }
        }
      })

      if (!valido) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
            <br>Campo(s) obrigatório(s) não preenchido(s)</br>
            <ul>
              ${msg}
            </ul>
          `,
        })
      }

      productListFields.map(item => {
        if(valido) {
          itemFields.forEach((field) => {
            if(field.obrigado === 'S' && (field.tipo !== '5' && field.tipo !== '6' )) {
              if (mode === 'edit') {
                if ((item[field.nome] === undefined || item[field.nome] === '') && field.conteudo === '') {
                  valido = false;
                  msg += `<li><strong>${field.titulo}</strong></li>`
                }
              } else {
                if (item[field.nome] === undefined || item[field.nome] === '') {
                  valido = false;
                  msg += `<li><strong>${field.titulo}</strog></li>`
                }
              }
            }
          })
        }
      })

      if (!valido) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
            <br>Campo(s) obrigatório(s) não preenchido(s)</br>
            <ul>
              ${msg}
            </ul>
          `,
        })
      }

      if (checklistFieldsValue.length > 0 ) {
        checklistFieldsValue.map(item => {
          if(valido) {
            checklistItensFields.forEach((field) => {
              if(field.obrigado === 'S' && (field.tipo !== '5' && field.tipo !== '6' )) {
                if (mode === 'edit') {
                  if ((item[field.nome] === undefined || item[field.nome] === '') && field.conteudo === '') {
                    valido = false;
                    msg += `<li><strong>${field.titulo}</strong></li>`
                  }
                } else {
                  if (item[field.nome] === undefined || item[field.nome] === '') {
                    valido = false;
                    msg += `<li><strong>${field.titulo}</strog></li>`
                  }
                }
              }
            })
          }
        })
  
        if (!valido) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `
              <br>Campo(s) obrigatório(s) não preenchido(s)</br>
              <ul>
                ${msg}
              </ul>
            `,
          })
        }
      }

      const fieldValuesToSend = utils.despiseFieldsValue(fields, fieldValues)
      const itensFieldValuesToSend = utils.despiseItensFieldsValue(itemFields, productListFields)
      
      setIsLoading(true)
      if (mode === 'add') {

        const { data, status, request } = await osService.create(indice, currentCompany, currentBranch.codigo, token, {
          ...fieldValuesToSend,
          itens: itensFieldValuesToSend
        })
  
        if (status === 200 || status === 201) {

          if(checklistFieldsValue.length > 0) {
            await createChecklist();
          }

          onClose(true)
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
  
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      } else {
        const notChangedFields = {};
  
        fields.map(field => field.visual !== 'D' ?? Object.assign(notChangedFields, { [field.nome]: field.conteudo }));
  
        const restFieldData = {
          ...notChangedFields,
          ...fieldValuesToSend
        }
  
        const { data, status, request } = await osService.update(currentCompany, currentBranch.codigo, indice,  token, restFieldData)
  
        if (status === 200 || status === 201) {

          if(checklistFieldsValue.length > 0) {
            await createChecklist();
          }

          onClose(true)
          Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else {
  
          setUIComponentState('error', {
            visible: true,
            title: 'Ops...',
            message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
            statusHttp: status,
            urlHttp: request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: data.msg,
                statusHttp: status,
                urlHttp: request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally{
      setIsLoading(false)
    }
  }

  const createChecklist = async () => {
    setIsLoading(true)

    try {
      const { data, status, request } = await osService.createChecklist(indice, currentCompany,  currentBranch.codigo, token, checklistFieldsValue);

      setIsLoading(false)

      if (status === 200 || status === 201) {
        Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: data.errorMessage || data.msg || 'Ocorreu um erro desconhecido',
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: '',
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }

    } catch (error) {
      setIsLoading(false)
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } 

  }

  function handleAddChecklist() {
    if(!checklistActive) {
      setChecklistActive(true);
    }
    
    if (checklistFieldsValue.length === 0) {
    
      const checklistFieldWithValues = { index: 0 }
      checklistItensFields.map(field => {      
      checklistFieldWithValues[field.nome] = field.conteudo       
      })
      setChecklistFieldsValue([checklistFieldWithValues])
    }else{

      const checklistFieldWithValues = { index:checklistFieldsValue.length }

      checklistItensFields.map(field => {
        checklistFieldWithValues[field.nome] = field.conteudo;        
      })
      setChecklistFieldsValue([...checklistFieldsValue, { ...checklistFieldWithValues }])
    }
  }

  function updateChecklistFieldsListValue(index: number, name: string, value: string | number) {  
   const checklistFieldValuesCopy = [...checklistFieldsValue];
   checklistFieldValuesCopy.find(item => item.index === index)[name] = value
   setChecklistFieldsValue(checklistFieldValuesCopy)
  }

  function handleDeleteChecklist(index: number) {
    const checklistFieldValuesCopy = [...checklistFieldsValue];
    const itensFieldValuesFiltered = checklistFieldValuesCopy.filter(item => item.index !== index)
    setChecklistFieldsValue(itensFieldValuesFiltered)
  }

  const currentItemQuantity = (indice: string | number): number => {
    return (productListFields.find((item) => item.indice === indice) && itemFields.find(field => field.regra === 'quantidade').nome) ? parseFloat(String(productListFields.find((item) => item.indice === indice)[itemFields.find(field => field.regra === 'quantidade').nome] || 0)) : 0;
  }

  const currentItemValue = (indice: string | number, fieldName: string, conteudo: string | number): string | number => {
    return productListFields.find((item) => item.indice === indice) && productListFields.find((item) => item.indice === indice)[fieldName] ? productListFields.find((item) => item.indice === indice)[fieldName] : conteudo;
  }

  const valueWithConversion = (indice: string | number) => {
    const balance = tableData.find(item => item['indice'] === indice || item['b1_qe'])
    if(balance && (balance['b1_conv'] || balance['b1_qe'])) {
      return Number(balance['b1_conv'] || balance['b1_qe']);
    } else {
      return 1;
    }
  }

  const handleChangeQuantityProductValue = (value: number | string, indice: string | number) => {
    const productListFieldsCopy = [...productListFields]
    const selectedProduct = productsFiltered.find(product => product.indice === indice)
    if (value === '' || Number(value) === 0) {
      setProductListFields(productListFieldsCopy.filter(item => item.indice !== indice))
      setProductsSelected(prevState => prevState.filter(product => product.indice !== indice))
      return 
    }

    if (productListFieldsCopy.find(item => item.indice === indice)) {
      productListFieldsCopy.find(item => item.indice === indice)[itemFields.find(field => field.regra === 'quantidade').nome] = value
    } else {
      setProductsSelected(prevState => [...prevState, selectedProduct])
      const fieldsAlreadyFilled = {} 

      itemFields.forEach(field => {
        if (field.conteudo !== '') {
          fieldsAlreadyFilled[field.nome] = field.conteudo
        }
      })
      
      const product = {}
      for (const [key, value] of Object.entries(dictionaryABA)) {
        if (selectedProduct[value.toLowerCase()] !== undefined) {
          product[key.toUpperCase()] = selectedProduct[value.toLowerCase()]
        }
      }

      productListFieldsCopy.push({
        indice,
        ...fieldsAlreadyFilled,
        ...product,
        ['ABA_ITEM']: '&&',
        [itemFields.find(field => field.regra === 'quantidade').nome]: value,
      })
    }

    setProductListFields(productListFieldsCopy)
  }

  const handleChangeProductValue = (value: number | string, fieldName: string, indice: string | number) => {
    const productListFieldsCopy = [...productListFields]
    const selectedProduct = productsFiltered.find(product => product.indice === indice)

    if (productListFieldsCopy.find(item => item.indice === indice)) {
      productListFieldsCopy.find(item => item.indice === indice)[fieldName] = value
      // setProductsSelected(prevState => prevState.filter(product => product.indice !== indice))
    } else {
      const fieldsAlreadyFilled = {} 
      setProductsSelected(prevState => [...prevState, selectedProduct])

      itemFields.forEach(field => {
        if (field.conteudo !== '') {
          fieldsAlreadyFilled[field.nome] = field.conteudo
        }
      })

      const product = {}
      for (const [key, value] of Object.entries(dictionaryABA)) {
        if (selectedProduct[value.toLowerCase()] !== undefined) {
          product[key.toUpperCase()] = selectedProduct[value.toLowerCase()]
        }
      }

      productListFieldsCopy.push({
        ...fieldsAlreadyFilled,
        ...product,
        indice,
        ['ABA_ITEM']: '&&',
        [fieldName]: value,
      })
    }
    setProductListFields(productListFieldsCopy)
  }

  const getProducts = async (order: string) => {
    // const toastId = toast.loading('Por favor aguarde...' )

    try {
      dispatch(updateTableLoading({ value: true })); 
      const { data, status, request } = await osService.getProducts(token, itensPerPage, 1, selectedClient.indice, currentBranch.codigo, currentCompany, order, undefined, undefined);
      const { titulo, dados, legenda, filtros, totalPages, ordem, fieldTypeConfig, dicionary, oculto, help, nivel }: IPaginatedServiceResponse = data;
      if (status === 200) {
        if(!configuracoes.usedirectproductsear) {
          const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

          dispatch(loadTableData({
            tableData: dados,
            tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
            tableTitles: titulo,
            tableHelps: help,
            tableLegend: legenda || [],
            tableFieldFilters: filtros,
            tableTotalPages: totalPages,
            tableFieldTypeConfig: fieldTypeConfig,
          }))
          setProductsFiltered(dados);
        }

        dispatch(updateDictionaryABA({ value: {
          ...dicionary.ABA,
        } }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      // toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getProductsWithPage = async (page: number) => {
    if (page > tableTotalPages) {
      return
    }

    const toastId = toast.loading('Por favor aguarde...' )

    try {

      dispatch(updateTableLoading({ value: true }));
      const { filter, statusFilter } = tableFilter;
      
      const { data, status, request } = await osService.getProducts(token, itensPerPage, page, selectedClient.indice, currentBranch.codigo, currentCompany, orderByText, filter, statusFilter);
      const { titulo, dados, ordem, oculto, legenda, filtros, fieldTypeConfig, totalPages, help, nivel }: IPaginatedServiceResponse = data;
  
      if (status === 200 || status === 201) {
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda || [],
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
        setProductsFiltered(dados);
        dispatch(updateTableCurrentPage({ value: page }));
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getProductsSearch = async (order: string, filters?: string | undefined,  statusFilter?: { [key: string]: string } | undefined) => {
    const toastId = toast.loading('Por favor aguarde...' )
    try {
      dispatch(updateTableLoading({ value: true }));

      const { data, status, request } = await osService.getProducts(token, itensPerPage, 1, selectedClient.indice, currentBranch.codigo, currentCompany, order, filters, statusFilter);
      const { titulo, dados, ordem, oculto, legenda, filtros, totalPages, fieldTypeConfig, help, nivel }: IPaginatedServiceResponse = data;
  
      if (status === 200) {
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda || [],
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
        setProductsFiltered(dados);

      } else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
      dispatch(updateTableLoading({ value: false }));
    }

  }

  const getProductsWithTextSearch = useCallback(debounce(async (searchValue: string) => {
    // Load
    dispatch(updateTableCurrentPage({ value: 1 }));

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();

      const searchString = useGrid ? utils.formatSearchStringToPaginationServiceGrid(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText) : utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValueText }));

      await getProductsSearch(orderByText, searchString);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }))
      dispatch(updateTableSearchText({ value: '' }))

      await getProductsSearch(orderByText);
    }

  }, Number(configuracoes.debounceinput) || 3000), [orderByText, tableFilter, tableFieldTypeConfig]);

  function handleCleanInputSearch() {
    const searchFieldInput = document.querySelector<HTMLInputElement>('#inputSearchProductList');
    if (searchFieldInput) {
      if(searchFieldInput.value !== ''){
        searchFieldInput.value = '';
        getProductsWithTextSearch('enter', '');
      }     
    }    
  }

  async function orderProduct(order: string) {
    setOrderByText(order);
    await getProductsSearch(order, tableFilter.filter, tableFilter.statusFilter)
  }

  function handleOrderBy(column: string) {
    if (tableFieldFilters.character.includes(column)) {
      if (Object.keys(orderBy)[0] === column) {
        switch (orderBy[column]) {
          case "ascending":
            orderProduct(`${column} DESC`)
            dispatch(updateOrderBy({ value: { [column]: "descending" } }))
            break;
          case "descending":
            orderProduct(``)
            dispatch(updateOrderBy({ value: { [column]: null } }))
            break;
          case null:
            orderProduct(`${column} ASC`)
            dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
            break;
        }
      } else {
        orderProduct(`${column} ASC`)
        dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
      }
    }

  }

  function onTrigger(f3SelectedValue: INewF3Data, fieldKey: string, trigger: string[], productIndex: number) {
    const fieldIndex = itemFields.findIndex(field => field.nome === fieldKey)

    const productListFieldsCopy = [...productListFields]
    const selectedProduct = productsFiltered.find(product => product.indice === productIndex)

    if(trigger.length > 0) {
      trigger.map((f3Key, index) => {
        const fieldTrigger = itemFields[fieldIndex + index]

        if (productListFieldsCopy.find(item => item.indice === productIndex)) {
          productListFieldsCopy.find(item => item.indice === productIndex)[fieldTrigger.nome] = String(f3SelectedValue[f3Key])
        } else {
          const fieldsAlreadyFilled = {} 
          setProductsSelected(prevState => [...prevState, selectedProduct])

          itemFields.forEach(field => {
            if (field.conteudo !== '') {
              fieldsAlreadyFilled[field.nome] = field.conteudo
            }
          })

          const product = {}
          for (const [key, value] of Object.entries(dictionaryABA)) {
            if (selectedProduct[value.toLowerCase()] !== undefined) {
              product[key.toUpperCase()] = selectedProduct[value.toLowerCase()]
            }
          }

          productListFieldsCopy.push({
            ...fieldsAlreadyFilled,
            ...product,
            indice: productIndex,
            ['ABA_ITEM']: '&&',
            [fieldTrigger.nome]: String(f3SelectedValue[f3Key]),
          })
        }
      })
    }
    setProductListFields(productListFieldsCopy)
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearchProductList').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if(changeItensPerPage > 0) {
      getProductsWithPage(1);
    }
  },[itensPerPage])

  useEffect(() => {
    setIsLoading(true)

    getAttendanceFields()
    dispatch(resetDataTable())

    if(orderServiceStatus === '2') {
      getChecklistFields()
    }
  }, [indice])

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth - (window.innerWidth * 6 / 100))
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };  
  }, [])

  return (
    <Modal
      onClose={() => !isLoading && onClose(false)}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Header>
        <Title>Atendimento</Title>
      </Header>

      <Modal.Content scrolling style={{ height:  '100%' }}>
        <Accordion  styled fluid style={{ height: 'auto' }}>
          <Accordion.Title
            active={accordionHeaderActive}
            onClick={() => setAccordionHeaderActive(!accordionHeaderActive)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div>
              <Icon name='dropdown' />
              <Title>
              {
                useTitleSettingsInAttendanceScreen 
                  && companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[0] 
                  ? companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[0] 
                  : 'Cabeçalho'
              }
              </Title>
            </div>


          </Accordion.Title>
          <Accordion.Content active={accordionHeaderActive}>
            <RestField  mode={mode} />
          </Accordion.Content>

          {
            orderServiceStatus === '2' && (
              <>
                <Accordion.Title
                  active={checklistActive}
                  onClick={() => setChecklistActive(!checklistActive)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div>
                    <Icon name='dropdown' />
                    <Title>
                    {
                      useTitleSettingsInAttendanceScreen 
                        && companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[1] 
                        ? companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[1] 
                        : 'Checklist' 
                    }
                    </Title>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={checklistActive}>
                  <div style={{ overflowX: 'auto' }}>
                    <OrderServiceItensRestField
                      mode={mode}
                      fields={checklistItensFields} 
                      itensFieldsValue={checklistFieldsValue}
                      updateFieldValues={({ index, name, value }) => updateChecklistFieldsListValue(index, name, value)}
                      deleteItemFieldsValue={(index) => handleDeleteChecklist(index)} 
                    />
                  </div>
                </Accordion.Content>
              </>
            )
          }

    
          <Accordion.Title
            active={itensActive}
            onClick={() => setItensActive(!itensActive)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div>
              <Icon name='dropdown' />
              <Title>
              {
                useTitleSettingsInAttendanceScreen 
                  && companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[2] 
                  ? companyPolicies.find(policy => policy.policy === useTitleSettingsInAttendanceScreen.dependencie).policy_value.split(';')[2] 
                  : 'Itens' 
              }
              </Title>
            </div>
          </Accordion.Title>
          {
            mode === 'add' ? (
              //height: `${window.innerHeight - 480}px`
              <Accordion.Content style={{ width: '100%', height: `${window.innerHeight - 280}px` }} active={itensActive}>
                 {/* height: `${window.innerHeight - 650}px` */}
                <Container style={{ width: '100%', height: `${window.innerHeight - 450}px` }}>
                  <Menu style={{ boxShadow: '0 0 3px 2px #dedede' }} borderless>
                    {
                      !showProductsSelected ?
                      <Menu.Item fluid>
                        <Input
                          id="inputSearchProductList"
                          style={{ width: '35rem', height: '4rem' }}
                          icon={tableSearchText.length !== 0 ? <Icon name='close' link onClick={handleCleanInputSearch} /> : <Icon name='search' link />}
                          placeholder='Pesquisar...'
                          defaultValue={tableSearchText}
                          onChange={event => {
                            getProductsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)
                          }}
                          onBlur={(event) => getProductsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)}
                        />

                      </Menu.Item> : null
                    }
                      
                    <Menu.Item position='right'>
                      <Button 
                        icon 
                        labelPosition='right' 
                        positive 
                        size='large' 
                        onClick={() => {
                          setShowProductsSelected(prevState => !prevState)
                          handleCleanInputSearch();
                        }}
                      >
                        { !showProductsSelected ? 'Visualizar itens selecionados' : 'Adicionar mais produtos'}
                        { !showProductsSelected ? <Icon name='eye' /> : <Icon name='plus' />}
                      </Button>
                    </Menu.Item>
                  </Menu>
                  <TableContainer>
                    <Table style={{ boxShadow: '0 0 3px 2px #dedede' }} celled compact sortable selectable color='teal'>
                      {
                        tableLoading ?
                        <div style={{ width: '100%', height: '100%'}}>
                          <Skeleton baseColor='#DDD' height={50}  width="100%"  count={itensPerPage + 1} />
                        </div>
                        : <>
                          <Table.Header fullWidth>
                            <Table.Row>
                              {
                                tableOrder.map((column, index) => {
                                  return (
                                    <Table.HeaderCell 
                                      key={`${column} - ${index}`}
                                      onClick={() => handleOrderBy(column)}
                                      sorted={orderBy[column] || null}

                                    >
                                      <TableColumnText>
                                        {tableTitles[column]}
                                      </TableColumnText>
                                    </Table.HeaderCell>
                                  );
                                })
                              }
    
                              <Table.HeaderCell textAlign="center">
                                <TableColumnText>
                                  Quantidade
                                </TableColumnText>
                              </Table.HeaderCell>

                              {
                                itemFields.map(field => {
                                  const isValid = utils.validationFormField(field.tipo, mode)

                                  if(isValid && field.regra !== 'quantidade') {
                                    return (
                                      <Table.HeaderCell key={field.nome} ><TableColumnText>{field.titulo} <span style={{ color: '#db2828' }}>{field.obrigado === 'S' ? '*' : ''}</span></TableColumnText></Table.HeaderCell>
                                    )
                                  }
                                })
                              }
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {
                              (showProductsSelected ? productsSelected : productsFiltered).map((row, index) => {
                                return (
                                  <Table.Row 
                                    key={index} 
                                    active={currentItemQuantity(row['indice']) && currentItemQuantity(row['indice']) > 0}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {
                                      tableOrder.map((columnName) => {
                                        return (
                                          <Table.Cell 
                                            key={columnName} 
                                            onClick={() => {
                                              if(columnName.includes('XIMG')) {
                                                handleOpenProductDetail(row[columnName.toLowerCase()] ? String(row[columnName.toLowerCase()]) : defaultProductImg, row['b1_cod'])
                                              }
                                            }}
                                          >
                                            {
                                              !!tableLegend.find(({ campo }) => campo === columnName) &&
                                              <Label
                                                size='large'
                                                style={{
                                                  background: tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]) ? tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]).cor : '#333',
                                                  color: '#fff'
                                                }}
                                              >
                                                {row[columnName.toLowerCase()].toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)}
                                              </Label>
                                            }
                                            {
                                              !tableLegend.find(({ campo }) => campo === columnName) && columnName.includes('XIMG') &&
                                                <Image
                                                  size="mini"
                                                  src={row[columnName.toLowerCase()] || defaultProductImg}
                                                  onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = defaultProductImg;
                                                  }}
                                                />
                                            }
                                            {
                                              !tableLegend.find(({ campo }) => campo === columnName) && !columnName.includes('XIMG') &&
                                              <TableDataText>
                                                  {
                                                    tableFieldTypeConfig.numeric && tableFieldTypeConfig.numeric.find(num => num.name === columnName && num.mask === 'currency')
                                                    ? (
                                                    <DatatableField
                                                      fieldName={columnName}
                                                      fieldValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(row[columnName.toLowerCase()].toString()))}
                                                    />
                                                    ) : (
                                                    <DatatableField
                                                      fieldName={columnName}
                                                      fieldValue={row[columnName.toLowerCase()]}
                                                    />
                                                    )
                                                  }
                                                  
                                              </TableDataText>
                                            }
                                          </Table.Cell>
                                        );
                                      })
                                    }
                                                                          
                                    <Table.Cell 
                                      textAlign="center" 
                                    >
                                      <QuantityContainer>
                                        {
                                          !useDecimalProductQuantity && 
                                          <Button 
                                            circular
                                            size='big'
                                            negative
                                            onClick={() => handleChangeQuantityProductValue((Number(currentItemQuantity(row['indice'])) - valueWithConversion(row['indice'])).toFixed(2), row['indice'])}
                                            icon="minus"
                                            style={{ background: styleSheet.mainColor }}
                                          />
                                        }
                                        <QuantityInput
                                          type="number"
                                          maxLength={6}
                                          max={999}
                                          min={0}
                                          onFocus={e => e.target.select()}
                                          value={typeof currentItemQuantity(row['indice']) === 'number' ? currentItemQuantity(row['indice']) : ""}
                                          onChange={event => handleChangeQuantityProductValue(event.target.value, row['indice'])}
                                        />
                                        {
                                          !useDecimalProductQuantity && 
                                          <Button
                                            circular
                                            size='big'
                                            positive
                                            onClick={() => { 
                                              handleChangeQuantityProductValue((Number(currentItemQuantity(row['indice'])) + valueWithConversion(row['indice'])).toFixed(2), row['indice']) 
                                            }}
                                            icon="plus"
                                            style={{ background: styleSheet.mainColor }}
                                          />
                                        }
                                        
                                      </QuantityContainer>  
                                    </Table.Cell>

                                    
                                    {
                                      itemFields.map(({ titulo, nome, obrigado, placeholder, tipo, type, options, f3, picture, f3Post, decimal = "2", conteudo, visual, len, fieldOccupationSize, regra }) => {
                                        const isValid = utils.validationFormField(tipo, mode)

                                        if(isValid && regra !== 'quantidade') {
                                          return (
                                            <Table.Cell width={1}>
                                              <Form.Field key={nome} required={obrigado === 'S'} style={{ width: fieldOccupationSize ? `${screenWidth * fieldOccupationSize / 100}px` : `${screenWidth * 32 / 100}px` }}>
                                                {
                                                  type === 'character' && !f3 &&
                                                  <Input
                                                    style={{ width: '100%', height: '4rem' }}
                                                    placeholder={placeholder}
                                                    onFocus={e => e.target.select()}
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                    onChange={
                                                      (ev, { value }) => { handleChangeProductValue(utils.pictureValidate(picture, value), nome, row['indice']) }
                                                    }
                                                    value={currentItemValue(row['indice'], nome, conteudo)}
                                                  />
                                                }
                                                {
                                                  type === 'number' &&
                                                  <Input
                                                    style={{ width: '100%', height: '4rem' }}
                                                    placeholder={placeholder}
                                                    type="number"
                                                    min="0"
                                                    onFocus={e => e.target.select()}
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                    onChange={
                                                      (ev, { value }) => {
                                                        handleChangeProductValue(value, nome, row['indice'])
                                                      }
                                                    }             
                                                    value={currentItemValue(row['indice'], nome, conteudo)}
                                                    maxLength={len}
                                                  />
                                                }
                                                {
                                                  type === 'percent' &&
                                                  <Input
                                                    style={{ width: '100%', height: '4rem' }}
                                                    placeholder={placeholder}
                                                    type="number"
                                                    icon="percent"
                                                    onFocus={e => e.target.select()}
                                                    min="0"
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                    onChange={
                                                      (ev, { value }) => {
                                                        handleChangeProductValue(value, nome, row['indice'])
                                                      }
                                                    }             
                                                    value={currentItemValue(row['indice'], nome, conteudo)}
                                                    maxLength={len}
                                                  />
                                                }
                                                {
                                                  type === 'date' &&
                                                  <Input
                                                    style={{ width: '100%', height: '4rem' }}
                                                    placeholder={placeholder}
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                    type="date"
                                                    onChange={
                                                      (ev, { value }) => { handleChangeProductValue(value, nome, row['indice']) }
                                                    }
                                                    defaultValue={utils.formatDateDefault(String(currentItemValue(row['indice'], nome, conteudo)))}
                                                    value={utils.formatDateDefault(String(currentItemValue(row['indice'], nome, conteudo)))}
                                                  />
                                                }

                                                {
                                                  type === 'time' &&
                                                  <Input
                                                    style={{ width: '100%', height: '4rem' }}
                                                    placeholder={placeholder}
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    type={'time'}
                                                    onChange={
                                                      (ev, { value }) => { handleChangeProductValue(value, nome, row['indice']) }
                                                    }
                                                    value={currentItemValue(row['indice'], nome, conteudo)}
                                                  />
                                                }

                        
                                                {
                                                  type === 'currency' &&
                                                    <Input
                                                      style={{ width: '100%', height: '4rem' }}
                                                      placeholder={placeholder}
                                                      type="text"
                                                      min="0"
                                                      onFocus={e => e.target.select()}
                                                      readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                      disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                      onChange={
                                                        (ev, { value }) => {
                                                          handleChangeProductValue(String(utils.unmaskBRLCurrency(value, decimal)), nome, row['indice'])
                                                        }
                                                      }             
                                                      value={utils.inputMaskBRLCurrency(Number(currentItemValue(row['indice'], nome, conteudo)), decimal)}
                                                      maxLength={len}
                                                    />
                                                }
                                                {
                                                  type === 'combo' &&
                                                  <Dropdown
                                                    search
                                                    selection
                                                    placeholder={placeholder}
                                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                                    style={{ width: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
                                                    options={options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } })}
                                                    onChange={
                                                      (ev, { value }) => { 
                                                        handleChangeProductValue(String(value), nome, row['indice'])
                                                      }
                                                    }
                                                    value={currentItemValue(row['indice'], nome, conteudo)}
                                                  />
                                                }

                                                {
                                                  type === 'character' && !!f3 &&
                                                  <F3
                                                    clientId={null}
                                                    token={token}
                                                    consulta={f3Post.consulta}
                                                    conteudo={currentItemValue(row['indice'], nome, f3Post.conteudo)}
                                                    email={f3Post.email}
                                                    requisito={f3Post.requisito}
                                                    requisitocont={f3Post.requisitocont}
                                                    readonly={false}
                                                    placeholder={placeholder}
                                                    label={titulo}
                                                    name={nome}
                                                    onChange={(f3SelectReturn, trigger) => handleChangeProductValue(f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo, nome, row['indice']) }
                                                    onChangeNewF3={async (f3SelectReturn, trigger) => {
                                                      onTrigger(f3SelectReturn, nome, trigger, row['indice'])
                                                    }}
                                                  />
                                                }
                                              </Form.Field>
                        
                                            </Table.Cell>
                                          )
                                        }
                                      })
                                    }
                                  </Table.Row>
                                );
                              })
                            }
                          </Table.Body>
                        </>
                      }          
                    </Table>
                  </TableContainer>
                  {
                    !showProductsSelected ? 
                    <Menu borderless style={{backgroundColor: '#FFF', boxShadow: '0 0 3px 2px #dedede'}}>
                      {
                        !tableLoading && 
                        <Menu.Item position="right">
                          {
                            customizations['useQtdPaginationSelect'] ? (
                              <Menu.Item position="right">
                                <Fieldset>
                                <LegendStatus>
                                  QTD PAGINA
                                </LegendStatus>
                                <LegendsStatusContainer>
                                  <Select 
                                    placeholder="Quantidade por pagina"
                                    defaultValue={itensPerPage}
                                    options={ configuracoes.quantityperpage ? utils.generateItensPerPage(configuracoes.quantityperpage) : utils.generateItensPerPageDefault(window.innerHeight, rowSize) }
                                    onChange={async (event, data) => {
                                      dispatch(updateItensPerPage({ value: Number(data.value) } ));
                                      dispatch(updateTableCurrentPage({ value: 1 }));
                                      setChangeItensPerPage(changeItensPerPage + 1);
                                    }}
                                  />
                                </LegendsStatusContainer>
                                </Fieldset>
                              </Menu.Item>
                            ) : null
                          }
                          <Menu>
                            <Pagination
                              defaultActivePage={tableCurrentPage}
                              firstItem={null}
                              lastItem={null}
                              pointing
                              secondary
                              totalPages={tableTotalPages}
                              onPageChange={(ev, { activePage }) => getProductsWithPage(activePage as number)}
                            />
                          </Menu>
                        </Menu.Item>
                      }
                    </Menu> : null
                  }
                </Container>
              </Accordion.Content>
            ) : (
              <Accordion.Content active={itensActive}>
                <Table size="large" celled>
                  <Table.Header>
                    <Table.Row>
                      {
                        tableFieldsTitle.map(title => {
                          if (tableFields[0] && tableFields[0].find(field => field.titulo === title) && tableFields[0].find(field => field.titulo === title).browse !== 'N') {
                            return (
                              <Table.HeaderCell>{title}</Table.HeaderCell>
                            )
                          }
                        })
                      }
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {
                      tableFields.map(fields => (
                        <Table.Row>
                          {
                            tableFieldsTitle.map(title => {
                              if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
                                if(fields.find(field => field.titulo === title).nome.includes('_XIMG')) {
                                  return (
                                    <Table.Cell
                                      width={1}
                                      onClick={() => {
                                        if(fields.find(field => field.titulo === title).nome.includes('_XIMG')) {
                                          handleOpenProductDetail(fields.find(field => field.titulo === title).conteudo !== ""  ? String(fields.find(field => field.titulo === title).conteudo) : defaultProductImg)
                                        }
                                      }}
                                    >
                                      <Image
                                        size="mini"
                                        src={fields.find(field => field.titulo === title).conteudo || defaultProductImg}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = defaultProductImg;
                                        }}
                                      />
                                    </Table.Cell>
                                  )
                                }else{
                                  return (
                                    <Table.Cell>
                                      {
                                        fields.find(field => field.titulo === title).type === 'currency' &&
                                          `R$ ${utils.convertBrl(fields.find(field => field.titulo === title).conteudo, useThreeDecimalPlaces ? 3 : 2)}`
                                      }
          
                                      {
                                        fields.find(field => field.titulo === title).type !== 'currency' &&
                                        fields.find(field => field.titulo === title).conteudo 
                                      }
                                    </Table.Cell>
                                  )
                                }
                              }

                            })
                          }
                        </Table.Row>
                      ))
                    }

                    {
                      totalFields.map(fields => (
                        <Table.Row>
                          {
                            tableFieldsTitle.map(title => {
                              if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
                                return (
                                  <Table.Cell>
                                    {
                                      fields.find(field => field.titulo === title).type === 'currency' &&
                                        `R$ ${utils.convertBrl(fields.find(field => field.titulo === title).conteudo, useThreeDecimalPlaces ? 3 : 2)}`
                                    }

                                    {
                                      fields.find(field => field.titulo === title).type !== 'currency' &&
                                      fields.find(field => field.titulo === title).conteudo 
                                    }
                                  </Table.Cell>
                                )
                              }
                            })
                          }
                        </Table.Row>
                      ))
                    }
                  </Table.Body>


                </Table>
              </Accordion.Content>
            )
            
          }
        </Accordion>
      </Modal.Content>

      <Modal.Actions>
          {/* {
            mode !== 'view' && 
            <Button icon labelPosition='right' positive size='big' onClick={handleAddItem}>
              Add Item
              <Icon name='plus' />
            </Button>
          } */}

          {
            mode !== 'view' && orderServiceStatus === '2' &&
            <Button icon labelPosition='right' positive size='big' onClick={handleAddChecklist}>
              Add Checklist
              <Icon name='plus' />
            </Button>
          }
          {
            mode === 'view' &&
            <Button onClick={() => onClose(false)} size='big'>Cancelar</Button>
          }
          {
            mode !== 'view' &&
            <Button.Group size='big'>
              <Button onClick={() => onClose(false)}>Cancelar</Button>
              <Button.Or text="ou" />
              <Button
                onClick={() => {
                  if(useConfirmationServiceOrderCreateWithEmptyItems && productListFields.length === 0) {
                    Swal.fire({
                      icon: 'info',
                      title: 'Tem certeza?',      
                      text: `Realmente deseja concluir o apontamento sem itens?`,
                      showCancelButton: true,
                      confirmButtonText: 'Sim',
                      confirmButtonColor: '#21ba45',
                      cancelButtonColor: '#e0e1e2',
                      cancelButtonText: 'Não'
                    }).then(async (result) => {
                      if(result.isConfirmed) {
                        handleCreateOrUpdate();
                      }
                    })

                  }else {
                    if(configuracoes.useconfirmationorder) {
                      return Swal.fire({
                        title: 'Confirmar ação?',
                        text: `Deseja ${mode === 'edit' ? 'alterar' : 'incluir'} este atendimento`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim, confirmar!',
                        cancelButtonText: 'Cancelar'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleCreateOrUpdate();
                        }
                      });
                    } else {
                      handleCreateOrUpdate();
                    }
                  }
                }}
                positive
                disabled={isLoading}
              >
                Salvar
              </Button>
            </Button.Group>
          }
        </Modal.Actions>
    </Modal>
  );
}

export default FormAttendanceModal;