import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

interface IconContainerProps  {
  color: string;
}

interface ContainerProps {
  color: string;
  isSelected: boolean;
}

export const Container = styled.div<ContainerProps>`
  position:relative;
  width: 100%;
  display: flex;
  height: 100%;
  min-height: 5rem;
  align-items: center;
  flex-direction: row;
  background: ${({ theme }) => theme.shape}; //${styleSheet.shape};
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
  border: 2px solid ${({ theme, color, isSelected }) => isSelected ? theme.dangerColor : color};
  padding: 1rem 0.2rem;
  font-size: 1.2rem;
  cursor: pointer;
  white-space: normal;
  margin-top: 0.5rem;
  font-weight: bold;

  border-radius: 4px;
`;

export const Data = styled.div`
  width: 60%;
  margin-top: .5rem;
`;

export const Title = styled.h2`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
  font-weight: bold;
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.mediumGrey}; //${styleSheet.mediumGrey};
  font-weight: normal;
`;

export const DadgeContainer = styled.div`
  display: flex;
  position: absolute;
  top: -9px;
  right: -5px;
`;

export const Dadge = styled.div<IconContainerProps>`
  background: ${({ color }) => color};
  color: ${({ theme }) => theme.shape};// ${styleSheet.shape};
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
  transition:  0.5s ease-out;

`;

export const OsInfosContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 12px;
  margin-right: .5rem;
  padding: 0.5rem;
`;

export const OsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div<IconContainerProps>`
  width: 2rem;
  height: 2rem;  
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  padding: 0.1rem;
  font-size: 1.6rem;
`;

export const Line = styled.div`
  width: 1px;
  height: 2rem;  
  background: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;