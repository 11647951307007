import { Dropdown, DropdownItemProps, Header, Image, Button, Icon, Modal } from 'semantic-ui-react';
import { Person, Gear, SignOut, ArrowCircleDown } from 'phosphor-react';
import React, { FC, useContext,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EnvironmentContext } from '../../contexts/Environment';

import { IBranches } from '../../interfaces/Auth/IBranches';

import { EnvironmentReducerInitialState, updateCurrentBranch, updateSelectedClient } from '../../reducers/Environment';
import { MenuReducerInitialState, updateSelectedMenu } from '../../reducers/Menu';
import { AuthReducerInitialState, signOut } from '../../reducers/Auth';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { updateItemsCount } from '../../reducers/Cart';

import avatar from '../../assets/images/avatar.png';
import { styleSheet } from '../../assets/styles/global';

import { HeaderContent, DropdownUsername, ModalParagraph, LogoModal, SemanticDropDown } from './styles';
import { useCookies } from 'react-cookie';
import { bucketURL } from '../../common/Constants';
import { MenuContext } from '../../contexts/MenuContext';

const DefaultHeader: FC = () => {
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { user, branches, pwm, userType } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { companyName, companyEnvironment  } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { menuList } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const showPolicieSettings = pwm.showpoliciessettings !== undefined ? pwm.showpoliciessettings : pwm.showpoliciessettings || userType === '3' ? true : false;

  const dispatch = useDispatch()

  const { getMenuRoute } = useContext(MenuContext)
  const { selectFirstClient } = useContext(EnvironmentContext);
  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);

  const [modalLogoutState, setModalLogoutState] = useState(false);
  const [modalBranchState, setModalBranchState] = useState(false);
  const [modalBranchTransitionState, setModalBranchTransitionState] = useState(false);
  const [branchValue, setBranchValue] = useState<IBranches>({} as IBranches);
  const [changeSettings, setChangeSettings] = useState('');

  const navigate = useNavigate();

  const dropdownOptions: DropdownItemProps[] = [
    // { key: 'user', text: 'Minha Conta', icon: <Person size={19} style={{ color: styleSheet.mainColor }} /> },
    showPolicieSettings && { key: 'settings', text: 'Configurações', icon: <Gear size={19} style={{ color: styleSheet.mainColor }} />, onClick: handleChangeSettings },
    branches.length > 1 && { key: 'branch', disabled: branches.length < 1 ? true : false, text: 'Trocar Filial', icon: <ArrowCircleDown size={19} style={{ color: styleSheet.mainColor }} />, onClick: branches.length > 1 && handleChangeBranch },
    { key: 'sign-out', text: 'Sair', icon: <SignOut size={19} style={{ color: styleSheet.mainColor }} />, onClick: handleConfirmationLogout }
  ].filter(Boolean) 

  function handleChangeSettings() {
    navigate('/settings')
  }

  async function setNewBranch() {
    setCookie('current_branch', JSON.stringify(branchValue))
    setCookie('selected_client', JSON.stringify({}))
    setCookie('selected_menu', menuList[0] )
    dispatch(updateCurrentBranch({ value: branchValue }))
    setModalBranchTransitionState(false);
    setModalBranchState(false);
    dispatch(updateItemsCount({ value: 0 }));
    navigate(`/${getMenuRoute(menuList[0].nome.toLowerCase())}`);
    dispatch(updateSelectedClient({ value: {} }))
    dispatch(updateSelectedMenu({ value: menuList[0] }))

    if(!menuList.find(menu => menu.descri === 'clientes')) {
      await selectFirstClient(branchValue.codigo, currentCompany)
    }
  }

  function handleChangeBranch() {
    setModalBranchState(true);
  }

  function handleConfirmationLogout() {
    setModalLogoutState(true);
  }

  function handleLogout() {
    dispatch(signOut())
    removeCookie('user_info')
    removeCookie('current_branch')
    removeCookie('current_company')
    removeCookie('selected_client')
    removeCookie('selected_menu')

    dispatch(updateItemsCount({ value: 0 }));
    dispatch(updateSelectedClient({ value: {} }));
    localStorage.removeItem('isToRemember');
    localStorage.removeItem('rememberToken');
    navigate('/');
  }

  // useEffect(() => {
  //   setCurrentBranch(branchValue);
  // }, [])

  const dropdownTrigger = (
    <>
      <DropdownUsername>
        <Image style={{ marginLeft: '2rem' }} avatar src={avatar} /> 
      </DropdownUsername>
    </>
  )

  return (
    <div>
      <Header style={{ border: 'none', boxShadow: '2px 2px 2px 2px #dedede' }} attached="top">
        <HeaderContent className="logoBox">
          <div></div>
          <div>
            {user}
            <SemanticDropDown
              trigger={dropdownTrigger}
              options={dropdownOptions}         
            />
          </div>
        </HeaderContent>
      </Header>
      <Modal
        basic
        onClose={() => setModalLogoutState(false)}
        onOpen={() => setModalLogoutState(true)}
        open={modalLogoutState}
        size='small'
        style={{ fontFamily: styleSheet.mainFont }}
        trigger={onclick}
      >
        <Header
          style={{ fontFamily: styleSheet.mainFont, fontSize: '2rem', display: 'flex', flexDirection: 'column' }}
        >
          <LogoModal className="logoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/logo`} alt="Logotipo" />
          Deseja realmente fazer o Logoff?
        </Header>
        <Modal.Content>
          <ModalParagraph>
            Ao concordar em sair, todas as alterações não salvas
            serão perdidas!
          </ModalParagraph>
        </Modal.Content>
        <Modal.Actions>
          <Button size='huge' inverted onClick={() => setModalLogoutState(false)}>
            <Icon name='remove' />
            Cancelar
          </Button>
          <Button  size='huge' color='green' inverted onClick={() => handleLogout()}>
            <Icon name='checkmark' />
            Sair
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setModalBranchState(false)}
        onOpen={() => setModalBranchState(true)}
        open={modalBranchState}
        size='tiny'
        trigger={onclick}
      >
        <Modal.Header
          style={{ fontFamily: styleSheet.mainFont, fontSize: '1.6rem' }}
        >
          Alterar filial
        </Modal.Header>
        <Modal.Content>
          <ModalParagraph>Ao trocar a filial, informações não salvas, serão perdidas.</ModalParagraph>
          <Dropdown
            placeholder='Escolha uma filial'
            fluid
            selection
            options={
              branches.map(({ codigo, nome }) => {
                return {
                  value: codigo,
                  text: `${codigo} - ${nome}`
                }
              })
            }
            onChange={(ev, { value }) => {
              const branchSearchedSelected = branches.find(({ codigo }) => codigo === value);
              setBranchValue(branchSearchedSelected);
            }}
            defaultValue={currentBranch.codigo}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={() => setModalBranchState(false)}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button positive onClick={() => setModalBranchTransitionState(true)} >Confirmar</Button>
          </Button.Group>
        </Modal.Actions>
        <Modal
          onClose={() => setModalBranchTransitionState(false)}
          onOpen={() => setModalBranchTransitionState(true)}
          open={modalBranchTransitionState}
          size='mini'
        >
          <Modal.Content>
            <ModalParagraph>Tem certeza desta ação?</ModalParagraph>
          </Modal.Content>
          <Modal.Actions>
            <Button.Group>
              <Button onClick={() => setModalBranchTransitionState(false)} >Cancelar</Button>
              <Button.Or text="ou" />
              <Button positive onClick={() => { setNewBranch() }} >Estou ciente</Button>
            </Button.Group>
          </Modal.Actions>
        </Modal>
      </Modal>
    </div>
  );
}

export default DefaultHeader;