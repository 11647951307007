import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ListBullets } from 'phosphor-react';

import { styleSheet } from '../../assets/styles/global';
import { Utils } from "../../common/Utils";
import { useDispatch, useSelector } from "react-redux";
import { EnvironmentReducerInitialState } from "../../reducers/Environment";
import { PolicieReducerInitialState } from "../../reducers/Policie";
import { AuthReducerInitialState } from "../../reducers/Auth";
import { UIContext } from "../../contexts/UIContext";
import { FieldTypeConfig, FiltersType, IFilterParams, LegendType, TableData, TableTitle } from "../../@types/Datatable";
import { IPaginatedServiceResponse } from "../../interfaces/Datatable/IPaginatedServiceResponse";
import { IErrorProps } from "../Error";
import Swal from "sweetalert2";
import { debounce } from "lodash";
import DatatableOrderService from "../Datatable/DatatableOrderService";
import { CartReducerInitialState, addLotesToItems, updateDictionaryC6, updateDictionaryCK, updateItemsList, updateProductValueList, updateSearchItemSelected, updateSearchProductSelected } from "../../reducers/Cart";
import { ProductService } from "../../services/methods/ProductService";
import { CartContext, dictionaryEXC6, dictionaryEXCK } from '../../contexts/CartContext';
import { MenuReducerInitialState } from "../../reducers/Menu";
import { updateTableSearching } from "../../reducers/DataTable";


export interface IConsultationModalProps {
  consultationServiceName: string;
  visualizationOnly: boolean;
  visible: boolean;
  onClose(): void;
}

const ConsultationModal: React.FC<IConsultationModalProps> = ({ consultationServiceName, visualizationOnly, visible, onClose }) => {  
  const productService: ProductService = new ProductService();

  const utils = new Utils();

  const { selectedProductToConsultation, consultationType, dictionaryC6, dictionaryCK, itemFields, searchProductSelected } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);

  const { currentBranch, selectedRow, currentCompany,  } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const useTitleDetail = companyPolicies.find(policy => policy.property === 'useTitleDetail' && policy.policy_value === 'true');
  
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [tableTitles, setTableTitles] = useState<TableTitle>({});
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableLegend, setTableLegend] = useState<LegendType[]>([]);
  const [tableFieldTypeConfig, setTableFieldTypeConfig] = useState<FieldTypeConfig>({} as FieldTypeConfig);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fieldFilters, setFieldFilters] = useState<FiltersType>({
    character: [],
    date: [],
    numeric: []
  });
  const [filters, setFilters] = useState<IFilterParams>({ filter: undefined, statusFilter: undefined })
  const [orderBy, setOrderBy] = useState('');

  async function getConsultationList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      setIsTableLoading(true);
      setCurrentPage(page)

      const response = await productService.consultation(consultationServiceName, consultationType, currentCompany, currentBranch.codigo, page, 8, token, selectedProductToConsultation.indice, order, filter, statusFilter);

      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, dicionary } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        setColumnOrder(ordem.filter(campo => !oculto.find(oculto => oculto === campo)));
        setTableTitles(titulo);
        utils.formatTableData(fieldTypeConfig, dados)
        setTableData(dados);
        setTableLegend(legenda);
        dispatch(updateDictionaryC6({
          value: {
            ...dictionaryC6,
            ...dicionary.SC6,
          }
        }))
        dispatch(updateDictionaryCK({
          value: {
            ...dictionaryCK,
            ...dicionary.SCK,
          }
        }))
        setFieldFilters(filtros)
        setTotalPages(totalPages);
        setTableFieldTypeConfig(fieldTypeConfig);
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsTableLoading(false)
      setIsLoading(false)

    }
  }

  const getConsultationWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, fieldFilters.character, "contains", searchValueText);

      setFilters(oldState => ({
        ...oldState,
        filter: searchString,
      }))

      setSearchText(searchValue);
      await getConsultationList(1, orderBy, searchString, filters.statusFilter);
    }
    else {
      setFilters(oldState => ({
        ...oldState,
        filter: undefined,
      }))
      setSearchText('');
      await getConsultationList(1, orderBy, undefined, filters.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [orderBy, tableFieldTypeConfig, filters]);

  async function orderConsultation(order: string) {
    setOrderBy(order)
    await getConsultationList(1, order, filters.filter, filters.statusFilter)
  }

  function updateFields() {
    if (tableData.find(item => item.indice === selectedRow.indice)) {
      
      if (configuracoes.usedirectproductsear && searchProductSelected.indice === selectedProductToConsultation.indice) {
        const product = {...searchProductSelected, ...selectedRow, indice: searchProductSelected.indice }
        dispatch(updateSearchProductSelected({ value: product }));
        const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
        const itemField = selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM';
        const item = itemFields.map(field => {
          if(field.nome === itemField) {
            return {
              ...field,
              conteudo: '&&'
            }
          }
          return {
            ...field,
            conteudo: product[dictionary[field.nome]] ? product[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''
          }
        });
        dispatch(updateSearchItemSelected({ value: item }));
      } else if (configuracoes.usedirectproductsear && searchProductSelected.indice !== selectedProductToConsultation.indice) {
        const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
        
        Object.entries(dictionary).map(([ key, value ]) => {
          if (value.includes('b8_') && selectedRow[value]) {
            console.log(selectedProductToConsultation.index, key, selectedRow[value])
            dispatch(updateProductValueList({index: selectedProductToConsultation.index, fieldName: key, value: selectedRow[value] }))
          }
        })

      } else {
        dispatch(addLotesToItems({ value: { ...selectedRow, indice: selectedProductToConsultation.indice }, itemIndice: selectedProductToConsultation.indice }))
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'E necessário que selecione um item.',
      })
    }
  }

  useEffect(() => {
    if (visible === true) {
      setIsLoading(true)
      getConsultationList(1, orderBy)
    }
  }, [visible])

  return (
    <>
      <Modal
        style={{ fontFamily: styleSheet.mainFont }}
        onClose={() => !isLoading && onClose()}
        closeOnDimmerClick={false}
        size="fullscreen"
        open={visible}
      >
        <Modal.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <ListBullets size={22} color={styleSheet.mainColor} /> 
            Itens da consulta
          </div>
        </Modal.Header>
        {
          isLoading ? (
            <Modal.Content fluid>
              <Dimmer active>
                <Loader size="big" inverted>Carregando</Loader>
              </Dimmer>
            </Modal.Content>
          ) : (
            <Modal.Content scrolling>
              <DatatableOrderService
                  isClientTable={false}
                  data={tableData}
                  loading={isTableLoading}
                  order={columnOrder}
                  title={tableTitles}
                  legend={tableLegend}
                  totalPages={totalPages}
                  fieldTypeConfig={tableFieldTypeConfig}
                  fieldFilters={fieldFilters}
                  currentPage={currentPage}
                  searchText={searchText}
                  onPageChange={(page) => getConsultationList(page, orderBy, filters.filter, filters.statusFilter)}
                  onOrderBy={(value) => orderConsultation(value)}
                  statusFilter={filters.statusFilter}
                  onStatusFilter={(status: { [key: string]: string } | undefined) => console.log(filters.filter, status)}
                  onDatatableActionClick={(action, indice) => {
                    console.log(action, indice)
                  }}
                  onSearch={(value) => getConsultationWithTextSearch(value)}
                  hasActions={false}
                />
            </Modal.Content>
          )
        }

        <Modal.Actions>
          <Button.Group  style={{ marginLeft: '1rem' }}>
          <Button tabIndex={-1} size="big" onClick={onClose}>{visualizationOnly ? 'Fechar' : 'Cancelar'}</Button>
          {
            visualizationOnly ? null : <Button.Or text="ou" />  
          }
          {
            visualizationOnly ? null : 
            <Button 
              size="big" 
              color='green' 
              onClick={() => {
                
                updateFields()
              
                onClose();
              } }
            >
              Adicionar
            </Button> 
          }

          </Button.Group>   
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default ConsultationModal;