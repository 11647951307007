import { Dropdown, Form, Input, Popup, TextArea } from 'semantic-ui-react';
import { Question } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import React, { FC, useMemo } from 'react';
import ReactQuill from 'react-quill';
import Swal from 'sweetalert2';

import { ClientService } from '../../services/methods/ClientService';

import F3 from '../F3';

import { FieldValues } from '../../@types/Restfield';

import { Utils } from '../../common/Utils';

import { updateFieldValues, RestFieldReducerInitialState, updatePropertyField, updateToOldPropertyField } from '../../reducers/RestField';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { updateCgcIsValid } from '../../reducers/RestFieldValidation';

import 'react-quill/dist/quill.snow.css';
import { DefaultLabel } from './styles';
import { IRestFieldFederalRevenue } from '../../interfaces/Fields/IRestFieldFederalRevenue';

interface IRestFieldProps {
  mode: string;
}

const RestField: FC<IRestFieldProps> = ({ mode }) => {

  const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const useDiscountValidationByClientFieldOnCartHeader = useMemo(() => companyPolicies.find(policy => policy.property === 'useDiscountValidationByClientFieldOnCartHeader' && policy.policy_value === 'true'), [companyPolicies]) 


  const dispatch = useDispatch();

  /** HOOK PARA CRITÉRIOS DE DEBUG */
  const clientService: ClientService = new ClientService();

  const utils = new Utils();

  enum dados {
    municipio = 'municipio',
    logradouro = 'logradouro',
    estado = 'estado',
    bairro = 'bairro',
    cep = 'cep',
    nome = 'nome',
    fantasia = 'fantasia',
    atividade = 'atividade'
  }

  const formFieldsToFill = (fieldNames: string[], data: IRestFieldFederalRevenue, key: string) => {
    let t = 0;
    const formatAwnsers = {
      [dados.municipio]: data.municipio,
      [dados.logradouro]: `${ data.logradouro }, ${ data.numero }`,
      [dados.estado]: data.uf,
      [dados.bairro]: data.bairro,
      [dados.cep]: data.cep.replace(/\D/g, ""),
      [dados.nome]: data.nome,
      [dados.fantasia]: data.fantasia,
      [dados.atividade]: data.atividade_principal[0].code.replaceAll('.', '').replace(/-/g, match => ++t === 2 ? '/' : match)
    }

    fields
      .forEach((field, index) => {
        if (fieldNames.includes(field.nome.toUpperCase())) {
          dispatch(updateFieldValues(
            {
              name: fields[index].nome,
              value: (formatAwnsers[key].substring(0, fields[index].len)).toUpperCase()
            }))
        }
      })
  }

  async function handleValidateCpfOrCnpj(cpfOrCnpj: string) {
    try {

      if (cpfOrCnpj === '') return;
      const useAutocompletePersonType = companyPolicies.find(policy => policy.property === 'useAutocompletePersonType' && policy.policy_value === 'true');
      const cpfOrCnpjFormatted = cpfOrCnpj.replace(/\D/g, "")

      if (useAutocompletePersonType) {
        if (cpfOrCnpjFormatted.length === 14) {
          dispatch(updateFieldValues({ name: 'A1_PESSOA', value: 'J' }))
        } else {
          dispatch(updateFieldValues({ name: 'A1_PESSOA', value: 'F' }))
        }
      }

      if (cpfOrCnpjFormatted.length <= 11) {
        const result = utils.CpfValidate(cpfOrCnpjFormatted)

        if (!result) {
          dispatch(updateCgcIsValid({ type: 'cpf', value: false }));
          return Swal.fire({
            title: 'Ops...',
            text: 'CPF invalido',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        dispatch(updateCgcIsValid({ type: 'cpf', value: true }));
        return
      }
      const { data } = await clientService.getClientByCNPJ(cpfOrCnpjFormatted)

      if (data.status === "OK") {
        dispatch(updateCgcIsValid({ type: 'cnpj', value: true }));

        if (configuracoes.usecnpjautocomplete) {

          // CAMPOS PARA ATIVIDADE
          const activities = ['A1_CNAE'];
          formFieldsToFill(activities, data, dados.atividade);

          // CAMPOS PARA O NOME
          const names = ['A1_NOME'];
          formFieldsToFill(names, data, dados.nome);

          // CAMPOS PARA O NOME FANTASIA
          const fancyNames = ['A1_NREDUZ'];
          formFieldsToFill(fancyNames, data, dados.fantasia);

          // CAMPOS PARA O BAIRRO
          const districts = ['A1_BAIRRO', 'A1_BAIRROE', 'A1_BAIRROC'];
          formFieldsToFill(districts, data, dados.bairro);

          // CAMPOS PARA O CEP
          const zipCodes = ['A1_CEP', 'A1_CEPE', 'A1_CEPC', 'A1_DVCEPCB', 'A1_DVCEPET'];
          formFieldsToFill(zipCodes, data, dados.cep);

          // CAMPOS PARA O LOGRADOURO
          const addresses = ['A1_END', 'A1_ENDCOB', 'A1_ENDENT'];
          formFieldsToFill(addresses, data, dados.logradouro);

          // CAMPOS PARA O MUNICIPIO
          const counties = ['A1_MUN', 'A1_MUNE', 'A1_MUNC', 'A1_DVMUNCB', 'A1_DVMUNET'];
          formFieldsToFill(counties, data, dados.municipio);

          // CAMPOS PARA O ESTADO
          const states = ['A1_EST', 'A1_ESTE', 'A1_ESTC', 'A1_DVESTCB', 'A1_DVESTET'];
          formFieldsToFill(states, data, dados.estado);
        }

      } else {
        data.cleanCNPJField && dispatch(updateFieldValues({ name: 'A1_CGC', value: '' }));
        dispatch(updateCgcIsValid({ type: 'cnpj', value: false }));
        return Swal.fire({
          title: 'Ops...',
          text: data.msg || data.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  function intersectionsPercentValue(fieldName: string, value: string | number) {
    if (useDiscountValidationByClientFieldOnCartHeader) {

      const [clientField, headerField] =  companyPolicies.find(policy => policy.policy === useDiscountValidationByClientFieldOnCartHeader.dependencie).policy_value?.trim().toUpperCase().split('=');
      const maxDiscountAllowed = selectedClient[clientField.trim().toLowerCase()]

      if (Number(value) > maxDiscountAllowed && `${selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ'}${headerField.trim()}` === fieldName.toUpperCase())  {
        Swal.fire({
          title: 'Atenção!',
          text: `Não é possível ter um valor maior que ${maxDiscountAllowed}% de desconto!`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });

        value = maxDiscountAllowed
      }
    }

    return Number(value)
  }

  async function intersectionsF3Value(f3Data: F3Data[], fieldValuesAtt: FieldValues) {
    const useDiscountByPaymentConditionInF3 = companyPolicies.find(policy => policy.property === 'useDiscountByPaymentConditionInF3' && policy.policy_value === 'true');
    const useClientDiscountByPriceTableInF3 = companyPolicies.find(policy => policy.property === 'useClientDiscountByPriceTableInF3' && policy.policy_value === 'true');


    for (let index = 0; index < f3Data.length; index++) {
      if (useDiscountByPaymentConditionInF3 && f3Data[index].nome === "E4_CODIGO") {
        const condsPag = companyPolicies.find(policy => policy.policy === useDiscountByPaymentConditionInF3.dependencie).policy_value.split(';')
        if (condsPag.includes(String(f3Data[index].conteudo))) {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DESC1`, value: 2 }))
        }
      }

      if (useClientDiscountByPriceTableInF3 && f3Data[index].nome === "DA0_CODTAB") {
        if (f3Data[index].conteudo === '021') {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DSCBUBA`, value: selectedClient.a1_bdesc }))
        } else {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DSCBUBA`, value: '0' }))
        }
      }
    }
  }

  async function intersectionsNewF3Value(f3Data: INewF3Data) {
    const useDiscountByPaymentConditionInF3 = companyPolicies.find(policy => policy.property === 'useDiscountByPaymentConditionInF3' && policy.policy_value === 'true');
    const useClientDiscountByPriceTableInF3 = companyPolicies.find(policy => policy.property === 'useClientDiscountByPriceTableInF3' && policy.policy_value === 'true');

    const f3Keys = Object.keys(f3Data)

    for (let index = 0; index < f3Keys.length; index++) {
      if (useDiscountByPaymentConditionInF3 && f3Keys[index] === "E4_CODIGO") {
        const condsPag = companyPolicies.find(policy => policy.policy === useDiscountByPaymentConditionInF3.dependencie).policy_value.split(';')
        if (condsPag.includes(String(f3Data[f3Keys[index]]))) {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DESC1`, value: 2 }))
        }
      }

      if (useClientDiscountByPriceTableInF3 && f3Keys[index] === "DA0_CODTAB") {
        if (f3Data[f3Keys[index]] === '021') {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DSCBUBA`, value: selectedClient.a1_bdesc }))
        } else {
          dispatch(updateFieldValues({ name: `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DSCBUBA`, value: '0' }))
        }
      }
    }
  }

  async function intersectionsCombo(fieldName: string, fieldValue: string) {
    const useObligationDeliveryFieldsByFreightType = companyPolicies.find(police => police.property === 'useObligationDeliveryFieldsByFreightType' && police.policy_value !== '');

    if (useObligationDeliveryFieldsByFreightType && fieldName.includes('_XTPFRET')) {
      const marketType = selectedClient['a1_dvtip'] ? selectedClient['a1_dvtip'].toString().replace('&$&', '') : '';
      const fields = companyPolicies.find(policie => policie.policy === useObligationDeliveryFieldsByFreightType.dependencie).policy_value.split(';')

      if (marketType === 'T') {
        if (fieldValue === useObligationDeliveryFieldsByFreightType.policy_value) {
          dispatch(updateToOldPropertyField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields, property: 'obrigado' }))
        } else {
          dispatch(updatePropertyField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields, value: 'N', property: 'obrigado' }))
        }
      }
    }
    const useClientDeliveryAddress = companyPolicies.find(police => police.property === 'useClientDeliveryAddress' && police.policy_value === 'true');

    if (useClientDeliveryAddress && companyPolicies.find(police => police.policy === useClientDeliveryAddress.dependencie).policy_value && fieldName.includes('_XALTEND')) {
      const [field, value] = companyPolicies.find(police => police.policy === useClientDeliveryAddress.dependencie).policy_value.split('[').pop().split(']')[0].split('=')
      console.log(field, value)
      const fieldsToFill = companyPolicies.find(police => police.policy === useClientDeliveryAddress.dependencie).policy_value.split(']')[1].split(';').filter(item => item !== '').map(item => {
        const [key, value] = item.split('=')

        return {
          [`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }${ key.trim() }`]: value.trim()
        }
      })
      const fieldsToUpdate = fieldsToFill.map(field => Object.keys(field)[0].replace(selectedMenu.nome === 'Pedidos' ? 'C5_' : 'CJ_', ''))

      if (fieldName === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }${ field.trim() }` && fieldValue === value.trim()) {
        fieldsToFill.forEach(item => {
          const [[key, value]] = Object.entries(item)
          dispatch(updateFieldValues({ name: key, value: selectedClient[value] ? selectedClient[value] : '' }))
        })
        dispatch(updatePropertyField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields: fieldsToUpdate, property: 'visual', value: 'V' }))
      } else {
        fieldsToFill.forEach(item => {
          const [[key, value]] = Object.entries(item)
          dispatch(updateFieldValues({ name: key, value: '' }))
        })
        dispatch(updateToOldPropertyField({ alias: selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ', fields: fieldsToUpdate, property: 'visual' }))
      }
    }
  }

  function onTrigger(f3SelectedValue: INewF3Data, fieldKey: string, trigger: string[]) {
    const fieldIndex = fields.findIndex(field => field.nome === fieldKey) + 1
    trigger.shift();
    if (trigger.length > 0) {
      trigger.map((f3Key, index) => {
        const fieldTrigger = fields[fieldIndex + index]
        dispatch(updateFieldValues({ name: fieldTrigger.nome, value: f3SelectedValue[f3Key] }))
      })
    }
  }

  return (
    <Form style={ { display: 'flex', flexWrap: 'wrap', gap: '1.5rem', flexGrow: '1' } }>
      {
        fields.map(({ titulo, nome, obrigado, placeholder, visual, tipo, type, options, help, f3, f3Post, decimal = "2", conteudo, len, picture, fieldOccupationSize, nivel }) => {
          let isValid = true
          isValid = utils.validationFormField(tipo, mode);
          isValid = configuracoes.usecustomerlevel && nivel ? customerLevel >= Number(nivel) : isValid
          const enableReadOnly = utils.isFieldDisabled(mode, visual, tipo, f3);

          if (isValid) {
            return (
              <Form.Field key={ nome } required={ obrigado === 'S' } style={ { width: configuracoes.useoccupationsize && fieldOccupationSize ? `${ fieldOccupationSize }%` : '32%' } }>
                <DefaultLabel>
                  { titulo }
                  {
                    help &&
                    <Popup
                      style={ { fontWeight: '600' } }
                      trigger={ <Question size={ 14 } style={ { marginLeft: '0.5rem', marginBottom: '-2px' } } /> }
                      content={ help }
                      size='large'
                      inverted
                    />
                  }
                </DefaultLabel>

                {
                  type === 'character' && !f3 &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    onFocus={ e => e.target.select() }
                    maxLength={ len }
                    onChange={
                      (ev, { value }) => {
                        dispatch(updateFieldValues({ name: nome, value: utils.pictureValidate(picture, value) }));
                        // if(picture === '@!') {
                        //   dispatch(updateFieldValues({ name: nome, value: utils.pictureValidate(picture, value)  }))
                        // } else {
                        //   dispatch(updateFieldValues({ name: nome, value}))
                        // }
                      }
                    }
                    value={ fieldValues[nome] !== undefined ? fieldValues[nome] : conteudo }
                  />

                }
                {
                  type === 'number' &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    type="number"
                    onFocus={ e => e.target.select() }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    maxLength={ len }
                    onChange={
                      (ev, { value }) => { dispatch(updateFieldValues({ name: nome, value: Number(value) })) }
                    }
                    defaultValue={ conteudo }
                    value={ fieldValues[nome] }
                  />
                }
                {
                  type === 'percent' &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    icon="percent"
                    onFocus={ e => e.target.select() }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    maxLength={ len }
                    onChange={
                      async (ev, { value }) => { 
                        dispatch(updateFieldValues({ name: nome, value: intersectionsPercentValue(nome, value) })) 
                      }
                    }
                    defaultValue={ conteudo }
                    value={ fieldValues[nome]  }
                  />
                }
                {
                  type === 'date' &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    type="date"
                    onChange={
                      (ev, { value }) => { dispatch(updateFieldValues({ name: nome, value })) }
                    }
                    defaultValue={ fieldValues[nome] ? utils.formatDateDefault(fieldValues[nome].toString()) : utils.formatDateDefault(conteudo.toString()) }
                    value={ fieldValues[nome] ? utils.formatDateDefault(fieldValues[nome].toString()) : utils.formatDateDefault(conteudo.toString()) }

                  />
                }

                {
                  type === 'time' &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    type={ mode === 'view' ? 'text' : 'time' }
                    onChange={
                      (ev, { value }) => { dispatch(updateFieldValues({ name: nome, value })) }
                    }
                    defaultValue={ fieldValues[nome] ? fieldValues[nome] : conteudo }
                    value={ fieldValues[nome] || conteudo }
                  />
                }

                {
                  type === 'memo' && nome !== 'PW0_MSG' &&
                  <TextArea
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    type="date"
                    onChange={
                      (ev, { value }) => { dispatch(updateFieldValues({ name: nome, value })) }
                    }
                    defaultValue={ fieldValues[nome] || conteudo }
                    value={ fieldValues[nome] || conteudo }
                  />
                }

                {
                  type === 'memo' && nome === 'PW0_MSG' &&
                  <ReactQuill
                    style={ { height: '18rem' } }
                    theme="snow"
                    modules={ {
                      toolbar: [
                        [{ 'header': [1, 2, 3, 4, 5, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image'],
                        ['clean']
                      ],
                    } }
                    value={ String(fieldValues[nome]) || String(conteudo) }
                    onChange={ (value) => dispatch(updateFieldValues({ name: nome, value })) }
                  />
                }

                {
                  type === 'currency' &&
                  <Input
                    style={ { height: '4rem' } }
                    placeholder={ placeholder }
                    type="text"
                    onFocus={ e => e.target.select() }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    maxLength={ len }
                    onChange={
                      (ev, { value }) => { dispatch(updateFieldValues({ name: nome, value: utils.unmaskBRLCurrency(value, decimal) })) }
                    }
                    value={ utils.inputMaskBRLCurrency(Number(fieldValues[nome] || conteudo), decimal) }
                  />
                }
                {
                  type === 'combo' &&
                  <Dropdown
                    search
                    selection
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    style={ { maxWidth: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' } }
                    options={ options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } }) }
                    onChange={
                      (ev, { value }) => {
                        dispatch(updateFieldValues({ name: nome, value: value.toString() }))
                        intersectionsCombo(nome, value.toString())
                      }
                    }
                    value={ fieldValues[nome] || conteudo }
                  />
                }
                {
                  type === 'cnpj' &&
                  <CpfCnpj
                    value={ fieldValues[nome] || conteudo }
                    onChange={ (event) => {
                      dispatch(updateFieldValues({ name: nome, value: event.target.value }))
                    } }
                    onFocus={ e => e.target.select() }
                    onBlur={ (event) => { handleValidateCpfOrCnpj(event.target.value) } }
                    placeholder={ placeholder }
                    readOnly={ enableReadOnly }
                    disabled={ enableReadOnly }
                    style={ { height: '4rem' } }
                    maxLength={ 18 } //{len}
                  />
                }
                {
                  type === 'character' && !!f3 &&
                  <F3
                    clientId={ null }
                    token={ token }
                    consulta={ f3Post.consulta }
                    conteudo={ fieldValues[nome] || conteudo || f3Post.conteudo }
                    email={ f3Post.email }
                    requisito={ f3Post.requisito }
                    requisitocont={ f3Post.requisitocont }
                    readonly={ mode === 'view' || enableReadOnly }
                    placeholder={ placeholder }
                    label={ titulo }
                    name={ nome }
                    onChange={ (f3SelectReturn, trigger) => {
                      dispatch(updateFieldValues({ name: nome, value: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo }))
                      intersectionsF3Value(f3SelectReturn, { ...fieldValues, [nome]: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo })
                    } }
                    onChangeNewF3={ (f3SelectReturn, trigger) => {
                      dispatch(updateFieldValues({ name: nome, value: f3SelectReturn[trigger[0]] }))
                      intersectionsNewF3Value(f3SelectReturn)
                      onTrigger(f3SelectReturn, nome, trigger)
                    } }
                  />
                }
              </Form.Field>
            )
          }
        }
        )
      }
    </Form>
  );
}

export default RestField;