import { createSlice } from '@reduxjs/toolkit'

import { ICustomization, IPolicies, IPoliciesDropdown } from './../../services/methods/SettingsService';
import { IPoliciesList } from '../../services/methods/SettingsService';
import { ICustomizations } from '../../contexts/PolicieContext';
import { ICompanyStyle } from '../../interfaces/Styles/IStyles';

import { ISelectedCompany, IUpdateAuthToken, IUpdateCanLogin, IUpdateCompanyCustomizations, IUpdateCompanyEnvironment, IUpdateCompanyFiles, IUpdateCompanyId, IUpdateCompanyName, IUpdateCompanyPolicies, IUpdateCompanyStyles, IUpdateCustomizations, IUpdatePoliciesDropdown, IUpdatePoliciesList, IUpdateBackgroundType, IUpdateBackgroundColor, IUpdateMobileBackgroundType } from './actions';

export interface PolicieReducerInitialState {
  companyId: number;
  authToken: string;
  backgroundType: string;
  mobileBackgroundType: string;
  backgroundColor: string;
  companyFiles: string[];
  companyName: string;
  companyRest: string;
  companyEnvironment: string;
  companyStatus: 'active' | 'inactive';
  canLogin: boolean;
  policiesList: IPoliciesList[];
  policiesDropdown: IPoliciesDropdown[];
  companyPolicies: IPolicies[];
  companyCustomizations: ICustomization[];
  companyStyles: ICompanyStyle[];
  customizations: ICustomizations;
}

export const policieSlice = createSlice({
  name: 'policie',
  initialState: { 
    companyId: 0,
    authToken: '',
    backgroundType: '',
    mobileBackgroundType: '',
    backgroundColor: '#FFF',
    companyRest: '',
    companyFiles: [],
    companyName: '',
    companyEnvironment: '',
    companyStatus: 'active',
    canLogin: false,
    policiesList: ([] as IPoliciesList[]),
    policiesDropdown: ([] as IPoliciesDropdown[]),
    companyPolicies: ([] as IPolicies[]),
    companyCustomizations: ([] as ICustomization[]),
    companyStyles: ([] as ICompanyStyle[]),
    customizations: ({} as ICustomizations)
   },
  reducers: {
    updateCompanyId: (state, action: IUpdateCompanyId) => {
      state.companyId = action.payload.value
    },

    updateAuthToken: (state, action: IUpdateAuthToken) => {
      state.authToken = action.payload.value
    },

    updateCompanyFiles: (state, action: IUpdateCompanyFiles) => {
      state.companyFiles = action.payload.value
    },

    updateBackgroundType: (state, action: IUpdateBackgroundType) => {
      state.backgroundType = action.payload.value
    },

    updateMobileBackgroundType: (state, action: IUpdateMobileBackgroundType) => {
      state.mobileBackgroundType = action.payload.value
    },

    updateBackgroundColor: (state, action: IUpdateBackgroundColor) => {
      state.backgroundColor = action.payload.value
    },
    
    updateCompanyName: (state, action: IUpdateCompanyName) => {
      state.companyName = action.payload.value
    },

    updateCompanyRest: (state, action: IUpdateCompanyName) => {
      state.companyRest = action.payload.value
    },

    updateCompanyEnvironment: (state, action: IUpdateCompanyEnvironment) => {
      state.companyEnvironment = action.payload.value
    },

    updateCanLogin: (state, action: IUpdateCanLogin) => {
      state.canLogin = action.payload.value
    },

    updatePoliciesList: (state, action: IUpdatePoliciesList) => {
      state.policiesList = action.payload.value
    },

    updatePoliciesDropdown: (state, action: IUpdatePoliciesDropdown) => {
      state.policiesDropdown = action.payload.value
    },

    updateCompanyPolicies: (state, action: IUpdateCompanyPolicies) => {
      state.companyPolicies = action.payload.value
    },


    updateCompanyCustomizations: (state, action: IUpdateCompanyCustomizations) => {
      state.companyCustomizations = action.payload.value
    },

    updateCompanyStyles: (state, action: IUpdateCompanyStyles) => {
      state.companyStyles = action.payload.value
    },

    updateCustomizations: (state, action: IUpdateCustomizations) => {
      state.customizations = action.payload.value
    },

    selectedCompany: (state, action: ISelectedCompany) => {
      state.companyId = action.payload.companyId;
      state.companyName = action.payload.companyName;
      state.companyEnvironment = action.payload.companyEnvironment;
      state.companyStatus = action.payload.companyStatus;
    }
  }
})

export const { updateAuthToken, updateCanLogin, updateBackgroundType, updateMobileBackgroundType, updateBackgroundColor, updateCompanyRest, updateCompanyFiles, updateCompanyCustomizations, updateCompanyEnvironment, updateCompanyId, updateCompanyName, updateCompanyPolicies, updateCompanyStyles, updateCustomizations, updatePoliciesDropdown, updatePoliciesList, selectedCompany } = policieSlice.actions;
export default policieSlice.reducer;