import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

import ContextProvider from './contexts/ContextProvider';
import PyliRoutes from './routes/app.routes';

import environmentReducer from './reducers/Environment';
import dashboardReducer from './reducers/Dashboard';
import restFieldReducer from './reducers/RestField';
import policieReducer from './reducers/Policie';
import authReducer from './reducers/Auth';
import cartReducer from './reducers/Cart';
import menuReducer from './reducers/Menu';
import logsReducer from './reducers/Logs';
import dataTableReducer from './reducers/DataTable';
import validateRestFieldReducer from './reducers/RestFieldValidation';

import { GlobalStyle } from './assets/styles/global';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';

const store = configureStore({
	reducer: {
		restField: restFieldReducer,
    policie: policieReducer,
    environment: environmentReducer,
    auth: authReducer,
    cart: cartReducer,
    logs: logsReducer,
    dashboard: dashboardReducer,
    menu: menuReducer,
    dataTable: dataTableReducer,
    validate: validateRestFieldReducer
	}
});

const Pyli = () => {
  return (
    <CookiesProvider>
      <Provider store={ store }>
        <ContextProvider>
            <PyliRoutes />
            <ToastContainer 
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              closeButton
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ContextProvider>
      </Provider>
    </CookiesProvider>
  );
}

export default Pyli;
