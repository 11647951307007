import React, { FC } from 'react';
import { Modal, Button, Table, Label, SemanticCOLORS } from 'semantic-ui-react';

import { styleSheet } from '../../assets/styles/global';
import { MenuReducerInitialState } from "../../reducers/Menu";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, resetSearchOptionsSelected, updateSearchOptions } from "../../reducers/Cart";
import { clearFieldValues } from "../../reducers/RestField";
import { EnvironmentReducerInitialState } from "../../reducers/Environment";
import { useNavigate } from "react-router";
import { AuthReducerInitialState } from "../../reducers/Auth";
import { updateViewCheckoutModal } from "../../reducers/DataTable";

export interface IImportedProductInfo {
  product: string;
  status: string;
  desc: string;
  type: 'notFound' | 'withOutBalance' | 'quantityChange' | 'success'
}

const typeColors = {
  'notFound': 'red',
  'withOutBalance': 'yellow',
  'quantityChange': 'blue',
  'success': 'green'
}

interface IImportCsvModalWarningModal {
  visible: boolean;
  onClose(): void;
}

const ImportCsvModalWarning: FC<IImportCsvModalWarningModal> =({ visible, onClose }) => {

  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const { modalImportCsvWarningProductInfo } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function handleCancelImportCsv() {
    return Swal.fire({
      title: 'Confirmar ação?',
      text: 'Os itens do carrinhos serão perdidos!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, confirmar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onClose()
        dispatch(clearCart());
        dispatch(clearFieldValues());
        dispatch(updateSearchOptions({ value: [] }));
        dispatch(resetSearchOptionsSelected());
        navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets');
      }
    });
  }

  function handleContinueImportCsv() {
    onClose()
    if (configuracoes.usedirectproductsear && selectedMenu.nome !== 'Consulta') {
      dispatch(updateViewCheckoutModal({ value: true }))
    }
  }

  return(
    <>
      <Modal
        closeIcon
        open={visible}
        onClose={handleCancelImportCsv}
        style={{fontFamily: styleSheet.mainFont}}
        closeOnDimmerClick={false}
      >
        <Modal.Header
          fluid
        >
          Resumo da Leitura do Arquivo!
        </Modal.Header>
        <Modal.Content scrolling>
          <Table selectable>
            <Table.Header
              style={{textAlign: 'center'}}
            >
              <Table.Row>
                <Table.HeaderCell>Produto</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              { modalImportCsvWarningProductInfo.map((item) => (
                  <Table.Row
                  style={{textAlign: 'center'}}
                  >
                    <Table.Cell>{item.product}</Table.Cell>
                    <Table.Cell>{item.desc}</Table.Cell>
                    <Table.Cell>
                      <Label 
                        size="big"
                        color={typeColors[item.type] as SemanticCOLORS}
                        horizontal 
                        style={{fontWeight: 'bold'}} 
                      >
                        {item.status}
                      </Label>
                    </Table.Cell>
                    </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
          </Modal.Content>
          <Modal.Actions  >
            <Button.Group>
              <Button 
                secondary 
                negative
                size="big"
                onClick={handleCancelImportCsv}
              >
                Cancelar
              </Button>
              <Button.Or />

              <Button 
                primary 
                positive
                size="big"
                onClick={handleContinueImportCsv}
              >
                Prosseguir
              </Button>
          
            </Button.Group>
          </Modal.Actions>
      </Modal>
    </>
  );
}

export default ImportCsvModalWarning;

