import { AxiosError, AxiosResponse } from "axios";

import protheusProvider from "../providers/protheus.provider";

interface IDetailsParams {
  branchId: string;
  cliente: string | number;
  companyId: string;
  indice: string | number;
  ordem: string;
}

export class ClosureService {
  public async index(year: string, month: string, companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsfechamento', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || "",
          year,
          month
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async details(fechamentoId: string | number, companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsfechamentoitem', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          fechamentoId,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async closure(year: string, month: string, companyId: string, branchId: string, authToken: string, customerKey: string | number, type: 'approval' | 'invoice'): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put('wsfechamento', null, {
        params: {
          companyId,
          branchId,
          token: authToken,
          customerKey,
          year,
          month,
          type: type === 'approval' ? 'aprovacao' : 'fechamento'
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async sendEmail(year: string, month: string, companyId: string, branchId: string, authToken: string, customerKey: string | number, email): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put('wsfechamento', null, {
        params: {
          companyId,
          branchId,
          token: authToken,
          customerKey,
          year,
          month,
          type: 'email',
          email
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}