import React, { FC, createContext, useState } from "react";
import { UIStateType } from "../@types/generic";
import Error, { IErrorProps } from "../components/Error";
import { IUIContext } from "../interfaces/UI/IUIContext";

export const UIContext = createContext<IUIContext>({} as IUIContext);

const UIContextProvider: FC = ({ children }) => {
  const [ errorComponentState, setErrorComponentState ] = useState<IErrorProps>({ visible: false, title: '', message: '', statusHttp: 0, urlHttp: '', onClose: () => console.log('') } as IErrorProps);

  const updateUIComponentState = (uiComponentName: string, uiComponentState: UIStateType) => {
    switch(uiComponentName) {
      case 'error': setErrorComponentState(uiComponentState); break;
    }
  }

  return (
    <UIContext.Provider
      value={{
        setUIComponentState: updateUIComponentState
      }}
    >
      <Error 
        visible={ errorComponentState.visible }
        title={ errorComponentState.title }
        message={ errorComponentState.message }
        statusHttp={ errorComponentState.statusHttp }
        urlHttp={ errorComponentState.urlHttp }
        onClose={ errorComponentState.onClose }
      />
      { children }
    </UIContext.Provider>
  );
}

export default UIContextProvider;