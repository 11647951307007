import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class BudgetService {
  public async index(companyId: string, branchId: string, page: number, pageSize: number, authToken: string, customerKey: string, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsorcamento', {
        params: {
          companyId,
          branchId,
          page,
          pageSize,
          token: authToken,
          customerKey,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getData(companyId: string, branchId: string, authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsorcamento/${indice}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getItens(companyId: string, branchId: string,  authToken: string, selectedBudget: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsorcitem/${selectedBudget}`, {
        params: {
          companyId,
          branchId,
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async delete(authToken: string, indice: string | number): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.delete(`wsorcamento/${indice}`, {
        data: {
          token: authToken,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async effect(branchId: string, companyId: string, authToken: string, indice: string | number) {
    try {
      const response = await protheusProvider.post(`wsefetorc/${branchId}/${authToken}`, {
        indice,
      }, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}