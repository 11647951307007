import axios, { AxiosInstance } from "axios";

class PoliciesProvider {
  public axiosInstance: AxiosInstance;

  public url = {
    hml: 'https://wspolicies.ethosx.com.br',
    prod: 'https://wspolicies.ethosx.com.br',
    local: 'http://localhost:7000'
  }

  constructor() {
    this.axiosInstance = axios.create({

      baseURL: this.url.prod,
      headers: {
        'Accept-Language': 'pt-BR'
      }
    });
  }
}

export default new PoliciesProvider().axiosInstance;

