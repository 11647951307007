import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Icon, Input, InputProps } from 'semantic-ui-react';
import { TableData } from '../../@types/Datatable';
import { CartReducerInitialState, unSelectedSearchOptions } from '../../reducers/Cart';
import { DataTableReducerInitialState } from '../../reducers/DataTable';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AutoCompleteContainer, InputGroup, ResultBox } from './styles';

interface IInputSearchProps {
  onSearch(value: string, noDebounce?: boolean): void;
  nextPage(): Promise<void>;
  onSelect(product: TableData): void;
}

const InputSearch: FC<IInputSearchProps> = ({  nextPage, onSearch, onSelect }) => {
  const { searchResetOptionText, searchOptionsHasMore, searchOptions } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { tableLoading, } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const showBalanceInSearchingProductsOnCart = useMemo(() => companyPolicies.find(police => police.property === 'showBalanceInSearchingProductsOnCart' && police.policy_value === 'true'), [companyPolicies]);
  const allowSelectedProductWithoutBalance = showBalanceInSearchingProductsOnCart ? useMemo(() => companyPolicies.find(police => police.policy === showBalanceInSearchingProductsOnCart.dependencie && police.policy_value === 'true'), [companyPolicies]) : null;
  const useDiscountByWholesaleQuantity = companyPolicies.find(police => police.property === 'useDiscountByWholesaleQuantity' && police.policy_value === 'true');

  const dispatch = useDispatch();


  const [searchValue, setSearchValue] = useState('');
  const [openList, setOpenList] = useState(false);
  
  const enterKeyPressed = useRef(false)
  const observer = useRef({} as IntersectionObserver);
  const lastProductElementRef = useCallback(node => {
    if(tableLoading) return;
    if(Object.keys(observer.current).length !== 0 ) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && searchOptionsHasMore) {
        nextPage();
      }
    })

    if(node) observer.current.observe(node)
  }, [tableLoading, searchOptionsHasMore]);

  function handleUnSelect() {
    setSearchValue('');
    dispatch(unSelectedSearchOptions());
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearchCart').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    setSearchValue('');
  }, [searchResetOptionText])
  
  return (
    <AutoCompleteContainer
      expand={openList && searchOptions.length > 0}
    >
      <InputGroup>
        <Input 
          id="inputSearchCart"
          style={{ width: '100%', minHeight: '4rem' }}
          fluid
          type="search" 
          icon={searchValue.length !== 0 ? <Icon name='close' link onClick={handleUnSelect} /> : <Icon name='search' link />}
          loading={tableLoading}
          onFocus={e => e.target.select()}
          placeholder="Comece a digitar para buscar..."
          value={searchValue}
          onChange={(ev, { value }) => { 
            setOpenList(true);
            onSearch(ev.target.value, enterKeyPressed.current);
            setSearchValue(ev.target.value);
          }}
          onBlur={(event) => {
            onSearch(event.target.value, enterKeyPressed.current);
          }}
        />
      </InputGroup>

      <ResultBox
        active={openList}
      >
        <ul>
          {
            searchOptions.map((option, index) => {
              return (
                <li 
                  key={option['b1_cod']}
                  onClick={() => {
                    if(showBalanceInSearchingProductsOnCart && option['b1_xsaldo'] <= 0 && !allowSelectedProductWithoutBalance) {
                      return toast.error('Saldo insuficiente!')
                    }
                    onSelect(option);
                    setSearchValue(option['b1_desc'].toString());
                    setOpenList(false);
                  }}
                >
                  <strong>[{option['b1_cod']}]</strong>

                  {
                    showBalanceInSearchingProductsOnCart ? (
                      <>
                        {
                          option['b1_xsaldo'] <= 0 && (
                            <strong style={{ whiteSpace: 'nowrap' }}>[Saldo insuficiente]</strong>
                          )
                        }

                        {
                          option['b1_xsaldo'] > 100 && (
                            <strong style={{ whiteSpace: 'nowrap' }}>[Saldo suficiente]</strong>
                          )
                        }

                        {
                          option['b1_xsaldo'] > 0 && option['b1_xsaldo'] <= 100 && (
                            <strong style={{ whiteSpace: 'nowrap' }}>[Saldo de {option['b1_xsaldo']}]</strong>
                          )
                        }
                      </>
                    ) : null 
                  }
                  {
                    useDiscountByWholesaleQuantity && option['b1_xqtddat'] !== undefined ? (
                      <strong>[{option['b1_xqtddat']}]</strong>
                    ) : null
                  }
                  <span>{option['b1_desc']}</span>
                </li>
              )
            }
          )
          }
          {
            searchOptionsHasMore && !tableLoading && <li ref={lastProductElementRef}></li>
          }
          {
            tableLoading && (
              <li>
                <span>Carregando mais itens, aguarde...</span>
              </li>
            )
          }
        </ul>
      </ResultBox>
    </AutoCompleteContainer>
  )
}

export default InputSearch;