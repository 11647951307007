import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

export const PageDefault = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const LoginBox = styled.div`
  width: 40rem;
  min-height: 55rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5rem 2rem;
  border-radius: 2rem;
  z-index: 2;

  .tooltip {
    position: relative;
    display: inline-block; 
  }

  .tooltip .tooltiptext{
    visibility: hidden;
    width: 7rem;
    background-color: ${({ theme }) => theme.mainColor};
    color: ${({ theme }) => theme.lighterGrey};
    text-align: center;
    padding: 5px 0;
    border-radius: .2rem;
    position: absolute;
    z-index: 1;
    width: 110px;
    bottom: 100%;
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 1s;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.mainColor} transparent transparent transparent;
    
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

export const LoginBoxMobile = styled.div`
  width: 36rem;
  /* min-height: 55rem; */
  background-color: rgba(255, 255, 255, 0.5);
  padding: 5rem 2rem;
  border-radius: 2rem;
  z-index: 2;
`;

export const BackgrondFilter = styled.div`
  position: absolute;
  filter: blur(2rem);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  width: 40rem;
  height: 55rem;
`;

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 1.4rem !important;
  margin-bottom: 2rem;
  font-weight: 600 !important;
  color: ${({ theme }) => theme.mainFont} !important; //${styleSheet.darkerGrey}!important;
`;

export const Logotipo = styled.img`
  max-width: 100%;
  height: 8rem;
  margin-bottom: 3rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LinkContainer = styled.div`
  width: 100%;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  /* margin-top: 1rem; */
  font-weight: 600;
  color: #1E90FF !important;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  font-weight: 500 !important;
  line-height: 10px !important;
    .ui.toggle.checkbox label {
      padding-top: 0;
  }
  .ui.toggle.checkbox .box, .ui.toggle.checkbox label {
    color: #6c6c6c;
}
`;

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;

  color: #6c6c6c;
  font-weight: 600;
  font-size: 1.2rem;
`;



export const MobileViewContainer = styled.div`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 3rem;
`;

export const MobileViewTitle = styled.div`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 2.4rem;
  font-weight: bold;
  margin: 1rem 0;
  color: ${({ theme }) => theme.defaultFontColor}; //${styleSheet.defaultFontColor};
`;

export const MobileViewDescription = styled.div`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 1.4rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;

export const MobileContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  margin-top: 1rem;
  padding: 0 30px;
`;

export const MobileButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem 2.4rem;
  background-color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
  margin-top: 2rem;
  border-radius: 10px;
`;

export const MobileButtonText = styled.div`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 1.6rem;
  color: ${({ theme }) => theme.defaultFontColor}; //${styleSheet.defaultFontColor};
`;