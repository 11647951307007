import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Modal, Transition } from 'semantic-ui-react';
import { IOpcoes } from '../../interfaces/Menu/IMenu';

import { Container, LabelInput } from './styles';

interface IConfirmationSubmenuOptionModalProps {
  visible: boolean;
  isPrinting: boolean;
  options: IOpcoes[];
  onClose(): void;
  handleConfirmationAction(type: string): void;
}

const PrintModal: FC<IConfirmationSubmenuOptionModalProps> = ({ visible, isPrinting,  onClose, options, handleConfirmationAction }) => {

  const [checkboxValue, setCheckboxValue] = useState('');

  useEffect(() => {
    if(options && options.length > 0) {
      setCheckboxValue(options[0].tipo)
    }
  }, [visible])

  return (
    <Transition animation='fly up' visible={visible} unmountOnHide>
      <Modal
        closeIcon
        size="mini"
        onClose={onClose}
        open={visible}
      >
        <Container>
          <Modal.Header>
            <LabelInput>
              Opções de impressão
            </LabelInput>
          </Modal.Header>

          <Modal.Content>
            <Form style={{ marginTop: '15px' }}>
              {
                options && options.length > 0 && 
                  options.map(option => (
                    <Form.Field>
                      <Checkbox
                        style={{ fontSize: '14px' }}
                        radio
                        label={option.descricao}
                        value={option.tipo}
                        checked={checkboxValue === option.tipo}
                        onChange={(e, data) => { setCheckboxValue(data.value.toString()) } }
                      />
                    </Form.Field>
                  ))
              }
            </Form>
          </Modal.Content>
        </Container>

        <Modal.Actions>
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button 
              onClick={() => {
                console.log(checkboxValue)
                handleConfirmationAction(checkboxValue);
              }} 
              positive
              disabled={isPrinting}
              loading={isPrinting}
            >
              Imprimir
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </Transition >
  );
}

export default PrintModal;