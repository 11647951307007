import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import { Gear, House, Buildings, CheckSquareOffset, ShoppingBag, ClipboardText, Receipt, UserCircleGear, Person, ChartPie, Kanban, Calendar, ListChecks, SignOut, Warning, CalendarX, ListDashes, FileCsv, ChartLine } from 'phosphor-react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Swal from 'sweetalert2';

import { LogsService } from '../../services/methods/LogsService';

import ImportCsvModal from '../ImportCsvModal'
import { IMenuItem } from '../../interfaces/Menu/IMenu';

import { EnvironmentReducerInitialState, updateModalImportCsvVisible, updateModalImportCsvWarningVisible, updateSelectedClient } from '../../reducers/Environment';
import { MenuReducerInitialState, updateSelectedMenu } from '../../reducers/Menu';
import { CartReducerInitialState, updateGridList, updateIsCatalog, updateIsConsultation, updateItemsCount, updatePriceTable} from '../../reducers/Cart';
import { AuthReducerInitialState, signOut } from '../../reducers/Auth';
import { PolicieReducerInitialState } from '../../reducers/Policie';

import { styleSheet } from '../../assets/styles/global';

import { MenuContainer, LogoModal, ModalParagraph, MenuName, Logo, Menus, MenuItem, MenuFooter, ButtonTable } from './styles';
import { DataTableReducerInitialState, resetDataTable } from '../../reducers/DataTable';
import { useCookies } from 'react-cookie';
import { bucketURL } from '../../common/Constants';
import { Utils } from '../../common/Utils';
import { MenuContext } from '../../contexts/MenuContext';
import ImportCsvModalWarning from "../ImportCsvModalWarning";


interface ISideMenuProps {
  // selectedMenu: string;
  allowedMenus?: object[];
}

function MenuIcon({ menuName }: { menuName: string }): JSX.Element {
  switch (menuName.toLowerCase()) {
    case 'home': return <House size={ 22 } />;
    case 'empresas': return <Buildings size={ 22 } />;
    case 'pendencias': return <CheckSquareOffset size={ 22 } />
    case 'pedidos': return <ShoppingBag size={ 22 } />;
    case 'orcamentos': return <ClipboardText size={ 22 } />;
    case 'financeiro': return <Receipt size={ 22 } />;
    case 'usuarios': return <UserCircleGear size={ 22 } />;
    case 'vendedor': return <Person size={ 22 } />;
    case 'dashboard2': return <ChartPie size={ 22 } />;
    case 'dashboard': return <ChartPie size={ 22 } />;
    case 'projetos': return <Kanban size={ 22 } />;
    case 'agendas': return <Calendar size={ 22 } />;
    case 'ordemservico': return <Calendar size={ 22 } />;
    case 'catalogo': return <ListChecks size={ 22 } />;
    case 'consulta': return <ListDashes size={ 22 } />;
    case 'fechamento': return <CalendarX size={22} />;
    case 'wspedrap': return <FileCsv size={22} />;
    case 'importtocart': return <FileCsv size={22} />;
    case 'logs': return <ChartLine size={22} />;
    case 'sair': return <SignOut size={ 22 } />;
    default: return <Warning size={ 22 } />;
  }
}

const SideMenu: FC<ISideMenuProps> = () => {
  const logsService: LogsService = new LogsService();

  const utils = new Utils();

  const { companyEnvironment, companyName  } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedClient, modalImportCsvVisible, modalImportCsvWarningVisible } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { menuListArr, menuLoading, selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { itemsCount, gridList } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
	const { user, pwm, userType } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { tableLoading, tableSearching } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);

  const dispatch = useDispatch()
  const showPolicieSettings = pwm.showpoliciessettings !== undefined ? pwm.showpoliciessettings : pwm.showpoliciessettings || userType === '3' ? true : false;

  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);
  const { getMenuRoute } = useContext(MenuContext)

  const [isMenuHover, setIsMenuHover] = useState(false);
  const [modalLogoutState, setModalLogoutState] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (selectedMenu.nome.toLocaleLowerCase() === "wspedrap" || selectedMenu.nome.toLocaleLowerCase() === "importtocart")
      dispatch(updateModalImportCsvVisible({ value: true }))
  }, [selectedMenu])

  function navigateToPage(pageName: string) {

    navigate(`/${ pageName }`);
  }

  async function registerPageLog(pageName: string) {
    pageName = pageName.charAt(0).toUpperCase() + pageName.slice(1);
    if (['Home', 'Logs', 'Clibloq', 'atualizaemp', 'Trocaempresa', 'filtropedi', 'filtroorc', 'filtrofin'].indexOf(pageName) === -1) {
      await logsService.createPageLog(companyName, user, pageName, companyEnvironment)
    }
  }

  async function handleNavigation(pageName: string, currentMenu: IMenuItem) {
    if (tableLoading || tableSearching) {
      return Swal.fire({
        title: "Aguarde...",
        html: `Uma requisição está sendo processada em <strong>${ selectedMenu.descri }</strong>`,
        timer: 4000,
        icon: 'info'
      });
    }

    if (pageName === 'financial' && pwm.allowcustomerfin !== undefined && !pwm.allowcustomerfin) {
      // do nothing
    } else if ((
      pageName !== 'clients' &&
      pageName !== 'dashboard' &&
      pageName !== 'salesperson' &&
      pageName !== 'home' &&
      pageName !== 'approvals' &&
      pageName !== 'users' &&
      pageName !== 'schedules' &&
      pageName !== 'orderService' &&
      pageName !== 'logs' &&
      pageName !== 'wspedrap' &&
      pageName !== 'consulta' && 
      pageName !== 'closure' 
    ) && !selectedClient.indice) {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Nenhum cliente foi selecionado.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }

    if (itemsCount > 0 || gridList.length > 0) {
      return Swal.fire({
        title: 'Confirmar ação?',
        text: 'Os itens do carrinhos serão perdidos!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmar!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          if (`/${ pageName }` === location.pathname) return;
          dispatch(resetDataTable())
          setCookie('selected_menu', JSON.stringify(currentMenu))
          dispatch(updateSelectedMenu({ value: currentMenu }))
          navigateToPage(pageName);
          dispatch(updateItemsCount({ value: 0 }))
          dispatch(updateGridList({ value: [] }))
          registerPageLog(currentMenu.descri)
          dispatch(updateIsConsultation({ value: false }))
          dispatch(updatePriceTable({ value: '' }))

          if (currentMenu.nome === 'Catalogo') {
            dispatch(updateIsCatalog({ value: true }))
          } else {
            dispatch(updateIsCatalog({ value: false }))
          }

          if (pageName === 'wspedrap' || pageName === 'importtocart') {
            dispatch(updateModalImportCsvVisible({ value: true }))
          } else {
            navigateToPage(pageName);
          }
        }
      });
    } else {

      if (pageName === 'wspedrap' || pageName === 'importtocart') {
        navigateToPage(pageName);
        dispatch(updateModalImportCsvVisible({ value: true }))
      }

      if (`/${ pageName }` === location.pathname) return;

      dispatch(resetDataTable())
      setCookie('selected_menu', JSON.stringify(currentMenu))
      dispatch(updateSelectedMenu({ value: currentMenu }))
      dispatch(updateIsConsultation({ value: false }))
      navigateToPage(pageName);
      registerPageLog(currentMenu.descri);

      if (currentMenu.nome === 'Consulta') {
        dispatch(updateIsConsultation({ value: true }))
        dispatch(updatePriceTable({ value: '' }))
        navigate('/cart');
        return;
      }
      
      if(currentMenu.nome === 'Catalogo') {
        dispatch(updateIsCatalog({ value: true }))
      }else {
        dispatch(updateIsCatalog({ value: false }))
      }

      navigateToPage(pageName);
    }
  }
  function handleChangeSettings() {
    navigate('/settings')
    dispatch(updateSelectedMenu({ value: { descri: 'configurações', nome: 'configurações', alias: '', ordem: 999, submenu: [] } }))
  }

  function handleConfirmationLogout() {
    setModalLogoutState(true);
  }

  function handleLogout() {
    removeCookie('user_info')
    removeCookie('current_branch')
    removeCookie('current_company')
    removeCookie('selected_client')
    removeCookie('selected_menu')

    dispatch(signOut())
    dispatch(updateItemsCount({ value: 0 }))
    dispatch(updateSelectedClient({ value: {} }))
    localStorage.removeItem('isToRemember');
    localStorage.removeItem('rememberToken');
    navigate('/');
  }

  return (
    <>
      <MenuContainer>

        <Menus>
          <Logo className="logoBucket" src={ companyName && `${ bucketURL }/${ companyEnvironment }/${ companyName }/logo` } alt="Logotipo" />

          {
            menuLoading && <Skeleton style={ { marginBottom: '10px' } } borderRadius={ 15 } height={ 50 } width={ 50 } baseColor='#DDD' count={ 5 } />
          }

          {
            !menuLoading && menuListArr && menuListArr.map(({ nome, descri }, index) => {
              return (
                <MenuItem
                  key={ nome }
                  isActive={ selectedMenu.nome === nome }
                  onClick={ () => handleNavigation(getMenuRoute(nome), menuListArr[index]) }
                >
                  <MenuIcon menuName={ nome } />
                  <MenuName>{ descri || nome }</MenuName>
                </MenuItem>

              );
            })
          }
          {
            showPolicieSettings &&
            <MenuItem
              isActive={ selectedMenu.nome === 'configurações' }
              onClick={ handleChangeSettings }
            >
              <Gear size={ 22 } />
              <MenuName>Configurações</MenuName>
            </MenuItem>
          }
        </Menus>


        <MenuFooter>
          <Popup
            style={ {
              borderRadius: 0,
              opacity: 0.7,
            } }
            trigger={
              <ButtonTable
                onClick={ handleConfirmationLogout }
              >
                <SignOut
                  size={ 22 }
                  style={ { cursor: 'pointer', color: `hsl(${ utils.hexToHSL(styleSheet.mainColor) }, 100%, 80%)` } }
                />
              </ButtonTable>
            }
            content={ <span style={ { fontSize: '1.3rem' } }>Sair</span> }
            inverted
          />
        </MenuFooter>
      </MenuContainer>

      <Modal
        basic
        onClose={ () => setModalLogoutState(false) }
        onOpen={ () => setModalLogoutState(true) }
        open={ modalLogoutState }
        size='small'
        style={ { fontFamily: styleSheet.mainFont } }
        trigger={ onclick }
      >
        <Header
          style={ { fontFamily: styleSheet.mainFont, fontSize: '2rem', display: 'flex', flexDirection: 'column' } }
        >
          <LogoModal className="logoBucket" src={ companyName && `${ bucketURL }/${ companyEnvironment }/${ companyName }/logo` } alt="Logotipo" />
          Deseja realmente fazer o Logoff?
        </Header>
        <Modal.Content>
          <ModalParagraph>
            Ao concordar em sair, todas as alterações não salvas
            serão perdidas!
          </ModalParagraph>
        </Modal.Content>
        <Modal.Actions>
          <Button size='huge' inverted onClick={ () => setModalLogoutState(false) }>
            <Icon name='remove' />
            Cancelar
          </Button>
          <Button size='huge' color='green' inverted onClick={ () => handleLogout() }>
            <Icon name='checkmark' />
            Sair
          </Button>
        </Modal.Actions>
      </Modal>

      <ImportCsvModal
        visible={ modalImportCsvVisible }
        onClose={ () => dispatch(updateModalImportCsvVisible({ value: false })) }
      />

      <ImportCsvModalWarning 
        visible={modalImportCsvWarningVisible}
        onClose={() => dispatch(updateModalImportCsvWarningVisible({ value: false }))}
      />
    </>
  );
}

export default SideMenu;
