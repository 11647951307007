import { createSlice } from '@reduxjs/toolkit'

import { IFilterField, IGraph, IGroup } from '../../interfaces/Dashboard/IDashboardContext';
import { IUpdateFilterFields, IUpdateGraphsData, IUpdateGraphSelected, IUpdateGraphsJsx, IUpdateGroups, IUpdateIsLoading, IUpdateIsLoadingGraphFullscreen, IUpdateModalChartOpen, IUpdateSelectedGraphsJsx, IUpdateSelectedGroup } from './action';

export interface DashboardReducerInitialState {
  groups: IGroup[];
  selectedGroup: IGroup;
  graphsData: IGraph[];
  graphSelected: IGraph;
  isLoading: boolean;
  isLoadingGraphFullscreen: boolean;
  graphsJsx: JSX.Element;
  selectedGraphsJsx: JSX.Element;
  modalChartOpen: boolean;
  filterFields: IFilterField[];
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    groups: ([] as  IGroup[]),
    selectedGroup: ({} as IGroup),
    graphsData: ([] as IGraph[]),
    graphSelected: ({} as IGraph),
    isLoading: true,
    isLoadingGraphFullscreen: true,
    graphsJsx: ({} as JSX.Element),
    selectedGraphsJsx: ({} as JSX.Element),
    modalChartOpen: false,
    filterFields: ([] as IFilterField[])
  },
  reducers: {
    updateGroups: (state, action: IUpdateGroups) => {
      state.groups = action.payload.value
    },

    updateSelectedGroup: (state, action: IUpdateSelectedGroup) => {
      state.selectedGroup = action.payload.value
    },

    updateGraphsData: (state, action: IUpdateGraphsData) => {
      state.graphsData = action.payload.value
    },

    updateGraphSelected: (state, action: IUpdateGraphSelected) => {
      state.graphSelected = action.payload.value
    },

    updateIsLoading: (state, action: IUpdateIsLoading) => {
      state.isLoading = action.payload.value
    },

    updateIsLoadingGraphFullscreen: (state, action: IUpdateIsLoadingGraphFullscreen) => {
      state.isLoadingGraphFullscreen = action.payload.value
    },

    updateGraphsJsx: (state, action: IUpdateGraphsJsx) => {
      state.graphsJsx = action.payload.value
    },

    updateSelectedGraphsJsx: (state, action: IUpdateSelectedGraphsJsx) => {
      state.selectedGraphsJsx = action.payload.value
    },

    updateModalChartOpen: (state, action: IUpdateModalChartOpen) => {
      state.modalChartOpen = action.payload.value
    },

    updateFilterFields: (state, action: IUpdateFilterFields) => {
      state.filterFields = action.payload.value
    },
  }
})

export const { updateFilterFields, updateGraphSelected, updateGraphsData, updateGraphsJsx, updateGroups, updateIsLoading, updateIsLoadingGraphFullscreen, updateModalChartOpen, updateSelectedGraphsJsx, updateSelectedGroup } = dashboardSlice.actions;
export default dashboardSlice.reducer; 