import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class ApprovalsService {
  public async index(branchId: string, page: number, pageSize: number, authToken: string, order: string, companyId: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsrestorccli', {
        params: {
          branchId,
          companyId,
          page,
          pageSize,
          token: authToken,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async approvedOrRefused(branchId: string, authToken: string, companyId: string, indice: string | number, type: 'A' | 'R'): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post('wsrestaprorc', {
        branchId,
        companyId,
        indice,
        token: authToken,
        type
      });
 
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;

      return response;
    }
  }
}