import styled from "styled-components";

interface AutoCompleteContainerProps {
  expand: boolean;
}

export const AutoCompleteContainer = styled.div<AutoCompleteContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  position: relative;
`;

export const InputGroup =  styled.div`
  width: 100%;
`;

interface ResultBoxProps {
  active: boolean;
}

export const ResultBox = styled.div<ResultBoxProps>`
  display: ${({ active }) => active ? 'block' : 'none'};
  /* position: absolute; */
  background: #fff;
  border: 0.1rem solid #b5b5b5;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top: 0;
  width: 100%;
  z-index: 999999999;
  max-height: 15rem;
  overflow-y: auto;
  /* top: 4rem;
  left: 0px;
  right: 0xp; */

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      width: 100%;
      transition: 0.2s ease-out;
      cursor: pointer;

      strong {
        margin-right: 1rem;
      }
    }

    li:hover {
      background: #B0E0E6;
    }
  }

  ::-webkit-scrollbar {
    width: 6px;     
  }

  ::-webkit-scrollbar-track {
    background: #e5e5e5;    
    border-radius: 20px;       

  }

  ::-webkit-scrollbar-thumb {
    background-color: #c3c3c3;    
    border-radius: 20px;       
    border: 3px solid #c3c3c3;  
  }
`;