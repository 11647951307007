import { createSlice } from '@reduxjs/toolkit';
import { IUpdateCgcIsValid } from './actions';
import { CGCField } from '../../@types/Restfield';

export interface RestFieldValidationReducerInitialState {
  cgcIsValid: CGCField
}

export const validateSlice = createSlice({
  name: 'validate',
  initialState: {
    cgcIsValid: {
      type: '',
      isValid: false,
    } as CGCField,
  },
  reducers:{
    updateCgcIsValid: (state, action: IUpdateCgcIsValid) => {
      state.cgcIsValid.type = action.payload.type
      state.cgcIsValid.isValid = action.payload.value
    }
  }
});


export const { updateCgcIsValid } = validateSlice.actions;
export default validateSlice.reducer;