import axios, { AxiosInstance } from "axios";

class EmailProvider {
  public axiosInstance: AxiosInstance;

  public url = {
    hml: 'https://apisms.ethosx.com.br/',
    prod: 'https://apisms.ethosx.com.br/'
  }

  constructor() {
    this.axiosInstance = axios.create({

      baseURL: this.url.hml,
      headers: {
        'Accept-Language': 'pt-BR'
      }
    });
  }
}

export default new EmailProvider().axiosInstance;

