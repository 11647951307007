export function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
  let timeoutId: NodeJS.Timeout | undefined;

  return function (this: any, ...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const [event, value] = args;

    if (event === 'enter') {
      func.call(this, value);
    } else {
      timeoutId = setTimeout(() => {
        func.call(this, value);
      }, delay);
    }
  };
}