import styled from 'styled-components';

export const InputOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

`;

export const LabelComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToggleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoaderContent = styled.div`
  display: flex;
  justify-self: flex-end;
  align-self: center;
`;

