import React, { FC } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { Barcode, Code, Upload, CalendarPlus, Password, Eye, Trash, Printer, Pencil, Envelope, Copy } from 'phosphor-react';

import { ISubmenuItem } from '../../interfaces/Menu/IMenu';
import { ButtonTable } from './styles';

const IconsMenu = {
  Alterar: <Pencil size={20} color="#A15F7F"  />,
  AlterarOrc: <Pencil size={20} color="#A15F7F"  />,
  Visualizar: <Eye size={20} color="#6FCCF1" />,
  Excluir: <Trash size={20} color="#E47261" />,
  Deletar: <Trash size={20} color="#E47261" />,
  Upload: <Upload size={20} color="#4A485F" />,
  'Danfe/XML': <Code size={20} color="#84686D" />,
  Boleto: <Barcode size={20} color="#4AAF9A" />,
  Efetivar: <CalendarPlus size={20} color="#648D56" />,
  EfetivaOrc: <CalendarPlus size={20} color="#648D56" />,
  ResetSenha: <Password size={20} color="#F8AA94" />,
  EnviaPedid:  <Envelope size={22} color="#EDA14C" />,
  Print:  <Printer size={22} color="#CBBA86" />,
  Copia: <Copy size={20} color="#4AAF9A" />,
}

enum ColorsMenu {
  Alterar = 'purple',
  AlterarOrc = 'purple',
  Visualizar = 'blue',
  Deletar = 'red',
  Upload = 'blue',
  'Danfe/XML' = 'violet',
  Boleto = 'teal',
  Efetivar = 'green',
  ResetSenha = 'pink',
  EnviaPedid = 'orange',
  Print = 'yellow',
  Planilha = 'orange',
  Copia = 'teal'
}

enum FunctionsMenu {
  Alterar = 'edit',
  AlterarOrc = 'edit',
  Visualizar = 'view',
  Deletar = 'delete',
  Upload = 'upload',
  'Danfe/XML' = 'xml',
  Boleto = 'boleto',
  Efetivar = 'effect',
  ResetSenha = 'resetPassword',
  EnviaPedid = 'email',
  Print = 'print',
  Planilha = 'taxes',
  Copia = 'copy'
}

interface SubMenuOptionsProps {
  subMenuSelected: ISubmenuItem;
  type: 'icon' | 'text';
  onOptionClick(action: string, indice?: string | number): void;
}

const SubMenuOptions: FC<SubMenuOptionsProps> = ({ subMenuSelected, type, onOptionClick}) => {
  return (
    <>
      {
        type === 'icon' && subMenuSelected.opcoes && 
        <Button.Group size='small'>
          {
            subMenuSelected.opcoes.map(({ nome, descricao }) => (
              ColorsMenu[nome] !== undefined &&
              <Popup
                trigger={
                  <ButtonTable 
                    color={ColorsMenu[nome]} 
                    onClick={() => {
                      onOptionClick(FunctionsMenu[nome])
                    }}
                  >
                    { IconsMenu[nome]}
                  </ButtonTable>
                }
                content={<span style={{ fontSize: '1.3rem' }}>{descricao}</span>}
                inverted
              />
            ))
          }
        </Button.Group>
      }

      {
        type === 'text' && subMenuSelected.opcoes && 
        <Button.Group size='big'>
         { 
          subMenuSelected.opcoes.map(({ nome, descricao }) => (
            ColorsMenu[nome] !== undefined && <Button  onClick={() => onOptionClick(FunctionsMenu[nome])} content={descricao} color={ColorsMenu[nome]} />
          )) 
        }
        </Button.Group>
      }
    </>
  );
}

export default SubMenuOptions;