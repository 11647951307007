import React, { createContext, FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "semantic-ui-react";
import { Card } from "semantic-ui-react";
import Swal from "sweetalert2";

import { DashboardService } from "../services/methods/DashboardService";

import { UIContext } from "./UIContext";

import { IErrorProps } from "../components/Error";

import {
  IDashboardContext,
  IFilterField,
  IGraph,
  IGroup,
} from "../interfaces/Dashboard/IDashboardContext";

import { AuthReducerInitialState } from "../reducers/Auth";

import { Utils } from "../common/Utils";

import RobotLoader from "../assets/images/robot_loading_2.gif";
import {
  DashboardReducerInitialState,
  updateFilterFields,
  updateGraphsData,
  updateGraphSelected,
  updateGraphsJsx,
  updateGroups,
  updateIsLoading,
  updateIsLoadingGraphFullscreen,
  updateModalChartOpen,
  updateSelectedGraphsJsx,
  updateSelectedGroup,
} from "../reducers/Dashboard";
import Chart from "../components/Dashboard/BuildChart";

export const DashboardContext = createContext<IDashboardContext>(
  {} as IDashboardContext
);

const DashboardContextProvider: FC = ({ children }) => {
  const { token } = useSelector(
    (state: { auth: AuthReducerInitialState }) => state.auth
  );
  const { selectedGroup } = useSelector(
    (state: { dashboard: DashboardReducerInitialState }) => state.dashboard
  );
  const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);
  const dashboardService = new DashboardService();
  const utils = new Utils();

  const drawLoadingRobot = () => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image src={RobotLoader} size="medium" centered />
      </div>
    );
  };

  const drawChart = (type: string, { data, keys, types }: IGraph) => {

    
    if (type === "timeline") {
      return <Chart type={type} data={data} />;
    }

    if (type === "lista" || type === "barralista" || type === "pizzalista") {
      return <Chart type={type} data={data} keys={keys} types={types} />;
    }

    return <Chart type={type} data={data} keys={keys} />;
  };

  const drawOpenChartFullscreen = (
    type: string,
    {  data, keys, types,  subGraph, subGraphType }: IGraph
    ) => { 
    
    if (types) {
      types.map((itemType, index)=> {
        if(itemType === "M"){
         data.map((item)=> (item[keys[index]] = utils.formatFloatAsBRL(parseFloat(item[keys[index]]))))
        }
      })
    }

    if (type === "timeline") {
      return <Chart type={type} data={data} isFullscreen={true} />;
    }

    if (type === "lista") {
      return (
        <Chart
          type={type}
          data={data}
          keys={keys}
          types={types}
          isFullscreen={true}
        />
      );
    }

    if (type === "barralista" || type === "pizzalista") {
      return (
        <Chart
          type={type}
          data={data}
          keys={keys}
          types={types}
          isFullscreen={true}
          subGraph={subGraph}
          subGraphType={subGraphType}
        />
      );
    }

    return <Chart type={type} data={data} keys={keys} isFullscreen={true} />;
  };

  async function createGraphJSX(group: IGroup, authToken: string) {
    dispatch(updateGraphsData({ value: [] }));
    dispatch(updateGraphsJsx({ value: <div></div> }));
    dispatch(updateSelectedGroup({ value: group }));

    const graphs = group.graficos;
    const graficos: IGraph[] = [];

    for (const graph of graphs) {
      if (graph.real == "2") {
        graficos.push({ ...graph, data: [""], keys: [] });
      } else {
        const response = await fetchGraphData(graph, {}, authToken, group.grupo);
        if (response !== undefined) {
          graficos.push(response);
        }
      }
    }

    dispatch(updateGraphsData({ value: graficos }));
    dispatch(
      updateGraphsJsx({
        value: (
          <Card.Group
            style={{ width: "100%" }}
            stackable
            textAlign="center"
            itemsPerRow={2}
          >
            {graficos.map((grafico) => {
              const { tipo, titulo, real, servico } = grafico;
              if (tipo) {
                return (
                  <Card
                    onClick={() =>
                      handleOpenChartFullscreen(grafico, authToken, group.grupo)
                    }
                    fluid
                    centered={true}
                    key={servico}
                    link
                  >
                    <Card.Content>
                      <Card.Header>{titulo}</Card.Header>
                      {real == "1"
                        ? drawChart(tipo, grafico)
                        : drawLoadingRobot()}
                    </Card.Content>
                  </Card>
                );
              }
            })}
          </Card.Group>
        ),
      })
    );
  }

  async function fetchGroups(authToken: string) {
    try {
      const { data, status, request } = await dashboardService.getGroups(
        authToken
      );

      dispatch(updateIsLoading({ value: true }));

      if (status === 200) {
        dispatch(updateGraphsData({ value: [] }));
        dispatch(updateGraphsJsx({ value: <div></div> }));
        dispatch(updateGroups({ value: data.grupos }));
        await createGraphJSX(data.grupos[0], authToken);
      } else {
        setUIComponentState("error", {
          visible: true,
          title: "Ops...",
          message: "Houve um erro ao buscar a lista de clientes.",
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState("error", {
              visible: false,
              title: "Ops...",
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => {
                console.log();
              },
            });
          },
        } as IErrorProps);
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateIsLoading({ value: false }));
    }
  }

  async function fetchGraphData(
    graph: IGraph,
    filtro: object,
    authToken: string,
    group: string
  ): Promise<IGraph> {
    try {
      const { data, status, request } = await dashboardService.getGraph(
        graph.servico,
        filtro || {},
        authToken,
        group
      );
        
      if (status === 200 || status === 201) {
        if (graph.tipo === "pontos") {
          const keys: string[] = data[0];
          const dataParsed: [string | number | object] = [0];
          dataParsed.shift();

          for (const index in data) {
            if (Number(index) === 0) continue;

            dataParsed.push(data[index][0], data[index][1]);
          }

          dataParsed.shift();

          return { ...graph, data: dataParsed, keys };
        } else if (graph.tipo === "timeline") {
          const dataParsed: [string | number | object] = [0];
          dataParsed.shift();

          for (const index in data) {
            dataParsed.push({
              x: data[index][0],
              y: [data[index][1], data[index][2]],
              fillColor: utils.getRandomColor(),
            });
          }

          return { ...graph, data: dataParsed };
        } else if (graph.tipo === "barrae") {
          const keys: string[] = data.map((x) => x[0]);
          const dataParsed: [string | number | object] = [0];
          dataParsed.shift();

          for (const index in data[0]) {
            if (Number(index) === 0) continue;

            dataParsed.push({
              name: data[0][index],
              data: data.map((x) => x[index]).slice(1),
            });
          }

          // dataParsed.shift();
          keys.shift();

          return { ...graph, data: dataParsed, keys };
        } else if (graph.tipo === "pizza") {
          const keys: string[] = data.map((x) => x[0]);
          const dataParsed = data;

          keys.shift();

          return { ...graph, data: dataParsed, keys };
        } else if (graph.tipo === "lista") {
          const keys: string[] = data[1][0];
          const dataParsed = data[1].splice(1);
          const formattedData = [];
          dataParsed.map((item) => {
            const result = {};
            item.map((value, index) => {
              result[keys[index]] = value;
            });
            formattedData.push(result);
          });

          return {
            ...graph,
            data: formattedData as [string | number | object | []],
            keys,
            types: data[0][1],
          };
        } else if (graph.tipo === "barralista" || graph.tipo === "pizzalista") {
          const keys: string[] = data.Grafico[1][0];
          const dataParsed = data.Grafico[1].splice(1);
          const formattedData = [];
          dataParsed.map((item) => {
            const result = {};
            item.map((value, index) => {
              result[keys[index]] = value;
            });
            formattedData.push(result);
          });

          return {
            ...graph,
            data: formattedData as [string | number | object | []],
            keys,
            types: data.Grafico[0][1],
            subGraph: data.SubGrafico,
            subGraphType: graph.tipo,
          };
        } else if (graph.tipo === "geo") {
          let max = 0;
          let min = 99999999999;
          for (let i = 0; i < data.length; i++) {
            if (i === 0) continue;
            if (i === 1) continue;

            if (Number(data[i].value) > max) {
              max = data[i].value;
            }
            if (Number(data[i].value) < min) {
              min = data[i].value;
            }
          }
          data.shift();
          const keys = [];
          keys.push(String(min));
          keys.push(String(max));
          return { ...graph, data: data, keys };
        } else if(graph.tipo === "barradupla") {
          const keys: string[] = [data.Grafico.map((x) => x[0]), data.Grafico2.map((x) => x[0])];
          const dataParsed: [string | number | object] = [0];
          dataParsed.shift();

          for (const index in data.Grafico[0]) {
            dataParsed.push({
              name: data.Grafico.map((x) => x[index])[0],
              data: data.Grafico.map((x) => x[index]).slice(1),
            });
          }

          dataParsed.shift();

          const dataParsed2: [string | number | object | []] = [0];
          dataParsed2.shift();

          for (const index in data.Grafico2[0]) {
            dataParsed2.push({
              name: data.Grafico2.map((x) => x[index])[0],
              data: data.Grafico2.map((x) => x[index]).slice(1),
            });
          }

          dataParsed2.shift();

          return { ...graph, data: [dataParsed, dataParsed2], keys };
        } else if (graph.tipo === "pizzabarra") {
          const keys1: string[] = data.Grafico.map((x) => x[0]);
          const dataParsed1 = data.Grafico;

          keys1.shift();

          const keys2: string[] = data.Grafico2.map((x) => x[0]);
          const dataParsed2: [string | number | object] = [0];
          dataParsed2.shift();

          for (const index in data.Grafico2[0]) {
            dataParsed2.push({
              name: data.Grafico2.map((x) => x[index])[0],
              data: data.Grafico2.map((x) => x[index]).slice(1),
            });
          }

          dataParsed2.shift();
          
          return { ...graph, data: [dataParsed1, dataParsed2], keys: [...keys1, '-', ...keys2] };
        } else {

          const keys: string[] = data.map((x) => x[0]);
          const dataParsed: [string | number | object] = [0];
          dataParsed.shift();

          for (const index in data[0]) {
            dataParsed.push({
              name: data.map((x) => x[index])[0],
              data: data.map((x) => x[index]).slice(1),
            });
          }

          dataParsed.shift();

          return { ...graph, data: dataParsed, keys };
        }
      } else {
        setUIComponentState("error", {
          visible: true,
          title: "Ops...",
          message: "Houve um erro ao buscar a lista de clientes.",
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState("error", {
              visible: false,
              title: "Ops...",
              message: "Houve um erro ao buscar a lista de clientes.",
              statusHttp: status,
              urlHttp: request,
              onClose: () => {
                console.log("");
              },
            } as IErrorProps);
          },
        } as IErrorProps);

        return undefined;
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    }
  }

  async function handleOpenChartFullscreen(
    graph: IGraph,
    authToken: string,
    group: string,
    filtros?: object
  ) {
    try {
      dispatch(updateFilterFields({ value: [] }));
      dispatch(updateGraphSelected({ value: graph }));
      dispatch(updateModalChartOpen({ value: true }));
      dispatch(updateIsLoadingGraphFullscreen({ value: true }));
      if (graph.filtros.length > 0) {
        const campoFiltros: IFilterField[] = [];

        for (const filter of graph.filtros) {
          const name = filter.filtro;
          const servicoNome = graph.servico;
          const { data, status } = await dashboardService.getGraph(
            graph.servico,
            {},
            authToken,
            group,
            filter.funcao
          );
          if (status === 200 || status === 201) {
            campoFiltros.push({
              graph: servicoNome,
              nome: name,
              options: data,
              isDate: data[0].data !== undefined ? true : false,
            });
          }
        }

        dispatch(updateFilterFields({ value: campoFiltros }));
      }

      const {
        tipo,
        titulo,
        data,
        keys,
        servico,
        types,
        subGraph,
        subGraphType,
      } = await fetchGraphData(graph, filtros || {}, authToken, group);

      if (tipo) {
        const graph = { data, keys, types, subGraph, subGraphType } as IGraph;
        dispatch(
          updateSelectedGraphsJsx({
            value: (
              <Card centered={true} fluid key={servico}>
                <Card.Content>
                  <Card.Header
                    style={{ fontSize: "2rem", marginBottom: "1rem" }}
                    textAlign="center"
                  >
                    {titulo}
                  </Card.Header>
                  {drawOpenChartFullscreen(tipo, graph)}
                </Card.Content>
              </Card>
            ),
          })
        );
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateIsLoadingGraphFullscreen({ value: false }));
    }
  }

  async function handleChangeGroup(group: IGroup, authToken: string) {
    dispatch(updateIsLoading({ value: true }));
    await createGraphJSX(group, authToken);
    dispatch(updateIsLoading({ value: false }));
  }

  return (
    <DashboardContext.Provider
      value={{
        fetchGraphData,
        fetchGroups,
        handleChangeGroup,
        handleOpenChartFullscreen,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
