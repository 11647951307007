import { Button, Dropdown, Form, Input, Popup, Table, Image } from "semantic-ui-react";
import { Trash, Plus, SquaresFour } from 'phosphor-react';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { CartContext, dictionaryEXC6, dictionaryEXCK } from "../../../contexts/CartContext";

import F3 from "../../F3";

import { PolicieReducerInitialState } from "../../../reducers/Policie";
import { AuthReducerInitialState } from "../../../reducers/Auth";

import { ButtonTable, InputNumber, TableContainer } from "./styles";
import { CartReducerInitialState, autoCompleteAllFieldsValue, deleteGrid, deleteItem, updateDictionaryC6, updateDictionaryCK, updateProductDiscountIncreaseAdjustedPrice, updateProductTotalPrice, updateProductValueList, updateSearchItemSelected, updateSearchItemSelectedDiscountIncreaseAdjustedPrice, updateSearchItemSelectedTotalPrice, updateSearchItemSelectedValue, updateSearchOptions, updateSearchOptionsFilter, updateSearchOptionsHasMore, updateSearchOptionsPage, updateSearchProductSelected } from "../../../reducers/Cart";
import { Utils } from "../../../common/Utils";

import InputSearch from "../../InputSearch";
import { IErrorProps } from "../../Error";
import { UIContext } from "../../../contexts/UIContext";
import { ProductService } from "../../../services/methods/ProductService";
import { EnvironmentReducerInitialState } from "../../../reducers/Environment";
import { IPaginatedServiceResponse } from "../../../interfaces/Datatable/IPaginatedServiceResponse";
import { TableData } from "../../../@types/Datatable";
import { MenuReducerInitialState } from "../../../reducers/Menu";
import { updateTableLoading, updateTableSearching } from "../../../reducers/DataTable";

import defaultProductImg from '../../../assets/images/default-product.jpeg';
import { updateFieldValues } from "../../../reducers/RestField";
import { debounce } from "../../../common/debounce";

const DatatableRestField: FC = () => {
  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth - (window.innerWidth * 6 / 100))

  const { 
    productList, itemsList, itemFields, type, isEditing, priceTable, 
    dictionaryC6, dictionaryCK, searchOptions, searchOptionsFilter,
    searchOptionsPage, searchOptionsHasMore, searchResetOptionText, 
    searchProductSelected, searchItemSelected, gridList
  } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { currentBranch, selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const productService: ProductService = new ProductService();

  const dictionary =  selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
  const useDecimalProductQuantity = companyPolicies.find(police => police.property === 'useDecimalProductQuantity' && police.policy_value === 'true');
  const useCheckBalance = companyPolicies.find(policy => policy.property === 'useCheckBalance' && policy.policy_value === 'true');
  const useSeparateProductInputSearch = companyPolicies.find(policy => policy.property === 'useSeparateProductInputSearch' && policy.policy_value === 'true');
  const useDiscountValidationByClientField = companyPolicies.find(policy => policy.property === 'useDiscountValidationByClientField' && policy.policy_value === 'true');
  const useDiscountValidationByProductField = companyPolicies.find(policy => policy.property === 'useDiscountValidationByProductField' && policy.policy_value === 'true');
  const useDiscountByWholesaleQuantity = companyPolicies.find(policy => policy.property === 'useDiscountByWholesaleQuantity' && policy.policy_value === 'true');
  const usePaymentConditionByTotalPrice = useMemo(() => companyPolicies.find(policy => policy.property === 'usePaymentConditionByTotalPrice' && policy.policy_value === 'true'), [companyPolicies]);
  const useGrid = companyPolicies.findIndex(policy => policy.property === 'useGrid' && policy.policy_value === 'true') !== -1;
  const useLotsAndSubLots = useMemo(() => companyPolicies.find(policy => policy.property === 'useLotsAndSubLots' && policy.policy_value === 'true'), [companyPolicies]);
  const useAutoComplenteOnProductsFields = useMemo(() => companyPolicies.find(policy => policy.property === 'useAutoComplenteOnProductsFields' && policy.policy_value === 'true'), [companyPolicies]);
  const fieldsToAutoComplenteOnProductsFields = useMemo(() => {
    if (useAutoComplenteOnProductsFields) {
      return companyPolicies.find(policy => policy.policy === useAutoComplenteOnProductsFields.dependencie).policy_value.split(';').map(value => `${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${value.toUpperCase()}`)
    }

    return []
  }, [useAutoComplenteOnProductsFields, companyPolicies])
  const dispatch = useDispatch();
  const utils = new Utils();

  const mode: string = isEditing ? 'edit' : 'add';

  const {
    handleGetAdjustedPrice,
    handleAddNewProduct,
    handleOpenProductDetail,
    checkBalance,
    handleOpenGridModal,
    handleOpenConsultationModal
  } = useContext(CartContext);

  const { setUIComponentState } = useContext(UIContext);
  
  function updateProductListInputValues(index: number, fieldName: string, value: string) {
    const productListCopy = [...productList]

    if (productListCopy[index].find(field => field.nome === fieldName).visual !== "V") {
      dispatch(updateProductValueList({index: index, fieldName: fieldName, value: value}))
    }
  }

  const conversionCalc = (inputValue: number, index: number): number => {
    const codFieldName = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
    const productSelectedCod = productList[index].find(productField => productField.nome === codFieldName).conteudo
    const product = configuracoes.usedirectproductsear ? searchProductSelected : itemsList.find(item => item['b1_cod'] === productSelectedCod);

    const multiplier = product.b1_conv ? Number(product.b1_conv) : product.b1_qe ? Number(product.b1_qe) : 0;

    inputValue = useCheckBalance ? checkBalance(Number(product['b1_conv'] || product['b1_qe']), Number(product['b1_xsaldo']), inputValue) : inputValue;

    const calc = (inputValue % multiplier);
    if (calc !== 0 && !isNaN(calc)) {
      if (inputValue < multiplier) {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>(${multiplier})</b>. O valor foi alterado para o mínimo - <b>(${multiplier})</b>`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return multiplier;
      } else {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>${multiplier}</b>. O valor foi alterado para o mínimo - <b>(${inputValue -calc})</b>, que é o valor mais próximo abaixo do digitado, respeitando o valor de conversão!`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return inputValue - calc;
      }
    }

    return inputValue;
  }

  const validateBalance = (inputValue: number, index: number): number => {
    const product = configuracoes.usedirectproductsear ? searchProductSelected : itemsList[index];
    const balance = product.b1_xsaldo ? Number(product.b1_xsaldo) : null;

    if(!balance) {
      return inputValue;
    }

    if(inputValue > balance) {
      Swal.fire({
        title: 'Atenção!',
        text: `O valor máximo permitido para esse produto é ${balance} o valor do produto foi alterado para ${balance}.`,
        icon: 'warning',
        confirmButtonText: 'Ok',
      });

      return balance;
    }

    return inputValue;
  }
  
  function updateProductListValuesInputNumber(index: number, fieldName: string, value: string | number) {
    const useBonificationFields = companyPolicies.find(police => police.property === 'useBonificationFields' && police.policy_value === 'true');

    const productListCopy = [...productList]
    const productField = productListCopy[index].find(field => field.nome === fieldName)
    const productPrice = productListCopy[index].find(field => field.regra === 'prcunit') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'prcunit').conteudo)) : 0;
    const quantity = productListCopy[index].find(field => field.regra === 'quantidade') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'quantidade').conteudo)) : 0;
    const discount = productListCopy[index].find(field => field.regra === 'desconto') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'desconto').conteudo)) : 0;
    const increase = productListCopy[index].find(field => field.regra === 'acrescimo') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'acrescimo').conteudo)) : 0;
    
    if (productField.visual !== "V") {
      dispatch(updateProductValueList({index: index, fieldName: fieldName, value: value}))
    }

    if (fieldName === productListCopy[index].find(field => field.regra === 'quantidade').nome) {
      if (useDiscountByWholesaleQuantity) {
        if (productListCopy[index].find(field => field.regra === 'qtdatacado') && Number(value) >= Number(productListCopy[index].find(field => field.regra === 'qtdatacado').conteudo)) {
          const productPriceWholesale = productListCopy[index].find(field => field.regra === 'prcatacado') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'prcatacado').conteudo)) : 0;
          if (!productListCopy[index].find(field => field.regra === 'prcven')?.conteudo) dispatch(updateProductValueList({index: index, fieldName: productListCopy[index].find(field => field.regra === 'prcven').nome, value: productPriceWholesale }))
          dispatch(updateProductValueList({index: index, fieldName: productListCopy[index].find(field => field.regra === 'prcunit').nome, value: productPriceWholesale }))
          if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: increase > 0 ? Number(((Number(increase) / 100 * productPriceWholesale) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlracres')?.decimal) || 2)) : 0 }));
          if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? Number(((Number(discount) / 100 * productPriceWholesale) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlrdesc')?.decimal) || 2)) : 0 }));
        } else {
          if (!productListCopy[index].find(field => field.regra === 'prcven')?.conteudo) dispatch(updateProductValueList({index: index, fieldName: productListCopy[index].find(field => field.regra === 'prcven').nome, value: Number(itemsList[index].b1_prv1) }))
          dispatch(updateProductValueList({index: index, fieldName: productListCopy[index].find(field => field.regra === 'prcunit').nome, value: Number(itemsList[index].b1_prv1) }))
          if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: increase > 0 ? Number(((Number(increase) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlracres')?.decimal) || 2)) : 0 }));
          if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? Number(((Number(discount) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlrdesc')?.decimal) || 2)) : 0 }));
        }
      } else {
        if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: increase > 0 ? Number(((Number(increase) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlracres')?.decimal) || 2)) : 0 }));
        if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? Number(((Number(discount) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlrdesc')?.decimal) || 2)) : 0 }));
      }
    }

    if (productListCopy[index].find(field => field.regra === 'desconto') && fieldName === productListCopy[index].find(field => field.regra === 'desconto').nome && Number(value) !== Number(productListCopy[index].find(field => field.regra === 'desconto').conteudo)) {
      if (useDiscountValidationByClientField) {
        const maxDiscountAllowed = selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value.toLowerCase()] !== undefined ? Number(selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value.toLowerCase()]) : 0
        if (Number(value) > maxDiscountAllowed)  {
          Swal.fire({
            title: 'Atenção!',
            text: `Não é possível ter um valor maior que ${maxDiscountAllowed}% de desconto!`,
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
  
          value = maxDiscountAllowed
        }
      }

      if (useDiscountValidationByProductField) {
        // const product = configuracoes.usedirectproductsear ? searchProductSelected : itemsList[index];

        const discountField =  companyPolicies.find(policy => policy.policy === useDiscountValidationByProductField.dependencie).policy_value?.toLowerCase();
        const maxDiscountAllowed = discountField && itemsList[index][discountField] ? Number(itemsList[index][discountField]) : 0
        console.log(maxDiscountAllowed, discountField)
        if (Number(value) > maxDiscountAllowed)  {
          Swal.fire({
            title: 'Atenção!',
            text: `Não é possível ter um valor maior que ${maxDiscountAllowed}% de desconto!`,
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
  
          value = maxDiscountAllowed
        }
      }

      if(Number(value) >= 100) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não é possível ter um valor maior que 99% de desconto!',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });

        value = '99';
      }

      const adjustedPrice = Number((productPrice - (Number(value) / 100 * productPrice)).toFixed(Number(productListCopy[index].find(field => field.regra === 'prcven')?.decimal) || 2))

      dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: Number(value), increase: 0, adjustedPrice: adjustedPrice }));
      if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: 0 }));
      if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: Number(((Number(value) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlrdesc')?.decimal) || 2)) }));
    }
    
    if (productListCopy[index].find(field => field.regra === 'acrescimo') && fieldName === productListCopy[index].find(field => field.regra === 'acrescimo')?.nome  && Number(value) !== Number(productListCopy[index].find(field => field.regra === 'acrescimo').conteudo)) {
      const adjustedPrice = Number((productPrice + (Number(value) / 100 * productPrice)).toFixed(Number(productListCopy[index].find(field => field.regra === 'prcven')?.decimal) || 2))

      dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: 0, increase: Number(value), adjustedPrice: adjustedPrice }));
      if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: 0 }));
      if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: Number(((Number(value) / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlracres')?.decimal) || 2)) }));
    }

    const bonificationField = selectedMenu.nome === 'Pedidos' ? 'C6_XTOTBON' : 'CK_XTOTBON';
    if(useBonificationFields && fieldName.includes('_XQTBON') && productListCopy[index].find(field => field.nome === bonificationField)) {
      const bonificationTotal = parseFloat((Number(value) * Number(String(productListCopy[index].find(field => field.regra === 'prcunit').conteudo))).toFixed(Number(productListCopy[index].find(field => field.nome === bonificationField)?.decimal) || 2));
      dispatch(updateProductValueList({index: index, fieldName: bonificationField, value: bonificationTotal }));
    }
    
    dispatch(updateProductTotalPrice({ index: index }));
    if (usePaymentConditionByTotalPrice) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
      document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
    }
  }

  function validateProductListPriceChange(index: number, regra: string, value: number): number {
    const productListCopy = [...productList]

    const productPrice = productListCopy[index].find(field => field.regra === 'prcunit') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'prcunit').conteudo)) : 0;

    if (configuracoes.notallowvllowprctab && regra === 'prcven') {
      if(productPrice > value) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não é possível alterar o valor para uma valor abaixo da tabela de preço.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return productPrice
      }
    }

    return value
  }

  function updateProductListValuesInputMonetary(index: number, fieldName: string, value: string) {
    const productListCopy = [...productList]

    const productPrice = productListCopy[index].find(field => field.regra === 'prcunit') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'prcunit').conteudo)) : 0;
    const quantity = productListCopy[index].find(field => field.regra === 'quantidade') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'quantidade').conteudo)) : 0;
    const totValue = productPrice * quantity;
    if(productListCopy[index].find(field => field.nome === fieldName).conteudo === value || parseFloat(value) === 0) {
      dispatch(updateProductValueList({ index, fieldName, value }));
    } else if (productListCopy[index].find(field => field.regra === 'prcven') && fieldName === productListCopy[index].find(field => field.regra === 'prcven').nome) {
      if(productPrice > parseFloat(value)) {
        const desconto = Number((((productPrice - parseFloat(value)) / productPrice) * 100).toFixed(Number(productListCopy[index].find(field => field.regra === 'desconto')?.decimal) || 2));

        dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: desconto, increase: 0, adjustedPrice: parseFloat(value) }));
        if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: 0 }));
        if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: Number(((desconto / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlrdesc')?.decimal) || 2)) }));
      } else{
        const acrescimo = Number((((parseFloat(value) - productPrice) / productPrice) * 100).toFixed(Number(Number(productListCopy[index].find(field => field.regra === 'acrescimo')) || 2)));

        dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: 0, increase: acrescimo, adjustedPrice: parseFloat(value) }));
        if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: Number(((acrescimo / 100 * productPrice) * quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'vlracres')?.decimal) || 2)) }));
        if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: 0 }));
      }
    } else if(productListCopy[index].find(field => field.regra === 'vlrdesc') && fieldName === productListCopy[index].find(field => field.regra === 'vlrdesc').nome) {
      const valueWithDiscountByUnit = Number(((totValue - parseFloat(value)) / quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'prcven')?.decimal) || 2));
      const adjustedPrice = productPrice - valueWithDiscountByUnit;
      const desconto = Number(((adjustedPrice / productPrice) * 100).toFixed(Number(productListCopy[index].find(field => field.regra === 'desconto')?.decimal) || 2));

      dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: desconto, increase: 0, adjustedPrice: valueWithDiscountByUnit }));
      dispatch(updateProductValueList({ index, fieldName, value }));

      if (productListCopy[index].find(field => field.regra === 'vlracres')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlracres').nome, value: 0 }));
    } else if(productListCopy[index].find(field => field.regra === 'vlracres') && fieldName === productListCopy[index].find(field => field.regra === 'vlracres').nome) {
      const valueWithIncreaseByUnit = Number(((totValue + parseFloat(value)) / quantity).toFixed(Number(productListCopy[index].find(field => field.regra === 'prcven')?.decimal) || 2));
      const adjustedPrice = valueWithIncreaseByUnit - productPrice;
      const acrescimo = Number(((adjustedPrice / productPrice) * 100).toFixed(Number(productListCopy[index].find(field => field.regra === 'acrescimo')?.decimal) || 2));

      dispatch(updateProductDiscountIncreaseAdjustedPrice({ index, discount: 0, increase: acrescimo, adjustedPrice: valueWithIncreaseByUnit }));
      dispatch(updateProductValueList({ index, fieldName, value }));
      
      if (productListCopy[index].find(field => field.regra === 'vlrdesc')) dispatch(updateProductValueList({ index, fieldName: productListCopy[index].find(field => field.regra === 'vlrdesc').nome, value: 0 }));
    }else {
      dispatch(updateProductValueList({ index, fieldName, value }));
    }

    dispatch(updateProductTotalPrice({ index: index }));
    if (usePaymentConditionByTotalPrice) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
      document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
    }
  }

  function validateProductSelectedPriceChange(regra: string, value: number): number {
    const itemSelectedCopy = [...searchItemSelected]  

    const productPrice = itemSelectedCopy.find(field => field.regra === 'prcunit') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'prcunit').conteudo)) : 0;

    if (configuracoes.notallowvllowprctab && regra === 'prcven') {
      if(productPrice > value) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não é possível alterar o valor para uma valor abaixo da tabela de preço.',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return productPrice
      }
    }

    return value
  }

  function updateProductSelectedValuesInputNumber(fieldName: string, value: string | number, decimal: number) {

    const useBonificationFields = companyPolicies.find(police => police.property === 'useBonificationFields' && police.policy_value === 'true');

    const itemSelectedCopy = [...searchItemSelected]  
    const productPrice = itemSelectedCopy.find(field => field.regra === 'prcunit') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'prcunit').conteudo)) : 0;
    const quantity = itemSelectedCopy.find(field => field.regra === 'quantidade') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'quantidade').conteudo)) : 0;
    const discount = itemSelectedCopy.find(field => field.regra === 'desconto') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'desconto').conteudo)) : 0;
    const increase = itemSelectedCopy.find(field => field.regra === 'acrescimo') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'acrescimo').conteudo)) : 0;

    if (itemSelectedCopy.find(field => field.nome === fieldName) && itemSelectedCopy.find(field => field.nome === fieldName).visual !== "V") {
      dispatch(updateSearchItemSelectedValue({ fieldName, value: parseFloat(Number(value).toFixed(decimal)) }));
    }
    if (itemSelectedCopy.find(field => field.regra === 'quantidade') && fieldName === itemSelectedCopy.find(field => field.regra === 'quantidade').nome) {
      if (useDiscountByWholesaleQuantity) {
        if (itemSelectedCopy.find(field => field.regra === 'qtdatacado') && Number(value) >= Number(itemSelectedCopy.find(field => field.regra === 'qtdatacado').conteudo)) {
          const productPriceWholesale = itemSelectedCopy.find(field => field.regra === 'prcatacado') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'prcatacado').conteudo)) : 0;
          dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'prcven').nome, value: productPriceWholesale }));
          dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'prcunit').nome, value: productPriceWholesale }));
          if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: increase > 0 ? ((Number(increase) / 100 * productPriceWholesale) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlracres')?.decimal) || 2) : 0 }));
          if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? ((Number(discount) / 100 * productPriceWholesale) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlrdesc')?.decimal) || 2) : 0 }));
        } else {
          dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'prcven').nome, value: Number(searchProductSelected.b1_prv1) }));
          dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'prcunit').nome, value: searchProductSelected.b1_prv1 }));
          if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: increase > 0 ? ((Number(increase) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlracres')?.decimal) || 2) : 0 }));
          if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? ((Number(discount) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlrdesc')?.decimal) || 2) : 0 }));
        }
      } else {
        if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: increase > 0 ? ((Number(increase) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlracres')?.decimal) || 2) : 0 }));
        if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: discount > 0 ? ((Number(discount) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlrdesc')?.decimal) || 2) : 0 }));
      }
    }

    if (itemSelectedCopy.find(field => field.regra === 'desconto') && fieldName === itemSelectedCopy.find(field => field.regra === 'desconto').nome && Number(value) !== Number(itemSelectedCopy.find(field => field.regra === 'desconto').conteudo)) {
      if (useDiscountValidationByClientField) {

        const discountField = (companyPolicies
         .find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value);

        const fieldExists =  selectedClient[discountField.toLowerCase()]

        const maxDiscountAllowed = discountField && fieldExists ? selectedClient[discountField.toLowerCase()] : 0;

        if (Number(value) > maxDiscountAllowed)  {
          Swal.fire({
            title: 'Atenção!',
            text: `Não é possível ter um valor maior que ${maxDiscountAllowed}% de desconto!`,
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
  
          value = maxDiscountAllowed
        }
      }

      if (useDiscountValidationByProductField) {

        const discountField = (companyPolicies
         .find(policy => policy.policy === useDiscountValidationByProductField.dependencie).policy_value);

        const fieldExists =  searchProductSelected[discountField.toLowerCase()]

        const maxDiscountAllowed = discountField && fieldExists ? searchProductSelected[discountField.toLowerCase()] : 0;

        if (Number(value) > maxDiscountAllowed)  {
          Swal.fire({
            title: 'Atenção!',
            text: `Não é possível ter um valor maior que ${maxDiscountAllowed}% de desconto!`,
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
  
          value = maxDiscountAllowed
        }
      }
      
      if(Number(value) >= 100) {
        Swal.fire({
          title: 'Atenção!',
          text: 'Não é possível ter um valor maior que 99% de desconto!',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });

        value = '99';
      }
      
      const adjustedPrice = Number((productPrice - (Number(value) / 100 * productPrice)).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'prcven')?.decimal) || 2));
      dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({  discount: parseFloat(Number(value).toFixed(decimal)), increase: 0, adjustedPrice: adjustedPrice }));
      if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: 0 }));
      if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: Number(((Number(value) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlrdesc')?.decimal) || 2)) }));
    }

    if (itemSelectedCopy.find(field => field.regra === 'acrescimo') && fieldName === itemSelectedCopy.find(field => field.regra === 'acrescimo').nome && Number(value) !== Number(itemSelectedCopy.find(field => field.regra === 'acrescimo').conteudo)) {
      const adjustedPrice = Number((productPrice + (Number(value) / 100 * productPrice)).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'prcven')?.decimal) || 2))
      dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({ discount: 0, increase: parseFloat(Number(value).toFixed(decimal)), adjustedPrice: adjustedPrice }));
      if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: 0 }));
      if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: Number(((Number(value) / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlracres')?.decimal) || 2)) }));
    }

    const bonificationField = selectedMenu.nome === 'Pedidos' ? 'C6_XTOTBON' : 'CK_XTOTBON';
    if(useBonificationFields && fieldName.includes('_XQTBON') && itemSelectedCopy.find(field => field.nome === bonificationField)) {
      const bonificationTotal = parseFloat((Number(value) * productPrice).toFixed(Number(itemSelectedCopy.find(field => field.nome === bonificationField)?.decimal) || 2));

      dispatch(updateSearchItemSelectedValue({ fieldName: bonificationField, value: bonificationTotal }));
    }

    dispatch(updateSearchItemSelectedTotalPrice());
    if (usePaymentConditionByTotalPrice) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
      document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
    }
  }

  function updateProductSelectedValuesInputMonetary(fieldName: string, value: string) {

    const itemSelectedCopy = [...searchItemSelected]

    const productPrice = itemSelectedCopy.find(field => field.regra === 'prcunit') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'prcunit').conteudo)) : 0;
    const quantity = itemSelectedCopy.find(field => field.regra === 'quantidade') ? parseFloat(String(itemSelectedCopy.find(field => field.regra === 'quantidade').conteudo)) : 0;
    const totValue = productPrice * quantity;
    if(itemSelectedCopy.find(field => field.nome === fieldName).conteudo === value || parseFloat(value) === 0) {
      dispatch(updateSearchItemSelectedValue({ fieldName, value }));
    } else if (itemSelectedCopy.find(field => field.regra === 'prcven') && fieldName === itemSelectedCopy.find(field => field.regra === 'prcven').nome) {
      if(productPrice > parseFloat(value)) {
        const desconto = Number((((productPrice - parseFloat(value)) / productPrice) * 100).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'desconto')?.decimal) || 2));

        dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({ discount: desconto, increase: 0, adjustedPrice: parseFloat(value) }));

        if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: 0 }));
        if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: Number(((desconto / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlrdesc')?.decimal) || 2)) }));
      } else{
        const acrescimo = Number((((parseFloat(value) - productPrice) / productPrice) * 100).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'acrescimo')?.decimal) || 2));

        dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({ discount: 0, increase: acrescimo, adjustedPrice: parseFloat(value) }));
        if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: Number(((acrescimo / 100 * productPrice) * quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'vlracres')?.decimal) || 2)) }));
        if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: 0 }));
      }
    } else if(itemSelectedCopy.find(field => field.regra === 'vlrdesc') && fieldName === itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome) {
      const valueWithDiscountByUnit = Number(((totValue - parseFloat(value)) / quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'prcven')?.decimal) || 2));
      const adjustedPrice = productPrice - valueWithDiscountByUnit;
      const desconto = parseFloat(((adjustedPrice / productPrice) * 100).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'desconto')?.decimal) || 2));

      dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({ discount: desconto, increase: 0, adjustedPrice: valueWithDiscountByUnit }));
      dispatch(updateSearchItemSelectedValue({ fieldName, value }));

      if (itemSelectedCopy.find(field => field.regra === 'vlracres')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlracres').nome, value: 0 }));
    } else if(itemSelectedCopy.find(field => field.regra === 'vlracres') && fieldName === itemSelectedCopy.find(field => field.regra === 'vlracres').nome) {
      const valueWithIncreaseByUnit = Number(((totValue + parseFloat(value)) / quantity).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'prcven')?.decimal) || 2));
      const adjustedPrice = valueWithIncreaseByUnit - productPrice;
      const acrescimo = parseFloat(((adjustedPrice / productPrice) * 100).toFixed(Number(itemSelectedCopy.find(field => field.regra === 'acrescimo')?.decimal) || 2));

      dispatch(updateSearchItemSelectedDiscountIncreaseAdjustedPrice({ discount: 0, increase: acrescimo, adjustedPrice: valueWithIncreaseByUnit }));
      dispatch(updateSearchItemSelectedValue({ fieldName, value }));

      if (itemSelectedCopy.find(field => field.regra === 'vlrdesc')) dispatch(updateSearchItemSelectedValue({ fieldName: itemSelectedCopy.find(field => field.regra === 'vlrdesc').nome, value: 0 }));
    }else {
      dispatch(updateSearchItemSelectedValue({ fieldName, value }));
    }

    dispatch(updateSearchItemSelectedTotalPrice());
    if (usePaymentConditionByTotalPrice) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
      document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
    }
  }

  function onTrigger(f3SelectedValue: INewF3Data, fieldKey: string, trigger: string[], idx: number) {
    const fieldIndex = itemFields.findIndex(field => field.nome === fieldKey) + 1
    trigger.shift();

    if(trigger.length > 0) {
      trigger.map((f3Key, index) => {
        const fieldTrigger = itemFields[fieldIndex + index]
        updateProductListInputValues(idx, fieldTrigger.nome, String(f3SelectedValue[f3Key]));
      })
    }
  }

  const getProductsSearch = async (page: number, filters?: string | undefined,  statusFilter?: { [key: string]: string } | undefined) => {
    try {
      dispatch(updateTableLoading({ value: true }));
      dispatch(updateSearchOptionsPage({ value: page }));

      const { data, status, request } = await productService.index(token, 10, page, selectedClient.indice.toString(), currentBranch.codigo, '', '', useGrid, filters,  statusFilter, priceTable);
      const { dados,  dicionary, hasNext }: IPaginatedServiceResponse = data;
  
      if (status === 200) {
        if(page !== 1) {
          dispatch(updateSearchOptions({ value: [...new Set([...searchOptions , ...dados.filter(data => !searchOptions.includes(data))])] }));
        }else {
          dispatch(updateSearchOptions({ value: dados }));

          dispatch(updateDictionaryC6({ value: {
            ...dicionary.SC6,
            ...dictionaryEXC6
          } }))
          dispatch(updateDictionaryCK({ value: {
            ...dicionary.SCK,
            ...dictionaryEXCK
          } }))
        }

        dispatch(updateSearchOptionsHasMore({ value: hasNext }));

      } else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }))
    }
  }

  const getProductsWithTextSearch = useCallback(debounce(async (searchValue: string) => {
    // Load
    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService([{type: "text", name: "B1_COD"}, {type: "text", name: "B1_DESC"}], ["B1_COD", "B1_DESC"], "contains", searchValueText);
      dispatch(updateSearchOptionsFilter({ value: searchString }));
      await getProductsSearch(1, searchString);
    }
    else {
      dispatch(updateSearchOptionsFilter({ value: '' }));
      await getProductsSearch(1);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), []);

  function handleSelectProduct(product: TableData) {
    dispatch(updateSearchProductSelected({ value: product }));
    const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
    const itemField = selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM';
    const item = itemFields.map(field => {
      if(field.nome === itemField) {
        return {
          ...field,
          conteudo: '&&'
        }
      }
      return {
        ...field,
        conteudo: product[dictionary[field.nome]] ? product[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''
      }
    });

    dispatch(updateSearchItemSelected({ value: item }));
    dispatch(updateSearchOptions({ value: [] }));
  }

  function handleAutoCompleteAllProducts(fieldName: string, value: string | number) {
    Swal.fire({
      icon: 'info',
      title: 'Preenchimento automático',      
      text: `Deseja fazer o preenchimento dos demais produtos com o mesmo valor`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#21ba45',
      cancelButtonColor: '#e0e1e2',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if(result.isConfirmed) {
        dispatch(autoCompleteAllFieldsValue({ fieldName, value }))
      }
    })
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth - (window.innerWidth * 6 / 100))
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };  
  }, [])
  
  useEffect(() => {
    productList.forEach((product, index) => {
      product.map(({ titulo, nome, regra, obrigado, placeholder, tipo, type, options, f3, f3Post, conteudo, visual }) => {
        if (type === 'currency') {
          if (regra === 'prcven') {
            handleGetAdjustedPrice(index)
          }
        }
      })
    })
  }, [])

  return (
    <TableContainer>
      <Table style={{ width: '100%', tableLayout: 'auto', marginBottom: '20px' }} compact={true} size="large">
        <Table.Header>
          <Table.Row>
            {
              itemFields.map(field => {
                let isValid = true
                isValid = utils.validationFormField(field.tipo, mode);
                isValid = configuracoes.usecustomerlevel && field.nivel ? customerLevel >= Number(field.nivel) : isValid

                if(isValid) {
                  return (
                    <Table.HeaderCell><p>{customizations['useCapitalLetters'] ? field.titulo.toUpperCase() : field.titulo} <span style={{ color: '#db2828' }}>{field.obrigado === 'S' ? '*' : ''}</span></p></Table.HeaderCell>
                  )
                }
              })
            }
            <Table.HeaderCell className='dataHeaderTableFixedActions'>
              { customizations['useCapitalLetters'] ? 'AÇÕES' : 'Ações' }
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          { 
            configuracoes.usedirectproductsear ? (
              <Table.Row>
                  {
                    configuracoes.usedirectproductsear ? (
                      searchItemSelected.map(({ titulo, nome, obrigado, placeholder, tipo, decimal = "2", type, options, regra, f3, f3Post, picture, conteudo, visual, browse, len, fieldOccupationSize, nivel }, index) => {
                        let isValid = true
                        isValid = utils.validationFormField(tipo, mode);
                        isValid = configuracoes.usecustomerlevel && nivel ? customerLevel >= Number(nivel) : isValid

                        if(isValid) {
                          return (
                            <Table.Cell 
                              // onClick={() => {
                              //   if(nome.includes('_XIMG')) {
                              //     handleOpenProductDetail(conteudo ? String(conteudo) : defaultProductImg,  '')
                              //   }
                              // }}
                            >
                              <Form.Field key={`${index}-${nome}`} required={obrigado === 'S'} style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? `${screenWidth * fieldOccupationSize / 100}px` : `32%` }}>
                                {
                                  nome.includes('_XIMG') &&
                                    <Image
                                      size="mini"
                                      src={conteudo || defaultProductImg}
                                      
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = defaultProductImg;
                                      }}
                                    />
                                }
                                {
                                  !nome.includes('_XIMG') && type === 'character' && !f3 && !nome.includes('_DESCRI') &&
                                  <Input
                                    style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem' }}
                                    placeholder={placeholder}
                                    onFocus={e => e.target.select()}
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    onChange={
                                      (ev, { value }) => { dispatch(updateSearchItemSelectedValue({ fieldName: nome, value: utils.pictureValidate(picture, value) })) }
                                    }
                                    value={conteudo}
                                  />
                                }           
                                {
                                  type === 'character' && !f3 && nome.includes('_DESCRI') && useSeparateProductInputSearch &&
                                  <Input
                                    style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '30rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    onFocus={e => e.target.select()}
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    onChange={
                                      (ev, { value }) => { dispatch(updateSearchItemSelectedValue({ fieldName: nome, value })) }
                                    }
                                    value={conteudo}
                                  />
                                }
                                {
                                  type === 'character' && !f3 && nome.includes('_DESCRI') && !useSeparateProductInputSearch &&
                                  <div style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '30rem', height: '4rem'  }}>
                                    <InputSearch
                                      onSearch={(value, noDebounce) => { 
                                        getProductsWithTextSearch(noDebounce ? 'enter' : 'change', value); 
                                      }}
                                      nextPage={async () => { getProductsSearch(searchOptionsPage + 1, searchOptionsFilter)}}
                                      onSelect={(product) => handleSelectProduct(product)}
                                    />
                                  </div>
                                }
                                {
                                  type === 'number' &&
                                  <InputNumber
                                    style={{  width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    type="number"
                                    onFocus={e => e.target.select()}
                                    onWheelCapture={e => {
                                      e.target.blur()
                                    }}
                                    min="0"
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    onChange={
                                      (ev, { value }) => {
                                        if(regra === 'quantidade') {
                                          if(!useDecimalProductQuantity && !Number.isInteger(value)) {                                          
                                            value = Math.round(value);
                                          }
                                        }
                                        updateProductSelectedValuesInputNumber(nome, value, parseFloat(decimal))
                                      }
                                    }
                                    onBlur={(ev) => {
                                      if(regra === 'quantidade') {
                                        if (useDiscountByWholesaleQuantity) {
                                          let value = ev.target.value
                                          const itemSelectedCopy = [...searchItemSelected]  
                                          if (Number(value) > Number(itemSelectedCopy.find(field => field.regra === 'qtdatacado').conteudo)) {
                                            const multiplier = Number(itemSelectedCopy.find(field => field.regra === 'qtdatacado').conteudo)
                                            const calc = (Number(value)  % multiplier);
                                            if (calc !== 0 && !isNaN(calc)) {
                                              if (Number(value) < multiplier) {
                                                Swal.fire({
                                                  title: 'Atenção!',
                                                  html: `O valor de conversão é <b>(${multiplier})</b>. O valor foi alterado para o mínimo - <b>(${multiplier})</b>`,
                                                  icon: 'warning',
                                                  confirmButtonText: 'Ok',
                                                });
                                                value = multiplier;
                                              } else {
                                                Swal.fire({
                                                  title: 'Atenção!',
                                                  html: `O valor de conversão é <b>${multiplier}</b>. O valor foi alterado para o mínimo - <b>(${Number(value)  -calc})</b>, que é o valor mais próximo abaixo do digitado, respeitando o valor de conversão!`,
                                                  icon: 'warning',
                                                  confirmButtonText: 'Ok',
                                                });
                                                value = Number(value)  - calc;
                                              }
                                            }
                                          }
                                          updateProductSelectedValuesInputNumber(nome, conversionCalc(value, 1), parseFloat(decimal))
                                        } else {
                                          updateProductSelectedValuesInputNumber(nome, conversionCalc(Number(ev.target.value), 1), parseFloat(decimal))
                                        }
                                      }else{
                                        updateProductSelectedValuesInputNumber(nome, Number(ev.target.value), parseFloat(decimal))
                                      }
                                    }}
                                    value={conteudo}
                                    maxLength={len}
                                  />
                                }
                                {
                                  type === 'percent' &&
                                  <InputNumber
                                    style={{  width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '11rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    onFocus={e => e.target.select()}
                                    type="number"
                                    onWheelCapture={e => {
                                      e.target.blur()
                                    }}
                                    icon="percent"
                                    min="0"
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    onChange={
                                      (ev, { value }) => {
                                        updateProductSelectedValuesInputNumber(nome, value, parseFloat(decimal))
                                      }
                                    }
                                    value={conteudo}
                                    maxLength={len}
                                  />
                                }
                                {
                                  type === 'date' &&
                                  <Input
                                    style={{  width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    type="date"
                                    onChange={
                                      (ev, { value }) => { dispatch(updateSearchItemSelectedValue({ fieldName: nome, value })) }
                                    }
                                    defaultValue={utils.formatDateDefault(conteudo.toString())}
                                    value={utils.formatDateDefault(conteudo.toString())}
                                  />
                                }
                                {
                                  type === 'time' &&
                                  <Input
                                    style={{  width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    type='time'
                                    onChange={
                                      (ev, { value }) => { dispatch(updateSearchItemSelectedValue({ fieldName: nome, value })) }
                                    }
                                    value={conteudo}
                                  />
                                }


                                {
                                  type === 'currency' &&
                                  <InputNumber
                                    style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '11rem', height: '4rem'  }}
                                    placeholder={placeholder}
                                    type="text"
                                    onFocus={e => e.target.select()}
                                    onWheelCapture={e => {
                                      e.target.blur()
                                    }}
                                    min="0"
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    onChange={
                                      (ev, { value }) => {
                                        updateProductSelectedValuesInputMonetary(nome, String(utils.unmaskBRLCurrency(value, decimal)))
                                      }
                                    }
                                    onBlur={
                                      (ev) => {
                                        updateProductSelectedValuesInputMonetary(nome, String(validateProductSelectedPriceChange(regra, utils.unmaskBRLCurrency(ev.target.value, decimal))))
                                      }
                                    }
                                    value={utils.inputMaskBRLCurrency(Number(conteudo), decimal)}
                                    maxLength={len}
                                  />
                                }
                                {
                                  type === 'combo' &&
                                  <Dropdown
                                    search
                                    selection
                                    placeholder={placeholder}
                                    readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                    disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                    style={ { width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' } }
                                    options={options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } })}
                                    onChange={
                                      (ev, { value }) => { dispatch(updateSearchItemSelectedValue({ fieldName: nome, value: String(value) })) }
                                    }
                                    value={conteudo}
                                  />
                                }

                                {
                                  type === 'character' && !!f3 &&
                                  <F3
                                    clientId={null}
                                    token={token}
                                    consulta={f3Post.consulta}
                                    conteudo={conteudo || f3Post.conteudo}
                                    email={f3Post.email}
                                    requisito={f3Post.requisito}
                                    requisitocont={f3Post.requisitocont}
                                    readonly={mode === 'view'}
                                    placeholder={placeholder}
                                    label={titulo}
                                    name={nome}
                                    onChange={(f3SelectReturn, trigger) =>  dispatch(updateSearchItemSelectedValue({ fieldName: nome, value: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo })) }
                                    onChangeNewF3={async (f3SelectReturn, trigger) => {
                                      dispatch(updateSearchItemSelectedValue({ fieldName: nome, value: String(f3SelectReturn[trigger[0]]) }))
                                      onTrigger(f3SelectReturn, nome, trigger, 1)
                                    }}
                                  />
                                }
                              </Form.Field>
                            </Table.Cell>
                          )
                        }
                      })
                    ) : null
                  }
                  
                  {
                    configuracoes.usedirectproductsear ? (
                      <Table.Cell className='dataRestfieldTableFixed' width={1}>
                        <Button.Group size='small'>
                          {
                            useLotsAndSubLots && (
                              <Popup
                                trigger={
                                  <Dropdown
                                    icon={null}
                                    trigger={
                                      <ButtonTable 
                                        color="#4AAF9A"
                                      >
                                        <SquaresFour size={20} color="#4AAF9A" />
                                      </ButtonTable>
                                    }
                                  >
                                    
                                    <Dropdown.Menu style={{ position: 'static', zIndex: 999999 }}>
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (searchProductSelected.indice) {
                                            handleOpenConsultationModal(searchProductSelected, 'Lotes')
                                          } else {
                                            Swal.fire({
                                              title: 'Atenção!',
                                              html: `Selecione um produto antes!`,
                                              icon: 'warning',
                                              confirmButtonText: 'Ok',
                                            });
                                          }
                                        }}
                                      >
                                        Lotes
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (searchProductSelected.indice) {
                                            handleOpenConsultationModal(searchProductSelected, 'Saldo')
                                          } else {
                                            Swal.fire({
                                              title: 'Atenção!',
                                              html: `Selecione um produto antes!`,
                                              icon: 'warning',
                                              confirmButtonText: 'Ok',
                                            });
                                          }
                                        }}
                                      >
                                        Saldo
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                }
                                content={<span style={{ fontSize: '1.3rem' }}>Lotes</span>}
                                inverted
                              />
                            )
                          }
                          <Popup
                            trigger={
                              <ButtonTable 
                                color="#4AAF9A"
                                onClick={handleAddNewProduct}
                              >
                                <Plus size={20} color="#4AAF9A" />
                              </ButtonTable>
                            }
                            content={<span style={{ fontSize: '1.3rem' }}>Adicionar</span>}
                            inverted
                          />
                        </Button.Group>
                      </Table.Cell>
                    ) : null
                  }
              </Table.Row>
            ) : null
          }
          {
            productList.map((product, index) => (
              <Table.Row className="tableProductRow">
                {
                  product.map(({ titulo, nome, obrigado, placeholder, tipo, type, decimal = "2", options, f3, regra, f3Post, conteudo, picture, visual, browse, len, fieldOccupationSize, nivel }) => {
                    let isValid = true
                    isValid = utils.validationFormField(tipo, mode);
                    isValid = configuracoes.usecustomerlevel && nivel ? customerLevel >= Number(nivel) : isValid

                    if(isValid) {
                      return (
                        <Table.Cell  
                          onClick={() => {
                            if(nome.includes('_XIMG')) {
                              handleOpenProductDetail(conteudo ? String(conteudo) : defaultProductImg, product.find(field => field.nome === Object.keys(dictionary).find(key => dictionary[key] === 'b1_cod')).conteudo || '')
                            }
                          }}
                        >
                          <Form.Field key={`${index}-${nome}`} required={obrigado === 'S'} style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? `${screenWidth * fieldOccupationSize / 100}px` : `32%` }}>
                            {
                              nome.includes('_XIMG') &&
                                <Image
                                  size="mini"
                                  src={conteudo || defaultProductImg}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = defaultProductImg;
                                  }}
                                />
                            }
                            {
                              !nome.includes('_XIMG') && type === 'character' && !f3 &&
                              <Input
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : nome.includes('_DESCRI') ? '30rem' : '14rem', height: '4rem' }}
                                placeholder={placeholder}
                                onFocus={e => e.target.select()}
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}                                                           
                                onChange={
                                  (ev, { value }) => { updateProductListInputValues(index, nome, utils.pictureValidate(picture, value)) }
                                }
                                onBlur={(ev) => {
                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, ev.target.value)
                                  }
                                }}
                                value={conteudo}
                              />
                            }
                            {
                              type === 'number' &&
                              <Input
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '9rem', height: '4rem' }}
                                placeholder={placeholder}
                                type="number"
                                onFocus={e => e.target.select()}
                                onWheelCapture={e => {
                                  e.target.blur()
                                }}
                                min="0"
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                onChange={
                                  (ev, { value }) => {
                                    let formattedValue: string | number = value;
                                    if(regra === 'quantidade') {
                                      if(!useDecimalProductQuantity && !Number.isInteger(value)) {
                                        formattedValue = Math.round(parseFloat(value));
                                      }
                                    }
                                    updateProductListValuesInputNumber(index, nome, formattedValue)
                                  }
                                }
                                onBlur={(ev) => {
                                  if(regra === 'quantidade') {
                                    if (useDiscountByWholesaleQuantity) {
                                      const productListCopy = [...productList]
                                      let value = ev.target.value
                                      if (Number(value) > Number(productListCopy[index].find(field => field.regra === 'qtdatacado').conteudo)) {
                                        const multiplier = Number(productListCopy[index].find(field => field.regra === 'qtdatacado').conteudo)
                                        const calc = (Number(value)  % multiplier);
                                        if (calc !== 0 && !isNaN(calc)) {
                                          if (Number(value)  < multiplier) {
                                            Swal.fire({
                                              title: 'Atenção!',
                                              html: `O valor de conversão é <b>(${multiplier})</b>. O valor foi alterado para o mínimo - <b>(${multiplier})</b>`,
                                              icon: 'warning',
                                              confirmButtonText: 'Ok',
                                            });
                                            value = multiplier;
                                          } else {
                                            Swal.fire({
                                              title: 'Atenção!',
                                              html: `O valor de conversão é <b>${multiplier}</b>. O valor foi alterado para o mínimo - <b>(${Number(value)  -calc})</b>, que é o valor mais próximo abaixo do digitado, respeitando o valor de conversão!`,
                                              icon: 'warning',
                                              confirmButtonText: 'Ok',
                                            });
                                            value = Number(value) - calc;
                                          }
                                        }
                                      }
                                      
                                      updateProductListValuesInputNumber(index, nome, conversionCalc(Number(value), index))
                                      if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                        handleAutoCompleteAllProducts(nome, conversionCalc(Number(value), index))
                                      }
                                    }else {
                                      updateProductListValuesInputNumber(index, nome, conversionCalc(Number(ev.target.value), index))
                                    }
                                  }else {
                                    updateProductListValuesInputNumber(index, nome, Number(ev.target.value))
                                  }

                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, ev.target.value)
                                  }
                                }}
                                value={conteudo}
                                maxLength={len}
                              />
                            }
                            {
                              type === 'percent' &&
                              <Input
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '11rem', height: '4rem' }}
                                placeholder={placeholder}
                                type="number"
                                onFocus={e => e.target.select()}
                                onWheelCapture={e => {
                                  e.target.blur()
                                }}
                                icon="percent"
                                min="0"
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                onChange={
                                  (ev, { value }) => {
                                    updateProductListValuesInputNumber(index, nome, Number(value))
                                  }
                                }
                                onBlur={(ev) => {
                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, ev.target.value)
                                  }
                                }}
                                value={conteudo}
                                maxLength={len}
                              />
                            }
                            {
                              type === 'currency' &&
                                <Input
                                  style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '11rem', height: '4rem' }}
                                  placeholder={placeholder}
                                  type="text"
                                  onFocus={e => e.target.select()}
                                  onWheelCapture={e => {
                                    e.target.blur()
                                  }}
                                  min="0"
                                  readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                  disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                  onChange={
                                    (ev, { value }) => {
                                      updateProductListValuesInputMonetary(index, nome, String(utils.unmaskBRLCurrency(value, decimal)))
                                    }
                                  }
                                  onBlur={
                                    (ev) => {
                                      updateProductListValuesInputMonetary(index, nome, String(validateProductListPriceChange(index, regra, utils.unmaskBRLCurrency(ev.target.value, decimal))))
                                      if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                        handleAutoCompleteAllProducts(nome, String(validateProductListPriceChange(index, regra, utils.unmaskBRLCurrency(ev.target.value, decimal))))
                                      }
                                    }
                                  }
                                  value={utils.inputMaskBRLCurrency(Number(conteudo), decimal)}
                                  maxLength={len}
                                />
                            }
                            {
                              type === 'date' &&
                              <Input
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem' }}
                                placeholder={placeholder}
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                type="date"
                                onChange={
                                  (ev, { value }) => { 
                                    updateProductListInputValues(index, nome, value) 
                                    if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(nome)) {
                                      handleAutoCompleteAllProducts(nome, ev.target.value)
                                    }
                                  }
                                }
                                defaultValue={utils.formatDateDefault(conteudo.toString())}
                                value={utils.formatDateDefault(conteudo.toString())}
                              />
                            }
                            {
                              type === 'combo' &&
                              <Dropdown
                                search
                                selection
                                placeholder={placeholder}
                                readOnly={utils.isFieldDisabled(mode, visual, tipo)}
                                disabled={utils.isFieldDisabled(mode, visual, tipo)}
                                style={{ width: configuracoes.useoccupationsize && fieldOccupationSize ? '100%' : '14rem', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem' }}
                                options={options.map(({ nome, conteudo }) => { return { key: conteudo, value: conteudo, text: nome } })}
                                onChange={
                                  (ev, { value }) => { 
                                    updateProductListInputValues(index, nome, String(value)) 
                                  }
                                }
                                onBlur={(ev, { value }) => {
                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, String(value))
                                  }
                                }}
                                value={conteudo}
                              />
                            }
                            {
                              type === 'character' && !!f3 &&
                              <F3
                                clientId={null}
                                token={token}
                                consulta={f3Post.consulta}
                                conteudo={conteudo || f3Post.conteudo}
                                email={f3Post.email}
                                requisito={f3Post.requisito}
                                requisitocont={f3Post.requisitocont}
                                readonly={mode === 'view'}
                                placeholder={placeholder}
                                label={titulo}
                                name={nome}
                                onChange={(f3SelectReturn, trigger) => { 
                                  dispatch(updateProductValueList({index: index, fieldName: nome, value: f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo })) 

                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, f3SelectReturn.find(({ nome }) => nome === trigger[0][0]).conteudo)
                                  }
                                }}
                                onChangeNewF3={async (f3SelectReturn, trigger) => {
                                  dispatch(updateProductValueList({index: index, fieldName: nome, value: String(f3SelectReturn[trigger[0]]) }))
                                  onTrigger(f3SelectReturn, nome, trigger, index)

                                  if (useAutoComplenteOnProductsFields && fieldsToAutoComplenteOnProductsFields.includes(`${selectedMenu.nome === 'Pedidos' ? 'C6_' : 'CK_'}${nome}`)) {
                                    handleAutoCompleteAllProducts(nome, String(f3SelectReturn[trigger[0]]))
                                  }
                                }}
                              />
                            }
                          </Form.Field>
                      </Table.Cell>
                    )
                  }
                  })
                }
                <Table.Cell className='dataRestfieldTableFixed'>
                  <Button.Group size='small'>
                    {
                      useGrid ? (
                        <Popup
                          trigger={
                            <ButtonTable 
                              color="#4AAF9A"
                              onClick={() => { 
                                handleOpenGridModal(gridList[index]) 
                              } }
                            >
                              <SquaresFour size={20} color="#4AAF9A" />
                            </ButtonTable>
                          }
                          content={<span style={{ fontSize: '1.3rem' }}>Grade</span>}
                          inverted
                        />
                      ) : null
                    }
                    {
                      useLotsAndSubLots ? (
                        <Dropdown
                          icon={null}
                          trigger={
                            <ButtonTable 
                              color="#4AAF9A"
                            >
                              <SquaresFour size={20} color="#4AAF9A" />
                            </ButtonTable>
                          }
                        >
                          <Dropdown.Menu style={{ position: 'static', zIndex: 999999 }}>
                            <Dropdown.Item 
                              onClick={() => handleOpenConsultationModal({...itemsList[index], index: index }, 'Lotes')}
                            >
                              Lotes
                            </Dropdown.Item>
                            <Dropdown.Item 
                              onClick={() => handleOpenConsultationModal({...itemsList[index], index: index }, 'Saldo')}
                            >
                              Saldo
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null
                    }
                    
                    <Popup
                      trigger={
                        <ButtonTable 
                          color="#E47261"
                          onClick={() => {
                            if (useGrid) {
                              dispatch(deleteGrid({ index, type: selectedMenu.nome === 'Pedidos' ? 'order' : 'budget' }));
                            } else if (useLotsAndSubLots) {
                              dispatch(deleteItem({ index, type: selectedMenu.nome === 'Pedidos' ? 'order' : 'budget' }));
                            } else {
                              dispatch(deleteItem({ index, type: selectedMenu.nome === 'Pedidos' ? 'order' : 'budget' }));
                            }

                            if (usePaymentConditionByTotalPrice) {
                              dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
                              document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
                            }
                          }}
                        >
                          <Trash size={20} color="#E47261" />
                        </ButtonTable>
                      }
                      content={<span style={{ fontSize: '1.3rem' }}>Deletar</span>}
                      inverted
                    />
                    
                  </Button.Group>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>

      </Table >
    </TableContainer>
  )
}

export default DatatableRestField 