import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont};// ${styleSheet.mainFont};
  font-size: 2rem;
  display: inline-block;
  text-align: left;
`;

export const Mandatory = styled.span`
  color: ${({ theme }) => theme.dangerColor}; //${styleSheet.dangerColor};
  font-size: 2rem;
`;


export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
`;

export const TableColumnText = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;

export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;


