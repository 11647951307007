import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Person } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { SalesPersonService } from '../../services/methods/SalesPersonService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import AttachModal from '../../components/AttachModal';
import { IErrorProps } from '../../components/Error';
import Datatable from '../../components/Datatable';
import FormModal from '../../components/FormModal';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { DataTableReducerInitialState, loadTableData, updateDataTableIndice, updateMode, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { clearAll, fillFieldAndFieldValues } from '../../reducers/RestField';

import { BrowserContent, Content, PageDefault } from './styles';
import { debounce } from "../../common/debounce";

const SalesPerson: FC = () => {

  const salesPersonService: SalesPersonService = new SalesPersonService();
  const fieldService: FieldService = new FieldService();

  const { tableCurrentPage, tableFieldTypeConfig, tableSearchText, tableFieldFilters, tableFilter, indice, tableOrderBy } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const [formFieldState, setFormFieldState] = useState(false);
  const [fieldLoad, setFieldLoad] = useState(false);
  const [attachModalOpen, setAttachModalOpen] = useState(false)
  
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [filterModal, setFilterModal] = useState(false);
  
  const utils = new Utils();

  async function getSalesPersonList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));
      const response = await salesPersonService.index('', currentBranch.codigo, page, itensPerPage, token, order, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getSalesPeopleWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getSalesPersonList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));
      await getSalesPersonList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function filterSalesPerson(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }));
    await getSalesPersonList(1, tableOrderBy, filter, statusFilter)
  }

  async function orderSalesPerson(order: string) {
    dispatch(updateTableOrderBy({ value: order }));
    await getSalesPersonList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  async function getSalesPersonFields(indice?: string | number) {
    try {
      setFieldLoad(true);
  
      const body: IRestFieldRequestData = {
        alias: 'SA3',
        alias2: '',
        url: '',
        tab: '1',
        usuario: '',
        indice: indice ? String(indice) : '',
        ordem: 1,
        token: token,
      }
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        dispatch(fillFieldAndFieldValues({fields: data.campos}))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setFieldLoad(false)
    }
  }

  async function onNewSalesPersonClick() {
    setFormFieldState(true);
    await getSalesPersonFields();
  }

  async function onEditSalesPersonClick(indice: string | number) {
    setFormFieldState(true);
    await getSalesPersonFields(indice);
  }

  async function onViewSalesPersonClick(indice: string | number) {
    setFormFieldState(true);
    await getSalesPersonFields(indice);
  }

  async function onAttachSalePersonClick() {
    setAttachModalOpen(true)
  }

  useEffect(() => {
    getSalesPersonList(tableCurrentPage, tableOrderBy)
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<Person size={24} color={styleSheet.mainColor} />}>

          <BrowserContent>
            <Datatable
              serviceName="wsvendedor"
              onItensPerPageChange={() => getSalesPersonList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onOrderBy={(value) => orderSalesPerson(value)}
              onSearch={ (value, noDebounce) => getSalesPeopleWithTextSearch(noDebounce ? 'enter' : '', value) }
              onPageChange={(page) => getSalesPersonList(page, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterSalesPerson(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => {
                dispatch(updateDataTableIndice({ value: indice }));
                if (action !== 'upload') dispatch(updateMode({ value: action }));
                if (action === 'filter') setFilterModal(true);
                if (action === 'edit') onEditSalesPersonClick(indice);
                if (action === 'view') onViewSalesPersonClick(indice);
                if (action === 'add') onNewSalesPersonClick();
                if (action === 'upload') onAttachSalePersonClick()
              }}
            />
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

      <FormModal
        visible={formFieldState}
        fieldIsLoading={fieldLoad}
        onClose={(reload) => {
          if(reload) { 
            getSalesPersonList(tableCurrentPage, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)
          }
          setFormFieldState(false)
          dispatch(clearAll())
        }}
      />

      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterSalesPerson}
        onClose={() => {
          setFilterModal(false)
        }}
      />

      <AttachModal
        indice={indice}
        open={attachModalOpen}
        onClose={() => {
          setAttachModalOpen(false)
          dispatch(updateDataTableIndice({ value: '' }));
        }}
      />
    </PageDefault>
  );
}

export default SalesPerson;