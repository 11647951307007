import { Button, Checkbox, Form, Input, Modal, Header, Label, Icon, Select } from 'semantic-ui-react';
import { Key, Check, Info, User, Eye } from 'phosphor-react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { decode, encode } from 'base-64';
import Swal from 'sweetalert2';

import packageJson from '../../../package.json';

import { AuthService } from '../../services/methods/AuthService';
import { LogsService } from '../../services/methods/LogsService';

import { EnvironmentContext } from '../../contexts/Environment';

import { UIContext } from '../../contexts/UIContext';

import ForgetPasswordModal from '../../components/ForgetPasswordModal';
import { IErrorProps } from '../../components/Error';
import LgpdLogin from '../../components/LgpdLogin';

import { IAuthServiceResponse, IHelperTokenResponse } from '../../interfaces/Auth/IAuthServiceResponse';

import { signIn, updateBearerAccessToken, updateBearerRefreshToken } from '../../reducers/Auth';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { EnvironmentReducerInitialState, updateCurrentBranch, updateCurrentCompany, updateItensPerPage } from '../../reducers/Environment';

import videoBackground from '../../assets/videos/video_background.mp4';
import { styleSheet } from '../../assets/styles/global';

import { PageDefault, InputLabel, Logotipo, LogoContainer, LoginBox, BackgrondFilter, LinkContainer, CheckboxContainer, Footer, MobileViewContainer, MobileViewTitle, MobileViewDescription, MobileContainerButtons, LoginBoxMobile } from './styles';
import { loadMenus, updateMenuLoading } from '../../reducers/Menu';
import { IMenuItem } from '../../interfaces/Menu/IMenu';
import { HomeService } from '../../services/methods/HomeService';
import { ICompaniesResponse } from '../../interfaces/Auth/ICompaniesResponse';
import SupportModal from '../../components/SupportModal';
import { toast } from 'react-toastify';
import { bucketURL } from '../../common/Constants';

enum Menus {
  home = 'home',
  empresas = 'clients',
  pendencias = 'approvals',
  usuarios = 'users',
  pedidos = 'orders',
  orcamentos = 'budgets',
  financeiro = 'financial',
  dashboard = 'dashboard',
  vendedor = 'salesperson',
  dashboard2 = 'dashboard',
  agendas = 'schedules',
  logs = 'logs',
  projetos = 'projects',
  fechamento = 'closure'
}

interface ICompanyProps {
  descricao: string;
  codigo: string;
}

const Login: FC = () => {
  const authService: AuthService = new AuthService();
  const logsService: LogsService = new LogsService();
  const homeService: HomeService = new HomeService();

	const { companyPolicies, customizations, canLogin, companyName, companyEnvironment, companyStatus, backgroundType, backgroundColor, companyRest } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  
  const textExternalLinkOnLoginScreen = companyPolicies.find(police => police.property === 'textExternalLinkOnLoginScreen' && police.policy_value !== '');
  const urlExternalLinkOnLoginScreen = companyPolicies.find(police => police.property === 'urlExternalLinkOnLoginScreen' && police.policy_value !== '');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [v3Modal, setv3Modal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [renderToast, setRenderToast] = useState(false);
  const [modalForgetPassword, setModalForgetPassword] = useState(false);
  const [ modalSupport, setModalSupport ] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfuirmNewPassword] = useState('');
  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);
  const [ visiblePassword, setVisiblePassword ] = useState(false)
  const [companiesList, setCompanyList] = useState<ICompanyProps[]>([]);
  
  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'selected_client']);
  const { selectFirstClient } = useContext(EnvironmentContext);
  const { setUIComponentState } = useContext(UIContext);

  const useProtheusSecurity = companyPolicies.find(police => police.property === 'useProtheusSecurity' && police.policy_value === 'true');
  const useMultiCompany = companyPolicies.find(police => police.property === 'useMultiCompany' && police.policy_value === 'true');
  const useDemoClient = companyPolicies.find(police => police.property === 'useDemoClient' && police.policy_value === 'true');

  const { search } = useLocation();
  const changePassword = new URLSearchParams(search).get('changepassword');

  function handleLoginButtonClick() {
    if(username.trim() === '' || password.trim() === '') {
      return toast.error('Preencha e-mail e senha!');
    }

    validateUserAccess(username, password);
  }

  function handleForgotPasswordClick() {
    setModalForgetPassword(true);
  }

  function handleOpenSupport() {
    setModalSupport(true);
  }

  async function getProtheusBearerToken() {
    try {
      const response = await authService.helperToken(companyName.toLowerCase(), companyRest)
      const { accessToken, renewToken } = response.data as IHelperTokenResponse

      if (response.status === 200) {
        dispatch(updateBearerAccessToken({ value: accessToken }))
        dispatch(updateBearerRefreshToken({ value: renewToken }))
        return accessToken
      }else {
        if (!!response.data.msg) {
          setUIComponentState('error', {
            visible: true,
            title: 'Não foi possível efetuar a autenticação!',
            message: response.data.msg,
            statusHttp: response.status,
            urlHttp: response.request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: response.data.msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        } else {
          setUIComponentState('error', {
            visible: true,
            message: 'Erro desconhecido.',
            title: 'Não foi possível efetuar a autenticação!',
            urlHttp: response.request.responseURL,
            statusHttp: response.status,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: response.data.msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function validateUserAccess(user: string, password: string) {    
    try {
      const encodedUser = encode(user);
      const encodedPass = encode(password);
      let helperToken = ''
      setIsValidating(true);
      if(useProtheusSecurity) {
        helperToken = await getProtheusBearerToken()
      }
      
      const response = await authService.auth(encodedUser, encodedPass, currentCompany, useProtheusSecurity ? helperToken : null);
      const { token, usuario, configuracoes, filiais, produtos, tipo, status, msg, nivel = "0" } = response.data as IAuthServiceResponse;

      if (response.status === 200 && status === 'ok') {
        if (tipo === '2') {
          selectFirstClient(filiais[0].codigo, currentCompany, token)
        }

        setCookie("user_info", JSON.stringify({
          token,
          user: usuario,
          activeUserEmail: user,
          userType: tipo,
          pwm: configuracoes,
          branches: filiais,
          allowedProducts: produtos
        }))
        setCookie('selected_client', JSON.stringify({}))
        setCookie('current_company', currentCompany)

        dispatch(signIn({
          token,
          user: usuario,
          activeUserEmail: user,
          userType: tipo,
          pwm: configuracoes,
          branches: filiais,
          allowedProducts: produtos,
          nivel: configuracoes.usecustomerlevel ? Number(nivel) : 0
        }))

        const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51
        const itensPerPage = configuracoes.quantityperpage ? Number(configuracoes.quantityperpage.split(';')[0]) : Math.floor((window.innerHeight - 375) / rowSize)
        dispatch(updateItensPerPage({ value: itensPerPage }))
        
        setCookie('current_branch', JSON.stringify(filiais[0]))
        dispatch(updateCurrentBranch({ value: filiais[0] }))
        const menu = await getMenuList(token)
        
         if (menu !== 'home') {
          navigate(menu);
        } else {
          navigate('home');
        }

        logsService.registerAccessLog(companyName, user, true, companyEnvironment, 'Usuário autenticado!')
      }
      else {
        if (!!msg) {
          setUIComponentState('error', {
            visible: true,
            title: 'Não foi possível efetuar a autenticação!',
            message: msg || 'O serviço do Protheus não está disponível, por favor, aguarde para tentar novamente. ',
            statusHttp: response.status,
            urlHttp: response.request.responseURL,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
          await logsService.registerAccessLog(companyName, user, false, companyEnvironment, msg)
        }
        else {
          setUIComponentState('error', {
            visible: true,
            message: 'O serviço do Protheus não está disponível, por favor, aguarde para tentar novamente.',
            title: 'Não foi possível efetuar a autenticação!',
            urlHttp: response.request.responseURL,
            statusHttp: response.status,
            onClose: () => {
              setUIComponentState('error', {
                visible: false,
                title: 'Ops...',
                message: msg,
                statusHttp: response.status,
                urlHttp: response.request.responseURL,
                onClose: () => { console.log() }
              })
            }
          } as IErrorProps);
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O serviço do Protheus não está disponível, por favor, aguarde para tentar novamente!',
      })
    } finally {
      setIsValidating(false);
      setLoginRemember();
    }

  }

  function setLoginRemember() {
    const rememberMeString = encode(`${username}---${password}`);

    localStorage.setItem('isToRemember', rememberMe.toString());
    if (rememberMe) {
      localStorage.setItem('rememberToken', rememberMeString);
    }
  }

  async function getMenuList(token: string): Promise<string> {
    try {
      dispatch(updateMenuLoading({value: true}));
      const { status, data } = await homeService.getMenu(token);
      const { menus }: { menus: IMenuItem[] } = data;
      
      if (status === 200) {
        dispatch(loadMenus({ menuList: menus, menuListArr: menus, selectedMenu: menus[0] }))
        localStorage.setItem('menu_list_storage', JSON.stringify(menus))
        return Menus[menus[0].nome.toLowerCase()]
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateMenuLoading({value: false}));

    }  
  }

  const getCompanies = async () => {
    if (!useMultiCompany) {
      dispatch(updateCurrentCompany({ value: '' }));
      return undefined;
    }

    try {
      setIsValidating(true);
      const { data, status } = await authService.getCompanies();

      const { empresas, msg }: ICompaniesResponse = data;

      if (status === 200 && data.status === 'ok') {
        dispatch(updateCurrentCompany({ value: empresas[0].codigo }));
        setCompanyList(empresas)
        return empresas[0].codigo;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: msg || 'O serviço do Protheus não está disponível, por favor, aguarde para tentar novamente.!',
        })
      }
    } catch (error) { 
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O serviço do Protheus não está disponível, por favor, aguarde para tentar novamente.!',
      })
    } finally {
      setIsValidating(false);
    }
  }

  useEffect(() => {
    if (companyStatus === 'inactive') {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'EMPRESA INATIVA - O Portal não está habilitado para acesso, favor, entrar em contato com o responsável técnico da empresa!',
      })
      return
    }

    if(useMultiCompany) {
      getCompanies();
    }

    const isToRemember = localStorage.getItem('isToRemember');

    const isLgpdFlagExists = localStorage.getItem('lgpdFlag');

    if (!isLgpdFlagExists) {
      setRenderToast(true);
    }

    if (isToRemember === 'true' && canLogin) {
      const rememberToken: string = localStorage.getItem('rememberToken') as string;
      const decodedToken = decode(rememberToken);

      setRememberMe(true);
      validateUserAccess(decodedToken.split('---')[0], decodedToken.split('---')[1]);
    }
  }, [canLogin, companyStatus]);

  useEffect(() => {
    setValidLength(newPassword.length >= 8 ? true : false);
    setUpperCase(newPassword.toLowerCase() === newPassword);
    setLowerCase(newPassword.toUpperCase() !== newPassword);
    setHasNumber(/\d/.test(newPassword));
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(newPassword));
  }, [newPassword]);

  useEffect(() => {
    setMatch(newPassword === confirmNewPassword);
  }, [])
  return (
    <>
        <PageDefault style={ backgroundType === 'Cor' ? { backgroundColor: backgroundColor } : null}>
          <ForgetPasswordModal
            forgetPasswordModalOpen={modalForgetPassword}
            setForgetPasswordModalOpen={setModalForgetPassword}
          />
          <SupportModal 
            isSupportModaOpen={modalSupport}
            setIsSupportModaOpen={setModalSupport}
          />
          {
            backgroundType !== 'Cor' && (
                backgroundType === 'Video' ? (
                  <video autoPlay loop muted style={{ position: 'fixed', zIndex: 1, width: '100%', minHeight: '100%', objectFit: 'cover' }}>
                    <source className="backgroundVideoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/backgroundVideo` } type='video/mp4' />
                  </video>
                ) : (
                  <img className="backgroundImageBucket"  src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/backgroundImage` } style={{ position: 'fixed', zIndex: 1, width: '100%', minHeight: '100%', objectFit: 'cover', border: 'none' }}/>
                )
            )
          }
          
          <BrowserView style={{ zIndex: 3 }}>
            <LoginBox>
              <LogoContainer>
                <Logotipo className="logoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/logo`} alt="Logotipo"></Logotipo>
              </LogoContainer>
              {
                !changePassword &&
                <>
                  <Form >
                    {
                      companiesList.length > 1 && 
                        <Form.Field>
                          <Select
                            placeholder="Selecione uma empresa"
                            options={companiesList.map(company => { return { key: company.codigo, value: company.codigo, text: company.descricao } })}
                            onChange={(event, data) =>  dispatch(updateCurrentCompany({ value: data.value as string }))}
                            value={currentCompany}
                          />
                        </Form.Field>
                    }
                    <Form.Field>
                      <InputLabel >Usuário</InputLabel>
                      <Input size='big'>
                        <Label basic style={{ backgroundColor: styleSheet.mainColor, borderColor: styleSheet.mainColor }}>
                          <User size={22} color='#fff' />
                        </Label>
                        <Input
                          placeholder='Digite seu usuário'
                          type="email"
                          onChange={(value) => { setUsername(value.currentTarget.value) }}
                        />
                      </Input>
                    </Form.Field>
                    <Form.Field >
                      <InputLabel>Senha</InputLabel>
                      <Input size='big'>
                        <Label basic style={{ backgroundColor: styleSheet.mainColor, borderColor: styleSheet.mainColor }}>
                          <Key size={22} color='#fff' />
                        </Label>
                        <Input
                          type={visiblePassword ? 'text' : 'password'}
                          placeholder='Digite sua senha'
                          onChange={(value) => { setPassword(value.currentTarget.value) }}
                          icon={visiblePassword ? <Icon name="eye slash" link onClick={() => setVisiblePassword(false)} /> :<Icon name="eye" link onClick={() => setVisiblePassword(true)} />}
                          
                        />
                      </Input>
                    </Form.Field>
                    <Form.Field>
                      <CheckboxContainer>
                        <Checkbox
                          toggle
                          label='Logar automáticamente'
                          checked={rememberMe}
                          onChange={(ev) => {
                            const ele = ev.target as HTMLLabelElement;
                            setRememberMe(!ele.parentElement!.classList.contains('checked'))
                          }}
                        />
                      </CheckboxContainer>
                    </Form.Field>
                    <Button
                      positive
                      fluid
                      size='huge'
                      type='submit'
                      icon="checkmark"
                      content="Entrar"
                      labelPosition='right'
                      onClick={handleLoginButtonClick}
                      disabled={isValidating || !canLogin || companyStatus === 'inactive'} //|| (username === '' || password === '')}
                      loading={isValidating}
                      style={{ width: '80%', marginLeft: '10%', marginTop: '4rem', marginBottom: '3rem' }}
                    />
                  </Form>
                  <LinkContainer>
                    <Button
                      style={{
                        background: 'transparent',
                        fontSize: '1.3rem',
                        fontFamily: styleSheet.mainFont,
                        fontWeight: '600',
                        color: '#4183c4'
                      }}
                      onClick={() => handleForgotPasswordClick()}
                    >
                      Esqueci minha senha
                    </Button>

                  </LinkContainer>
                  {
                    customizations['useExternalLinkOnLoginScreen'] && urlExternalLinkOnLoginScreen && textExternalLinkOnLoginScreen ? (
                      <LinkContainer>
                        <a href={urlExternalLinkOnLoginScreen.policy_value} target="_blank">{textExternalLinkOnLoginScreen.policy_value}</a>
                      </LinkContainer>
                    ) : null 
                  }
                  {
                    useDemoClient && 
                    <LinkContainer>
                      <Link to="/">Criar usuário de demonstração</Link>
                    </LinkContainer>
                  }
                 
                </>
              }
              {
                changePassword &&
                <>
                  <Form
                    style={{ marginTop: '2rem' }}
                  >
                    <Form.Field>
                      <InputLabel htmlFor='novaSenha'>Nova Senha</InputLabel>
                      <Input size='big'>
                        <Label basic style={{ backgroundColor: styleSheet.mainColor, borderColor: styleSheet.mainColor }}>
                          <Key size={22} color='#fff' />
                        </Label>
                        <Input
                          name='novaSenha'
                          type='password'
                          placeholder='Digite sua nova senha'
                          onChange={() => {
                            setNewPassword(newPassword)
                            console.log(newPassword)
                          }}
                        />
                      </Input>
                    </Form.Field>
                    <Form.Field>
                      <InputLabel htmlFor='confirmarSenha'>Confirmar Senha</InputLabel>
                      <Input size='big'>
                        <Label basic style={{ backgroundColor: styleSheet.mainColor, borderColor: styleSheet.mainColor }}>
                          <Check size={22} color='#fff' />
                        </Label>
                        <Input
                          name='confirmarSenha'
                          type='password'
                          placeholder='Confirme a senha'
                          onChange={(value) => {
                            setPassword(value.currentTarget.value)
                          }}
                        />
                      </Input>
                      <ul>
                        <li>Mínimo de 8 caracteres: {validLength ? <span>True</span> : <span>False</span>}</li>
                        <li>Possui um numero: {hasNumber ? <span>True</span> : <span>False</span>}</li>
                        <li>Possui letra maiúscula: {upperCase ? <span>True</span> : <span>False</span>}</li>
                        <li>Possui letra minúscula: {lowerCase ? <span>True</span> : <span>False</span>}</li>
                        <li>Possui caracteres especiais: {specialChar ? <span>True</span> : <span>False</span>}</li>
                        <li>Igual nova senha cadastrada: {match ? <span>True</span> : <span>False</span>}</li>
                      </ul>
                    </Form.Field>
                    <Button
                      positive
                      fluid
                      size='huge'
                      type='button'
                      icon="checkmark"
                      content="Salvar"
                      labelPosition='right'
                      onClick={handleLoginButtonClick}
                      disabled={isValidating}
                      loading={isValidating}
                      style={{ width: '80%', marginLeft: '10%', marginTop: '8rem', marginBottom: '11.5rem' }}
                    />
                  </Form>
                </>
              }
              <Footer>
                <span>{packageJson.version}</span>
                
              </Footer>
              
              <Button
                    style={{
                      background: 'transparent',
                      fontSize: '1.3rem',
                      fontFamily: styleSheet.mainFont,
                      fontWeight: '600',
                      color: '#4183c4',
                    }}
                    floated='right'
                    className='tooltip'
                    onClick={() => {
                      handleOpenSupport();
                    }}
                  >
                    <span className='tooltiptext' style={{width: '100%', display: 'flex', justifyContent: 'center', left: '4.5rem'}}>Suporte</span>
                    <Icon name="question circle outline" size='large' corner='bottom right' />
                </Button>
            </LoginBox>
          </BrowserView>

          <MobileView style={{ zIndex: 3 }}>
           <LoginBoxMobile>
              <MobileViewContainer>
                <Info size={60} color={styleSheet.mainColor} />
                <MobileViewTitle>Atenção!</MobileViewTitle>
                <MobileViewDescription>
                  Para uma melhor experiência, recomendamos o download do aplicativo para Android ou IOS do PYLI no link abaixo:
                </MobileViewDescription>

                <MobileContainerButtons>
                    <Button
                      as="a"
                      href={`/app/pyli${window.location.href.split('/')[2].split('.')[0]}.apk`}
                      icon="android"
                      style={{ backgroundColor: '#A4c639', color: styleSheet.shape }}
                      size="massive"
                    />


                    <Button
                      as="a"
                      href={`itms-services://?action=download-manifest&url=${window.location.href}/app/app.plist`}
                      icon="apple"
                      style={{ backgroundColor: '#a9a9a9', color: styleSheet.shape }}
                      size="massive"
                    />
                </MobileContainerButtons>
              </MobileViewContainer>
            </LoginBoxMobile>
          </MobileView>
          
          <BackgrondFilter />

          <Modal
            closeIcon
            onClose={() => setv3Modal(false)}
            open={v3Modal}
          >
            <Modal.Header>Seja Bem Vindo a v3!</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Header>O que há de novo?</Header>

              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                content="Vamos lá!"
                labelPosition='right'
                icon='checkmark'
                onClick={() => setv3Modal(false)}
                positive
              />
            </Modal.Actions>
          </Modal>
        </PageDefault>
        {
          renderToast && <LgpdLogin />
        }
      
      
    </>
  );
}

export default Login;


