import { BookmarkSimple } from 'phosphor-react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { FC, useState } from 'react';
import { Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';

import { ISubmenuItem } from '../../interfaces/Menu/IMenu';

import { MenuReducerInitialState, updateSelectedMenu } from '../../reducers/Menu';
import { EnvironmentReducerInitialState, updateModalImportCsvVisible } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';
import { useCookies } from 'react-cookie';
import { updateIsConsultation } from '../../reducers/Cart';


const DatatableSubMenu: FC = () => {

  const { selectedClient, clientLegends } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu, menuList } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const dispatch = useDispatch();

  const [subMenu, setSubMenu] = useState<ISubmenuItem[]>(selectedMenu?.submenu);

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['selected_menu']);

  const utils = new Utils();
  function handleSubMenuFunction(name: string) {
    const useValidationByClientStatusInOrderCreate = companyPolicies.find(police => police.property === 'useValidationByClientStatusInOrderCreate')
    const useValidationByClientStatusInBudgetCreate = companyPolicies.find(police => police.property === 'useValidationByClientStatusInBudgetCreate')

    if (useValidationByClientStatusInOrderCreate && name === 'IncPedido') {
      if (utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInOrderCreate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Este cliente ainda não está regular!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    } else if (useValidationByClientStatusInBudgetCreate && name === 'IncOrcamen') {
      if (utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInBudgetCreate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'Este cliente ainda não está regular!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    switch (name) {
      case 'IncPedido':
        if (!selectedClient.indice) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Nenhum cliente foi selecionado.',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
        setCookie('selected_menu', JSON.stringify(menuList.find(menu => menu.nome === 'Pedidos')))
        dispatch(updateSelectedMenu({ value: menuList.find(menu => menu.nome === 'Pedidos') }))
        navigate('/cart')
        break;

      case 'IncOrcamen':
        if (!selectedClient.indice) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Nenhum cliente foi selecionado.',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
        setCookie('selected_menu', JSON.stringify(menuList.find(menu => menu.nome === 'Orcamentos')))
        dispatch(updateSelectedMenu({ value: menuList.find(menu => menu.nome === 'Orcamentos') }))
        navigate('/cart')
        break;
      case 'WsPedRap':
        dispatch(updateModalImportCsvVisible({ value: true }))
        break;
      case 'importToCart':
        if (!selectedClient.indice) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Nenhum cliente foi selecionado.',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
        dispatch(updateModalImportCsvVisible({ value: true }))
        break;
      default:
        break;
    }
  }

  return (
    <div style={ { display: 'flex' } }>
      { subMenu && subMenu.map(({ nome, descricao, escopo }) => (
        escopo === 'G' && nome !== "Incluir"  &&
        <Button
          icon
          labelPosition='right'
          size='large'
          color='twitter'
          onClick={ () => handleSubMenuFunction(nome) }
          style={ { display: 'flex', alignItems: 'center' } }
        >
          { descricao }
          <div style={ {
            backgroundColor: '#55acee',
            filter: 'brightness(0.9)',
            width: '3.2rem', height: '100%',
            margin: '0', position: 'absolute',
            right: '0', paddingTop: '0.7rem',
            borderTopRightRadius: '0.28571429rem', borderBottomRightRadius: '0.28571429rem'
          } }
          >
            <BookmarkSimple size={ 15 } color={ styleSheet.shape } />
          </div>
        </Button>
      ))
      }
    </div>
  );
}

export default DatatableSubMenu;