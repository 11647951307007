import { IImportedProductInfo } from './../../components/ImportCsvModalWarning';
import { IBranches } from './../../interfaces/Auth/IBranches';
import { TableData, LegendType } from './../../interfaces/Datatable/IPaginatedServiceResponse';
import { createSlice } from '@reduxjs/toolkit'
import { IUpdateSelectedClient, IUpdateSelectedRow, IUpdateClientLegends, IUpdateOrderBy, IUpdateCurrentBranch, IUpdateCurrentCompany, IUpdateItensPerPage, IUpdateModalImportCsvVisible, IUpdateModalImportCsvWarningVisible, IUpdateModalImportCsvWarningProductInfo } from './actions';

export interface EnvironmentReducerInitialState {
  selectedRow: TableData; 
  selectedClient: TableData;
  clientLegends: LegendType[];
  orderBy: { [key: string]: "ascending" | "descending" };
  currentBranch: IBranches;
  currentCompany: string;
  itensPerPage: number;
  modalImportCsvVisible: boolean;
  modalImportCsvWarningVisible: boolean;
  modalImportCsvWarningProductInfo: IImportedProductInfo[]
}

export const environmentSlice = createSlice({
  name: 'environment',
  initialState: {
    selectedRow: ({} as TableData),
    selectedClient: ({} as TableData),
    clientLegends: ([] as LegendType[]),
    orderBy: ({} as { [key: string]: "ascending" | "descending" | null }),
    currentBranch: ({} as IBranches),
    currentCompany: '' as string,
    itensPerPage: Math.floor((window.innerHeight - 375) / 51),
    modalImportCsvVisible: false,
    modalImportCsvWarningVisible: false,
    modalImportCsvWarningProductInfo: ([] as IImportedProductInfo[])
  },
  reducers: {
    updateSelectedRow: (state, action: IUpdateSelectedRow) => {
      state.selectedRow = action.payload.value
    },

    updateSelectedClient: (state, action: IUpdateSelectedClient) => {
      state.selectedClient = action.payload.value
    },

    updateClientLegends: (state, action: IUpdateClientLegends) => {
      state.clientLegends = action.payload.value
    },

    updateOrderBy: (state, action: IUpdateOrderBy) => {
      state.orderBy = action.payload.value
    },

    updateCurrentBranch: (state, action: IUpdateCurrentBranch) => {
      state.currentBranch = action.payload.value
    },

    updateCurrentCompany: (state, action: IUpdateCurrentCompany) => {
      state.currentCompany = action.payload.value
    },

    updateItensPerPage: (state, action: IUpdateItensPerPage) => {
      state.itensPerPage = action.payload.value
    },

    updateModalImportCsvVisible: (state, action: IUpdateModalImportCsvVisible) => {
      state.modalImportCsvVisible = action.payload.value
    },

    updateModalImportCsvWarningVisible: (state, action: IUpdateModalImportCsvWarningVisible) => {
      state.modalImportCsvWarningVisible = action.payload.value
    },

    updateModalImportCsvWarningProductInfo: (state, action: IUpdateModalImportCsvWarningProductInfo) => {
      state.modalImportCsvWarningProductInfo = action.payload.value
    },

    
  }
})

export const { updateSelectedRow, updateModalImportCsvVisible, updateModalImportCsvWarningVisible, updateModalImportCsvWarningProductInfo, updateSelectedClient, updateClientLegends, updateCurrentBranch, updateCurrentCompany, updateItensPerPage, updateOrderBy } = environmentSlice.actions;
export default environmentSlice.reducer;