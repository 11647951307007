import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";
import { FaCheck } from 'react-icons/fa'

interface StatusGroupProps {
  isSelected: boolean;
  cor: string;
}

interface CheckProps {
  checked: boolean
  cor: string;
}

export const Container = styled.div`
  width:100%;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow: auto;
  margin-bottom: 1rem;
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LabelInput = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.mainFont};
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-family: ${({ theme }) => theme.mainFont};
`;

export const StatusGroup = styled.button<StatusGroupProps>`
  display: flex;
  width: 49%;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${({ cor }) => cor};
  border-radius: 6px;
  word-break: break-all;

  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
  font-weight: ${({ isSelected }) => isSelected ? 'bold' : 'normal'};
  font-size: 1.4rem;//${({ isSelected }) => isSelected ? '1.4rem' : '1.2rem'};
  font-family: ${({ theme }) => theme.mainFont};
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
`;

export const CheckMark = styled(FaCheck) <CheckProps>`
  color: ${({ cor }) => cor};
  display: ${({ checked }) => checked ? 'flex' : 'none'};
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #d9dadc;
  border-radius: 8px;
  width: 100%;
  margin-top: .8rem;
`;

export const LegendStatus = styled.legend`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 0.5rem;
`;

export const LegendsStatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  margin-top: 0.4rem;
`;
