import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";
import homeImg from '../../assets/images/home-background.jpg';

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const HTMLTitleVersion = styled.span`
  font-size: 2.3rem;
  font-weight: 500;
  margin-bottom: -5rem;
  margin-top: 4rem;
  margin-right: 2rem;
  color: ${({ theme }) => theme.mainColor}; //${styleSheet.mainColor};
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};

  display: flex;
  align-items: center;
  justify-content: end;

`;

export const HTMLTitle = styled.span`
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  color: ${({ theme }) => theme.mainColor}; //${styleSheet.mainColor};
  font-family: ${({ theme }) => theme.mainFont};// ${styleSheet.mainFont};

  display: flex;
  align-items: center;
  justify-content: start;
`;

export const HTMLSubTitle = styled.h2`
  color: #4BDBF6;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  margin-bottom: -8rem;
  margin-top: 5rem;
  margin-right: 14rem;

  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 1.5rem;
  font-weight: 500;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
  gap: 2rem;
`;

export const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  height: 30rem;
  z-index: -1;
  margin-bottom: 2rem;
  border-radius: 2rem;

  background-image: url(${homeImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -15rem;
`;

export const MarkupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  
  h1 {
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  color: #696969;
  }
`;