import React, { useEffect, useState } from 'react';
import { Button, Divider, Image, Label, Modal, Segment, Transition } from 'semantic-ui-react';
import { IProductGroups } from '../../interfaces/Product/IProduct';
import { Group, GroupsContainer, LabelInput } from './styles';


interface Props {
  visible: boolean;
  selectedGroup: IProductGroups;
  groups: IProductGroups[]
  onClose(): void;
  onSelect(group: IProductGroups): void;
  onSearch(group: IProductGroups): void;
}

const ProductGroupModal: React.FC<Props> = ({ visible, selectedGroup, groups, onClose, onSelect, onSearch }) => {

  return (
    <Transition animation='fly up' visible={visible} unmountOnHide>
      <Modal
        closeIcon
        size='small'
        onClose={onClose}
        open={visible}
      >
        <Modal.Header>
          <LabelInput>
            Filtro de Produtos
          </LabelInput>
        </Modal.Header>

        <Modal.Content scrolling>
          <Segment>
            <LabelInput>
              Grupo de Produtos
            </LabelInput>
            <Divider />
            <GroupsContainer>
              {
                groups.map(group => (
                  <Group 
                    key={group.conteudo}
                    onClick={() => group.conteudo !== selectedGroup.conteudo ? onSelect(group) : onSelect({} as IProductGroups)} 
                    isSelected={group.conteudo === selectedGroup.conteudo}
                  >
                    <Image style={{ width: '5rem', marginBottom: '0.5rem' }} size="mini" src={group.imagem} />
                    <Label size='large'>{group.nome}</Label>
                  </Group>
                ))
              }
            </GroupsContainer>
          </Segment>
        </Modal.Content>

        <Modal.Actions>
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button onClick={() => onSearch(selectedGroup)} positive>Filtrar</Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </Transition >
  );  
}

export default ProductGroupModal;