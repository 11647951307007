import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Kanban } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { ProjectService } from '../../services/methods/ProjectService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import FilterModal, { IFilter } from '../../components/FilterModal';
import ViewExpenseModal from '../../components/ViewExpenseModal';
import ViewProjectModal from '../../components/ViewProjectModal';
import DefaultHeader from '../../components/DefaultHeader';
import AttachModal from '../../components/AttachModal';
import { IErrorProps } from '../../components/Error';
import FormModal from '../../components/FormModal';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { Content, PageDefault, BrowserContent } from './styles';
import { clearAll, fillFieldAndFieldValues } from '../../reducers/RestField';
import { DataTableReducerInitialState, loadTableData, updateDataTableIndice, updateMode, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { debounce } from "../../common/debounce";
import { PolicieReducerInitialState } from "../../reducers/Policie";

const Projects: FC = () => {
  const projectService: ProjectService = new ProjectService();
  const fieldService: FieldService = new FieldService();

  const { tableData, tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableFilter, tableSearchText, tableOrderBy, indice, mode } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

	const dispatch = useDispatch();

  const useInitialFilterProject = useMemo(() => companyPolicies.find(policy => policy.property === 'useInitialFilterProject' && policy.policy_value === 'true'), [companyPolicies])
  const initialFilterProject = useMemo(() => {
    if (useInitialFilterProject) {
      return JSON.parse(companyPolicies.find(policy => policy.policy === useInitialFilterProject.dependencie).policy_value)
    }

    return undefined
  }, [companyPolicies])

  const { setUIComponentState } = useContext(UIContext);

  const [attachModalOpen, setAttachModalOpen] = useState(false)
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [formFieldState, setFormFieldState] = useState(false);
  const [fieldLoad, setFieldLoad] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [viewItensModal, setViewItensModal] = useState(false);
  const [viewExpensesModal, setViewExpensesModal] = useState(false);
  const [selectedIndice, setSelectedIndice] = useState<string | number>('')
  const [selectedProjectCode, setSelectedProjectCode] = useState<string | number>('')

  const utils = new Utils();

  async function getProjectList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));
      const response = await projectService.index(currentBranch.codigo, page, itensPerPage, token, String(selectedClient.indice), order, undefined, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getProjectsWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getProjectList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));

      await getProjectList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function getProjectFields(indice?: string | number) {
    try {
      setFieldLoad(true);
  
      const body: IRestFieldRequestData = {
        alias: 'SZ2',
        alias2: '',
        url: '',
        tab: '1',
        usuario: '',
        indice: indice ? String(indice) : '',
        ordem: 1,
        token: token,
      }
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        dispatch(fillFieldAndFieldValues({fields: data.campos}))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setFieldLoad(false)
    }
  }

  async function filterProjects(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }));
    await getProjectList(1, tableOrderBy, filter, statusFilter)
  }

  async function orderProjects(order: string) {
    dispatch(updateTableOrderBy({ value: order }));
    await getProjectList(1, order, tableFilter.filter, tableFilter.statusFilter);
  }

  async function onNewProjectClick() {
    setFormFieldState(true);
    await getProjectFields();
  }

  async function onEditProjectClick(indice: string | number) {
    dispatch(clearAll());
    dispatch(updateMode({ value: 'edit' }));
    setViewItensModal(true);
    setSelectedIndice(indice)
    setSelectedProjectCode(tableData.find(item => item.indice === indice)['z2_cod'])
  }

  async function onViewProjectClick(indice: string | number) {
    dispatch(clearAll());
    dispatch(updateMode({ value: 'view' }));
    setViewItensModal(true);
    setSelectedIndice(indice);
    setSelectedProjectCode(tableData.find(item => item.indice === indice)['z2_cod'])
  }

  async function onAttachProjectClick() {
    setAttachModalOpen(true);
  }

  useEffect(() => {
    if (useInitialFilterProject) {
      dispatch(updateTableFilter({ value: { filter: undefined, statusFilter: initialFilterProject } }))
    }
    getProjectList(tableCurrentPage, tableOrderBy, undefined, initialFilterProject);
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<Kanban size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Datatable
              serviceName="wsprojeto"
              onItensPerPageChange={() => getProjectList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onPageChange={(page) => getProjectList(page, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onOrderBy={(value) => orderProjects(value)}
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterProjects(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => {
                dispatch(updateDataTableIndice({ value: indice }));
                if (action !== 'upload') dispatch(updateMode({ value: action }));
                if (action === 'add') onNewProjectClick();
                if (action === 'edit') onEditProjectClick(indice);
                if (action === 'view') onViewProjectClick(indice);
                if (action === 'filter') setFilterModal(true)
                if (action === 'upload') onAttachProjectClick()
              }}
              onSearch={ (value, noDebounce) => getProjectsWithTextSearch(noDebounce ? 'enter' : '', value) }
            />
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

      <FormModal
        visible={formFieldState}
        fieldIsLoading={fieldLoad}
        onClose={(reload) => {
          if(reload) { 
            getProjectList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterProject);
          }
          setFormFieldState(false)
          dispatch(clearAll())
        }}
      />

      {
        viewItensModal &&
        <ViewProjectModal
          indice={selectedIndice}
          onClose={(reload) => {
            setViewItensModal(false)
            if (reload) {
              getProjectList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterProject);
            }
          }}
          onOpenExpenseModal={() => { setViewExpensesModal(true); setViewItensModal(false)}}
          visible={viewItensModal}
          code={selectedProjectCode}
          mode={mode}
        />
      }

      {
        viewExpensesModal &&
        <ViewExpenseModal
          indice={selectedIndice}
          onClose={() => { 
            onViewProjectClick(selectedIndice)
            setViewExpensesModal(false)
          }}
          visible={viewExpensesModal}
          code={selectedProjectCode}
          mode={"add"}
        />
      }

      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterProjects}
        onClose={() => {
          setFilterModal(false)
        }}
      />

      <AttachModal
        indice={indice}
        open={attachModalOpen}
        onClose={() => {
          setAttachModalOpen(false)
          dispatch(updateDataTableIndice({ value: '' }));

        }}
      />
    </PageDefault>
  );
}

export default Projects;