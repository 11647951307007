import { AxiosError, AxiosResponse } from "axios";
import { IObjectParams } from "../../interfaces/Product/IProduct";
import protheusProvider from "../providers/protheus.provider";

export class ClientService {
  public async index(branchId: string, page: number, pageSize: number, authToken: string, order: string, companyId: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wscliente', {
        params: {
          branchId,
          companyId,
          page,
          pageSize,
          token: authToken,
          order,
          filter,
          whereapiadp: statusFilter || ""
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async create(url: string, companyId: string, branchId: string, authToken: string, objectParams: IObjectParams) {
    try {
      const response = await protheusProvider.post(`${url}`, {
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async update(url: string, companyId: string, branchId: string, indice: string | number, authToken: string, objectParams: IObjectParams) {
    try {
      const response = await protheusProvider.put(`${url}/${indice}`, {
        token: authToken,
        data: objectParams
      }, { params: { companyId, branchId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async delete(indice: string | number,  companyId: string, branchId: string | number, authToken: string) {
    try {
      const response = await protheusProvider.delete(`wscliente/${indice}`,  {
        data: {
          token: authToken,
        },
        params: { 
          companyId,
          branchId
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getClientByCNPJ(cnpj: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsrestcnpj/01`, {
        cnpj: cnpj
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}

