import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';


export const Container = styled.div`
  width: 100%;
  background: #9c9c9c;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 1000;
`;

export const FooterSpan = styled.span`
  font-size: 1.2rem;
  padding: 0.4rem 3rem;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
`;

export const SelectedClientFooter = styled.div`
display: flex;
align-items: center;
`;

export const FooterSpanSelectedClient = styled.span`
  font-size: 1.2rem;
  padding: 0.4rem 0.5rem;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
`;