import { Button, Dimmer, Divider, Image, Label, Loader, Modal, Table } from 'semantic-ui-react';
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListBullets, Minus, Plus } from 'phosphor-react';

import GridRestField from '../RestField/GridRestField';

import { UIContext } from '../../contexts/UIContext';
import { CartContext } from '../../contexts/CartContext';

import { FieldService } from '../../services/methods/FieldService';

import { CartReducerInitialState, updateGridList, updateGridQuantity, updateItemsCount, updateSpecificGridList } from '../../reducers/Cart';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { styleSheet } from '../../assets/styles/global';
import defaultProductImg from '../../assets/images/default-product.jpeg';

import { InputQuantity, TableCell, TableCellLabel, TableCellProduct, TableCellQuantity, TableDataText, TableHeaderCell, TableHeaderCellLast } from './styles';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import Swal from 'sweetalert2';
import { updateAllGridFieldValues, updateGridFieldValues } from '../../reducers/RestField';

export interface IGridModalProps {
  visible: boolean;
  onClose(): void;
}

const GridModal: React.FC<IGridModalProps> = ({  visible, onClose }) => {  
  
  const { selectedGrid, gridList, itemsCount } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const dispatch = useDispatch();

  const showBalanceInEachLineOfGrid = companyPolicies.find(police => police.property === 'showBalanceInEachLineOfGrid' && police.policy_value === 'true');
  const useGridColor = companyPolicies.find(police => police.property === 'useGridColor' && police.policy_value === 'true');
  
  const { setUIComponentState } = useContext(UIContext);
  const { handleOpenProductDetail, handleCovertProductListToCart } = useContext(CartContext)

  const [isLoading, setIsLoading] = useState(false);

  
  const columns: string[] = selectedGrid.linha ? selectedGrid.linha.reduce((accumulator, line) => { 
    line.coluna.map(column => { 
      if (!accumulator.includes(column.codigo)) { 
        accumulator = [ ...accumulator, column.codigo] 
      } 
    } )

    return accumulator
  }, []) : []

  useEffect(() => {
    Object.keys(selectedGrid).map(key => {
      if (key === 'linha') return;
      dispatch(updateGridFieldValues({ name: key.toUpperCase(), value: selectedGrid[key] }))
    })
  }, [selectedGrid])

  return (
    <>
    <Modal
      style={{ fontFamily: styleSheet.mainFont }}
      onClose={() => !isLoading && onClose()}
      closeOnDimmerClick={false}
      size={columns.length > 10 ? "fullscreen" : "large"}
      open={visible}
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <ListBullets size={22} color={styleSheet.mainColor} /> 
          Itens da Grade
        </div>

        <Label style={{ borderRadius: '8px' }} color="green" size="massive">
          {
            selectedGrid.linha && new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selectedGrid['b4_prv1'] *  selectedGrid.linha.reduce((acc, currentLine) => {
              if (selectedGrid['b4_grdfix'] === 'NAO' || selectedGrid['b4_grdfix'] === 'Nao' || selectedGrid['b4_grdfix'] === 'N') {
                return acc + currentLine.coluna.reduce((accColumn, currentColumn) => {
                  return accColumn + currentColumn.quantidade
                }, 0)
              }else {
                return acc + (currentLine.quantidade * currentLine.coluna.reduce((accColumn, currentColumn) => {
                  return accColumn + currentColumn.quantidade
                }, 0))
              }
            }, 0) || 0)
          }
        </Label>
      </Modal.Header>
      {
        isLoading ?
          <Modal.Content fluid>
            <Dimmer active>
              <Loader size="big" inverted>Carregando</Loader>
            </Dimmer>
          </Modal.Content>

          :
          <Modal.Content  scrolling>
            <GridRestField  mode="add" />

            <Divider />

            {
              (selectedGrid['b4_grdfix'] === 'NAO' || selectedGrid['b4_grdfix'] === 'Nao' || selectedGrid['b4_grdfix']==="N") && 
              <Table definition compact celled textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {
                      columns.map(column => (
                        <Table.HeaderCell 
                          key={column}
                        >
                          <p>{column}</p>
                          {/* <p>{selectedGrid.linha[0].coluna.find(col => col.codigo === column).quantidade}</p> */}
                        </Table.HeaderCell>
                      ))
                    }
                    <TableHeaderCellLast />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    !showBalanceInEachLineOfGrid && (
                      <Table.Row>
                        <TableCell textAlign="left">
                          <TableCellLabel>Estoque disponível</TableCellLabel>
                        </TableCell>
                        {
                          columns.map(column => {
                            return (
                              <TableCell key={column} style={{ width: '70px', background: styleSheet.mainColor }}>
                                <TableCellLabel>{selectedGrid.linha[0].coluna.find(col => col.codigo === column).saldo > 50 ? '+50' : selectedGrid.linha[0].coluna.find(col => col.codigo === column).saldo}</TableCellLabel>
                              </TableCell>
                            )
                          })
                        }
                        <TableCell width={1} textAlign='center'>
                          <TableCellLabel>Total</TableCellLabel>
                        </TableCell>
                      </Table.Row>
                    )
                  }
                  {
                    selectedGrid.linha.map((line, indexLine) => {
                      return (
                        <>
                          {
                            showBalanceInEachLineOfGrid && (
                              <Table.Row key={indexLine}>
                                <TableCell textAlign="left">
                                  <TableCellLabel>Estoque disponível</TableCellLabel>
                                </TableCell>
                                {
                                  columns.map(column => {
                                    return (
                                      <TableCell key={column} style={{ width: '70px' }}>
                                        <TableCellLabel>{selectedGrid.linha[indexLine].coluna.find(col => col.codigo === column).saldo}</TableCellLabel>
                                      </TableCell>
                                    )
                                  })
                                }
                                <TableCell width={1} textAlign='center'>
                                  <TableCellLabel>Total</TableCellLabel>
                                </TableCell>
                              </Table.Row>
                            )
                          }
                          <Table.Row>
                            <TableCell 
                              textAlign="left" 
                              onClick={() => {
                                handleOpenProductDetail(line.imagem ? String(line.imagem) : defaultProductImg)
                              }}
                            >
                              <TableCellProduct>
                                <Image
                                  size="mini"
                                  src={line.imagem || defaultProductImg}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = defaultProductImg;
                                  }}
                                />
                                  {line.nome}
                              </TableCellProduct>
                            </TableCell>
                            {
                              columns.map((column, indexColumn) => {
                                return (
                                  <TableCell key={indexColumn} style={{ maxWidth: '70px' }}>
                                    <TableCellQuantity>
                                      <InputQuantity 
                                        style={{ width: '100%' }} 
                                        size="mini" 
                                        type="text" 
                                        value={line.coluna.find(col => col.codigo === column).quantidade} 
                                        onChange={event => 
                                          dispatch(updateGridQuantity({ type: 'aberta', indexLine: indexLine, indexColumn: indexColumn, quantity: Number(event.target.value)})) 
                                        }
                                        onFocus={e => e.target.select()}
                                        onBlur={event => {
                                          if (Number(event.target.value) > selectedGrid.linha[indexLine].coluna.find(col => col.codigo === column).saldo) {
                                            dispatch(updateGridQuantity({ type: 'aberta', indexLine: indexLine, indexColumn: indexColumn, quantity: selectedGrid.linha[indexLine].coluna.find(col => col.codigo === column).saldo })) 

                                            return Swal.fire({
                                              title: 'Atenção!',
                                              html: `Não tem saldo suficiente para o valor digitado, o valor foi alterado para o máximo permitido ${selectedGrid.linha[indexLine].coluna.find(col => col.codigo === column).saldo}`,
                                              icon: 'info',
                                              confirmButtonText: 'Ok'
                                            })
                                          }
                                        }}
                                      />

                                      <div style={{ marginTop: '7px', display: 'flex', gap: '5px'  }}>
                                        <Button 
                                          tabIndex={-1}
                                          style={{ padding: '4px'}} 
                                          size="mini" 
                                          circular
                                          disabled={line.coluna.find(col => col.codigo === column).quantidade <= 0}
                                          onClick={() => dispatch(updateGridQuantity({ type: 'aberta', indexLine: indexLine, indexColumn: indexColumn, quantity: line.coluna.find(col => col.codigo === column).quantidade - 1}))}

                                        >
                                          <Minus size={14} weight="bold" color="#000"/>                                         
                                        </Button>
                                        <Button 
                                          tabIndex={-1}
                                          style={{ padding: '4px' }} 
                                          size="mini" 
                                          color='green' 
                                          circular
                                          onClick={() => {
                                              if (line.coluna.find(col => col.codigo === column).quantidade >= selectedGrid.linha[indexLine].coluna.find(col => col.codigo === column).saldo) {
                                                return Swal.fire({
                                                  title: 'Atenção!',
                                                  html: `Não tem saldo suficiente para o valor digitado, o valor foi alterado para o máximo permitido ${line.coluna.find(col => col.codigo === column).quantidade}`,
                                                  icon: 'info',
                                                  confirmButtonText: 'Ok'
                                                })
                                              }
                                            dispatch(updateGridQuantity({ type: 'aberta', indexLine: indexLine, indexColumn: indexColumn, quantity: line.coluna.find(col => col.codigo === column).quantidade + 1}))
                                          }}
                                        >
                                          <Plus size={14} weight="bold" color="#FFF" />
                                        </Button>
                                      </div>
                                    </TableCellQuantity>
                                  </TableCell>
                                )
                              })
                            }

                            <TableCell width={1} textAlign="center">
                              <TableDataText>
                                {line.coluna.reduce((acc, col) => {
                                  return acc + col.quantidade 
                                }, 0)}
                              </TableDataText>
                            </TableCell>
                          </Table.Row>
                        </>
                      )
                    })
                  }
                </Table.Body>
              </Table>
            }
            {
              (selectedGrid['b4_grdfix'] === 'SIM' || selectedGrid['b4_grdfix'] === 'Sim' || selectedGrid['b4_grdfix'] === 'S') && 
              <Table definition compact celled textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <TableHeaderCell  />
                    <TableHeaderCell  />
                    {
                      columns.map(column => (
                        <Table.HeaderCell 
                          key={column}
                        >
                          <p>{column}</p>
                          <p>{selectedGrid.linha[0].coluna.find(col => col.codigo === column).quantidade}</p>
                        </Table.HeaderCell>
                      ))
                    }
                    <TableHeaderCellLast />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {
                    !showBalanceInEachLineOfGrid && (
                      <Table.Row>
                        <TableCell style={{ width: '80px' }}>
                          <TableCellLabel>Quantidade</TableCellLabel>
                        </TableCell>
                        <TableCell  textAlign="left">
                          <TableCellLabel>Estoque disponível</TableCellLabel>
                        </TableCell>
                        {
                          columns.map(column => {
                            return (
                              <TableCell key={column} style={{ width: '70px', background: styleSheet.mainColor }}>
                                <TableCellLabel>{selectedGrid.linha[0].coluna.find(col => col.codigo === column).saldo > 50 ? '+50' : selectedGrid.linha[0].coluna.find(col => col.codigo === column).saldo}</TableCellLabel>
                              </TableCell>
                            )
                          })
                        }
                        <TableCell style={{ width: '40px' }} textAlign='center'>
                          <TableCellLabel>Total</TableCellLabel>
                        </TableCell>
                      </Table.Row>
                    )
                  }
                  { selectedGrid.linha.map((line, index) => { 
                    return (
                      <React.Fragment key={index}>
                        {
                          showBalanceInEachLineOfGrid && (
                            <Table.Row>
                              <TableCell style={{ width: '80px' }}>
                                <TableCellLabel>Quantidade</TableCellLabel>
                              </TableCell>
                              <TableCell  textAlign="left">
                                <TableCellLabel>Estoque disponível</TableCellLabel>
                              </TableCell>
                              {
                                columns.map(column => {
                                  return (
                                    <TableCell key={column} style={{ width: '70px' }}>
                                      <TableCellLabel>{selectedGrid.linha[index].coluna.find(col => col.codigo === column) ? selectedGrid.linha[index].coluna.find(col => col.codigo === column).saldo : 0}</TableCellLabel>
                                    </TableCell>
                                  )
                                })
                              }
                              <TableCell style={{ width: '40px' }} textAlign='center'>
                                <TableCellLabel>Total</TableCellLabel>
                              </TableCell>
                            </Table.Row>
                          )
                        }
                        <Table.Row>
                          <TableCell style={{ maxWidth: '80px' }}>
                            <TableCellQuantity>
                              <InputQuantity 
                                style={{ width: '100%', textAlign: 'center' }} 
                                size="mini" 
                                type="text" 
                                value={line.quantidade}
                                onFocus={e => e.target.select()}
                                onChange={event => dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: Number(event.target.value)}))}
                                onBlur={event => {                                
                                  if (line.coluna.every(column => column.quantidade <= 0 || column.saldo <= 0)) {                                    
                                    return Swal.fire({
                                      title: 'Atenção!',
                                      html: 'Os itens da grade não possuem quantidade/saldo suficiente!',
                                      icon: 'info',
                                      confirmButtonText: 'Ok'
                                    }).then(()=> {
                                      dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: 0}))
                                    })
                                  }                                                 
                               
                                  if (line.coluna.findIndex(column => column.quantidade * (Number(event.target.value)) > column.saldo) !== -1) {
                                    const maxQuantityValueAllowed = Math.floor(line.coluna.find(column => column.quantidade * (Number(event.target.value)) > column.saldo).saldo / line.coluna.find(column => column.quantidade * (Number(event.target.value)) > column.saldo).quantidade)
                                    dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: maxQuantityValueAllowed }))

                                    return Swal.fire({
                                      title: 'Atenção!',
                                      html: `Não tem saldo suficiente para o valor digitado, o valor foi alterado para o máximo permitido ${maxQuantityValueAllowed}`,
                                      icon: 'info',
                                      confirmButtonText: 'Ok'
                                    })
                                  }
                                }}
                              />

                              <div style={{ marginTop: '7px', display: 'flex', gap: '5px'  }}>
                                <Button 
                                  style={{ padding: '4px' }} 
                                  size="mini" 
                                  circular
                                  disabled={line.quantidade <= 0}
                                  onClick={() => dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: line.quantidade - 1}))}
                                >
                                  <Minus size={14} weight="bold" color="#000" />
                                </Button>
                                <Button 
                                  style={{ padding: '4px' }} 
                                  size="mini" 
                                  color='green' 
                                  circular
                                  onClick={() => {                                    
                                  if (line.coluna.every(column => column.quantidade <= 0 || column.saldo <= 0)) {
                                      return Swal.fire({
                                        title: 'Atenção!',
                                        html: 'Os itens da grade não possuem quantidade/saldo suficiente!',
                                        icon: 'info',
                                        confirmButtonText: 'Ok'
                                      }).then(()=> {
                                        dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: 0}))                                         
                                      })
                                    }
                                    
                                    if (line.coluna.findIndex(column => column.quantidade * (line.quantidade + 1) > column.saldo) !== -1) {
                                      return Swal.fire({
                                        title: 'Atenção!',
                                        html: `Não tem saldo suficiente para o valor digitado, o valor foi alterado para o máximo permitido ${line.quantidade}`,
                                        icon: 'info',
                                        confirmButtonText: 'Ok'
                                      })
                                    }
                                      dispatch(updateGridQuantity({ type: 'fechada', indexLine: index, indexColumn: index, quantity: line.quantidade + 1}))
                                  }}
                                >
                                  <Plus size={14} weight="bold" color="#FFF" />
                                </Button>
                              </div>
                            </TableCellQuantity>
                          </TableCell>
                          <TableCell  
                            textAlign="left"
                            onClick={() => {
                              handleOpenProductDetail(line.imagem ? String(line.imagem) : defaultProductImg)
                            }}
                          >
                            <TableCellProduct>
                              <Image
                                size="mini"
                                src={line.imagem || defaultProductImg}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = defaultProductImg;
                                }}
                              />
                              {line.nome}
                            </TableCellProduct>
                          </TableCell>

                          {
                            columns.map(column => { 
                              return (
                                <TableCell key={column} style={{ width: '40px' }}>
                                  <TableCellQuantity>
                                    {line.coluna.find(col => col.codigo === column) ? line.coluna.find(col => col.codigo === column).quantidade *  line.quantidade : 0}
                                  </TableCellQuantity>
                                </TableCell>
                              )
                            }) 
                          }

                          <TableCell style={{ width: '40px' }} textAlign="center">
                            <TableDataText>
                              {line.coluna.reduce((acc, col) => {
                                return acc + (col.quantidade * line.quantidade)
                              }, 0)}
                            </TableDataText>
                          </TableCell>
                        </Table.Row>
                      </React.Fragment>
                    )
                  }) }
                </Table.Body>
              </Table>
            }
          </Modal.Content>
      }

      <Modal.Actions>
        <Button.Group  style={{ marginLeft: '1rem' }}>
        <Button tabIndex={-1} size="big" onClick={onClose}>Cancelar</Button>
        <Button.Or text="ou" />  
        <Button 
          size="big" 
          color='green' 
          onClick={() => {
            const [gridIsValid] = selectedGrid.linha.map(line => {
              if (selectedGrid['b4_grdfix'] === 'SIM' || selectedGrid['b4_grdfix'] === 'Sim' || selectedGrid['b4_grdfix'] === 'S') {
                return selectedGrid['b4_qtdmin'] <= line.coluna.reduce((acc, col) => {
                  return acc + (col.quantidade * line.quantidade)
                }, 0)
              } else {
                return selectedGrid['b4_qtdmin'] <= line.coluna.reduce((acc, col) => {
                  return acc + col.quantidade 
                }, 0)
              }
            }) 

            if (!gridIsValid) {
              return Swal.fire({
                title: 'Atenção!',
                html: `O quantidade mínimo permitido da grade é ${selectedGrid['b4_qtdmin']}`,
                icon: 'info',
                confirmButtonText: 'Ok'
              })
            }

            if (gridList.find(grid => useGridColor ? grid.b4_grdcod === selectedGrid.b4_grdcod : grid.indice === selectedGrid.indice)) {
              dispatch(updateSpecificGridList({ indice: selectedGrid.indice, useGridColor: !!useGridColor, codigo: selectedGrid.b4_grdcod, value: selectedGrid }));
            } else {
              dispatch(updateGridList({ value: [...gridList, selectedGrid] }));
            }
            onClose();
          }}
        >
          { gridList.find(grid => useGridColor ? grid.b4_grdcod === selectedGrid.b4_grdcod : grid.indice === selectedGrid.indice) ? 'Alterar' : 'Adicionar' }
        </Button> 
        </Button.Group>   
      </Modal.Actions>
    </Modal>
    </>
  );
}

export default GridModal;