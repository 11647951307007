import React, { FC } from "react";

import {  Header } from "./styles";
import 'react-loading-skeleton/dist/skeleton.css'

import OrdersBarChart from "../OrdersBar";


const ClientValues: FC = () => {
  return (
    <>
      <Header style={{ height: `${window.innerHeight - 300}px` }}>
        <div style={{ flex: 1, height: '100%' }}>
          <OrdersBarChart  /> 
        </div>
      </Header>
    </>
  );
};

export default ClientValues;
