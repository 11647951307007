import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

interface IButtonTable {
  color: string;
}

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  /* padding: 2rem .3rem 0 2rem; */
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;     
  }

  ::-webkit-scrollbar-track {
    background: #EEEEEE;    
    border-radius: 20px;       

  }

  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;    
    border-radius: 20px;       
    border: 3px solid #EEEEEE;  
  }
`;

export const Infos = styled.div`
  width: 15%;
  margin-top: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Calendar = styled.div`
  width: 85%;
  padding: 1rem;
  overflow-y: auto;
  position: relative;

  th.fc-day-mon,
  th.fc-day-tue,
  th.fc-day-wed,
  th.fc-day-thu,
  th.fc-day-fri {
    background-color: ${({ theme }) => theme.mainColor}  !important;
  }

  .fc-day-sat,
  .fc-day-sun {
    background-color: ${({ theme }) => theme.lighterGrey}  !important;
  }

  th.fc-day-sat,
  th.fc-day-sun {
    background-color: ${({ theme }) => theme.darkerGrey}  !important;
  }

`;

export const DefaultLabel = styled.label`
  font-size: 1.3rem !important;
`;

export const DeleteZone = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  border: 1px solid  ${({ theme }) => theme.dangerColor}; //${styleSheet.dangerColor};
  background-color: rgba(242, 100, 92, 200);
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonTable = styled.button<IButtonTable>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.75rem;
  border: 0;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;