import { Tab, Modal, Accordion, Form, Button, Loader, Dimmer, Dropdown } from 'semantic-ui-react'
import React, { FC, useContext, useEffect, useState } from 'react';
import { ChartPie } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { DashboardContext } from '../../contexts/DashboardContext';

import DefaultHeader from '../../components/DefaultHeader';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { DashboardReducerInitialState, updateModalChartOpen, updateSelectedGroup } from '../../reducers/Dashboard';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';

import { BrowserContent, Content, PageDefault } from './styles';
import { AuthReducerInitialState } from '../../reducers/Auth';
import Swal from 'sweetalert2';
import { FilterAlertMessage } from '../../common/Constants';
 

const Dashboard: FC = () => {
	const { groups, graphSelected, isLoading, isLoadingGraphFullscreen, selectedGroup, graphsJsx, selectedGraphsJsx, modalChartOpen, filterFields } = useSelector((state: { dashboard: DashboardReducerInitialState }) => state.dashboard);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch()

  const [inputValues, setInputValues] = useState<{ nome: string, conteudo: string, isDate: boolean }[]>([])
  const [filterActive, setFilterActive] = useState(false)

  const { fetchGroups, handleChangeGroup, handleOpenChartFullscreen } = useContext(DashboardContext);

  useEffect(() => {
    fetchGroups(token)
  }, [])

  useEffect(()=> {
    setInputValues([]);
  },[graphSelected])

  const panes = groups.map(group => {
    return {
      menuItem: `${group.grupo} - ${group.graficos.length} gráficos`,
      render: () =>
        <Tab.Pane style={{ background: 'transparent', border: 'none' }}>
          {
            isLoading && <Skeleton baseColor='#DDD' style={{ marginRight: '2rem', marginBottom: '1rem' }} inline height={265}  width="48.5%" count={group.graficos.length} /> 
          }
          {graphsJsx}
        </Tab.Pane>
    }
  })

  const handleTabChange = async (e, { activeIndex }) => {
    const group = groups[activeIndex]
    await handleChangeGroup(group, token)
  }

  const handleChangeFilters = (name: string, value: string, isDate) => {
    const inputAlreadyAdded = inputValues.find(input => input.nome === name)
    if (inputAlreadyAdded) {
      const inputArr = inputValues.filter(input => input.nome !== name)
      setInputValues([...inputArr, { nome: name, conteudo: value, isDate }])
    } else {
      setInputValues(prevState => [...prevState, { nome: name, conteudo: value, isDate }])
    }
  }

  const handleFilterGraph = async () => {
  
    if(!inputValues.length || filterFields.length !== inputValues.length) {
      
      const message = filterFields.length === 1 
        ? FilterAlertMessage.oneFilter 
        : FilterAlertMessage.manyFilters;    
         
      Swal.fire({
        title: 'Atenção!',    
        html: `${message}`,
        icon: 'warning',
        confirmButtonText: 'Ok',       
        target: document.getElementById('modalDashboardContent')    
      });

      return;
    }

    const filtros = {}
    inputValues.map(input => {
      if (input.isDate) {
        filtros[input.nome] = input.conteudo.replaceAll('-', '')
      } else {
        filtros[input.nome] = input.conteudo
        filtros[input.nome + 'DESCRI'] = input.conteudo
      }
    })

    await handleOpenChartFullscreen(graphSelected, token, selectedGroup.grupo, filtros)

    setFilterActive(false)
  }

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<ChartPie size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Tab 
              style={{ width: '100%' }} 
              menu={{ secondary: true, pointing: true }} 
              onTabChange={handleTabChange} 
              panes={panes} 

            />
          </BrowserContent>
        </Browser>
      </Content>

      <Modal
        style={{ height: '90%', minHeight: '600px' }}
        dimmer="blurring"
        size="fullscreen"
        closeIcon={true}
        open={modalChartOpen}
        onClose={() => dispatch(updateModalChartOpen({ value: false }))}
      >
        <Modal.Content id="modalDashboardContent" scrolling>
        {
          filterFields.length > 0 && (
            // <Modal.Header>
              <Accordion>
                <Accordion.Title
                  active={filterActive}
                  content='Filtros'
                  index={0}
                  onClick={() => setFilterActive(!filterActive)}
                />
                <Accordion.Content active={filterActive}>
                  <Form>
                    <Form.Group widths={4}>
                      {
                        filterFields.map(field =>
                          field.isDate ?
                            <Form.Field>
                              <label >
                                {field.nome}
                              </label>
                              <input className={field.nome} type="date" onChange={(event) => handleChangeFilters(field.nome, event.target.value, true)} />
                            </Form.Field>
                            :
                            <Form.Field>
                              <label>{field.nome}</label>
                              <Dropdown
                                search
                                placeholder="Selecione"
                                selection
                                options={field.options.map(option => { return  { key: option.codigo, value: option.codigo, text: option.nome  } })}
                                onChange={(ev, data) => {
                                  handleChangeFilters(field.nome, String(data.value), false)
                                }}
                              />
                            </Form.Field>
                            // <Form.Field label={field.nome} control='select' onChange={(event) => handleChangeFilters(field.nome, event.target.value, false)} >
                            //   <option value="Selecione">Selecione</option>
                            //   {
                            //     field.options.map(option => (
                            //       <option value={option.codigo}>{option.nome}</option>
                            //     ))
                            //   }
                            // </Form.Field>
                        )
                      }
                    </Form.Group>
                    <Button onClick={handleFilterGraph} primary size="large" >Filtrar</Button>
                  </Form>
                </Accordion.Content>
              </Accordion>
            // </Modal.Header>
          )
        }
          {
            isLoadingGraphFullscreen ?
              <Dimmer active inverted>
                <Loader size='medium'></Loader>
              </Dimmer>

              : selectedGraphsJsx
          }
        </Modal.Content>
      </Modal>
      <Footer />
    </PageDefault>
  );
}

export default Dashboard;