import { Container, Table, Segment, Form, Header, Icon, Grid, Divider, Dropdown, DropdownProps, Image, Tab, Checkbox, Button, Popup, Input, Label, List } from 'semantic-ui-react';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Gear, Trash, Copy, Upload, File, FileText, Download } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { encode } from 'base-64';


import { SettingsService } from '../../services/methods/SettingsService';
import { FieldService } from '../../services/methods/FieldService';

import { PolicieContext } from '../../contexts/PolicieContext';
import { UIContext } from '../../contexts/UIContext';

import { Customization } from '../../components/Customization';
import DefaultHeader from '../../components/DefaultHeader';
import RestField from '../../components/RestField';
import { Policy } from '../../components/Policy';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { ICompanyStyle } from '../../interfaces/Styles/IStyles';

import { PolicieReducerInitialState, updateBackgroundType, updateCompanyFiles, updateMobileBackgroundType } from '../../reducers/Policie';
import { EnvironmentReducerInitialState, updateSelectedClient } from '../../reducers/Environment';
import { AuthReducerInitialState, signOut } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { DataTableReducerInitialState } from '../../reducers/DataTable';

import { Alias, Alias2, bucketURL } from '../../common/Constants';
import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { AppSettingsContainer, BrowserContent, ButtonTable, Content, HeaderTabFiles, ImageBackground, InputColorContainer, InputColorContent, InputColorLabel, PageDefault, SemanticTab, SettingsContainer, SettingsContent, SettingsContentTable, TableImage } from './styles';
import { fillFieldAndFieldValues } from '../../reducers/RestField';
import { IErrorProps } from '../../components/Error';
import SideMenu from '../../components/SideMenu';
import { useCookies } from 'react-cookie';

const emptyOption = {key: '', value: '', text: ''};

interface IProperty {
  [key: string]: string;
}

const Settings: FC = () => {
  const settingService: SettingsService = new SettingsService();
  const fieldService: FieldService = new FieldService();
  
  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);
	const { companyPolicies, backgroundType, mobileBackgroundType, companyCustomizations, companyEnvironment, companyStyles, companyFiles, companyName, authToken, policiesDropdown, policiesList, companyId } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { menuList, selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { tableLoading } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);

  const dispatch = useDispatch()

  const { getCompanyPolicies, getCompanyStyles, getPolicies, getCompanyFiles } = useContext(PolicieContext);
  const { setUIComponentState } = useContext(UIContext);
  
  const [propertyList, setPropertyList] = useState<IProperty>({});

  const [firstColor, setFirstColor] = useState('');
  const [secondColor, setSecondColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [primaryColor, setPrimaryColor] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [secondaryColor, setSecondaryColor] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [backgroundColorPolicy, setBackgroundColorPolicy] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [backgroundTypePolicy, setBackgroundTypePolicy] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [mobileBackgroundTypePolicy, setMobileBackgroundTypePolicy] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [urlRest, setUrlRest] = useState<ICompanyStyle>({} as ICompanyStyle);
  const [urlRestText, setUrlRestText] = useState('');
  const [fieldLoad, setFieldLoad] = useState(false);

  const inputFileRefLogoImage = useRef<HTMLInputElement>(null);
  const inputFileRefBackgroundImage = useRef<HTMLInputElement>(null);
  const inputFileRefBackgroundImageMobile = useRef<HTMLInputElement>(null);
  const inputFileRefVideo = useRef<HTMLInputElement>(null);
  const inputCompanyFiles = useRef<HTMLInputElement>(null);

  const utils = new Utils();
  const settingsService = new SettingsService();
  const navigate = useNavigate();

  async function handleSetFiles(files: FileList | null, type: 'logo' | 'backgroundImage' | 'backgroundImageMobile' | 'backgroundVideo') {
    const filesArr = Array.prototype.slice.call(files);
    const fileResponse = await utils.getBase64(filesArr[0]);
    const id = toast.loading(`Atualizando arquivo...`)
    try {

      const { status } = await settingService.updateCompanyStylesAssets(companyId, authToken, String(fileResponse), type);

      if(status === 200) {
        getCompanyStyles();
        return toast.update(id, {render: 'Arquivo atualizado com sucesso!', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
      
    } catch (error) {
      console.log('error', error)
      return toast.update(id, { render: 'Algo deu errado!', isLoading: false, autoClose: 5000})
    } finally {
      inputFileRefLogoImage.current.value = ""
      inputFileRefBackgroundImage.current.value = ""
      inputFileRefVideo.current.value = ""
      inputFileRefBackgroundImageMobile.current.value = ""
      
      window.document.querySelectorAll<HTMLImageElement | HTMLSourceElement>(`.${type}Bucket`).forEach(async (asset) => {
        await fetch(`${bucketURL}/${companyEnvironment}/${companyName}/${type}`, { cache: 'reload', mode: 'no-cors' })
        asset.src = asset.src.includes('?') ? asset.src.split('?')[0]+`?${new Date().getTime()}` : asset.src+`?${new Date().getTime()}`;
      })
    }
  }


  const handleNewDropdownListPolicyValue = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    try {
      
      const newPolicyId = Number(data.value);
      const fatherPolicie = policiesList.find(policy => policy.dependencie === newPolicyId);
      const searchedPolicies = policiesList.find(policy => policy.id === newPolicyId);
  
      if(companyPolicies.find(policy => policy.policy === newPolicyId) ){
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Esta regra de negócio já está cadastrada!',
        });
        
      } else {
        if(fatherPolicie) {
          await settingsService.includeCompanyPolicies(companyId, authToken, fatherPolicie.id, fatherPolicie.type === 'boolean' ? 'true' : '');
        }
        await settingsService.includeCompanyPolicies(companyId, authToken, newPolicyId, searchedPolicies.type === 'boolean' ? 'true' : '');
        if(searchedPolicies.dependencie) {
          const policieDependencie = policiesList.find(policy => policy.id === searchedPolicies.dependencie)
          await settingsService.includeCompanyPolicies(companyId, authToken, policieDependencie.id, policieDependencie.type === 'boolean' ? 'true' : '');
        }
        getCompanyPolicies();
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }
  
  async function handleChangeColor(value: string, id: number, type: 'primary' | 'secondary' | 'background') {
    try {
      const { status } = await settingService.updateCompanyStyles(companyId, authToken, id, value);

      if(status === 200) {
        getCompanyStyles();
        if(type === 'primary') {
          styleSheet.mainColor = value
        }
        if (type === 'secondary'){
          styleSheet.contrastColor = value
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function handleChangeRest(id: number) {
    Swal.fire({
      title: 'Confirmar ação?',
      text: 'Realmente deseja alterar o rest todos as requisições sera enviada para esse rest.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, confirmar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { status } = await settingService.updateCompanyStyles(companyId, authToken, id, urlRestText);
    
          if(status === 200) {
            getCompanyStyles();
            handleLogout();
          }
        } catch (error) {
          console.log('error', error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          })
        }
      }
    })
  }

  async function handleToggleBackgroundType(type: 'Cor' | 'Video' | 'Image') {
    try {
      if(backgroundTypePolicy.style) {
        dispatch(updateBackgroundType({ value: type }))
        const { status } = await settingService.updateCompanyStyles(companyId, authToken, backgroundTypePolicy.style, type);
        if(status === 200) {
          toast.success('Tipo de background alterado com sucesso!')
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }

  }

  async function handleToggleMobileBackgroundType(type: 'Cor' | 'Image') {
    try {
      if(mobileBackgroundTypePolicy.style) {
        dispatch(updateMobileBackgroundType({ value: type }))
        const { status } = await settingService.updateCompanyStyles(companyId, authToken, mobileBackgroundTypePolicy.style, type);
        if(status === 200) {
          toast.success('Tipo de background alterado com sucesso!')
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }

  }

  async function handleUploadCompanyFiles(files: FileList | null) {
    try {
      const filesArr = Array.prototype.slice.call(files)
      const id = toast.loading(`Fazendo o upload 0/${filesArr.length}`)
      let filesAlreadyUploaded = 0;
      for (const file of filesArr) {
        if (!!file) {
          const data = await utils.getBase64(file) as Promise<string>
          
          await settingService.uploadCompanyFiles(companyId,  file.name, data.toString() , authToken);
          filesAlreadyUploaded += 1;
          toast.update(id, {render: `Fazendo o upload ${filesAlreadyUploaded}/${filesArr.length}`, isLoading: true });
        }
      }

      const filesToUpdate = filesArr.map(file => {
        if (
          file.name.toLowerCase().includes('.png') || 
          file.name.toLowerCase().includes('.gif') || 
          file.name.toLowerCase().includes('.jpg') || 
          file.name.toLowerCase().includes('.jpeg') || 
          file.name.toLowerCase().includes('.mp4') 
        ) {
          return file.name.split('.')[0]  
        } else {
          return file.name
        }
      })
      
      dispatch(updateCompanyFiles({ value: [...companyFiles, ...filesToUpdate] }))
      return toast.update(id, {render: 'Arquivos carregados com sucesso!', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      inputCompanyFiles.current.value = ""
    }
  }

  async function handleDeleteCompanyFile(fileName: string) {
    const id = toast.loading(`Excluindo arquivo...`)
    try {
      const { status } = await settingService.deleteCompanyFiles(companyId, fileName, authToken);

      if(status === 200) {
        dispatch(updateCompanyFiles({ value: [...companyFiles.filter(file => file !== fileName)]}))
        return toast.update(id, {render: 'Arquivo excluído com sucesso!', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
    } catch (error) {
      console.log('error', error)
      return toast.update(id, { render: 'Algo deu errado!', isLoading: false, autoClose: 5000})
    }
  }

  function handleLogout() {
    removeCookie('user_info')
    removeCookie('current_branch')
    removeCookie('current_company')
    removeCookie('selected_client')
    removeCookie('selected_menu')

    dispatch(signOut())
    dispatch(updateSelectedClient({ value: {} }))
    
    localStorage.removeItem('isToRemember');
    localStorage.removeItem('rememberToken');
    navigate('/');
  }

  async function getFields(selectedMenu: string) {
    try {
      if (!Alias[selectedMenu.toLowerCase()]) {
        return toast.warning('Esse menu não tem um Alias vinculado!')
      }
      setFieldLoad(true);
      const body: IRestFieldRequestData = {
        alias: Alias[selectedMenu.toLowerCase()],
        alias2: Alias2[selectedMenu.toLowerCase()] || '',
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token,
      }
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);
  
      if (status === 200) {
        dispatch(fillFieldAndFieldValues({fields: data.campos}))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setFieldLoad(false)
    }
  }

  function getPanes() {
    return [
      {
        menuItem: 'Geral',
        render: () => 
        <Tab.Pane style={{ background: 'transparent', border: 'none', padding: '2rem' }} attached={false} loading={tableLoading}>
          <SettingsContent>
            <div style={{ display: 'flex',  alignItems: 'flex-end', gap: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <span style={{ padding: 0, margin: '2px' }}>URL do REST Protheus</span>
                <Input 
                  value={urlRestText}
                  onChange={(ev, data) => setUrlRestText(data.value)} 
                  style={{ width: '50rem' }} 
                />
              </div>

              <Button 
                onClick={() => handleChangeRest(urlRest.style)}
                style={{ height: '35px', background: styleSheet.mainColor, color: '#FFF' }}
              >
                Trocar
              </Button>
            </div>
          </SettingsContent>
        </Tab.Pane>,
      },
      {
        menuItem: 'Politicas',
        render: () => 
          <Tab.Pane style={{ background: 'transparent', border: 'none' }} attached={false} loading={tableLoading}>
            <SettingsContentTable>
                <h2>Regras de Negócio</h2>
                <Dropdown 
                  name='policieSearch'
                  style={{border: '1px solid #365A93', fontSize: '2rem'}}
                  fluid 
                  options={ [ emptyOption, ...policiesDropdown ] } 
                  placeholder='Adicione uma nova regra de negócio...'
                  allowAdditions
                  search
                  value=''
                  selection
                  onChange={(e, data) => { handleNewDropdownListPolicyValue(e, data) }}
                />
              <List relaxed style={{ width: '100%' }}>
                { companyPolicies.map((policy) => <Policy data={ policy } key={ `${policy.id} - policy` } /> )}
              </List>
            </SettingsContentTable>
          </Tab.Pane>,
      },

      {
        menuItem: 'Arquivos',
        render: () => 
          <Tab.Pane style={{ background: 'transparent', border: 'none' }} attached={false} loading={tableLoading}>
            <SettingsContentTable>
              <HeaderTabFiles>
                <h2>Arquivos</h2>
                <div>
                  <Button size="big" primary onClick={() => inputCompanyFiles.current.click()}>
                    <input
                      ref={inputCompanyFiles}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(event) => handleUploadCompanyFiles(event.target.files)}
                      multiple
                    />
                    Carregar
                    <Upload size={16} style={{ marginLeft: '5px'}} />
                  </Button>
                </div>
              </HeaderTabFiles>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} textAlign='center'> Imagem </Table.HeaderCell>
                    <Table.HeaderCell textAlign='left'> Nome </Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign='center'> Opções </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    companyFiles.map((file, index) => (
                      <Table.Row key={`${file} - ${index}`}>
                        <Table.Cell textAlign='center'>
                          {
                            file.split('.').length > 1 ? (
                              <FileText size="50" />
                            ) : (
                              <TableImage src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/assets/${file}`} />
                            )
                          }
                        </Table.Cell>
                        <Table.Cell>{file}</Table.Cell>
                        <Table.Cell textAlign='center'>
                          <Button.Group size='small'>
                            <Popup
                              trigger={
                                <ButtonTable 
                                  onClick={() => {
                                    handleDeleteCompanyFile(file)
                                  }}
                                >
                                  <Trash size={20} color="#E47261" />
                                </ButtonTable>
                              }
                              content={<span style={{ fontSize: '1.3rem' }}>Excluir</span>}
                              inverted
                            />
                            
                            <Popup
                              trigger={
                                <ButtonTable 
                                  onClick={() => {
                                    const a = document.createElement('a')
                                    a.href = `${bucketURL}/${companyEnvironment}/${companyName}/assets/${file}`
                                    a.download = file
                                    document.body.appendChild(a)
                                    a.click()
                                    document.body.removeChild(a)

                                  }}
                                >
                                  <Download size={20} color="#648D56" />
                                </ButtonTable>
                              }
                              content={<span style={{ fontSize: '1.3rem' }}>Baixar</span>}
                              inverted
                            />
                            <Popup
                              trigger={
                                <ButtonTable 
                                  onClick={() => {
                                    navigator.clipboard.writeText(`${bucketURL}/${companyEnvironment}/${companyName}/assets/${file}`)
                                    toast.success('Url copiada...')
                                  }}
                                >
                                  <Copy size={20} color="#648D56" />
                                </ButtonTable>
                              }
                              content={<span style={{ fontSize: '1.3rem' }}>Copiar Url</span>}
                              inverted
                            />
                          </Button.Group>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
            </SettingsContentTable>
          </Tab.Pane>,
      },
      {
        menuItem: 'Estilos',
        render: () => 
          <Tab.Pane style={{ background: 'transparent', border: 'none', padding: '2rem' }} attached={false} loading={tableLoading}>
            <SettingsContent>
              <InputColorContainer>
                <InputColorContent>
                  <InputColorLabel htmlFor="mainColor">Cor Principal</InputColorLabel>
                  <input 
                    id="mainColor"
                    className='inputColor'
                    type="color" 
                    value={firstColor}
                    onChange={(ev) => setFirstColor(ev.target.value)}  
                    onBlur={(ev) => handleChangeColor(ev.target.value, primaryColor.style, 'primary')}
                  />
                </InputColorContent>

                <InputColorContent>
                  <InputColorLabel htmlFor="secondaryColor">Cor Secundária</InputColorLabel>
                  <input 
                    id="secondaryColor"
                    className='inputColor'
                    type="color" 
                    value={secondColor}
                    onChange={(ev) => setSecondColor(ev.target.value)}  
                    onBlur={(ev) => handleChangeColor(ev.target.value, secondaryColor.style, 'secondary')}
                  />
                </InputColorContent>

                <InputColorContent>
                  <InputColorLabel htmlFor="backgroundColor">Cor de fundo login</InputColorLabel>
                  <input 
                    id="backgroundColor"
                    className='inputColor'
                    type="color" 
                    value={backgroundColor}
                    onChange={(ev) => setBackgroundColor(ev.target.value)}  
                    onBlur={(ev) => handleChangeColor(ev.target.value, backgroundColorPolicy.style, 'background')}
                  />
                </InputColorContent>
              </InputColorContainer>
            </SettingsContent>
            <SettingsContent>
              <InputColorContent>
                <InputColorLabel htmlFor="">Tipo de fundo no login</InputColorLabel>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
                  <Segment size="big" style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem' }}>
                    <Checkbox 
                      toggle 
                      checked={backgroundType === 'Image'}
                      onChange={() => handleToggleBackgroundType('Image')}
                    />
                    Imagem
                  </Segment>

                  <Segment size="big" style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem' }}>
                    <Checkbox 
                      toggle 
                      checked={backgroundType === 'Video'}
                      onChange={() => handleToggleBackgroundType('Video')}
                    />
                    Video
                  </Segment>

                  <Segment size="big" style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem' }}>
                    <Checkbox 
                      toggle 
                      checked={backgroundType === 'Cor'}
                      onChange={() => handleToggleBackgroundType('Cor')}
                    />
                    Cor
                  </Segment>
                </div>
              </InputColorContent>
            </SettingsContent>
            <SettingsContent>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }} placeholder>
                      <Label size="big" attached='top'>Logomarca do cliente</Label>
                      <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                          <input
                            ref={inputFileRefLogoImage}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => handleSetFiles(event.target.files, 'logo')}
                            accept=".png, .jpg, .jpeg"
                          />
                          <Grid.Column onClick={() => inputFileRefLogoImage.current.click()}>
                            <Header icon>
                              <Icon name='sync' />
                              Clique aqui para alterar o logo atual...
                            </Header>
                          </Grid.Column>

                          <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: '215px', padding: '1rem' }} >
                            <ImageBackground className="logoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/logo`} alt="Logo da Empresa" />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>

                  <Grid.Column>
                    <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }} placeholder>
                      <Label size="big" attached='top'>Imagem de fundo</Label>
                      <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                          <input
                            ref={inputFileRefBackgroundImage}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => handleSetFiles(event.target.files, 'backgroundImage')}
                            accept=".png, .jpg, .jpeg"
                          />
                          <Grid.Column onClick={() => inputFileRefBackgroundImage.current.click()}>
                            <Header icon>
                              <Icon name='sync' />
                              Clique aqui para alterar o background atual...
                            </Header>
                          </Grid.Column>

                          <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: '215px', padding: '1rem' }}>
                            <ImageBackground className="backgroundImageBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/backgroundImage`} alt="Background da tela de login" />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>

                  <Grid.Column>
                    <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }} placeholder>
                      <Label size="big" attached='top'>Video de fundo</Label>
                      <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                          <input
                            ref={inputFileRefVideo}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(event) => handleSetFiles(event.target.files, 'backgroundVideo')}
                            accept=".mp4, .mov, .wmv, flv"
                          />
                          <Grid.Column onClick={() => inputFileRefVideo.current.click()}>
                            <Header icon>
                              <Icon name='sync' />
                              Clique aqui para alterar o video atual...
                            </Header>
                          </Grid.Column>

                          <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: '215px', padding: '1rem' }} >
                            <video autoPlay loop muted style={{ width: '100%', minHeight: '100%', objectFit: 'cover' }}>
                              <source className="backgroundVideoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/backgroundVideo`} type='video/mp4' />
                            </video>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SettingsContent>
            <SettingsContent>
              <AppSettingsContainer>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '2rem' }}>
                  <h2>Configurações do Aplicativo</h2>
                  <InputColorContent>
                    <InputColorLabel htmlFor="">Tipo de fundo no login do aplicativo</InputColorLabel>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem' }}>
                      <Segment  size="big" style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem' }}>
                        <Checkbox 
                          toggle 
                          checked={mobileBackgroundType === 'Image'}
                          onChange={() => handleToggleMobileBackgroundType('Image')}
                        />
                        Imagem
                      </Segment>

                      <Segment size="big" style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem' }}>
                        <Checkbox 
                          toggle 
                          checked={mobileBackgroundType === 'Cor'}
                          onChange={() => handleToggleMobileBackgroundType('Cor')}
                        />
                        Cor
                      </Segment>
                    </div>
                  </InputColorContent>
                </div>
                <Grid style={{ marginTop: '2rem' }} columns={3}>
                  <Grid.Row>
                    <Grid.Column>
                      <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }} placeholder>
                        <Label size="big" attached='top'>Imagem de fundo para o Aplicativo</Label>
                        <Grid columns={2} stackable textAlign='center'>
                          <Divider vertical></Divider>
                          <Grid.Row verticalAlign='middle'>
                            <input
                              ref={inputFileRefBackgroundImageMobile}
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(event) => handleSetFiles(event.target.files, 'backgroundImageMobile')}
                              accept=".png, .jpg, .jpeg"
                            />
                            <Grid.Column onClick={() => inputFileRefBackgroundImageMobile.current.click()}>
                              <Header icon>
                                <Icon name='sync' />
                                Clique aqui para alterar o background atual...
                              </Header>
                            </Grid.Column>

                            <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: '215px', padding: '1rem' }}>
                              <ImageBackground className="backgroundImageMobileBucket" src={`${bucketURL}/${companyEnvironment}/${companyName}/backgroundImageMobile`} alt="Background da tela de login do aplicativo" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </AppSettingsContainer>
            </SettingsContent>
          </Tab.Pane>,
      },
      {
        menuItem: 'Customizações',
        render: () => 
          <Tab.Pane style={{ background: 'transparent', border: 'none' }} attached={false} loading={tableLoading}>
            <SettingsContentTable>
              <h2>Customização do Produto</h2>
              <Table  style={{ boxShadow: '0 0 3px 2px #dedede' }} celled >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign='center'> Propriedade </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Descrição </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Dispositivo </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Valor </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { companyCustomizations.map((customization) => <Customization data={ customization } key={ `${customization.id} - customization` } /> )}
                </Table.Body>
              </Table>
            </SettingsContentTable>
          </Tab.Pane>,
      },
      {
        menuItem: 'Campos',
        render: () => 
        <Tab.Pane style={{ background: 'transparent', border: 'none' }} attached={false} loading={tableLoading}>
          <SemanticTab 
            menu={{ fluid: true, vertical: true, tabular: true }} 
            onTabChange={(e, data) => {
              getFields(data.panes[data.activeIndex].menuItem)
            }}
            panes={menuList.map(menu=> {
              return {
                menuItem: menu.nome,
                render: () => 
                  <Tab.Pane style={{ boxShadow: '0 0 3px 2px #dedede' }} loading={fieldLoad}>
                    <RestField mode='view' />
                  </Tab.Pane>
                ,
              }
            })} 
          />
        </Tab.Pane>,
      },
    ]
  }

  useEffect(() => {
    if(companyStyles.find(style => style.property === 'urlRest')) {
      setUrlRest(companyStyles.find(style => style.property === 'urlRest'))
      setUrlRestText(companyStyles.find(style => style.property === 'urlRest').style_value)
    }
    if(companyStyles.find(style => style.property === 'primaryColor')) {
      setPrimaryColor(companyStyles.find(style => style.property === 'primaryColor'))
      setFirstColor(companyStyles.find(style => style.property === 'primaryColor').style_value)
    }
    if(companyStyles.find(style => style.property === 'secondaryColor')) {
      setSecondaryColor(companyStyles.find(style => style.property === 'secondaryColor'))
      setSecondColor(companyStyles.find(style => style.property === 'secondaryColor').style_value)
    }
    if(companyStyles.find(style => style.property === 'backgroundColor')) {
      setBackgroundColorPolicy(companyStyles.find(style => style.property === 'backgroundColor'))
      setBackgroundColor(companyStyles.find(style => style.property === 'backgroundColor').style_value)
    }
    if(companyStyles.find(style => style.property === 'backgroundType')) {
      setBackgroundTypePolicy(companyStyles.find(style => style.property === 'backgroundType'))
    }

    if(companyStyles.find(style => style.property === 'mobileBackgroundType')) {
      setMobileBackgroundTypePolicy(companyStyles.find(style => style.property === 'mobileBackgroundType'))
    }
  }, [companyStyles])

  useEffect(() => {
    const propValue: IProperty = {};

    for (const { property, policy_value } of companyPolicies) {
      propValue[property] = policy_value;
    }

    // for (const { propriedade, valor } of descriptionValue) {
    //   propValue[propriedade] = valor;
    // }
    setPropertyList(propValue);
  }, []);

  useEffect(() => {
    if(authToken !== '' && companyId !== 0) {
      getCompanyPolicies();
    }   
    if(companyId !== 0) {
      getPolicies();
      getCompanyFiles();
    }
  }, [authToken, companyId])

  return (
    <PageDefault>
      <DefaultHeader />
      <Content>
        <SideMenu />
        <Browser title='Configurações' icon={<Gear size={24} color={styleSheet.mainColor} />} >
          <BrowserContent>
            <Container style={{ overflowY: 'auto' }}  fluid>
              <SemanticTab style={{ marginBottom: '2rem' }}  menu={{ secondary: true, pointing: true }}  panes={getPanes()} fluid />
            </Container>
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

    </PageDefault>
  );
}

export default Settings;