import { AxiosError, AxiosResponse } from "axios";
import { F3RequestData } from "../../@types/generic";
import { FieldValues } from "../../@types/Restfield";
import protheusProvider from "../providers/protheus.provider";

export class FieldService {
  public async getFields(branchId: string, companyId: string, serializedData: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`wsrestfield/${branchId}/${serializedData}`, { params: { companyId } });
    return response;
  }

  public async getF3(branchId: string, companyId: string, clientId: string, searchCode: string, content: string | number, email: string, request: string, requestData: string, authToken: string, isNewF3?: boolean, total?: number) {
    
    const response = await protheusProvider.post(`wsrestnewf3/${branchId}/${isNewF3 ? '2' : ''}`, {
      clientId,
      consulta: searchCode,
      conteudo: content,
      email,
      requisito: request,
      requisitocont: requestData,
      token: authToken,
      total
    } as F3RequestData, { params: { companyId } });
    return response;
  }

  public async create(subsidiaryId: string, companyId: string, objectParams: { [key: string]: string | number | FieldValues }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsppddata/${subsidiaryId}`, objectParams, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async update(subsidiaryId: string, companyId: string,  objectParams: { [key: string]: string | number | FieldValues }): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put(`wsppddata/${subsidiaryId}`, objectParams, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

}