import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Checkbox, Table, Icon, Button, Loader, Modal } from 'semantic-ui-react';
import { DeviceMobile, Desktop } from 'phosphor-react';
import Swal from 'sweetalert2';

import { PolicieContext } from '../../contexts/PolicieContext';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { ICustomization, IPolicies, SettingsService } from '../../services/methods/SettingsService';
import { ToggleContent, LoaderContent, ToggleContainer } from './styles';

export interface IProps {
  data: ICustomization;
}

const DeviceIcon = {
  web: <Desktop size={24} />,
  mobile: <DeviceMobile size={24} />,
  all: [<Desktop size={24} />, <DeviceMobile size={24} />]
}

export const Customization = ({ data: { customization, customization_value, property, device, description, company, type, id, dependencie}}: IProps) => {
	const {  authToken  } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const settingsService = new SettingsService();
  const { getCompanyCustomization } = useContext(PolicieContext);
  const [ isChecked, setIsChecked ] = useState(customization_value.toString());
  const [ inputElement, setInputElement ] = useState('');
  const [ defaultValueApi, setDefaultValueApi ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleteLoading, setIsDeleteLoading ] = useState(false);
  const [ isLoadingDone, setIsLoadingDone ] = useState(false);
  const [ modalDeletePolicies, setModalDeletePolicies ] = useState(false);
  const [policyDependency, setPolicyDependency] = useState<IPolicies>();

 const handleOnBlur =  async (event: React.FocusEvent<HTMLInputElement>) => {
    try {
      const inputValue = event.currentTarget.value;
      // if(inputValue === customization_value) return;
      setIsLoading(true);
      await settingsService.updateCompanyCustomizations(company, authToken, id, inputValue);
      setIsLoading(false);
      setIsLoadingDone(true);
      getCompanyCustomization();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
 }

  const handleSwitch =  async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const newValue = (isChecked === 'true') ? 'false' : 'true';
      setIsChecked(newValue);
      setIsLoading(true);
      await settingsService.updateCompanyCustomizations(company, authToken, customization, newValue);
      setIsLoading(false);
      setIsLoadingDone(true);
      getCompanyCustomization();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const inputPolicieValue =  async () => {
    try {
      const defaultValueApiCopy = defaultValueApi;
      defaultValueApiCopy.push(inputElement);
      const newValue = defaultValueApiCopy.join(';')
      setIsLoading(true)
      await settingsService.updateCompanyCustomizations(company, authToken, id, newValue);
      setInputElement('');
      setIsLoading(false);
      setIsLoadingDone(true);
      getCompanyCustomization();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const defaultValuePolicies = () => {
    // const showDefaultValue = customization_value.split(';');
    // setDefaultValueApi(showDefaultValue);
  }

  const handleRemoveLabel = async (index: number) => {
    try {
      const defaultValueApiCopy = defaultValueApi;
      defaultValueApiCopy.splice(index, 1);
      setDefaultValueApi([...defaultValueApiCopy]);
      const newValue = defaultValueApiCopy.join(';');
      setIsLoading(true);
      await settingsService.updateCompanyCustomizations(company, authToken, id, newValue);
      setIsLoading(false);
      setIsLoadingDone(true);
      console.log(newValue);
      getCompanyCustomization();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const handleRemovePolicy = async() => {
    try {
      setIsDeleteLoading(true);
      const { status } = await settingsService.deleteCompanyPolicies(id, authToken);
      if(policyDependency) {
        await settingsService.deleteCompanyPolicies(policyDependency.id, authToken);
      }
      setIsDeleteLoading(false);
      if(status === 200) return getCompanyCustomization();
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsDeleteLoading(false)
    }
  }

 useEffect(() => {
   if(type === 'options') {
    defaultValuePolicies();
   }
 }, [type]);

 useEffect(() => {
  if(isLoadingDone) {
    setTimeout(() => {
      setIsLoadingDone(false);
    }, 2000)
  }
 },[isLoadingDone])

  // useEffect(() => {
  //   const currentCustomizationDependency = companyCustomizations.find(customization => customization.customization === dependencie);
  //   const fatherDependency = companyCustomizations.find(customization => customization.dependencie === customization);
  //   if(currentCustomizationDependency) {
  //     setPolicyDependency(currentCustomizationDependency)
  //   }else if (fatherDependency) {
  //     setPolicyDependency(fatherDependency)
  //   }
  // }, [])

 
  if (type === 'boolean' ) {
    return (
      <>
        <Table.Row key={ id }>
          <Table.Cell width='2'>
            <span>{ property }</span>
          </Table.Cell>         
          <Table.Cell textAlign='left' width='9'>
            { description }
          </Table.Cell>
          <Table.Cell textAlign='center' width='1'>
            { DeviceIcon[device] }
          </Table.Cell>
          <Table.Cell textAlign='center' width='1'>
            <ToggleContainer>
              <ToggleContent>
                <span>Não</span>
                <Checkbox
                  toggle
                  style={{ margin: '0 1rem' }}
                  checked={ isChecked === 'true' ? true : false }
                  onChange={ handleSwitch }
                  disabled={ isLoading }
                />
                <span>Sim</span>
              </ToggleContent>
              <LoaderContent>
                {
                  isLoading && 
                    <Loader active inline />
                }
                {
                  isLoadingDone && 
                    <Icon name='check' color='green' />
                }
              </LoaderContent>
            </ToggleContainer>
          </Table.Cell>
        </Table.Row>

        <Modal
          size='mini'
          open={ modalDeletePolicies }
          onClose={ () => setModalDeletePolicies(false) }
        >
          <Modal.Header>Deletar Regra de Negócio</Modal.Header>
          <Modal.Content style={{ fontSize: '1.5rem' }}>
            <p>Tem certeza que deseja deletar esta regra?</p>
            {
              policyDependency && <p>Esta regra tem dependência <strong>"{policyDependency.property}"</strong> que será excluída também </p>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ () => setModalDeletePolicies(false) }>
              Não
            </Button>
            <Button positive onClick={ handleRemovePolicy }>
              Sim
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  } else if (type === 'text'){
    return (
      <>
        {/* <Table.Row key={ id }>
          <Table.Cell width='2'>
            <span>{ property }</span>
          </Table.Cell>
          <Table.Cell textAlign='left' width='9'>
            <span>{ description }</span>
          </Table.Cell>
          <Table.Cell textAlign='center' width='1' >
            <Input 
              style={{ width: '90%', textAlign: 'center', marginRight: '.65rem' }}
              onBlur={ handleOnBlur }
              defaultValue={ customization_value }
            />
            {
              isLoading && 
                <Loader active inline color='blue' />
            }
            {
            isLoadingDone && 
              <Icon name='check' color='green' />
            }
          </Table.Cell>
          <Table.Cell textAlign='center' width='1'>
            <Button 
              style={{ background: 'none', margin: '0' }}
            >
              <Icon
                name='trash alternate outline'
                color='red'
                size='large'
                onClick={() => setModalDeletePolicies(true) }
                loading={ isDeleteLoading }
              />
            </Button>
          </Table.Cell>
        </Table.Row>

        <Modal
          size='mini'
          open={ modalDeletePolicies }
          onClose={ () => setModalDeletePolicies(false) }
        >
          <Modal.Header>Deletar Regra de Negócio</Modal.Header>
          <Modal.Content style={{ fontSize: '1.5rem' }}>
            <p>Tem certeza que deseja deletar esta regra?</p>
            {
              policyDependency && <p>Esta regra tem dependência <strong>"{policyDependency.property}"</strong> que será excluída também </p>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ () => setModalDeletePolicies(false) }>
              Não
            </Button>
            <Button positive onClick={ handleRemovePolicy }>
              Sim
            </Button>
          </Modal.Actions>
        </Modal> */}
      </>
    )
  } else {
    return(
      <>
        {/* <Table.Row key={id}>
          <Table.Cell width='2'>
            <span>{property}</span>
          </Table.Cell>
          <Table.Cell textAlign='left' width='9'>
            <span>{description}</span>
          </Table.Cell>
          <Table.Cell textAlign='center' width='1'>
            <InputOptionContainer>
              <Input
                action={{
                  color: 'blue',
                  labelPosition: 'right',
                  icon: 'plus',
                  content: 'Add',
                  onClick: () => { inputPolicieValue() },
                }}
                placeholder='insira o valor...'
                onChange={(_, data) => setInputElement(data.value)}
                value={inputElement} />
              <LabelComponent>
                {defaultValueApi.map((option, index) => option !== '' &&
                <Label
                  style={{ backgroundColor: styleSheet.contrastColor, color: styleSheet.shape }}
                >
                  {option}
                  <Icon
                    name='delete'
                    onClick={() => handleRemoveLabel(index)} />
                </Label>
                )}
                {isLoading &&
                  <Loader active inline />}
                {isLoadingDone &&
                  <Icon name='check' color='green' />}
              </LabelComponent>
            </InputOptionContainer>
          </Table.Cell>
          <Table.Cell textAlign='center' width='1'>
            <Button
              style={{ background: 'none', margin: '0' }}
            >
              <Icon
                name='trash alternate outline'
                color='red'
                size='large'
                onClick={() => setModalDeletePolicies(true) }
                loading={isDeleteLoading} />
            </Button>
          </Table.Cell>
        </Table.Row>
      
        <Modal
          size='mini'
          open={ modalDeletePolicies }
          onClose={ () => setModalDeletePolicies(false) }
        >
          <Modal.Header>Deletar Regra de Negócio</Modal.Header>
          <Modal.Content style={{ fontSize: '1.5rem' }}>
            <p>Tem certeza que deseja deletar esta regra?</p>
            {
              policyDependency && <p>Esta regra tem dependência <strong>"{policyDependency.property}"</strong> que será excluída também </p>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={ () => setModalDeletePolicies(false) }>
              Não
            </Button>
            <Button positive onClick={ handleRemovePolicy }>
              Sim
            </Button>
          </Modal.Actions>
        </Modal> */}
      </>
    )
  }
  
}

