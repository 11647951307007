import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ClipboardText } from 'phosphor-react';
import { styleSheet } from '../../assets/styles/global';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { decode } from 'base-64';
import Swal from 'sweetalert2';

import { BudgetService } from '../../services/methods/BudgetService';
import { EmailService } from '../../services/methods/EmailService';

import { UIContext } from '../../contexts/UIContext';

import ViewBudgetItensModal from '../../components/ViewBudgetItensModal';
import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import EmailModal from '../../components/EmailModal';
import PrintModal from '../../components/PrintModal';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IOpcoes } from '../../interfaces/Menu/IMenu';

import { DataTableReducerInitialState, loadTableData, resetDataTable, updateTableCurrentPage, updateTableData, updateTableFilter, updateTableLoading, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { EnvironmentReducerInitialState, updateCurrentBranch } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { createOrEditOrderOrBudget, updateConsultationType, updateSelectedProductToConsultation } from '../../reducers/Cart';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { BrowserContent, Content, PageDefault } from './styles';
import ProductDetailModal from '../../components/ProductDetailModal';
import SelectedClientModal from '../../components/SelectedClientModal';
import { useCookies } from 'react-cookie';
import { debounce } from "../../common/debounce";
import SendModal from "../../components/SendModal";
import ConsultationModal from "../../components/ConsultationModal";

const Budgets: FC = () => {
  const budgetService: BudgetService = new BudgetService();
  const emailService: EmailService = new EmailService();
  
  const { tableData, tableOrderBy, tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableFilter, tableLegend } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, selectedClient, currentCompany, itensPerPage, clientLegends } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, branches, activeUserEmail, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch()

  const useInitialFilterBudget = useMemo(() => companyPolicies.find(policy => policy.property === 'useInitialFilterBudget' && policy.policy_value === 'true'), [companyPolicies])
  const initialFilterBudget = useMemo(() => {
    if (useInitialFilterBudget) {
      return JSON.parse(companyPolicies.find(policy => policy.policy === useInitialFilterBudget.dependencie).policy_value)
    }

    return undefined
  }, [companyPolicies])

  const [cookies, setCookie, removeCookie] = useCookies(['user_info', 'current_company', 'current_branch', 'url_rest', 'selected_client', 'selected_menu']);

  const navigate = useNavigate();

  const { setUIComponentState } = useContext(UIContext);

  const [viewItensModal, setViewItensModal] = useState(false)
  const [selectedIndice, setSelectedIndice] = useState<string | number>('')

  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [filterModal, setFilterModal] = useState(false);
  const [isDanfeXml, setIsDanfeXml] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [printOptions, setPrintOption] = useState<IOpcoes[]>([]);
  const [emailModal, setEmailModal] = useState(false);
  const [danfeModal, setDanfeModal] = useState(false);
  const [selectedClientModal, setSelectedClientModal] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [effectOrcLoading, setEffectOrcLoading] = useState(false);
  const [consultationModalVisible, setConsultationModalVisible] = useState(false)


  const utils = new Utils();

  async function getBudgetList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }))
      dispatch(updateTableLoading({ value: true }))
  
      const response = await budgetService.index(currentCompany, currentBranch.codigo, page, itensPerPage, token, String(selectedClient.indice), order, filter, statusFilter)
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))  
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
      
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getBudgetsWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);


      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getBudgetList(1, orderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));
      await getBudgetList(1, orderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [orderBy, tableFieldTypeConfig, tableFilter]);

  async function handlePrint(indice: string | number, typePrint?: string) {
    const id = toast.loading("Por favor aguarde...")
    setEmailModal(false);
    setIsSendEmail(true);
    
    try {
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      
      const nf = isDanfeXml ? {
        ...selectedRow
      } : {
        C5_NUM: selectedRow.C5_NUM || selectedRow.c5_num,
      }
      const url = isDanfeXml ? 'WSRESTDANFEXML' : 'WSENVPED';
      const config = notHaveDanfeConfiguration && isDanfeXml;

      const {data} = await emailService.print(url, currentBranch.codigo, currentCompany, String(indice), "SCJ", nf, token, config, typePrint);
      if(data.status === 'ok') {
        if(data.html) {  
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

          Swal.fire({
            html: `${decode(data.html)} <br/><br/><button class="button-pdf-download" onClick="window.print();">Download em PDF</button> `,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
          });

          document.querySelector<HTMLElement>('.swal2-container > div').style.width = '80%';
        }else if(data.x64) {
          const blob = utils.base64toBlob(`data:application/pdf;base64,${data.x64}`);
          const url = URL.createObjectURL(blob);

          const hiddenElement = document.createElement('a');
          hiddenElement.href = url
          hiddenElement.target = '_blank';
          hiddenElement.click();
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        }else{
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          return Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        }

      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function handleSendThroughEmail(email: string, indice: string | number, type?: string) {
    const id = toast.loading("Por favor aguarde...")
    try {
      setEmailModal(false);
      setIsSendEmail(true);
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      const nf = {
        CJ_NUM: selectedRow.CJ_NUM || selectedRow.cj_num,
      }
      const config = notHaveDanfeConfiguration && isDanfeXml

      const { data } = await emailService.sendEmail(currentBranch.codigo, currentCompany, String(indice), email, activeUserEmail, "SCJ", nf, token, config, type);

    if(data.status === 'ok') {
      if(data.html) {  
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

        Swal.fire({
          html: `${decode(data.html)} <br/><br/><button class="button-pdf-download" onClick="window.print();">Download em PDF</button> `,
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true
        });

        document.querySelector<HTMLElement>('.swal2-container > div').style.width = '80%';
      }else{
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }
    }else{
      toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: data.errorMessage || data.msg,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function handleDanfeXml(email: string, indice: string | number, sendType: string, type?: string) {
    const id = toast.loading("Por favor aguarde...")
    setDanfeModal(false);    
    try {
      const selectedRow = tableData.find(item => item.indice === indice);
      const notHaveDanfeConfiguration = companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true');
      const useLandscapeDanfe = companyPolicies.find(police => police.property === 'useLandscapeDanfe' && police.policy_value === 'true');
      
      const config = notHaveDanfeConfiguration && isDanfeXml;
      const orientation = useLandscapeDanfe ? 2 : 1;

      const {data} = await emailService.danfe(sendType, currentBranch.codigo, currentCompany, String(indice), email, activeUserEmail, "SCJ", selectedRow, token, config, isDanfeXml ? orientation : null, type);

      if(data.status === 'ok') {
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

        if (sendType === 'download' && data.arquivos.pdf.length > 0) {
          const blobPDF = utils.base64toBlob(data.arquivos.pdf[0]);
          const urlPDF = URL.createObjectURL(blobPDF);

          const hiddenElementPDF = document.createElement('a');
          hiddenElementPDF.href = urlPDF
          hiddenElementPDF.target = '_blank';
          hiddenElementPDF.click();

          if (data.arquivos.xml[0]) {
            const hiddenElementXML = document.createElement('a');
            hiddenElementXML.href = data.arquivos.xml[0]
            hiddenElementXML.download = `${String(indice).trim()}.xml`;
            hiddenElementXML.click();
          }
        }

      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  async function filterBudget(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }))
    await getBudgetList(1, orderBy, filter, statusFilter)
  }

  async function handleDeleteBudget(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")
    try {
      const { data } = await budgetService.delete(token, indice)
      
      if(data.status === 'ok') {
        const tableDataArr = [...tableData];
        const newTableData = tableDataArr.filter(item => item.indice !== indice);
        dispatch(updateTableData({ value: newTableData }))
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }else{
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });

      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function orderBudget(order: string) {
    setOrderBy(order)
    await getBudgetList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  async function onViewBudgetClick(indice: string | number) {
    setViewItensModal(true);
    setSelectedIndice(indice)
  }

  async function onEmailBudgetClick(indice: string | number, danfeXml: boolean) {
    if(!danfeXml && selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'enviapedid').opcoes.length > 0) {
      setPrintOption(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'enviapedid').opcoes);
    }else {
      setPrintOption([]);
    }
    setIsDanfeXml(danfeXml);
    if (danfeXml) {
      setDanfeModal(true);
    } else {
      setEmailModal(true);
    }
    setSelectedIndice(indice);
  }

  async function handleEffectOrc(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")
    try {
      setEffectOrcLoading(true);
      const { data } = await budgetService.effect(currentBranch.codigo, currentCompany, token, indice)
  
      if (data.status === 'ok') {
        Swal.fire({
          title: 'Sucesso',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

        await getBudgetList(tableCurrentPage, orderBy, tableFilter.filter, tableFilter.statusFilter)
      } else {
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        Swal.fire({
          title: 'OPS...',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      console.log('error', error)
      toast.update(id, {render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setEffectOrcLoading(false);
    }
  }

  function handleActionDataTable(action: string, indice: string | number) {
    setSelectedIndice(indice);
    const useValidationByClientStatusInBudgetCreate = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInBudgetCreate' && policy.policy_value === 'true');
    const useValidationByClientStatusInBudgetUpdate = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInBudgetUpdate' && policy.policy_value === 'true');
    const useValidationByClientStatusInBudgetDelete = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInBudgetDelete' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInBudgetUpdate = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInBudgetUpdate' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInPrint = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInPrint' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInSendEmail = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInSendEmail' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInDanfeXml = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInDanfeXml' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInBudgetDelete = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInBudgetDelete' && policy.policy_value === 'true');
    const useValidationByClientStatusInBudgetEffective = companyPolicies.find(policy => policy.property === 'useValidationByClientStatusInBudgetEffective' && policy.policy_value === 'true');
    const useValidationByBudgetStatusInBudgetEffective = companyPolicies.find(policy => policy.property === 'useValidationByBudgetStatusInBudgetEffective' && policy.policy_value === 'true');
    const showBranchExchangeMessage = companyPolicies.find(policy => policy.property === 'showBranchExchangeMessage' && policy.policy_value === 'true');
    
    if(useValidationByClientStatusInBudgetCreate && action === 'add') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInBudgetCreate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você inclua um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByBudgetStatusInBudgetUpdate && action === 'edit') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInBudgetUpdate.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do orçamento selecionado não permite que você altere um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByClientStatusInBudgetUpdate && action === 'edit') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInBudgetUpdate.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você altere um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByClientStatusInBudgetDelete && action === 'delete') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInBudgetDelete.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você exclua um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByBudgetStatusInBudgetDelete && action === 'delete') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInBudgetDelete.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do orçamento selecionado não permite que você exclua esse orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByClientStatusInBudgetEffective && action === 'effect') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByClientStatusInBudgetEffective.dependencie).policy_value.split(';'), clientLegends, selectedClient)) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do cliente selecionado não permite que você efetive um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }      
    }

    if(useValidationByBudgetStatusInBudgetEffective && action === 'effect') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInBudgetEffective.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status do orçamento selecionado não permite que você efetive um orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByBudgetStatusInPrint && action === 'print') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInPrint.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse orçamento não permite que você faça a impressão do orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByBudgetStatusInSendEmail && action === 'email') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInSendEmail.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse orçamento não permite que você faça o envio de orçamento por email!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if(useValidationByBudgetStatusInDanfeXml && action === 'xml') {
      if(utils.statusValidate(companyPolicies.find(policy => policy.policy === useValidationByBudgetStatusInDanfeXml.dependencie).policy_value.split(';'), tableLegend, tableData.find(data => data.indice === indice))) {
        return Swal.fire({
          title: 'Atenção!',
          text: 'O Status desse orçamento não permite que você faça o envio do danfeXml do orçamento!',
          icon: 'info',
          confirmButtonText: 'Ok'
        })
      }
    }

    if (action === 'edit' || action === 'copy' || action === 'effect') {
      const selectedRow = tableData.find(item => item.indice === indice)
      if (selectedRow.cj_filial && currentBranch.codigo !== selectedRow.cj_filial) {
        if(branches.find(branch => branch.codigo === selectedRow.cj_filial)) {
          if (showBranchExchangeMessage) {
            Swal.fire({
              title: 'Atenção!',
              text: `Filial alterada para ${branches.find(branch => branch.codigo === selectedRow.cj_filial).nome}!`,
              icon: 'info',
              confirmButtonText: 'Ok'
            })
          }
          setCookie('current_branch', JSON.stringify(branches.find(branch => branch.codigo === selectedRow.cj_filial)))
          dispatch(updateCurrentBranch({ value: branches.find(branch => branch.codigo === selectedRow.cj_filial) }))
        } else {
          return Swal.fire({
            title: 'Atenção!',
            text: 'Você não tem acesso a Filial desse orçamento!',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
        }
      }
    }

    switch (action) {
      case 'add':
        dispatch(createOrEditOrderOrBudget({ indice: '', isEditing: false, isCopying: false, type: 'budget' }))
        navigate('/cart');
        break;
      case 'edit':
        dispatch(createOrEditOrderOrBudget({ indice: indice, isEditing: true, isCopying: false, type: 'budget' }))
        navigate('/cart');
        break;
      case 'copy':
        setSelectedIndice(indice);
        setSelectedClientModal(true)
        break;
      case 'view':
       onViewBudgetClick(indice);
       break;
      case 'filter':
        setFilterModal(true);
        break;
      case 'email':
        onEmailBudgetClick(indice, false);
        break;
      case'delete':
        if(configuracoes.useconfirmationorder) {
          return Swal.fire({
            title: 'Confirmar ação?',
            text: 'Você deseja excluir esse orçamento!',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, confirmar!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              handleDeleteBudget(indice);
            }
          });
        } else {
          handleDeleteBudget(indice);
        }
        break;
      case 'xml':
        onEmailBudgetClick(indice, true);
        break;
      case 'print': 
        setIsDanfeXml(false);
        if(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'print').opcoes.length > 0) {
          setPrintModal(true);
          setPrintOption(selectedMenu.submenu.find(submenu => submenu.nome.toLowerCase() === 'print').opcoes)
        }else {
          handlePrint(indice, '')
        }
        break;
      case 'effect': 
        if(!effectOrcLoading) {
          if(configuracoes.useconfirmationorder) {
            return Swal.fire({
              title: 'Confirmar ação?',
              text: 'Você deseja efetivar esse orçamento!',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim, confirmar!',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                handleEffectOrc(indice);
              }
            });
          } else {
            handleEffectOrc(indice);
          }
        }
        break;
      case 'log':
        const selectedRow = tableData.find(item => item.indice === indice)
        dispatch(updateConsultationType({ value: selectedMenu.nome }))
        dispatch(updateSelectedProductToConsultation({ value: selectedRow }))
        setConsultationModalVisible(true);
        break;
      default:
        console.log(action)
        break;
    }
    }

  useEffect(() => {
    dispatch(resetDataTable())
    
    if (useInitialFilterBudget) {
      dispatch(updateTableFilter({ value: { filter: undefined, statusFilter: initialFilterBudget } }))
    }

    getBudgetList(tableCurrentPage, orderBy, undefined, initialFilterBudget)
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<ClipboardText size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Datatable
              serviceName="wsorcamento"
              onItensPerPageChange={() => getBudgetList(1, orderBy, tableFilter.filter, tableFilter.statusFilter)}
              onPageChange={(page) => getBudgetList(page, orderBy, tableFilter.filter, tableFilter.statusFilter)}
              onOrderBy={(value) => orderBudget(value)}
              onSearch={ (value, noDebounce) => getBudgetsWithTextSearch(noDebounce ? 'enter' : '', value) }
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterBudget(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => handleActionDataTable(action, indice)}
            />
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />


      {
        viewItensModal &&
        <ViewBudgetItensModal
          indice={selectedIndice}
          onClose={(reload) => {
            if (reload) {
              getBudgetList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterBudget);
            }
            setViewItensModal(false)
          }}
          visible={viewItensModal}
          mode="view"
        />
      }

      <ProductDetailModal />

      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterBudget}
        onClose={() => {
          setFilterModal(false)
        }}
      />

      <EmailModal
        visible={emailModal}
        onClose={() => setEmailModal(false)}
        handleSendEmail={(email, type) => handleSendThroughEmail(email, selectedIndice, type)}
        options={printOptions}
        isSendEmail={isSendEmail}
      />

      <SendModal
        visible={danfeModal}
        onClose={() => setDanfeModal(false)}
        handleSend={ (email, sendType, type) => handleDanfeXml(email, selectedIndice, sendType, type) }
        isSend={isSendEmail}
        options={ printOptions }
      />

      <SelectedClientModal 
        visible={selectedClientModal}
        onClose={() => setSelectedClientModal(false)}
        onConfirm={() => {
          dispatch(createOrEditOrderOrBudget({ indice: selectedIndice, isEditing: false, isCopying: true, type: 'budget' }))
          navigate('/cart');
        }}
      />

      <PrintModal
        visible={printModal}
        onClose={() => setPrintModal(false)}
        handleConfirmationAction={(type) =>  { handlePrint(selectedIndice, type); setPrintModal(false); } }
        options={printOptions}
        isPrinting={isSendEmail}
      />

      <ConsultationModal 
        consultationServiceName="wsconsgener"
        visualizationOnly={true}
        visible={consultationModalVisible}
        onClose={() => setConsultationModalVisible(false)}
      />
    </PageDefault>
  );
}


export default Budgets;