import { createSlice } from '@reduxjs/toolkit'

import { IFillFieldAndFieldValuesActions, IUpdateAllFieldValues, IUpdateFieldValues,  IUpdatePropertyField, IUpdateToOldOPropertyField } from './actions';
import { IRestField } from '../../interfaces/Fields/IRestField';
import { FieldValues } from '../../@types/Restfield';

export interface RestFieldReducerInitialState {
  fields: IRestField[];
  fieldValues: FieldValues;
  gridFields: IRestField[];
  gridFieldValues: FieldValues;
  fieldsStorage: IRestField[];
}

export const restFieldSlice = createSlice({
  name: 'restField',
  initialState: { 
    fields: ([] as IRestField[]), 
    fieldValues: ({} as FieldValues), 
    gridFields: ([] as IRestField[]), 
    gridFieldValues: ({} as FieldValues), 
    fieldsStorage: ([] as IRestField[]) 
  },
  reducers: {
    fillFieldAndFieldValues: (state, action: IFillFieldAndFieldValuesActions) => {
      let fieldsValues = {}

      action.payload.fields.map(field => {
        if (field.conteudo !== '') {
          fieldsValues = { ...fieldsValues, [field.nome]: field.conteudo }
        }
      })
      
      state.fieldValues = fieldsValues;
      state.fields = action.payload.fields;
      state.fieldsStorage = action.payload.fields;
    },

    updateFieldValues: (state, action: IUpdateFieldValues) => {
      state.fieldValues = { ...state.fieldValues, [action.payload.name]: action.payload.value }
    },

    updateAllFieldValues: (state, action: IUpdateAllFieldValues) => {
      state.fieldValues = { ...state.fieldValues, ...action.payload.value }
    },

    fillGridFieldAndFieldValues: (state, action: IFillFieldAndFieldValuesActions) => {
      let fieldsValues = {}

      action.payload.fields.map(field => {
        if (field.conteudo !== '') {
          fieldsValues = { ...fieldsValues, [field.nome]: field.conteudo }
        }
      })
      state.gridFieldValues = fieldsValues;
      state.gridFields = action.payload.fields;
    },

    updateGridFieldValues: (state, action: IUpdateFieldValues) => {
      state.gridFieldValues = { ...state.gridFieldValues, [action.payload.name]: action.payload.value }
    },

    updateAllGridFieldValues: (state, action: IUpdateAllFieldValues) => {
      state.gridFieldValues = { ...state.gridFieldValues, ...action.payload.value }
    },

    clearAll: (state) => {
      state.fieldValues = {}
      state.fields = []
    },

    clearFieldValues: (state) => {
      state.fieldValues = {}
    },

    updatePropertyField: (state, action: IUpdatePropertyField) => {
      action.payload.fields.map(fieldName => {
        if (state.fields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)) {
          state.fields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property] = action.payload.value
        } 
      })
    },

    updateToOldPropertyField: (state, action: IUpdateToOldOPropertyField) => {
      action.payload.fields.map(fieldName => {
        if (state.fields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)) {
          state.fields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property]  = state.fieldsStorage.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property] 
        } 
      })
    },

    updatePropertyGridField: (state, action: IUpdatePropertyField) => {
      action.payload.fields.map(fieldName => {
        state.gridFields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property] = action.payload.value
      })
    },

    updateToOldPropertyGridField: (state, action: IUpdateToOldOPropertyField) => {
      action.payload.fields.map(fieldName => {
        state.gridFields.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property]  = state.fieldsStorage.find(field => field.nome === `${action.payload.alias}_${fieldName}`)[action.payload.property] 
      })
    }
  }
})

export const { 
  fillFieldAndFieldValues, 
  updateFieldValues, 
  updateAllFieldValues, 
  clearAll, 
  clearFieldValues, 
  updatePropertyField, 
  updateToOldPropertyField,

  fillGridFieldAndFieldValues, 
  updateGridFieldValues, 
  updateAllGridFieldValues, 
  updatePropertyGridField, 
  updateToOldPropertyGridField 
} = restFieldSlice.actions;
export default restFieldSlice.reducer;