import React, { FC } from 'react';
import { FieldTypes } from '../../../@types/Restfield';

// import { Container } from './styles';

interface IDatatableFieldProps {
  fieldName: string;
  fieldValue: string | number;
  // fieldType: 'character' | 'numeric' | 'date';
  // fieldSubType: FieldTypes;
  // format?: string;
  // currency?: 'BRL' | 'USD' | 'EUR';
  // decimalPlates?: number;
}

const DatatableField: FC<IDatatableFieldProps> = ({ fieldName, fieldValue }) => {
  if (String(fieldValue).includes('http://') || String(fieldValue).includes('https://') ) {
    return (
      <a key={ fieldName } href={String(fieldValue)} target='_blank'>
        {
          fieldValue
        }
      </a>
    );
  } else {
    return (
      <span key={ fieldName }>
        {
          fieldValue
        }
      </span>
    );
  }
}

export default DatatableField;