import { AxiosError, AxiosResponse } from "axios";
import { IUploadFile } from "../../interfaces/Attach/Attach";
import protheusProvider from "../providers/protheus.provider";


export class AttachService {
  public async index(branchId: string, authToken: string, indice: string | number, searchKey: string | number, typeReg: string, companyId: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get('wsregfile', {
        params: {
          branchId,
          companyId,
          indice,
          searchKey,
          token: authToken,
          typeReg
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async upload(branchId: string, companyId: string, objectParams: IUploadFile): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wspostarc/api/wspostarc/${branchId}`, objectParams, { params: { companyId } });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async delete(branchId: string, companyId: string, payload: IUploadFile): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.delete(`wspostarc/api/wspostarc/${branchId}`, {data: payload, params: { companyId }});
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async open(table: string, description: string, indice: string, fileName: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsretarc/${table}/${description}/${indice}/${fileName}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}