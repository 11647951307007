import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;

export const QuantityInput = styled.input`
  width: 6rem;
  text-align: center; 
  outline: 0;
  border: 0;
  background: transparent;
  -webkit-appearance: none;

  &:hover {
    border-bottom-width:  2px;
    border-bottom-color: #ddd;
  }

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

export const CatalogProductContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  algin-items: center;
  justify-content: space-between;
`;