import axios, { AxiosInstance } from "axios";
import { Utils } from "../../common/Utils";

const utils = new Utils();

class HelperProvider {
  public axiosInstance: AxiosInstance;

  public url = {
    hml: 'https://apiqahelper.ethosx.com.br',
    prod: 'https://apihelper.ethosx.com.br',
  }

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: utils.getEnvironment() === 'Produção' ? this.url.prod : this.url.hml,
      headers: {
        'Accept-Language': 'pt-BR'
      }
    });
  }
}

export default new HelperProvider().axiosInstance;

