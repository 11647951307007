import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

// import logoBranca from '../../assets/images/logobranca.png';
import logoEthosx10 from '../../assets/images/pdf/10_anos_vetorizado.png';
import backgroundPDF from '../../assets/images/pdf/backgroundpdf.jpg'
import robotoRegular from '../../assets/fonts/Roboto-Regular.ttf'
import robotoMedium from '../../assets/fonts/Roboto-Medium.ttf'
import { styleSheet } from '../../assets/styles/global';
import { ISchedule } from '../../interfaces/ServiceOrder/ISchedule';
import moment from 'moment';

interface Props {
  data: ISchedule;
  name: string;
  signature: string;
}

Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: robotoRegular
})

Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: robotoMedium,
  fontWeight: 'bold'
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: styleSheet.shape,
    fontFamily: 'Roboto',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  backgroundImage: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  headerLateral: {
    width: '37.6%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff6f1'
  },
  logo: {
    width: 250,
    height: 250,
  },
  companyDescription: {
    width: '90%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
  },
  textCompanyDescription: {
    fontSize: 12,
    color: '#014a73',
    marginTop: 5,
  },
  body: {
    width: '62.4%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-around',
    paddingVertical: 80,
  },
  attendance: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  client: {
    width: '49%',
  },
  title: {
    fontSize: 16,
    color: '#F4A034',
    fontWeight: 'bold'
  },
  attendanceContent: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
  },
  attendanceText: {
    fontSize: 10,
    color: '#014a73',
    marginTop: 5,
  },
  detailing: {
    marginTop: 20,
    width: '90%',
    marginBottom: 40,
  },
  detailingContent: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 20,
  },
  detailingText: {
    fontSize: 10,
    color: '#014a73',
    textAlign: 'justify',
    margin: 5,
    lineHeight: 1.6,
    wordBreak: 'break-all',
  },

  signaturesContainer: {
    width: '100%',
    height: 150,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  clientSignature: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 200,
    height: 1,
    backgroundColor: '#111',
    marginBottom: 20,
  },
  clientName: {
    color: '#014a73',
    fontSize: 12,
  },
  analystSignature: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  analystName: {
    color: '#014a73',
    fontSize: 12,
  },
  bold: {
    fontWeight: 'bold'
  }
});

// Create Document Component
const PdfOs: React.FC<Props> = ({ data, name, signature }) => {
  return (
    <>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Image fixed style={styles.backgroundImage} src={backgroundPDF} />
          <View style={styles.container}>

            <View fixed style={styles.headerLateral}>
              <Image style={styles.logo} src={logoEthosx10} />

              <View style={styles.companyDescription}>
                <Text style={[styles.textCompanyDescription, { fontWeight: 'bold', fontSize: 16 }]}>Ordem de Serviço - {data.os}</Text>
                 <Text style={styles.textCompanyDescription}>Ethosx consultoria LTDA - 15.688.141/0001-16</Text> 
                <Text style={styles.textCompanyDescription}>Rua Cantagalo, 2589 2º andar</Text>
                <Text style={styles.textCompanyDescription}>Tatuape - 03319-002 - São Paulo - SP</Text>
                <Text style={styles.textCompanyDescription}>(11) 2918-2365</Text>
              </View>
            </View>

            <View style={styles.body}>
              <View style={styles.attendance}>
                <View style={styles.client}>
                  <Text style={styles.title}>Cliente</Text>
                  <View style={styles.attendanceContent}>
                    <Text style={[styles.attendanceText, { alignSelf: 'center' }]}>{data.nomecli}</Text>
                    <Text style={styles.attendanceText}><Text style={styles.bold}>Tel</Text>: {data.telcli}</Text>
                    <Text style={styles.attendanceText}><Text style={styles.bold}>Contato</Text>: {data.contato}</Text>
                    <Text style={styles.attendanceText}><Text style={styles.bold}>Projeto</Text>: {data.projeto}</Text>
                  </View>
                </View>

                <View style={styles.client}>
                  <Text style={styles.title}>Atendimento</Text>
                  <View style={styles.attendanceContent}>
                    <Text style={styles.attendanceText}>{name.trim()}</Text>
                    <Text style={styles.attendanceText}>{moment(data.data).format('DD/MM/YYYY')}</Text>
                    <Text style={styles.attendanceText}>{data.horaini} até {data.horafim} | {data.local}</Text>
                    <Text style={styles.attendanceText}><Text style={styles.bold}>Total</Text>: {data.horatot} | <Text style={styles.bold}>Desconto</Text>: {data.desconto}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.detailing}>
                <Text style={styles.title}>Detalhamento</Text>
                <View style={styles.detailingContent}>
                  <Text style={styles.detailingText}>{data.tarefa?.replaceAll('\\n', '\r\n').substring(0, 1800)}</Text>
                </View>
              </View>

              {
                data.tarefa!.length > 1801 && (
                  <View style={styles.detailing}>
                    <Text style={styles.title}>Detalhamento</Text>
                    <View style={styles.detailingContent}>
                      <Text style={styles.detailingText}>{data.tarefa?.replaceAll('\\n', '\r\n').substring(1801, 2800)}</Text>
                    </View>
                  </View>
                )
              }

              <View style={[styles.signaturesContainer, { marginTop: data.tarefa!.length > 550 ? 70 : 30 }]}>
                <View style={styles.clientSignature}>
                  <View style={styles.line} ></View>
                  <Text style={styles.clientName}>{data.nomecli}</Text>
                </View>

                <View style={styles.analystSignature}>
                  {
                    !!signature && (
                      <Image source={signature} />
                    )
                  }
                  <View style={styles.line} ></View>
                  <Text style={styles.analystName}>{name.trim()}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

export default PdfOs