import styled from "styled-components";

interface IButtonTable {
  color: string;
}

export const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
`;

export const ButtonTable = styled.button<IButtonTable>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;
