import styled from "styled-components";

export const Container = styled.div`
  width:100%;
  padding: 20px;
`;

export const LabelInput = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2rem;
`;

