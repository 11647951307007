import { Accordion, Button, Dimmer, Divider, Form, Icon, Input, Label, Loader, Modal, Table } from 'semantic-ui-react';
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ProductService } from '../../services/methods/ProductService';
import { LogsService } from '../../services/methods/LogsService';

import { CartContext, dictionaryEXC6, dictionaryEXCK } from '../../contexts/CartContext';

import DatatableRestField from '../RestField/DatatableRestField';
import SubMenuOptions from '../SubMenuOptions';
import RestField from '../RestField';
import { IRestField } from './../../interfaces/Fields/IRestField';

import { RestFieldReducerInitialState } from '../../reducers/RestField';
import { CartReducerInitialState, updateDictionaryC6, updateDictionaryCK, updateItemsCount, updateGridList, updateSearchItemSelected, updateSearchOptions, updateSearchOptionsFilter, updateSearchOptionsHasMore, updateSearchOptionsPage, updateSearchProductSelected, resetSearchOptionsSelected, updateDeleteProductList } from '../../reducers/Cart';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';

import { InputRadio, LabelText, Mandatory, Title } from './styles';
import { Utils } from '../../common/Utils';
import { Minus, Plus, ShoppingCart } from 'phosphor-react';
import InputSearch from '../InputSearch';
import { resetDataTable, updateTableLoading, updateTableSearching } from '../../reducers/DataTable';
import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { UIContext } from '../../contexts/UIContext';
import { IErrorProps } from '../Error';
import { debounce } from 'lodash';
import { TableData } from '../../@types/Datatable';

export interface IViewCheckoutModalProps {
  visible: boolean;
  onClose(): void;
  onClearCart(): void;
}

const ViewCheckoutModal: React.FC<IViewCheckoutModalProps> = ({ visible, onClose, onClearCart }) => {
  const [totalTaxes, setTotalTaxes] = useState({});
  const [itensTaxes, setItensTaxes] = useState<object[]>([]);
  const [itensTaxesTitle, setItensTaxesTitle] = useState<string[]>([]);

  const navigate = useNavigate();

  const productService: ProductService = new ProductService();
  const logsService: LogsService = new LogsService();
  const utils = new Utils();

  const { productList, gridList, itemsCount,  indice, isEditing, isCopying, updateItens, searchProductSelected, deleteProductList, priceTable, searchOptions, dictionaryC6, dictionaryCK, itemFields , searchOptionsPage, searchOptionsFilter } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { currentBranch, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies, companyName } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { selectedMenu, selectedSubMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token, user, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const dispatch = useDispatch();

  const useValidationMinOrderValue = companyPolicies.find(policy => policy.property === 'useValidationMinOrderValue' && policy.policy_value === 'true');
  const useSeparateProductInputSearch = companyPolicies.find(policy => policy.property === 'useSeparateProductInputSearch' && policy.policy_value === 'true');
  const useGrid = companyPolicies.findIndex(policy => policy.property === 'useGrid' && policy.policy_value === 'true') !== -1;
  const accordionsCloseByDefault = companyPolicies.find(police => police.property === 'accordionsCloseByDefault' && police.policy_value === 'true')
  
  const {
    handleGetTotalPrice,
    handleAddNewProduct
  } = useContext(CartContext);
  const { setUIComponentState } = useContext(UIContext);

  const [accordionHeaderActive, setAccordionHeaderActive] = useState(false);
  const [itensActive, setItensActive] = useState(accordionsCloseByDefault ? false : true);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalTaxes, setOpenModalTaxes] = useState(false);

  async function registerOrderLog(type: 'Pedido' | 'Orçamento', num: string, cartJson: string, total: number, quantity: number) {
    await logsService.createOrderLog(companyName, user, type, num, cartJson, total, quantity)
  }

  async function handleCreateOrderOrBudget() {
    try {
      let valido = true
      let msg = ''
      const table = selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'
      const cod = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
      let itensList: IRestField[][] = productList;

      if(Object.keys(searchProductSelected).length > 0) {
        await Swal.fire({
          icon: 'info',
          title: 'Você tem um item preenchido mas não adicionado!',      
          text: `Deseja adicioná-lo ao ${selectedMenu.nome === 'Pedidos' ? 'pedido' : 'orçamento'} ?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          confirmButtonColor: '#21ba45',
          cancelButtonColor: '#e0e1e2',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if(result.isConfirmed) {
            itensList = await handleAddNewProduct();
          }
        })
      }

      if(!useGrid && !itensList) return;

      if(itensList.length === 0) {
        return Swal.fire({
          icon: 'info',
          title: 'Atenção',
          html: 'Nenhum item selecionado!',
        })
      }
      
      fields.forEach((field) => {
        if (field.obrigado === "S") {
          if (isEditing || isCopying) {
            if ((fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') && field.conteudo === '') {
              valido = false
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          } else {
            if (fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') {
              valido = false
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          }
        }
      })

      const gridProductList = []
      if (useGrid) {
        gridList.map((grid, index) => {
          grid.linha.map(line => {
            line.coluna.map(column => {
              const fieldsWithData = {}
              if (grid['b4_grdfix'] === 'NAO' || grid['b4_grdfix'] === 'N' || grid['b4_grdfix'] === 'Nao' ) {
                itemFields.map(field => {
                  if (field.nome === 'C6_ITEM' || field.nome === 'CK_ITEM') {
                    fieldsWithData[field.nome] = column.item ? column.item : '&&'
                  } 
                  if (field.nome === 'C6_DESCRI' || field.nome === 'CK_DESCRI') {
                    fieldsWithData[field.nome] = column.nome
                  } 
                  if (field.nome === 'C6_PRODUTO' || field.nome === 'CK_PRODUTO') {
                    fieldsWithData[field.nome] = column.produto
                  } 
                  if (field.nome === 'C6_QTDVEN' || field.nome === 'CK_QTDVEN') {
                    fieldsWithData[field.nome] = column.quantidade
                  } 
                  if (field.nome === 'C6_PRCVEN' || field.nome === 'CK_PRCVEN') {
                    fieldsWithData[field.nome] = grid['b4_prv1']
                  } 
                  if (field.nome === 'C6_ENTREG' || field.nome === 'CK_ENTREG') {
                    fieldsWithData[field.nome] = itensList[index].find(itemField => (itemField.nome === 'C6_ENTREG' || itemField.nome === 'CK_ENTREG')).conteudo || field.conteudo
                  } 
                })
                if (column.quantidade === 0 && isEditing) {
                  gridProductList.push({
                    [selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM']: column.item,
                    'AUTDELETA': 'S'
                  })
                }
                
                if (column.quantidade > 0) {
                  gridProductList.push(fieldsWithData)
                }
              } else {
                itemFields.map(field => {
                  if (field.nome === 'C6_ITEM' || field.nome === 'CK_ITEM') {
                    fieldsWithData[field.nome] = column.item ? column.item : '&&'
                  } 
                  if (field.nome === 'C6_DESCRI' || field.nome === 'CK_DESCRI') {
                    fieldsWithData[field.nome] = column.nome
                  } 
                  if (field.nome === 'C6_PRODUTO' || field.nome === 'CK_PRODUTO') {
                    fieldsWithData[field.nome] = column.produto
                  }
                  if (field.nome === 'C6_QTDVEN' || field.nome === 'CK_QTDVEN') {
                    fieldsWithData[field.nome] = column.quantidade * line.quantidade
                  }
                  if (field.nome === 'C6_PRCVEN' || field.nome === 'CK_PRCVEN') {
                    fieldsWithData[field.nome] = grid['b4_prv1']
                  } 
                  if (field.nome === 'C6_ENTREG' || field.nome === 'CK_ENTREG') {
                    fieldsWithData[field.nome] = itensList[index].find(itemField => (itemField.nome === 'C6_ENTREG' || itemField.nome === 'CK_ENTREG')).conteudo || field.conteudo
                  } 
                })
                if (column.quantidade * line.quantidade === 0 && isEditing) {
                  gridProductList.push({
                    [selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM']: column.item,
                    'AUTDELETA': 'S'
                  })
                }
                if (column.quantidade * line.quantidade > 0) {
                  gridProductList.push(fieldsWithData)
                }
              }
            })
          })
        })
      }

      let deletedItensArray = [...deleteProductList]
  
      const itens = useGrid ? gridProductList : itensList.map(product => {
        let productAlreadyAdded = false
  
        const productFormatted = {}
        product.map(field => {
          if (field.conteudo !== '' && field.visual !== 'D') {
            productFormatted[field.nome] = field.conteudo
          }
        })
  
        if (isEditing) {
          // Verifica se e um produto novo add ou ja estava adicionado
          updateItens.map(item => {
            if (product.find(field => field.nome === cod).conteudo === item[cod.toLowerCase()]) {
              
              productAlreadyAdded = true
              if (!productFormatted[selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'] || productFormatted[selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'] === '&&') {
                productFormatted[selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'] = item[selectedMenu.nome === 'Pedidos' ? 'c6_item' : 'ck_item']
              }
              
              if (deletedItensArray.find(deletedItem => deletedItem[selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'] === item[selectedMenu.nome === 'Pedidos' ? 'c6_item' : 'ck_item'])) {
                deletedItensArray = deletedItensArray.filter(deletedItem => deletedItem[selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'] !== item[selectedMenu.nome === 'Pedidos' ? 'c6_item' : 'ck_item'])
              }
            }
          })

          if (productAlreadyAdded) {
            return {
              ...productFormatted,
            }
          } else {
            return {
              ...productFormatted,
              [table]: "&&"
            }
          }
        } else {
          return {
            ...productFormatted,
            [table]: "&&"
          }
        }
      })
   
      const fieldValuesToSend = utils.despiseFieldsValue(fields, fieldValues)
      // const itensFieldValuesToSend = utils.despiseItensFieldsValue(itemFields, itens)
  
      let objectParams;
      if (isEditing && deletedItensArray.length > 0) {
        objectParams = {
          ...fieldValuesToSend,
          itens: [...itens, ...deletedItensArray]
        }
      } else {
        objectParams = {
          ...fieldValuesToSend,
          itens,
        }
      }

      if(useValidationMinOrderValue) {
        if(handleGetTotalPrice() < Number(selectedClient.a1_xclas)) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'Valor do pedido abaixo do mínimo para faturamento.',
          })
        }
      }

      if (!valido) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
          <br>Campo(s) obrigatório(s) não preenchido(s)<br/>
            <ul>
              ${msg}
            </ul>
          `,
        })
      }

      setIsLoading(true)
      const url = selectedMenu.nome === 'Pedidos' ? 'wspedido' : 'wsorcamento'
      if (isEditing && !isCopying) {
        const { data, request, status } = await productService.update(url, String(currentCompany), currentBranch.codigo, token, String(selectedClient.indice), objectParams, indice)
        const useMsgWithOrderNumberInOrderUpdate = companyPolicies.find(policy => policy.property === 'useMsgWithOrderNumberInOrderUpdate' && policy.policy_value === 'true');
  
        if (status === 201 || status === 200) {
          if(useMsgWithOrderNumberInOrderUpdate && selectedMenu.nome === 'Pedidos') {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Pedido de número ${data.numero} foi alterado com sucesso`,
            })
          }else{
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: data.msg.replaceAll('\r\n', '<br>'),
            })
          }
          
          dispatch(updateItemsCount({ value: 0 }))
          dispatch(updateGridList({ value: [] }))
          dispatch(resetDataTable())
          navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets')
        } else {
          console.log(data, status, request)
          Swal.fire({
            icon: 'error',
            title: 'OPS...',
            html: data.errorMessage.replaceAll('\r\n', '<br>'),
          })
        }
      } else {
        const { data, request, status } = await productService.create(url, String(currentCompany), currentBranch.codigo, token, String(selectedClient.indice), objectParams)
        const useMsgWithOrderNumberInOrderCreate = companyPolicies.find(policy => policy.property === 'useMsgWithOrderNumberInOrderCreate' && policy.policy_value === 'true');
  
        if (status === 201 || status === 200) {
          if(useMsgWithOrderNumberInOrderCreate && selectedMenu.nome === 'Pedidos' && !isEditing) {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: `Pedido de número ${data.numero} foi gerado com sucesso`,
            })
          }else{
            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              html: data.msg.replaceAll('\r\n', '<br>'),
            })
          }
          dispatch(updateItemsCount({ value: 0 }))
          dispatch(updateGridList({ value: [] }))
          dispatch(resetDataTable())
          navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets')
          const total = handleGetTotalPrice()
          registerOrderLog(selectedMenu.nome === 'Pedidos' ? 'Pedido' : 'Orçamento', data.num, JSON.stringify(objectParams), parseFloat(total.toFixed(2)), itemsCount)
  
        } else {
          console.log(data, status, request)
          Swal.fire({
            icon: 'error',
            title: 'OPS...',
            html: data.errorMessage.replaceAll('\r\n', '<br>'),
          })
        }
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
      dispatch(resetSearchOptionsSelected());
    }
  }

  async function handleGetTaxes() {
    try {
      let valido = true
      let msg = ''
      const table = selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM'
      const cod = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
      
      fields.forEach((field) => {
        if (field.obrigado === "S") {
          if (isEditing || isCopying) {
            if ((fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') && field.conteudo === '') {
              valido = false
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          } else {
            if (fieldValues[field.nome] === undefined || fieldValues[field.nome] === '') {
              valido = false
              msg += `<li><strong>${field.titulo}</strong></li>`
            }
          }
        }
      })
  
      const itens = productList.map(product => {
        let productAlreadyAdded = false
  
        const productFormatted = {}
        product.map(field => {
          if (field.conteudo !== '') {
            productFormatted[field.nome] = field.conteudo
          }
        })
  
        
        if (isEditing) {
          // Verifica se e um produto novo add ou ja estava adicionado
          updateItens.map(item => {
            if (product.find(field => field.nome === cod).conteudo === item[cod.toLowerCase()]) {
              productAlreadyAdded = true
            }
          })
          if (productAlreadyAdded) {
            return {
              ...productFormatted,
            }
          } else {
            return {
              ...productFormatted,
              [table]: "&&"
            }
          }
        } else {
          return {
            ...productFormatted,
            [table]: "&&"
          }
        }
      })
  
      let objectParams;
      if (isEditing && deleteProductList.length > 0) {
        objectParams = {
          ...fieldValues,
          itens: [...itens, ...deleteProductList]
        }
      } else {
        objectParams = {
          ...fieldValues,
          itens,
        }
      }

      if(useValidationMinOrderValue) {
        if(handleGetTotalPrice() < Number(selectedClient.a1_xclas)) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: 'Valor do pedido abaixo do mínimo para faturamento.',
          })
        }
      }
  
      if (!valido) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `
          <br>Campo(s) obrigatório(s) não preenchido(s)<br/>
            <ul>
              ${msg}
            </ul>
          `,
        })
      }

      setIsLoading(true)
 
      const process = selectedMenu.nome === 'Pedidos' ? 'PED' : 'ORC'
      const { data, status } = await productService.getTaxes(process, String(currentCompany), currentBranch.codigo, token, String(selectedClient.indice), objectParams);
  
      if(status === 200 || status === 201) {
        setTotalTaxes(data.total);
        setItensTaxes(data.itens);
        setItensTaxesTitle(Object.keys(data.itens[0]));
  
        setOpenModalTaxes(true);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false);
    }

  }

  const getProductsSearch = async (page: number, filters?: string | undefined,  statusFilter?: { [key: string]: string } | undefined) => {
    try {
      dispatch(updateTableLoading({ value: true }));
      dispatch(updateSearchOptionsPage({ value: page }));

      const { data, status, request } = await productService.index(token, 10, page, selectedClient.indice.toString(), currentBranch.codigo, '', '', useGrid, filters,  statusFilter, priceTable);
      const { dados,  dicionary, hasNext }: IPaginatedServiceResponse = data;
  
      if (status === 200) {
        if(page !== 1) {
          dispatch(updateSearchOptions({ value: [...new Set([...searchOptions , ...dados.filter(data => !searchOptions.includes(data))])] }));
        }else {
          dispatch(updateSearchOptions({ value: dados }));

          dispatch(updateDictionaryC6({ value: {
            ...dicionary.SC6,
            ...dictionaryEXC6
          } }))
          dispatch(updateDictionaryCK({ value: {
            ...dicionary.SCK,
            ...dictionaryEXCK
          } }))
        }

        dispatch(updateSearchOptionsHasMore({ value: hasNext }));

      } else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }))
    }
  }

  const getProductsWithTextSearch = useCallback(debounce(async (searchValue: string) => {
    // Load
    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService([{type: "text", name: "B1_COD"}, {type: "text", name: "B1_DESC"}], ["B1_COD", "B1_DESC"], "contains", searchValueText);
      dispatch(updateSearchOptionsFilter({ value: searchString }));
      await getProductsSearch(1, searchString);
    }
    else {
      dispatch(updateSearchOptionsFilter({ value: '' }));
      await getProductsSearch(1);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), []);

  function handleSelectProduct(product: TableData) {
    dispatch(updateSearchProductSelected({ value: product }));
    const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
    const itemField = selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM';
    const item = itemFields.map(field => {
      if(field.nome === itemField) {
        return {
          ...field,
          conteudo: '&&'
        }
      }
      return {
        ...field,
        conteudo: product[dictionary[field.nome]] ? product[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''
      }
    });

    dispatch(updateSearchItemSelected({ value: item }));
    dispatch(updateSearchOptions({ value: [] }));
  }

  return (
    <>
    <Modal
      style={{ fontFamily: styleSheet.mainFont }}
      onClose={() => !isLoading && onClose()}
      closeOnDimmerClick={false}
      open={visible}
      size='fullscreen'
      closeIcon
    >
      <Modal.Header style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <ShoppingCart size={22} color={styleSheet.mainColor} /> 
        Checkout de {selectedMenu.descri}
      </Modal.Header>
      {
        isLoading ?
          <Modal.Content fluid>
            <Dimmer active>
              <Loader size="big" inverted>Carregando</Loader>
            </Dimmer>
          </Modal.Content>

          :
          <Modal.Content  scrolling>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form style={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', flexGrow: '1' }}> 
              {
                useSeparateProductInputSearch && 
                <Form.Field style={{ width: '40%' }}>
                  <LabelText>Pesquisa de itens</LabelText>
                   <InputSearch
                      onSearch={(value) => { 
                        getProductsWithTextSearch(value); 
                      }}
                      nextPage={async () => { getProductsSearch(searchOptionsPage + 1, searchOptionsFilter)}}
                      onSelect={(product) => handleSelectProduct(product)}
                    />
                </Form.Field>
              }
              </Form>
              <Label style={{ borderRadius: '8px' }} color="green" size="massive">
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(handleGetTotalPrice())}
              </Label>
            </div>

            <Divider />

            <Accordion style={{ marginTop: '2rem', boxShadow: '0 0 3px 2px #dedede', border: '1px solid #f6f6f6' }} fluid styled>
              <Accordion.Title
                active={accordionHeaderActive}
                onClick={() => setAccordionHeaderActive(!accordionHeaderActive)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f6f6f6' }}
              >
                <Title>Cabeçalho de {selectedMenu.descri} <Mandatory>{fields.find(field => field.obrigado === 'S') ? '*' : ''}</Mandatory></Title>
                {
                  accordionHeaderActive ? <Minus size={22} color={styleSheet.mainColor} />  : <Plus size={22} color={styleSheet.mainColor}  />
                }
              </Accordion.Title>  
              <Accordion.Content active={accordionHeaderActive}>
                <RestField  mode="add" />
              </Accordion.Content>
            </Accordion>

            <Accordion style={{ marginTop: '2rem', boxShadow: '0 0 3px 2px #dedede', border: '1px solid #f6f6f6', borderBottom: '1px solid #f6f6f6' }} fluid styled >
              <Accordion.Title
                active={itensActive}
                onClick={() => setItensActive(!itensActive)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #f6f6f6'  }}
              >
                <Title>Itens do {selectedMenu.descri}</Title>
                {
                  itensActive ? <Minus size={22} color={styleSheet.mainColor} />  : <Plus size={22} color={styleSheet.mainColor}  />
                }
              </Accordion.Title>
              <Accordion.Content style={{ minHeight: '100%' }} active={itensActive} >
                <DatatableRestField />    
              </Accordion.Content>
            </Accordion>
          </Modal.Content>
      }

      <Modal.Actions>
        <SubMenuOptions
          subMenuSelected={selectedSubMenu}
          type="text"
          onOptionClick={(action) => {
            if(action === 'taxes') handleGetTaxes();
          }}
        />
        <Button.Group  style={{ marginLeft: '1rem' }}>
        <Button size="big" onClick={onClearCart}>Limpar</Button>
        <Button.Or text="ou" />  
        <Button size="big" color='green' onClick={handleCreateOrderOrBudget}>Confirmar</Button>  {/* {isEditing ? 'Alterar ' : 'Criar '} {selectedMenu.nome === 'Pedidos' ? 'Pedido' : 'Orçamento'}</Button> */}
        </Button.Group>   
      </Modal.Actions>
    </Modal>


    <Modal
       onClose={() => setOpenModalTaxes(false)}
       open={openModalTaxes}
       size='fullscreen'
       style={{ fontFamily: styleSheet.mainFont, height: '85%' }}
       closeIcon
    >
      <Modal.Content scrolling>
  
        <Title>TOTAIS DO PEDIDO</Title>

        <Table size="large" style={{ marginTop: '20px' }} celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>PEDIDO</Table.HeaderCell>
              <Table.HeaderCell>IPI</Table.HeaderCell>
              <Table.HeaderCell>ICMS</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>{totalTaxes['pedido']}</Table.Cell>
              <Table.Cell>{totalTaxes['ipi']}</Table.Cell>
              <Table.Cell>{totalTaxes['icms']}</Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
     

        <Title>ITENS</Title>

        <Table size="large" style={{ marginTop: '20px' }} celled>
          <Table.Header>
            <Table.Row>
              {
                itensTaxesTitle.map(title => (
                  <Table.HeaderCell>{title}</Table.HeaderCell>
                ))
              }
             
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              itensTaxes.map(item => (
                <Table.Row>
                  {
                    itensTaxesTitle.map(title => (
                      <Table.Cell>{item[title]}</Table.Cell>
                    ))
                  }    
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        
      </Modal.Content>

    </Modal>
    </>
  );
}

export default ViewCheckoutModal;