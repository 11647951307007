import React, { FC } from 'react';
import { ShoppingCart as CartIcon, CaretLeft, CaretRight } from 'phosphor-react';
import { Button, Card, Label, Placeholder } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { IProduct } from '../../interfaces/Product/IProduct';

import { addNewProductQuantity, CartReducerInitialState, updateQuantityValue } from '../../reducers/Cart';
import { DataTableReducerInitialState } from '../../reducers/DataTable';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { CatalogProductContainer, QuantityInput } from './styles';

import { styleSheet } from '../../assets/styles/global';
import defaultProductImg from '../../assets/images/default-product.jpeg';

interface ICatalogProps {
  onNextProduct(): void;
  onPreviewProduct(): void;
  onOpenCart(): void;
  productIndex: number;
}

const Catalog: FC<ICatalogProps> = ({ productIndex, onNextProduct, onPreviewProduct, onOpenCart }) => {
  const { itemsCount, itemsList, itemFields, dictionaryC6, dictionaryCK } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { tableData, tableLoading } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch();

  const product  = tableData[productIndex] ? tableData[productIndex] : {} as IProduct;
  const productPrice = itemFields.find(field => field.regra === 'prcven') ? product[dictionaryCK[itemFields.find(field => field.regra === 'prcven').nome]] : 0

  const currentItemQuantity = (b1_cod: string | number = product.b1_cod): number => {
    return itemsList.find((item) => item.b1_cod === b1_cod) ? parseFloat(String(itemsList.find((item) => item.b1_cod === b1_cod)?.quantity)) : 0;
  }

  const valueWithConversion = (cod: string | number) => {
    const balance = tableData.find(item => item['b1_cod'] === cod || item['b1_qe'])
    if(balance && (balance['b1_conv'] || balance['b1_qe'])) {
      return Number(balance['b1_conv'] || balance['b1_qe']);
    } else {
      return 1;
    }
  }

  const conversionCalc = (inputValue: number, balance?: string | number): number => {
    const multiplier = balance ? Number(balance) : product.b1_conv ? Number(product.b1_conv) : product.b1_qe ? Number(product.b1_qe) : 0;
    const calc = (inputValue % multiplier);
    if (calc !== 0 && !isNaN(calc)) {
      if (inputValue < multiplier) {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>(${multiplier})</b>. O valor foi alterado para o mínimo - <b>(${multiplier})</b>`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return multiplier;
      } else {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>(${multiplier})</b>. O valor foi alterado para o mínimo - <b>(${inputValue - calc})</b>`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return inputValue - calc;
      }
    }

    return inputValue;
  }
  
  const handleChangeQuantityProductValue = (value: number | string, cod: string | number) => {

    if(isNaN(Number(value))) {
      value = 0
    }

    if (Number(value) < 0) {
      dispatch(updateQuantityValue({ value: 0 }))
      return 
    }

    const newProduct = tableData.find((item) => item.b1_cod === cod)
    const productAlreadyAdded = itemsList.find(product => product.b1_cod === cod)

    if (productAlreadyAdded) {
      const otherProducts = itemsList.filter(product => product.b1_cod !== productAlreadyAdded.b1_cod)
      const itensCount = itemsCount - Number(productAlreadyAdded.quantity)
      
      if (value === 0) {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + value, itemsList: [...otherProducts] }))

      } else {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + parseFloat(value.toString()), itemsList: [...otherProducts, { ...productAlreadyAdded, quantity: value, discount: 0, increase: 0 }] }))
      }

    } else {
      dispatch(addNewProductQuantity({ itemsCount: itemsCount + parseFloat(value.toString()), itemsList: [...itemsList, { ...newProduct, quantity: value, discount: 0, increase: 0 }] }))
    }
  }

  return (
    <>
      <CatalogProductContainer>
        <CaretLeft 
          style={{ alignSelf: 'center', cursor: productIndex > 0 ? 'pointer' : 'not-allowed', opacity: productIndex > 0 ? 1 : 0.4 }} 
          size={40} 
          onClick={ productIndex > 0 ? onPreviewProduct : null}
        />
          {
            tableLoading ? (
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
            ) : (
              <div style={{ width: '93%', margin: '20px 0', height: '100%' }}>
                <Label size="big">{productIndex + 1}/{tableData.length}</Label>
                <Card style={{ width: '100%', height: '100%' }}>
                  <img 
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
                    src={product['b1_ximg'] ? String(product['b1_ximg']) : defaultProductImg} 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = defaultProductImg;
                    }}
                  />

                  <Card.Content textAlign="center">
                  <Label color="green" size="massive" style={{ marginRight: '1rem' }}>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(productPrice))}
                  </Label>
                    <Button 
                      circular
                      size='big'
                      negative
                      onClick={() => handleChangeQuantityProductValue((Number(currentItemQuantity(product['b1_cod'])) - valueWithConversion(product['b1_cod'])).toFixed(2), product['b1_cod'])}
                      icon="minus"
                      style={{ background: styleSheet.mainColor }}
                    />
                  
                    <QuantityInput
                      type="number"
                      maxLength={6}
                      max={999}
                      min={0}
                      // transparent
                      value={currentItemQuantity(product['b1_cod']) || "0"}
                      onChange={event => handleChangeQuantityProductValue(parseFloat(event.target.value), product['b1_cod'])}
                      onBlur={event => handleChangeQuantityProductValue(conversionCalc(parseFloat(event.target.value), product['b1_conv'] || product['b1_qe']).toFixed(2), product['b1_cod'])}
                    />
                    <Button
                      circular
                      size='big'
                      positive
                      onClick={() => handleChangeQuantityProductValue((Number(currentItemQuantity(product['b1_cod'])) + valueWithConversion(product['b1_cod'])).toFixed(2), product['b1_cod'])}
                      icon="plus"
                      style={{ background: styleSheet.mainColor }}
                    />
                  </Card.Content>
                </Card>
              </div>
            )
          }
        <CaretRight 
          style={{ alignSelf: 'center', cursor: productIndex < tableData.length - 1 ? 'pointer' : 'not-allowed', opacity: productIndex < tableData.length - 1 ? 1 : 0.4 }} 
          size={40} 
          onClick={ productIndex < tableData.length - 1 ? onNextProduct : null}
        />
      </CatalogProductContainer>
      <Label
        as="a"
        size='massive'
        circular
        style={{ position: 'absolute', right: '2.4rem', bottom: '3rem', width: '4.5rem', height: '4.5rem', backgroundColor: styleSheet.mainColor }}
        onClick={onOpenCart}
      >
        <CartIcon color="#fff" />
        <Label style={{ position: 'absolute', top: '-10px', right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: styleSheet.contrastColor, color: styleSheet.shape }}  size="big" circular floating>
          <span style={{ fontSize: '1.1rem' }}>{itemsCount}</span>
        </Label>
      </Label>
    </>
  );
}

export default Catalog;