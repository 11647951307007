import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class DashboardService {
  public async getGroups(authToken: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsrestestru/PO/${authToken}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getGraph(funcao: string, filter: object, authToken: string, groupName: string, filterName?: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wsrestpainel`, {
        token: authToken,
        funcao: filterName ? filterName : funcao,
        filtro: {
          ...filter,
          grupo: groupName
        },
      })

      return response;

    } catch (ex) {
      const { response } = ex as AxiosError
      return response
    }
  }

}

