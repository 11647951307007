import React, { FC, createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultTheme } from "styled-components";
import { useCookies } from "react-cookie";
import { encode } from "base-64";
import Swal from "sweetalert2";

import protheusProvider from "../services/providers/protheus.provider";
import { SettingsService } from "../services/methods/SettingsService";

import {
  PolicieReducerInitialState,
  selectedCompany,
  updateAuthToken,
  updateBackgroundColor,
  updateBackgroundType,
  updateCanLogin,
  updateCompanyCustomizations,
  updateCompanyFiles,
  updateCompanyPolicies,
  updateCompanyRest,
  updateCompanyStyles,
  updateCustomizations,
  updateMobileBackgroundType,
  updatePoliciesDropdown,
  updatePoliciesList,
} from "../reducers/Policie";

import { styleSheet } from "../assets/styles/global";
import { updateTableLoading } from "../reducers/DataTable";
import { updateItensPerPage } from "../reducers/Environment";

export interface ICustomizations {
  [property: string]: string;
}

interface IPolicieContext {
  theme: DefaultTheme;
  getToken(): Promise<void>;
  getCompanyPolicies(): Promise<void>;
  getPolicies(): Promise<void>;
  getCompanyStyles(): Promise<void>;
  getCompanyCustomization(): Promise<void>;
  getCompanyFiles(): Promise<void>;
}

export const PolicieContext = createContext<IPolicieContext>(
  {} as IPolicieContext
);

const PolicieContextProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState<DefaultTheme>(styleSheet);

  const { authToken, companyId, companyName } = useSelector(
    (state: { policie: PolicieReducerInitialState }) => state.policie
  );
  const dispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies(["url_rest"]);

  const settingService: SettingsService = new SettingsService();

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (authToken !== "") getCompany();
  }, [authToken]);

  useEffect(() => {
    if (companyId) {
      getCompanyPolicies();
      getCompanyCustomization();
      getCompanyStyles();
      getCompanyFiles();
    }
  }, [companyId]);

  async function getToken(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const encodeUser = encode("ethosx");
      const encodePassword = encode("3th0sx");
      const {
        status,
        data: { token, error },
      } = await settingService.auth(encodeUser, encodePassword);
      if (status !== 200)
        throw (
          error || `Falha não esperada ao buscar token, status code: ${ status }`
        );

      dispatch(updateAuthToken({ value: token }));
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  async function getCompany(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const url = window.location.href.split("/")[2].includes("localhost")
        ? "drastosa.pyli.com.br"
        : window.location.href.split("/")[2];
      const {
        status,
        data: {
          error,
          data: { id, name, environment, deleted_at },
        },
      } = await settingService.getCompany(url, authToken);

      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar dados da empresa, status code: ${ status }`
        );
      dispatch(
        selectedCompany({
          companyId: id,
          companyEnvironment: environment,
          companyName: name,
          companyStatus: deleted_at === null ? 'active' : 'inactive'
        })
      );
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  async function getPolicies(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const {
        status,
        data: { error, data },
      } = await settingService.getPolicies(authToken);
      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar as policies da empresa, status code: ${ status }`
        );
      dispatch(updatePoliciesList({ value: data }));
      dispatch(
        updatePoliciesDropdown({
          value: data.map((policy) => {
            return {
              key: `${ policy.id }`,
              value: `${ policy.id }`,
              text: `${ policy.property } - ${ policy.description }`,
            };
          }),
        })
      );
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  async function getCompanyPolicies(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const {
        status,
        data: { error, policies },
      } = await settingService.getCompanyPolicies(companyId, authToken);
      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar as policies da empresa, status code: ${ status }`
        );

      dispatch(updateCompanyPolicies({ value: policies }));
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  async function getCompanyCustomization(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const {
        status,
        data: { error, policies },
      } = await settingService.getCompanyCustomizations(companyId, authToken);
      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar as policies da empresa, status code: ${ status }`
        );

      dispatch(
        updateCompanyCustomizations({
          value: policies.map((customization) => {
            return { type: "boolean", ...customization };
          }),
        })
      );

      const customizationsObject = policies.reduce((prev, current) => {
        if (current.device !== "mobile") {
          Object.assign(prev, {
            [current.property]: current.customization_value,
          });
        }

        return prev;
      }, {});
      
      dispatch(updateCustomizations({ value: customizationsObject }));
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));

    }
  }

  async function getCompanyStyles(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const {
        status,
        data: { error, styles },
      } = await settingService.getCompanyStyles(companyId, authToken);
      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar as policies da empresa, status code: ${ status }`
        );

      dispatch(updateCompanyStyles({ value: styles }));

      if (styles.find((style) => style.property === "urlRest")) {
        protheusProvider.defaults.baseURL = styles.find(
          (style) => style.property === "urlRest"
        ).style_value;
        setCookie(
          "url_rest",
          styles.find((style) => style.property === "urlRest").style_value
        );
        dispatch(
          updateCompanyRest({
            value: styles.find((style) => style.property === "urlRest")
              .style_value,
          })
        );
      }

      if (
        styles.find(
          (style) =>
            style.property === "primaryColor" && style.style_value !== ""
        )
      ) {
        styleSheet.mainColor = styles.find(
          (style) => style.property === "primaryColor"
        ).style_value;
      }
      if (
        styles.find(
          (style) =>
            style.property === "secondaryColor" && style.style_value !== ""
        )
      ) {
        styleSheet.contrastColor = styles.find(
          (style) => style.property === "secondaryColor"
        ).style_value;
      }

      if (
        styles.find(
          (style) =>
            style.property === "backgroundColor" && style.style_value !== ""
        )
      ) {
        dispatch(
          updateBackgroundColor({
            value: styles.find((style) => style.property === "backgroundColor")
              .style_value,
          })
        );
      }

      if (styles.find((style) => style.property === "backgroundType")) {
        dispatch(
          updateBackgroundType({
            value: styles.find((style) => style.property === "backgroundType")
              .style_value,
          })
        );
      }

      if (styles.find((style) => style.property === "mobileBackgroundType")) {
        dispatch(
          updateMobileBackgroundType({
            value: styles.find(
              (style) => style.property === "mobileBackgroundType"
            ).style_value,
          })
        );
      }

      setTheme(styleSheet);
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
      dispatch(updateCanLogin({ value: true }));

    }
  }

  async function getCompanyFiles(): Promise<void> {
    try {
      dispatch(updateTableLoading({ value: true }));

      const {
        status,
        data: { error, data },
      } = await settingService.getCompanyFiles(companyId, authToken);
      if (status !== 200)
        throw (
          error ||
          `Falha não esperada ao buscar os arquivos da empresa, status code: ${ status }`
        );

      dispatch(updateCompanyFiles({ value: data }));
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo deu errado!",
      });
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  return (
    <PolicieContext.Provider
      value={ {
        theme,
        getToken,
        getCompanyPolicies,
        getPolicies,
        getCompanyStyles,
        getCompanyFiles,
        getCompanyCustomization,
      } }
    >
      { children }
    </PolicieContext.Provider>
  );
};

export default PolicieContextProvider;
