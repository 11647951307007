import styled, { css } from "styled-components";

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
`;

export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;

export const TableColumnText = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;
