import { Modal, Icon, Button, Accordion, Table, Dimmer, Loader, Image } from 'semantic-ui-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';
import { exportToExcel } from 'react-json-to-excel';

import { BudgetService } from '../../services/methods/BudgetService';
import { FieldService } from '../../services/methods/FieldService';

import { CartContext } from '../../contexts/CartContext';
import { UIContext } from '../../contexts/UIContext';

import { IErrorProps } from '../Error';
import RestField from '../RestField';

import { IPaginatedItensServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { IRestField } from '../../interfaces/Fields/IRestField';
import { FormMode } from '../../@types/generic';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { fillFieldAndFieldValues } from '../../reducers/RestField';
import { AuthReducerInitialState } from '../../reducers/Auth';

import defaultProductImg from '../../assets/images/default-product.jpeg';
import { styleSheet } from '../../assets/styles/global';

import { Utils } from '../../common/Utils';

import { Title } from './styles';
import { useNavigate } from "react-router";
import { MenuReducerInitialState } from "../../reducers/Menu";
import moment from "moment";


export interface IViewBudgetItensModalProps {
  indice: string | number;
  visible: boolean;
  mode: FormMode;
  onClose(reload: boolean): void;
}

const ViewBudgetItensModal: React.FC<IViewBudgetItensModalProps> = ({ indice, mode, visible, onClose }) => {
  const fieldService: FieldService = new FieldService();
  const budgetService: BudgetService = new BudgetService();

  const { currentBranch, currentCompany, selectedRow } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const useExcelExportBrowser = useMemo(() => companyPolicies.find(policy => policy.property === 'useExcelExportBrowser' && policy.policy_value === 'true'), [companyPolicies]);

  const dispatch = useDispatch();

  const utils = new Utils();

  const { setUIComponentState } = useContext(UIContext);
  const {
    handleOpenProductDetail
  } = useContext(CartContext);

  const [tableFields, setTableFields] = useState<IRestField[][]>([]);
  const [totalFields, setTotalFields] = useState<IRestField[][]>([]);
  const [tableFieldsTitle, setTableFieldsTitle] = useState<string[]>([]);
  const [headerActive, setHeaderActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  async function getBudgetFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: 'SCJ',
        alias2: 'SCK',
        url: '',
        tab: '1',
        usuario: '',
        indice: String(indice),
        ordem: 1,
        token: token,
      }

      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        await Promise.all([getBudgetDataList(data.campos), getBudgetItensList(data.campos1[Object.keys(data.campos1)[0]])])
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      if (error.response.data.errorCode === 403) {
        onClose(true)        
        return Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: error.response.data.errorMessage,
        })
      } else {
        console.log('error', error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado!',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function getBudgetDataList(fields: IRestField[]) {
    try {
      const response = await budgetService.getData(currentCompany, currentBranch.codigo, token, indice)
      const { dados } = response.data;
      if (response.status === 200) {
        const fieldsWithValues = fields.map(field => {
          const value = dados[0][field.nome.toLowerCase()]
          field.conteudo = value
          field.visual = "V"
          return field
        })
        dispatch(fillFieldAndFieldValues({ fields: fieldsWithValues }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getBudgetItensList(fieldsTable: IRestField[]) {
    try {
      const response = await budgetService.getItens(currentCompany, currentBranch.codigo, token, selectedRow.indice);
      const { dados, totalizadores } = response.data as IPaginatedItensServiceResponse;

      if (response.status === 200) {

        const tableFieldsWithValue: IRestField[][] = []

        dados.map((item: { [x: string]: string | number; }) => {
          const fields: IRestField[] = fieldsTable.map(field => {
            return {
              ...field,
              conteudo: item[field.nome.toLowerCase()]
            }
          })


          tableFieldsWithValue.push(fields)

        })
        const totalFieldsWithValue: IRestField[][] = []

        const totalFields: IRestField[] = fieldsTable.map(field => {
          return {
            ...field,
            conteudo: totalizadores.totRegistros[field.nome]
          }
        })
        totalFieldsWithValue.push(totalFields)

        const tableFieldsWithTitle = fieldsTable
          .filter(({ tipo, titulo }) => titulo && utils.validationFormField(tipo, mode))
          .map(item => item.titulo)


        setTableFieldsTitle(tableFieldsWithTitle)
        setTableFields(tableFieldsWithValue)
        setTotalFields(totalFieldsWithValue)
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const handleExportDataToExcel = useCallback(() => {
    const result = tableFields.map(fields => {
      const formattedData = {}
      tableFieldsTitle.map(title => {
        if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
          formattedData[title] = fields.find(field => field.titulo === title).conteudo
        }
  
      })
      return formattedData
    }) 

    const totalizers = totalFields.map(fields => {
      const formattedData = {}
      tableFieldsTitle.map(title => {
        if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
          formattedData[title] = fields.find(field => field.titulo === title).type === 'currency' 
            ? `R$ ${utils.convertBrl(fields.find(field => field.titulo === title).conteudo, Number(fields.find(field => field.titulo === title).decimal))}`
            : fields.find(field => field.titulo === title).conteudo
        }
  
      })
      return formattedData
    }) 

    exportToExcel([...result, ...totalizers], `Orçamento-${indice}-${moment().format('DD-MM-YYYY')}`)
  }, [tableFields, tableFieldsTitle, totalFields])

  useEffect(() => {
    setIsLoading(true)
    getBudgetFields()
  }, [indice])

  return (
    <Modal
      onClose={ () => !isLoading && onClose(false) }
      open={ visible }
      size='fullscreen'
      style={ { fontFamily: styleSheet.mainFont } }
      closeIcon
    >
      <Modal.Header>
        Visualização de Orçamento
      </Modal.Header>
      <Dimmer active={ isLoading } inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Modal.Content scrolling>
        <Accordion fluid>
          <Accordion.Title
            active={ headerActive }
            index={ 0 }
            onClick={ () => setHeaderActive(!headerActive) }
          >
            <Icon name='dropdown' />
            <Title>Cabeçalho</Title>
          </Accordion.Title>
          <Accordion.Content active={ headerActive }>
            <RestField mode={ mode } />
          </Accordion.Content>

        </Accordion>
        <Title>Itens</Title>
        <Table size="large" celled>
          <Table.Header>
            <Table.Row>
              {
                tableFieldsTitle.length && tableFieldsTitle.map(title => {
                  return (
                    <Table.HeaderCell>{ title }</Table.HeaderCell>
                  )
                }
                )
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              tableFields.length && tableFields.map(fields => (
                <Table.Row>
                  {
                    tableFieldsTitle.map(title => {

                      if (fields.find(field => field.titulo === title).nome.includes('_XIMG')) {
                        return (
                          <Table.Cell
                            width={ 1 }

                            onClick={ () => {
                              if (fields.find(field => field.titulo === title).nome.includes('_XIMG')) {
                                handleOpenProductDetail(fields.find(field => field.titulo === title).conteudo !== "" ? String(fields.find(field => field.titulo === title).conteudo) : defaultProductImg)
                              }
                            } }
                          >
                            <Image
                              size="mini"
                              src={ fields.find(field => field.titulo === title).conteudo || defaultProductImg }
                              onError={ ({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = defaultProductImg;
                              } }
                            />
                          </Table.Cell>
                        )
                      } else {
                        return (
                          <Table.Cell>
                            {
                              fields.find(field => field.titulo === title).type === 'currency' &&
                              `R$ ${ utils.convertBrl(fields.find(field => field.titulo === title).conteudo, Number(fields.find(field => field.titulo === title).decimal) || 2) }`
                            }

                            {
                              fields.find(field => field.titulo === title).type !== 'currency' &&
                              fields.find(field => field.titulo === title).conteudo
                            }
                          </Table.Cell>
                        )
                      }

                    })
                  }
                </Table.Row>
              ))
            }

            {
              totalFields.map(fields => (
                <Table.Row>
                  {
                    tableFieldsTitle.map(title => {
                      return (
                        <Table.Cell>
                          {
                            fields.find(field => field.titulo === title).type === 'currency' &&
                            `R$ ${ utils.convertBrl(fields.find(field => field.titulo === title).conteudo, Number(fields.find(field => field.titulo === title).decimal) || 2) }`
                          }

                          {
                            fields.find(field => field.titulo === title).type !== 'currency' &&
                            fields.find(field => field.titulo === title).conteudo
                          }
                        </Table.Cell>
                      )
                    })
                  }
                </Table.Row>
              ))
            }
          </Table.Body>

        </Table>
      </Modal.Content>

      <Modal.Actions>
        {
          useExcelExportBrowser ? (
            <Button 
              style={ { height: '3rem', fontSize: '1.1rem', backgroundColor: styleSheet.mainColor } } 
              color='green' 
              size='massive' 
              onClick={handleExportDataToExcel}
            >
              Excel
            </Button>
          ) : null
        }
        <Button size="big" onClick={ () => onClose(false) }>Cancelar</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ViewBudgetItensModal;