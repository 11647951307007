import styled, { css } from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;

export const Groups = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const DefaultLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 45%;
  font-size: 1.3rem !important;
`;

export const QuantityContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem;
  text-align: center;
`;

export const QuantityInput = styled.input`
  width: 6rem;
  text-align: center; 
  outline: 0;
  border: 0;
  background: transparent;
  -webkit-appearance: none;

  &:hover {
    border-bottom-width:  2px;
    border-bottom-color: #ddd;
  }

  ::-webkit-inner-spin-button, 
  ::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  overflow-x: auto;
  display: flex;
  margin-top: 1.5rem; 
  box-shadow: 0 0 3px 2px #dedede;
`;

export const TableContainerGrid = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem; 
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #d9dadc;
  border-radius: 8px;
  margin-right: 1rem;
`;

export const LegendStatus = styled.legend`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 0.5rem;
`;

interface ILegendsProps {
  color: string;
  isActive: boolean;
}

export const Legend = styled.button<ILegendsProps>`
  border: 0;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.lighterGrey_200}; //${styleSheet.lighterGrey_200};
  border-radius: 5rem;
  ${({ isActive, color }) => isActive && css`
    border: 2px solid ${color};
  `}
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
  cursor: pointer;
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
`;

export const LegendInitialName = styled.span<ILegendsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  border-radius: 5rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
`;

export const LegendName = styled.span`
  font-size: 1.2rem;
  padding: 0.5rem;
`;