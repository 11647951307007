import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  margin-bottom: 30px;
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;
