import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 2rem;
  display: inline-block;
  text-align: left;
`;

export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;


