import { Button, Container, Dropdown, DropdownItem, DropdownMenu, Icon, Input, Label, Menu, Pagination, Popup, Select, Table } from 'semantic-ui-react';
import { Barcode, Code, Upload, CalendarPlus, Password, Eye, Trash, Printer, Pencil, Envelope, Copy, Question, Check, Checks, ChartLine, X  } from 'phosphor-react';

import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { exportToExcel } from 'react-json-to-excel';

import { SelectedContainer } from '../Browser/styles';
import DatatableSubMenu from '../DatatableSubMenu';
import DatatableLoad from './DatatableLoad';

import { ISubmenuItem } from '../../interfaces/Menu/IMenu';
import DatatableField from './DatatableField';

import { EnvironmentReducerInitialState, updateItensPerPage, updateOrderBy, updateSelectedClient, updateSelectedRow } from '../../reducers/Environment';
import { MenuReducerInitialState, updateSelectedSubMenu } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';
import { ButtonTable, Checkbox, CheckMark, Fieldset, LegendsStatusContainer, LegendStatus, StatusGroup, TableColumnText, TableContainer, TableDataText, TotalizerCircle, Totalizers, TotalizersText, TotalizersTitle } from './styles';
import { DataTableReducerInitialState, updateTableCurrentPage, updateTableSearching } from '../../reducers/DataTable';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { useCookies } from 'react-cookie';
import moment from "moment";
import { AuthReducerInitialState } from "../../reducers/Auth";
import protheusProvider from "../../services/providers/protheus.provider";
import { AxiosError } from "axios";
import { IPaginatedServiceResponse } from "../../interfaces/Datatable/IPaginatedServiceResponse";
import { toast } from "react-toastify";
import { Utils } from '../../common/Utils';

const IconsMenu = {
  Alterar: <Pencil size={ 20 } color="#A15F7F" />,
  AlterarOrc: <Pencil size={ 20 } color="#A15F7F" />,
  Visualizar: <Eye size={ 20 } color="#6FCCF1" />,
  Excluir: <Trash size={ 20 } color="#E47261" />,
  Upload: <Upload size={ 20 } color="#4A485F" />,
  'Danfe/XML': <Code size={ 20 } color="#84686D" />,
  Boleto: <Barcode size={ 20 } color="#4AAF9A" />,
  Efetivar: <CalendarPlus size={ 20 } color="#648D56" />,
  EfetivaOrc: <CalendarPlus size={ 20 } color="#648D56" />,
  ResetSenha: <Password size={ 20 } color="#F8AA94" />,
  EnviaPedid: <Envelope size={ 22 } color="#EDA14C" />,
  Print: <Printer size={ 22 } color="#CBBA86" />,
  Copia: <Copy size={ 20 } color="#4AAF9A" />,
  Aprovar: <Check size={ 20 } color="#648D56" />,
  Faturar: <Checks size={ 20 } color="#648D56" />,
  Autorizar: <Check size={ 20 } color="#648D56" />,
  Recusar: <X size={ 20 } color="#E47261" />,
  ConsGener: <ChartLine size={20} color="#F8AA94" />
}

enum ColorsMenu {
  Alterar = 'purple',
  AlterarOrc = 'purple',
  Visualizar = 'blue',
  Excluir = 'red',
  Upload = 'blue',
  'Danfe/XML' = 'violet',
  Boleto = 'teal',
  Efetivar = 'green',
  EfetivaOrc = 'green',
  ResetSenha = 'pink',
  EnviaPedid = 'orange',
  Print = 'yellow',
  Copia = 'teal',
  Aprovar = 'green',
  Faturar = 'green',
  Autorizar = 'green',
  ConsGener = 'pink',
  Recusar = 'red'
}

enum FunctionsMenu {
  Alterar = 'edit',
  AlterarOrc = 'edit',
  Visualizar = 'view',
  Excluir = 'delete',
  Upload = 'upload',
  'Danfe/XML' = 'xml',
  Boleto = 'boleto',
  Efetivar = 'effect',
  EfetivaOrc = 'effect',
  ResetSenha = 'resetPassword',
  EnviaPedid = 'email',
  Print = 'print',
  Copia = 'copy',
  Aprovar = 'approval',
  Faturar = 'invoice',
  Autorizar = 'authorize',
  Recusar = 'refuse',
  ConsGener = 'log'
}

interface IDatatableProps {
  onPageChange(page: number): void;
  onDatatableActionClick(action: string, indice?: string | number): void;
  onSearch?(value: string, noDebounce?: boolean): void;
  onStatusFilter?(value: { [key: string]: string } | undefined): void;
  onOrderBy?(value: string): void;
  onChangePeriod?(date: string): void;
  onItensPerPageChange(): void;
  serviceName?: string;
}


const Datatable: FC<IDatatableProps> = ({ onPageChange, onDatatableActionClick, onSearch, onChangePeriod, onStatusFilter, onOrderBy, onItensPerPageChange, serviceName }) => {
  const { tableLoading, tableTitles, tableHelps, tableOrder, tableData, tableTotalizers, tableLegend, tableTotalPages, tableSearchText, tableFieldFilters, tableCurrentPage, tableFilter } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { selectedClient, selectedRow, orderBy, itensPerPage, currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { pwm: configuracoes, token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const utils = new Utils()

  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies(['selected_client']);

  const isClientTable = selectedMenu.nome === 'Empresas'
  const showLegendFilterInFooter = companyPolicies.find(policy => policy.property === 'showLegendFilterInFooter' && policy.policy_value === 'true');
  const showFinancialTotalizers = companyPolicies.find(policy => policy.property === 'showFinancialTotalizers' && policy.policy_value === 'true');
  const useExcelExportBrowser = useMemo(() => companyPolicies.find(policy => policy.property === 'useExcelExportBrowser' && policy.policy_value === 'true'), [companyPolicies]);
  const useCompressedLegendFilterInFooter = companyPolicies.find(policy => policy.property === 'useCompressedLegendFilterInFooter' && policy.policy_value === 'true');
  const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51


  const [subMenu, setSubMenu] = useState<ISubmenuItem[]>(selectedMenu?.submenu);

  const [changeItensPerPage, setChangeItensPerPage] = useState(0);
  const enterKeyPressed = useRef(false)

  function getLegendColor(legendName: string): string {
    let legendColor = ''
    tableLegend.map(({ dados, campo }) => {
      dados.find(({ nome, cor }) => {
        if (nome === legendName) {
          legendColor = cor
        }
      })
    })

    return legendColor
  }


  function handleOrderBy(column: string) {
    if (tableFieldFilters.character.includes(column)) {
      if (Object.keys(orderBy)[0] === column) {
        switch (orderBy[column]) {
          case "ascending":
            onOrderBy(`${ column } DESC`)
            dispatch(updateOrderBy({ value: { [column]: "descending" } }))
            break;
          case "descending":
            onOrderBy(``)
            dispatch(updateOrderBy({ value: { [column]: null } }))

            break;
          case null:
            onOrderBy(`${ column } ASC`)
            dispatch(updateOrderBy({ value: { [column]: "ascending" } }))

            break;
        }
      } else {
        onOrderBy(`${ column } ASC`)
        dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
      }
    }

  }

  function handleCleanInputSearch() {
    document.querySelector<HTMLInputElement>('#inputSearch').value = ''
    onSearch('', true);
  }

  const handleExportFilteredDataToExcel = useCallback(() => {
    const jsonData = tableData.map(data => {
      const formattedData = {}
      tableOrder.map((item) => {
        formattedData[tableTitles[item]] = data[item.toLowerCase()]
      })

      return formattedData
    }) 

    exportToExcel(jsonData, `${selectedMenu.descri}-${moment().format('DD-MM-YYYY')}`)
  }, [tableData, tableTitles])

  const handleExportAllDataToExcel = useCallback(async () => {
    const id = toast.loading("Exportando dados....")

    try {
      const response = await protheusProvider.get(`${serviceName}`, {
        params: {
          branchId: currentBranch.codigo,
          companyId: currentCompany,
          page: 1,
          pageSize: 99999999,
          token: token,
          order: '',
          filter: '',
          whereapiadp:""
        }
      });

      const { titulo, dados, ordem, oculto } = response.data as IPaginatedServiceResponse;

      const ordemArray = ordem.filter(campo => !oculto.find(oculto => oculto === campo))

      if (response.status === 200) {
        const jsonData = dados.map(data => {
          const formattedData = {}
          ordemArray.map((item) => {
            formattedData[tableTitles[item]] = data[item.toLowerCase()]
          })
    
          return formattedData
        }) 
    
        exportToExcel(jsonData, `${selectedMenu.descri}-${moment().format('DD-MM-YYYY')}`)
        toast.update(id, { render: 'Exportação concluída', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
      } else {
        toast.update(id, { render: 'Algo deu errado na exportação dos dados', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
      }
    }
    catch (ex) {
      toast.update(id, { render: 'Algo deu errado na exportação dos dados', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });

      console.log(ex)
    }
  }, [serviceName])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearch').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (changeItensPerPage > 0) {
      onItensPerPageChange();
    }
  }, [changeItensPerPage])

  return (
    <Container style={ { width: '100%', height: `${ window.innerHeight - 305 }px` } }>
      <Menu style={ { boxShadow: '0 0 3px 2px #dedede' } } borderless>
        <Menu.Item>
          <Input
            id="inputSearch"
            className='icon'
            type="search"
            loading={ tableLoading }
            disabled={ tableLoading }
            icon={ tableSearchText.length !== 0 ? <Icon name='close' link onClick={ handleCleanInputSearch } /> : <Icon name='search' link /> }
            defaultValue={ tableSearchText }
            onChange={ (event) => {  
              dispatch(updateTableSearching({ value: true }))
              onSearch(event.target.value, enterKeyPressed.current);
            } }
            onBlur={(event) => {
              onSearch(event.target.value, enterKeyPressed.current);
            }}
            placeholder='Pesquisar...'
            style={ { width: '40rem' } } />
        </Menu.Item>
        { (tableLegend.length > 0 || tableFieldFilters.numeric.length > 0 || tableFieldFilters.date.length > 0) && (
          <Menu.Item>
            <Button style={ { height: '3rem', fontSize: '1.1rem', backgroundColor: styleSheet.mainColor } } icon labelPosition='right' color='blue' size='massive' onClick={ () => onDatatableActionClick('filter') }>
              Filtros
              <Icon name='filter' />
            </Button>
          </Menu.Item>
        ) }

            <Menu.Item position='right'>
              <DatatableSubMenu />
              {
                subMenu && subMenu.find(menu => (menu.nome.includes('Incluir') || menu.nome.includes('IncluirOrc')) && menu.escopo === 'G') && (
                  <SelectedContainer>
                    <Button icon labelPosition='right' positive size='large' onClick={ () => {
                      onDatatableActionClick('add')
                      dispatch(updateSelectedSubMenu({ value: subMenu.find(sub => sub.nome === 'Incluir' || 'IncluirOrc') }))
                    } }>
                      {subMenu.find(menu => (menu.nome.includes('Incluir'))).descricao || 'Incluir'}
                      <Icon name='plus' />
                    </Button>
                  </SelectedContainer>
                )
              }
            </Menu.Item>

        {
          onChangePeriod ? (
            <Menu.Item position='right'>
              <Input 
                type="month"
                defaultValue={moment().format('YYYY-MM')}
                onChange={ev => onChangePeriod(ev.target.value)}
              />
            </Menu.Item>
          ) : null
        }
       </Menu>

      <TableContainer>
        {
          tableLoading ? <DatatableLoad itensPerPage={ itensPerPage } /> :
            <Table celled compact={customizations['useCompactLineOnBrowserTables'] ? 'very' : true} sortable selectable color='teal'>
              <Table.Header>
                <Table.Row >
                  {
                    tableOrder.map((column) => {
                      return (
                        <Table.HeaderCell
                          key={ column }
                          className='dataHeaderTableFixed'
                          onClick={ () => handleOrderBy(column) }
                          sorted={ orderBy[column] || null }
                        >
                          <TableColumnText>
                            { customizations['useCapitalLetters'] ? tableTitles[column].toUpperCase() : tableTitles[column] }
                            {
                              tableHelps[column] ? (
                                <Popup
                                  style={ { fontWeight: '600' } }
                                  trigger={ <Question size={ 14 } style={ { marginLeft: '0.5rem', marginBottom: '-2px' } } /> }
                                  content={ tableHelps[column] }
                                  size='large'
                                  inverted
                                />
                              ): null
                            }
                          </TableColumnText>
                        </Table.HeaderCell>
                      );
                    })
                  }
                  <Table.HeaderCell className='dataHeaderTableFixedActions' textAlign="center"  key='actions'>
                    <TableColumnText>
                      {customizations['useCapitalLetters'] ? 'AÇÕES' : 'Ações'}
                    </TableColumnText>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  tableData.map((row) => {
                    return (
                      <Table.Row key={ row.indice } onClick={ () => {
                        dispatch(updateSelectedRow({ value: row }))

                        if (isClientTable) {
                          if (selectedClient.indice && row.indice === selectedClient.indice) {
                            dispatch(updateSelectedClient({ value: {} }))
                            setCookie('selected_client', JSON.stringify({}))
                          } else {
                            setCookie('selected_client', JSON.stringify(row))
                            dispatch(updateSelectedClient({ value: row }))
                          }
                        }
                      } } active={ isClientTable ? selectedClient.indice === row.indice : selectedRow.indice === row.indice }>
                        {
                          tableOrder.map((columnName) => {
                            return (
                              <Table.Cell textAlign={tableLegend.find(({ campo }) => campo === columnName) ? 'center' : 'left'}  width={ !!tableLegend.find(({ campo }) => campo === columnName) ? 1 : 2 } key={ columnName }>
                                {
                                  !!tableLegend.find(({ campo }) => campo === columnName) &&
                                  <Label
                                    size="large"
                                    style={ {
                                      background: tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]) ? tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]).cor : '#333',
                                      color: '#fff'
                                    } }
                                  >
                                    {/* {row[columnName.toLowerCase()].toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)} */ }
                                    { row[columnName.toLowerCase()] }
                                  </Label>
                                }
                                {
                                  !tableLegend.find(({ campo }) => campo === columnName) &&
                                  <TableDataText>
                                    <DatatableField
                                      fieldName={ columnName }
                                      fieldValue={ row[columnName.toLowerCase()] }
                                    />
                                  </TableDataText>
                                }
                              </Table.Cell>
                            );
                          })
                        }

                        <Table.Cell className='dataTableFixed' width={ 1 } key="actions">
                          <Button.Group size='small'>
                            {
                              subMenu && subMenu.map(({ nome, descricao, escopo }, index) => (
                                escopo === 'I' && ColorsMenu[nome] !== undefined &&
                                <Popup
                                  key={ `${ nome }-${ index }` }
                                  trigger={
                                    <ButtonTable
                                      color={ ColorsMenu[nome] }
                                      onClick={ () => {
                                        onDatatableActionClick(FunctionsMenu[nome], row.indice)
                                        dispatch(updateSelectedSubMenu({ value: subMenu.find(sub => sub.nome === nome) }))
                                      } }
                                    >
                                      { IconsMenu[nome] }
                                    </ButtonTable>
                                  }
                                  content={ <span style={ { fontSize: '1.3rem' } }>{ descricao }</span> }
                                  inverted
                                />
                              ))
                            }
                          </Button.Group>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                }
              </Table.Body>
            </Table>
        }

      </TableContainer>
      <Menu borderless style={ { boxShadow: '0 0 3px 2px #dedede' } }>
        {
          showLegendFilterInFooter && (
            <Menu.Item>
              {
                tableLegend.map(({ dados, campo }) => {
                  return (
                    <Fieldset key={ campo }>
                      <LegendStatus>
                        { tableTitles[campo] }
                      </LegendStatus>
                      <LegendsStatusContainer>
                        {
                          dados.map(({ nome, cor }) => {
                            return (
                              <StatusGroup
                                key={ nome }
                                cor={ cor }
                                onClick={ () => {
                                  if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
                                    const status = { ...tableFilter.statusFilter };
                                    delete status[campo]
                                    onStatusFilter(status)
                                  } else {
                                    onStatusFilter({ ...tableFilter.statusFilter, [campo]: nome })
                                  }
                                } }
                                isSelected={ tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome }
                              >
                                <Checkbox>
                                  <CheckMark cor={ cor } checked={ tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome } />
                                </Checkbox>

                                { useCompressedLegendFilterInFooter ? nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2) : nome }
                              </StatusGroup>
                            );
                          })
                        }
                      </LegendsStatusContainer>
                    </Fieldset>
                  )
                })
              }
            </Menu.Item>
          )
        }

        
        {
          selectedMenu.descri === 'financeiro' && configuracoes.showtotalfinancial && (
            <Menu.Item>
              <Menu secondary>
                {
                  tableTotalizers.map(total => (
                    <Menu.Item key={total.nome}>
                      <Totalizers color={getLegendColor(total.nome)}>
                        <TotalizerCircle color={getLegendColor(total.nome)}>{total.nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)}</TotalizerCircle>
                        <TotalizersTitle>{total.nome}</TotalizersTitle>
                        <TotalizersText>Saldo - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(total.saldo))}</TotalizersText>
                        <TotalizersText>Total - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(total.valor))}</TotalizersText>
                      </Totalizers>
                    </Menu.Item>
                  ))
                }
              </Menu>
            </Menu.Item>
          )
        }

        {
          !tableLoading &&
          <Menu.Item position="right">
            {
              useExcelExportBrowser ? (
                <Menu.Item position="right">
                  <Dropdown
                    style={ { backgroundColor: styleSheet.mainColor, color: '#FFF' } } 
                    text='Excel'
                    icon="file"
                    floating
                    labeled
                    button
                    className="icon"
                    upward={true}
                  >
                    <DropdownMenu>
                      <DropdownItem text='Exportar dados filtrados' onClick={handleExportFilteredDataToExcel}/>
                      {
                        serviceName ? <DropdownItem text='Exportar todos os dados' onClick={handleExportAllDataToExcel} /> : null
                      }
                      
                    </DropdownMenu>
                  </Dropdown>
                </Menu.Item>
              ) : null
            }
            {
              customizations['useQtdPaginationSelect'] ? (
                <Menu.Item position="right">
                  <Fieldset>
                  <LegendStatus>
                    QTD PAGINA
                    </LegendStatus>
                    <LegendsStatusContainer>
                      <Select
                        placeholder="Quantidade por pagina"
                        defaultValue={ itensPerPage }
                        options={ configuracoes.quantityperpage ? utils.generateItensPerPage(configuracoes.quantityperpage) : utils.generateItensPerPageDefault(window.innerHeight, rowSize) }
                        onChange={ async (event, data) => {
                          dispatch(updateItensPerPage({ value: Number(data.value) }));
                          dispatch(updateTableCurrentPage({ value: 1 }));
                          setChangeItensPerPage(changeItensPerPage + 1);
                        } }
                      />
                    </LegendsStatusContainer>
                  </Fieldset>
                </Menu.Item>
              ) : null
            }
            <Menu floated='right'>
              <Pagination
                // defaultActivePage={currentPage}
                activePage={ tableCurrentPage }
                firstItem={ null }
                lastItem={ null }
                pointing
                secondary
                totalPages={ tableTotalPages }
                onPageChange={ (ev, { activePage }) => onPageChange(activePage as number) }
              />
            </Menu>
          </Menu.Item>
        }
      </Menu>

    </Container>
  );
}

export default Datatable;