import { Button, Modal, Transition, Form, Segment, Divider, Input, Sidebar, Menu } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyInput from 'react-currency-input-field';
import React, { FC, } from 'react';
import moment from 'moment'

import { Utils } from '../../common/Utils';
import { DataTableReducerInitialState, updateTableFilter } from '../../reducers/DataTable';
import { Checkbox, CheckMark, Content, Fieldset, FormGroup, Header, LabelInput, LegendsStatusContainer, LegendStatus, StatusContainer, StatusGroup } from './styles';
import { X } from 'phosphor-react';
import { styleSheet } from '../../assets/styles/global';

interface IFormModalProps {
  visible: boolean;
  filtersValue: IFilter[];
  onClose(): void;
  handleFilter(filter: string | undefined, status: { [key: string]: string | undefined }): void;
  setFiltersValues(filters: IFilter[]): void;
}

export interface IFilter {
  name: string;
  value: string;
  option: string;
}

const FilterModal: FC<IFormModalProps> = ({ visible, filtersValue,  onClose, handleFilter, setFiltersValues }) => {

  const { tableFilter, tableTitles, tableFieldFilters, tableLegend } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const dispatch = useDispatch();

  const utils = new Utils();

  function handleUpdateFilter(name: string, value: string, option: string) {
    const filters = [...filtersValue]
    const filterAlreadyAdded = filters.findIndex(fil => fil.name === name && fil.option === option)
    if (filterAlreadyAdded !== -1) {
      filters.splice(filterAlreadyAdded, 1)
      if(value !== '') {
        setFiltersValues([...filters, { name, value, option }])
      }else{
        setFiltersValues(filters)
      }
    } else {
      setFiltersValues([...filters, { name, value, option }])
    }
  }

  function handleFormatFilter() {
    let filterString = ''
    filtersValue.map((fil, index) => {
      if(tableFieldFilters.numeric.includes(fil.name )) {
        const valor = fil.value.replaceAll('.', '').replace(',', '.')
        if (tableFieldFilters.date.includes(fil.name)) {
          if (index !== filtersValue.length - 1) {
            filterString += `${fil.name} ${fil.option} '${valor}' and `
          } else {
            filterString += `${fil.name} ${fil.option} '${valor}'`
          }
        } else {
          if (index !== filtersValue.length - 1) {
            filterString += `${fil.name} ${fil.option} ${valor} and `
          } else {
            filterString += `${fil.name} ${fil.option} ${valor}`
          }
        }
      }else{
        if (tableFieldFilters.date.includes(fil.name)) {
          if (index !== filtersValue.length - 1) {
            filterString += `${fil.name} ${fil.option} '${fil.value}' and `
          } else {
            filterString += `${fil.name} ${fil.option} '${fil.value}'`
          }
        } else {
          if (index !== filtersValue.length - 1) {
            filterString += `${fil.name} ${fil.option} ${fil.value} and `
          } else {
            filterString += `${fil.name} ${fil.option} ${fil.value}`
          }
        }
      }
      
    })

    handleFilter(filterString, tableFilter.statusFilter)
    onClose()
  }

  function cleanFilters() {
    setFiltersValues([])
    dispatch(updateTableFilter({ value: { ...tableFilter, statusFilter: undefined } }))
    handleFilter(undefined, undefined)
    onClose()
  }

  return (
    // <Transition animation='fly up' visible={visible} unmountOnHide>
    //   <Modal
    //     closeIcon
    //     size='tiny'
    //     onClose={onClose}
    //     open={visible}
    //   >
    //     {/* <Container> */}
    //       <Modal.Header>
    //         <LabelInput>
    //           Filtros Disponíveis
    //         </LabelInput>
    //       </Modal.Header>

    //       <Modal.Content scrolling>
    //         {
    //           tableLegend.length > 0 &&
    //           <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
    //             <LabelInput>
    //               Legendas
    //             </LabelInput>
    //             <Divider />
    //             <StatusContainer>
    //               {
    //                 tableLegend.map(({ dados, campo }) => {
    //                   return (
    //                     <Fieldset key={campo}>
    //                        <LegendStatus>
    //                         {tableTitles[campo]}
    //                       </LegendStatus>
    //                       <LegendsStatusContainer>
    //                         {
    //                           dados.map(({ nome, cor }) => {
    //                             return (
    //                               <StatusGroup
    //                                 key={nome}
    //                                 cor={cor}
    //                                 onClick={() => {
    //                                   if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
    //                                     const status = {...tableFilter.statusFilter};
    //                                     delete status[campo]
    //                                     dispatch(updateTableFilter({ value: { ...tableFilter, statusFilter: status } }))
    //                                   } else {
    //                                     dispatch(updateTableFilter({ value: { ...tableFilter, statusFilter: { ...tableFilter.statusFilter, [campo]: nome } } }))
    //                                   }
    //                                 }}
    //                                 isSelected={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome}
    //                               >
    //                                 <Checkbox>
    //                                   <CheckMark cor={cor} checked={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome} />
    //                                 </Checkbox>

    //                                 {nome}
    //                               </StatusGroup>
    //                             );
    //                           })
    //                         }
    //                       </LegendsStatusContainer>
    //                     </Fieldset>
    //                   )
                      
    //                 })
    //               }
    //             </StatusContainer>
    //           </Segment>
    //         }
    //         {
    //           tableFieldFilters.numeric.length > 0 &&
    //           <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
    //             <LabelInput>
    //               Numéricos
    //             </LabelInput>
    //             <Divider />
    //             <Form >
    //               {
    //                 tableFieldFilters.numeric.map(filterName => (
    //                   <div key ={filterName}>
    //                     <LabelInput>
    //                       {tableTitles[filterName]}
    //                     </LabelInput>
    //                     <FormGroup>
    //                       <Form.Field style={{ width: '49%' }}>
    //                         Valor Min.
    //                         <CurrencyInput
    //                           allowDecimals={true}
    //                           decimalScale={2}
    //                           decimalsLimit={2}
    //                           groupSeparator="."
    //                           decimalSeparator=','
    //                           style={{ height: '4rem' }}
    //                           id={filterName}
    //                           name={filterName}
    //                           max={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
    //                           defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
    //                           value={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
    //                           onValueChange={(value) => handleUpdateFilter(filterName, value, 'ge')}
    //                         />
    //                       </Form.Field>
    //                       <Form.Field style={{ width: '49%' }}>
    //                         Valor Max.
    //                         <CurrencyInput
    //                           allowDecimals={true}
    //                           decimalScale={2}
    //                           decimalsLimit={2}
    //                           groupSeparator="."
    //                           decimalSeparator=','
    //                           style={{ height: '4rem' }}
    //                           id={filterName}
    //                           name={filterName}
    //                           min={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
    //                           defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
    //                           value={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
    //                           onValueChange={(value) => handleUpdateFilter(filterName, value, 'le')}
    //                         />                         
    //                       </Form.Field>
    //                     </FormGroup>
    //                   </div>
    //                 ))
    //               }
    //             </Form>
    //           </Segment>
    //         }

    //         {
    //           tableFieldFilters.date.length > 0 &&
    //           <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
    //             <LabelInput>
    //               Datas
    //             </LabelInput>
    //             <Divider />
    //             <Form>
    //               {
    //                 tableFieldFilters.date.map(filterName => (
    //                   <div key={filterName}>
    //                     <LabelInput>
    //                       {tableTitles[filterName]}
    //                     </LabelInput>
    //                     <FormGroup>
    //                       <Form.Field style={{ width: '49%' }}>
    //                         De
    //                         <Input
    //                           className='inputFilter'
    //                           type="date"
    //                           max={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value).format('YYYY-MM-DD') : ''}
    //                           onChange={e => handleUpdateFilter(filterName, `${moment(e.target.value).format('YYYYMMDD')}`, 'ge')}
    //                           defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value).format('YYYY-MM-DD') : ''}
    //                         />
    //                       </Form.Field>
    //                       <Form.Field style={{ width: '49%' }}>
    //                         Até
    //                         <Input
    //                           className='inputFilter'
    //                           type="date"
    //                           min={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value).format('YYYY-MM-DD') : ''}
    //                           onChange={e => handleUpdateFilter(filterName, `${moment(e.target.value).format('YYYYMMDD')}`, 'le')}
    //                           defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value).format('YYYY-MM-DD') : ''}
    //                         />
    //                       </Form.Field>
    //                     </FormGroup>
    //                   </div>
    //                 ))
    //               }
    //             </Form>
    //           </Segment>
    //         }
    //       </Modal.Content>
    //     {/* </Container> */}

    //     <Modal.Actions>
    //       <Button.Group size='big'>
    //         <Button onClick={cleanFilters}>Limpar Tudo</Button>
    //         <Button.Or text="ou" />
    //         <Button onClick={handleFormatFilter} positive>Filtrar</Button>
    //       </Button.Group>
    //     </Modal.Actions>
    //   </Modal>
    // </Transition >
      <Sidebar
        as={Menu}
        animation='push'
        direction='right'
        icon='labeled'
        vertical
        onHide={onClose}
        visible={visible}
        width='wide'
        style={{ padding: '1rem', display: 'flex', zIndex: 99999, backgroundColor: '#f7f8fb', color: styleSheet.darkerGrey }}
      >
        <Header>
          <LabelInput>
            Filtros Disponíveis
          </LabelInput>

          <X size={22} onClick={onClose} />
        </Header>

        <Content>
          {
            tableLegend.length > 0 &&
            <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
              <LabelInput>
                Legendas
              </LabelInput>
              <Divider />
              <StatusContainer>
                {
                  tableLegend.map(({ dados, campo }) => {
                    return (
                      <Fieldset key={campo}>
                          <LegendStatus>
                          {tableTitles[campo]}
                        </LegendStatus>
                        <LegendsStatusContainer>
                          {
                            dados.map(({ nome, cor }) => {
                              return (
                                <StatusGroup
                                  key={nome}
                                  cor={cor}
                                  onClick={() => {
                                    if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
                                      const status = {...tableFilter.statusFilter};
                                      delete status[campo]
                                      dispatch(updateTableFilter({ value: { ...tableFilter, statusFilter: status } }))
                                    } else {
                                      dispatch(updateTableFilter({ value: { ...tableFilter, statusFilter: { ...tableFilter.statusFilter, [campo]: nome } } }))
                                    }
                                  }}
                                  isSelected={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome}
                                >
                                  <Checkbox>
                                    <CheckMark cor={cor} checked={tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome} />
                                  </Checkbox>

                                  <span style={{ flex: 1 }}>{nome}</span>
                                </StatusGroup>
                              );
                            })
                          }
                        </LegendsStatusContainer>
                      </Fieldset>
                    )
                    
                  })
                }
              </StatusContainer>
            </Segment>
          }
          {
            tableFieldFilters.numeric.length > 0 &&
            <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
              <LabelInput>
                Numéricos
              </LabelInput>
              <Divider />
              <Form >
                {
                  tableFieldFilters.numeric.map(filterName => (
                    <div key ={filterName}>
                      <LabelInput>
                        {tableTitles[filterName]}
                      </LabelInput>
                      <FormGroup>
                        <Form.Field style={{ width: '49%' }}>
                          Valor Min.
                          <CurrencyInput
                            allowDecimals={true}
                            decimalScale={2}
                            decimalsLimit={2}
                            groupSeparator="."
                            decimalSeparator=','
                            style={{ height: '4rem' }}
                            id={filterName}
                            name={filterName}
                            max={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
                            defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
                            value={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
                            onValueChange={(value) => handleUpdateFilter(filterName, value, 'ge')}
                          />
                        </Form.Field>
                        <Form.Field style={{ width: '49%' }}>
                          Valor Max.
                          <CurrencyInput
                            allowDecimals={true}
                            decimalScale={2}
                            decimalsLimit={2}
                            groupSeparator="."
                            decimalSeparator=','
                            style={{ height: '4rem' }}
                            id={filterName}
                            name={filterName}
                            min={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value : ''}
                            defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
                            value={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value : ''}
                            onValueChange={(value) => handleUpdateFilter(filterName, value, 'le')}
                          />                         
                        </Form.Field>
                      </FormGroup>
                    </div>
                  ))
                }
              </Form>
            </Segment>
          }
          {
            tableFieldFilters.date.length > 0 &&
            <Segment style={{ boxShadow: '0 0 3px 2px #dedede' }}>
              <LabelInput>
                Datas
              </LabelInput>
              <Divider />
              <Form>
                {
                  tableFieldFilters.date.map(filterName => (
                    <div key={filterName}>
                      <LabelInput>
                        {tableTitles[filterName]}
                      </LabelInput>
                      <FormGroup>
                        <Form.Field style={{ width: '49%' }}>
                          De
                          <Input
                            className='inputFilter'
                            type="date"
                            max={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value).format('YYYY-MM-DD') : ''}
                            onChange={e => handleUpdateFilter(filterName, `${moment(e.target.value).format('YYYYMMDD')}`, 'ge')}
                            defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value).format('YYYY-MM-DD') : ''}
                          />
                        </Form.Field>
                        <Form.Field style={{ width: '49%' }}>
                          Até
                          <Input
                            className='inputFilter'
                            type="date"
                            min={filtersValue.find(fil => fil.name === filterName && fil.option === 'ge') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'ge').value).format('YYYY-MM-DD') : ''}
                            onChange={e => handleUpdateFilter(filterName, `${moment(e.target.value).format('YYYYMMDD')}`, 'le')}
                            defaultValue={filtersValue.find(fil => fil.name === filterName && fil.option === 'le') ? moment(filtersValue.find(fil => fil.name === filterName && fil.option === 'le').value).format('YYYY-MM-DD') : ''}
                          />
                        </Form.Field>
                      </FormGroup>
                    </div>
                  ))
                }
              </Form>
            </Segment>
          }
        </Content>

        <Button.Group style={{ alignSelf: 'flex-end' }} floated='right' size='big'>
          <Button onClick={cleanFilters}>Limpar Tudo</Button>
          <Button.Or text="ou" />
          <Button onClick={handleFormatFilter} positive>Filtrar</Button>
        </Button.Group>
      </Sidebar>
  );
}

export default FilterModal;