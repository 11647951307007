import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { LogsReducerInitialState } from "../../../../reducers/Logs";
import { Utils } from "../../../../common/Utils";

const OrdersBarChart: FC = () => {
  const { chartOrderByClientData } = useSelector((state: { logs: LogsReducerInitialState }) => state.logs);

  const utils = new Utils()

  return (
    <Chart
      type="bar"
      width="100%"
      height="100%"
      series={
        chartOrderByClientData.map(({ _id, total }) => {
          return {
            name: _id,
            data: [total],
          }
        }) as ApexAxisChartSeries
      }
      options={{
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        dataLabels: {
          formatter: (value, opts) => {
            return utils.formatDashboardLabel(value)
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },

        // title: {
        //   text: 'Valores/Clientes (vísivel apenas para EthosX)',
        //   floating: true,
        //   offsetY: 160,
        //   align: 'center',
        //   style: {
        //     fontSize: '12px'
        //   }
        // },
        xaxis: {
          categories: ['Valores/Clientes'],
        },
        
      }}
    />
  );
}

export default OrdersBarChart;