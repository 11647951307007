import { createSlice } from '@reduxjs/toolkit'

import { IDictionary } from './../../interfaces/Fields/IDictionary';
import { IRestField } from './../../interfaces/Fields/IRestField';
import { IProduct, ProductDetail } from '../../interfaces/Product/IProduct';
import { IAddLotesToItemsList, IAddNewProductQuantity, IAutoCompleteAllFieldsValue, ICreateOrEditOrderOrBudget, IDeleteGrid, IDeleteItem, IUpdateCalculateProductTotal, IUpdateConsultationType, IUpdateDeleteProductList, IUpdateDictionaryABA, IUpdateDictionaryC6, IUpdateDictionaryCK, 
  IUpdateGridLine, 
  IUpdateGridList, 
  IUpdateGridQuantity, 
  IUpdateImportedData, 
  IUpdateIndice, IUpdateIsCatalog, IUpdateIsConsultation, IUpdateIsCopying, IUpdateIsEditing, IUpdateItemFields, IUpdateItemsCount, IUpdateItemsList, 
  IUpdateModalQuantityState, IUpdatePriceTable, IUpdateProduct, IUpdateProductDetail, IUpdateProductDiscountIncreaseAdjustedPrice, IUpdateProductList, 
  IUpdateProductTotalPrice, 
  IUpdateProductValueList, 
  IUpdateQuantityValue, IUpdateSearchItemSelected, IUpdateSearchItemSelectedDiscountIncreaseAdjustedPrice, IUpdateSearchItemSelectedValue, IUpdateSearchOptions, IUpdateSearchOptionsFilter, IUpdateSearchOptionsHasMore, IUpdateSearchOptionsPage, IUpdateSearchProductSelected, IUpdateSearchResetOptionText, IUpdateSelectedGrid, IUpdateSelectedProductToConsultation, IUpdateSpecificGridList, IUpdateType, IUpdateUpdateFields, IUpdateUpdateItens, IUpdateViewConsultationModal, IUpdateViewGridModal 
} from './actions';
import { TableData } from '../../@types/Datatable';
import { FieldValues } from '../../@types/Restfield';

export interface CartReducerInitialState {
  type: 'budget' | 'order',
  isCatalog: boolean;
  isConsultation: boolean;
  isImported: boolean;
  importedData: Array<{ [key: string]: string }>;
  itemsCount: number;
  viewGridModal: boolean;
  consultationType: string;
  viewConsultationModal: boolean;
  selectedGrid: TableData;
  selectedProductToConsultation: TableData;
  gridList: TableData[];
  itemsList: IProduct[];
  itemFields: IRestField[];
  productList: IRestField[][];
  deleteProductList: FieldValues[],
  product: IProduct;
  quantityValue: number;
  modalQuantityState: boolean;
  calculateProductTotal: boolean;
  priceTable: string | number;
  dictionaryC6: IDictionary;
  dictionaryABA: IDictionary;
  dictionaryCK: IDictionary;
  indice: string | number;
  isEditing: boolean;
  isCopying: false;
  updateFields: IProduct;
  updateItens: IProduct[];
  productDetail: ProductDetail;
  searchOptions: TableData[];
  searchOptionsFilter: string;
  searchOptionsPage: number;
  searchOptionsHasMore: boolean;
  searchResetOptionText: boolean;
  searchProductSelected: TableData;
  searchItemSelected: IRestField[];
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    type: ('budget' as 'budget' | 'order'),
    isCatalog: false,
    isConsultation: false,
    isImported: false,
    importedData: [],
    itemsCount: 0,
    viewGridModal: false,
    consultationType: '',
    viewConsultationModal: false,
    selectedGrid: ({} as TableData),
    selectedProductToConsultation: ({} as TableData),
    gridList: ([] as TableData[]),
    itemsList: ([] as IProduct[]),
    itemFields: ([] as IRestField[]),
    productList: ([] as IRestField[][]),
    deleteProductList: ([] as FieldValues[]),
    product: ({} as IProduct),
    quantityValue: 1,
    modalQuantityState: false,
    calculateProductTotal: false,
    priceTable: ('' as string | number),
    dictionaryC6: ({} as IDictionary),
    dictionaryABA: ({} as IDictionary),
    dictionaryCK: ({} as IDictionary),
    indice: ('' as string | number),
    isEditing: false,
    isCopying: false,
    updateFields: ({} as IProduct),
    updateItens: ([] as IProduct[]),
    productDetail: ({} as ProductDetail),
    searchOptions: ([] as TableData[]),
    searchOptionsFilter: '',
    searchOptionsPage: 1,
    searchOptionsHasMore: false,
    searchResetOptionText: true,
    searchProductSelected: ({} as TableData),
    searchItemSelected: ([] as IRestField[])
  },
  reducers: {
    updateType: (state, action: IUpdateType) => {
      state.type = action.payload.value
    },

    updateIsCatalog: (state, action: IUpdateIsCatalog) => {
      state.isCatalog = action.payload.value
    },

    updateIsConsultation: (state, action: IUpdateIsConsultation) => {
      state.isConsultation = action.payload.value
    },

    updateImportedData: (state, action: IUpdateImportedData) => {
      state.importedData = action.payload.value
    },

    updateViewGridModal: (state, action: IUpdateViewGridModal) => {
      state.viewGridModal = action.payload.value
    },

    updateConsultationType: (state, action: IUpdateConsultationType) => {
      state.consultationType = action.payload.value
    },

    updateViewConsultationModal: (state, action: IUpdateViewConsultationModal) => {
      state.viewConsultationModal = action.payload.value
    },

    updateSelectedGrid: (state, action: IUpdateSelectedGrid) => {
      state.selectedGrid = action.payload.value
    },

    updateSelectedProductToConsultation: (state, action: IUpdateSelectedProductToConsultation) => {
      state.selectedProductToConsultation = action.payload.value
    },

    updateGridList: (state, action: IUpdateGridList) => {
      state.gridList = action.payload.value
    },

    updateGridListLine: (state, action: IUpdateGridLine) => {
      if (state.gridList.findIndex(grid => action.payload.useGridColor ? grid.b4_grdcod === action.payload.b4_grdcod : grid.indice === action.payload.indice) !== -1) {
        state.gridList[state.gridList.findIndex(grid => action.payload.useGridColor ? grid.b4_grdcod === action.payload.b4_grdcod : grid.indice === action.payload.indice)].linha = action.payload.line
      }
    },

    updateSpecificGridList: (state, action: IUpdateSpecificGridList) => {
      state.gridList[state.gridList.findIndex(grid => action.payload.useGridColor ? grid.b4_grdcod === action.payload.codigo : grid.indice === action.payload.indice)] = action.payload.value
    },

    updateGridQuantity: (state, action: IUpdateGridQuantity) => {
      if (action.payload.type === 'aberta') {
        state.selectedGrid.linha[action.payload.indexLine].coluna[action.payload.indexColumn].quantidade = action.payload.quantity;
      } else {
        state.selectedGrid.linha[action.payload.indexLine].quantidade = action.payload.quantity;
      }
    },
 
    updateItemsCount: (state, action: IUpdateItemsCount) => {
      state.itemsCount = action.payload.value
    },

    updateItemsList: (state, action: IUpdateItemsList) => {
      state.itemsList = action.payload.value
    },

    addLotesToItems: (state, action: IAddLotesToItemsList) => {
      state.itemsList = state.itemsList.map(item => {
        if (item.indice === action.payload.itemIndice) {
          return {
            ...item,
            ...action.payload.value
          }
        }

        return item
      })
    },

    updateItemFields: (state, action: IUpdateItemFields) => {
      state.itemFields = action.payload.value;
      state.searchItemSelected = action.payload.value;
    },

    updateProductList: (state, action: IUpdateProductList) => {
      state.productList = action.payload.value;
    },

    updateProduct: (state, action: IUpdateProduct) => {
      state.product = action.payload.value
    },

    updateQuantityValue: (state, action: IUpdateQuantityValue) => {
      state.quantityValue = action.payload.value
    },

    updateModalQuantityState: (state, action: IUpdateModalQuantityState) => {
      state.modalQuantityState = action.payload.value
    },

    updateCalculateProductTotal: (state, action: IUpdateCalculateProductTotal) => {
      state.calculateProductTotal = action.payload.value
    },

    updatePriceTable: (state, action: IUpdatePriceTable) => {
      state.priceTable = action.payload.value
    },

    updateDictionaryABA: (state, action: IUpdateDictionaryABA) => {
      state.dictionaryABA = action.payload.value
    },

    updateDictionaryC6: (state, action: IUpdateDictionaryC6) => {
      state.dictionaryC6 = action.payload.value
    },

    updateDictionaryCK: (state, action: IUpdateDictionaryCK) => {
      state.dictionaryCK = action.payload.value
    },

    updateIndice: (state, action: IUpdateIndice) => {
      state.indice = action.payload.value
    },

    updateIsEditing: (state, action: IUpdateIsEditing) => {
      state.isEditing = action.payload.value
    },

    updateIsCopying: (state, action: IUpdateIsCopying) => {
      state.isCopying = action.payload.value
    },
    
    updateUpdateFields: (state, action: IUpdateUpdateFields) => {
      state.updateFields = action.payload.value
    },

    updateUpdateItens: (state, action: IUpdateUpdateItens) => {
      state.updateItens = action.payload.value
    },

    updateProductDetail: (state, action: IUpdateProductDetail) => {
      state.productDetail = action.payload.value;
    },

    updateDeleteProductList: (state, action: IUpdateDeleteProductList) => {
      state.deleteProductList = action.payload.value;
    },

    // updateAllFieldValues: (state, action) => {
    //   state[action.payload.stateName] = { ...state[action.payload.stateName], ...action.payload.stateValue }
    // },

    addNewProductQuantity: (state, action: IAddNewProductQuantity) => {
      state.itemsList = action.payload.itemsList;
      state.itemsCount = action.payload.itemsCount;

      state.modalQuantityState = false;
      state.quantityValue = 1;
      state.product = {};
    },

    createOrEditOrderOrBudget: (state, action: ICreateOrEditOrderOrBudget) => {
      state.isEditing = action.payload.isEditing;
      state.isCopying = action.payload.isCopying;
      state.indice = action.payload.indice;
      state.type = action.payload.type;
    },

    clearCart: (state) => {
      state.itemsList = [];
      state.productList = [];
      state.deleteProductList = [];
      state.itemsCount = 0;
      // state.priceTable = ''
      state.gridList = [];
      state.importedData = [];
      state.isImported = false;
    },

    updateProductValueList: (state, action: IUpdateProductValueList) => {   
      if(state.productList[action.payload.index].find(field => field.nome === action.payload.fieldName)) state.productList[action.payload.index].find(field => field.nome === action.payload.fieldName).conteudo = action.payload.value
    },

    autoCompleteAllFieldsValue: (state, action: IAutoCompleteAllFieldsValue) => {
      state.productList = state.productList.map(product => {
        if (product.find(field => field.nome === action.payload.fieldName)) {
          product.find(field => field.nome === action.payload.fieldName).conteudo = action.payload.value
        }

        return product
      })
    },

    updateProductDiscountIncreaseAdjustedPrice: (state, action: IUpdateProductDiscountIncreaseAdjustedPrice) => {
      if (state.productList[action.payload.index].find(field => field.regra === 'desconto')) state.productList[action.payload.index].find(field => field.regra === 'desconto').conteudo = action.payload.discount
      if (state.productList[action.payload.index].find(field => field.regra === 'acrescimo')) state.productList[action.payload.index].find(field => field.regra === 'acrescimo').conteudo = action.payload.increase
      if (state.productList[action.payload.index].find(field => field.regra === 'prcven')) state.productList[action.payload.index].find(field => field.regra === 'prcven').conteudo = action.payload.adjustedPrice
    },

    updateProductTotalPrice: (state, action: IUpdateProductTotalPrice) => {
      const productListCopy = [...state.productList]
      
      const adjustValue = productListCopy[action.payload.index] && productListCopy[action.payload.index].find(field => field.regra === 'prcven') ? parseFloat(String(productListCopy[action.payload.index].find(field => field.regra === 'prcven').conteudo)) : 0;
      const quantity = productListCopy[action.payload.index] && productListCopy[action.payload.index].find(field => field.regra === 'quantidade') ? productListCopy[action.payload.index].find(field => field.regra === 'quantidade').conteudo : 0;

      const total = adjustValue * Number(quantity);

      if(state.productList[action.payload.index].find(field => field.regra === 'vlrtot')) {
        state.productList[action.payload.index].find(field => field.regra === 'vlrtot').conteudo = total
      }
    },

    deleteItem: (state, action: IDeleteItem) => {
      state.itemsCount = state.itemsCount - Number(state.productList[action.payload.index].find(field => field.regra === 'quantidade').conteudo);

      state.productList[action.payload.index].find(field => field.regra === 'quantidade').conteudo = 0;
      
      if (state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '&&' && state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '') {
        state.deleteProductList.push({ AUTDELETA: 'S', [action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM']:  state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo  })
      }

      const productCode = state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_PRODUTO' : 'CK_PRODUTO')).conteudo
      state.productList.splice(action.payload.index, 1);

      const findItemListIndex = state.itemsList.findIndex(item => item.b1_cod === productCode)
      state.itemsList[findItemListIndex].quantity = 0;
      state.itemsList.splice(findItemListIndex, 1);
    },

    deleteGrid: (state, action: IDeleteGrid) => {
      state.itemsCount = state.itemsCount - Number(state.productList[action.payload.index].find(field => field.regra === 'quantidade').conteudo);

      state.productList[action.payload.index].find(field => field.regra === 'quantidade').conteudo = 0;
      if (state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '&&' && state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '') {
        state.deleteProductList.push({ AUTDELETA: 'S', [action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM']:  state.productList[action.payload.index].find(field => field.nome === (action.payload.type === 'order' ? 'C6_ITEM' : 'CK_ITEM')).conteudo  })
      }
      state.productList.splice(action.payload.index, 1);

      state.gridList[action.payload.index].quantity = 0;
      state.gridList.splice(action.payload.index, 1);
    },

    updateSearchOptions: (state, action: IUpdateSearchOptions) => {
      state.searchOptions = action.payload.value
    },

    updateSearchOptionsFilter: (state, action: IUpdateSearchOptionsFilter) => {
      state.searchOptionsFilter = action.payload.value
    },

    updateSearchOptionsPage: (state, action: IUpdateSearchOptionsPage) => {
      state.searchOptionsPage = action.payload.value
    },

    updateSearchOptionsHasMore: (state, action: IUpdateSearchOptionsHasMore) => {
      state.searchOptionsHasMore = action.payload.value
    },
    
    updateSearchResetOptionText: (state, action: IUpdateSearchResetOptionText) => {
      state.searchResetOptionText = action.payload.value
    },

    updateSearchProductSelected: (state, action: IUpdateSearchProductSelected) => {
      state.searchProductSelected = action.payload.value
    },

    updateSearchItemSelected: (state, action: IUpdateSearchItemSelected) => {
      state.searchItemSelected = action.payload.value
    },

    updateSearchItemSelectedValue: (state, action: IUpdateSearchItemSelectedValue) => {
      state.searchItemSelected.find(field => field.nome === action.payload.fieldName).conteudo = action.payload.value;
    },

    updateSearchItemSelectedDiscountIncreaseAdjustedPrice: (state, action: IUpdateSearchItemSelectedDiscountIncreaseAdjustedPrice) => {
      if (state.searchItemSelected.find(field => field.regra === 'desconto')) state.searchItemSelected.find(field => field.regra === 'desconto').conteudo = action.payload.discount
      if (state.searchItemSelected.find(field => field.regra === 'acrescimo')) state.searchItemSelected.find(field => field.regra === 'acrescimo').conteudo = action.payload.increase
      if (state.searchItemSelected.find(field => field.regra === 'prcven')) state.searchItemSelected.find(field => field.regra === 'prcven').conteudo = action.payload.adjustedPrice
    },

    updateSearchItemSelectedTotalPrice: (state) => {
      const productListCopy = [...state.searchItemSelected]
      
      const adjustValue = productListCopy.find(field => field.regra === 'prcven') ? parseFloat(String(productListCopy.find(field => field.regra === 'prcven').conteudo)) : 0;
      const quantity = productListCopy.find(field => field.regra === 'quantidade') ? productListCopy.find(field => field.regra === 'quantidade').conteudo : 0;

      const total = adjustValue * Number(quantity);

      if(state.searchItemSelected.find(field => field.regra === 'vlrtot')) {
        state.searchItemSelected.find(field => field.regra === 'vlrtot').conteudo = total
      }
    },

    resetSearchOptionsSelected: (state) => {
      state.searchProductSelected = {} as TableData;
      state.searchItemSelected = state.itemFields;
      state.searchResetOptionText = !state.searchResetOptionText;
      state.searchOptionsPage = 1;
      state.searchOptionsFilter = '';
    },

    unSelectedSearchOptions: (state) => {
      state.searchItemSelected = state.itemFields;
      state.searchProductSelected = {} as TableData;
    }
  }
})

export const { updateIndice, updateIsEditing, updateItemFields, updateItemsCount, 
  updateItemsList, updateModalQuantityState, updatePriceTable, updateProduct, updateIsCatalog,
  updateProductList, updateQuantityValue, updateType, updateUpdateFields, updateIsConsultation,
  updateUpdateItens, updateDictionaryC6, updateDictionaryCK,
  addNewProductQuantity, clearCart, createOrEditOrderOrBudget, updateProductValueList, 
  updateProductDiscountIncreaseAdjustedPrice, updateProductTotalPrice, deleteItem, deleteGrid,
  autoCompleteAllFieldsValue, updateProductDetail, updateImportedData,
  updateCalculateProductTotal, updateSearchOptions, updateSearchOptionsFilter,
  updateSearchOptionsPage, updateSearchOptionsHasMore, updateSearchResetOptionText, 
  updateSearchProductSelected, updateSearchItemSelected, resetSearchOptionsSelected, updateDeleteProductList,
  unSelectedSearchOptions, updateSearchItemSelectedValue, updateSearchItemSelectedDiscountIncreaseAdjustedPrice,
  updateSearchItemSelectedTotalPrice, updateSelectedGrid,  updateViewGridModal, updateViewConsultationModal, updateGridQuantity, updateGridList,
  updateGridListLine, updateSpecificGridList, updateDictionaryABA, updateSelectedProductToConsultation, updateConsultationType, addLotesToItems
} = cartSlice.actions;
export default cartSlice.reducer;