import moment from "moment";
import { FieldConfigCharacterOrDate, FieldTypeConfig, LegendType, TableData, TableLevel } from "../@types/Datatable";
import { FieldValues } from "../@types/Restfield";
import { IPWM } from "../interfaces/Auth/IPWM";
import { IRestField } from "../interfaces/Fields/IRestField";

export class Utils {
  public localeConfig: ApexLocale[] = [{
    name: 'br',
    options: {
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    }
  }]

  public getEnvironment(): string {

    const {hostname} = window.location;
    
    if (hostname.includes('hml')) {
      return 'Homologação';
    } else if (hostname.includes('localhost') || hostname.includes('127.0.0.1')) {
      return 'Desenvolvimento';
    } else {
      return 'Produção';
    }
  }

  public removeBRLFormat (value: string): number {
    // remove ("R$", ".", ",", " "), mas mantém o operador ("-")  
    const formatValue = +value.replace("R$", "").replace(".", "").replace(",", "").replace(/\s/g, '');
    const checkMathOperator  = Math.sign(formatValue); 
    
    if(checkMathOperator === -1) return  -value.replace(/[^0-9]/g, '');
    return  +(value.replace(/[^0-9]/g,''));
  } 

  public formatFloatAsBRL(floatValue: number, decimalPlaces?: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: decimalPlaces ? decimalPlaces : 2 }).format(floatValue);
  }

  public formatFloatAsUSD(floatValue: number): string {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(floatValue);
  }

  public inputMaskBRLCurrency(value: number, decimal: string): string {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: parseFloat(decimal)
    }).format(Number(value)).replace('R$', '')
  }

  public unmaskBRLCurrency(value: string, decimal: string): number {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '') ? value.replace('.', '').replace(',', '').replace(/\D/g, '') : '0'

    const options = { minimumFractionDigits: parseFloat(decimal) }
    const result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / Number('1'.padEnd(Number(decimal) + 1, '0'))
    )
  
    return parseFloat(result.replace('.', '').replace(',', '.'))
  }

  public getRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  public formatTableData(fieldTypeConfig: FieldTypeConfig, dados: TableData[]) {
    const { character, numeric, date } = fieldTypeConfig;

    const characterFilter = character.filter(cha => cha.type !== "none" && cha.name !== 'B1_COD')

    for (const item of dados) {
      characterFilter.map(cha => {
        if(cha.type === 'cnpj') {
          if (item[cha.name.toLowerCase()] !== undefined && String(item[cha.name.toLowerCase()]).length == 14) {
            item[cha.name.toLowerCase()] = String(item[cha.name.toLowerCase()]).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
          } else {
            item[cha.name.toLowerCase()] = String(item[cha.name.toLowerCase()]).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
          }
        }
      })

      numeric.map(num => {
        if (item[num.name.toLowerCase()] !== undefined && num.mask === "currency") {
          item[num.name.toLowerCase()] = `R$ ${this.convertBrl(item[num.name.toLowerCase()])}`
        }

        if (item[num.name.toLowerCase()] !== undefined && num.mask === "percent") {
          item[num.name.toLowerCase()] = `${item[num.name.toLowerCase()]}%`
        }
      })
    }
  }

  public formatSearchStringToPaginationService(fieldsArray: FieldConfigCharacterOrDate[], fieldFilter: string[], searchRule: 'contains' | 'eq', searchTerm: string) {
    let searchString = '';
    const cnpj = searchTerm;

    for (const field of fieldsArray) {
      if(fieldFilter.includes(field.name)){
        if (searchRule === 'contains') {
          if(field.type === 'cnpj') {
            searchString += ` or contains(${field.name}, '${cnpj.replaceAll('.', '').replace('/', '').replace('-', '')}')`;        
          }else{
            searchString += ` or contains(${field.name}, '${searchTerm}')`;
          }
        }
        else {
          if(field.type === 'cnpj') {
            searchString += ` or ${field.name} eq '${cnpj.replaceAll('.', '').replace('/', '').replace('-', '')}'`;
          }else{
            searchString += ` or ${field.name} eq '${searchTerm}'`;
          }
        }
      }
    }

    return searchString.substring(4);
  }

  public formatSearchStringToPaginationServiceGrid(fieldsArray: FieldConfigCharacterOrDate[], fieldFilter: string[], searchRule: 'contains' | 'eq', searchTerm: string) {
    let searchString = '';
    const cnpj = searchTerm;
    return searchTerm
    for (const field of fieldsArray) {
      if(fieldFilter.includes(field.name)){
        if (searchRule === 'contains') {
          if(field.type === 'cnpj') {
            searchString += `${cnpj.replaceAll('.', '').replace('/', '').replace('-', '')}`;        
          }else{
            searchString += `${searchTerm}`;
          }
        }
        else {
          if(field.type === 'cnpj') {
            searchString += `${cnpj.replaceAll('.', '').replace('/', '').replace('-', '')}`;
          }else{
            searchString += `${searchTerm}`;
          }
        }
      }
    }

    return searchString.substring(4);
  }

  public convertBrl(floatValue: string | number, decimalPlaces?: number) {
    // floatValue = Number(floatValue).toFixed(decimalPlaces || 2);
    // return floatValue.toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    if(floatValue) {
      return floatValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: decimalPlaces ? decimalPlaces : 2 }).replace('R$', '')
    }else{
      return 0
    }
  }

  public async getBase64(file: File) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        reject(error)
      };
    });
  }

  public CpfValidate(strCPF) {
    let Soma = 0;
    let Resto = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  public pictureValidate(picture: string, value: string) {

    if(picture.split(' ')[0].includes('A')) {
      value = value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
    }

    if(picture.split(' ')[0].includes('!')) {
      value = value.toUpperCase();
    }

    if(picture.split(' ')[0].includes('@R')) {
      value = value.replace(/\D/g, "");
    }

    if(picture.split(' ')[1] === '99999-999') {
      value = value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
    }
  
    if(picture === '9999-9/99') {
      value = value.replace(/\D/g, "").replace(/^(\d{4})(\d{1})(\d{2})+?$/, "$1-$2/$3");
    }

    if(picture.split(' ')[1] === 'XXX-9999') {
      // -> /[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/
      // value = value.replace(/[^\w]/g, '').replace(/([A-Z]{3})([0-9]{1})([A-Za-z0-9]{1})([0-9]{2})/, "$1-$2$3$4")

      if (value.length === 0) {
        value = '' 
      } else if (value.length === 1) {
        const mask = /^[A-Z]/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 2) {
        const mask = /^[A-Z]{2}/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 3) {
        const mask = /^[A-Z]{3}/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 4) {
        const mask = /^[A-Z]{3}-/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1)+"-"+value[3];
        }else{
          value = value
        }
      }
      if (value.length === 5) {
        const mask = /^[A-Z]{3}-[0-9]/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 6) {
        const mask = /^[A-Z]{3}-[0-9][A-Z0-9]/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 7) {
        const mask = /^[A-Z]{3}-[0-9][A-Z0-9][0-9]/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
      if (value.length === 8) {
        const mask = /^[A-Z]{3}-[0-9][A-Z0-9][0-9]{2}/
        if (!mask.exec(value)) {
          value = value.substring(0, value.length-1);
        }
      }
    }

    return value;
  }

  public formatFieldsValuesByPicture = (restField: IRestField[], fieldValues: FieldValues ): FieldValues => {
    const fieldValuesFormatted: FieldValues = {...fieldValues}
    restField.map(field => {
      if(field.picture.includes('!') && fieldValues[field.nome]) {
        fieldValuesFormatted[field.nome] = String(fieldValues[field.nome]).toUpperCase()
      }
      if(field.picture.includes('99999-999') && fieldValues[field.nome]) {
        fieldValuesFormatted[field.nome] = String(fieldValues[field.nome]).replace(/\D/g, "")
      }
    })
  
    return fieldValuesFormatted;
  }

  public statusValidate(status: string[], fields:  LegendType[], rowSelected: TableData) {   
    const result = fields.map(field => {        
    const fieldValue: string | undefined = rowSelected[field.campo.toLowerCase()]?.toString()      
      if(status.map(sts => sts.toUpperCase()).includes(fieldValue?.toUpperCase())) {       
  // public statusValidate(status: string[], fields:  LegendType[], rowSelected: TableData) {
  //   const result = fields.map(field => {
  //     const row = rowSelected[field.campo.toLowerCase()] ? rowSelected[field.campo.toLowerCase()].toString().toLowerCase() : ''
  //     if(status.map(sta => sta.toLowerCase()).includes(row)) {
        return true;
      } else {
        return false;
      }
    })  
    return result.find(value => value === true);
  }

  public formatSelectClientTitle(selectedClient: TableData, pwmConfig: IPWM): string {
    let selectClientTitle = ''
    if (pwmConfig.selectclienttitle && selectedClient.indice) {
      const clientTitles = pwmConfig.selectclienttitle.split('+')
      clientTitles.map((title, index) => {
        selectClientTitle += selectedClient[title]
        if (index < clientTitles.length - 1) {
          selectClientTitle += ' - '
        }
      })
  
      return selectClientTitle
    } else if(!pwmConfig.selectclienttitle && selectedClient.indice) {
      selectClientTitle = selectedClient ? String(selectedClient.a1_nreduz) : 'N/A'
      return selectClientTitle
    } else {
      return 'N/A'
    }
  }

  public formatDateDefault(date: string): string {
    if(date === '') return ''
    if(date === "  /  /  ") return ''

    if (date.includes('-')) {
      return date
    }

    let newDate = moment()
    const year = date.length === 8 ? date.substring(6, 8) : date.substring(8, 10)

    newDate = moment(newDate).set("date", Number(date.substring(0, 2)))
    newDate = moment(newDate).set("month", Number(date.substring(3, 5)) - 1)

    newDate = moment(newDate).set("year", Number("20" + year))

    return moment(newDate).format('YYYY-MM-DD')
  }

  public despiseFieldsValue(fields: IRestField[], fieldsValues: FieldValues): FieldValues {
    const fieldValuesToSend = {};

    fields.forEach(( field ) => {
      if (field.visual !== 'D' && field.tipo !== '6') {
       fieldsValues[field.nome] !== undefined
        ?    fieldValuesToSend[field.nome] = fieldsValues[field.nome]     
        :    fieldValuesToSend[field.nome] = field.conteudo || ''                                
      }
    });

    return fieldValuesToSend;
  }

  public despiseItensFieldsValue(fields: IRestField[], itensFieldsValues: FieldValues[]): FieldValues[] {
    const itensFieldValuesToSend: FieldValues[] = [];
    
    itensFieldsValues.map(fieldsValues => {
      const itemFieldsValue = {};
      fields.forEach((field) => {
        if(field.visual !== 'D') {
          if( fieldsValues[field.nome]) {
            itemFieldsValue[field.nome] = fieldsValues[field.nome];
          }
        }
      });
      itensFieldValuesToSend.push(itemFieldsValue);
    })

    return itensFieldValuesToSend;
  }

  public isFieldDisabled(mode: string, visual: "A" | "V" | "D", tipo: "1" | "2" | "3" | "4" | "5" | "6", f3?: string): boolean {
    let disabled = true;
    const hasContent = !!f3;   

    switch (mode) {
      case "add":
        if(tipo === '1' || tipo === '3') {         
          disabled = false;
        }        
        break;
      case "edit": 
        if(tipo === '2' || tipo === '3') {
         disabled = false;
        }
        if(tipo !== '1' && visual === 'V' && hasContent) {    
          disabled = false;
        } 
        break;
      case "view": 
        disabled = true;
        break;
      default:
        disabled = true;
        break;
    }
 
    if(visual === 'V' && !hasContent) {    
      disabled = true;
    }    
    return disabled;
  }

  public validationFormField(type: '1' | '2' | '3' | '4' | '5' | '6', mode: string): boolean {
    let valid = false;

    switch (mode) {
      case 'add':
        if(type !== '4' && type !== '5' && type !== '6') {
          valid = true;
        }
        break;
      case 'edit': 
        if(type !== '4' && type !== '5' && type !== '6') {
          valid = true;
        }
        break;
      case 'view': 
        if(type !== '4' && type !== '5') {
          valid = true;
        }
        break;
      default:
        valid = false;
        break;
    }
    
    return valid
  }

  public base64toBlob (data: string): Blob {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  }

  public isValidHex(hex: string){
    const regexColor = new RegExp('(^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$)'); 
    return regexColor.test(hex);
  }

  public hexToHSL(H: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(H);

    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255, g /= 255, b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if(max == min){
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return h
  }

  public formatDashboardLabel(value: string | number | number[], type?: string | number | object) {
    if (typeof value === "number" && value && type === "M") {
      let copyOfValue = value;

      let final = "";

      const decPlaces = Math.pow(10, 3);
      // Enumerate number abbreviations
      const abbrev = ["K", "M", "B", "T"];

      for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);

        if (size <= copyOfValue) {
          copyOfValue = Math.floor((copyOfValue * decPlaces) / size) / decPlaces;

          if (copyOfValue == 1000 && i < abbrev.length - 1) {
            copyOfValue = 1;
            i++;
          }

          final = String(copyOfValue.toFixed(3)) + abbrev[i];
          break;
        } else {
          final = String(value);
        }
      }
     
      return final;
    }
    return value?.toString();
  }

  public formatDashboardYaxisPopup(value: string | number | number[], type?: string ) {   
    if (typeof value === "number" && value && type === 'M') {
      return String(this.formatFloatAsBRL(value, 2))
    }
    else if (typeof value === "number" && value && type === 'N') {
      return String(this.convertBrl(value, 2));
    }
    else {
      return value?.toString();
    }
  }

  public customHiddenFields(customerLevel: number, fieldsLevel: TableLevel, hiddenFields: string[]): string[] {
    Object.entries(fieldsLevel)
      .forEach(([key, value]) => {
        if (Number(value) > customerLevel && !hiddenFields.includes(key)) {
          hiddenFields.push(key)
        }
      })

    return hiddenFields
  }

  public generateItensPerPageDefault(windowSize: number, rowSize: number) {
    return  [
      { key: Math.floor((windowSize - 375) / rowSize), value: Math.floor((windowSize - 375) / rowSize), text: Math.floor((windowSize - 375) / rowSize) },
      { key: 25, value: 25, text: 25 },
      { key: 50, value: 50, text: 50 },
      { key: 100, value: 100, text: 100 },
    ] 
  }

  public generateItensPerPage(itensPerPage: string) {
    const itensArray = itensPerPage.split(';')
    itensArray.pop()
    return itensArray.map(item => {
      return { key: Number(item), value: Number(item), text: Number(item) }
    })
  }
}