import { Input, Table } from "semantic-ui-react";
import styled from "styled-components";

export const TableCell = styled(Table.Cell)`
  padding: 0 !important;
`;

export const TableHeaderCell = styled(Table.HeaderCell)`
  box-shadow: 0 -1px 0 1px #fff;
  background: #FFF !important;
  border-left: none !important;
`;

export const TableHeaderCellLast = styled(Table.HeaderCell)`
  box-shadow: 0 -1px 0 1px #fff;
  background: #FFF !important;
`;

export const InputQuantity = styled(Input)`
  width: 100%;

  & > input {
    text-align: center !important;
  }
`;

export const TableDataText = styled.span`
  font-size: 1.6rem;
  white-space: nowrap;
`;

export const TableCellLabel = styled.div`
  width: 100%;
  display: flex;
  algin-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.mainColor};
  color: #FFF;
  font-size: 1.2rem;
`;

export const TableCellProduct = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.2rem;
`;

export const TableCellQuantity = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

    font-size: 1.1rem;
  white-space: nowrap;
`;