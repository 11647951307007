import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
`;

export const LogoModal = styled.img`
  width: 20rem !important;
  height: 8rem;
  align-self: center;
  margin-bottom: 3rem;
`;

export const DropdownUsername = styled.span`
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
`;

export const ModalParagraph = styled.p`
  font-size: 1.8rem;
`;

export const SemanticDropDown = styled(Dropdown)`
   z-index: 200 !important;
   
  .menu > .item {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 5px !important;
  }
`;