import React, { useState } from 'react';
import { BsBuilding, BsCheck, BsExclamation, BsTrash } from 'react-icons/bs';
import { FiMonitor } from 'react-icons/fi'
import { styleSheet } from '../../assets/styles/global';

import {
  Container, Dadge, DadgeContainer, Data, IconContainer, Line, OsInfo, OsInfosContainer, SubTitle, Title
} from './styles';


interface Props {
  os: string;
  nomeCoordenador: string;
  local: string;
  title: string;
  subTitle: string;
  color: string;
  hour: number;
  isSelected: boolean;
}

const Os: React.FC<Props> = ({ os, nomeCoordenador, local, title, subTitle, color, hour, isSelected }) => {
  return (
    <Container
      color={color}
      isSelected={isSelected}
    >
        <DadgeContainer>
          <Dadge color={styleSheet.warningColor}>{hour}h</Dadge>
          <Dadge color={color}>{nomeCoordenador.split(" ").slice(0, 2).map((n)=>n[0]).join("")}</Dadge>
        </DadgeContainer>
        <OsInfosContainer>
          <OsInfo>
            {
              os === '' &&
              <IconContainer color={styleSheet.dangerColor}>
                <BsExclamation color="#FFF"  size={15} />
              </IconContainer>
            }
            {
              os !== '' &&
              <IconContainer color={styleSheet.successColor}>
                <BsCheck color="#FFF"  size={15}  />
              </IconContainer>
            }
          </OsInfo>
          <Line />
          <OsInfo>
            {
              local === 'REMOTO' 
                ? 
                <IconContainer color={styleSheet.infoColor}>
                  <FiMonitor color="#FFF" size={15} />
                </IconContainer>
                :
                <IconContainer color={styleSheet.warningColor}>
                  <BsBuilding color="#FFF" size={15} />
                </IconContainer>
            }
          </OsInfo>
        </OsInfosContainer>
        <Data>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Data>
    </Container>
  );  
}

export default Os;