import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 98%;
  margin-left: 1%;
  z-index: 2;
  opacity: 0.94;
  z-index: 99999;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  margin-left: 4rem;

  svg {
    position: absolute;
    left: 0;
    margin-left: 11px;
  }
  
`;
export const SpanContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around; 
  flex-direction: column; 
`;


