import React, { FC } from "react";
import EnvironmentContextProvider from "./Environment";
import DashboardProvider from "./DashboardContext";
import UIContextProvider from "./UIContext";
import CartContextProvider from "./CartContext";
import PolicieContextProvider from "./PolicieContext";
import OrderServiceContext from './OrderServiceContext'
import MenuContextProvider from "./MenuContext";
import { useSelector } from "react-redux";
import { PolicieReducerInitialState } from "../reducers/Policie";

import { GlobalStyle, GlobalStyleUppercase } from "../assets/styles/global";

const ContextProvider: FC = ({ children }) => {
  const { customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  return (
    <PolicieContextProvider>
      {
        customizations['useCapitalLetters'] 
          ? <GlobalStyleUppercase  />
          : <GlobalStyle  />
      }
        
        <UIContextProvider>
          <MenuContextProvider>
            <EnvironmentContextProvider>
                <DashboardProvider>
                  <OrderServiceContext>
                    <CartContextProvider>
                      {children}
                    </CartContextProvider>
                  </OrderServiceContext>
                </DashboardProvider>
            </EnvironmentContextProvider>
          </MenuContextProvider>
        </UIContextProvider>
    </PolicieContextProvider>
  );
}

export default ContextProvider;