import { Input, Label, Image, Icon, Menu, Button, Grid, Table, Pagination, Card, Container, Modal, Select, Dropdown } from 'semantic-ui-react';
import { ShoppingCart as CartIcon, SquaresFour as GridIcon, List as ListIcon, ShoppingBagOpen } from 'phosphor-react';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { encode } from 'base-64';
import Swal from 'sweetalert2';
import moment from 'moment';

import { ProductService } from '../../services/methods/ProductService';
import { FieldService } from '../../services/methods/FieldService';

import { CartContext, dictionaryEXC6, dictionaryEXCK } from '../../contexts/CartContext';
import { UIContext } from '../../contexts/UIContext';

import { TableColumnText, TableDataText } from '../../components/Datatable/styles';
import DatatableField from '../../components/Datatable/DatatableField';
import ViewCheckoutModal from '../../components/ViewCheckoutModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';
import F3 from '../../components/F3';

import { IProduct, IProductGroups } from '../../interfaces/Product/IProduct';
import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { IDictionary } from '../../interfaces/Fields/IDictionary';

import {
  addNewProductQuantity, CartReducerInitialState, clearCart, updateDictionaryC6,
  updateDictionaryCK, updateItemFields, updateItemsCount, updateItemsList, updateGridList, updateProductList,
  updateModalQuantityState, updatePriceTable, updateProduct, updateQuantityValue,
  updateUpdateItens, updateSearchOptions, updateViewGridModal, updateViewConsultationModal, updateIsConsultation, resetSearchOptionsSelected, updateDeleteProductList, 
} from '../../reducers/Cart';
import { clearFieldValues, fillFieldAndFieldValues, fillGridFieldAndFieldValues, RestFieldReducerInitialState, updateFieldValues } from '../../reducers/RestField';
import { EnvironmentReducerInitialState, updateItensPerPage, updateOrderBy, updateSelectedRow } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';
import defaultProductImg from '../../assets/images/default-product.jpeg';

import {
  PageDefault,
  Content,
  BrowserContent,
  DefaultLabel,
  QuantityContainer,
  QuantityInput,
  TableContainer,
  Fieldset,
  LegendStatus,
  LegendContainer,
  Legend,
  LegendInitialName,
  LegendName,
  TableContainerGrid
} from './styles'
import ProductGroupModal from '../../components/ProductGroupModal';
import ProductDetailModal from '../../components/ProductDetailModal';
import { DataTableReducerInitialState, loadTableData, resetDataTable, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableSearchText, updateTableSearching, updateViewCheckoutModal } from '../../reducers/DataTable';
import { useNavigate } from 'react-router';
import { IRestField } from '../../interfaces/Fields/IRestField';
import Catalog from '../Catalog';
import GridModal from '../../components/GridModal';
import { Id, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { debounce } from "../../common/debounce";
import LotsModal from "../../components/ConsultationModal";
import ConsultationModal from "../../components/ConsultationModal";

interface IState {
  [key: string]: string | number;
}

const Cart: FC = () => {
  const [cookies] = useCookies(['selected_menu']);
  const [increase, setIncrease] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [groups, setGroups] = useState<IProductGroups[]>([] as IProductGroups[])
  const [listStyle, setListStyle] = useState<'grid' | 'list'>('list');
  const [productsFiltered, setProductsFiltered] = useState<IProduct[]>([] as IProduct[]);
  const [currentIndexProductCatalog, setCurrentIndexProductCatalog] = useState<number>(0);
  const [orderByText, setOrderByText] = useState('');

  const [modalFilterGroup, setModalFilterGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IProductGroups>({} as IProductGroups);

  const [changeItensPerPage, setChangeItensPerPage] = useState(0);

  const enterKeyPressed = useRef(false)

  const fieldService: FieldService = new FieldService();
  const productService: ProductService = new ProductService();

  const { tableLoading, tableTitles, viewCheckoutModal, tableOrder, tableData, tableLegend, tableTotalPages, tableSearchText, tableFieldFilters, tableFieldTypeConfig, tableCurrentPage, tableFilter } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { productList, priceTable, deleteProductList, itemsList, viewGridModal, viewConsultationModal, itemsCount, product, indice, isEditing, isCopying, dictionaryC6, dictionaryCK, quantityValue, modalQuantityState, itemFields, isCatalog, gridList, isConsultation } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { currentBranch, selectedClient, itensPerPage, currentCompany, orderBy } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { token, pwm: configuracoes, userType, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { fields, fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const totalItensOnCart = useMemo(() => {
    if (customizations['useCountdownByRowOnCart']) {
      return itemsList.reduce((prev, curr) => {
        return prev += Number(curr.quantity) > 0 ? 1 : 0
      }, 0) 
    } else {
      return Number.isInteger(itemsCount) 
      ? itemsCount 
      : itemsCount.toFixed(2) 
    }     
  }, [customizations, itemsList, itemsCount])
  
  const rowSize = customizations['useCompactLineOnBrowserTables'] ? 32 : 51
  const useDecimalProductQuantity = companyPolicies.find(police => police.property === 'useDecimalProductQuantity' && police.policy_value === 'true');
  const useCheckBalance = companyPolicies.find(policy => policy.property === 'useCheckBalance' && policy.policy_value === 'true');
  const notShowBalanceInCheckBalanceMessage = companyPolicies.find(policy => policy.property === 'notShowBalanceInCheckBalanceMessage' && policy.policy_value === 'true');
  const showLegendFilterInFooter = companyPolicies.find(policy => policy.property === 'showLegendFilterInFooter' && policy.policy_value === 'true');
  const useGrid = companyPolicies.findIndex(policy => policy.property === 'useGrid' && policy.policy_value === 'true') !== -1;
  const useLotsAndSubLots = useMemo(() => companyPolicies.find(policy => policy.property === 'useLotsAndSubLots' && policy.policy_value === 'true'), [companyPolicies]);
  const useGridColor = companyPolicies.find(policy => policy.property === 'useGridColor' && policy.policy_value === 'true');
  const useDiscountValidationByClientField = companyPolicies.find(policy => policy.property === 'useDiscountValidationByClientField' && policy.policy_value === 'true');
  const useDiscountValidationByProductField = companyPolicies.find(policy => policy.property === 'useDiscountValidationByProductField' && policy.policy_value === 'true');
  const useDiscountByWholesaleQuantity = companyPolicies.find(policy => policy.property === 'useDiscountByWholesaleQuantity' && policy.policy_value === 'true');
  const usePaymentConditionByTotalPrice = useMemo(() => companyPolicies.find(policy => policy.property === 'usePaymentConditionByTotalPrice' && policy.policy_value === 'true'), [companyPolicies]);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setUIComponentState } = useContext(UIContext);
 
  const {
    isImported,
    handleCovertProductListToCart,
    checkBalance,
    handleOpenProductDetail,
    handleOpenGridModal,
    handleOpenConsultationModal,
    handleImportOrderOrBudgetToCart
  } = useContext(CartContext);

  const utils = new Utils();

  async function getFields() {
    const toastId = toast.loading('Por favor aguarde...')
    try {
      dispatch(updateTableLoading({ value: true }));
      const body: IRestFieldRequestData = {
        alias: selectedMenu.nome === 'Pedidos' ? 'SC5' : 'SCJ',
        alias2: selectedMenu.nome === 'Pedidos' ? 'SC6' : 'SCK',
        url: '',
        tab: '1',
        usuario: '',
        indice: indice ? String(indice) : '',
        ordem: indice ? "&$&1" : 1,
        token: token,
        customerKey: selectedClient.indice,
      }

      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.errorMessage || data.msg,
        }).then(() => { navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets') })
        return;
      }

      if (status === 200) {
        const useClientPaymentCondition = companyPolicies.find(police => police.property === 'useClientPaymentCondition' && police.policy_value === 'true');
        const useClientCNPJ = companyPolicies.find(police => police.property === 'useClientCNPJ' && police.policy_value === 'true');
        const useClientCondSegment = companyPolicies.find(police => police.property === 'useClientCondSegment' && police.policy_value === 'true');
        const useClientType = companyPolicies.find(police => police.property === 'useClientType' && police.policy_value === 'true');
        const useClientStore = companyPolicies.find(police => police.property === 'useClientStore' && police.policy_value === 'true');
        const useTransportTypeHidden = companyPolicies.find(police => police.property === 'useTransportTypeHidden' && police.policy_value === 'true');
        const useClientValuesOnOrderOrBudget = companyPolicies.find(police => police.property === 'useClientValuesOnOrderOrBudget' && police.policy_value === 'true');

        const hideDiscountAndIncreaseFieldsForClientsTypeUser = companyPolicies.find(police => police.property === 'hideDiscountAndIncreaseFieldsForClientsTypeUser' && police.policy_value === 'true');
        const useCurrentDateInEmission = companyPolicies.find(police => police.property === 'useCurrentDateInEmission' && police.policy_value === 'true');
        const useObligationFieldsByMarketType = companyPolicies.find(police => police.property === 'useObligationFieldsByMarketType' && police.policy_value !== '');
        const useObligationDeliveryFieldsByFreightType = companyPolicies.find(police => police.property === 'useObligationDeliveryFieldsByFreightType' && police.policy_value !== '');

        if (useClientValuesOnOrderOrBudget && !isEditing) {
          const fieldsToFill = {} 
          companyPolicies.find(police => police.policy === useClientValuesOnOrderOrBudget.dependencie).policy_value.split(';').filter(item => item !== '').map(item => {
            const [key, value] = item.split('=')
    
            fieldsToFill[`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ key.trim() }`] = value.trim()
          })

          for (const [key, value] of Object.entries<string>(fieldsToFill)) {
            data.campos.map(campo => {
              if (campo.nome === key.toUpperCase()) {
                campo.conteudo = selectedClient[value.toLowerCase()];
              }
            })
          }
        }

        if (useClientPaymentCondition) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_CONDPAG`) {
              campo.conteudo = selectedClient.a1_cond || '';
            }
          })
        }

        if (useClientCNPJ) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_CNPJ`) {
              campo.conteudo = String(selectedClient.a1_cgc).replace(/\D/g, "") || '';
            }
          })
        }

        if (useClientCondSegment) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_XCODSEG`) {
              campo.conteudo = selectedClient.a1_codseg || '';
            }
          })
        }

        if (useClientType) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_TIPOCLI`) {
              campo.conteudo = selectedClient.a1_tipo || '';
            }
          })
        }

        if (useTransportTypeHidden && userType === '2') {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_XTRANSP`) {
              campo.conteudo = '1';
              campo.tipo = 4;
            }
          })
        }

        if (useClientStore) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_LOJA`) {
              campo.conteudo = data.campos[`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_LOJACLI`] || '';
            }
          })
        }

        if (useCurrentDateInEmission) {
          data.campos.map(campo => {
            if (campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_EMISSAO`) {
              campo.conteudo = moment();
            }
          })
        }

        if (useObligationFieldsByMarketType) {
          const fieldsToObligation = companyPolicies.find(policie => policie.policy === useObligationFieldsByMarketType.dependencie).policy_value.split(';')
          const marketType = selectedClient['a1_dvtip'].toString().replace('&$&', '');
          fieldsToObligation.map(fieldName => {
            if (data.campos.find(item => item.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ fieldName }`)) {
              data.campos.find(item => item.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ fieldName }`).obrigado = marketType === useObligationFieldsByMarketType.policy_value ? 'S' : 'N';
            }
          })
        }

        if (useObligationDeliveryFieldsByFreightType && isEditing) {
          const fieldFrete = data.campos.find(item => item.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_XTPFRET`);
          const freightType = fieldFrete.conteudo;
          const fieldsToObligation = companyPolicies.find(policie => policie.policy === useObligationDeliveryFieldsByFreightType.dependencie).policy_value.split(';')

          if (useObligationFieldsByMarketType) {
            const marketType = selectedClient['a1_dvtip'].toString().replace('&$&', '');
            if (freightType === useObligationDeliveryFieldsByFreightType.policy_value) {
              fieldsToObligation.map(fieldName => {
                data.campos.find(item => item.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ fieldName }`).obrigado = marketType === 'T' ? 'S' : 'N';
              })
            }
          } else {
            if (freightType === useObligationDeliveryFieldsByFreightType.policy_value) {
              fieldsToObligation.map(fieldName => {
                data.campos.find(item => item.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ fieldName }`).obrigado = 'S';
              })
            }
          }
        }

        if (hideDiscountAndIncreaseFieldsForClientsTypeUser && userType === '2') {
          if (data.campos1.item01.find(field => field.regra === 'desconto')) data.campos1.item01.find(field => field.regra === 'desconto').tipo = '4';
          if (data.campos1.item01.find(field => field.regra === 'acrecimo')) data.campos1.item01.find(field => field.regra === 'acrecimo').tipo = '4';
          if (data.campos1.item01.find(field => field.regra === 'prcunit')) data.campos1.item01.find(field => field.regra === 'prcunit').tipo = '4';
        }

        let updateItemFieldsArray

        if (isEditing || isCopying) {
          updateItemFieldsArray = await getFieldsWithoutData()
        } else {
          updateItemFieldsArray = data.campos1[Object.keys(data.campos1)[0]]
          dispatch(updateItemFields({ value: updateItemFieldsArray }))
        }
        getHeaderData(data.campos)
        if (configuracoes.usepricetable) {
          const tableName = selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA'
          const table = data.campos.find(field => field.nome === tableName)
          dispatch(updatePriceTable({ value: table.conteudo }))
          if (table.conteudo === '') {
            Swal.fire({
              icon: 'info',
              title: "Informativo",
              text: "É necessário preencher o filtro de Tabela de Preço para buscar os produtos."
            })
            toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
            dispatch(updateTableLoading({ value: false }));
          } else {
            getProducts(updateItemFieldsArray, '', table.conteudo)
          }
        } else {
          getProducts(updateItemFieldsArray, "")
        }
      }

      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar os campos.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      if (error.response.data.errorCode === 403) {
        navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets')
        return Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: error.response.data.errorMessage,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo deu errado!',
        })
      }
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true });
      dispatch(updateTableLoading({ value: false }));

    }
  }

  async function getFieldsWithoutData() {
    try {
      dispatch(updateTableLoading({ value: true }));
      const body: IRestFieldRequestData = {
        alias: selectedMenu.nome === 'Pedidos' ? 'SC5' : 'SCJ',
        alias2: selectedMenu.nome === 'Pedidos' ? 'SC6' : 'SCK',
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token,
        customerKey: selectedClient.indice,
      }

      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.errorMessage || data.msg,
        }).then(() => { navigate('/orders') })
        return;
      }

      if (status === 200) { 
        const updateItemFieldsArray = data.campos1[Object.keys(data.campos1)[0]]
        dispatch(updateItemFields({ value: updateItemFieldsArray }))

        return updateItemFieldsArray
      }
    } catch (error) {
      throw error
    } 
  }

  async function getGridFields() {
    try {
      const body: IRestFieldRequestData = {
        alias: "SB4",
        alias2: "",
        url: '',
        tab: '1',
        usuario: '',
        indice: "",
        ordem: 1,
        token: token,
      }

      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        dispatch(fillGridFieldAndFieldValues({ fields: data.campos }))
      } else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const getGroups = async () => {

    try {
      dispatch(updateTableLoading({ value: true }));

      const { data, status, request } = await productService.getGroups(currentBranch.codigo, currentCompany);

      if (status === 200) {
        if (data.status === 'ok') {
          setGroups(data.data);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data.msg || 'Algo deu errado!',
          })
        }
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }

  }

  const getProducts = async (itemFields: IRestField[], order: string, pTable?: string | number) => {
    const toastId = toast.loading('Por favor aguarde...')

    try {
      dispatch(updateTableLoading({ value: true }));
      const { data, status, request } = await productService.index(token, configuracoes.usedirectproductsear ? 1 : itensPerPage, 1, selectedClient.indice?.toString(), currentBranch.codigo, currentCompany, order, useGrid, undefined, undefined, pTable);
      const { titulo, dados, legenda, filtros, totalPages, ordem, fieldTypeConfig, dicionary, oculto, help, nivel }: IPaginatedServiceResponse = data;

      if (status === 200) {
        if (!configuracoes.usedirectproductsear || isConsultation) {
          const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

          dispatch(loadTableData({
            tableData: dados,
            tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
            tableTitles: titulo,
            tableHelps: help,
            tableLegend: legenda || [],
            tableFieldFilters: filtros,
            tableTotalPages: totalPages,
            tableFieldTypeConfig: fieldTypeConfig
          }))
          setProductsFiltered(dados);
        }

        dispatch(updateDictionaryC6({
          value: {
            ...dicionary.SC6,
            ...dictionaryEXC6,
          }
        }))
        dispatch(updateDictionaryCK({
          value: {
            ...dicionary.SCK,
            ...dictionaryEXCK,
          }
        }))

        if (isEditing || isCopying) {
          const dictionary = selectedMenu.nome === 'Pedidos'
            ? {
              ...dicionary.SC6,
              ...dictionaryEXC6
            } : {
              ...dicionary.SCK,
              ...dictionaryEXCK
            }

          await getOrderOrBudgetItensList(dictionary, itemFields)
        }
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })

      if (isImported) {
        await handleImportOrderOrBudgetToCart()
      }
      
      dispatch(updateTableLoading({ value: false }));

      if (configuracoes.usedirectproductsear && selectedMenu.nome !== 'Consulta' && !isImported) {
        dispatch(updateViewCheckoutModal({ value: true }))
      }
    }
  }

  async function orderProduct(order: string) {
    setOrderByText(order);
    await getProductsSearch(order, tableFilter.filter, tableFilter.statusFilter)
  }

  function handleOrderBy(column: string) {
    if (tableFieldFilters.character.includes(column)) {
      if (Object.keys(orderBy)[0] === column) {
        switch (orderBy[column]) {
          case "ascending":
            orderProduct(`${ column } DESC`)
            dispatch(updateOrderBy({ value: { [column]: "descending" } }))
            break;
          case "descending":
            orderProduct(``)
            dispatch(updateOrderBy({ value: { [column]: null } }))
            break;
          case null:
            orderProduct(`${ column } ASC`)
            dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
            break;
        }
      } else {
        orderProduct(`${ column } ASC`)
        dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
      }
    }

  }

  async function getHeaderData(fields: IRestField[]) {
    try {
      if (!isEditing || !isCopying) {
        return dispatch(fillFieldAndFieldValues({ fields: fields }));
      }
      const url = selectedMenu.nome === 'Pedidos' ? 'wspedido' : 'wsorcamento'
      const response = await productService.getHeaderData(url, currentCompany, currentBranch.codigo, token, indice)
      const { dados } = response.data;
      if (response.status === 200) {
        const fieldsWithValues = fields.map(field => {
          const value = dados[0][field.nome.toLowerCase()]
          field.conteudo = value
          return field
        })
        dispatch(fillFieldAndFieldValues({ fields: fieldsWithValues }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getOrderOrBudgetItensList(dictionary: IDictionary, itemFields: IRestField[]) {
    try {
      // const url = selectedMenu.nome === 'Pedidos' ? 'wspedido' : 'wsorcamento'
      const url = selectedMenu.nome === 'Pedidos' ? `wspeditem${ useGrid ? '/grade' : '' }` : `wsorcitem${ useGrid ? '/grade' : '' }`

      const response = await productService.getItens(url, currentCompany, currentBranch.codigo, token, indice);
      const { dados } = response.data

      if (response.status === 200) {
        dispatch(updateUpdateItens({ value: dados }))
        const itemFieldsValue = dados.map(item => {
          return itemFields.map(field => {
            return {
              ...field,
              conteudo: item[field.nome.toLowerCase()]
            }
          })
        })
 
        dispatch(updateProductList({ value: [...itemFieldsValue] }));
        const productsSelected: IProduct[] = []
        dados.map(item => {
          const product = {}
          const keys = Object.keys(item)
          keys.forEach(key => {
            if (dictionary[key.toUpperCase()] !== undefined) {
              product[dictionary[key.toUpperCase()]] = item[key]
            }
          })
          if (useGrid) {
            product['linha'] = item.grade.linha
            if (item.grade['grdfix'] !== undefined) product['b4_grdfix'] = item.grade['grdfix']
            if (item.grade['qtdmin'] !== undefined) product['b4_qtdmin'] = item.grade['qtdmin']
            if (item.grade['linhap'] !== undefined) product['b4_linhap'] = item.grade['linhap']
            if (item.grade['sessao'] !== undefined) product['b4_sessao'] = item.grade['sessao']
            if (item.grade['especi'] !== undefined) product['b4_especi'] = item.grade['especi']
            if (item.grade['qtdmin'] !== undefined) product['b4_qtdmin'] = item.grade['qtdmin']
          }
          productsSelected.push(product)
        })

        const totalItens = productsSelected.reduce((previousValue, currentValue) => previousValue += Number(currentValue.quantity), 0)
        
        dispatch(updateItemsList({ value: productsSelected }))
        dispatch(updateGridList({ value: productsSelected }))
        dispatch(updateItemsCount({ value: totalItens }))

      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  const getProductsWithPage = async (page: number) => {
    if (page > tableTotalPages) {
      return
    }

    const toastId = toast.loading('Por favor aguarde...')

    try {

      dispatch(updateTableLoading({ value: true }));
      const { filter, statusFilter } = tableFilter;

      const { data, status, request } = await productService.index(token, itensPerPage, page, selectedClient.indice?.toString(), currentBranch.codigo, currentCompany, orderByText, useGrid, filter, statusFilter, priceTable);
      const { titulo, dados, ordem, oculto, legenda, filtros, fieldTypeConfig, totalPages, help, nivel }: IPaginatedServiceResponse = data;


      if (status === 200 || status === 201) {
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda || [],
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
        setProductsFiltered(dados);
        dispatch(updateTableCurrentPage({ value: page }));
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getProductsSearch = async (order: string, filters?: string | undefined, statusFilter?: { [key: string]: string } | undefined) => {
    const toastId = toast.loading('Por favor aguarde...')
    try {
      dispatch(updateTableLoading({ value: true }));

      const { data, status, request } = await productService.index(token, itensPerPage, 1, selectedClient.indice?.toString(), currentBranch.codigo, '', order, useGrid, filters, statusFilter, priceTable);
      const { titulo, dados, ordem, oculto, legenda, filtros, totalPages, fieldTypeConfig, help, nivel }: IPaginatedServiceResponse = data;

      if (status === 200) {
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda || [],
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))
        setProductsFiltered(dados);

      } else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: data.errorMessage,
          statusHttp: status,
          urlHttp: request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      toast.update(toastId, { isLoading: false, autoClose: 10, closeOnClick: true })
      dispatch(updateTableLoading({ value: false }));
    }

  }

  const getProductsWithTextSearch = useCallback(debounce(async (searchValue: string) => {
    // Load
    dispatch(updateTableCurrentPage({ value: 1 }));

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();

      const searchString = useGrid ? utils.formatSearchStringToPaginationServiceGrid(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText) : utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValueText }));

      await getProductsSearch(orderByText, searchString);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }))
      dispatch(updateTableSearchText({ value: '' }))

      await getProductsSearch(orderByText);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 3000), [orderByText, tableFieldTypeConfig, tableFilter]);

  const setFilter = async (filterId: string) => {
    if (fieldValues[selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA'] === '' && configuracoes.usepricetable) {
      return Swal.fire({
        icon: 'info',
        title: "Informativo",
        text: "É necessário preencher o filtro de Tabela de Preço para buscar os produtos."
      })
    }
    dispatch(updateTableCurrentPage({ value: 1 }));


    if (filterId) {
      const searchString = useGrid ? utils.formatSearchStringToPaginationServiceGrid([{ type: "text", name: "B1_GRUPO" }], ["B1_GRUPO"], "eq", filterId) : utils.formatSearchStringToPaginationService([{ type: "text", name: "B1_GRUPO" }], ["B1_GRUPO"], "eq", filterId);
      await getProductsSearch(orderByText, searchString)
    } else {
      await getProductsSearch(orderByText)
    }

  }

  async function filterProduct(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }))
    await getProductsSearch(orderByText, filter, statusFilter)
  }

  const calcValueWithConversion = (type: "increase" | "decrease") => {
    if (quantityValue === 0 && type === "decrease") return 0;

    const multiplier = product.b1_conv ? Number(product.b1_conv) : product.b1_qe ? Number(product.b1_qe) : 1;

    return multiplier;
  }

  const valueWithConversion = (cod: string | number) => {

    const balance = tableData.find(item => item['b1_cod'] === cod || item['b1_qe'] === cod)
    if (useDiscountByWholesaleQuantity) {
      if (balance && balance['b1_xqtdat']) {
        return Number(balance['b1_xqtdat']);
      } else {
        return 1;
      }
    } else {
      if (balance && (balance['b1_conv'] || balance['b1_qe'])) {
        return Number(balance['b1_conv'] || balance['b1_qe']);
      } else {
        return 1;
      }
    }
  }

  const conversionCalc = (inputValue: number, balance?: string | number): number => {
    const multiplier = balance ? Number(balance) : product.b1_conv ? Number(product.b1_conv) : product.b1_qe ? Number(product.b1_qe) : 0;
    const calc = (inputValue % multiplier);
    if (calc !== 0 && !isNaN(calc)) {
      if (inputValue < multiplier) {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>(${ multiplier })</b>. O valor foi alterado para o mínimo - <b>(${ multiplier })</b>`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return multiplier;
      } else {
        Swal.fire({
          title: 'Atenção!',
          html: `O valor de conversão é <b>(${ multiplier })</b>. O valor foi alterado para o mínimo - <b>(${ inputValue - calc })</b>`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
        return inputValue - calc;
      }
    }

    return inputValue;
  }

  const currentItemQuantity = (b1_cod: string | number = product.b1_cod): number => {
    return itemsList.find((item) => item.b1_cod === b1_cod) ? parseFloat(String(itemsList.find((item) => item.b1_cod === b1_cod)?.quantity)) : 0;
  }

  const currentItemIncrase = (b1_cod: string | number = product.b1_cod) => {
    return Number(itemsList.find((item) => item.b1_cod === b1_cod) ? itemsList.find((item) => item.b1_cod === b1_cod)?.increase : '0');
  }

  const currentItemDiscount = (b1_cod: string | number = product.b1_cod) => {
    return Number(itemsList.find((item) => item.b1_cod === b1_cod) ? itemsList.find((item) => item.b1_cod === b1_cod)?.discount : '0');
  }

  const handleChangeQuantityProductValue = (value: number | string, cod: string | number) => {

    if (isNaN(Number(value))) {
      value = 0
    }

    if (!useDecimalProductQuantity && !Number.isInteger(value)) {
      value = Math.round(Number(value))
    }

    if (Number(value) < 0) {
      dispatch(updateQuantityValue({ value: 0 }))
      return
    }

    const newProduct = tableData.find((item) => item.b1_cod === cod)
 
    const productAlreadyAdded = itemsList.find(product => product.b1_cod === cod)
    if (productAlreadyAdded) {
      const otherProducts = itemsList.filter(product => product.b1_cod !== productAlreadyAdded.b1_cod)
      const itensCount = itemsCount - Number(productAlreadyAdded.quantity)

      if (value === 0) {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + value, itemsList: [...otherProducts] }))

        const fieldName = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
        const selectedProduct = productList.find(fields => fields.find(field => field.nome === fieldName).conteudo === cod)

        if (isEditing && selectedProduct.find(field => field.nome === (selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '' && selectedProduct.find(field => field.nome === (selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM')).conteudo !== '&&') {
          dispatch(updateDeleteProductList({ value: [...deleteProductList, { AUTDELETA: 'S', [selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM']: selectedProduct.find(field => field.nome === (selectedMenu.nome === 'Pedidos' ? 'C6_ITEM' : 'CK_ITEM')).conteudo }]  }));
        }
      } else {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + parseFloat(value.toString()), itemsList: [...otherProducts, { ...productAlreadyAdded, quantity: value, discount: productAlreadyAdded.discount || 0, increase: productAlreadyAdded.increase || 0 }] }))
      }

    } else {
      dispatch(addNewProductQuantity({ itemsCount: itemsCount + parseFloat(value.toString()), itemsList: [...itemsList, { ...newProduct, quantity: value, discount: newProduct.discount || 0, increase: newProduct.increase || 0 }] }))
    }
  }

  const validateValueWithConversion = () => {

    const multiplier = Number(product.b1_conv) || Number(product.b1_qe) || 0;

    const calc = (quantityValue % multiplier);

    if (calc !== 0 && !isNaN(calc)) {

      if (quantityValue < multiplier) {
        const message = `
          O valor de conversão é ${ multiplier }. 
          O valor foi alterado para o mínimo: ${ multiplier }.
        `;
        dispatch(updateQuantityValue({ value: multiplier }))

        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: message,
          statusHttp: 500,
          urlHttp: '',
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: message,
              statusHttp: 500,
              urlHttp: '',
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
        return false;
      } else {
        const newValue = quantityValue - calc;
        const message = `
          O valor de conversão é ${ multiplier }!
          O valor foi alterado para ${ newValue }, que é o valor mais próximo abaixo do digitado,respeitando o valor de conversão!
        `
        dispatch(updateQuantityValue({ value: newValue }))

        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: message,
          statusHttp: 500,
          urlHttp: '',
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: message,
              statusHttp: 500,
              urlHttp: '',
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
        return false;
      }

    }

    return true;
  }

  const addGridProductToCart = () => {
    if (!validateValueWithConversion()) return;
    const productCopy = { ...product };
    let value = quantityValue;

    if (useCheckBalance) {
      value = checkBalance(Number(productCopy['b1_conv'] || productCopy['b1_qe']), Number(productCopy['b1_xsaldo']), quantityValue)
    }

    const productAlreadyAdded = itemsList.find(product => product.b1_cod === productCopy.b1_cod)
    if (productAlreadyAdded) {
      const otherProducts = itemsList.filter(product => product.b1_cod !== productCopy.b1_cod)
      const itensCount = itemsCount - Number(productAlreadyAdded.quantity)

      if (value === 0) {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + value, itemsList: [...otherProducts] }))

      } else {
        dispatch(addNewProductQuantity({ itemsCount: itensCount + value, itemsList: [...otherProducts, { ...productCopy, quantity: value, discount: discount, increase: increase }] }))
      }

      setDiscount(0)
      setIncrease(0)

    } else {
      dispatch(addNewProductQuantity({ itemsCount: itemsCount + value, itemsList: [...itemsList, { ...productCopy, quantity: value, discount: discount, increase: increase }] }))

      setDiscount(0)
      setIncrease(0)
    }
  }

  const handleDiscountOrIncreaseChange = (type: 'discount' | 'increase', value: number) => {
    if (type === 'discount') {
      const maxDiscountAllowed = selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value] !== undefined ? Number(selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value]) : 0
      if (useDiscountValidationByClientField && Number(value) > maxDiscountAllowed) {
        Swal.fire({
          title: 'Atenção!',
          text: `Não é possível ter um valor maior que ${ maxDiscountAllowed }% de desconto!`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });

        value = maxDiscountAllowed
      }

      const maxDiscountProductAllowed = product[companyPolicies.find(policy => policy.policy === useDiscountValidationByProductField.dependencie).policy_value.toLowerCase()] !== undefined ? Number(product[companyPolicies.find(policy => policy.policy === useDiscountValidationByProductField.dependencie).policy_value.toLowerCase()]) : 0
      if (useDiscountValidationByProductField && Number(value) > maxDiscountProductAllowed) {
        Swal.fire({
          title: 'Atenção!',
          text: `Não é possível ter um valor maior que ${ maxDiscountProductAllowed }% de desconto!`,
          icon: 'warning',
          confirmButtonText: 'Ok',
        });

        value = maxDiscountProductAllowed
      }

      if (value >= 100) {
        setDiscount(99)

        return Swal.fire({
          title: 'Atenção!',
          text: 'Não é possível ter um valor maior que 99% de desconto!',
          icon: 'warning',
          confirmButtonText: 'Ok',
        });
      }
      setDiscount(value)
      setIncrease(0)
    } else {
      setIncrease(value)
      setDiscount(0)
    }
  }

  const handleCartClear = async () => {
    dispatch(clearCart());
    dispatch(clearFieldValues());
    dispatch(updateViewCheckoutModal({ value: false }))
    if (configuracoes.usepricetable) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA', value: priceTable }))
    }
    if (!configuracoes.usedirectproductsear) {
      dispatch(updateViewCheckoutModal({ value: false }))
    }
  }

  function handleCleanInputSearch() {
    document.querySelector<HTMLInputElement>('#inputSearchProductList').value = ''
    getProductsWithTextSearch('enter', '');
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearchProductList').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (modalQuantityState) {
      const itemQuantity = currentItemQuantity();
      setIncrease(currentItemIncrase() || 0);
      setDiscount(currentItemDiscount() || 0);

      if (Number(itemQuantity) === 0) {
        dispatch(updateQuantityValue({ value: Number(product.b1_conv || product.b1_qe) || 0 }))
      }
    }
  }, [modalQuantityState])

  useEffect(() => {
    dispatch(resetDataTable())
    handleCartClear();
    getFields();
    if (useGrid) {
      getGridFields();
    }
    if (configuracoes.showproductgroups) {
      getGroups();
    }
    if (customizations['cartAsGrid'] && !customizations['cartAsList']) {
      setListStyle('grid');
    } else if (!customizations['cartAsGrid'] && customizations['cartAsList']) {
      setListStyle('list');
    }
  }, [])

  useEffect(() => {
    if (!configuracoes.usedirectproductsear && changeItensPerPage > 0) {
      getProductsWithPage(1);
    }
  }, [itensPerPage])

  useEffect(() => {
    if (cookies.selected_menu) {
      if (cookies.selected_menu.nome === "Consulta") dispatch(updateIsConsultation({ value: true }))
    }
  }, [selectedMenu])

  return (
    <PageDefault>

      <DefaultHeader />

      <Content>
        <SideMenu />

        <Browser title={ `Lista de produtos` } icon={ <ShoppingBagOpen size={ 24 } color={ styleSheet.mainColor } /> }>
          <BrowserContent>
            {
              isCatalog ? (
                <Container style={ { width: '100%', height: configuracoes.usedirectproductsear ? 'auto' : `${ window.innerHeight - 285 }px` } }>
                  <Catalog
                    productIndex={ currentIndexProductCatalog }
                    onNextProduct={ () => { setCurrentIndexProductCatalog(currentIndexProductCatalog + 1) } }
                    onPreviewProduct={ () => { setCurrentIndexProductCatalog(currentIndexProductCatalog - 1) } }
                    onOpenCart={ () => {
                      dispatch(updateViewCheckoutModal({ value: true }))
                      handleCovertProductListToCart()
                    } }
                  />
                </Container>
              ) : (
                <Container style={ { width: '100%', height: configuracoes.usedirectproductsear ? 'auto' : `${ window.innerHeight - 305 }px` } }>
                  <Menu style={ { boxShadow: '0 0 3px 2px #dedede' } } borderless>
                    <Menu.Item fluid>
                      { configuracoes.usepricetable &&
                        <F3
                          clientId={ null }
                          token={ token }
                          consulta={ fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`) ? fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`).f3Post.consulta : '' }
                          conteudo={ String(priceTable) }
                          email={ fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`) ? fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`).f3Post.email : '' }
                          requisito={ fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`) ? fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`).f3Post.requisito : '' }
                          requisitocont={ fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`) ? fields.find(campo => campo.nome === `${ selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA' }`).f3Post.requisitocont : '' }
                          readonly={ isEditing || isCopying }
                          placeholder={ 'Tabela de Preço' }
                          label={ 'titulo' }
                          name={ '_TABELA' }
                          onChange={ (f3SelectReturn) => {
                            if (itemsCount > 0 || gridList.length > 0) {
                              return Swal.fire({
                                title: 'Confirmar ação?',
                                text: 'Os itens do carrinhos serão perdidos!',
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim, confirmar!',
                                cancelButtonText: 'Cancelar'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(clearCart())
                                  dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA', value: f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo }))
                                  dispatch(updatePriceTable({ value: f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo }))
                                  getProducts(itemFields, orderByText, f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo);
                                }
                              });
                            } else {
                              dispatch(clearCart())
                              dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA', value: f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo }))
                              dispatch(updatePriceTable({ value: f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo }))
                              getProducts(itemFields, orderByText, f3SelectReturn.find(({ nome }) => nome.toLowerCase().includes('cod') || nome.toLowerCase().includes('chave')).conteudo);
                            }
                          } }
                          onChangeNewF3={ (f3SelectReturn, trigger) => {
                            if (itemsCount > 0 || gridList.length > 0) {
                              return Swal.fire({
                                title: 'Confirmar ação?',
                                text: 'Os itens do carrinhos serão perdidos!',
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Sim, confirmar!',
                                cancelButtonText: 'Cancelar'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(clearCart());
                                  dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA', value: f3SelectReturn[trigger[0]] }))
                                  dispatch(updatePriceTable({ value: f3SelectReturn[trigger[0]] }))
                                  getProducts(itemFields, orderByText, f3SelectReturn[trigger[0]]);
                                }
                              });
                            } else {
                              dispatch(clearCart());
                              dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_TABELA' : 'CJ_TABELA', value: f3SelectReturn[trigger[0]] }))
                              dispatch(updatePriceTable({ value: f3SelectReturn[trigger[0]] }))
                              getProducts(itemFields, orderByText, f3SelectReturn[trigger[0]]);
                            }
                          } }
                        />
                      }
                      <Input
                        id="inputSearchProductList"
                        style={ { width: '35rem', height: '4rem', marginLeft: configuracoes.usepricetable ? '1rem' : '0' } }
                        icon={ tableSearchText.length !== 0 ? <Icon name='close' link onClick={ handleCleanInputSearch } /> : <Icon name='search' link /> }
                        loading={ tableLoading }
                        disabled={ tableLoading }
                        placeholder='Pesquisar...'
                        defaultValue={ tableSearchText }
                        onChange={ event => {
                          dispatch(updateTableSearching({ value: true }))
                          getProductsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)
                        } }
                        onBlur={ event => {
                          getProductsWithTextSearch(enterKeyPressed.current ? 'enter' : '', event.target.value)
                        } }
                      />

                      {
                        groups.length > 0 && (
                          <Button
                            icon
                            labelPosition='right'
                            color='blue'
                            style={ { height: '3rem', fontSize: '1.1rem', backgroundColor: styleSheet.mainColor, marginLeft: '2rem' } }
                            onClick={ () => setModalFilterGroup(true) }
                          >
                            Filtros
                            <Icon name='filter' />
                          </Button>
                        )
                      }
                    </Menu.Item>
                    <Menu.Item position='right'>
                      {
                        (customizations['cartAsGrid'] && customizations['cartAsList']) &&
                        <Button.Group style={ { marginRight: '2rem' } }>
                          <Button
                            onClick={ () => setListStyle('grid') }
                            style={ { height: '3rem', backgroundColor: listStyle === 'grid' ? styleSheet.contrastColor : '' } }
                          >
                            <GridIcon color={ listStyle === 'grid' ? '#fff' : '#000' } size="15" />
                          </Button>
                          <Button.Or text="ou" />
                          <Button
                            onClick={ () => setListStyle('list') }
                            style={ { height: '3rem', backgroundColor: listStyle === 'list' ? styleSheet.contrastColor : '' } }
                          >
                            <ListIcon color={ listStyle === 'list' ? '#fff' : '#000' } size="15" />
                          </Button>
                        </Button.Group>
                      }
                      {
                        !isConsultation ? (
                          <Label
                            as="a"
                            circular
                            style={ { position: 'relative', width: '4rem', height: '4rem', backgroundColor: styleSheet.mainColor, display: 'flex', alignItems: 'center', justifyContent: 'center' } }
                            onClick={ () => {
                              if (configuracoes.usedirectproductsear && configuracoes.usepricetable && !priceTable) {
                                return Swal.fire({
                                  title: 'Atenção!',
                                  text: 'É necessário preencher o filtro de Tabela de Preço para buscar os produtos.',
                                  icon: 'warning',
                                  confirmButtonText: 'Ok',
                                });
                              }
                              dispatch(updateViewCheckoutModal({ value: true }))
                              handleCovertProductListToCart()
                            } }
                          >
                            <CartIcon size={ 22 } color="#fff" />
                            <Label style={ { position: 'absolute', top: '-5px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: styleSheet.contrastColor, color: styleSheet.shape, borderRadius: '50%' } } size="medium" floating>
                              {
                                useGrid ? (
                                  <span style={ { fontSize: '1.1rem' } }>
                                    { gridList.length || 0 }
                                  </span>
                                ) : (
                                  <span style={ { fontSize: '1.1rem' } }>
                                    {totalItensOnCart}
                                  </span>
                                )
                              }
                            </Label>
                          </Label>
                        ) : null
                      }
                    </Menu.Item>
                  </Menu>
                  {
                    listStyle === 'list' && (
                      <>
                        <TableContainer>
                          <Table style={ { boxShadow: '0 0 3px 2px #dedede' } } celled compact={customizations['useCompactLineOnBrowserTables'] ? 'very' : true} sortable selectable color='teal'>
                            {
                              tableLoading ?
                                <div style={ { width: '100%', height: '100%' } }>
                                  <Skeleton baseColor='#DDD' height={ 50 } width="100%" count={ itensPerPage + 1 } />
                                </div>
                                : <>
                                  <Table.Header fullWidth>
                                    <Table.Row>
                                      {
                                        tableOrder.map((column, index) => {
                                          return (
                                            <Table.HeaderCell
                                              className='dataHeaderTableFixed'
                                              key={ `${ column } - ${ index }` }
                                              onClick={ () => handleOrderBy(column) }
                                              sorted={ orderBy[column] || null }

                                            >
                                              <TableColumnText>
                                                { tableTitles[column] }
                                              </TableColumnText>
                                            </Table.HeaderCell>
                                          );
                                        })
                                      }
                                      {
                                        !isConsultation && useLotsAndSubLots ? (
                                          <Table.HeaderCell textAlign="center" className='dataHeaderTableFixedActions' width={ 1 } key='actions'>
                                            Ações
                                          </Table.HeaderCell>
                                        ) : null
                                      }
                                      {
                                        !isConsultation ? (
                                          <Table.HeaderCell textAlign="center" className='dataHeaderTableFixedActions' width={ 1 } key='actions'>
                                            { useGrid ? 'Ações' : 'Quantidade' }
                                          </Table.HeaderCell>
                                        ) : null
                                      }
                                    </Table.Row>
                                  </Table.Header>

                                  <Table.Body>
                                    {
                                      productsFiltered.map((row, index) => {
                                        return (
                                          <Table.Row
                                            key={ index }
                                            active={ useGrid ? !!gridList.find(grid => useGridColor ? grid.b4_grdcod === row.b4_grdcod : grid.indice === row.indice) : currentItemQuantity(row['b1_cod']) && currentItemQuantity(row['b1_cod']) > 0 }
                                            onClick={ () => {
                                              console.log()
                                            } }
                                            style={ { cursor: 'pointer' } }
                                          >
                                            {
                                              tableOrder.map((columnName) => {
                                                return (
                                                  <Table.Cell
                                                    key={ columnName }
                                                    onClick={ () => {
                                                      if (columnName.includes('XIMG')) {
                                                        handleOpenProductDetail(row[columnName.toLowerCase()] ? String(row[columnName.toLowerCase()]) : defaultProductImg, row['b1_cod'])
                                                      }
                                                    } }
                                                  >
                                                    {
                                                      !!tableLegend.find(({ campo }) => campo === columnName) &&
                                                      <Label
                                                        size='large'
                                                        style={ {
                                                          background: tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]) ? tableLegend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]).cor : '#333',
                                                          color: '#fff'
                                                        } }
                                                      >
                                                        { row[columnName.toLowerCase()].toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2) }
                                                      </Label>
                                                    }
                                                    {
                                                      !tableLegend.find(({ campo }) => campo === columnName) && columnName.includes('XIMG') &&
                                                      <Image
                                                        size="mini"
                                                        src={ row[columnName.toLowerCase()] || defaultProductImg }
                                                        onError={ ({ currentTarget }) => {
                                                          currentTarget.onerror = null;
                                                          currentTarget.src = defaultProductImg;
                                                        } }
                                                      />
                                                    }
                                                    {
                                                      !tableLegend.find(({ campo }) => campo === columnName) && !columnName.includes('XIMG') &&
                                                      <TableDataText>
                                                        {
                                                          tableFieldTypeConfig.numeric && tableFieldTypeConfig.numeric.find(num => num.name === columnName && num.mask === 'currency')
                                                            ? (
                                                              <DatatableField
                                                                fieldName={ columnName }
                                                                fieldValue={ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(row[columnName.toLowerCase()].toString())) }
                                                              />
                                                            )
                                                            :
                                                            tableFieldTypeConfig.numeric && tableFieldTypeConfig.numeric.find(num => num.name === columnName && num.mask === 'percent')
                                                              ? (
                                                                <DatatableField
                                                                  fieldName={ columnName }
                                                                  fieldValue={ `${ row[columnName.toLowerCase().toString()] } %` }
                                                                />
                                                              )
                                                              : (
                                                                <DatatableField
                                                                  fieldName={ columnName }
                                                                  fieldValue={ row[columnName.toLowerCase()] }
                                                                />
                                                              )
                                                        }
                                                      </TableDataText>
                                                    }
                                                  </Table.Cell>
                                                );
                                              })
                                            }

                                            {
                                              !isConsultation && useLotsAndSubLots ? (
                                                <Table.Cell
                                                  textAlign="center"
                                                  width={ 1 }
                                                  active={ useGrid ? !!gridList.find(grid => useGridColor ? grid.b4_grdcod === row.b4_grdcod : grid.indice === row.indice) : currentItemQuantity(row['b1_cod']) && currentItemQuantity(row['b1_cod']) > 0 }
                                                >
                                                  <Dropdown
                                                    icon={null}
                                                    trigger={
                                                      <Button
                                                        circular
                                                        size='big'
                                                        positive
                                                        icon="plus"
                                                        color="green"
                                                      />
                                                    } 
                                                  >
                                                    <Dropdown.Menu style={{ position: 'static', zIndex: 999999 }}>
                                                      <Dropdown.Item 
                                                        onClick={() => { 
                                                          if (itemsList.find(item => item.indice === row.indice)) {
                                                            handleOpenConsultationModal(row, 'Lotes')
                                                          } else {
                                                            Swal.fire({
                                                              title: 'Atenção!',
                                                              html: `Coloque uma quantidade no produto antes`,
                                                              icon: 'warning',
                                                              confirmButtonText: 'Ok',
                                                            });
                                                          }
                                                        }}
                                                      >
                                                        Lotes
                                                      </Dropdown.Item>
                                                      <Dropdown.Item 
                                                        onClick={() => { 
                                                          if (itemsList.find(item => item.indice === row.indice)) {
                                                            handleOpenConsultationModal(row, 'Saldo')
                                                          } else {
                                                            Swal.fire({
                                                              title: 'Atenção!',
                                                              html: `Coloque uma quantidade no produto antes`,
                                                              icon: 'warning',
                                                              confirmButtonText: 'Ok',
                                                            });
                                                          }
                                                        }}
                                                      >
                                                        Saldo
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                  </Dropdown>
                                                </Table.Cell>
                                              ) : null
                                            }
                                            {
                                              !isConsultation ? (
                                                <Table.Cell
                                                  textAlign="center"
                                                  className='dataTableFixed'
                                                  width={ 1 }
                                                  active={ useGrid ? !!gridList.find(grid => useGridColor ? grid.b4_grdcod === row.b4_grdcod : grid.indice === row.indice) : currentItemQuantity(row['b1_cod']) && currentItemQuantity(row['b1_cod']) > 0 }
                                                >
                                                  {
                                                    useGrid ? (
                                                      <Button
                                                        circular
                                                        size='big'
                                                        positive
                                                        onClick={ () => { handleOpenGridModal(row) } }
                                                        icon="plus"
                                                        color="green"
                                                      // style={{ background: styleSheet.mainColor }}
                                                      />
                                                    ) : (
                                                      <QuantityContainer>
                                                        {
                                                          !useDecimalProductQuantity &&
                                                          <Button
                                                            circular
                                                            size='big'
                                                            negative
                                                            onClick={ () => handleChangeQuantityProductValue((Number(currentItemQuantity(row['b1_cod'])) - (useDiscountByWholesaleQuantity ? (Number(currentItemQuantity(row['b1_cod']) > row['b1_xqtdat'])) ? valueWithConversion(row['b1_cod']) : 1 : valueWithConversion(row['b1_cod']))).toFixed(2), row['b1_cod']) }
                                                            icon="minus"
                                                            style={ { background: styleSheet.mainColor } }
                                                          />
                                                        }
                                                        <QuantityInput
                                                          type="number"
                                                          maxLength={ 6 }
                                                          max={ 999 }
                                                          min={ 0 }
                                                          onFocus={ e => e.target.select() }
                                                          value={ typeof currentItemQuantity(row['b1_cod']) === 'number' ? currentItemQuantity(row['b1_cod']) : "" }
                                                          onChange={ event => handleChangeQuantityProductValue(parseFloat(event.target.value), row['b1_cod']) }
                                                          onBlur={ event => {
                                                            if (useCheckBalance && row['b1_xsaldo'] && parseFloat(event.target.value) > row['b1_xsaldo']) {
                                                              handleChangeQuantityProductValue(checkBalance(Number(row['b1_conv'] || row['b1_qe']), Number(row['b1_xsaldo']), parseFloat(event.target.value)), row['b1_cod'])
                                                            } else {
                                                              handleChangeQuantityProductValue((conversionCalc(parseFloat(event.target.value), useDiscountByWholesaleQuantity && currentItemQuantity(row['b1_cod']) >= row['b1_xqtdat'] ? row['b1_xqtdat'] : (row['b1_conv'] || row['b1_qe']))).toFixed(2), row['b1_cod'])
                                                            }
                                                          } }
                                                        />
                                                        {
                                                          !useDecimalProductQuantity &&
                                                          <Button
                                                            circular
                                                            size='big'
                                                            positive
                                                            onClick={ () => {
                                                              if (useCheckBalance && row['b1_xsaldo'] && (Number(currentItemQuantity(row['b1_cod'])) + valueWithConversion(row['b1_cod'])) > row['b1_xsaldo']) {
                                                                return Swal.fire({
                                                                  title: 'Atenção!',
                                                                  text: notShowBalanceInCheckBalanceMessage ? 'Valor não permitido. O valor foi alterado para o máximo permitido' : `O valor máximo permitido para esse produto é ${ row['b1_xsaldo'] }`,
                                                                  icon: 'warning',
                                                                  confirmButtonText: 'Ok',
                                                                });
                                                              }
                                                              handleChangeQuantityProductValue((Number(currentItemQuantity(row['b1_cod'])) + (useDiscountByWholesaleQuantity ? (Number(currentItemQuantity(row['b1_cod']) >= row['b1_xqtdat'])) ? valueWithConversion(row['b1_cod']) : 1 : valueWithConversion(row['b1_cod']))).toFixed(2), row['b1_cod'])
                                                            } }
                                                            icon="plus"
                                                            style={ { background: styleSheet.mainColor } }
                                                          />
                                                        }

                                                      </QuantityContainer>
                                                    )
                                                  }
                                                </Table.Cell>
                                              ) : null
                                            }
                                          </Table.Row>
                                        );
                                      })
                                    }
                                  </Table.Body>
                                </>
                            }
                          </Table>
                        </TableContainer>
                        <Menu borderless style={ { backgroundColor: '#FFF', boxShadow: '0 0 3px 2px #dedede' } }>
                          {
                            showLegendFilterInFooter &&
                            <Menu.Item>
                              {
                                tableLegend.map(({ dados, campo }) => {
                                  return (
                                    <Fieldset key={ campo }>
                                      <LegendStatus>
                                        { tableTitles[campo] }
                                      </LegendStatus>
                                      <LegendContainer>
                                        { dados.map(({ nome, cor }) => {
                                          return (
                                            <Legend
                                              key={ nome }
                                              isActive={ tableFilter.statusFilter ? tableFilter.statusFilter[campo] === nome : false }
                                              color={ cor }
                                              onClick={ () => {
                                                if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
                                                  const status = { ...tableFilter.statusFilter };
                                                  delete status[campo]
                                                  filterProduct(tableFilter.filter, status)

                                                } else {
                                                  filterProduct(tableFilter.filter, { ...tableFilter.statusFilter, [campo]: nome })
                                                }
                                              } }
                                            >
                                              <LegendInitialName isActive={ tableFilter.statusFilter ? tableFilter.statusFilter[campo] === nome : false } color={ cor }>{ nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2) }</LegendInitialName>
                                              <LegendName>{ nome }</LegendName>
                                            </Legend>
                                          );
                                        }) }
                                      </LegendContainer>
                                    </Fieldset>
                                  )
                                })
                              }
                            </Menu.Item>
                          }

                          {
                            !tableLoading && 
                            <Menu.Item position="right">
                              {
                                customizations['useQtdPaginationSelect'] ? (
                                  <Menu.Item position="right">
                                    <Fieldset>
                                      <LegendStatus>
                                      QTD PAGINA
                                      </LegendStatus>
                                      <LegendContainer>
                                        <Select
                                          placeholder="Quantidade por pagina"
                                          defaultValue={ itensPerPage }
                                          options={ configuracoes.quantityperpage ? utils.generateItensPerPage(configuracoes.quantityperpage) : utils.generateItensPerPageDefault(window.innerHeight, rowSize) }
                                          onChange={ async (event, data) => {
                                            dispatch(updateItensPerPage({ value: Number(data.value) }));
                                            dispatch(updateTableCurrentPage({ value: 1 }));
                                            setChangeItensPerPage(changeItensPerPage + 1);
                                          } }
                                        />
                                      </LegendContainer>
                                    </Fieldset>
                                    
                                  </Menu.Item>
                                ) : null
                              }
                              <Menu>
                                <Pagination
                                  defaultActivePage={ tableCurrentPage }
                                  firstItem={ null }
                                  lastItem={ null }
                                  pointing
                                  secondary
                                  totalPages={ tableTotalPages }
                                  onPageChange={ (ev, { activePage }) => getProductsWithPage(activePage as number) }
                                />
                              </Menu>
                            </Menu.Item>
                          }
                        </Menu>
                      </>
                    )
                  }

                  {
                    listStyle === 'grid' && (
                      <>
                        <TableContainerGrid>
                          <Container style={ { width: '100%', height: '100%' } }>
                            <Grid relaxed columns={ 5 }>
                              {
                                tableLoading &&
                                <Skeleton baseColor='#DDD' style={ { marginRight: '2rem' } } inline height={ 220 } width={ 270 } count={ itensPerPage + 1 } />
                              }
                              {
                                !tableLoading && tableData.map(({ b1_ximg, b1_cod, b1_desc, b1_prv1, b1_conv, b1_qe, b1_xsaldo, ...rest }) => (
                                  <Grid.Column key={ b1_cod } stretched>
                                    <Card
                                      onClick={ () => {

                                        if (!isConsultation) {
                                          dispatch(updateProduct({ value: { b1_cod, b1_desc, b1_prv1, b1_conv, b1_qe, ...rest } }))
                                          dispatch(updateQuantityValue({ value: Number(currentItemQuantity(b1_cod)) }))
                                          dispatch(updateModalQuantityState({ value: true }))
                                        }
                                        handleOpenProductDetail(b1_ximg ? b1_ximg : defaultProductImg, b1_cod)

                                      } }
                                      link
                                      centered
                                      fluid
                                      style={ { textAlign: 'center', background: '#FFF', boxShadow: '0 0 3px 2px #dedede' } }
                                    >
                                      <div>
                                        <Label style={ { position: 'absolute', top: '-10px', right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: styleSheet.contrastColor, color: styleSheet.shape, borderRadius: '50%' } } circular size="big" floating>
                                          <span style={ { fontSize: '1.1rem' } }>{ currentItemQuantity(b1_cod) || "0" }</span>
                                        </Label>
                                      </div>
                                      <Image
                                        style={ { padding: '10px 0', background: 'transparent' } }
                                        size="tiny"
                                        centered
                                        src={ b1_ximg || defaultProductImg }
                                        onError={ ({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = defaultProductImg;
                                        } }
                                      />
                                      <Card.Content>
                                        <Card.Header style={ { fontSize: '1.6rem' } }>{ b1_desc }</Card.Header>
                                      </Card.Content>
                                      <Card.Content>
                                        <Label size="big" style={ { background: styleSheet.mainColor, color: '#FFF' } }>{ utils.formatFloatAsBRL(b1_prv1, 2) }</Label>
                                        {
                                        tableTitles['B1_XSALDO'] &&
                                        <Card.Content>
                                          <Label size="big" style={ { background: 'orange', color: '#000', marginTop: '0.5rem' } }> { b1_xsaldo }</Label>
                                        </Card.Content>
                                      }
                                      </Card.Content>
                                    </Card>
                                  </Grid.Column>
                                ))
                              }
                            </Grid>
                          </Container>
                        </TableContainerGrid>

                        <Menu borderless style={ { background: '#FFF !important', boxShadow: '0 0 3px 2px #dedede' } }>
                          {
                            showLegendFilterInFooter &&
                            <Menu.Item>
                              {
                                tableLegend.map(({ dados, campo }) => {
                                  return (
                                    <Fieldset key={ campo }>
                                      <LegendStatus>
                                        { tableTitles[campo] }
                                      </LegendStatus>
                                      <LegendContainer>
                                        { dados.map(({ nome, cor }) => {
                                          return (
                                            <Legend
                                              key={ nome }
                                              isActive={ tableFilter.statusFilter ? tableFilter.statusFilter[campo] === nome : false }
                                              color={ cor }
                                              onClick={ () => {
                                                if (tableFilter.statusFilter && tableFilter.statusFilter[campo] === nome) {
                                                  const status = { ...tableFilter.statusFilter };
                                                  delete status[campo]
                                                  filterProduct(tableFilter.filter, status)

                                                } else {
                                                  filterProduct(tableFilter.filter, { ...tableFilter.statusFilter, [campo]: nome })
                                                }
                                              } }
                                            >
                                              <LegendInitialName isActive={ tableFilter.statusFilter ? tableFilter.statusFilter[campo] === nome : false } color={ cor }>{ nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2) }</LegendInitialName>
                                              <LegendName>{ nome }</LegendName>
                                            </Legend>
                                          );
                                        }) }
                                      </LegendContainer>
                                    </Fieldset>
                                  )
                                })
                              }
                            </Menu.Item>
                          }

                          {
                            !tableLoading && 
                            <Menu.Item position="right">
                              {
                                customizations['useQtdPaginationSelect'] ? (
                                  <Menu.Item position="right">
                                    <Fieldset>
                                      <LegendStatus>
                                        QTD PAGINA
                                      </LegendStatus>
                                      <LegendContainer>
                                        <Select
                                          placeholder="Quantidade por pagina"
                                          defaultValue={ itensPerPage }
                                          options={ configuracoes.quantityperpage ? utils.generateItensPerPage(configuracoes.quantityperpage) : utils.generateItensPerPageDefault(window.innerHeight, rowSize) }
                                          onChange={ async (event, data) => {
                                            dispatch(updateItensPerPage({ value: Number(data.value) }));
                                            dispatch(updateTableCurrentPage({ value: 1 }));
                                            setChangeItensPerPage(changeItensPerPage + 1);
                                          } }
                                        />
                                      </LegendContainer>
                                    </Fieldset>
                                  </Menu.Item>
                                ) : null
                              }
                              <Menu>
                                <Pagination
                                  defaultActivePage={ tableCurrentPage }
                                  firstItem={ null }
                                  lastItem={ null }
                                  pointing
                                  secondary
                                  totalPages={ tableTotalPages }
                                  onPageChange={ (ev, { activePage }) => getProductsWithPage(activePage as number) }
                                />
                              </Menu>
                            </Menu.Item>
                          }
                        </Menu>
                      </>

                    )
                  }
                </Container>
              )
            }
          </BrowserContent>
        </Browser>

      </Content>

      <Footer />

      <Modal
        size="mini"
        open={ modalQuantityState }
        onClose={ () => dispatch(updateModalQuantityState({ value: false })) }
        centered
      >
        <Modal.Header>Informe a quantidade de itens</Modal.Header>
        <Modal.Content style={ { display: 'flex', alignItems: 'center', flexDirection: 'column' } }>
          {
            !useDecimalProductQuantity ?
              <QuantityContainer>
                <Button
                  circular
                  size='big'
                  negative
                  onClick={ () => dispatch(updateQuantityValue({ value: parseFloat((quantityValue - calcValueWithConversion("decrease")).toFixed(2)) })) }
                  icon="minus"
                  style={ { background: styleSheet.mainColor } }
                />

                <Input
                  className='productQuantity'
                  maxLength={ 4 }
                  transparent
                  type="number"
                  min={ 0 }
                  onChange={ event => {
                    let value = parseFloat(event.target.value);

                    if (value < 0) return;

                    if (!useDecimalProductQuantity && !Number.isInteger(value)) {
                      value = Math.round(value);
                    }
                    dispatch(updateQuantityValue({ value: value }));
                  } }
                  value={ quantityValue.toString() }
                  onBlur={ event => {
                    if (useCheckBalance && product['b1_xsaldo'] && parseFloat(event.target.value) > product['b1_xsaldo']) {
                      dispatch(updateQuantityValue({ value: Number(product['b1_xsaldo']) }))

                      return Swal.fire({
                        title: 'Atenção!',
                        text: notShowBalanceInCheckBalanceMessage ? 'Valor não permitido. O valor foi alterado para o máximo permitido' : `O valor máximo permitido para esse produto é ${ product['b1_xsaldo'] } o valor do produto foi alterado para ${ product['b1_xsaldo'] }.`,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                      });
                    }
                    dispatch(updateQuantityValue({ value: conversionCalc(parseFloat(event.target.value), useDiscountByWholesaleQuantity ? product['b1_xqtdat'] : undefined) }))
                  } }
                >
                  <input style={ { width: '6rem', textAlign: 'center', border: '0', outline: 0 } } />
                </Input>
                <Button
                  circular
                  size='big'
                  positive
                  onClick={ () => {
                    if (useCheckBalance && product['b1_xsaldo'] && (quantityValue + calcValueWithConversion("increase")) > product['b1_xsaldo']) {
                      return Swal.fire({
                        title: 'Atenção!',
                        text: notShowBalanceInCheckBalanceMessage ? 'Valor não permitido. O valor foi alterado para o máximo permitido' : `O valor máximo permitido para esse produto é ${ product['b1_xsaldo'] }`,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                      });
                    }
                    dispatch(updateQuantityValue({ value: parseFloat((quantityValue + calcValueWithConversion("increase")).toFixed(2)) }))
                  } }
                  icon="plus"
                  style={ { background: styleSheet.mainColor } }
                />

              </QuantityContainer>
              :
              <div style={ { width: '100%', display: 'flex', marginTop: '2rem', justifyContent: 'center' } }>
                <DefaultLabel>
                  Quantidade
                  <Input
                    style={ { width: '100%' } }
                    maxLength={ 6 }
                    type="number"
                    min={ 0 }
                    onChange={ event => {
                      if (parseFloat(event.target.value) < 0) return
                      dispatch(updateQuantityValue({ value: parseFloat(event.target.value) }))
                    } }
                    value={ quantityValue.toString() }
                    onBlur={ event => {
                      if (useCheckBalance && product['b1_xsaldo'] && parseFloat(event.target.value) > product['b1_xsaldo']) {
                        dispatch(updateQuantityValue({ value: Number(product['b1_xsaldo']) }))

                        return Swal.fire({
                          title: 'Atenção!',
                          text: notShowBalanceInCheckBalanceMessage ? 'Valor não permitido. O valor foi alterado para o máximo permitido' : `O valor máximo permitido para esse produto é ${ product['b1_xsaldo'] } o valor do produto foi alterado para ${ product['b1_xsaldo'] }.`,
                          icon: 'warning',
                          confirmButtonText: 'Ok',
                        });
                      }
                      dispatch(updateQuantityValue({ value: conversionCalc(parseFloat(event.target.value), useDiscountByWholesaleQuantity ? product['b1_xqtdat'] : undefined) }))
                    } }
                  />
                </DefaultLabel>
              </div>
          }

          <div style={ { width: '100%', display: 'flex', marginTop: '2rem', justifyContent: 'space-between' } }>
            <DefaultLabel>
              Desconto
              <Input style={ { width: '100%' } } type="number" value={ discount } onChange={ event => handleDiscountOrIncreaseChange('discount', Number(event.target.value)) } />
            </DefaultLabel>

            <DefaultLabel>
              Acréscimo
              <Input style={ { width: '100%' } } type="number" value={ increase } onChange={ event => handleDiscountOrIncreaseChange('increase', Number(event.target.value)) } />
            </DefaultLabel>
          </div>

        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={ () => dispatch(updateModalQuantityState({ value: false })) } >
              <Icon name="close" />
            </Button>
            <Button.Or text="ou" />
            <Button positive onClick={ () => addGridProductToCart() } >
              Confirmar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>


      <ViewCheckoutModal
        onClose={ async () => {
          if (configuracoes.usedirectproductsear) {
            if (itemsCount > 0 || gridList.length > 0) {
              await Swal.fire({
                title: 'Confirmar ação?',
                text: 'Os itens do carrinhos serão perdidos!',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, confirmar!',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  handleCartClear();
                  dispatch(updateSearchOptions({ value: [] }));
                  dispatch(resetSearchOptionsSelected());
                  dispatch(updatePriceTable({ value: '' }))
                  navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets');
                }
              });
            } else {
              handleCartClear();
              dispatch(updateSearchOptions({ value: [] }));
              dispatch(resetSearchOptionsSelected());
              navigate(selectedMenu.nome === 'Pedidos' ? '/orders' : '/budgets');
            }
          } else {
            dispatch(updateViewCheckoutModal({ value: false }))
            const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK
            const codFieldName = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
            
            const product = productList.map(prod => {
              const productFields: IState = prod.find(productField => productField.nome === codFieldName) ? {...itemsList.find(item => item['b1_cod'] === prod.find(productField => productField.nome === codFieldName).conteudo)} : {}

              prod.map(field => {
                if (dictionary[field.nome] !== undefined) {
                  productFields[dictionary[field.nome]] = field.conteudo
                }
              })
              return productFields
            })
            const totalItens = product.reduce((previousValue, currentValue: IState) => previousValue = previousValue + Number(currentValue.quantity), 0)
            dispatch(updateItemsCount({ value: totalItens }))
            dispatch(updateItemsList({ value: product }))
          }

          if (usePaymentConditionByTotalPrice) {
            dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
            document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
          }
        } }
        onClearCart={ handleCartClear }
        visible={ viewCheckoutModal }
      />

      <GridModal
        visible={ viewGridModal }
        onClose={ () => dispatch(updateViewGridModal({ value: false })) }
      />

      <ConsultationModal
        consultationServiceName="wsconsulta"
        visualizationOnly={false}
        visible={ viewConsultationModal }
        onClose={ () => { 
          dispatch(updateViewConsultationModal({ value: false })) 
          dispatch(updateSelectedRow({value: {} }))
        } }
      />

      {
        configuracoes.showproductgroups && (
          <ProductGroupModal
            selectedGroup={ selectedGroup }
            visible={ modalFilterGroup }
            groups={ groups }
            onClose={ () => setModalFilterGroup(false) }
            onSelect={ (group) => setSelectedGroup(group) }
            onSearch={ (group) => {
              setFilter(group.conteudo)
              setModalFilterGroup(false)
            } }
          />
        )
      }

      <ProductDetailModal />

    </PageDefault>
  )
}

export default Cart