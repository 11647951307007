import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { IRestField } from "../interfaces/Fields/IRestField";

import { CartReducerInitialState, resetSearchOptionsSelected, updateProductDetail, updateCalculateProductTotal, updateItemsCount, updateItemsList, updateProductList, updateProductTotalPrice, updateProductValueList, updateSelectedGrid, updateViewGridModal, updateGridListLine, updateViewConsultationModal, updateSelectedProductToConsultation, updateConsultationType, addNewProductQuantity, updateSearchItemSelected, deleteItem } from "../reducers/Cart";
import { EnvironmentReducerInitialState, updateModalImportCsvWarningProductInfo, updateModalImportCsvWarningVisible } from "../reducers/Environment";
import { RestFieldReducerInitialState, updateAllGridFieldValues, updateFieldValues } from "../reducers/RestField";
import { PolicieReducerInitialState } from "../reducers/Policie";
import { MenuReducerInitialState } from "../reducers/Menu";
import { AuthReducerInitialState } from "../reducers/Auth";
import { ProductService } from "../services/methods/ProductService";
import { toast } from "react-toastify";
import { IProduct } from "../interfaces/Product/IProduct";
import { IImportedProductInfo } from "../components/ImportCsvModalWarning";
import { updateViewCheckoutModal } from "../reducers/DataTable";

export enum dictionaryEXC6 {
  C6_DESCONT = 'discount',
  C6_ACRESC = 'increase',
  C6_QTDVEN = 'quantity',
}

export enum dictionaryEXCK {
  CK_DESCONT = 'discount',
  CK_ACRESC = 'increase',
  CK_QTDVEN = 'quantity',
}

interface IState {
  [key: string]: string | number;
}



interface ICartContext {
  isImported: boolean;
  handleGetTotalPrice(): number;
  handleGetBonificationTotal(): number;
  handleCovertProductListToCart(): void;
  handleAdjustedPriceCalc(index: number, value: string): void;
  handleGetAdjustedPrice(index: number): void;
  handleOpenProductDetail(image: string, productCod?: string | number): Promise<void>;
  checkBalance(multiplier: number, limitBalance: number, valorInput: number, showMessage?: boolean): number;
  handleAddNewProduct(): Promise<IRestField[][]>;
  handleOpenGridModal(row: IProduct): Promise<void>;
  handleOpenConsultationModal(row: IProduct, type: string): Promise<void>;
  handleImportOrderOrBudgetToCart(): Promise<void>;
  updateIsImported(value: boolean): void;
  updateImportedData(value: Array<{ [key: string]: string }>): void;
}

export const CartContext = createContext<ICartContext>({} as ICartContext);

const CartContextProvider: FC = ({ children }) => {
  const productService: ProductService = new ProductService();

  const [isImported, setIsImported] = useState(false)
  const [importedData, setImportedData] = useState<Array<{ [key: string]: string }>>([])


  const { type, productList, isEditing, searchProductSelected, itemsCount, calculateProductTotal, dictionaryC6, itemsList, gridList, dictionaryCK, itemFields, searchItemSelected, priceTable } = useSelector((state: { cart: CartReducerInitialState }) => state.cart);
  const { selectedClient, currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { token, pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const useGrid = companyPolicies.find(policy => policy.property === 'useGrid' && policy.policy_value === 'true');
  const useGridColor = companyPolicies.find(policy => policy.property === 'useGridColor' && policy.policy_value === 'true');
  const useDiscountByWholesaleQuantity = companyPolicies.find(policy => policy.property === 'useDiscountByWholesaleQuantity' && policy.policy_value === 'true');
  const usePaymentConditionByTotalPrice = useMemo(() => companyPolicies.find(policy => policy.property === 'usePaymentConditionByTotalPrice' && policy.policy_value === 'true'), [companyPolicies]);
  const showSuccessProductsOnImportModalWarning = useMemo(() => companyPolicies.find(policy => policy.property === 'showSuccessProductsOnImportModalWarning' && policy.policy_value === 'true'), [companyPolicies]);
  const useDiscountValidationByClientField = useMemo(() => companyPolicies.find(policy => policy.property === 'useDiscountValidationByClientField' && policy.policy_value === 'true'), [companyPolicies]);
  const useDiscountValidationByProductField = useMemo(() => companyPolicies.find(policy => policy.property === 'useDiscountValidationByProductField' && policy.policy_value === 'true'), [companyPolicies]);

  const dispatch = useDispatch()

  async function handleOpenGridModal(row: IProduct): Promise<void> {
    const grid = gridList.find(grid => useGridColor ? grid.b4_grdcod === row.b4_grdcod : grid.indice === row.indice) ?? row

    if (grid.linha.length <= 0) {
      const id = toast.loading('Carregando grade...')
      await productService.getGridLine(currentCompany.toString(), currentBranch.codigo, token,  grid.b4_grdcod, grid.b4_chvlin, priceTable)
        .then(({ data, status }) => {
          if (status === 200) {
            dispatch(updateAllGridFieldValues({
              value: Object.fromEntries(
                Object.entries(row).map(([k, v]) => [k.toUpperCase(), v])
              )
            }))
            dispatch(updateSelectedGrid({ value: { ...grid, linha: data.linha } }))
            dispatch(updateGridListLine({ b4_grdcod: grid.b4_grdcod, indice: grid.indice, useGridColor: !!useGridColor, line: data.linha }))
            dispatch(updateViewGridModal({ value: true }))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: data.msg || 'Algo deu errado!',
            })
          }
        }).catch((error) => {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          })
        }).finally(() => {
          toast.update(id, { isLoading: false, autoClose: 10, closeOnClick: true })
        })
    } else {
      dispatch(updateAllGridFieldValues({
        value: Object.fromEntries(
          Object.entries(row).map(([k, v]) => [k.toUpperCase(), v])
        )
      }))
      dispatch(updateSelectedGrid({ value: grid }))
      dispatch(updateViewGridModal({ value: true }))
    }
  }
  
  async function handleOpenConsultationModal(row: IProduct, type: string): Promise<void> {
    dispatch(updateSelectedProductToConsultation({ value: row }))
    dispatch(updateViewConsultationModal({ value: true }))
    dispatch(updateConsultationType({ value: type }))
  }

  function handleGetTotalPrice(): number {
    const useDiscountTotalPriceByRestField = companyPolicies.find(policy => policy.property === 'useDiscountTotalPriceByRestField' && policy.policy_value === 'true');
    const useCustomDiscountRestField = companyPolicies.find(policy => policy.property === 'useCustomDiscountRestField' && policy.policy_value === 'true');

    let totalProduct = 0
    let totalPrice = 0

    productList.map((item) => {
      const productPrice = item.find(field => field.regra === 'prcunit') ? Number(item.find(field => field.regra === 'prcunit').conteudo) : 0;
      const productPriceVen = item.find(field => field.regra === 'prcven') ? Number(item.find(field => field.regra === 'prcven').conteudo) : 0;
      const quantity = item.find(field => field.regra === 'quantidade') ? Number(item.find(field => field.regra === 'quantidade').conteudo) : 0
      const discount = item.find(field => field.regra === 'desconto') ? Number(item.find(field => field.regra === 'desconto').conteudo) : 0
      const increase = item.find(field => field.regra === 'acrescimo') ? Number(item.find(field => field.regra === 'acrescimo').conteudo) : 0

      if (productPriceVen && productPriceVen !== productPrice) {
        totalProduct = quantity * productPriceVen
      } else {
        totalProduct = quantity * productPrice
  
        if (discount > 0) {
          totalProduct = totalProduct - (totalProduct * discount / 100)
        } else if (increase > 0) {
          totalProduct = totalProduct + (totalProduct * increase / 100)
        } else {
          totalProduct = totalProduct
        } 
      }

      totalPrice += totalProduct
    })

    if (useDiscountTotalPriceByRestField && fieldValues[`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DESC`]) {
      totalPrice -= (totalPrice / 100) * Number(fieldValues[`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_DESC`])
    }

    const restFieldDiscountFields = companyPolicies.find(policy => policy.policy === useCustomDiscountRestField?.dependencie && policy.policy_value !== '');

    if (useCustomDiscountRestField && restFieldDiscountFields) {
      const fields = restFieldDiscountFields.policy_value.split(";");

      fields.forEach((value) => {
        const field = fieldValues[`${ selectedMenu.nome === 'Pedidos' ? 'C5' : 'CJ' }_${ value }`];
        if (field) totalPrice -= totalPrice * (Number(field) / 100);
      })
    }

    return totalPrice
  }

  function handleGetBonificationTotal(): number {
    let totalBonificationProduct = 0;
    let bonificationTotal = 0;

    productList.map((item) => {
      const productPrice = item.find(field => field.regra === 'prcunit') ? Number(item.find(field => field.regra === 'prcunit').conteudo) : 0;
      const bonificationQuantity = item.find(field => field.nome.includes('_XQTBON')) ? Number(item.find(field => field.nome.includes('_XQTBON')).conteudo) : 0
      // const quantity = item.find(field => field.regra === 'quantidade') ? item.find(field => field.regra === 'quantidade').conteudo : 0
      const discount = item.find(field => field.regra === 'desconto') ? Number(item.find(field => field.regra === 'desconto').conteudo) : 0
      const increase = item.find(field => field.regra === 'acrescimo') ? Number(item.find(field => field.regra === 'acrescimo').conteudo) : 0


      totalBonificationProduct = Number(bonificationQuantity) * Number(String(productPrice))
      if (discount > 0) {
        totalBonificationProduct = totalBonificationProduct - (totalBonificationProduct * Number(discount) / 100);
      } else if (increase > 0) {
        totalBonificationProduct = totalBonificationProduct + (totalBonificationProduct * Number(increase) / 100);
      } else {
        totalBonificationProduct = totalBonificationProduct;
      }


      bonificationTotal += totalBonificationProduct;
    })

    if (bonificationTotal > 0 && handleGetTotalPrice() > 0) {
      return (bonificationTotal / handleGetTotalPrice()) * 100;
    } else {
      return 0
    }
  }

  function handleCovertProductListToCart() {
    // Volta dessa validação com a validação para só edição por estar limpando o carrinho ao tentar alterar a quantidade
    if (configuracoes.usedirectproductsear && isEditing) {
      return;
    }

    const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK
    const products: IRestField[][] = []

    if (useGrid) {
      gridList.forEach((item, index) => {
        if (productList[index]) {
          if (!configuracoes.alloworderzeroqtd && productList[index].find(field => field.regra === 'quantidade') && item[dictionary[productList[index].find(field => field.regra === 'quantidade').nome]] <= 0) {
            return;
          }
          const product = productList[index].map(field => {
            let value = item[dictionary[field.nome]] !== undefined ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''
            if (field.type === 'number' || field.type === 'percent' || field.type === 'currency') {
              if (field.regra === 'quantidade') {
                if (item['b4_grdfix'] === 'SIM' || item['b4_grdfix'] === 'S' || item['b4_grdfix'] === 'Sim') {
                  value = item.linha.reduce((acc, current) => {
                    return acc + (current.quantidade * current.coluna.reduce((accColumn, currentColumn) => accColumn + currentColumn.quantidade, 0))
                  }, 0)
                } else {
                  value = item.linha.reduce((acc, current) => {
                    return acc + current.coluna.reduce((accColumn, currentColumn) => accColumn + currentColumn.quantidade, 0)
                  }, 0)
                }
              } else {
                value = Number(item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '')
              }
            }

            return {
              ...field,
              conteudo: value
            }
          })

          products.push(product)
        } else {
          if (!configuracoes.alloworderzeroqtd && itemFields.find(field => field.regra === 'quantidade') && item[dictionary[itemFields.find(field => field.regra === 'quantidade').nome]] <= 0) {
            return;
          }
          const product = itemFields.map(field => {
            let value = item[dictionary[field.nome]] !== undefined ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''
            if (field.type === 'number' || field.type === 'percent' || field.type === 'currency') {
              if (field.regra === 'quantidade') {
                if (item['b4_grdfix'] === 'SIM' || item['b4_grdfix'] === 'S' || item['b4_grdfix'] === 'Sim') {
                  value = item.linha.reduce((acc, current) => {
                    return acc + (current.quantidade * current.coluna.reduce((accColumn, currentColumn) => accColumn + currentColumn.quantidade, 0))
                  }, 0)
                } else {
                  value = item.linha.reduce((acc, current) => {
                    return acc + current.coluna.reduce((accColumn, currentColumn) => accColumn + currentColumn.quantidade, 0)
                  }, 0)
                }
              } else {
                value = Number(item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '')
              }
            }
            return {
              ...field,
              conteudo: value
            }
          })

          products.push(product)
        }
      });
    } else {

      itemsList.forEach((item, index) => {      
        
        const fieldName = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO'
        const selectedProduct = productList.find(fields => fields.find(field => field.nome === fieldName).conteudo === item.b1_cod)
        // TORCA DE productList[index] para selectedProduct por erros na alteração
        if (selectedProduct) {
          if (!configuracoes.alloworderzeroqtd && selectedProduct.find(field => field.regra === 'quantidade') && item[dictionary[selectedProduct.find(field => field.regra === 'quantidade').nome]] <= 0) {
            return;
          }
       
          const product = selectedProduct.map(field => {
            let value = item[dictionary[field.nome]] !== undefined ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''

            if (useDiscountByWholesaleQuantity && (field.regra === 'prcunit' || field.regra === 'prcven')) {
              if (selectedProduct.find(field => field.regra === 'qtdatacado') && selectedProduct.find(field => field.regra === 'quantidade') && Number(item[dictionary[selectedProduct.find(field => field.regra === 'quantidade')!.nome]]) >= Number(item[dictionary[selectedProduct.find(field => field.regra === 'qtdatacado')!.nome]])) {
                const productPriceWholesale = selectedProduct.find(field => field.regra === 'prcatacado') ? parseFloat(String(item[dictionary[selectedProduct.find(field => field.regra === 'prcatacado')!.nome]])) : item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '';
                return {
                  ...field,
                  conteudo: productPriceWholesale
                }
              }
            }

            if (field.type === 'number' || field.type === 'percent' || field.type === 'currency') {
              value = Number(item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '')
            }

            return {
              ...field,
              conteudo: value
            }
          })

          products.push(product)
        } else {
          if (!configuracoes.alloworderzeroqtd && itemFields.find(field => field.regra === 'quantidade') && item[dictionary[itemFields.find(field => field.regra === 'quantidade').nome]] <= 0) {
            return;
          }
          const product = itemFields.map(field => {
            let value = item[dictionary[field.nome]] !== undefined ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : ''

            if (useDiscountByWholesaleQuantity && (field.regra === 'prcunit' || field.regra === 'prcven')) {
              if (itemFields.find(field => field.regra === 'qtdatacado') && itemFields.find(field => field.regra === 'quantidade') && Number(item[dictionary[itemFields.find(field => field.regra === 'quantidade')!.nome]]) >= Number(item[dictionary[itemFields.find(field => field.regra === 'qtdatacado')!.nome]])) {
                const productPriceWholesale = itemFields.find(field => field.regra === 'prcatacado') ? parseFloat(String(item[dictionary[itemFields.find(field => field.regra === 'prcatacado')!.nome]])) : item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '';
                return {
                  ...field,
                  conteudo: productPriceWholesale
                }
              }
            }

            if (field.type === 'number' || field.type === 'percent' || field.type === 'currency') {
              value = Number(item[dictionary[field.nome]] ? item[dictionary[field.nome]] : field.conteudo ? field.conteudo : '')
            }

            return {
              ...field,
              conteudo: value
            }
          })

          products.push(product)
        }
      });
    }

    const adjustedProducts = products.map(product => {
      const productPrice = product.find(field => field.regra === 'prcunit') ? parseFloat(String(product.find(field => field.regra === 'prcunit').conteudo)) : 0;
      // const quantity = product.find(field => field.regra === 'quantidade') ? parseFloat(String(product.find(field => field.regra === 'quantidade').conteudo)) : 0;
      const discount = product.find(field => field.regra === 'desconto') ? parseFloat(String(product.find(field => field.regra === 'desconto').conteudo)) : 0;
      const increase = product.find(field => field.regra === 'acrescimo') ? parseFloat(String(product.find(field => field.regra === 'acrescimo').conteudo)) : 0;


      if (discount > 0) {
        const adjustedPrice = productPrice - (Number(discount) / 100 * productPrice)
        if (product.find(field => field.regra === 'prcven')) product.find(field => field.regra === 'prcven').conteudo = adjustedPrice
      } else if (increase > 0) {
        const adjustedPrice = productPrice + (Number(increase) / 100 * productPrice)
        if (product.find(field => field.regra === 'prcven')) product.find(field => field.regra === 'prcven').conteudo = adjustedPrice
      } 

      return product
    })
  
    dispatch(updateProductList({ value: adjustedProducts }));
    dispatch(updateCalculateProductTotal({ value: !calculateProductTotal }));
  }

  function handleAdjustedPriceCalc(index: number, value: string) {
    const cartItems = [...productList]

    const price = cartItems[index].find(item => item.regra === 'prcunit') ? parseFloat(String(cartItems[index].find(item => item.regra === 'prcunit').conteudo)) : 0;


    const adjustedPrice = Number(value.replace('.', '').replace(',', '.'))
    const priceDiff = adjustedPrice - price;

    if (priceDiff < 0) {
      const discountPercentage = ((priceDiff * -1) / price) * 100;
      if (cartItems[index].find(item => item.regra === 'acrescimo')) cartItems[index].find(item => item.regra === 'acrescimo').conteudo = 0;
      if (cartItems[index].find(item => item.regra === 'desconto')) cartItems[index].find(item => item.regra === 'desconto').conteudo = parseFloat(discountPercentage.toFixed(4));
    }
    else if (priceDiff > 0) {
      const increasePercentage = (priceDiff / price) * 100;
      if (cartItems[index].find(item => item.regra === 'desconto')) cartItems[index].find(item => item.regra === 'desconto').conteudo = 0;
      if (cartItems[index].find(item => item.regra === 'acrescimo')) cartItems[index].find(item => item.regra === 'acrescimo').conteudo = parseFloat(increasePercentage.toFixed(4));
    }
    else {
      if (cartItems[index].find(item => item.regra === 'desconto')) cartItems[index].find(item => item.regra === 'desconto').conteudo = 0;
      if (cartItems[index].find(item => item.regra === 'acrescimo')) cartItems[index].find(item => item.regra === 'acrescimo').conteudo = 0;
    }

    if (cartItems[index].find(item => item.regra === 'vlrtot')) cartItems[index].find(item => item.regra === 'vlrtot').conteudo = adjustedPrice;

    dispatch(updateProductList({ value: cartItems }));
    handleGetTotalPrice();
  }

  function handleGetAdjustedPrice(index: string | number) {
    const productListCopy = [...productList]

    const productPrice = productListCopy[index].find(field => field.regra === 'prcunit') ? parseFloat(String(productListCopy[index].find(field => field.regra === 'prcunit').conteudo)) : 0;
    const discount = productListCopy[index].find(field => field.regra === 'desconto') ? productListCopy[index].find(field => field.regra === 'desconto').conteudo : 0;
    const discountFieldName = productListCopy[index].find(field => field.regra === 'desconto') ? productListCopy[index].find(field => field.regra === 'desconto').nome : type === 'budget' ? 'CK_DESCONT' : 'C6_DESCONT';
    const increase = productListCopy[index].find(field => field.regra === 'acrescimo') ? productListCopy[index].find(field => field.regra === 'acrescimo').conteudo : 0;
    const increaseFieldName = productListCopy[index].find(field => field.regra === 'acrescimo') ? productListCopy[index].find(field => field.regra === 'acrescimo').nome : type === 'budget' ? 'CK_ACRESC' : 'C6_ACRESC';
    const prcvenFieldName = productListCopy[index].find(field => field.regra === 'prcven') ? productListCopy[index].find(field => field.regra === 'prcven').nome : type === 'budget' ? 'CK_PRCVEN' : 'C6_PRCVEN';

    if (Number(discount) > 0) {
      const adjustedPrice = parseFloat((productPrice - (Number(discount) / 100 * productPrice)).toFixed(2))
      dispatch(updateProductValueList({ index: index, fieldName: increaseFieldName, value: 0 }))
      dispatch(updateProductValueList({ index: index, fieldName: discountFieldName, value: Number(discount) }))
      dispatch(updateProductValueList({ index: index, fieldName: prcvenFieldName, value: adjustedPrice }))
    }

    if (Number(increase) > 0) {
      const adjustedPrice = parseFloat((productPrice + (Number(increase) / 100 * productPrice)).toFixed(2))
      dispatch(updateProductValueList({ index: index, fieldName: discountFieldName, value: 0 }))
      dispatch(updateProductValueList({ index: index, fieldName: increaseFieldName, value: Number(increase) }))
      dispatch(updateProductValueList({ index: index, fieldName: prcvenFieldName, value: adjustedPrice }))
    }

    dispatch(updateProductList({ value: productListCopy }));
  }

  const checkBalance = useCallback((multiplier: number, limitBalance: number, valorInput: number, showMessage = true): number => {
    const useValidationByClientCanalInCheckBalance = companyPolicies.find(policy => policy.property === 'useValidationByClientCanalInCheckBalance' && policy.policy_value === 'true');
    const notShowBalanceInCheckBalanceMessage = companyPolicies.find(policy => policy.property === 'notShowBalanceInCheckBalanceMessage' && policy.policy_value === 'true');

    const isMultiplierLessThenBalance = valorInput <= limitBalance;
    const maximumAvaibleBalance = Math.floor(limitBalance / multiplier);
    const divisionBetweenQuantityAndMultiplier = valorInput > limitBalance ? limitBalance / multiplier : valorInput / multiplier;
    const roundedDivisionBetweenQuantityAndMultiplier = Math.floor(divisionBetweenQuantityAndMultiplier);

    let isBalanceStatusValid = true;
    let businessRulesMessages = '';
    let value = valorInput //validateBalance(valorInput, limitBalance);

    if (multiplier && multiplier > 0) {
      if (!isMultiplierLessThenBalance && maximumAvaibleBalance >= 1) {
        isBalanceStatusValid = false;
        businessRulesMessages += 'Não há saldo para a quantidade digitada. O valor máximo permitido foi aplicado.';
        value = maximumAvaibleBalance * multiplier;
      } else if ((!isMultiplierLessThenBalance || multiplier > limitBalance) && maximumAvaibleBalance < 1) {
        isBalanceStatusValid = false;
        businessRulesMessages += 'Não há saldo para a quantidade digitada';
        businessRulesMessages += 'Não há saldo para este produto ser incluído';

        value = 0;
      } else {
        if (valorInput === 0) {
          return 0;
        }

        if (useValidationByClientCanalInCheckBalance && selectedClient['a1_xclasd']) {
          const allowedCanal = companyPolicies.find(policy => policy.policy === useValidationByClientCanalInCheckBalance.dependencie)!.policy_value.split(';')
          if (allowedCanal.includes(selectedClient['a1_xclasd'].toString().trim())) {
            return valorInput;
          }
        } else if (divisionBetweenQuantityAndMultiplier < 1) {
          if (multiplier !== 1) {

            if (showMessage) Swal.fire({
              title: 'Atenção!',
              html: notShowBalanceInCheckBalanceMessage ? 'O valor de conversão não permitido. O valor foi alterado para o mínimo permitido.' : `O valor de conversão é <b>${ multiplier }</b>. O valor foi alterado para o mínimo - <b>(${ multiplier })</b>.`,
              icon: 'info',
              confirmButtonText: 'Ok'
            })

            return multiplier;
          }
        } else if (divisionBetweenQuantityAndMultiplier >= 1) {
          if (multiplier !== 1 && ((multiplier * roundedDivisionBetweenQuantityAndMultiplier) % valorInput) !== 0) {
            if (showMessage) Swal.fire({
              title: 'Atenção!',
              html: notShowBalanceInCheckBalanceMessage
                ? 'O valor de conversão não permitido. O valor foi alterado para valor mais próximo abaixo do digitado, respeitando o valor de conversão!'
                : `O valor de conversão é <b>${ multiplier }</b>! O valor foi alterado para <b>${ multiplier * roundedDivisionBetweenQuantityAndMultiplier }</b>, que é o valor mais próximo abaixo do digitado, respeitando o valor de conversão!`,
              icon: 'info',
              confirmButtonText: 'Ok'
            })
          }
          return multiplier * roundedDivisionBetweenQuantityAndMultiplier;
        }
      }
    } else {

      if (valorInput > limitBalance) {
        isBalanceStatusValid = false;
        businessRulesMessages += 'Não há saldo para a quantidade digitada. O valor máximo permitido foi aplicado.';
        value = limitBalance;
      }
    }

    if (!isBalanceStatusValid) {
      if (showMessage) Swal.fire({
        title: 'Atenção!',
        html: `<b>Verificar a(s) seguinte(s) regra(s) de negócio abaixo:</b><br /><ul>${ businessRulesMessages }</ul>`,
        icon: 'info',
        confirmButtonText: 'Ok'
      })

      return value;
    }

    return value;
  }, [companyPolicies]);

  async function handleOpenProductDetail(image: string, productCod?: string) {
    try {
      if (!configuracoes.allowproductinfos) {
        dispatch(updateProductDetail({ value: { visible: true, image } }))
        return;
      }

      if (!productCod) {
        dispatch(updateProductDetail({ value: { visible: true, image } }));
        return;
      }

      const id = toast('Carregando...')
      const { data, status } = await productService.productDetail(token, currentBranch.codigo, currentCompany.toString(), productCod);

      if (status === 200 && data.status === 'ok') {
        dispatch(updateProductDetail({ value: { visible: true, image, description: data.infos } }))
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg || 'Algo deu errado!',
        })
      }
      toast.update(id, {
        autoClose: 100
      })
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function handleAddNewProduct() {
    const dictionary = selectedMenu.nome === 'Pedidos' ? dictionaryC6 : dictionaryCK;
    const fieldName = selectedMenu.nome === 'Pedidos' ? 'C6_PRODUTO' : 'CK_PRODUTO';

    const productAlreadyAdded = productList.find(product =>
      product.find(field => field.nome === fieldName).conteudo === searchItemSelected.find(field => field.nome === fieldName).conteudo
    )

    if (productAlreadyAdded) {
      await Swal.fire({
        icon: 'info',
        title: 'Atenção',
        text: 'Item já existente no carrinho!',
      });
      return;
    }

    let valido = true
    let msg = ''

    searchItemSelected.forEach((field) => {
      if (field.obrigado === "S") {
        if (field.conteudo === '') {
          valido = false
          msg += `<li><strong>${ field.titulo }</strong></li>`
        }
      }
    })

    if (!valido) {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: `
        <br>Campo(s) obrigatório(s) não preenchido(s)<br/>
          <ul>
            ${ msg }
          </ul>
        `,
      })
      return;
    }

    const newProductList = [...productList, searchItemSelected];
    
    await dispatch(updateProductList({ value: newProductList }));
    const productFields: IState = {}
    searchItemSelected.map(field => {
      if (dictionary[field.nome] !== undefined) {
        productFields[dictionary[field.nome]] = field.conteudo
      }
    })
  
    const newItemsList = [ ...itemsList, { ...productFields, ...searchProductSelected }]

    const totalItens = newItemsList.reduce((previousValue, currentValue: IState) => previousValue = previousValue + Number(currentValue.quantity), 0);

    dispatch(updateItemsList({ value: newItemsList }));
    dispatch(resetSearchOptionsSelected());
    dispatch(updateItemsCount({ value: totalItens }));

    if (usePaymentConditionByTotalPrice) {
      dispatch(updateFieldValues({ name: selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG', value: '' }))
      document.querySelector<HTMLInputElement>(`#${selectedMenu.nome === 'Pedidos' ? 'C5_CONDPAG' : 'CJ_CONDPAG'}`).value = ""
    }

    return newProductList;
  }

  async function handleImportOrderOrBudgetToCart() { 
    const productInformationWarning:  Array<IImportedProductInfo> = []

    dispatch(updateModalImportCsvWarningProductInfo({ value: [] }))
    const id = toast.loading('Importando produtos...')

    const promises = importedData.map(async (item) => {
      const { data, status } = await productService.index(token, 1, 1, selectedClient.indice?.toString(), currentBranch.codigo, currentCompany, '', !!useGrid, `contains(B1_COD, '${item.B1_COD}')`, undefined, priceTable);
    
      if (status === 200 && data.dados.length > 0) {
        const quantity = 
          checkBalance(Number(data.dados[0]['b1_conv'] || data.dados[0]['b1_qe']), Number(data.dados[0]['b1_xsaldo']), parseFloat(item.quantity), false)
        let discount = item.discount || "0"

        
        if (quantity !== parseFloat(item.quantity) && quantity > 0) {
          productInformationWarning.push({ 
            product: item.B1_COD, 
            desc: data.dados[0]['b1_desc'], 
            status: `Quantidade alterada de ${item.quantity} para ${quantity}`, 
            type: 'quantityChange' 
          })
        } else if (quantity !== parseFloat(item.quantity) && quantity === 0) {
          productInformationWarning.push({ 
            product: item.B1_COD, 
            desc: data.dados[0]['b1_desc'],  
            status: `Produto com saldo insuficiente`, 
            type: 'withOutBalance' 
          })
        }

        if (Math.sign(Number(discount)) !== 1 && Math.sign(Number(discount)) !== 0) {
          if (productInformationWarning.find(product => product.product === item.B1_COD)) {
            productInformationWarning.find(product => product.product === item.B1_COD)
              .status = `${productInformationWarning.find(product => product.product === item.B1_COD).status}. Desconto alterada de ${discount} para 0`
          } else {
            productInformationWarning.push({ 
              product: item.B1_COD, 
              desc: data.dados[0]['b1_desc'],  
              status: `Desconto alterada de ${discount} para 0`, 
              type: 'quantityChange' 
            })
          }

          discount = "0"
        }

        if (!productInformationWarning.find(product => product.product === item.B1_COD) || productInformationWarning.find(product => product.product === item.B1_COD)?.type !== 'withOutBalance') {
          if (useDiscountValidationByClientField) {
            const maxDiscountAllowed = selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value.toLowerCase()] !== undefined ? Number(selectedClient[companyPolicies.find(policy => policy.policy === useDiscountValidationByClientField.dependencie).policy_value.toLowerCase()]) : 0
            if (Number(discount) > maxDiscountAllowed)  {
              if (productInformationWarning.find(product => product.product === item.B1_COD)) {
                productInformationWarning.find(product => product.product === item.B1_COD)
                  .status = `${productInformationWarning.find(product => product.product === item.B1_COD).status}. Desconto alterada de ${discount} para ${maxDiscountAllowed}`
              } else {
                productInformationWarning.push({ 
                  product: item.B1_COD, 
                  desc: data.dados[0]['b1_desc'],  
                  status: `Desconto alterada de ${discount} para ${maxDiscountAllowed}`, 
                  type: 'quantityChange' 
                })
              }
  
              discount = String(maxDiscountAllowed)
            }
          }
  
          if (useDiscountValidationByProductField) {
            const discountField =  companyPolicies.find(policy => policy.policy === useDiscountValidationByProductField.dependencie).policy_value?.toLowerCase();
            const maxDiscountAllowed = discountField && data.dados[0][discountField] ? Number(data.dados[0][discountField]) : 0
  
            if (Number(discount) > maxDiscountAllowed)  {
              if (productInformationWarning.find(product => product.product === item.B1_COD)) {
                productInformationWarning.find(product => product.product === item.B1_COD)
                  .status = `${productInformationWarning.find(product => product.product === item.B1_COD).status}. Desconto alterada de ${discount} para ${maxDiscountAllowed}`
              } else {
                productInformationWarning.push({ 
                  product: item.B1_COD, 
                  desc: data.dados[0]['b1_desc'],  
                  status: `Desconto alterada de ${discount} para ${maxDiscountAllowed}`, 
                  type: 'quantityChange' 
                })
              }
  
              discount = String(maxDiscountAllowed)
            }
          }
        }

        if (showSuccessProductsOnImportModalWarning && !productInformationWarning.find(product => product.product === item.B1_COD)) {
          productInformationWarning.push({ 
            product: item.B1_COD, 
            desc: data.dados[0]['b1_desc'],  
            status: `Ok`, 
            type: 'success' 
          })
        }

        return { ...data.dados[0], quantity, discount: Number(discount) || 0, increase: Number(item.increase) || 0 };
      } else {
        productInformationWarning.push({ product: item.B1_COD, desc: '',  status: `Produto não encontrado`, type: 'notFound' })
      }
      return null;
    });
    
    const results = await Promise.all(promises);
    const filteredResults = results.filter(result => result !== null);

    dispatch(addNewProductQuantity({
      itemsCount: itemsCount + filteredResults.reduce((acc, curr) => acc + parseFloat(curr.quantity.toString()), 0),
      itemsList: [...itemsList, ...filteredResults]
    }));

    if (productInformationWarning.filter(productInfo => productInfo.type !== 'success').length > 0) {
      dispatch(updateModalImportCsvWarningProductInfo({ value: productInformationWarning }))
      dispatch(updateModalImportCsvWarningVisible({ value: true }))
      dispatch(updateViewCheckoutModal({ value: false }))
    } else {
      dispatch(updateViewCheckoutModal({ value: true }))
    }

    toast.update(id, { isLoading: false, autoClose: 10, closeOnClick: true })
    setIsImported(false)
  }

  useEffect(() => {
    if (useGrid) {
      handleCovertProductListToCart();
    }
  }, [itemsList, gridList, useGrid])

  useEffect(() => {
    productList.forEach((_, index) => {
      dispatch(updateProductTotalPrice({ index: index }))
    })
  }, [calculateProductTotal])

  document.querySelectorAll('input[type="number"]').forEach((input: HTMLInputElement) => {
    input.oninput = () => {
      if (input.value.length > input.maxLength) input.value = input.value.slice(0, input.maxLength);
    }
  })

  return (
    <CartContext.Provider value={ {
      isImported,
      updateIsImported: (value) => setIsImported(value),
      updateImportedData: (value) => setImportedData(value),
      handleGetTotalPrice,
      handleGetBonificationTotal,
      handleCovertProductListToCart,
      handleAdjustedPriceCalc,
      handleGetAdjustedPrice,
      handleOpenProductDetail,
      checkBalance,
      handleAddNewProduct,
      handleOpenGridModal,
      handleOpenConsultationModal,
      handleImportOrderOrBudgetToCart
    } }>
      { children }
    </CartContext.Provider>
  );
}

export default CartContextProvider;