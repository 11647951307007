import React, { FC, useMemo } from "react";
import { LockOpen } from "phosphor-react";
import { useSelector } from "react-redux";

import { styleSheet } from "../../../../assets/styles/global";

import { AccessCount, AccessCountContainer, AccessCountNumber, AccessCountText, Header } from "./styles";
import ClientsChart from "../Clients";
import 'react-loading-skeleton/dist/skeleton.css'
import { LogsReducerInitialState } from "../../../../reducers/Logs";


const ActiveClients: FC = () => {
  const {accessLogData } = useSelector((state: { logs: LogsReducerInitialState }) => state.logs);

  const keys = useMemo(() => accessLogData.reduce<Array<string | number>>((acc, cur) => {
    if (!acc.includes(cur.client)) {
      acc.push(cur.client)
    }

    return acc
  }, []), [accessLogData])

  const data = useMemo(() => keys.map(key => {
    return accessLogData.filter(accLog => accLog.client === key).length
  }), [accessLogData, keys])

  return (
    <>
      <Header style={{ height: `${window.innerHeight - 300}px` }}>
        <AccessCount>
          <LockOpen size={35} color={styleSheet.mainColor} />
          <AccessCountContainer>
            <AccessCountNumber>{accessLogData.length}</AccessCountNumber>
            <AccessCountText>acessos</AccessCountText>
          </AccessCountContainer>
        </AccessCount>
        
        <div style={{ flex: 1, height: '100%' }}>
          <ClientsChart keys={keys} data={data} /> 
        </div>
      </Header>

    </>
  );
};

export default ActiveClients;
