import React, { ChangeEvent, DragEvent, FC, useRef, useState } from 'react';
import { Button, Card, Dimmer, Grid, Icon, Label } from 'semantic-ui-react';
import { Utils } from '../../common/Utils';
import { IFile } from '../../interfaces/Attach/Attach';
import { Container, DropContainer, DropMessage, FileRemove } from './styles';

interface IDropZoneProps {
  fileList: IFile[];
  setFileList(value: IFile[]): void;
}

const DropZone: FC<IDropZoneProps> = ({ fileList, setFileList }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cardDimmerStatus, setCardDimmerStatus] = useState({});

  const utils = new Utils();

  const dragOver = (e: DragEvent) => {
    e.preventDefault();
  }

  const dragEnter = (e: DragEvent) => {
    e.preventDefault();
  }

  const dragLeave = (e: DragEvent) => {
    e.preventDefault();
  }

  async function fileDrop(e: DragEvent) {
    e.preventDefault();
    const files = Array.prototype.slice.call(e.dataTransfer.files)
    
    const filesSelected: IFile[] = [];
    for (const file of files) {
      const isFileUploaded = !!fileList.find(({ name }) => name === file.name);
      if (!isFileUploaded) {
        if (!!file) {
          const data = await utils.getBase64(file) as Promise<string>
          
          filesSelected.push({ name: file.name, data: data.toString(), size: file.size })
        }
      }
    }

    setFileList([...fileList, ...filesSelected]);
  }

  async function filesSelected(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const files = Array.prototype.slice.call(e.target.files)

    const filesSelected: IFile[] = [];
    for (const file of files) {
      const isFileUploaded = !!fileList.find(({ name }) => name === file.name);
      if (!isFileUploaded) {
        if (!!file) {
          const data = await utils.getBase64(file) as Promise<string>
          
          filesSelected.push({ name: file.name, data: data.toString(), size: file.size })
        }
      }
    }
    
    setFileList([...fileList, ...filesSelected]);
  }

  function handleDimmerBlurStatus(key: string, value: boolean) {
    const cardValueStatus = {
      [key]: value
    }

    setCardDimmerStatus({ ...cardDimmerStatus, ...cardValueStatus });

  }

  const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const fileType = (fileName: string) => {
    return fileName ? fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName : '';
  }

  const removeFile = (name: string) => {
    const selectedFileListIndex = fileList.findIndex(e => e.name === name);
    fileList.splice(selectedFileListIndex, 1);
    setFileList([...fileList]);
  }


  return (
    <Container>
      <DropContainer
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()}
        haveFiles={fileList.length > 0}
      >
        <input 
          ref={inputRef} 
          style={{ display: 'none' }} 
          type="file" 
          multiple
          onChange={filesSelected}  
        />
        {
          fileList.length === 0 &&
          <DropMessage 
            onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()}
          >
            <Icon 
              name='cloud upload' 
              size='huge' 
              onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()}
            />
            <span
              onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()}
            >
              Upload de Arquivos
            </span>
          </DropMessage>
        }
        {
          fileList.length > 0 &&
          <Grid columns="equal" onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()} fluid  style={{ width: '100%', maxHeight: '100%' }} >
            {/* <Grid.Row columns={4} onClick={(event) => event.target === event.currentTarget &&  inputRef.current.click()}  style={{ paddingTop: '2rem', background: 'green' }}> */}
              {
                fileList.map((data, i) => {
                  return (
                    <Grid.Column  key={data.name} width={4}>
                      <Dimmer.Dimmable dimmed={cardDimmerStatus[data.name]} >
                        <Card
                          key={i}
                          style={{ padding: '0' }}
                        >
                          <div>
                            <Label circular color='red' floating as='a' size="large" onClick={() => handleDimmerBlurStatus(data.name, true)}>
                              <Icon fitted name='trash alternate outline' />
                            </Label>
                          </div>
                          <Icon fitted name='file pdf outline' size='huge' color='blue' />
                          <Card.Content>
                            <Card.Header style={{ color: '#fff', background: '#0080c8', textTransform: 'uppercase', textAlign: 'center' }}>{fileType(data.name)}</Card.Header>
                            <Card.Description style={{ wordWrap: 'break-word' }}>
                              {data.name}
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra fluid content>
                            <span>({fileSize(data.size)})</span>
                          </Card.Content>
                        </Card>
                        <Dimmer
                          active={cardDimmerStatus[data.name]}
                          onClickOutside={() => handleDimmerBlurStatus(data.name, false)}
                        >
                          <FileRemove >
                            <Icon
                              name='warning circle'
                              size='large'
                              color='orange'
                            />
                            <Button
                              color='orange'
                              onClick={() => {
                                removeFile(data.name);
                                handleDimmerBlurStatus(data.name, false);
                              }
                              }
                            >
                              Clique aqui para confirmar
                            </Button>
                          </FileRemove>
                        </Dimmer>
                      </Dimmer.Dimmable>
                    </Grid.Column>
                  );
                })
              }
            {/* </Grid.Row> */}
          </Grid>
        }
      </DropContainer>

    </Container>
  );
}

export default DropZone;

//uma variável de estado que cria um objeto
//key = pode ser tb
//
//criar objeto 

