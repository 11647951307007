import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { redirect } from 'react-router';
import { Receipt } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { FinancialService } from '../../services/methods/FinancialService';

import { UIContext } from '../../contexts/UIContext';

import ViewFinancialModal from '../../components/ViewFinancialModal';
import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import EmailModal from '../../components/EmailModal';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { BrowserContent, Content, PageDefault } from './styles';
import { DataTableReducerInitialState, loadTableData, updateDataTableIndice, updateMode, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { IOpcoes } from '../../interfaces/Menu/IMenu';
import { PolicieReducerInitialState } from "../../reducers/Policie";
import { debounce } from "../../common/debounce";
import SendModal, { SendType } from "../../components/SendModal";
import ConsultationModal from "../../components/ConsultationModal";
import { updateConsultationType, updateSelectedProductToConsultation } from "../../reducers/Cart";

const Financial: FC = () => {
  const financialService: FinancialService = new FinancialService();

  const { tableData, tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableFilter, indice, mode, tableOrderBy } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, selectedClient, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch();

  const useInitialFilterFinancial = useMemo(() => companyPolicies.find(policy => policy.property === 'useInitialFilterFinancial' && policy.policy_value === 'true'), [companyPolicies])
  const initialFilterFinancial = useMemo(() => {
    if (useInitialFilterFinancial) {
      return JSON.parse(companyPolicies.find(policy => policy.policy === useInitialFilterFinancial.dependencie).policy_value)
    }

    return undefined
  }, [companyPolicies])
  const useChatbot = useMemo(() => companyPolicies.find(police => police.property === 'useChatbot' && police.policy_value === 'true'),companyPolicies);
  const useLandscapeDanfe = useMemo(() => companyPolicies.find(police => police.property === 'useLandscapeDanfe' && police.policy_value === 'true'), [companyPolicies]);
  const notHaveDanfeConfiguration = useMemo(() => companyPolicies.find(police => police.property === 'notHaveDanfeConfiguration' && police.policy_value === 'true'), [companyPolicies])
  
  const chatbotUrl = useChatbot ? useMemo(() => companyPolicies.find(police => police.policy === useChatbot.dependencie) || '',companyPolicies) : '';

  const { setUIComponentState } = useContext(UIContext);

  const [formFieldState, setFormFieldState] = useState(false);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [isDanfeXml, setIsDanfeXml] = useState(false);
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([]);
  const [printOptions, setPrintOption] = useState<IOpcoes[]>([]);
  const [filterModal, setFilterModal] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [action, setAction] = useState("");

  const [consultationModalVisible, setConsultationModalVisible] = useState(false)

  const utils = new Utils();

  async function getFinancialList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));
      const selectedClientIndice = selectedClient.indice ? String(selectedClient.indice) : ''
      const response = await financialService.index(currentCompany, currentBranch.codigo, page, itensPerPage, token, selectedClientIndice, order, filter, statusFilter)
      // const response = await financialService.index(99, '01', page, 9, token, '00000201');
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, totalizadores, oculto, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig,
          tableTotalizadores: totalizadores
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }))
    }
  }

  const getFinancialWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getFinancialList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));
      await getFinancialList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function sendTicket(email: string, sendType: SendType, typeprint?: string) {    
    const id = toast.loading("Por favor aguarde...")
    const selectedItem = tableData.find(data => data.indice === indice);
    const orientation = useLandscapeDanfe ? 2 : 1;
    const config = notHaveDanfeConfiguration && isDanfeXml;


    const isTicket = action === "boleto" ? typeprint : null
    
    setModalSendEmail(false);
    setIsSendEmail(true);
    const url = isDanfeXml ? "WSRESTDANFETIT" : "WSRESTBOLCLI"
    try {
      const objectParams = {
        nf: selectedItem,
        token,
        id: selectedItem.indice,
        orientation,
      }

      if (sendType === 'mail') {
        objectParams['email'] = email
      }

      const { data, status } = await financialService.sendTicket(
        url,
        currentBranch.codigo,
        currentCompany,
        objectParams,
        config,
        isTicket,
      );
      if(status === 200 || status === 201) {
        if (isDanfeXml) {
          if (sendType === 'download' && data.arquivos.pdf.length > 0) {
            data.arquivos.pdf.map(pdf => {
              const blob = utils.base64toBlob(pdf);
              const url = URL.createObjectURL(blob);

              const hiddenElement = document.createElement('a');
              hiddenElement.href = url
              hiddenElement.target = '_blank';
              hiddenElement.click();
            })

            if (data.arquivos.xml.length > 0) {
              data.arquivos.xml.map((xml, index) => {
                const hiddenElementXML = document.createElement('a');
                hiddenElementXML.href = xml
                hiddenElementXML.download = `${selectedItem.indice.trim()}_${index}.xml`;
                hiddenElementXML.click();
              })
             
            }
          }
        } else {
          if (sendType === 'download' && data[0].arquivos.pdf.length > 0) {
            data[0].arquivos.pdf.map(pdf => {
              const blob = utils.base64toBlob(pdf);
              const url = URL.createObjectURL(blob);

              const hiddenElement = document.createElement('a');
              hiddenElement.href = url
              hiddenElement.target = '_blank';
              hiddenElement.click();
            })
          }
        }
        

        if(isDanfeXml) {
          toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
          return Swal.fire({
            title: 'Sucesso!',
            text: data.msg,
            icon: 'success',
            confirmButtonText: 'Ok'
          })     
        }else{
          toast.update(id, {type: "success", isLoading: false, autoClose: 100, closeOnClick: true });
          for (const response of data) {
            if(response.status === "erro") {
              await toast.error(response.msg, { autoClose: 8000, closeOnClick: true })
            }else{
              await toast.success(response.msg, { autoClose: 8000, closeOnClick: true })
            } 
          }
        }
      }else{
        toast.update(id, {render: data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      console.log(error)
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }

 
  }

  function onLogFinancialClick(indice: string | number) {
    const selectedRow = tableData.find(item => item.indice === indice)
    dispatch(updateConsultationType({ value: selectedMenu.nome }))
    dispatch(updateSelectedProductToConsultation({ value: selectedRow }))
    setConsultationModalVisible(true);
  }

  async function filterFinancial(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }));
    await getFinancialList(1, tableOrderBy, filter, statusFilter);
  }

  async function orderFinancia(order: string) {
    dispatch(updateTableOrderBy({ value: order }))
    setModalSendEmail(false);
    await getFinancialList(1, order, tableFilter.filter, tableFilter.statusFilter);
  }

  async function onViewUserClick() {
    setFormFieldState(true);
  }

  function onSendEmailClick(action: string, isDanfe) {

    if (configuracoes.linkbillet && configuracoes.linkbillet !== '') {
      const link = document.createElement('a');
      //só abre se o link vier com o protocolo, ex.: http|https
      link.href = configuracoes.linkbillet;
      link.target = '_blank';
      link.click();
      return;
    }

    if (
      action === "boleto" &&
      selectedMenu.submenu.find(
        (submenu) => submenu.nome.toLowerCase() === "boleto"
      ).opcoes.length > 0
    ) {
      setPrintOption(
        selectedMenu.submenu.find(
          (submenu) => submenu.nome.toLowerCase() === "boleto"
        ).opcoes
      );
      setAction(action);
    } else {
      setPrintOption([]);
      setAction("");
    }

    setModalSendEmail(true);
    setIsDanfeXml(isDanfe);
  }

  useEffect(() => {
    if (useInitialFilterFinancial) {
      dispatch(updateTableFilter({ value: { filter: undefined, statusFilter: initialFilterFinancial } }))
    }
    getFinancialList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterFinancial)
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<Receipt size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Datatable
              serviceName="wstitrec"
              onItensPerPageChange={() => getFinancialList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onSearch={ (value, noDebounce) => getFinancialWithTextSearch(noDebounce ? 'enter' : '', value) }
              onPageChange={(page) => getFinancialList(page, tableOrderBy, tableFilter.filter, tableFilter.statusFilter)}
              onOrderBy={(value) => orderFinancia(value)}
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterFinancial(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => {
                dispatch(updateDataTableIndice({ value: indice }));
                if (action !== 'upload') dispatch(updateMode({ value: action }));
                if (action === 'filter') setFilterModal(true);
                if (action === 'view') onViewUserClick();
                if (action === 'boleto') onSendEmailClick(action, false);
                if (action === 'xml') onSendEmailClick(action, true);
                if (action === 'print') sendTicket('', 'download');
                if (action === 'log') onLogFinancialClick(indice)
              }}
            />
        </BrowserContent>
        </Browser>

      </Content>


      {
        formFieldState &&
        <ViewFinancialModal
          indice={indice}
          onClose={() => setFormFieldState(false)}
          visible={formFieldState}
          mode={mode}
        />
      }


      <Footer />


      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterFinancial}
        onClose={() => {
          setFilterModal(false)
        }}
      />
    

    {/* {
      modalSendEmail &&
      <EmailModal
        visible={modalSendEmail}
        onClose={() => setModalSendEmail(false)}
        handleSendEmail={ (email, type) => sendTicket(email, type) }
        isSendEmail={isSendEmail}
        options={ printOptions }
      />
    } */}

    {
      modalSendEmail &&
      <SendModal
        visible={modalSendEmail}
        onClose={() => setModalSendEmail(false)}
        handleSend={ (email, type) => sendTicket(email, type) }
        isSend={isSendEmail}
        options={ printOptions }
      />
    }

      <ConsultationModal 
        visualizationOnly={true}
        consultationServiceName="wsconsgener"
        visible={consultationModalVisible}
        onClose={() => setConsultationModalVisible(false)}
      />
    </PageDefault>

  );

}

export default Financial;
