import React, { FC, useEffect, useState } from 'react';
import { Form, Input, TextArea, Button, Select, Modal } from 'semantic-ui-react';

import axios from 'axios';

import Logo from './../../assets/images/pyli.png'
import packageJson from '../../../package.json';
import { HeaderContainer, InputImageContainer, LogoContainer,Logotipo, NoticeSpan, NoticeSpanContainer, SelectContainer, SelectContent } from './styles';
import DropZone from '../DropZone';
import { IFile } from '../../interfaces/Attach/Attach';
import { toast } from 'react-toastify';
import { SupportEmailService } from '../../services/methods/SupportEmailService';
import { useSelector } from 'react-redux';
import { SupportReducerInitialState } from '../../reducers/Support';
import { encode } from 'base-64';
import { styleSheet } from '../../assets/styles/global';
import { bucketURL } from '../../common/Constants';
import { PolicieReducerInitialState } from '../../reducers/Policie';


interface ISupportModal {
  isSupportModaOpen: boolean;
  setIsSupportModaOpen(value: boolean): void;
}


const SupportModal: FC<ISupportModal> = ({isSupportModaOpen, setIsSupportModaOpen}) =>  {
  const {  companyName, companyEnvironment  } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const [ userName, setUserName ] = useState('');
  const [ userNameError, setUserNameError ] = useState(false);
  const [ userEmail, setUserEmail ] = useState('');
  const [ userEmailError, setUserEmailError ] = useState(false);
  const [ userFunctionalityInput, setUserFunctionalityInput ] = useState('');
  const [ userFunctionalityInputError, setUserFunctionalityInputError ] = useState(false);
  const [ userAction, setUserAction ] = useState('');
  const [ userActionError, setUserActionError ] = useState(false);
  const [ userImpact, setUserImpact ] = useState('');
  const [ userImpactError, setUserImpactError ] = useState(false);
  const [ userDescription, setUserDescription ] = useState('');
  const [ userDescriptionError, setUserDescriptionError ] = useState(false);
  const [ formError, setFormError ] = useState(false);
  const [ fileList, setFileList ] = useState<IFile[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ token, setToken ] = useState('');
  const [ isCloseIconModal, setIsCloseIconModal ] = useState(true);
  

  const supportEmailService: SupportEmailService = new SupportEmailService();
  // const { token } = useSelector((state: { support: SupportReducerInitialState }) => state.support);

  
  const impactSelect = [
    { key: '1', text: 'Duvida sobre o produto', value: 'Duvida sobre o produto' },
    { key: '2', text: 'Mau funcionamento ou desempenho com procedimento paliativo', value: 'Mau funcionamento ou desempenho com procedimento paliativo' },
    { key: '3', text: 'Mau funcionamento ou desempenho em atividade não crítica', value: 'Mau funcionamento ou desempenho em atividade não crítica' },
    { key: '4', text: 'Mau funcionamento ou desempenho em atividade críticas sem procedimento paliativo', value: 'Mau funcionamento ou desempenho em atividade críticas sem procedimento paliativo' },
  ]

  const funcionalitySelect = [
    { key: 'HM', text: 'Home', value: 'Home', 
      submenu:[]
    },
    { key: 'CLI', text: 'Clientes', value: 'Clientes', 
      submenu: [
        { key: '1', text: 'Inclusão', value: 'Inclusão' },
        { key: '2', text: 'Alteração', value: 'Alteração' },
        { key: '3', text: 'Visualização', value: 'Visualização' },
        { key: '4', text: 'Excluir', value: 'Excluir' },
        { key: '5', text: 'Anexar documento', value: 'Anexar documento' },
        { key: '6', text: 'Incluir Pedido', value: 'Incluir Pedido' },
        { key: '7', text: 'Incluir Orçamento', value: 'Incluir Orçamento' },
      ] 
    },
    
    { key: 'USR', text: 'Usuarios', value: 'Usuários',
     submenu:[
        { key: '1', text: 'Inclusão', value: 'Inclusão' },
        { key: '2', text: 'Alteração', value: 'Alteração' },
        { key: '3', text: 'Resetar senha', value: 'Resetar senha' },
      ] 
    },
    { key: 'PED', text: 'Pedidos', value: 'Pedidos',
     submenu:[
        { key: '1', text: 'Inclusão', value: 'Inclusão' },
        { key: '2', text: 'Alteração', value: 'Alteração' },
        { key: '3', text: 'Visualização', value: 'Visualização' },
        { key: '4', text: 'Enviar Pedido', value: 'Enviar Pedido' },
        { key: '5', text: 'Impressão', value: 'Impressão' },
      ]
    },
    { key: 'ORC', text: 'Orçamentos', value: 'Orçamentos', 
      submenu:[
        { key: '1', text: 'Visualizar', value: 'Visualizar' },
        { key: '2', text: 'Enviar Pedido', value: 'Enviar Pedido' },
        { key: '3', text: 'Efetivar orçamento', value: 'Efetivar orçamento' },
        { key: '4', text: 'Incluir orçamento', value: 'Incluir orçamento' },
        { key: '5', text: 'Alterar orçamento', value: 'Alterar orçamento' },
        { key: '6', text: 'Impressão', value: 'Impressão' },
      ] 
    },
    { key: 'FIN', text: 'Financeiro', value: 'Financeiro',
     submenu:[
        { key: '1', text: 'Visualizar', value: 'Visualizar' },
        { key: '2', text: 'Boleto', value: 'Boleto' },
      ]
    },
    { key: 'DASH', text: 'Dashboard', value: 'Dashboard', 
      submenu:[] 
    },
    { key: 'VEN', text: 'Representante', value: 'Representante', 
      submenu:[
        { key: '1', text: 'Inclusão', value: 'Inclusão' },
        { key: '2', text: 'Alteração', value: 'Alteração' },
        { key: '3', text: 'Upload', value: 'Upload' },
        { key: '4', text: 'Pendentes', value: 'Pendentes' },
      ] 
    },
    { key: 'AGEN', text: 'Agendas', value: 'Agendas', 
      submenu:[
        { key: '1', text: 'Inclusão', value: 'Inclusão' },
        { key: '2', text: 'Alteração', value: 'Alteração' },
        { key: '3', text: 'Excluir', value: 'Excluir' },
      ] 
    },
  ]

  function osSearch() {
    const os = navigator.userAgent;
    let osFinded = '';
    
    if (os.search('Windows')!==-1){
      osFinded="Windows";
    }
    else if (os.search('Mac')!==-1){
        osFinded="MacOS";
    }
    else if (os.search('X11')!==-1 && !(os.search('Linux')!==-1)){
        osFinded="UNIX";
    }
    else if (os.search('Linux')!==-1 && os.search('X11')!==-1){
        osFinded="Linux"
    }
    return osFinded;
  }


  function handleSumbimt () {
    let error = false;

    if(userName === '') {
      setUserNameError(true);
      error = true;
    } else {
      setUserNameError(false);
    }
    if(userEmail === '') {
      setUserEmailError(true);
      error = true;
    } else {
      setUserEmailError(false);
    }
    if(userFunctionalityInput === '') {
      setUserFunctionalityInputError(true);
      error = true;
    } else {
      setUserFunctionalityInputError(false);
    }
    if(userAction === '') {
      setUserActionError(true);
      error = true;
    } else {
      setUserActionError(false);
    }

    if(userDescription === '') {
      setUserDescriptionError(true);
      error = true;
    } else {
      setUserDescriptionError(false);
    }
    if(userImpact === '') {
      setUserImpactError(true);
      error = true;
    } else {
      setUserImpactError(false);
    }

    if(error){
      setFormError(true);
      return;
    }
    setFormError(false);

    handleRequestSupport();

  }

  async function handleRequestSupport(){
    const id = toast.loading('Por favor aguarde...')
   
    try {
      setIsLoading(true);
      setIsSupportModaOpen(true)
      
      const files = fileList.map(file => {
        return {
          filename: file.name,
          content: file.data
        }
      })

      const supportParams ={
        fromName: "Ethosx Suporte Web",
        fromMail: "suporteweb@ethosx.com",
        to: ["aws@ethosx.com"],
        subject: "Seu ticket foi enviado",
        html: 
        `
          <span> Versão do app: <font color='red'><strong>${packageJson.version}</strong></font></span> 
          <br>
          <span> Sistema Operacional: <font color='red'><strong>${osSearch()} </strong></font></span> 
          <br>
          <span> URL: <font color='red'><strong>${window.location.href}<strong></font></span> 
          <br>
          <span> Nome usuário: <font color='red'><strong>${userName}<strong></font></span>
          <br>
          <span> Email usuário: <font color='red'><strong>${userEmail}<strong></font></span>
          <br>
          <span> Impacto: <font color='red'><strong>${userImpact}<strong></font></span>
          <br>
          <span> Qual funcionalidade: <font color='red'><strong>${userFunctionalityInput}<strong></font></span>
          <br>
          <span> O que não está funcionando: <font color='red'><strong>${userAction}<strong></font></span>
          <br>
          <p> Descrição do ocorrido: ${userDescription}</p>
        `,
        files: files
      }

      const encodeAuthorization = encode('ethosx:3th0sx')
      const { data: { token } } = await supportEmailService.authToken(encodeAuthorization)
      const { data, status } = await supportEmailService.send(supportParams, token);
      if (status === 201 || status === 200) {
        setUserName('');
        setUserEmail('');
        setUserFunctionalityInput('');
        setUserAction('');
        setUserImpact('');
        setUserDescription('');
        setFileList([]);
        
        return toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      }

    } catch (error) {
      console.log(error)

      return toast.update(id, {isLoading: false, autoClose: 10})
    } finally {
      setIsLoading(false)
      setIsSupportModaOpen(false)
    }
  }


  const userFunctionalityInputFound = funcionalitySelect.find(item => item.value === userFunctionalityInput) ? funcionalitySelect.find(item => item.value === userFunctionalityInput).submenu : []

  return(
    <Modal
      open={isSupportModaOpen}
      closeIcon
      closeOnDimmerClick={false}
      size='fullscreen'
      style={{padding: '2rem', margin: 'auto'}}
      centered
      onOpen={() => {
        setIsSupportModaOpen(true)
      }}
      onClose={() => {
        setIsSupportModaOpen(false);
        setUserName('');
        setUserNameError(false);
        setUserEmail('');
        setUserEmailError(false);
        setUserFunctionalityInput('');
        setUserFunctionalityInputError(false);
        setUserAction('');
        setUserActionError(false);
        setUserImpact('');
        setUserImpactError(false);
        setUserDescription('');
        setUserDescriptionError(false);
        setFileList([]);
      }}
    >
      <HeaderContainer>
        <LogoContainer>
          <Logotipo className="logoBucket" src={companyName && `${bucketURL}/${companyEnvironment}/${companyName}/logo`} alt='Logo Pyli'></Logotipo>
        </LogoContainer> 
      </HeaderContainer>
      
      <Modal.Content scrolling>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input
              id='form-input-control-first-name'
              value={userName}
              required
              control={Input}
              label='Nome'
              type='text'
              placeholder='Nome'
              onChange={(value) => { setUserName(value.currentTarget.value) }}
              onBlur={ userName === '' ? () => setUserNameError(true) : () => setUserNameError(false)}
              error={userNameError}
            />
            <Form.Input
              id='form-input-control-error-email'
              value={userEmail}
              control={Input}
              required
              label='E-mail'
              type='email'
              placeholder='seuendereço@gmail.com'
              onChange={(value) => { setUserEmail(value.currentTarget.value) }}
              onBlur={ userEmail === '' ? () => setUserEmailError(true) : () => setUserEmailError(false)}
              error={userEmailError}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              control={Select}
              options={
                  impactSelect.sort(function(a,b){
                  if(a.text < b.text) return -1;
                  if(a.text > b.text) return 1;
                  return 0;
                })
              }
              value={userImpact}
              style={{width: '100%'}}
              required
              placeholder='Selecione o tipo de funcionalideda'
              label={{ children: 'Impacto', htmlFor: 'support-impact' }}
              searchInput={{ id: 'support-impact' }}
              onChange={(ev, value) => { setUserImpact(value.value) }}
              error={userImpactError}
            />
           
            <Form.Input
              control={Select}
              options={
                [
                  ...funcionalitySelect.sort(function(a,b) {
                  if(a.text < b.text) return -1;
                  if(a.text > b.text) return 1;
                  return 0;
                }),
                {Key:"OUT", text: 'Outros', value: 'Outros'}
              ]
              }
              value={userFunctionalityInput}
              style={{width: '100%'}}
              required
              label={{ children: 'Funcionalidade', htmlFor: 'support-funcionality' }}
              placeholder='Selecione o tipo de funcionalideda'
              searchInput={{ id: 'support-funcionality' }}
              onChange={(ev, value) => { setUserFunctionalityInput(value.value.toString()) }}
              error={userFunctionalityInputError}
            />
          
            <Form.Input
              control={Select}
              options={
                [
                  ...userFunctionalityInputFound.sort(function(a,b) {
                  if(a.text < b.text) return -1;
                  if(a.text > b.text) return 1;
                  return 0;
                }),
                {Key:"OUT", text: 'Outros', value: 'Outros'}
              ]
              }
              value={userAction}
              style={{width: '100%'}}
              required
              label={{ children: 'Ação', htmlFor: 'support-action' }}
              placeholder='Selecione o tipo de funcionalideda'
              searchInput={{ id: 'support-action' }}
              onChange={(ev, value) => { setUserAction(value.value.toString()) }}
              error={userActionError}
                
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              control={TextArea}
              id='form-textarea-control-opinion'
              value={userDescription}
              required
              label='Descreva o ocorrido:'
              placeholder='Descrição do ocorrido...'
              onChange={(value) => { setUserDescription(value.currentTarget.value) }}
              onBlur={ userDescription === '' ? () => setUserDescriptionError(true) : () => setUserDescriptionError(false)}
              error={userDescriptionError}
            />
          
          </Form.Group>
          <InputImageContainer>
            <span>
              Caso tenha um arquivo/imagem, adicione abaixo:
            </span>
            <DropZone
              fileList={fileList}
              setFileList={setFileList}
            />
          </InputImageContainer>
          
          <NoticeSpanContainer>
            <NoticeSpan>Confirme todas as informações descritas acima antes de enviar*</NoticeSpan>
          </NoticeSpanContainer>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => {
            setIsSupportModaOpen(false);
            setUserName('');
            setUserNameError(false);
            setUserEmail('');
            setUserEmailError(false);
            setUserFunctionalityInput('');
            setUserFunctionalityInputError(false);
            setUserAction('');
            setUserActionError(false);
            setUserImpact('');
            setUserImpactError(false);
            setUserDescription('');
            setUserDescriptionError(false);
            setFileList([]);
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={() => {
            setUserNameError(false);
            setUserEmailError(false);
            setUserFunctionalityInputError(false);
            setUserActionError(false);
            setUserImpactError(false);
            setUserDescriptionError(false);
            handleSumbimt()
          }} 
          positive
          type='submit'
          disabled={
            !userName
            || !userEmail
            || !userFunctionalityInput
            || !userAction
            || !userDescription
            || !userImpact
          }
        > 
          Enviar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SupportModal;