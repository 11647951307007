import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Buildings } from 'phosphor-react';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { ClientService } from '../../services/methods/ClientService';
import { FieldService } from '../../services/methods/FieldService';

import AttachModal from '../../components/AttachModal';
import { UIContext } from '../../contexts/UIContext';

import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import Datatable from '../../components/Datatable';
import FormModal from '../../components/FormModal';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';

import { clearAll, fillFieldAndFieldValues } from '../../reducers/RestField';
import { EnvironmentReducerInitialState, updateClientLegends } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { Content, PageDefault, BrowserContent } from './styles';
import { DataTableReducerInitialState, loadTableData, updateDataTableIndice, updateMode, updateTableCurrentPage, updateTableData, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { toast } from 'react-toastify';
import { debounce } from "../../common/debounce";
import { PolicieReducerInitialState } from "../../reducers/Policie";
import ConsultationModal from "../../components/ConsultationModal";
import { updateConsultationType, updateSelectedProductToConsultation } from "../../reducers/Cart";

const Clients: FC = () => {
  const clientService: ClientService = new ClientService();
  const fieldService: FieldService = new FieldService();

  const { tableData, tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableFilter, tableOrderBy, indice } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies, customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);

  const dispatch = useDispatch();

  const useInitialFilterClient = useMemo(() => companyPolicies.find(policy => policy.property === 'useInitialFilterClient' && policy.policy_value === 'true'), [companyPolicies])
  const initialFilterClient = useMemo(() => {
    if (useInitialFilterClient) {
      return JSON.parse(companyPolicies.find(policy => policy.policy === useInitialFilterClient.dependencie).policy_value)
    }

    return undefined
  }, [companyPolicies])


  const { setUIComponentState } = useContext(UIContext);

  const [attachModalOpen, setAttachModalOpen] = useState(false)
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([])
  const [fieldLoad, setFieldLoad] = useState(false);
  const [formFieldState, setFormFieldState] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [consultationModalVisible, setConsultationModalVisible] = useState(false)

  const utils = new Utils();

  async function getClientList(page: number, order: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    try {
      dispatch(updateTableCurrentPage({ value: page }))
      dispatch(updateTableLoading({ value: true }))

      const response = await clientService.index(currentBranch.codigo, page, itensPerPage, token, order, currentCompany, filter, statusFilter);
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, oculto, help, nivel } = response.data as IPaginatedServiceResponse;

      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados);
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableHelps: help,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig
        }))

        dispatch(updateClientLegends({ value: legenda }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }));
    }
  }

  const getClientsWithTextSearch = useCallback(debounce(async (searchValue: string) => {
   
    if (!!searchValue) {

      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getClientList(1, tableOrderBy, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));

      await getClientList(1, tableOrderBy, undefined, tableFilter.statusFilter);
    }
    
    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function getClientFields(indice?: string | number) {
    try {
      setFieldLoad(true);
      const body: IRestFieldRequestData = {
        alias: 'SA1',
        alias2: '',
        url: '',
        tab: '1',
        usuario: '',
        indice: indice ? String(indice) : '',
        ordem: 1,
        token: token,
      }
      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        dispatch(fillFieldAndFieldValues({ fields: data.campos }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setFieldLoad(false)
    }
  }

  async function filterClient(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }))
    await getClientList(1, tableOrderBy, filter, statusFilter)
  }

  async function orderClient(order: string) {
    dispatch(updateTableOrderBy({ value: order }))
    await getClientList(1, order, tableFilter.filter, tableFilter.statusFilter)
  }

  async function onNewUserClick() {
    setFormFieldState(true);
    await getClientFields();
  }

  async function onEditUserClick(indice: string | number) {
    setFormFieldState(true);
    await getClientFields(indice);
  }

  async function onViewUserClick(indice: string | number) {
    setFormFieldState(true);
    await getClientFields(indice);
  }

  async function onDeleteUserClick(indice: string | number) {
    return Swal.fire({
      title: 'Confirmar ação?',
      text: 'Você deseja excluir esse Cliente!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteClient(indice);
      }
    });

  }

  function onLogClientClick(indice: string | number) {
    const selectedRow = tableData.find(item => item.indice === indice)
    dispatch(updateConsultationType({ value: selectedMenu.nome }))
    dispatch(updateSelectedProductToConsultation({ value: selectedRow }))
    setConsultationModalVisible(true);
  }

  async function handleDeleteClient(indice: string | number) {
    const id = toast.loading("Por favor aguarde...")

    try {
      const { data, status } = await clientService.delete(indice, currentCompany.toString(), currentBranch.codigo, token)

      if (status === 200 && data.status === 'ok') {
        const tableDataArr = [...tableData];
        const newTableData = tableDataArr.filter(item => item.indice !== indice);
        dispatch(updateTableData({ value: newTableData }));
        toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
        return Swal.fire({
          title: 'Sucesso!',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else {
        toast.update(id, { render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
        return Swal.fire({
          title: 'Ops!',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      console.log('error', error)
      return toast.update(id, { render: 'Algo deu errado!', type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
    }
  }

  async function onAttachUserClick() {

    setAttachModalOpen(true)
  }

  useEffect(() => {
    if (useInitialFilterClient) {
      dispatch(updateTableFilter({ value: { filter: undefined, statusFilter: initialFilterClient } }))
    }

    getClientList(tableCurrentPage, tableOrderBy, undefined, initialFilterClient);
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <SideMenu />
      <Content>
        <Browser title={ selectedMenu.descri || selectedMenu.nome } icon={ <Buildings size={ 24 } color={ styleSheet.mainColor } /> }>

          <BrowserContent>
            <Datatable
              serviceName="wscliente"
              onItensPerPageChange={ () => getClientList(1, tableOrderBy, tableFilter.filter, tableFilter.statusFilter) }
              onPageChange={ (page) => getClientList(page, tableOrderBy, tableFilter.filter, tableFilter.statusFilter) }
              onOrderBy={ (value) => orderClient(value) }
              onStatusFilter={ (status: { [key: string]: string } | undefined) => filterClient(tableFilter.filter, status) }
              onDatatableActionClick={ (action, indice) => {
                dispatch(updateDataTableIndice({ value: indice }))
                if (action !== 'upload') dispatch(updateMode({ value: action }))
                if (action === 'add') onNewUserClick();
                if (action === 'edit') onEditUserClick(indice);
                if (action === 'view') onViewUserClick(indice);
                if (action === 'filter') setFilterModal(true)
                if (action === 'upload') onAttachUserClick()
                if (action === 'delete') onDeleteUserClick(indice)
                if (action === 'log') onLogClientClick(indice)
              } }
              onSearch={(value, noDebounce) => {
                getClientsWithTextSearch(noDebounce ? 'enter' : 'change', value)
              }}
            />
          </BrowserContent>
        </Browser>
      </Content>

      <Footer />

      {
        formFieldState &&
        <FormModal
          visible={ formFieldState }
          fieldIsLoading={ fieldLoad }
          onClose={ (reload) => {
            if (reload) {
              getClientList(tableCurrentPage, tableOrderBy, tableFilter.filter, initialFilterClient);
            }
            setFormFieldState(false)
            dispatch(clearAll())
          } }
        />
      }

      <FilterModal
        visible={ filterModal }
        filtersValue={ filtersValue }
        setFiltersValues={ setFiltersValue }
        handleFilter={ filterClient }
        onClose={ () => {
          setFilterModal(false)
        } }
      />

      {
        attachModalOpen &&
        <AttachModal
          indice={ indice }
          open={ attachModalOpen }
          onClose={ () => {
            setAttachModalOpen(false)
            dispatch(updateDataTableIndice({ value: '' }))
          } }
          reload={ () => {
            getClientList(tableCurrentPage, tableOrderBy, tableFilter.filter, tableFilter.statusFilter);
          } }
        />
      }

      <ConsultationModal 
        visualizationOnly={true}
        consultationServiceName="wsconsgener"
        visible={consultationModalVisible}
        onClose={() => setConsultationModalVisible(false)}
      />
    </PageDefault>
  );
}

export default Clients;