import { AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";

export class HomeService {
  public async getMessage(authToken: string, branchId: string, companyId: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`wsrestmensagem/${authToken}`, {
      params: {
        branchId,
        companyId
      }
    });
    return response;
  }

  public async getMenu(authToken: string): Promise<AxiosResponse> {
    const response = await protheusProvider.get(`wsrestmenu/${authToken}`);
    return response;
  }
}