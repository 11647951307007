import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const PageDefault = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-bottom: 2.5rem;
`;

export const BrowserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  width: calc(100% - 6.5rem);
  height: 100%;
  margin-left: 3rem;
`;

export const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  /* padding: 2rem .3rem 0 2rem; */
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;     
  }

  ::-webkit-scrollbar-track {
    background: #EEEEEE;    
    border-radius: 20px;       

  }

  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;    
    border-radius: 20px;       
    border: 3px solid #EEEEEE;  
  }
`;

export const Calendar = styled.div`
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  position: relative;

  th.fc-day-mon,
  th.fc-day-tue,
  th.fc-day-wed,
  th.fc-day-thu,
  th.fc-day-fri {
    background-color: ${({ theme }) => theme.mainColor}  !important;
  }

  .fc-day-sat,
  .fc-day-sun {
    background-color: ${({ theme }) => theme.lighterGrey}  !important;
  }

  th.fc-day-sat,
  th.fc-day-sun {
    background-color: ${({ theme }) => theme.darkerGrey}  !important;
  }

`;

interface DataProps  {
  color: string;
}

export const Data = styled.div<DataProps>`
  width: 100%;
  background: ${({ theme }) => theme.lighterGrey}; //${styleSheet.shape};
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
  border: 2px solid ${({ color }) => color};

  width: 100%;
  height: 100%;

  padding: 1rem 0.2rem;
  font-size: 1.2rem;
  cursor: pointer;
  white-space: normal;
  margin-top: 0.5rem;
  font-weight: bold;

  border-radius: 4px;
`;

export const Title = styled.h2`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
  font-weight: bold;
  display: inline;
`;

export const SubTitle = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.mediumGrey}; //${styleSheet.mediumGrey};
  font-weight: normal;
`;