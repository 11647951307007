import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

export const PageDefault = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 12rem;
  height: 12rem;
  border: 1px solid #000;
  border-radius: .5rem;
`;

export const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  gap: .5rem;
`;


export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;
export const Logotipo = styled.img`
  height: 5rem;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
`;

export const SelectContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 80%;
  gap: 0.5rem;

  legend::after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
  }
`;

export const NoticeSpanContainer = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: end;
  margin-top: 2rem;
  padding: 1.2rem 0rem 0 0;
`;
export const NoticeSpan = styled.span`
  font-size: 1.2rem;
  color: ${styleSheet.dangerColor};
  font-weight: bold;
`;

export const InputImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .5rem;
  
  span{
    font-weight: bold;
  }
`;
 