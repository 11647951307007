import { Button, Container, Icon, Input, Label,  Menu, Pagination, Popup, Table } from 'semantic-ui-react';
import { Barcode, Code, Upload, CalendarPlus, Password, Eye, Trash, Printer, Pencil, Envelope, Copy } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useRef, useState } from 'react';

import { SelectedContainer } from '../../Browser/styles';
import DatatableSubMenu from '../../DatatableSubMenu';
import DatatableLoad from '../DatatableLoad';

import { ITotalizadores, LegendType, TableData, TableTitle } from '../../../interfaces/Datatable/IPaginatedServiceResponse';
import { FieldTypeConfig, FiltersType } from '../../../@types/Datatable';
import { ISubmenuItem } from '../../../interfaces/Menu/IMenu';
import DatatableField from '../DatatableField';

import { EnvironmentReducerInitialState, updateOrderBy, updateSelectedClient, updateSelectedRow } from '../../../reducers/Environment';
import { MenuReducerInitialState } from '../../../reducers/Menu';

import { ButtonTable, Fieldset, Legend, LegendContainer, LegendInitialName, LegendName, LegendStatus, TableColumnText, TableContainer, TableDataText } from './styles';
import { RestFieldReducerInitialState } from '../../../reducers/RestField';
import { PolicieReducerInitialState } from "../../../reducers/Policie";

const IconsMenu = {
  Alterar: <Pencil size={20} color="#A15F7F"  />,
  AlterarOrc: <Pencil size={20} color="#A15F7F"  />,
  Visualizar: <Eye size={20} color="#6FCCF1" />,
  Excluir: <Trash size={20} color="#E47261" />,
  Upload: <Upload size={20} color="#4A485F" />,
  'Danfe/XML': <Code size={20} color="#84686D" />,
  Boleto: <Barcode size={20} color="#4AAF9A" />,
  Efetivar: <CalendarPlus size={20} color="#648D56" />,
  EfetivaOrc: <CalendarPlus size={20} color="#648D56" />,
  ResetSenha: <Password size={20} color="#F8AA94" />,
  EnviaPedid:  <Envelope size={22} color="#EDA14C" />,
  Print:  <Printer size={22} color="#CBBA86" />,
  Copia: <Copy size={20} color="#4AAF9A" />,
}

enum ColorsMenu {
  Alterar = 'purple',
  AlterarOrc = 'purple',
  Visualizar = 'blue',
  Excluir = 'red',
  Upload = 'blue',
  'Danfe/XML' = 'violet',
  Boleto = 'teal',
  Efetivar = 'green',
  ResetSenha = 'pink',
  EnviaPedid = 'orange',
  Print = 'yellow',
  Copia = 'teal'
}

enum FunctionsMenu {
  Alterar = 'edit',
  AlterarOrc = 'edit',
  Visualizar = 'view',
  Excluir = 'delete',
  Upload = 'upload',
  'Danfe/XML' = 'xml',
  Boleto = 'boleto',
  Efetivar = 'effect',
  ResetSenha = 'resetPassword',
  EnviaPedid = 'email',
  Print = 'print',
  Copia = 'copy'
}

interface IDatatableOrderServiceProps {
  isClientTable: boolean;
  title: TableTitle;
  loading: boolean;
  order: string[];
  data: TableData[];
  legend: LegendType[];
  fieldTypeConfig: FieldTypeConfig;
  fieldFilters: FiltersType;
  totalPages: number;
  currentPage?: number;
  totalizers?: ITotalizadores[];
  searchText?: string;
  hasActions?: boolean;
  onPageChange(page: number): void;
  onDatatableActionClick(action: string, indice?: string | number): void;
  onSearch?(value: string, noDebounce?: boolean): void;
  statusFilter?: { [key: string]: string } | undefined;
  onStatusFilter?(value: { [key: string]: string } | undefined): void;
  onOrderBy?(value: string): void
}


const DatatableOrderService: FC<IDatatableOrderServiceProps> = ({ loading, isClientTable, title, order, data, legend, totalPages, searchText, fieldFilters, hasActions = true, onPageChange, onDatatableActionClick, onSearch, currentPage, onStatusFilter, statusFilter, onOrderBy, totalizers }) => {

  const { selectedClient, selectedRow, orderBy } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { fieldValues } = useSelector((state: { restField: RestFieldReducerInitialState }) => state.restField);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const { customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const dispatch = useDispatch()

  const enterKeyPressed = useRef(false)

  const [subMenu, setSubMenu] = useState<ISubmenuItem[]>(selectedMenu?.submenu);

  function handleOrderBy(column: string) {
    if (fieldFilters.character.includes(column)) {
      console.log(orderBy,  column)
      if (Object.keys(orderBy)[0] === column) {
        switch (orderBy[column]) {
          case "ascending":
            onOrderBy(`${column} DESC`)
            dispatch(updateOrderBy({ value: { [column]: "descending" } }))
            break;
          case "descending":
            onOrderBy(``)
            dispatch(updateOrderBy({ value: { [column]: null } }))

            break;
          case null:
            onOrderBy(`${column} ASC`)
            dispatch(updateOrderBy({ value: { [column]: "ascending" } }))

            break;
        }
      } else {
        onOrderBy(`${column} ASC`)
        dispatch(updateOrderBy({ value: { [column]: "ascending" } }))
      }
    }

  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        enterKeyPressed.current = true
        document.querySelector<HTMLInputElement>('#inputSearchOrderService').blur()
      } else {
        enterKeyPressed.current = false
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
  
  return (
    <Container style={{ width: '100%' }}>
      <Menu borderless>
        <Menu.Item>
          <Input
            id="inputSearchOrderService"
            className='icon'
            icon='search'
            defaultValue={searchText}
            onChange={(event) => onSearch(event.target.value, enterKeyPressed.current)}
            onBlur={(event) =>  onSearch(event.target.value, enterKeyPressed.current)}
            placeholder='Pesquisar...'
            style={{ width: '40rem' }} />
        </Menu.Item>

        {
          hasActions ? (
            <Menu.Item position='right'>
              <DatatableSubMenu />
              <SelectedContainer>
                {
                  fieldValues['AB6_STATUS'] === 'A' && (
                    <Button icon labelPosition='right' positive size='large' onClick={() => {
                      onDatatableActionClick('apontar')
                    }}>
                      Apontar
                      <Icon name='plus' />
                    </Button>
                  )
                }
              </SelectedContainer>
            </Menu.Item>
          ) : null
        }
        
      </Menu>
      <TableContainer>
        {
          loading ? <DatatableLoad itensPerPage={5}/> :
          <Table celled compact={customizations['useCompactLineOnBrowserTables'] ? 'very' : true}  sortable selectable color='teal'>
            <Table.Header>
              <Table.Row>
                {
                  order.map((column) => {
                    return (
                      <Table.HeaderCell
                        key={column}
                        onClick={() => handleOrderBy(column)}
                        sorted={orderBy[column] || null}
                      >
                        <TableColumnText>
                          { customizations['useCapitalLetters'] ? title[column].toUpperCase() : title[column] }
                        </TableColumnText>
                      </Table.HeaderCell>
                    );
                  })
                }
                {
                  hasActions ? (
                    <Table.HeaderCell className='dataHeaderTableFixedActions' textAlign="center" key='actions'>
                      <TableColumnText>
                        {customizations['useCapitalLetters'] ? 'AÇÕES' : 'Ações'}
                      </TableColumnText>
                    </Table.HeaderCell>
                  ) : null
                }
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                data.map((row, index) => {
                  return (
                    <Table.Row 
                      key={row.indice} 
                      onClick={() => {
                        dispatch(updateSelectedRow({ value: row }))

                        if (isClientTable) {
                          dispatch(updateSelectedClient({ value: row }))
                        }
                      } } 
                      active={isClientTable ? selectedClient.indice === row.indice : selectedRow.indice === row.indice} style={{ cursor: 'pointer' }}
                    >
                      {
                        order.map((columnName) => {
                          return (
                            <Table.Cell width={!!legend.find(({ campo }) => campo === columnName) ? 1 : 2} key={columnName}>
                              {
                                !!legend.find(({ campo }) => campo === columnName) &&
                                <Label
                                  circular
                                  size='large'
                                  style={{
                                    background: legend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]) ? legend.find(({ campo }) => campo === columnName.toUpperCase()).dados.find(({ nome }) => nome === row[columnName.toLowerCase()]).cor : '#333',
                                    color: '#fff'
                                  }}
                                >
                                  {row[columnName.toLowerCase()].toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)}
                                </Label>
                              }
                              {
                                !legend.find(({ campo }) => campo === columnName) &&
                                <TableDataText>
                                  <DatatableField
                                    fieldName={columnName}
                                    fieldValue={row[columnName.toLowerCase()]}
                                  />
                                </TableDataText>
                              }
                            </Table.Cell>
                          );
                        })
                      }
                      {
                        hasActions ? (
                          <Table.Cell className='dataTableFixed' width={1} key="actions">
                            <Button.Group size='small'>
                              {
                                selectedMenu.nome !== 'Fechamento' ? (
                                  <Popup
                                    key={'Excluir'}
                                    trigger={
                                      <ButtonTable 
                                        color={ColorsMenu['Excluir']} 
                                        onClick={() => {
                                          onDatatableActionClick(FunctionsMenu['Excluir'], row.indice)
                                        }}
                                      >
                                      { IconsMenu['Excluir']}
                                      </ButtonTable>
                                    }
                                    content={<span style={{ fontSize: '1.3rem' }}>Excluir</span>}
                                    inverted
                                  />
                                ) : null
                              }
                              {
                                selectedMenu.nome !== 'Fechamento' ? (
                                  <Popup
                                    key={'Upload'}
                                    trigger={
                                      <ButtonTable 
                                        color={ColorsMenu['Upload']} 
                                        onClick={() => {
                                          onDatatableActionClick(FunctionsMenu['Upload'], row.indice)
                                        }}
                                      >
                                      { IconsMenu['Upload']}
                                      </ButtonTable>
                                    }
                                    content={<span style={{ fontSize: '1.3rem' }}>Upload</span>}
                                    inverted
                                  />
                                ) : null
                              }

                                <Popup
                                  key={'Visualizar'}
                                  trigger={
                                    <ButtonTable 
                                      color={ColorsMenu['Visualizar']} 
                                      onClick={() => {
                                        onDatatableActionClick(FunctionsMenu['Visualizar'], row.indice)
                                      }}
                                    >
                                    { IconsMenu['Visualizar']}
                                    </ButtonTable>
                                  }
                                  content={<span style={{ fontSize: '1.3rem' }}>Visualizar</span>}
                                  inverted
                                />
                            </Button.Group>
                          </Table.Cell>
                        ) : null
                      }
                    </Table.Row>
                  );
                })
              }
            </Table.Body>

            {/* <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={order.length + 1}>

                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer> */}
          </Table>
        }

      </TableContainer>
      <Menu borderless style={{backgroundColor: '#F9FAFB'}}>
        <Menu.Item>
          {
            legend.map(({ dados, campo }) => {
              return (
                <Fieldset key={campo}>
                  <LegendStatus>
                    {title[campo]}
                  </LegendStatus>
                  <LegendContainer>
                    {dados.map(({ nome, cor }) => {
                      return (
                        <Legend
                          key={nome}
                          isActive={statusFilter ? statusFilter[campo] === nome : false}
                          color={cor}
                          onClick={() => {
                            if (statusFilter && statusFilter[campo] === nome) {
                              const status = {...statusFilter};
                              delete status[campo]
                              onStatusFilter(status)
                            } else {
                              onStatusFilter({ ...statusFilter, [campo]: nome })
                            }
                          }}
                        >
                          <LegendInitialName isActive={statusFilter ? statusFilter[campo] === nome : false} color={cor}>{nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)}</LegendInitialName>
                          <LegendName>{nome}</LegendName>
                        </Legend>
                      );
                    })}
                  </LegendContainer>
                </Fieldset>
              )
            })
          }
        </Menu.Item>

        <Menu.Item position="right">
          <Menu floated='right'>
            <Pagination
              // defaultActivePage={currentPage}
              activePage={currentPage}
              firstItem={null}
              lastItem={null}
              pointing
              secondary
              totalPages={totalPages}
              onPageChange={(ev, { activePage }) => onPageChange(activePage as number)}
            />
          </Menu>
        </Menu.Item>
      </Menu>

    </Container>
  );
}

export default DatatableOrderService;