import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  font-size: 2rem;
  display: inline-block;
  text-align: left;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 1rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Col = styled.div`
  width: 100%;
`;

export const Textarea = styled.textarea`
  resize: vertical;
  width: 100%;
  height: 80px;
  border: 0.1rem solid #c1c1c1;
  padding: 1rem 0.8rem;
  outline: 0;
`;

