import React, { FC, useMemo } from 'react';
import Chart from "react-apexcharts";
import { LogData } from "../../../../reducers/Logs";

interface IOrderBudgetChartProps {
  data: LogData[];
}

const OrderBudgetChart: FC<IOrderBudgetChartProps> = ({ data }) => {
  const orders = useMemo(() => data.filter(({ values }) => values.type === 'Pedido').length, [data])
  const budgets = useMemo(() => data.filter(({ values }) => values.type === 'Orçamento').length, [data])

  return (
    <Chart
      type="bar"
      width="100%"
      height={180}
      series={[{
        name: 'Pedidos',
        data: [orders],
        color: 'rgba(70, 130, 180, 0.8)'
      }, {
        name: 'Orçamentos',
        data: [budgets],
        color: 'rgba(46, 139, 87, 0.8)'  
      }] as ApexAxisChartSeries}
      options={{
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        xaxis: {
          categories: ['Tipo'],
        }
      }}
    />
  );
}

export default OrderBudgetChart;