import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';



interface IDotsChartProps {
  data: [string | number | object];
  keys: string[];
  isFullscreen?: boolean;
}

const DotsChart: FC<IDotsChartProps> = ({ data, keys, isFullscreen = false }) => {
  const utils = new Utils();

  return (
    <Chart
      type="scatter"
      width="100%"
      height={isFullscreen ? '500' : '250'}
      series={[
        {
          name: keys.join('/'),
          data: data
        }
      ] as ApexAxisChartSeries}
      options={{
        chart: {
          width: '100%',
          height: isFullscreen ? '500' : '250',
          type: 'scatter',
          zoom: {
            enabled: true,
            type: 'xy'
          },
          defaultLocale: 'br',
          locales: utils.localeConfig
        },
        xaxis: {
          tickAmount: 10,
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(1)
            }
          }
        },
        yaxis: {
          tickAmount: 7
        }
      }}
    />
  );
}

export default DotsChart;