import styled from 'styled-components';
import { styleSheet } from '../../assets/styles/global';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const Logotipo = styled.img`
  height: 5rem;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0;
  width: 100%;
  gap: 2rem;
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
  font-size: 1.6rem;
  
  span {
    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
  }
`;

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
  font-size: 1.4rem !important;
  margin-bottom: 2rem;  
  font-weight: 600 !important;
  color: ${({ theme }) => theme.darkerGrey} !important; //${ styleSheet.darkerGrey }!important;
`;

export const InfoCodeContainer = styled.span`
  width: 100%;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
`;

export const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const InfoButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const ResendCodeText = styled.p`
  margin-top: 1rem;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.darkerGrey};
`;

export const ResendCodeLink = styled.span`
  text-decoration: none;
  color: ${({ theme }) => theme.mainColor};
  transform: 0.2s color ease-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.contrastColor};
  }
`;

export const ValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
`;

interface CircleProps {
  active: boolean;
}

export const Circle = styled.div<CircleProps>`
  height: 1rem;
	width: 1rem;
	border-radius: 30rem;
	background-color: ${({ active }) => active ? 'green' : '#B5B5B5'};
	margin-right: 0.3rem;
`;

export const ValidationLabel = styled.div`
  display: flex;
  algin-items: center;
  gap: 1rem;
`;