import React, { FC, useMemo } from 'react';
import Chart from "react-apexcharts";

import { LogData } from "../../../../reducers/Logs";

interface ILoginsChartProps {
  data: LogData[];
}

const LoginsChart: FC<ILoginsChartProps> = ({ data }) => {
  const success = useMemo(() => data.filter(({ values }) => values.status).length, [data])
  const failure = useMemo(() => data.filter(({ values }) => !values.status).length, [data])

  return (
    <Chart
      type="bar"
      width="100%"
      height="100%"
      series={[{
        name: 'Login com Sucesso',
        data: [success],
        color: 'rgba(67, 205, 128, 0.8)'
      }, {
        name: 'Login com Erro',
        data: [failure],
        color:'rgba(238, 59, 59, 0.8)'   
      }] as ApexAxisChartSeries}
      options={{
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center'
        },
        xaxis: {
          categories: ['Logins'],
        }
      }}
    />
  );
}

export default LoginsChart;