import axios, { AxiosInstance } from "axios";
import { Utils } from "../../common/Utils";

const utils = new Utils();

class LoggerProvider {
  public axiosInstance: AxiosInstance;

  public url = {
    hml: 'https://apiqalogger.ethosx.com.br',
    prod: 'https://apilogger.ethosx.com.br',
    dev: 'http://localhost:5443'
  }

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: utils.getEnvironment() === 'Produção' ? this.url.prod : this.url.hml,
      headers: {
        'Accept-Language': 'pt-BR'
      }
    });
  }
}

export default new LoggerProvider().axiosInstance;

