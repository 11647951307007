import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CalendarX, Receipt } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import Swal from 'sweetalert2';

import { ClosureService } from "../../services/methods/ClosureService";

import { UIContext } from '../../contexts/UIContext';

import ViewFinancialModal from '../../components/ViewFinancialModal';
import FilterModal, { IFilter } from '../../components/FilterModal';
import DefaultHeader from '../../components/DefaultHeader';
import { IErrorProps } from '../../components/Error';
import EmailModal from '../../components/EmailModal';
import Datatable from '../../components/Datatable';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IPaginatedServiceResponse } from '../../interfaces/Datatable/IPaginatedServiceResponse';
import { IOpcoes } from '../../interfaces/Menu/IMenu';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';

import { BrowserContent, Content, PageDefault } from './styles';
import { DataTableReducerInitialState, loadTableData, updateDataTableIndice, updateMode, updateTableCurrentPage, updateTableFilter, updateTableLoading, updateTableOrderBy, updateTableSearchText, updateTableSearching } from '../../reducers/DataTable';
import { PolicieReducerInitialState } from "../../reducers/Policie";
import ViewClosureModal from "../../components/ViewClosureModal";
import moment from "moment";

const Closure: FC = () => {
  const closureService: ClosureService = new ClosureService();

  const { tableData, tableCurrentPage, tableFieldTypeConfig, tableFieldFilters, tableFilter, indice, mode, tableOrderBy } = useSelector((state: { dataTable: DataTableReducerInitialState }) => state.dataTable);
  const { currentBranch, itensPerPage, selectedClient, currentCompany, selectedRow } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { token, pwm: configuracoes, customerLevel } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const dispatch = useDispatch();

  const { setUIComponentState } = useContext(UIContext);

  const [period, setPeriod] = useState(moment().format('YYYY-MM'))
  const [formFieldState, setFormFieldState] = useState(false);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [filtersValue, setFiltersValue] = useState<IFilter[]>([]);
  const [printOptions, setPrintOption] = useState<IOpcoes[]>([]);
  const [filterModal, setFilterModal] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [action, setAction] = useState("");
  const [filterClosureVisualization, setFilterClosureVisualization] = useState<string>('')

  const utils = new Utils();

  async function getClosureList(page: number, order: string, period: string, filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    const [ year, month ] = period.split('-')
    try {
      dispatch(updateTableCurrentPage({ value: page }));
      dispatch(updateTableLoading({ value: true }));
      const selectedClientIndice = selectedClient.indice ? String(selectedClient.indice) : ''
      const response = await closureService.index(year, month, currentCompany, currentBranch.codigo, page, itensPerPage, token, selectedClientIndice, order, filter, statusFilter)
      // const response = await financialService.index(99, '01', page, 9, token, '00000201');
      const { titulo, ordem, dados, legenda, totalPages, fieldTypeConfig, filtros, totalizadores, oculto, nivel } = response.data as IPaginatedServiceResponse;
  
      if (response.status === 200) {
        utils.formatTableData(fieldTypeConfig, dados)
        const hiddenFields = configuracoes.usecustomerlevel && nivel ? utils.customHiddenFields(customerLevel, nivel, oculto) : oculto

        dispatch(loadTableData({
          tableData: dados,
          tableOrder: ordem.filter(campo => !hiddenFields.find(oculto => oculto === campo)),
          tableTitles: titulo,
          tableLegend: legenda,
          tableFieldFilters: filtros,
          tableTotalPages: totalPages,
          tableFieldTypeConfig: fieldTypeConfig,
          tableTotalizadores: totalizadores
        }))
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateTableLoading({ value: false }))
    }
  }

  const getClosureWithTextSearch = useCallback(debounce(async (searchValue: string) => {

    if (!!searchValue) {
      const searchValueText = configuracoes.usesearchsensitive ? searchValue : searchValue.toUpperCase();
      const searchString = utils.formatSearchStringToPaginationService(tableFieldTypeConfig.character, tableFieldFilters.character, "contains", searchValueText);

      dispatch(updateTableFilter({ value: { ...tableFilter, filter: searchString } }));
      dispatch(updateTableSearchText({ value: searchValue }));

      await getClosureList(1, tableOrderBy, period, searchString, tableFilter.statusFilter);
    }
    else {
      dispatch(updateTableFilter({ value: { ...tableFilter, filter: undefined } }));
      dispatch(updateTableSearchText({ value: '' }));
      await getClosureList(1, tableOrderBy, period, undefined, tableFilter.statusFilter);
    }

    dispatch(updateTableSearching({ value: false }))
  }, Number(configuracoes.debounceinput) || 2000), [tableOrderBy, tableFieldTypeConfig, tableFilter]);

  async function filterClosure(filter?: string | undefined, statusFilter?: { [key: string]: string } | undefined) {
    dispatch(updateTableFilter({ value: { filter, statusFilter } }));
    await getClosureList(1, tableOrderBy, period, filter, statusFilter);
  }

  async function orderClosure(order: string) {
    dispatch(updateTableOrderBy({ value: order }))
    setModalSendEmail(false);
    await getClosureList(1, order, period, tableFilter.filter, tableFilter.statusFilter);
  }

  async function onViewUserClick() {
    setFormFieldState(true);
  }


  async function handleSendThroughEmail(email: string, type?: string) {
    const id = toast.loading("Por favor aguarde...")
    setModalSendEmail(false);
    setIsSendEmail(true);
    const [ year, month ] = period.split('-')
    
    try {
      const { data, status } = await closureService.sendEmail(year, month,  currentCompany, currentBranch.codigo, token, selectedRow.indice, email)

      if (status === 200 || status === 201) {
        toast.update(id, {type: "success", isLoading: false, autoClose: 100, closeOnClick: true });
        await getClosureList(tableCurrentPage, tableOrderBy, period, undefined, tableFilter.statusFilter);
      } else {
        toast.update(id, {render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }
    } catch (error) {
      toast.update(id, {render: error || "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsSendEmail(false)
    }
  }

  
  async function onClosureClick(indice: string | number, action: "approval" | "invoice") {
    const id = toast.loading("Por favor aguarde...")
    const [ year, month ] = period.split('-')
    
    try {
      const { data, status } = await closureService.closure(year, month, currentCompany, currentBranch.codigo, token, indice, action)

      if (status === 200 || status === 201) {
        Swal.fire({
          title: 'Sucesso',
          text: data.msg,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        
        toast.update(id, {render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
        await getClosureList(tableCurrentPage, tableOrderBy, period, tableFilter.filter, tableFilter.statusFilter)
      } else {
        if(data.errorCode === 499) {
          setFilterClosureVisualization("contains(ZX_NUM, '')")
          setFormFieldState(true);
        }
        toast.update(id, { render: data.errorMessage || data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
        Swal.fire({
          title: 'OPS...',
          text: data.errorMessage || data.msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    } catch (error) {
      toast.update(id, {render: "O servidor retornou um erro interno!", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      return Swal.fire({
        title: 'Ops!',
        text: "O servidor retornou um erro interno!",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }

  useEffect(() => {
    getClosureList(tableCurrentPage, tableOrderBy, period, undefined, tableFilter.statusFilter)
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<CalendarX size={24} color={styleSheet.mainColor} />}>
          <BrowserContent>
            <Datatable
              serviceName="wsfechamento"
              onItensPerPageChange={() => getClosureList(1, tableOrderBy, period, tableFilter.filter, tableFilter.statusFilter)}
              onSearch={(value) => getClosureWithTextSearch(value)}
              onPageChange={(page) => getClosureList(page, tableOrderBy, period, tableFilter.filter, tableFilter.statusFilter)}
              onOrderBy={(value) => orderClosure(value)}
              onStatusFilter={(status: { [key: string]: string } | undefined) => filterClosure(tableFilter.filter, status)}
              onDatatableActionClick={(action, indice) => {
                dispatch(updateDataTableIndice({ value: indice }));
                if (action !== 'upload') dispatch(updateMode({ value: action }));
                if (action === 'filter') setFilterModal(true);
                if (action === 'view') onViewUserClick();
                if (action === 'email') setModalSendEmail(true);
                if (action === 'approval' || action === 'invoice') onClosureClick(indice, action) 
              }}
              onChangePeriod={(date) => { 
                setPeriod(date)
                getClosureList(1, tableOrderBy, date, tableFilter.filter, tableFilter.statusFilter)
              }}
            />
        </BrowserContent>
        </Browser>

      </Content>


      {
        formFieldState &&
        <ViewClosureModal
          indice={indice}
          onClose={() => { 
            setFormFieldState(false) 
            setFilterClosureVisualization('')
          } }
          visible={formFieldState}
          filter={filterClosureVisualization}
          mode={mode}
        />
      }


      <Footer />


      <FilterModal
        visible={filterModal}
        filtersValue={filtersValue}
        setFiltersValues={setFiltersValue}
        handleFilter={filterClosure}
        onClose={() => {
          setFilterModal(false)
        }}
      />
    

    {
      modalSendEmail &&
      <EmailModal
        visible={modalSendEmail}
        onClose={() => setModalSendEmail(false)}
        handleSendEmail={ (email, type) => handleSendThroughEmail(email, type) }
        isSendEmail={isSendEmail}
        options={ printOptions }
      />
    }
    </PageDefault>

  );

}

export default Closure;
