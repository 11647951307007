import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../../common/Utils';

interface IClientsChartProps {
  keys: Array<string | number>
  data: number[]
}

const ClientsChart: FC<IClientsChartProps> = ({ keys, data }) => {
  const utils = new Utils();
  const colors = keys.map(() => utils.getRandomColor());

  return (
    <Chart
      type="bar"
      width="100%"
      height="100%"
      series={[{
        name: 'Clientes Mais Ativos (visível apenas para EthosX)',
        data: data,
      }] as ApexAxisChartSeries}
      options={{
        legend: {
          position: 'top',
          offsetX: 30
        },
        title: {
          text: 'Clientes Mais Ativos (visível apenas para EthosX)',
          style: {
            fontSize: '12px'
          }
        },
        colors: colors,
        xaxis: {
          labels: {
            rotate: -25,
          },
          categories: keys,
        },
      }}
    />
  );
}

export default ClientsChart;