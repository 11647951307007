import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import { toast } from 'react-toastify';
import { OsService } from '../../services/methods/OsService';
import PdfOs from "../../components/PDFOS";
import { ISchedule } from "../../interfaces/ServiceOrder/ISchedule";

const OSPDFView: React.FC = () => {
  const osService: OsService = new OsService();

  const [data, setData] = useState<ISchedule>({} as ISchedule)
  const [signature, setSignature] = useState('')
  const [isLoading, setIsLoading] = useState(true)


  async function getOS() {
    try {
      const searchParams = new URLSearchParams(window.location.search)
      const scheduleKey = searchParams.get('scheduleKey')
      const analista =  searchParams.get('analista')
      const token = searchParams.get('token')

      const { data } = await osService.getOs(Number(scheduleKey), analista, token)
      
      if (data.status === 'ok') {
        const os = data.analista.agendas[0]
        setData(os)
        setSignature(data.analista.assinatura)
      }
    } catch (error) {
      console.log(error)
      toast.error('Ops... Algo deu errado tente novamente.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOS()
  }, [])

  return (
    <>
      {
        isLoading ? <></> : (
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <PdfOs data={data} name={data.analista} signature={signature} />
          </PDFViewer >
        )
      }
    </>

  )
};

export { OSPDFView }