import { Modal, Transition, Button, Table, Tab, Loader, Popup } from 'semantic-ui-react';
import React, { FC, useEffect, useState } from 'react';
import { Eye, Trash } from 'phosphor-react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Id, toast } from 'react-toastify';
import { encode } from 'base-64';
import Swal from 'sweetalert2';
import { Utils } from '../../common/Utils';

import { AttachService } from '../../services/methods/AttachService';
import { IFile } from '../../interfaces/Attach/Attach';

import SubMenuOptions from '../SubMenuOptions';
import DropZone from '../DropZone';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { ButtonTable, Content } from './styles';
import { PageTypeReg, UploadDescription, UploadTable } from "../../common/Enums";

interface IAttachModal {
  indice: string | number;
  open: boolean;
  onClose(): void;
  reload?: () => void;
}

const AttachModal: FC<IAttachModal> = ({ indice, open, onClose, reload }) => {
  const utils = new Utils();
  const attachService: AttachService = new AttachService();

  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { selectedMenu, selectedSubMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
  const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);


  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletedFile, setDeletedFile] = useState(999999999);

  const [files, setFiles] = useState<string[]>([])
  const [tabSelected, setTabSelected] = useState<'Lista' | 'Upload'>('Lista');
  const [fileList, setFileList] = useState<IFile[]>([]);

  async function loadFiles() {
    try {
      setIsLoading(true)
      const { data, status } = await attachService.index(currentBranch.codigo, token, String(indice), String(indice), PageTypeReg[selectedMenu.nome], currentCompany)
      if (status === 200) {
        setFiles(data.files)
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setIsLoading(false)
    }
  }

  async function handleUploadFile() {
    const id = toast.loading("Por favor aguarde...")
    try {
      setIsLoading(true)

      const files = fileList.map(file => {
        return {
          nome: file.name,
          data: file.data
        }
      })

      const objectParams = {
        descricao: UploadDescription[selectedMenu.nome],
        files: files,
        registro: String(indice),
        indice: String(indice),
        size: '',
        tabela: UploadTable[selectedMenu.nome],
        type: ''
      }
      const { data, status } = await attachService.upload(currentBranch.codigo, currentCompany, objectParams)

      if ((status === 201 || status === 200) && data.status !== 'erro') {
        setFiles(state => [...state, ...data.fileName.map(fileName => fileName)])
        // onClose()
        setFileList([])
        setTabSelected('Lista')
        reload()
        return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      } else {
        return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
      }

    } catch (error) {
      console.log(error)
      return toast.update(id, { isLoading: false, autoClose: 10 })
    } finally {
      setIsLoading(false)
    }

  }

  async function openFile(url: string) {
    const newTab = window.open();
    newTab.document.write('<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    newTab?.document.close();
  }

  async function handleOpenFile(fileName: string) {
    try {
      const indiceEncode = encode(String(indice))
      const fileNameEncode = encode(fileName)
      const { data, status } = await attachService.open(UploadTable[selectedMenu.nome], UploadDescription[selectedMenu.nome], indiceEncode, fileNameEncode)

      if (status === 200 && data.status === "ok") {
        let url = data.data;
        if (data.status === "ok") {
          if (data.data.indexOf("application/PNG") > -1 || data.data.indexOf("application/JPG") > -1 || data.data.indexOf("application/JPEG") > -1) {
            url = data.data.replace("application", "image")
            openFile(url);
          }

          else if (data.data.indexOf("application/PDF") > -1) {
            const blob = utils.base64toBlob(data.data)
            url = URL.createObjectURL(blob);
            openFile(url);
          }

          else {
            const blob = utils.base64toBlob(data.data)
            url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${ fileName }`);
            link.click();
          }

        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.msg,
        })
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function handleDeleteFile(fileName: string) {
    let id: Id
    try {
      return Swal.fire({
        title: 'Confirmar ação?',
        text: 'Tem certeza que deseja excluir esse arquivo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, confirmar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setDeleteLoading(true)
          const filesArr = files.filter(file => file !== fileName)
          const objectParams = {
            descricao: UploadDescription[selectedMenu.nome],
            files: [{ nome: fileName, data: '' }],
            registro: String(indice),
            indice: String(indice),
            size: '',
            tabela: UploadTable[selectedMenu.nome],
            type: ''
          }
          id = toast.loading("Por favor aguarde...")
          const { data, status } = await attachService.delete(currentBranch.codigo, currentCompany, objectParams)

          if (data.status === 'ok') {
            setFiles(filesArr)
            setDeleteLoading(false)
            return toast.update(id, { render: data.msg, type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });

          } else {
            setDeleteLoading(false)
            return toast.update(id, { render: data.msg || data.errorMessage, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true });
          }
        }
      });
    } catch (error) {
      console.log('error', error)
      toast.update(id, { render: 'Algo deu errado!', type: "success", isLoading: false, autoClose: 5000, closeOnClick: true });
      setDeleteLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  useEffect(() => {
    setFileList([])
    loadFiles()
  }, [indice])

  return (
    <Transition animation='fly up'>
      <Modal
        closeIcon
        size='small'
        open={ open }
        onClose={ () => onClose() }
        closeOnDimmerClick={false}
        // style={ { height: '60rem' } }
      >
        <Modal.Header>
          Anexar Documento
        </Modal.Header>
        <Modal.Content scrolling>
          <DropZone
            fileList={ fileList }
            setFileList={ setFileList }
          />

          {
            isLoading ?
              <Skeleton baseColor='#DDD' height={ 50 } width="100%" count={ 11 } />
              :
              <Table celled>
                <Table.Header>
                  <Table.Row >
                    <Table.HeaderCell textAlign='center'> Tipo </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Nome </Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'> Ações </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    files.map((file, index) => (
                      <Table.Row key={ file } textAlign='center'>
                        <Table.Cell width='2'>{ file.split('.').pop() }</Table.Cell>
                        <Table.Cell>{ file }</Table.Cell>
                        <Table.Cell style={ { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' } } width='2'>
                          { (deleteLoading && deletedFile === index)

                            ? <Loader className='loadRow' inverted active inline />
                            :
                            <>
                              <SubMenuOptions
                                subMenuSelected={ selectedSubMenu }
                                type="icon"
                                onOptionClick={ (action) => {
                                  if (action === 'delete' && !deleteLoading) {
                                    handleDeleteFile(file);
                                    setDeletedFile(index)
                                  }
                                } }
                              />
                              <Button.Group size='small'>
                                {
                                  selectedMenu.nome === 'OrdemServico' && selectedSubMenu.nome === 'Visualizar' && (
                                    <Popup
                                      trigger={
                                        <ButtonTable
                                          color="#E47261"
                                          onClick={ () => {
                                            handleDeleteFile(file);
                                            setDeletedFile(index)
                                          } }
                                        >
                                          <Trash size={ 20 } color="#E47261" />
                                        </ButtonTable>
                                      }
                                      content={ <span style={ { fontSize: '1.3rem' } }>Excluir</span> }
                                      inverted
                                    />
                                  )
                                }
                                <Popup
                                  trigger={
                                    <ButtonTable
                                      color="#6FCCF1"
                                      onClick={ () => {
                                        handleOpenFile(file)
                                      } }
                                    >
                                      <Eye size={ 20 } color="#6FCCF1" />
                                    </ButtonTable>
                                  }
                                  content={ <span style={ { fontSize: '1.3rem' } }>Visualizar</span> }
                                  inverted
                                />
                              </Button.Group>
                            </>
                          }
                        </Table.Cell>
                      </Table.Row>
                    ))
                  }
                </Table.Body>
              </Table>
          }
          {/* <Tab
            menu={ { secondary: true, pointing: true } }
            panes={ panes }
            activeIndex={ tabSelected === 'Lista' ? 0 : 1 }
            onTabChange={ (event, data) => {
              console.log(data, data.panes[data.activeIndex].menuItem)
              setTabSelected(data.panes[data.activeIndex].menuItem)
            } }
          /> */}
        </Modal.Content>
        
        <Modal.Actions>
          <Button.Group>
            <Button
              onClick={ () => onClose() }
            >
              Cancelar
            </Button>
            <Button.Or text="ou" />
            <Button
              loading={ isLoading }
              disabled={ isLoading || fileList.length <= 0 }
              positive
              onClick={ () => handleUploadFile() }
            >
              Salvar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </Transition>
  );
}

export default AttachModal;