import React, { createContext, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { HomeService } from '../services/methods/HomeService';

import { IMenuContext } from '../interfaces/Menu/IMenuContext';
import { IMenuItem } from '../interfaces/Menu/IMenu';

import { loadMenus, updateMenuLoading } from '../reducers/Menu';
import { AuthReducerInitialState } from '../reducers/Auth';

enum Menus {
  home = 'home',
  empresas = 'clients',
  pendencias = 'approvals',
  usuarios = 'users',
  pedidos = 'orders',
  orcamentos = 'budgets',
  financeiro = 'financial',
  dashboard = 'dashboard',
  vendedor = 'salesperson',
  dashboard2 = 'dashboard',
  agendas = 'schedules',
  logs = 'logs',
  projetos = 'projects',
}


export const MenuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuContextProvider: FC = ({ children }) => {
  const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const dispatch = useDispatch()

  const homeService: HomeService = new HomeService();

  async function getMenuList(token: string, selectedMenu: IMenuItem): Promise<string> {
    try {
      dispatch(updateMenuLoading({ value: true }));
      const { status, data } = await homeService.getMenu(token);
      const { menus }: { menus: IMenuItem[] } = data;

      if (status === 200) {
        dispatch(loadMenus({ menuList: menus, menuListArr: menus, selectedMenu: selectedMenu }))
        localStorage.setItem('menu_list_storage', JSON.stringify(menus))
        return Menus[menus[0].nome.toLowerCase()]
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      dispatch(updateMenuLoading({ value: false }));
    }
  }

  function getMenuRoute(pageName: string): string {
    switch (pageName.toLowerCase()) {
      case 'home': return 'home';
      case 'empresas': return 'clients';
      case 'pendencias': return 'approvals'
      case 'usuarios': return 'users';
      case 'pedidos': return 'orders';
      case 'orcamentos': return 'budgets';
      case 'financeiro': return 'financial';
      case 'dashboard': return 'dashboard';
      case 'vendedor': return 'salesperson';
      case 'dashboard2': return 'dashboard';
      case 'dashboard': return 'dashboard';
      case 'agendas': return 'schedules';
      case 'ordemservico': return 'orderService';
      case 'pendencias': return '';
      case 'assistencia': return '';
      case 'wspedrap': return 'wspedrap';
      case 'importtocart': return 'importtocart';
      case 'catalogo': return 'cart';
      case 'consulta': return 'consulta';
      case 'logs': return 'logs';
      case 'projetos': return 'projects';
      case 'fechamento': return 'closure';

      default: return 'home';
    }
  }

  // USADO APENAS PELA SIGVARIS, ALGUMAS ROTAS SÃO ACESSADAS POR UM TEMPLATE HTML NA HOME
  function getMenuRouteTemplate(pageName: string): string {
    switch (pageName.toLowerCase()) {    
      case 'orders': return 'pedidos';    
      case 'financial': return 'financeiro'; 
      case 'dashboard': return 'dashboard'; 

      default: return 'home';
    }
  }

  return (
    <MenuContext.Provider
      value={ {
        getMenuList,
        getMenuRoute,
        getMenuRouteTemplate
      } }
    >
      { children }
    </MenuContext.Provider>
  );
}

export default MenuContextProvider;