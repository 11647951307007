import { Header, Modal, Button, Table, Dimmer, Loader, Step, Segment, Card } from 'semantic-ui-react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { encode } from 'base-64';
import Swal from 'sweetalert2';

import { OrderService } from '../../services/methods/OrderService';
import { FieldService } from '../../services/methods/FieldService';

import { UIContext } from '../../contexts/UIContext';

import { IRestFieldRequestData } from '../../interfaces/Fields/IRestFieldRequestData';
import { IRestField } from '../../interfaces/Fields/IRestField';
import { FieldValues } from '../../@types/Restfield';
import { FormMode } from '../../@types/generic';
import { IErrorProps } from '../Error';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';

import { Utils } from '../../common/Utils';

import { styleSheet } from '../../assets/styles/global';
import { Col, Container, Textarea } from './styles';
import { BudgetService } from '../../services/methods/BudgetService';

export interface IApprovalModalProps {
  indice: string | number;
  visible: boolean;
  mode: FormMode;
  onClose(): void;
}

const ApprovalModal: React.FC<IApprovalModalProps> = ({ indice, mode, visible, onClose }) => {
  const fieldService: FieldService = new FieldService();
  const budgetService: BudgetService = new BudgetService();

  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  
  const utils = new Utils();
  
  const { setUIComponentState } = useContext(UIContext);
  
  const [step, setStep] = useState<1 | 2>(1)
  const [fields, setFields] = useState<IRestField[]>([]);
  const [tableFields, setTableFields] = useState<IRestField[][]>([]);
  const [fieldValues, setFieldValues] = useState<FieldValues>({});
  const [tableFieldsTitle, setTableFieldsTitle] = useState<string[]>([])
  const [itensActive, setItensActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  const useThreeDecimalPlaces = companyPolicies.find(policy => policy.property === 'useThreeDecimalPlaces' && policy.policy_value === 'true');

  async function getOrderFields() {
    try {
      setFields([])
      const body: IRestFieldRequestData = {
        alias: 'SCJ',
        alias2: 'SCK',
        url: '',
        tab: '1',
        usuario: '',
        indice: '',
        ordem: 1,
        token: token,
      }

      const encodedBody = encode(JSON.stringify(body));
      const { data, status, request } = await fieldService.getFields(currentBranch.codigo, currentCompany, encodedBody);

      if (status === 200) {
        await setFields(data.campos);
        setIsLoading(false)
        // await getOrderItensList(data.campos, data.campos1.item01)
      }
      else {
        console.log('error')
        setUIComponentState('error', {
          visible: true,
          title: 'Ops...',
          message: 'Houve um erro ao buscar a lista de clientes.',
          statusHttp: status,
          urlHttp: request,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: data.msg,
              statusHttp: status,
              urlHttp: request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  async function getOrderItensList(fields: IRestField[], fieldsTable: IRestField[]) {
    try {
      const response = await budgetService.getData('', currentBranch.codigo, token, indice) //indice)
      const { dados } = response.data;
      if (response.status === 200) {
        const fieldsWithValues = fields.map(field => {
          const value = dados[0][field.nome.toLowerCase()]
          field.conteudo = value
          field.visual = "V"
          return field
        })
        const tableFieldsWithValue: IRestField[][] = []
        dados[0].itens.map(item => {
          const fields: IRestField[] = fieldsTable.map(field => {
            return {
              ...field,
              conteudo: item[field.nome.toLowerCase()]
            }
          })
          tableFieldsWithValue.push(fields)
        })
        const tableFieldsWithTitle = fieldsTable.map(item => item.titulo)
        setTableFieldsTitle(tableFieldsWithTitle)
        setTableFields(tableFieldsWithValue)
        setFields(fieldsWithValues)
        setIsLoading(false)
      }
      else {
        setUIComponentState('error', {
          visible: true,
          title: 'Ops... Houve um erro inesperado!',
          message: response.data.errorMessage,
          statusHttp: response.status,
          urlHttp: response.request.responseURL,
          onClose: () => {
            setUIComponentState('error', {
              visible: false,
              title: 'Ops...',
              message: response.data.msg,
              statusHttp: response.status,
              urlHttp: response.request.responseURL,
              onClose: () => { console.log() }
            })
          }
        } as IErrorProps);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    }
  }

  function handleClose() {
    setStep(1)
    onClose()
  }

  useEffect(() => {
    setIsLoading(true)
    getOrderFields()
  }, [indice])

  return (
    <Modal
      onClose={() => !isLoading && handleClose()}
      open={visible}
      size='fullscreen'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Dimmer active={isLoading} inverted>
        <Loader size="huge" inverted>Carregando</Loader>
      </Dimmer>

      <Header>
        <Step.Group ordered>
          <Step completed={step === 2} active={step === 1}>
            <Step.Content>
              <Step.Title>LIBERAÇÃO DE PEDIDO</Step.Title>
            </Step.Content>
          </Step>

          <Step active={step === 2} >
            <Step.Content>
              <Step.Title>CONFIRMAÇÃO</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      </Header>

      {
        step === 1 ?
          <Modal.Content scrolling>
            <Container>
              <Card.Group style={{ width: '100%', marginBottom: '1rem', display: 'flex', textAlign: 'center' }}>
                <Card style={{ width: '24%' }}>
                  <Card.Content>
                    <Card.Header>0%</Card.Header>
                    <br />
                    <Card.Header>Desconto NF</Card.Header>
                  </Card.Content>
                </Card>

                <Card style={{ width: '24%' }}>
                  <Card.Content>
                    <Card.Header>8.01%</Card.Header>
                    <br />
                    <Card.Header>Bonificação</Card.Header>
                  </Card.Content>
                </Card>


                <Card style={{ width: '24%' }}>
                  <Card.Content>
                    <Card.Header>90</Card.Header>
                    <br />
                    <Card.Header>Prazo Cadastro (dias)</Card.Header>
                  </Card.Content>
                </Card>

                <Card style={{ width: '24%' }}>
                  <Card.Content>
                    <Card.Header>90</Card.Header>
                    <br />
                    <Card.Header>Prazo Negociado (dias)</Card.Header>
                  </Card.Content>
                </Card>

              </Card.Group>
              <strong style={{ alignSelf: 'flex-start' }}>Observações</strong>
              <Segment size="large" style={{ width: '100%', margin: '5px 0' }}>BONIFICAÇÃO DE 8% REFERENTE A PAGAMENTO DE AÇÕES PDV.</Segment>
            </Container>

            <Table size="large" celled>
              <Table.Header>
                <Table.Row>
                  {
                    tableFieldsTitle.map(title => {
                      if (tableFields[0] && tableFields[0].find(field => field.titulo === title) && tableFields[0].find(field => field.titulo === title).browse !== 'N') {
                        return (
                          <Table.HeaderCell>{title}</Table.HeaderCell>
                        )
                      }
                    })
                  }
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {
                  tableFields.map(fields => (
                    <Table.Row>
                      {
                        tableFieldsTitle.map(title => {
                          if (fields.find(field => field.titulo === title) && fields.find(field => field.titulo === title).browse !== 'N') {
                            return (
                              <Table.Cell>
                                {
                                  fields.find(field => field.titulo === title).type === 'currency'
                                    ? `R$ ${utils.convertBrl(fields.find(field => field.titulo === title).conteudo, useThreeDecimalPlaces ? 3 : 2)}`
                                    : fields.find(field => field.titulo === title).conteudo
                                }
                              </Table.Cell>
                            )
                          }

                        })
                      }
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
          </Modal.Content>
          :
          <Container>
            <Col><strong>Observação de aprovações:</strong> </Col>
            <Segment size="big" style={{ width: '100%' }}>Ok</Segment>

            <Col><strong>Observações de pedido</strong> </Col>
            <Segment size="big" style={{ width: '100%' }}>BONIFICAÇÃO DE 8% REFERENTE A PAGAMENTO DE AÇÕES PDV.</Segment>

            <Col style={{ marginBottom: '10px' }}><strong>Justificativa da Aprovação</strong> <span style={{ color: "#f00" }}>*</span></Col>
            <Textarea></Textarea>

          </Container>
      }



      <Modal.Actions>
        {
          step === 1 ?
            <Button.Group size="large">
              <Button negative onClick={handleClose}>Reprovar</Button>
              <Button.Or text="ou" />
              <Button onClick={() => setStep(2)} positive>Aprovar</Button>
            </Button.Group>
            :
            <Button.Group size="large">
              <Button onClick={handleClose}>Canelar</Button>
              <Button.Or text="ou" />
              <Button positive>Confirmar</Button>
            </Button.Group>
        }
      </Modal.Actions>
    </Modal>
  );
}

export default ApprovalModal;