import { styleSheet } from '../../../assets/styles/global';
import styled, { css } from "styled-components";

interface IButtonTable {
  color: string;
}

interface ILegendsProps {
  color: string;
  isActive: boolean;
}

interface ITotalizerCircleProps {
  color: string;
}

export const TableContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
`;

export const TableColumnText = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;

export const LabelInput = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2rem;
`;

export const Fieldset = styled.fieldset`
  border: 1px solid #d9dadc;
  border-radius: 8px;
  margin-right: 1rem;
`;

export const LegendStatus = styled.legend`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.darkerGrey}; //${styleSheet.darkerGrey};
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  margin-top: 0.5rem;
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0.4rem;
`;


export const TableDataText = styled.span`
  font-size: 1.1rem;
  white-space: nowrap;
`;

export const LegendDetail = styled.div`
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0,0,0,.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;
  color: #fff;
  font-size: 1.2rem;
`;

export const CircularLabelLegend = styled.div`
  display: inline-block;
  line-height: 1.9;
  vertical-align: middle;
  margin: 0 0.14285714em;
  background-color: #e8e8e8;
  background-image: none;
  height: 2rem;
  width: 2rem;
  text-align: center;
  color: rgba(0,0,0,.6);
  text-transform: none;
  font-weight: 700;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background .1s ease;
  transition: background .1s ease;
  color: #fff;
  font-size: 1.1rem;
  border-radius: 200rem;
`;

export const LegendResumeName = styled.span`
  line-height: 1.8;
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
`;

export const ButtonTable = styled.button<IButtonTable>`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1.75rem;
  border: 0;
  margin-right: .5rem;
  /* background: ${({ color }) => color}; */
  background: transparent;
  cursor: pointer;
`;

export const Totalizers = styled.div<ITotalizerCircleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: .7rem;
  border-radius: 4px 4px 4px 0;
  background: #F5F5F5;
  border-left: 2px solid ${({ color }) => color};
  background: ${({ theme }) => theme.lighterGrey_200};// ${styleSheet.lighterGrey_200};
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
`;

export const TotalizerCircle = styled.span<ITotalizerCircleProps>`
  position: absolute;
  top: 0px;
  left: -1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  border-radius: 5rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
`;

export const TotalizersTitle = styled.span`
  line-height: 1.8rem;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0 1rem;
  border-radius: 1.2rem;
  margin-bottom: 1rem;
`;

export const TotalizersText = styled.span`
  line-height: 1.8rem;
  vertical-align: middle;
  font-size: 1.2rem;
  font-weight: 400;
`;

export const Legend = styled.button<ILegendsProps>`
  border: 0;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.lighterGrey_200}; //${styleSheet.lighterGrey_200};
  border-radius: 5rem;
  ${({ isActive, color }) => isActive && css`
    border: 2px solid ${color};
  `}
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
  cursor: pointer;
  margin-left: 1rem;
  font-family: ${({ theme }) => theme.mainFont}; //${styleSheet.mainFont};
`;

export const LegendInitialName = styled.span<ILegendsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  border-radius: 5rem;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.shape}; //${styleSheet.shape};
`;

export const LegendName = styled.span`
  font-size: 1.2rem;
  padding: 0.5rem;
`;

