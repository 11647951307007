import {  Button, Dimmer, Dropdown, Label, Loader, Menu } from 'semantic-ui-react';
import FullCalendar, { EventContentArg, EventDropArg } from '@fullcalendar/react';
import { Trash, Calendar as CalendarIcon } from 'phosphor-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Dexie, {Table} from 'dexie';
import Swal from 'sweetalert2';
import tippy from 'tippy.js';
import moment from 'moment';

import 'tippy.js/dist/tippy.css';

import { SchedulesService } from '../../services/methods/SchedulesService';

import DefaultHeader from '../../components/DefaultHeader';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';
import Os from '../../components/Os';


import { Coordinators, IAnalyst, IClient, IProject, ISchedule } from '../../interfaces/Scheludes/ISchedules';

import { PolicieReducerInitialState } from '../../reducers/Policie';
import { MenuReducerInitialState } from '../../reducers/Menu';
import { AuthReducerInitialState } from '../../reducers/Auth';

import {KeyPressing} from '../../common/KeyPressing';

import { styleSheet } from '../../assets/styles/global';

import { Content, PageDefault, CalendarContainer, Calendar, Infos, DefaultLabel, BrowserContent, DeleteZone, ButtonTable } from './styles';

interface ISchedulesStorage {
  client: IClient;
  project: IProject;
  user: string;
  id?: number;
}

interface DB extends Dexie {
  schedules?: Table<ISchedulesStorage>;
}

const Schedules: FC = () => {
 
  const scheduleService: SchedulesService = new SchedulesService();

	const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token, user } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  
  const calendar = useRef<FullCalendar>(null);
  
  const startDayRangeSchedule = companyPolicies.find(police => police.property === 'startDayRangeSchedule') ? companyPolicies.find(police => police.property === 'startDayRangeSchedule').policy_value : 25;
  

  const db: DB = new Dexie('PYLI');
  db.version(1).stores({
    schedules: '++id, user, client, project', 
  });

  db.open().catch((err) => {
    console.log(err.stack || err)
  }) 
  const [calendarLoad, setCalendarLoad] = useState(false);
  const [analystLoad, setAnalystLoad] = useState(false);
  const [projectLoad, setProjectLoad] = useState(false);
  const [clientLoad, setClientLoad] = useState(false);
  const [recentSchedulesLoad, setRecentSchedulesLoad] = useState(false);
  const [ searchRecentSchedules, setSearchRecentSchedules ] = useState('');
  const [dropZone, setDropZone] = useState(false);

  const [analystSelected, setAnalystSelected] = useState<IAnalyst>({} as IAnalyst);
  const [projectSelected, setProjectSelected] = useState<IProject>({} as IProject);
  const [clientSelected, setClientSelected] = useState<IClient>({} as IClient);
  const [recentScheduleSelected, setRecentScheduleSelected] = useState<ISchedulesStorage>({} as ISchedulesStorage);
  const [coordinators, setCoordinators] = useState<Coordinators[]>([]);
  const [recentSchedules, setRecentSchedules] = useState<ISchedulesStorage[]>([]);

  const [deletedScheduleList, setDeletedScheduleList] = useState<number[]>([])

  const [analysts, setAnalysts] = useState<IAnalyst[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectsFiltered, setProjectsFiltered] = useState<IProject[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [local, setLocal] = useState<"REMOTO" | "PRESENCIAL">("REMOTO"); 
  const [currentDate, setCurrentDate] = useState(Number(moment().format('DD')) > (Number(startDayRangeSchedule)) ? `${moment().format('YYYY')}-${moment().format('MM')}-${startDayRangeSchedule}` : `${moment().format('YYYY')}-${moment().subtract(1, 'month').format('MM')}-${startDayRangeSchedule}`);
  const [month, setMonth] = useState(Number(moment().format('DD')) > (Number(startDayRangeSchedule)) ? (Number(moment().format('MM')) + 1) > 12 ? 1 : (Number(moment().format('MM')) + 1) : Number(moment().format('MM')));
  const [ year, setYear ] = useState(Number(moment().format('DD')) > (Number(startDayRangeSchedule)) && (Number(moment().format('MM')) + 1) > 12 ? Number(moment().format('YYYY')) + 1 : Number(moment().format('YYYY')));
  const [fullCalendarData, setFullCalendarData] = useState<ISchedule[]>([]);
  const recentScheduleFilter = recentSchedules.filter((item) => String((item.client.Nome)).includes(searchRecentSchedules.toUpperCase()) || String(item.project.Nome).includes(searchRecentSchedules.toUpperCase()));

  async function getAnalysts() {
    try {
      setAnalystLoad(true);
      const { data, status } = await scheduleService.getAnalyst(token);
  
      if (status === 200) {
        setAnalysts(data.dados);
      }    
      
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } 
  }

  async function getRecentSchedule() {
    try {
      setRecentSchedulesLoad(true);

      const schedules = await db.schedules.where({user: user}).reverse().sortBy('client');
  
      schedules.sort(function (a, b) {
        if (a.client.Nome > b.client.Nome) {
          return 1;
        }
        if (a.client.Nome < b.client.Nome) {
          return -1;
        }
        return 0;
      });
  
      setRecentSchedules(schedules)
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setRecentSchedulesLoad(false);
    }
  }

  async function getClients() {
    try {
      setClientLoad(true);
      const { data, status } = await scheduleService.getClients(token);
  
      if (status === 200) {
        setClients(data);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } 
  }

  async function getProjects(clientId: string) {
    try {
      setProjectLoad(true);
      const { data, status } = await scheduleService.getProjects(token, clientId);
  
      if (status === 200) {
        setProjects(data);
        setProjectsFiltered(data);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setProjectLoad(false);
    }
  } 

  function getDates(startDate: string, endDate: string) {
    const dateArray = [];
    let currentDate = moment(startDate);
    const stopDate = moment(endDate);
    while (currentDate <= stopDate) {
      dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
      currentDate = moment(currentDate).add(1, 'days');
    }
    dateArray.pop();
    return dateArray;
  }

  function handleValidateBlockedDay(dateStart: string): boolean {
    const dayBlocked = fullCalendarData.find(schedule => schedule.data === dateStart && schedule.codcli.includes('99999'))

    if(dayBlocked) {
      return true;
    } else{
      return false;
    }
  }

  function validateNotPeriod(date: string): boolean {
    const startDate = moment(currentDate).format('YYYY-MM-DD')
    const finalDate = moment(startDate).add(1, 'month').add(1, 'day').format('YYYY-MM-DD')
    return !moment(date).isBetween(startDate, finalDate);
  }

  async function handleAddNewSchedule(dateStart: string, dateEnd: string) {
    const scroll = document.querySelector('#calendarContainer').scrollTop;

    if(handleValidateBlockedDay(dateStart)) {
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Este dia esta bloqueado você não pode incluir Os.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }

    if(fullCalendarData.find(calendarData => calendarData.data === dateStart) && clientSelected.Codigo === '999999') {
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Este dia já possui uma agenda, e não pode ser bloqueado.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }

    if(!analystSelected.nome || !clientSelected.Nome || !projectSelected.Nome || !local) {
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Preencha todos os campos apara adicionar um agenda.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }


    let id;
    
    try {
      if(clientSelected.Codigo.includes('99999') && projectSelected.Codigo === "YYYYYY" || projectSelected.Codigo === "XXXXXX" ) {
       
        await Swal.fire({
          title: 'Confirmação',
          html: 
            `
            <label class="blockLabel">Observação*<textarea class="blockObs"></textarea></label>
            <label style="display: ${projectSelected.Codigo === "XXXXXX" ? 'none' : 'block' }" class="blockLabel">Validade*<input class="blockDate" min="${new Date().toISOString().split(".")[0]}" type="datetime-local" /></label>
            `,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Confirmar',
          preConfirm:() => {
            const obs = document.querySelector<HTMLTextAreaElement>('.blockObs').value;
            const dateValidate = document.querySelector<HTMLInputElement>('.blockDate').value;

            if(!obs) { 
              Swal.showValidationMessage(
                `Faltou preencher o campo Observação!`
              );
            }
            if(projectSelected.Codigo === "YYYYYY" && !dateValidate) { 
              Swal.showValidationMessage(
                `Faltou preencher o campo de Validade!`
              );
            }
          },
        }).then( async (result) => {
          if(result.isConfirmed === true) {
            const obs = document.querySelector<HTMLTextAreaElement>('.blockObs').value;
            const dateValidate = document.querySelector<HTMLInputElement>('.blockDate').value;
            

            const dates = getDates(dateStart, dateEnd)
            for (const date of dates) {
              if(validateNotPeriod(date)){
                return Swal.fire({
                  title: 'Atenção!',
                  text: 'Você não poder adicionar uma OS fora do período',
                  icon: 'info',
                  confirmButtonText: 'Ok'
                })
              }
            }

            id = toast("Por favor aguarde...", {  isLoading: true, closeOnClick: true, rtl: false }); //toast.update(id, {render: "Por favor, aguarde...", isLoading: true, closeOnClick: true, rtl: false });
            
            const osData = dates.map(date => {
              return {
                cCliente: clientSelected.Codigo,
                cCodUser:  analystSelected.codigo,
                cData: date,
                cLocal: local,
                cNomeCli: clientSelected.Nome,
                cNomeProj: projectSelected.Nome,
                cProjeto: projectSelected.Codigo,
                cValidade: dateValidate,
                cObs: obs
              }
            })

            const objectParams = {
              token,
              data: osData,
            }

            const {data, status} = await scheduleService.createSchedules(objectParams)

            if(status === 200 || status === 201) {
              const schedules: ISchedule[] = data.map((OsData, index) => {
                if(OsData.status === 'ok' || OsData.status === 'warning') {
                  if(index === 0) {
                    toast.update(id, {render: OsData.msg, type: OsData.status === 'ok' ? "success" : 'warning', isLoading: false, autoClose: OsData.status === 'ok' ? 500 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
                  } else {
                    toast[OsData.status === 'ok' ? 'success' : 'warning'](`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: OsData.status === 'ok' ? 5000 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
                  }
                  return {
                    almoco: "",
                    analista: analystSelected.nome,
                    codcli: clientSelected.Codigo,
                    codpro: projectSelected.Codigo,
                    osColor:  `${projectSelected.Color}`,
                    color: 'transparent',
                    contato: "",
                    data: OsData.data,
                    desconto: "00:00",
                    despesas: [],
                    horafim: "",
                    horaini: "",
                    horatot: 0,
                    justificativa: "",
                    key: OsData.key,
                    local: local === 'REMOTO' ? 'REMOTO' : 'PRESENCIAL',
                    nomeCoordenador: projectSelected.NomeCoor,
                    nomecli: clientSelected.Nome,
                    os: "",
                    projeto: projectSelected.Nome,
                    projetohoras: "",
                    qtdAlmoco: 0,
                    razao: "",
                    status: "",
                    tarefa: "",
                    telcli: "",
                    totDesp: 0,
                    valortot: 0,
                    title: `${clientSelected.Nome}`,
                    start: OsData.data,
                    observacao: obs
                  }
                } else {
                  if(index === 0) {
                    toast.update(id, {render: OsData.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
                  } else {
                    toast.error(`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
                   
                  }
                }
              }).filter(OsData => OsData !== undefined)
            
              await setFullCalendarData(prevState => [...prevState, ...schedules])

              if (!recentSchedules.find(schedule => schedule.client.Codigo === clientSelected.Codigo && schedule.project.Codigo === projectSelected.Codigo)) {      
                db.schedules.add({
                  client: clientSelected,
                  project: projectSelected,
                  user: user,
                }).then(async () => {
                  // const schedules = await db.schedules.where({user: usuario}).limit(10).reverse().toArray();
                  const schedules = await db.schedules.where({user: user}).reverse().sortBy('client');

                  schedules.sort(function (a, b) {
                    if (a.client.Nome > b.client.Nome) {
                      return 1;
                    }
                    if (a.client.Nome < b.client.Nome) {
                      return -1;
                    }
                    return 0;
                  });
                  
                  setRecentSchedules(schedules);
                })
                await calendar.current.render();
              }
            }
          }
        })
      }else {
        const dates = getDates(dateStart, dateEnd)
        
        for (const date of dates) {
          if(validateNotPeriod(date)){
            return Swal.fire({
              title: 'Atenção!',
              text: 'Você não poder adicionar uma OS fora do período',
              icon: 'info',
              confirmButtonText: 'Ok'
            })
          }
        }
        
        id = toast("Por favor aguarde...", {  isLoading: true, closeOnClick: true, rtl: false });
        // toast.update(id, {render: "Por favor aguarde...",  isLoading: true });

        const osData = dates.map(date => {
          return {
            cCliente: clientSelected.Codigo,
            cCodUser:  analystSelected.codigo,
            cData: date,
            cLocal: local,
            cNomeCli: clientSelected.Nome,
            cNomeProj: projectSelected.Nome,
            cProjeto: projectSelected.Codigo,
            cValidade: '',
            cObs: ''
          }
        })

        const objectParams = {
          token,
          data: osData,
        }

        const {data, status} = await scheduleService.createSchedules(objectParams)

        if(status === 200 || status === 201) {
          const schedules: ISchedule[] = data.map((OsData, index) => {
            if(OsData.status === 'ok' || OsData.status === 'warning') {
              if(index === 0) {
                toast.update(id, {render: `${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`, type: OsData.status === "ok" ? "success" : "warning", isLoading: false, autoClose: OsData.status === 'ok' ? 500 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast[OsData.status === 'ok' ? 'success' : 'warning'](`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: OsData.status === 'ok' ? 5000 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              }
              return {
                almoco: "",
                analista: analystSelected.nome,
                codcli: clientSelected.Codigo,
                codpro: projectSelected.Codigo,
                osColor:  `${projectSelected.Color}`,
                color: 'transparent',
                contato: "",
                data: OsData.data,
                desconto: "00:00",
                despesas: [],
                horafim: "",
                horaini: "",
                horatot: 0,
                justificativa: "",
                key: OsData.key,
                local: local === 'REMOTO' ? 'REMOTO' : 'PRESENCIAL',
                nomeCoordenador: projectSelected.NomeCoor,
                nomecli: clientSelected.Nome,
                os: "",
                projeto: projectSelected.Nome,
                projetohoras: "",
                qtdAlmoco: 0,
                razao: "",
                status: "",
                tarefa: "",
                telcli: "",
                totDesp: 0,
                valortot: 0,
                title: `${clientSelected.Nome}`,
                start: OsData.data
              }
            } else {
              if(index === 0) {
                console.log('Aqui erro - ',OsData)
                toast.update(id, {render: data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast.error(`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
                console.log('Erro de bloqueio',OsData)
              }
            }
          }).filter(OsData => OsData !== undefined)
        
          await setFullCalendarData(prevState => [...prevState, ...schedules])

          if (!recentSchedules.find(schedule => schedule.client.Codigo === clientSelected.Codigo && schedule.project.Codigo === projectSelected.Codigo)) {      

            db.schedules.add({
              client: clientSelected,
              project: projectSelected,
              user: user,
            }).then(async () => {
              // const schedules = await db.schedules.where({user: usuario}).limit(10).reverse().toArray();
              const schedules = await db.schedules.where({user: user}).reverse().sortBy('client');

              schedules.sort(function (a, b) {
                if (a.client.Nome > b.client.Nome) {
                  return 1;
                }
                if (a.client.Nome < b.client.Nome) {
                  return -1;
                }
                return 0;
              });
              
              setRecentSchedules(schedules);
            })
            await calendar.current.render();
          }
          document.querySelector('#calendarContainer').scrollTop = scroll;     
        } 
      }
    } catch (error) {
      console.log(error)
      // calendar.current.refetchEvents()
      return  toast.update(id, {render: error.msg || "Algo deu errado", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
    }
  }
   
  async function handleUpdateSchedule(args: EventDropArg, date: string, os: ISchedule) {
    const scroll = document.querySelector('#calendarContainer').scrollTop;

    if(handleValidateBlockedDay(date)) {  
      args.revert()   
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Este dia esta bloqueado você não pode incluir Os.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }

    if(fullCalendarData.find(calendarData => calendarData.data === date) && os.codcli === '999999') {
      args.revert()
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Este dia já possui uma agenda e não pode ser bloqueado.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }

    if(!analystSelected.nome) {
      args.revert()
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Selecione um analista para alterar!',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }
    
    const id = toast.loading("Por favor aguarde...")

    if(KeyPressing.isKeyPressed(18)) {
      try {
        const objectParams = {
          token,
          data: [{
            cCliente: os.codcli,
            cCodUser:  analystSelected.codigo,
            cData: date,
            cLocal: os.local === 'REMOTO' ? 'R' : 'P',
            cNomeCli: os.nomecli,
            cNomeProj: os.projeto,
            cProjeto: os.codpro,
            cValidade: os.validade ? os.validade : '',
            cObs: os.observacao ? os.observacao : ''
          }]
        }
        
        const {data, status} = await scheduleService.createSchedules(objectParams)
        
        if(status === 200 || status === 201) {
          const schedules: ISchedule[] = data.map((OsData, index) => {
            if(OsData.status === 'ok' || OsData.status === 'warning') {
              if(index === 0) {
                toast.update(id, {render: `${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`, type: OsData.status === "ok" ? "success" : "warning", isLoading: false, autoClose: OsData.status === 'ok' ? 500 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast[OsData.status === 'ok' ? 'success' : 'warning']( `${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`, {autoClose: OsData.status === 'ok' ? 5000 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              }
              return {
                almoco: "",
                analista: analystSelected.nome,
                codcli: os.codcli,
                codpro: os.codpro,
                osColor:  `${os.osColor}`,
                color: 'transparent',
                contato: "",
                data: OsData.data,
                desconto: "00:00",
                despesas: [],
                horafim: "",
                horaini: "",
                horatot: 0,
                justificativa: "",
                key: OsData.key,
                local: os.local === 'REMOTO' ? 'REMOTO' : 'PRESENCIAL',
                nomeCoordenador: os.nomeCoordenador,
                nomecli: os.nomecli,
                os: "",
                projeto: os.projeto,
                projetohoras: "",
                qtdAlmoco: 0,
                razao: "",
                status: "",
                tarefa: "",
                telcli: "",
                totDesp: 0,
                valortot: 0,
                title: `${os.nomecli}`,
                start: OsData.data
              }
            } else {
              if(index === 0) {
                toast.update(id, {render: data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast.error(`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
              }
            }
            
          })
          
					await setFullCalendarData(prevState => [...prevState, ...schedules]);
          document.querySelector('#calendarContainer').scrollTop = scroll;
        }else{
          args.revert()
         
        }
      } catch (error) {
        args.revert()
        console.log(error)
        return toast.update(id, {render: error.response.data.errorMessage || "Algo deu errado", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
      } 
    }else{
      try {
        const objectParams = {
          token,
          data: [{
            id: os.key,
            cCliente: os.codcli,
            cCodUser:  analystSelected.codigo,
            cData: date,
            cLocal: os.local === 'REMOTO' ? 'R' : 'P',
            cNomeCli: os.nomecli,
            cNomeProj: os.projeto, 
            cProjeto: os.codpro,
            cValidade: os.validade ? os.validade : '',
            cObs: os.observacao ? os.observacao : ''
          }]
        }
        
        const {data, status} = await scheduleService.updateSchedules(objectParams)
        
        if(status === 200 || status === 201) {
          data.map((OsData, index) => {
            if(OsData.status === 'ok' || OsData.status === 'warning') {
              if(index === 0) {
                toast.update(id, {render: `${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`, type: OsData.status === "ok" ? "success" : "warning", isLoading: false, autoClose: OsData.status === 'ok' ? 500 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast[OsData.status === 'ok' ? 'success' : 'warning']( `${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`, {autoClose: OsData.status === 'ok' ? 5000 : 10000, closeOnClick: true, hideProgressBar: false, rtl: false });
              }
            } else {
              if(index === 0) {
                toast.update(id, {render: data.msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
              } else {
                toast.error(`${OsData.msg} - ${moment(OsData.data).format('DD/MM/YYYY')}`,{ autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
              }
            }
          })
          const calendarData = [...fullCalendarData]
          const scheduleSelected = calendarData.find(item => item.key === os.key);
          const schedulesFiltered = calendarData.filter(item => item.key !== os.key)
          scheduleSelected.data = date;
          scheduleSelected.start = date;

          await setFullCalendarData([...schedulesFiltered, scheduleSelected])
        }else{
          toast.update(id, {render: data.errorMessage || data.msg || "Algo deu errado", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
          args.revert()
        }
      } catch (error) {
        args.revert()
        console.log(error)
        return toast.update(id, {render: error.response.data.errorMessage || "Algo deu errado", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
      }
    }
  }

  async function handleDeleteSchedule(keys: number[], args?: EventDropArg) {
    const scroll = document.querySelector('#calendarContainer').scrollTop;
    const schedulesArr = [...fullCalendarData];

    if(schedulesArr.find(schedule => keys.find(key => key === schedule.key) && schedule.status === '3')) {
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Existe Os já fechada remova ela e tente excluir novamente',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }
    const schedules = schedulesArr.filter(item => !keys.find(key => key === item.key))


    const id = toast.loading("Por favor aguarde...")
    try {
      const {data, status} = await scheduleService.deleteSchedules(keys, token)
      
      if(status === 200) {
        data.map(({status, msg, data, index}) => {
          if(status === 'ok'){
            if(index === 0) {
              toast.success(`${msg} - ${moment(data).format('DD/MM/YYYY')}`,{ autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
            } else {
              toast.update(id, {render: `${msg} - ${moment(data).format('DD/MM/YYYY')}`, type: "success", isLoading: false, autoClose: 2000, closeOnClick: true, hideProgressBar: false, rtl: false }); 
            }
            setDeletedScheduleList(prevState => prevState.filter(item => !keys.includes(item)))
          } else {
            if(index === 0) {
              toast.error(`${msg} - ${moment(data).format('DD/MM/YYYY')}`,{ autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
            } else {
              toast.update(id, {render: msg, type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false  });
            }
            args.revert()
          }
        })
        setFullCalendarData(schedules);
        document.querySelector('#calendarContainer').scrollTop = scroll;
      }
    } catch (error) {
      console.log(error)
      return toast.update(id, {render: error.response.data.errorMessage || "Algo deu errado", type: "error", isLoading: false, autoClose: 5000, closeOnClick: true, hideProgressBar: false, rtl: false });
    } 
  }

  async function handleDeleteRecentProject(id: number) {
    db.schedules.where({ id }).delete().then(async () => {
      // const schedules = await db.schedules.where({user: usuario}).limit(10).reverse().toArray();
      const schedules = await db.schedules.where({user: user}).reverse().sortBy('client');
      schedules.sort(function (a, b) {
        if (a.client.Nome > b.client.Nome) {
          return 1;
        }
        if (a.client.Nome < b.client.Nome) {
          return -1;
        }
        return 0;
      });
      
      setRecentSchedules(schedules);
      
    })
  }

  async function handleChangeAnalyst(analystCode: string) {
    setAnalystSelected(analysts.find(analyst => analyst.codigo === analystCode));
    const id = toast.loading("Por favor aguarde...")
    try {
      setCalendarLoad(true);

      const { data } = await scheduleService.getSchedules(month, year, analystCode, token);
      const { analista, status } = data;
  
      if (status === 'ok') {
        const analistaOs: ISchedule[] = []
        analista.agendas.map((item: ISchedule) => {
          item.title = `${item.nomecli}`;
          item.start = item.data
          item.osColor = item.color
          item.color = 'transparent'
          item.start = item.data;
  
          analistaOs.push(item);
  
          return item;
        })
        const key = 'nomeCoordenador';
        const arrayUniqueByKey = [...new Map(analista.agendas.map((item:ISchedule) =>[item[key], { nome: item.nomeCoordenador, color: item.osColor! }])).values()];
        setCoordinators(arrayUniqueByKey as Coordinators[]);
        setFullCalendarData(analistaOs);
        
        const date = moment(new Date(analista.currentDate)).subtract(1, 'months').date(Number(startDayRangeSchedule)).format('YYYY-MM-DD'); 
        setCurrentDate(date);
        setDeletedScheduleList([])
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setCalendarLoad(false);
      toast.update(id, {render: 'Agendas carregadas!' , isLoading: false, autoClose: 500, closeOnClick: true, hideProgressBar: false, rtl: false }); 
    }
  }

  async function handleChangeClient(clientCode: string) {
    setProjectsFiltered([]);
    setClientSelected(clients.find(analyst => analyst.Codigo.trim() === clientCode.trim()));
    getProjects(clientCode)
    setProjectSelected({} as IProject);
  }

  async function handleChangeMonth(month: number, year: number) {
    if(!analystSelected.codigo) {
      return  Swal.fire({
        title: 'Atenção!',
        text: 'Selecione uma analista primeiro.',
        icon: 'info',
        confirmButtonText: 'Ok'
      })
    }
    
    setCalendarLoad(true);
    const id = toast.loading("Por favor aguarde...")
    try {
      setMonth(month);
      setYear(year);
      setDeletedScheduleList([])
      const { data } = await scheduleService.getSchedules(month, year, analystSelected.codigo, token);
      const { analista, status } = data;
  
      if (status === 'ok') {
        const analistaOs: ISchedule[] = []
        analista.agendas.map((item: ISchedule) => {
          item.title = `${item.nomecli}`;
          item.start = item.data
          item.osColor = item.color
          item.color = 'transparent'
  
          analistaOs.push(item);
  
          return item;
        })
        const key = 'nomeCoordenador';
        const arrayUniqueByKey = [...new Map(analista.agendas.map((item:ISchedule) =>[item[key], { nome: item.nomeCoordenador, color: item.osColor! }])).values()];
        setCoordinators(arrayUniqueByKey as Coordinators[]);
        setFullCalendarData(analistaOs);

        const date = moment(new Date(analista.currentDate)).subtract(1, 'months').date(Number(startDayRangeSchedule)).format('YYYY-MM-DD'); 
        setCurrentDate(date);
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo deu errado!',
      })
    } finally {
      setCalendarLoad(false);
      return toast.update(id, { isLoading: false, autoClose: 100, closeOnClick: true, hideProgressBar: false, rtl: false }); 
    }
  }

  function renderEventContent(eventContent: EventContentArg) {
    return (
      <Os 
        os={eventContent.event._def.extendedProps.os}
        nomeCoordenador={eventContent.event._def.extendedProps.nomeCoordenador}
        local={eventContent.event._def.extendedProps.local} 
        title={eventContent.event.title}
        subTitle={eventContent.event._def.extendedProps.projeto}
        color={eventContent.event._def.extendedProps.osColor}
        hour={eventContent.event._def.extendedProps.horatot}
        isSelected={!!deletedScheduleList.find(item => item === eventContent.event._def.extendedProps.key)}
      />
    )
  }

  function handleSelectRecentSchedules(recSchedule: ISchedulesStorage) {
    setRecentScheduleSelected(recSchedule);
    setClientSelected(recSchedule.client);
    getProjects(recSchedule.client.Codigo);
    setProjectSelected(recSchedule.project);
  }

  async function loadData() {
    await Promise.all([getAnalysts(), getClients()])
    setAnalystLoad(false);
    setProjectLoad(false);
    setClientLoad(false);
  }

  useEffect(() => {
    loadData();
    getRecentSchedule();
  }, [])
  
  return (
    <PageDefault>
      <DefaultHeader />

      <Content id='calendarContainer'>
        <SideMenu />
        {/* title={selectedMenu.descri || selectedMenu.nome} icon={<FluentUI.Person size={30} color={styleSheet.contrastColor} />}> */}
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<CalendarIcon size={24} color={styleSheet.mainColor} />}>
        <BrowserContent>
          <CalendarContainer>
            <Infos>
              <div >
                <DefaultLabel>Analista</DefaultLabel>
                <Dropdown
                  id="dropdown"
                  search
                  selection
                  loading={analystLoad}
                  disabled={analystLoad}
                  placeholder="Analista"
                  style={{ width: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem', marginBottom: '1rem', zIndex: 5 }}
                  options={analysts.map(({ nome, codigo }) => { return { value: codigo, text: nome } })}
                  onChange={
                    (ev, { value }) => { handleChangeAnalyst(value.toString()) }
                  }
                  value={analystSelected.codigo || ''}
                />

                <DefaultLabel>Cliente</DefaultLabel>
                <Dropdown
                  id="dropdown"
                  search
                  selection
                  loading={clientLoad}
                  disabled={clientLoad}
                  placeholder="Clientes"
                  style={{ width: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem', marginBottom: '1rem', zIndex: 4 }}
                  options={clients.map(({ Nome, Codigo }) => { return {  value: Codigo, text: `${Codigo} - ${Nome}` } })}
                  onChange={
                    (ev, { value }) => { handleChangeClient(value.toString()) }
                  }
                  value={clientSelected.Codigo || ''}
                />

                <DefaultLabel>Projetos</DefaultLabel>
                <Dropdown
                  id="dropdown"
                  search
                  selection
                  loading={projectLoad}
                  disabled={projectLoad}
                  placeholder="Projetos"
                  style={{ width: '100%', height: '4rem', fontSize: '1.2rem', lineHeight: '1.5rem', marginBottom: '1rem', zIndex: 3 }}
                  options={projectsFiltered.map(({ Nome, Codigo }) => { return {  value: Codigo, text: `${Codigo === 'XXXXXX' || Codigo ===  'YYYYYY' ? '' : Codigo + ' - '}  ${Nome}` } })}
                  onChange={
                    (ev, { value }) => { setProjectSelected(projects.find(project => project.Codigo === value)) }
                  }
                  value={projectSelected.Codigo || ''}
                />

                <DefaultLabel>Local</DefaultLabel>
                <Dropdown
                  search
                  selection
                  placeholder="Local"
                  style={{ width: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem', marginBottom: '1rem', zIndex: 2 }}
                  options={[
                    { key: 'REMOTO', value: 'REMOTO', text: 'REMOTO' },
                    { key: 'PRESENCIAL', value: 'PRESENCIAL', text: 'PRESENCIAL' }
                  ]}
                  onChange={
                    (ev, { value }) => { setLocal(value.toString() as "REMOTO" | "PRESENCIAL") }
                  }
                  value={local}
                />

              <DefaultLabel>Projetos Recentes</DefaultLabel>
              <Dropdown
                id="recentProjects"
                selection
                search
                autofocus={false}
                loading={recentSchedulesLoad}
                disabled={recentSchedulesLoad}
                placeholder={recentScheduleSelected.client ? `${recentScheduleSelected.client.Nome} - ${recentScheduleSelected.project.Nome}` : "Projetos Recentes"}
                style={{ width: '100%', height: '4rem', fontSize: '1.3rem', lineHeight: '1.9rem', marginBottom: '1rem', zIndex: 1 }}
                value={recentScheduleSelected.id}
                selectedLabel={recentScheduleSelected.id}
                onSearchChange={(event, data) => setSearchRecentSchedules(data.searchQuery)}
              >
                <Dropdown.Menu style={{ maxHeight: `${window.innerHeight - 550}px` }}>
                  {
                    recentScheduleFilter.map((recentSchedule) =>  
                      <Dropdown.Item 
                        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        onClick={(ev, { value }) => { handleSelectRecentSchedules(recentSchedules.find(recentSchedule => recentSchedule.id === Number(value))) }} 
                        value={recentSchedule.id} 
                      >
                        {`${recentSchedule.client.Nome} - ${recentSchedule.project.Codigo === 'XXXXXX' || recentSchedule.project.Codigo === 'YYYYYY' ? '' : recentSchedule.project.Codigo + ' - '} ${recentSchedule.project.Nome}`}
                        <Button.Group size='small'>
                          <ButtonTable 
                            color="#E47261"
                            onClick={() => {
                              handleDeleteRecentProject(recentSchedule.id)
                            }}
                          >
                            <Trash size={20} color="#E47261" />
                          </ButtonTable>
                        </Button.Group>
                      </Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>

              {
                deletedScheduleList.length > 0 ?
                <Button 
                  fluid
                  size="huge"
                  negative
                  onClick={() => { 
                    Swal.fire({
                      title: 'Confirmar ação?',
                      text: `Você deseja excluir essa ${deletedScheduleList.length} Agendas?`,
                      icon: 'info',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim, confirmar!',
                      cancelButtonText: 'Cancelar'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleDeleteSchedule(deletedScheduleList)
                      }
                    })
                  }}
                >
                  Excluir agendas selecionadas ({deletedScheduleList.length})
                </Button> : null
              }
              </div>
            </Infos>
            <Calendar>
              {
                calendarLoad ?
                  <div style={{ height: window.innerHeight - 220 }}>
                    <Dimmer inverted active>
                      <Loader size='big'>Carregando Agendas...</Loader>
                    </Dimmer>
                  </div>
                :
                <FullCalendar  
                  ref={calendar}
                  height='auto'//{window.innerHeight - 220}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  views={{
                    dayGridMonth: {
                      type: "month",
                      duration: { week: moment(currentDate).isoWeekday() >= 6 ? 6 : 5  },
                      buttonText: "4 Weeks",
                      fixedWeekCount : true                     
                    }
                  }}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'customTitle',
                    right: '',   
                  }}
                  customButtons={{
                    prev: {
                      text: 'Prev',
                      click: async function () {
                        if (month === 1) {
                          setMonth(12)
                          setYear(year -1)
                          await handleChangeMonth(12, year - 1)
                        } else {
                          setMonth(month - 1)
                          await handleChangeMonth(month - 1, year)
                        }
                      }
                    },
                    next: {
                      text: 'Next',
                      click: async function () {
                        if (month === 12) {
                          setMonth(1)
                          setYear(year + 1)
                          await handleChangeMonth(1, year + 1)
                        } else {
                          setMonth(month + 1)
                          await handleChangeMonth(month + 1, year)
                        }
                      }
                    },
                    today: {
                      text: 'Hoje',
                      click: async function () {
                        const month = Number(moment().format('DD')) > ((Number(startDayRangeSchedule))) ?  Number(moment().format('MM')) + 1 > 12 ? 1 : (Number(moment().format('MM')) + 1) : Number(moment().format('MM'))
                        const year = Number(moment().format('DD')) > ((Number(startDayRangeSchedule))) && Number(moment().format('MM')) + 1 > 12 ? Number(moment().format('YYYY')) + 1 : Number(moment().format('YYYY'))
                        setMonth(Number(month))
                        await handleChangeMonth(Number(month), year)
                      }
                    },
                    customTitle: {
                      text: `Período de ${new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(new Date(moment(currentDate).add(1, 'month').format()))}`,
                      click: (ev) => {
                        ev.preventDefault()
                      },
                    }
                  }}
                  stickyHeaderDates={false}
                  events={fullCalendarData}
                  eventContent={renderEventContent}
                  eventMouseEnter={(arg) => (arg.event.extendedProps.codcli.includes('99999')) ? tippy(arg.el, { content: `${arg.event.extendedProps.observacao}`, theme: 'material' }) : tippy(arg.el, arg.event._def.extendedProps.os !== '' ? { content: `OS - ${arg.event._def.extendedProps.os}`, theme: 'material'} : { content: 'OS - Pendente' })}
                  eventClick={args => {
                    const osKey = args.event._def.extendedProps.key as number
                    setDeletedScheduleList(prev => prev.find(item => item === osKey) ? prev.filter(item => item !== osKey) : [...prev, osKey])
                    // handleAddNewSchedule(args.event.startStr, moment(args.event.startStr, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"))
                  } }
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  initialDate={currentDate}
                  visibleRange={{
                    start: currentDate,
                    end: moment(currentDate).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD')
                  }}
                  // scroll no evento pra mais de um dia
                  eventDurationEditable={false}
                  // eventResize={args => console.log(args.event.startStr, args.event.endStr)}
                  
                  eventDragStart={ () => { 
                    setDropZone(true)
                  }}              
                  eventDragStop={async (args)=> {
                    
                    const trashEl = document.getElementById('calendarTrash') //as HTMLElement;
                    
                    const x1 = 0;//trashEl.offsetLeft;
                    const x2 = trashEl.offsetLeft + trashEl.offsetWidth;
                    const y1 = trashEl.offsetTop;
                    const y2 = trashEl.offsetTop + trashEl.offsetHeight;
                    
                    setDropZone(false)

                    if (args.jsEvent.pageX >= x1 && args.jsEvent.pageX <= x2 && args.jsEvent.pageY >= y1 && args.jsEvent.pageY <= y2) {  
                      if(args.event._def.extendedProps.tarefa !== '' || args.event._def.extendedProps.horatot > 0 ) {
                        setDropZone(false)
  
                        return  Swal.fire({
                          title: 'Atenção!',
                          text: 'Você não pode excluir uma os que já foi apontada!',
                          icon: 'warning',
                          confirmButtonText: 'Ok'
                        })
                      }
                      const selectedSchedule = args.event._def.extendedProps.key
                      const schedulesToDelete = deletedScheduleList.length > 0 ? deletedScheduleList.includes(selectedSchedule) ? deletedScheduleList : [...deletedScheduleList, selectedSchedule] : [selectedSchedule]
                      await handleDeleteSchedule(schedulesToDelete, args.event._def.extendedProps as EventDropArg);
                      
                      // e.event.remove()    
                    }
                  }}
                  eventDrop={args => {
                    if(args.event._def.extendedProps.status === "3") {
                      args.revert()
                      return  Swal.fire({
                        title: 'Atenção!',
                        text: 'Esta Os já esta fechada.',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                      })
                    }
                    handleUpdateSchedule(args, args.event.startStr, args.event._def.extendedProps as ISchedule)
                  }}
                  select={args => {
                    if (KeyPressing.isKeyPressed(69)) {
                      const schedulesToDelete = fullCalendarData.filter(schedule => moment(schedule.data).isBetween(moment(args.startStr).subtract(1, 'day'), moment(args.endStr))).map(item => item.key)
                      Swal.fire({
                        title: 'Confirmar ação?',
                        text: `Você deseja excluir essa ${schedulesToDelete.length} Agendas?`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim, confirmar!',
                        cancelButtonText: 'Cancelar'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDeleteSchedule(schedulesToDelete)
                        }
                      })
                    } else {
                      handleAddNewSchedule(args.startStr, args.endStr)
                    }
                  }}
                  locale='br'
                  expandRows={true}
                  dragScroll={false}
                  firstDay={1}
                  dayCellClassNames={function (args) {
                    const startDate = moment(currentDate).format('YYYY-MM-DD')
                    const finalDate = moment(startDate).add(1, 'month').add(1, 'day').format('YYYY-MM-DD')
                    if(moment(args.date).isBetween(startDate, finalDate)){
                      return 'inPeriod'
                    }else{
                      return 'notInPeriod'
                    }
                  }}
                />
              }
              <Menu borderless style={{backgroundColor: '#F9FAFB'}}>
                {
                  coordinators.map(coordinator => coordinator.nome !== "" && (
                    <Menu.Item>                  
                      <>
                        <Label
                          as="a"
                          circular
                          size='large'
                          style={{
                            background: coordinator.color,
                            color: '#fff'
                          }}
                          onClick={() => {
                            console.log()
                          }}
                        >
                          {coordinator.nome.toString().split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').substring(0, 2)}
                        </Label>
                        <Label 
                          style={{ background: styleSheet.mediumGrey, color: styleSheet.shape  }} 
                          size='large'
                        >
                          {coordinator.nome.split(' ')[0]}
                        </Label>
                      </>
                    </Menu.Item>                
                  ))
                }
              </Menu>
            </Calendar>
          </CalendarContainer>
        </BrowserContent>
        </Browser>

      </Content>

      <Footer />

      {
        dropZone && 
        <DeleteZone id="calendarTrash">
          <Trash size={50} color={styleSheet.shape} />
        </DeleteZone>
      }
      
    </PageDefault>
  );
}

export default Schedules;