import { createSlice } from '@reduxjs/toolkit';

import { IUpdateAccessLogData, IUpdateChartOrderByClientData, IUpdateLogIsLoading, IUpdateOrderLogData, IUpdatePageLogData } from "./actions";
import { IOrderLogsByGroupChar } from "../../interfaces/Logs/ILogin";

export interface LogData {
  [key: string]: any
}

export interface LogsReducerInitialState {
  accessLogData: LogData[];
  pageLogData: LogData[];
  orderLogData: LogData[];
  chartOrderByClientData: IOrderLogsByGroupChar[];
  logIsLoading: boolean;
}

export const logsSlice = createSlice({
  name: 'logs',
  initialState: {
    accessLogData: [],
    pageLogData: [],
    orderLogData: [],
    chartOrderByClientData: [],
    logIsLoading: false,
  },
  reducers:{
    updateAccessLogData: (state, action: IUpdateAccessLogData) => {
      state.accessLogData = action.payload.value
    },

    updatePageLogData: (state, action: IUpdatePageLogData) => {
      state.pageLogData = action.payload.value
    },

    updateOrderLogData: (state, action: IUpdateOrderLogData) => {
      state.orderLogData = action.payload.value
    },

    updateChartOrderByClientData: (state, action: IUpdateChartOrderByClientData) => {
      state.chartOrderByClientData = action.payload.value
    },

    updateLogIsLoading: (state, action: IUpdateLogIsLoading) => {
      state.logIsLoading = action.payload.value
    }
  }
});

export const { updateAccessLogData, updatePageLogData, updateOrderLogData, updateChartOrderByClientData, updateLogIsLoading } = logsSlice.actions;
export default logsSlice.reducer