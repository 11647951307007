import { createSlice } from '@reduxjs/toolkit'
import { IAllowedProducts } from '../../interfaces/Auth/IAllowedProducts';
import { IBranches } from '../../interfaces/Auth/IBranches';
import { IPWM } from '../../interfaces/Auth/IPWM';
import { IUpdateBearerAccessToken, IUpdateBearerRefreshToken, IUpdateToken, IUpdateUser, IUpdateUserType, IUpdatePwm, IUpdateBranches, IUpdateAllowedProduct, ISingIn } from './actions';


export interface AuthReducerInitialState {
  token: string;
  bearerAccessToken: string;
  bearerRefreshToken: string;
  user: string;
  activeUserEmail: string;
  userType: string;
  pwm: IPWM;
  customerLevel: number;
  branches: IBranches[];
  allowedProducts: IAllowedProducts[];
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    bearerAccessToken: '',
    bearerRefreshToken: '',
    user: '',
    activeUserEmail: '',
    userType: '',
    customerLevel: 0,
    pwm: ({} as IPWM),
    branches: ([] as IBranches[]),
    allowedProducts: ([] as IAllowedProducts[]),
  },
  reducers: {
    updateToken: (state, action: IUpdateToken) => {
      state.token = action.payload.value
    },

    updateBearerAccessToken: (state, action: IUpdateBearerAccessToken) => {
      state.bearerAccessToken = action.payload.value
    },

    updateBearerRefreshToken: (state, action: IUpdateBearerRefreshToken) => {
      state.bearerRefreshToken = action.payload.value
    },

    updateUser: (state, action: IUpdateUser) => {
      state.user = action.payload.value
    },

    updateUserType: (state, action: IUpdateUserType) => {
      state.user = action.payload.value
    },

    updatePwm: (state, action: IUpdatePwm) => {
      state.pwm = action.payload.value
    },

    updateBranches: (state, action: IUpdateBranches) => {
      state.branches = action.payload.value
    },

    updateAllowedProduct: (state, action: IUpdateAllowedProduct) => {
      state.allowedProducts = action.payload.value
    },

    signIn: (state, action: ISingIn) => {
      state.token = action.payload.token
      state.user = action.payload.user
      state.activeUserEmail= action.payload.activeUserEmail
      state.userType = action.payload.userType
      state.pwm = action.payload.pwm
      state.branches = action.payload.branches
      state.allowedProducts = action.payload.allowedProducts
      state.customerLevel = action.payload.nivel
    },

    signOut: (state) => {
      state.token = ''
      state.user = ''
      state.activeUserEmail = ''
      state.userType = ''
      state.pwm = {} as IPWM
      state.branches = []
      state.allowedProducts = []
      state.customerLevel = 0
    }

  }
})

export const { updateToken, updateBearerAccessToken, updateBearerRefreshToken, updateUser, updateUserType, updatePwm, updateBranches, updateAllowedProduct, signIn, signOut } = authSlice.actions;
export default authSlice.reducer; 