import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, Transition, Input, Form, Checkbox } from 'semantic-ui-react';
import { IOpcoes } from '../../interfaces/Menu/IMenu';
import { EnvironmentReducerInitialState } from '../../reducers/Environment';

import { Container, LabelInput } from './styles';
import { PolicieReducerInitialState } from "../../reducers/Policie";

interface ISendModalProps {
  visible: boolean;
  isSend: boolean;
  options?: IOpcoes[];
  onClose(): void;
  handleSend(value: string, sendType: SendType, type?: string): void;
}

export type SendType = 'mail' | 'whatsapp' | 'download'

const SendModal: FC<ISendModalProps> = ({ visible, isSend,  onClose, handleSend, options }) => {
  const { selectedClient } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
  const { companyPolicies } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);

  const useChatbot = useMemo(() => companyPolicies.find(police => police.property === 'useChatbot' && police.policy_value === 'true'),companyPolicies);
  const allowDownloadTicketOrDanfeOnFinance = useMemo(() => companyPolicies.find(police => police.property === 'allowDownloadTicketOrDanfeOnFinance' && police.policy_value === 'true'),companyPolicies);

  const [inputValue, setInputValue] = useState('');
  const [sendType, setSendType] = useState<SendType>(allowDownloadTicketOrDanfeOnFinance ? 'download' : 'mail')
  const [checkboxValue, setCheckboxValue] = useState('');

  useEffect(() => {
    if(selectedClient.a1_email && sendType === 'mail') {
      setInputValue(selectedClient.a1_email.toString())
    }
    if(options && options.length > 0) {
      setCheckboxValue(options[0].tipo)
    }
  }, [visible, sendType])

  return (
    <Transition animation='fly up' visible={visible} unmountOnHide>
      <Modal
        closeIcon
        size="mini"
        onClose={onClose}
        open={visible}
      >
        <Container>
          <Modal.Content>
            <Form>
              <LabelInput>
                Informe como deseja receber o boleto
              </LabelInput>
              <Form.Field>
                <Checkbox
                  style={{ fontSize: '14px', marginTop: '1rem' }}
                  toggle
                  label="Email"
                  value="mail"
                  checked={sendType === "mail"}
                  onChange={(e, data) => setSendType(data.value.toString() as SendType)}
                />
              </Form.Field>
              {
                useChatbot ? (
                  <Form.Field>
                    <Checkbox
                      style={{ fontSize: '14px' }}
                      toggle
                      label="Whatsapp"
                      value="whatsapp"
                      checked={sendType === "whatsapp"}
                      onChange={(e, data) => setSendType(data.value.toString() as SendType)}

                    />
                  </Form.Field>
                ) : null
              }
              {
                allowDownloadTicketOrDanfeOnFinance ? (
                  <Form.Field>
                    <Checkbox
                      style={{ fontSize: '14px' }}
                      toggle
                      label="Download"
                      value="download"
                      checked={sendType === "download"}
                      onChange={(e, data) => setSendType(data.value.toString() as SendType)}
                    />
                  </Form.Field>
                ) : null
              }

              {
                sendType === 'mail' || sendType === 'whatsapp' ? (
                  <>
                    <LabelInput>
                      Informe email {useChatbot && 'ou whatsapp'} 
                    </LabelInput>

                    <Input
                      style={{ width: '100%', marginTop: '1rem' }}
                      value={inputValue}
                      onChange={e => setInputValue(e.target.value)}
                    />
                  </>
                ) : null
              }
              </Form>
            <Form style={{ marginTop: '15px' }}>
              {
                options && options.length > 0 && 
                  options.map(option => (
                    <Form.Field>
                      <Checkbox
                        style={{ fontSize: '14px' }}
                        radio
                        label={option.descricao}
                        value={option.tipo}
                        checked={checkboxValue === option.tipo}
                        onChange={(e, data) => setCheckboxValue(data.value.toString())}
                      />
                    </Form.Field>
                  ))
              }
            </Form>
          </Modal.Content>
        </Container>

        <Modal.Actions>
          <Button.Group size='big'>
            <Button onClick={onClose}>Cancelar</Button>
            <Button.Or text="ou" />
            <Button 
              onClick={() => {
                setInputValue('');
                handleSend(inputValue, sendType, checkboxValue);
              }} 
              positive
              disabled={(isSend || inputValue.trim() === '') && sendType !== 'download'}
              loading={isSend}
            >
              Confirmar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </Transition >
  );
}

export default SendModal;