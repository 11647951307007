import { AxiosError, AxiosResponse } from "axios";
import protheusProvider from "../providers/protheus.provider";
import helperProvider from "../providers/helper.provider";


export class AuthService {
  public async auth(username: string, password: string, companyId: string,  bearerToken?: string): Promise<AxiosResponse> {
    try {
      if(bearerToken) protheusProvider.defaults.headers.common.Authorization = `Bearer ${bearerToken}`;
      const response = await protheusProvider.get(`wsrestprod/${ username }/${ password }`, {
        params: {
          companyId,
        }
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  
  }

  public async authEmail(email: string, sendBy: 'email' | 'sms'): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put(`wscodepass/genderCode/${email}?sendBy=${sendBy}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async validateCode(email: string, code: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.post(`wscodepass/validCode/${email}?code=${code}`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async newPassword(password: string, token: string): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.put(`wsrestpass`, {
        password,
        token
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async helperToken(clientName: string, clientUrlRest: string): Promise<AxiosResponse> {
    try {
      const response = await helperProvider.post(`v1/oauth`, {
        companyName: clientName,
        protheusAPIUrl: clientUrlRest
      });
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }

  public async getCompanies(): Promise<AxiosResponse> {
    try {
      const response = await protheusProvider.get(`wsrestempsys`);
      return response;
    }
    catch (ex) {
      const { response } = ex as AxiosError;
      return response;
    }
  }
}