import React, { FC, useState } from 'react';
import { Message, Segment, Button, Transition } from 'semantic-ui-react';
import { Warning } from 'phosphor-react';
import { Container, MessageContainer, SpanContainer } from './styles';


const LgpdLogin: FC = () => {
  const [open, setOpen] = useState(true);

  function onConfirmLGPDFlag() {
    localStorage.setItem('lgpdFlag', 'true');
    setOpen(false);
  }

  function onDeclineLGPDFlag() {
    localStorage.setItem('lgpdFlag', 'false');
    setOpen(false);
  }

  return (
    <Transition
      animation='fly up'
      duration={1100}
      unmountOnHide={true}
      visible={open}
    >
      <Container>
        <Message color='black' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
          <MessageContainer>
            <Warning size={50} />
            <Message.Header>
              <h1>Política de privacidade e segurança</h1>
            </Message.Header>
            <SpanContainer> Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a prestação de serviços e aprimorar a experiência de uso.!</SpanContainer>
          </MessageContainer>
          <Message.Content>
            <Segment inverted>
              <Button.Group>
                <Button inverted color='red' onClick={onDeclineLGPDFlag} > Não Autorizo </Button>
                <Button inverted color='green' onClick={onConfirmLGPDFlag} > Autorizo  </Button>
              </Button.Group>
            </Segment>
          </Message.Content>
        </Message>
      </Container>
    </Transition>
  );
}

export default LgpdLogin;