import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';
import moment from 'moment'
import { useSelector } from "react-redux";
import { AuthReducerInitialState } from "../../../reducers/Auth";



interface ITimelineChartProps {
  data: [string | number | object];
  isFullscreen?: boolean;
}

const TimelineChart: FC<ITimelineChartProps> = ({ data, isFullscreen = false }) => {
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  const utils = new Utils();

  return (
    <Chart
      type="rangeBar"
      width="100%"
      height={isFullscreen ? '500' : '250'}
      series={[
        { data: data }
      ] as ApexAxisChartSeries}
      options={
        {
          chart: {
            width: '100%',
            height: isFullscreen ? '500' : '250',
            type: 'rangeBar'
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
              dataLabels: {
                hideOverflowingLabels: false
              }
            }
          },
          dataLabels: {
            enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
            formatter: function (val, opts) {
              const label = opts.w.globals.labels[opts.dataPointIndex]
              const a = moment(val[0])
              const b = moment(val[1])
              const diff = b.diff(a, 'days')
              return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
            },
            style: {
              colors: ['#f3f4f5', '#fff']
            }
          },
          xaxis: {
            type: 'datetime'
          },
          yaxis: {
            show: false
          },
          grid: {
            row: {
              colors: ['#f3f4f5', '#fff'],
              opacity: 1
            }
          }
        }
      }
    />
  );
}

export default TimelineChart;