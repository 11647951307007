import React, { FC, Fragment, useEffect, useState } from 'react';
import { House } from 'phosphor-react';
import { Container, Header } from 'semantic-ui-react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import packageInfo from '../../../package.json';

import 'react-loading-skeleton/dist/skeleton.css'
import parse from 'html-react-parser';

import { HomeService } from '../../services/methods/HomeService';

import DefaultHeader from '../../components/DefaultHeader';
import SideMenu from '../../components/SideMenu';
import Browser from '../../components/Browser';
import Footer from '../../components/Footer';

import { IHTMLMessages } from '../../interfaces/Home/IHTMLMessages';

import { EnvironmentReducerInitialState } from '../../reducers/Environment';
import { PolicieReducerInitialState } from '../../reducers/Policie';
import { AuthReducerInitialState } from '../../reducers/Auth';
import { MenuReducerInitialState } from '../../reducers/Menu';

import { styleSheet } from '../../assets/styles/global';

import { BrowserContent, Content, HTMLSubTitle, HTMLTitleVersion, ImgContainer, MarkupContainer, PageDefault } from './styles';

const Home: FC = () => {
  const [htmlTitle, setHtmlTitle] = useState('');
  const [htmlHome, setHtmlHome] = useState('');
  const [loading, setLoading] = useState(false);

  const homeService: HomeService = new HomeService();

  const { currentBranch, currentCompany } = useSelector((state: { environment: EnvironmentReducerInitialState }) => state.environment);
	const { customizations } = useSelector((state: { policie: PolicieReducerInitialState }) => state.policie);
  const { selectedMenu } = useSelector((state: { menu: MenuReducerInitialState }) => state.menu);
	const { token } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);

  async function getHomeMessages(): Promise<void> {
    try {
      setLoading(true);
      const { status, data }: { status: number, data: IHTMLMessages } = await homeService.getMessage(token, currentBranch.codigo, currentCompany);

      if (status === 200) {
        setHtmlTitle(data.msguser);
        setHtmlHome(data.msggrupo);
      }
      else {
        console.log(data);
      }
    } catch (error) {
      console.log(error)
    } finally{
      setLoading(false);
    }
    
  }
  useEffect(() => {
    getHomeMessages();
  }, []);

  return (
    <PageDefault>
      <DefaultHeader />

      <Content>
        <SideMenu />
        <Browser title={selectedMenu.descri || selectedMenu.nome} icon={<House size={24} color={styleSheet.mainColor} />} >
          <BrowserContent>
            <Container fluid>
              {
                customizations['useBannerOnHomePage'] && 
                <Header dividing textAlign='center' style={{ width: '100%' }}>
                  <HTMLTitleVersion> Seja bem vindo a {packageInfo.version}!</HTMLTitleVersion>
                  <HTMLSubTitle >  {packageInfo.name.toUpperCase()} - {packageInfo.client} </HTMLSubTitle>
                  <ImgContainer />
                </Header>
              }
            </Container>
            <MarkupContainer>
              {
                loading && <div>
                  <Skeleton baseColor='#DDD' height={30}  width={550}  count={1} />
                  <Skeleton baseColor='#DDD' height={25}  width={450}  count={1} />
                  <Skeleton baseColor='#DDD' height={25}  width={350}  count={2} />
                  <Skeleton baseColor='#DDD' height={25}  width={250}  count={2} />
                </div>
              }
                                      
              <Fragment>{parse(htmlTitle)}</Fragment>  
              <Fragment>{parse(htmlHome)}</Fragment>  
             
            </MarkupContainer>
          </BrowserContent>
        </Browser>
      </Content>
      <Footer />
    </PageDefault>
  );
}

export default Home;